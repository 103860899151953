import CryptoJS from 'crypto-js'

const members = {
  '6278ad49821f0816a0efb28a_kakaopay1': 'b',
  '624ec6d947e1e75262a67069_kakaopay1': 'b',
  '60edc82a02e0338c61886477_kakaopay1': 'b',
  '6280857fa7b6c50ef93b1915_algorithm': 'd',
  '63170d9c356d44d1c9404184_algorithm': 'c',
  '626ec2b1a66592f5c1603f5f_algorithm': 'c',
  '6229d4bffab6bc5deedfd8b3_algorithm': 'c',
  '60edc82a02e0338c61886477_recommendModelRandomSelect': 'a',
  '60edc82a02e0338c61886477_aiPricing': 'a',
  '62289ecd88d236b5f8d40bc4_aiPricing': 'a',
  '62494250ec5c9b755afbba10_aiPricing': 'a',
  '6280857fa7b6c50ef93b1915_openteamdeal1108': 'b',
  '6368c909eb756b0381c89be2_openteamdeal1108': 'a',
  '60edc82a02e0338c61886477_timedeal0906': 'a',
  '62494250ec5c9b755afbba10_rankdeal0906': 'a',
  '62baa11046770bc2278c7f19_rankdeal0906': 'a',
  '62289ecd88d236b5f8d40bc4_rankdeal0906': 'a',
  '62271be8b3ef552fb2dd424d_rankdeal0906': 'a',
  '60fa478ad28d5e1c65152c6f_rankdeal0906': 'b',
  '6171614346fa9f36878921c1_rankdeal0906': 'a',
  '6280857fa7b6c50ef93b1915_mabsys0126': 'b',
  '6171614346fa9f36878921c1_lqd0130': 'a',
  '60fa478ad28d5e1c65152c6f_lqd0130': 'b',
  '6171614346fa9f36878921c1_ltd0207': 'a',
  '60fa478ad28d5e1c65152c6f_ltd0207': 'a',
  '626ec2b1a66592f5c1603f5f_canary0216': 'b',
  '6280857fa7b6c50ef93b1915_canary0216': 'b',
  '614b747d698b43323956cf2c_catefi0308': 'a',
  '614b747d698b43323956cf2c_canary0216': 'b',
  '6280857fa7b6c50ef93b1915_serverChange0309': 'b',
  '60f7444cd28d5e1c65152c21_catere0316': 'a', // 현식
  '60edc82a02e0338c61886477_arrivedOrderCaching': 'a', // 현직
  '6280857fa7b6c50ef93b1915_searchPersonalize0421': 'b',
  '614b747d698b43323956cf2c_styleMainToggle0407': 'a',
  '6301c88204228e2fd03fb3b6_EnhanceImpThruSearch': 'a', //세진
  '6280857fa7b6c50ef93b1915_fastFeedback0515': 'b',
  '6280857fa7b6c50ef93b1915_searchES0615': 'b',
  '6280857fa7b6c50ef93b1915_teamDealFix0713': 'b',
  '614b747d698b43323956cf2c_searchES0615': 'b',
  '626aa6a6c4d18f4110ecd6cb_dailyCheckInAd0605': 'e',
  // deal
  '62494250ec5c9b755afbba10_purechunk0618': 'b', // 진희
  '63d5ee1d3996f9130f8a7440_purechunk0618': 'b', // 영훈
  '62494250ec5c9b755afbba10_insert0622': 'a',
  '63d5ee1d3996f9130f8a7440_insert0622': 'a',
  '62494250ec5c9b755afbba10_related0629': 'a',
  '63d5ee1d3996f9130f8a7440_related0629': 'a',
  '6280857fa7b6c50ef93b1915_searchRelatedKeyword0704': 'b',
  '614b747d698b43323956cf2c_searchRelatedKeyword0704': 'b',
  '62494250ec5c9b755afbba10_visited0712': 'a', // 진희
  '63d5ee1d3996f9130f8a7440_visited0712': 'a', // 영훈
  '62494250ec5c9b755afbba10_random0718': 'a', // 진희
  '63d5ee1d3996f9130f8a7440_random0718': 'a', // 영훈
  '62494250ec5c9b755afbba10_price0720': 'a', // 진희
  '63d5ee1d3996f9130f8a7440_price0720': 'a', // 영훈
  '6280857fa7b6c50ef93b1915_itemWinnerAB0731': 'a', // 영환
  '614b747d698b43323956cf2c_itemWinnerAB0731': 'b', // 시창
  '61adb8da70ddf077458f5688_itemWinnerAB0731': 'b', // MD 나라
  '60edc82a02e0338c61886477_visited0817': 'b',
  '60edc82a02e0338c61886477_price0720': 'b',
  '649edb415d75ad38c6a1c445_tenMinDeal1025': 'b',
  '60edc82a02e0338c61886477_tenMinDeal1025': 'b', // 현직
  '6486ad07e7745aa2fd26566e_tenMinDeal1025': 'b', // 우진 dev
  '620cce8196d049583db642e3_tenMinDeal1025': 'd', // 우진
  '64983246bc423e260005dc6e_tenMinDeal1025': 'c', // 정준
  '651105228137d2b41b5d2e93_tenMinDeal1025': 'd', // 민웅
  '62494250ec5c9b755afbba10_tenMinDeal1025': 'a', // 진희
  '643df625a7a3e326096aece6_tenMinDeal1025': 'b', // 유성
  '60f7444cd28d5e1c65152c21_tenMinDeal1025': 'd', // 현식
  '614b747d698b43323956cf2c_winnerDeal1016': 'b', // 시창
  '6280857fa7b6c50ef93b1915_winnerDeal1016': 'c', // 영환
  '62494250ec5c9b755afbba10_winnerDeal1016': 'd', // 진희
  '60edc82a02e0338c61886477_winnerDeal1016': 'e', // 현직
  '64983246bc423e260005dc6e_winnerDeal1016': 'f', // 정준
  '643df625a7a3e326096aece6_luckTest1022': 'b', // 유성
  '6243835a9d22e940cecde8a5_luckTest1022': 'b', // 유성
  '64983246bc423e260005dc6e_winnerDeal1020': 'f', // 정준
  '64983246bc423e260005dc6e_winnerDeal1023': 'e', // 정준
  '614b747d698b43323956cf2c_winnerDeal1023': 'e', // 시창
  '64983246bc423e260005dc6e_winnerDealSorting1025': 'e', //정준
  '64983246bc423e260005dc6e_winnerDealMethod1025': 'c', //정준
  '6280857fa7b6c50ef93b1915_shippingInfoPush1125': 'b', // 영환
  '63aaa42da6e60a0e1fbdd7a3_shippingInfoPush1125': 'b',
  '6273409489df7134fe1d3674_yutnori0114': 'b',
  '627d10bfd430af8ffc940c7a_yutnori0114': 'b',
  '62271be8b3ef552fb2dd424d_yutnori0114': 'b',
  '60f1393ffc798a6746ccb33e_yutnori0114': 'b',
  '6243835a9d22e940cecde8a5_yutnori0114': 'b',
  '60edc82a02e0338c61886477_yutnori0114': 'b',
  '64d8a693675a64d4c3e20a31_yutnori0114': 'b', // dev
  '6273409489df7134fe1d3674_tWaterUp0116': 'b',
  '6273409489df7134fe1d3674_tteokgguk0122': 'b',
  '627d10bfd430af8ffc940c7a_tteokgguk0122': 'b',
  '620cce8196d049583db642e3_newYear24DealGetWaterBtn0123': 'a', // 우진
  '627d10bfd430af8ffc940c7a_newYear24DealGetWaterBtn0123': 'a', // 지성
  '6273409489df7134fe1d3674_newYear24DealGetWaterBtn0123': 'a', // 범래
  '64983246bc423e260005dc6e_newYear24DealGetWaterBtn0123': 'a', // 정준
  '627d10bfd430af8ffc940c7a_rewardTest0213': 'a', // wtwt
  '627d10bfd430af8ffc940c7a_cheerupGganbuTest': 'a',
  '643df625a7a3e326096aece6_reward0815': 'b',
  '627d10bfd430af8ffc940c7a_reward0815': 'b',
  '626ec2b1a66592f5c1603f5f_alranch0911': 'b',
  '6565aa51ed116a90f986d306_alranch0911': 'b',
}

const map = 'abcdefghij'
const AB = (id = '', key = 'test0000', g = [50]) => {
  if (!id) return 'a'
  if (`${id}_${key}` in members) {
    return members[`${id}_${key}`]
  }

  var hashed = CryptoJS.HmacSHA256(key, id).toString(CryptoJS.enc.Hex)
  const code = (parseInt(hashed?.slice(-8), 36) % 10000) / 100

  let r = ''
  g.forEach((each, i) => {
    if (code < each && !r) r = map[i]
  })

  if (!r) r = map[g.length]
  return r
}

export default AB
