import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import WhiteTextTimer from '../WhiteTextTimer'
import ModalStore from '../../store/ModalStore'

let rewardTypeMap = {
  level1: { rewardType: `고급비료`, rewardAmount: 2 },
  level2: { rewardType: `고급비료 2개, 비료`, rewardAmount: 2 },
  level3: { rewardType: `물 300g, 고급비료 3개, 비료`, rewardAmount: 3 },
  level4: { rewardType: `물 400g, 고급비료 4개, 비료`, rewardAmount: 4 },
}

const RelayRewardModal = observer(({ token, relayRewardInfo, farmData }) => {
  const [openLevelList, setOpenLevelList] = useState(false)
  const [backgroundPngSrc, setBackgroundPngSrc] = useState()

  useEffect(() => {
    if (openLevelList) {
      setBackgroundPngSrc('url(../relayReward/rewardList.png)')
    } else {
      setBackgroundPngSrc('url(../relayReward/background.png)')
    }
  }, [openLevelList])

  const sendToPurchaseScreen = () => {
    let rewardType = rewardTypeMap[`level${relayRewardInfo?.rewardLevel}`]
    const purchaseData = {
      enteringComponent: `Alfarm_relayReward_${relayRewardInfo?.rewardLevel}`,
      text: '올팜 릴레이 보상 찬스',
      source:
        'https://assets.ilevit.com/6364431c-74a5-4b1a-a4e0-b62460eeb7d6.png',
      rewardAmount: rewardType?.rewardAmount,
      rewardType: rewardType?.rewardType,
      rewardText: `상품을 구매하면 ${rewardType?.rewardType} ${rewardType?.rewardAmount}개를 받아요`,
    }

    window.location.href = `#commonPurchaseReward.${JSON.stringify(
      purchaseData,
    )}`
    ModalStore.setIsModalOpen('basic')
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 900,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90vw',
            height: '150vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'column',
            // border: "1px solid red",
            padding: 16,
            backgroundImage: backgroundPngSrc,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            // paddingTop: "57vw",
            paddingTop: '28vw',
            paddingBottom: '16px',
            // marginBottom: "15%",
          }}
        >
          {openLevelList ? (
            <div
              style={{
                // border: "1px solid black",
                width: '100%',
                height: '100%',
              }}
            />
          ) : (
            <>
              {/* 닫기버튼 */}
              <button
                style={{
                  width: '8vw',
                  right: 0,
                  top: -8,
                  position: 'absolute',
                  zIndex: 999,
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('basic')
                }}
              >
                <img
                  style={{
                    zIndex: 101,
                  }}
                  src='/icon/modalCloseButton.png'
                  alt=''
                />
              </button>

              {/* 모달 내용 */}
              <div
                style={{
                  marginTop: '1vw',
                  // border: "1px solid black",
                  width: '100%',
                  height: '100%',

                  marginBottom: 16,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0px 16px 0px 16px',
                  }}
                >
                  <button
                    style={{
                      // width: "vw",
                      right: '8vw',
                      top: '42vw',
                      position: 'absolute',
                      zIndex: 999,
                    }}
                    onClick={() => {
                      setOpenLevelList(true)
                    }}
                  >
                    <div
                      style={{
                        fontFamily: 'Maplestory',
                        color: '#442b22',
                        marginRight: 4,
                        fontWeight: 'bold',
                        padding: '6px 8px 6px 8px',
                        // border: "2px solid black",
                        borderRadius: 8,
                        backgroundColor: '#EFC68B',
                      }}
                    >
                      {`구매 보상표`}
                    </div>
                  </button>
                  <div
                    style={{
                      margin: '16px 0px 64px 0px',
                      // border: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '4px 8px 4px 8px',
                        // backgroundColor: "white",
                        // borderRadius: 8,
                        // border: "2px solid black",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: 'Maplestory',
                          color: '#442b22',
                          marginRight: 4,
                          fontWeight: 'bold',
                        }}
                      >
                        {`남은 시간: `}
                      </div>
                      <WhiteTextTimer
                        onTimeEnd={() => ModalStore.setIsModalOpen('basic')}
                        timeStamp={new Date(relayRewardInfo?.endAt)}
                        timerMinutes={0}
                        interval={1000}
                        style={{
                          fontFamily: 'Maplestory',
                          color: '#442b22',
                          fontWeight: 'bold',
                        }}
                        startFromMinutes
                        noDays
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      height: '1vw',
                    }}
                  />
                  <div
                    style={{
                      width: '100%',
                      height: '28vw',
                      backgroundImage: `url(../relayReward/reward${relayRewardInfo?.rewardLevel}.png)`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      // border: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        fontFamily: 'Maplestory',
                        color: '#442b22',
                        fontWeight: 'bold',
                        left: '50%',
                        // top: -8,
                        marginTop: '-3vh',

                        // border: "1px solid black",
                        // marginBottom: 8,
                        transform: 'translate(-50%, 0%)',
                      }}
                    >
                      {`지금 구매하면 받는 보상`}
                    </div>
                  </div>
                  <div
                    style={{
                      height: '6vh',
                    }}
                  />

                  {relayRewardInfo?.rewardLevel < 4 && (
                    <div
                      style={{
                        width: '100%',
                        height: '28vw',
                        backgroundImage: `url(../relayReward/reward${
                          relayRewardInfo?.rewardLevel + 1
                        }.png)`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        // border: "1px solid bl8ack",
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          fontFamily: 'Maplestory',
                          color: '#442b22',
                          fontWeight: 'bold',
                          left: '50%',
                          // top: -8,
                          marginTop: '-3vh',

                          // border: "1px solid black",
                          // marginBottom: 8,
                          transform: 'translate(-50%, 0%)',
                        }}
                      >
                        {`다음 구매에 받을 보상`}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {/* CTA */}
          <div
            style={{
              background:
                'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

              width: '60vw',
              height: '14vw',
              borderRadius: 99,
              color: 'black',
              fontFamily: 'Maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 8,
            }}
            onClick={() => {
              if (openLevelList) {
                setOpenLevelList(false)
              } else {
                sendToPurchaseScreen()
              }
            }}
          >
            {openLevelList ? '돌아가기' : '구매하고 보상 받기'}
          </div>
        </div>
      </div>
    </>
  )
})

export default RelayRewardModal
