import React, { useState, useEffect } from 'react'
import ModalStore from 'store/ModalStore'

const imgSrcMap = {
  water: '/icon/getWaterButtonNew.png',
  fertilizer: '/icon/fertilizer.png',
  fertilizerSet: '/icon/fertilizerSet.png',
  nutriment: '/icon/nutriment.png',
  specialPurchase: '/icon/specialPurchase.png',
  jangbogiCoin: '/dealEvent/jangbogi/coin.png',
}

// if (!getResourceModal) {
//   setTimeout(() => {
//     setGetResourceModal(false);
//     setRewardAmountForRewardToast(0);
//     setRewardTypeForRewardToast("water");
//   }, 1500);
// }
const GetResourceModal = ({
  amount,
  rewardType = 'water',
  setGetResourceModal = () => {},
  getResourceModal,
  setRewardAmountForRewardToast,
  setRewardTypeForRewardToast,
}) => {
  const [prefix, setPrefix] = useState('g')
  const [timerId, setTimerId] = useState(null)

  useEffect(() => {
    if (rewardType === 'water') {
      setPrefix('g')
    } else if (rewardType === 'nutriment') {
      setPrefix('')
    } else if (rewardType === 'fertilizer' || rewardType === 'fertilizerSet') {
      setPrefix('개')
    } else if (rewardType === 'specialPurchase') {
      setPrefix('개')
    } else if (rewardType === 'jangbogiCoin') {
      setPrefix('개')
    }
  }, [])

  useEffect(() => {
    if (getResourceModal) {
      const newTimerId = setTimeout(() => {
        setGetResourceModal(false)
        setRewardAmountForRewardToast(0)
        setRewardTypeForRewardToast('water')
        ModalStore.set('rewardPopUpOn', false)
      }, 1500)

      // 타이머 ID를 상태에 저장합니다.
      setTimerId(newTimerId)
    }

    // 컴포넌트가 언마운트되면 타이머를 정리합니다.
    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [getResourceModal])

  const handleCloseModal = () => {
    setGetResourceModal(false)

    // 클릭 이벤트 핸들러에서 타이머를 취소합니다.
    if (timerId) {
      clearTimeout(timerId)
    }
  }

  return (
    <div
      className='absolute flex-1 left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 w-full h-full bg-transparent z-[2000000]'
      onClick={handleCloseModal}
    >
      <div className='z-[2000000] bg-black absolute w-[35vw] h-[35vw] left-[50%] top-[50%] rounded-[15px] opacity-80 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center flex-col'></div>
      <div className='z-[2000000] absolute w-[35vw] h-[35vw] left-[50%] top-[50%] rounded-[15px] -translate-x-1/2 -translate-y-1/2 flex justify-center items-center flex-col'>
        <img
          className='w-[20vw] z-[2000000] h-auto left-[50%] top-[50%] '
          src={imgSrcMap[rewardType]}
          alt=''
        />

        <div className='left-[50%] top-[50%] z-[2000000] font-bold text-center'>
          {`${amount}${prefix} 획득!`}
        </div>
      </div>
    </div>
  )
}
export default GetResourceModal
