import { observable } from 'mobx'

const AlwayzUserStore = observable({
  alwayzUserData: {},
  teamDealId: null,

  setAlwayzUserData(data) {
    this.alwayzUserData = data
  },
  setTeamDealId(teamDealId) {
    this.teamDealId = teamDealId
  },
})

export default AlwayzUserStore
