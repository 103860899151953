import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'

const LeftScrollButtonSeosonalEventInviteModal = observer(
  ({ farmData, token, setFarmData }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)

    const shareKakao = () => {
      const inviteData = {
        shareType: 'AlfarmSeasonalEventNewUserInvite',
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
    }

    const Button = () => {
      return (
        <div
          style={{
            background: isButtonPressed
              ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressed
              ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
              : '',
            width: '60vw',
            height: '14vw',
            borderRadius: 99,
            color: 'black',
            fontFamily: 'Maplestory',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1vw',
            left: '50%',
            bottom: '0%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            fontSize: '5vw',
          }}
          onClick={() => {
            shareKakao()
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
        >
          친구 초대하기
        </div>
      )
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90%',
              height: '90vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              backgroundImage: 'url(../grapeEvent/grapeInviteModal.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <button
              style={{
                width: '10%',
                top: '-10%',
                right: '0%',
                transform: 'translate(0%, -50%)',
                position: 'absolute',
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            {Button()}
          </div>
        </div>
      </>
    )
  },
)
export default LeftScrollButtonSeosonalEventInviteModal
