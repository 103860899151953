import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import LoadingIndicator from '../loadingIndicator'
import commaNumber from 'comma-number'

import moment from 'moment'

const GAME_MSG = {
  dyson: '다이슨 에어랩',
  fastHarvest: '바로 수확',
  giftCard5000: '5,000원 쿠폰',
  giftCard10000: '10,000원 쿠폰',
  giftCard50000: '50,000원 쿠폰',
  fert5: '고급비료 5개',
  fert10: '고급비료 10개',
  fert15: '고급비료 15개',
  water1000: '물 1,000g',
  water3000: '물 3,000g',
  water5000: '물 5,000g',
  coupon: '3,000원 쿠폰',
  couponMinus: '뽑기권 사용',
  couponPlus: '뽑기권 획득',
}

const GAME_IMAGE = {
  dyson: '/roulettev2/dyson/dyson1.png',
  fastHarvest: '/roulettev2/harvest/harvest3.png',
  giftCard5000: '/roulettev2/giftCard/giftCard2.png',
  giftCard10000: '/roulettev2/giftCard/giftCard3.png',
  giftCard50000: '/roulettev2/giftCard/giftCard1.png',
  fert5: '/roulettev2/fert/fert2.png',
  fert10: '/roulettev2/fert/fert3.png',
  fert15: '/roulettev2/fert/fert1.png',
  water1000: '/roulettev2/water/water2.png',
  water3000: '/roulettev2/water/water3.png',
  water5000: '/roulettev2/water/water1.png',
  coupon: '/roulettev2/basic/basic3.png',
}

const RouletteEventHistoryModal = observer(
  ({ token, setOpenRouletteEventHistoryModal }) => {
    const [rouletteEventHistoryData, setRouletteEventHistoryData] = useState([])
    const [emptyModal, setEmptyModal] = useState(false)

    const getRouletteEventV2History = async () => {
      const result = await backendApis.getRouletteEventV2History(token)
      if (result?.data?.length === undefined) {
        setEmptyModal(true)
      }
      let description
      let image
      const customData = result?.data?.map((item) => {
        description = GAME_MSG[item.type] || '기타'
        image = GAME_IMAGE[item.type] || ''
        return { ...item, description, image }
      })

      setRouletteEventHistoryData(customData)
    }

    useEffect(() => {
      getRouletteEventV2History()
    }, [token])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1000,
            width: '100%',
            height: '100vh',
            background: 'rgba(0,0,0,0.8)',
          }}
        />
        <div
          style={{
            width: '80%',
            height: '110vw',
            position: 'absolute',
            zIndex: 1001,
            top: '55vw',
            left: '10%',
            background: 'white',
            borderRadius: 20,
          }}
        >
          <div
            style={{
              fontFamily: 'maplestory',
              color: '#402C24',
              fontSize: 22,
              textAlign: 'center',
              marginTop: '5vw',
              marginBottom: '1vw',
            }}
          >
            룰렛 기록
          </div>
          <div
            style={{
              backgroundColor: '#f2f2f2',
              width: '100%',
              height: '0.5vw',
              marginTop: 12,
            }}
          />
          <div
            style={{
              position: 'relative',
              overflowY: 'scroll',
              height: '93vw',
            }}
          >
            <div>
              {rouletteEventHistoryData?.length > 0 &&
                rouletteEventHistoryData?.map((item) => {
                  return (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginTop: 8,
                        }}
                      >
                        <div
                          style={{
                            marginLeft: 18,
                          }}
                        >
                          <div
                            style={{
                              color: '#5F2D0C',
                              zIndex: 1999,
                              fontFamily: 'maplestory',
                              fontSize: 16,
                              marginTop: 8,
                              marginRight: 16,
                            }}
                          >
                            {item?.description}
                          </div>
                          <div
                            style={{
                              color: '#855C36',
                              fontFamily: 'maplestory',
                              fontSize: 12,
                              marginTop: 10,
                            }}
                          >
                            {moment(item?.rouletteAt).format(`MM월 DD일 HH:mm`)}
                          </div>
                        </div>

                        <div
                          style={{
                            color: '#EE7B63',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginRight: 18,
                            display: 'flex',
                            flex: 1,
                            fontSize: 20,
                            fontFamily: 'maplestory',
                            marginTop: 8,
                          }}
                        >
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            {item?.description === '뽑기권 사용' && (
                              <div
                                style={{
                                  color: 'black',
                                  fontSize: '4vw',

                                  // width: "100%",
                                }}
                              >
                                {item?.count}개
                              </div>
                            )}
                            {item?.description === '뽑기권 획득' && (
                              <div
                                style={{
                                  color: 'black',
                                  fontSize: '4vw',
                                }}
                              >
                                {item?.count}개
                              </div>
                            )}
                            <img
                              style={{
                                width: '15vw',
                              }}
                              alt=''
                              src={item?.image}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          height: 1,
                          backgroundColor: '#EDEDED',
                          fontSize: 18,
                          marginTop: 16,
                        }}
                      />
                    </>
                  )
                })}
            </div>
          </div>
        </div>

        <button
          style={{
            width: '8%',
            top: '40vw',
            right: '10vw',
            zIndex: 1001,
            position: 'absolute',
          }}
          onClick={() => {
            setOpenRouletteEventHistoryModal(false)
          }}
        >
          <img src='/icon/modalCloseButton.png' alt='' />
        </button>

        {emptyModal && (
          <>
            <div
              style={{
                color: 'black',
                fontFamily: 'maplestory',
                fontSize: '5vw',
                marginTop: '40vw',
                textAlign: 'center',
                position: 'absolute',
                zIndex: 3333,
                width: '100%',
                top: '40vw',
              }}
            >
              뽑기 기록이 없어요
              <br />
              친구를 초대해 룰렛을 돌려보세요!
            </div>
          </>
        )}
      </>
    )
  },
)

export default RouletteEventHistoryModal
