import Layout from '../comps/atoms/layout'
import React, { useState, useEffect, useRef } from 'react'
import Iframe from 'react-iframe'
import { useSearchParam } from 'react-use'
import FlappyBirdRewardModal from '../comps/atoms/FlappyBirdRewardModal'
import backendApis from '../utils/backendApis'
import timeChecker from '../utils/timeChecker'
import { NavLink } from 'react-router-dom'
import commaNumber from 'comma-number'
import toast, { Toaster } from 'react-hot-toast'
import FlappyBirdEventModal from '..//comps/atoms/FlappyBirdEventModal'
import FlappyBirdPurchaseModal from '..//comps/atoms/FlappyBirdPurchaseModal'
import ModalStore from '../store/ModalStore'
import { observer } from 'mobx-react-lite'

const FlappyBird = observer(({}) => {
  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const randomString = useSearchParam('randomString')
  const waterParams = useSearchParam('water')

  const [showEventModal, setShowEventModal] = useState()
  const [showBottomButton, setShowBottomButton] = useState(true)
  const [playLog, setPlayLog] = useState([])
  const [bestScore, setBestScore] = useState(0)
  const [rewardAvailable, setRewardAvailable] = useState(false)
  const [purchaseAvailable, setPurchaseAvailable] = useState(false)
  const [rewardAmount, setRewardAmount] = useState(0)

  const childRef = useRef()
  const [water, setWater] = useState(waterParams)
  const [gameStatus, setGameStatus] = useState('getReady')
  const toastBlock = useRef()
  const timeInfoRef = useRef()

  const notify = (toastBlock) => {
    if (toastBlock.current === true) return
    toast('물이 부족해요')
    toastBlock.current = true
    setTimeout(() => {
      toastBlock.current = false
    }, 2000)
  }

  useEffect(() => {
    toastBlock.current = false
    window.addEventListener('message', function (e) {
      // e.data가 전달받은 메시지
      if (e.data?.status === 'gameOver') {
        setGameStatus('gameOver')
        handleGameOver(e)
      } else if (e.data?.status === 'gameStart') {
        setShowBottomButton(false)
        setGameStatus('play')
      } else if (e.data?.status === 'getReady') {
        setGameStatus('getReady')
      } else if (e.data?.status === 'bestScore') {
        updateBestScore(e.data?.score)
      }
    })
    initFlappyBird()
    childRef.current = document.getElementById('child') // iframe
  }, [])

  useEffect(() => {
    if (gameStatus === 'gameOver') {
      if (rewardAvailable && playLog?.length % 6 === 0) {
        ModalStore.setIsModalOpen('flappyBirdReward')
      } else if (purchaseAvailable && playLog?.length % 6 === 0) {
        ModalStore.setIsModalOpen('flappyBirdPurchase')
      }
    }
  }, [gameStatus])

  const setRewardAmountProcess = async (timeInfo, best) => {
    let rewardAmount = Math.min(Math.round(best / 4) * 50, 1500)
    await backendApis.updateFlappyBirdPurchaseReward({
      reward: rewardAmount,
    })
    setRewardAmount(Math.min(rewardAmount), 1500)
    let result = await backendApis.getSpecificOngoingAlfarmInfo({
      fieldsProjected: JSON.stringify({
        lastFlappyBirdPurchaseAt: 1,
      }),
    })

    let date = new Date(result?.data?.lastFlappyBirdPurchaseAt ?? '2022-01-01')

    if (timeChecker(date, timeInfo)) {
      setPurchaseAvailable(true)
    } else {
      setPurchaseAvailable(false)
    }
  }

  const updateBestScore = async (score) => {
    await backendApis.updateSpecificField({
      fieldName: 'flappyBirdAllTimeBestScore',
      value: Number(score),
    })
  }

  const handleGameOver = async (e) => {
    setShowBottomButton(true)
    let newLog = await pushFlappyBirdLog(e.data?.score)
    initFlappyBird(newLog)
  }

  const initFlappyBird = async (newLog) => {
    let timeInfo = await resetTodayBest()
    timeInfoRef.current = timeInfo
    getFlappyBirdLog(timeInfo, newLog)
    setAllTimeBestScore()
  }

  const setAllTimeBestScore = async () => {
    let result = await backendApis.getSpecificOngoingAlfarmInfo({
      fieldsProjected: JSON.stringify({
        flappyBirdAllTimeBestScore: 1,
      }),
    })

    if (!result?.data?.flappyBirdAllTimeBestScore) {
      let score = localStorage.getItem('flappyBirdBest') ?? 0
      await backendApis.updateSpecificField({
        fieldName: 'flappyBirdAllTimeBestScore',
        value: Number(score),
      })
    } else {
      if (typeof result?.data?.flappyBirdAllTimeBestScore === 'string') {
        await backendApis.updateSpecificField({
          fieldName: 'flappyBirdAllTimeBestScore',
          value: Number(result?.data?.flappyBirdAllTimeBestScore),
        })
      }
      localStorage.setItem(
        'flappyBirdBest',
        result?.data?.flappyBirdAllTimeBestScore,
      )
    }
  }

  const getFlappyBirdLog = async (timeInfo, newLog) => {
    let playLog = []
    let result

    if (!newLog) {
      result = await backendApis.getSpecificOngoingAlfarmInfo({
        fieldsProjected: JSON.stringify({
          flappyBirdLog: 1,
          lastFlappyBirdRewardAt: 1,
        }),
      })
      playLog = [...result?.data?.flappyBirdLog]
    } else {
      result = await backendApis.getSpecificOngoingAlfarmInfo({
        fieldsProjected: JSON.stringify({
          lastFlappyBirdRewardAt: 1,
        }),
      })
      playLog = [...newLog]
    }

    setPlayLog(playLog)

    setRewardAvailable(
      timeChecker(result?.data?.lastFlappyBirdRewardAt, timeInfo),
    )

    let best = bestScore

    for (let i = 0; i < playLog.length; i++) {
      if (playLog[i]?.score > best) {
        best = playLog[i]?.score
      }
    }
    if (best > bestScore) {
      setBestScore(best)
      setRewardAmountProcess(timeInfo, best)
    }
  }

  const pushFlappyBirdLog = async (score) => {
    let result = await backendApis.pushFlappyBirdLog({ score: score })
    return result.data
  }

  const resetTodayBest = async () => {
    let timeInfo = await backendApis.getStandardTimeInfo()
    let timeResult = await backendApis.getSpecificOngoingAlfarmInfo({
      fieldsProjected: JSON.stringify({
        lastFlappyBirdPlayedAt: 1,
      }),
    })
    let time = new Date(
      timeResult?.data?.lastFlappyBirdPlayedAt ?? '2022-01-01',
    )

    if (time) {
      if (timeChecker(time, timeInfo?.data)) {
        window.localStorage.setItem('flappyBirdToday', 0)
        await backendApis.updateSpecificField({
          fieldName: 'flappyBirdLog',
          value: [],
        })
      }
    } else {
      window.localStorage.setItem('flappyBirdToday', 0)
    }
    return timeInfo?.data
  }

  const startGame = async () => {
    if (water < playLog?.length ?? 0) {
      notify(toastBlock)
      return
    }
    if (gameStatus === 'gameOver') {
      childRef.current.contentWindow.postMessage({ msg: 'startClicked' }, '*')
    }

    if (gameStatus === 'getReady') {
      let result = await backendApis.reduceWaterByPlay({
        amount: playLog?.length ?? 0,
      })
      await backendApis.updatelastUpdatedAt(token, 'PUT', {
        updateType: 'lastFlappyBirdPlayedAt',
      })
      if (result?.data?.success) {
        childRef.current.contentWindow.postMessage({ msg: 'startClicked' }, '*')
        setWater(water - playLog?.length ?? 0)
      }
    }
  }

  const BottomButton = ({ img, onClick }) => {
    return (
      <div
        style={{
          width: '20vw',
          height: '20vw',
          backgroundImage: img,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        onClick={onClick}
      />
    )
  }

  return (
    <>
      <Iframe
        styles={{ border: 0, margin: 0 }}
        frameBorder={0}
        url='/FlappyBird.html'
        width='100%'
        height='100%'
        id='child'
        className=''
        display='block'
        position='absolute'
        // allowFullScreen={true}
      />
      <NavLink
        to={`/farmPage?token=${token}&itemType=${itemType}&randomNumber=${randomString}`}
      >
        <button
          style={{
            position: 'absolute',
            width: '7vw',
            marginLeft: '2vw',
            paddingTop: '10vw',
          }}
          action='goBack'
        >
          <img src='/luckyPiggy/backWhite.png' alt='' />
        </button>
      </NavLink>
      {gameStatus === 'getReady' && (
        <>
          <div
            style={{
              position: 'absolute',
              width: 130,
              height: 50,
              bottom: 30,
              marginBottom: '28vh',
              left: '50%',
              transform: 'translate(-50%)',
              backgroundColor: '#E9FCDB',
              borderRadius: 16,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              paddingTop: 8,
              fontFamily: 'maplestory',
              border: '2px solid #FDB800',
              color: 'black',
              fontWeight: 'bold',
              zIndex: 10,
            }}
          >
            {`내 물: ${commaNumber(water)}g`}
            <div
              style={{
                position: 'absolute',
                width: 200,
                height: 60,
                bottom: -40,
                // marginBottom: "28vh",
                left: '50%',
                transform: 'translate(-50%)',
                backgroundColor: '#FDB800',
                borderRadius: 16,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'maplestory',
                border: '2px solid #E9FCDB',
                color: 'black',
                fontWeight: 'bold',
                zIndex: 20,
                textAlign: 'center',
                flexDirection: 'column',
              }}
              onClick={() => {
                startGame()
              }}
            >
              <div style={{ color: 'black' }}>시작하기</div>
              <div style={{ color: '#333', fontWeight: 'normal' }}>{`(물 ${
                playLog?.length ?? 0
              }g 차감)`}</div>
            </div>
          </div>
          <div
            style={{
              color: 'gray',
              position: 'absolute',
              fontFamily: 'maplestory',
              bottom: 30,
              marginBottom: '20vh',
              left: '50%',
              transform: 'translate(-50%)',
              fontSize: '0.8rem',
            }}
          >
            *새벽 4시에 시작비용이 초기화돼요
          </div>
        </>
      )}
      {gameStatus === 'gameOver' && (
        <>
          <div
            style={{
              position: 'absolute',
              width: 200,
              height: 50,
              bottom: 0,
              marginBottom: '28vh',
              left: '50%',
              transform: 'translate(-50%)',
              backgroundColor: '#FDB800',
              borderRadius: 16,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'maplestory',
              border: '2px solid #E9FCDB',
              color: 'black',
              fontWeight: 'bold',
              zIndex: 20,
            }}
            onClick={() => {
              startGame()
            }}
          >
            {`다시하기`}
          </div>
        </>
      )}
      {showBottomButton && (
        <div
          style={{
            width: '100%',
            position: 'absolute',
            bottom: 0,
            padding: 16,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <BottomButton
            img='url(../icon/backToFarm.png)'
            onClick={() => {
              window.location.href = `/farmPage?token=${token}&itemType=${itemType}&randomNumber=${randomString}`
            }}
          />
          <BottomButton
            img='url(../flappyBirdImg/rewardEntryIcon.png)'
            onClick={async () => {
              ModalStore.setIsModalOpen('flappyBirdReward')
            }}
          />
          <BottomButton
            img='url(../flappyBirdImg/purchaseEntryIcon.png)'
            onClick={async () => {
              ModalStore.setIsModalOpen('flappyBirdPurchase')
            }}
          />
          {/* <BottomButton
            img="url(../flappyBirdImg/eventEntryIcon.png)"
            onClick={async () => {
              setShowEventModal(true);
            }}
          /> */}
        </div>
      )}

      {ModalStore?.isModalOpen === 'flappyBirdReward' && (
        <FlappyBirdRewardModal
          token={token}
          itemType={itemType}
          bestScore={bestScore}
          setRewardAvailable={setRewardAvailable}
          rewardAvailable={rewardAvailable}
        />
      )}
      {showEventModal && <FlappyBirdEventModal />}
      {ModalStore?.isModalOpen === 'flappyBirdPurchase' && (
        <FlappyBirdPurchaseModal
          token={token}
          itemType={itemType}
          bestScore={bestScore}
          timeInfo={timeInfoRef.current}
          rewardAmount={rewardAmount}
          purchaseAvailable={purchaseAvailable}
        />
      )}
    </>
  )
})

export default FlappyBird
