import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import QRCode from 'react-qr-code'
import WhiteTextTimer from '../WhiteTextTimer'
import ModalStore from '../../store/ModalStore'

const rewardImageMap = {
  water: 'url(../qrBonus/water.png)',
  fertilizer: 'url(../qrBonus/fertilizer.png)',
  fertilizerSet: 'url(../qrBonus/fertilizerSet.png)',
}

const noRewardImageMap = {
  2004: {
    src: 'url(../qrBonus/openedGiftBox.png)',
    text: '이미 오늘의 선물을 받으셨어요',
  },

  2002: {
    src: 'url(../qrBonus/noSelfInvite.png)',
    text: '셀프 선물은 불가능해요',
  },
}

const QRBonusRewardModal = observer(({ farmData, setFarmData }) => {
  const [giftBoxClassName, setGiftBoxClassName] = useState('')
  const [showResult, setShowResult] = useState(false)

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 900,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90vw',
            height: '150vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -55%)',
            display: 'flex',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'column',
            // border: "1px solid red",
            padding: 16,
            backgroundImage: 'url(../qrBonus/background.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            paddingTop: '57vw',
            paddingBottom: '16%',
            // marginBottom: "15%",
          }}
        >
          <button
            style={{
              width: '8vw',
              right: 0,
              top: '20vw',
              position: 'absolute',
              zIndex: 999,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            style={{
              // border: "1px solid black",
              width: '100%',
              height: '100%',
              // marginBottom: "12vw",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {farmData?.status === 2000 ? (
              <>
                {!showResult ? (
                  <div
                    style={{
                      width: '100%',
                      color: 'black',
                      fontFamily: 'Maplestory',
                      textAlign: 'center',
                      marginBottom: 8,
                    }}
                  >
                    친구에게 받은
                    <br />
                    선물상자를 열어보세요!
                  </div>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      color: 'black',
                      fontFamily: 'Maplestory',
                      textAlign: 'center',
                      marginBottom: 8,
                      fontSize: '5vw',
                    }}
                  >
                    축하합니다!
                  </div>
                )}

                <div
                  style={{
                    // width: "100%",
                    width: 128,
                    height: 128,
                    backgroundImage: 'url(../qrBonus/giftBox.png)',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    margin: '8px 0px 8px 0px',
                  }}
                  className={giftBoxClassName}
                  onClick={() => {
                    if (giftBoxClassName === '') {
                      setGiftBoxClassName('shakePop')
                      setTimeout(() => {
                        setShowResult(true)
                        setGiftBoxClassName('hideGiftBox')
                        let temp = farmData
                        temp[farmData?.rewardType] += farmData?.rewardAmount
                        setFarmData(temp)
                      }, 1700)
                    }
                  }}
                />
                {showResult && (
                  <>
                    <div
                      style={{
                        width: '20vw',
                        height: '20vw',
                        backgroundImage: 'url(../icon/getWaterButtonNew.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        margin: '8px 0px 8px 0px',
                      }}
                    />
                    <div
                      style={{
                        color: 'black',
                        fontFamily: 'Maplestory',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '5vw',
                      }}
                    >
                      물 {farmData?.rewardAmount}g을 받았어요!
                    </div>
                    <div
                      style={{
                        background:
                          'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                        width: '60vw',
                        height: '14vw',
                        borderRadius: 99,
                        color: 'black',
                        fontFamily: 'Maplestory',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '4vw',
                      }}
                      onClick={() => ModalStore.setIsModalOpen('basic')}
                    >
                      보상 받기
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div
                  style={{
                    color: 'black',
                    fontFamily: 'Maplestory',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {noRewardImageMap[farmData?.status]?.text}
                </div>
                <div
                  style={{
                    // width: "100%",
                    width: 128,
                    height: 128,
                    backgroundImage: noRewardImageMap[farmData?.status]?.src,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    margin: '8px 0px 8px 0px',
                  }}
                />
                <div
                  style={{
                    background:
                      'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                    width: '60vw',
                    height: '14vw',
                    borderRadius: 99,
                    color: 'black',
                    fontFamily: 'Maplestory',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                  }}
                  onClick={() => ModalStore.setIsModalOpen('basic')}
                >
                  닫기
                </div>
              </>
            )}

            <div
              style={{
                width: '100%',
                color: '#666',
                fontFamily: 'Maplestory',
                textAlign: 'center',
                fontSize: '0.8rem',
                margin: '8px 0px 8px 0px',
              }}
            >
              *보상은 하루에 1번만 받을 수 있어요.
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default QRBonusRewardModal
