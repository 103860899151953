import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import BottomSheetStore from '../../store/BottomSheetStore'
import TimeStore from '../../store/TimeStore'
import { nanoid } from 'nanoid'
import { useNavigate } from 'react-router-dom'
import UserStore from '../../store/UserStore'

function timeForToday(value) {
  const today = TimeStore.currentNewDate
  const timeValue = new Date(value)

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60,
  )
  if (betweenTime < 1) return '방금전'
  if (betweenTime < 60) {
    return `${betweenTime}분전`
  }

  const betweenTimeHour = Math.floor(betweenTime / 60)
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24)
  if (betweenTimeDay < 365) {
    return `${betweenTimeDay}일전`
  }

  return `${Math.floor(betweenTimeDay / 365)}년전`
}

const FeedSocialHistory = observer(({ token, farmData }) => {
  const [socialHistoryData, setSocialHistoryData] = useState([])
  const [emptyFeed, setEmptyFeed] = useState(false)
  const navigate = useNavigate()

  const shareKakao = () => {
    window.location.href = '#alfarmGganbuInvite'
  }

  const getSocialHistory = async () => {
    const result = await backendApis.getSocialHistory(token)
    if (result?.data?.length === 0) {
      setEmptyFeed(true)
    }
    let description
    const customData = result?.data?.map((item) => {
      if (item?.type === 101) {
        description = `${item?.userName}님이 물을 요청했어요💧`
      } else if (item?.type === 102) {
        description = `${item?.userName}님이 고마움을 표현했어요🙏`
      } else if (item?.type === 103) {
        description = `${item?.userName}님이 농장 방문 메세지를 남겼어요👩🏻‍🌾👨🏻‍🌾`
      } else if (item?.type === 104) {
        description = `${item?.userName}님이 응원을 보냈어요🔥`
      } else if (item?.type === 105) {
        description = `${item?.userName}님이 물을 주고 메세지를 남겼어요🪣`
      } else if (item?.type === 106) {
        description = `${item?.userName}님이 격려 메세지를 남겼어요💪🏻`
      } else if (item?.type === 201) {
        description = `${item?.userName}님이 이쪽저쪽 점수를 자랑했어요😎`
      } else if (item?.type === 'giveWaterBasketToGganbu') {
        description = '작물에 물 10g을 줬어요🪣'
      } else if (item?.type === 'giveWaterBasketFiveToGganbu') {
        description = '작물에 물 50g을 줬어요🪣🪣'
      } else if (item?.type === 'waterFromGganbu') {
        description = '농장에 방문하여 물을 받았어요💧'
      } else if (item?.type === 'bonusRewardBox') {
        description = `${item?.userName}님이 보물상자를 열었어요🎁`
      } else if (item?.type === 'purchasedForGganbu') {
        description = '품앗이로 물 500g을 줬어요🪣'
      } else if (item?.type === 'plantedBomb') {
        description = `${item?.userName}님이 농장에 폭탄을 설치했어요💣`
      } else if (item?.type === 'pokedByGganbu') {
        description = `${item?.userName}님이 나를 콕! 찔렀어요 👉❤️👈`
      } else if (item?.type === 'gganbuGiftWater') {
        description = `${item?.userName}님이 물 50g을 선물했어요🎁`
      } else if (item?.type === 'gganbuGiftFertilizer') {
        description = `${item?.userName}님이 일반비료 1개를 선물했어요🎁`
      } else if (item?.type === 'gganbuGiftFertilizerSet') {
        description = `${item?.userName}님이 고급비료 1개를 선물했어요🎁`
      } else {
        description = `${item?.userName}님의 농장에 방문해보세요`
      }

      return { ...item, description }
    })

    setSocialHistoryData(customData)
  }

  useEffect(() => {
    getSocialHistory()
  }, [token])

  return (
    <div
      style={{
        // position: "relative",
        overflowY: 'scroll',
        height: '90vw',
      }}
    >
      <div>
        {socialHistoryData?.length > 0 &&
          socialHistoryData?.map((item) => {
            return (
              <div key={nanoid + Math.random()}>
                <div
                  style={{
                    zIndex: 1999,
                    marginLeft: 16,
                    marginTop: 4,
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                  }}
                  onClick={() => {
                    let randomString = Math.random().toString(36).slice(2, 8)
                    if (item?.userId) {
                      navigate(
                        `/gganbuFarm/${item?.userId}?token=${token}&itemType=${farmData?.itemType}&gganbuName=${item?.userName}&randomNumber=${randomString}`,
                      )
                    }
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      alignContent: 'center',
                      marginTop: 4,
                    }}
                  >
                    <img
                      style={{
                        zIndex: 1999,
                        width: 40,
                        height: 40,
                        borderRadius: 50,
                      }}
                      src={item?.userImage}
                      onError={(e) => {
                        e.target.src = UserStore?.default_profile_image
                      }}
                      alt='profile'
                    />
                    <div
                      style={{
                        marginLeft: 16,
                      }}
                    >
                      <div
                        style={{
                          color: 'black',
                          zIndex: 1999,
                          fontFamily: 'maplestory',
                          fontSize: 16,
                        }}
                      >
                        {item?.userName}
                      </div>

                      <div
                        style={{
                          color: '#5F2D0C',
                          zIndex: 1999,
                          fontFamily: 'maplestory',
                          fontSize: 14,
                          marginBottom: 4,
                          marginRight: 16,
                        }}
                      >
                        {item?.description}
                      </div>
                      <div
                        style={{
                          color: '#855C36',
                          fontFamily: 'maplestory',
                          fontSize: 12,
                          // marginTop: 10,
                        }}
                      >
                        {timeForToday(item?.loggedAt)}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: '#EDEDED',
                    fontSize: 18,
                    marginTop: 8,
                  }}
                />
              </div>
            )
          })}
      </div>
      {emptyFeed && (
        <>
          <div
            style={{
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: '5.5vw',
              marginTop: '20vw',
              textAlign: 'center',
            }}
          >
            맞팜을 초대하여 피드를 쌓아보세요.
            <br />
            물과 비료를 더 많이 획득할 수있어요!
          </div>
          <div
            style={
              {
                // zIndex: 0.9,
              }
            }
          >
            <button
              style={{
                flex: 1,
                marginLeft: '15vw',
                marginTop: '8vw',
                right: '0',
                fontSize: 18,
                textAlign: 'center',
                zIndex: 2,
                borderRadius: 30,
                width: '70%',
                height: 50,
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              }}
              onClick={() => {
                BottomSheetStore.setIsbottomSheetOpen('basic')
                setTimeout(() => {
                  shareKakao()
                }, 200)
              }}
            >
              <div
                style={{
                  color: '#402C24',
                  fontFamily: 'maplestory',
                  fontSize: 18,
                }}
              >
                맞팜 초대하기
              </div>
            </button>
          </div>
        </>
      )}
    </div>
  )
})

export default FeedSocialHistory
