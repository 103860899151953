import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import WhiteTextTimer from '../../comps/WhiteTextTimer'
import ModalStore from '../../store/ModalStore'
import UserStore from '../../store/UserStore'

const InviteButton = ({ isFilled = false, shareKakao, userImage }) => {
  return isFilled ? (
    <img
      style={{
        width: '15vw',
        height: '15vw',
        borderRadius: 55,
        marginRight: 8,
        marginTop: '0.5vw',
      }}
      src={userImage}
      onError={(e) => {
        e.target.src = UserStore?.default_profile_image
      }}
    />
  ) : (
    <div
      style={{
        // border: "1px solid black",
        width: '15vw',
        height: '15vw',
        backgroundImage: 'url(../lastMinuteInvite/invite.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      onClick={() => shareKakao()}
    />
  )
}

const LevelUpChanceModalNew = observer(
  ({
    farmData,
    setFarmData,
    userCodePushVersion,
    codePushVersionAvailable,
  }) => {
    const [isFilled, setIsFilled] = useState(false)
    const [userImage, setUserImage] = useState()
    const [infoModal, setInfoModal] = useState()

    const shareKakao = () => {
      const inviteData = {
        shareType: 'AlfarmLevelUpChanceNew',
        referralCode: farmData?.referralCode,
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
    }

    useEffect(() => {
      if (farmData?.levelUpChanceInviteInfo?.inviteeList?.length > 0) {
        setIsFilled(true)
        setUserImage(
          farmData?.levelUpChanceInviteInfo?.inviteeList?.[0]?.userImage,
        )
      }
    }, [farmData])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '14vw',
              backgroundImage: 'url(../levelUpChance/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '47vw',
              paddingBottom: '24%',
            }}
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: '10vw',
                position: 'absolute',
                zIndex: 1999,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 1101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  color: 'black',
                  fontFamily: 'maplestory',
                  width: '100vw',
                  fontSize: '5vw',
                  textAlign: 'center',
                  marginTop: '2vw',
                }}
              >
                수확 마지막 단계로 이동할 수 있어요!
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {farmData?.levelUpChanceInviteInfo?.createdAt && (
                  <WhiteTextTimer
                    // onTimeEnd={() => setOpenLevelUpChanceModalNew(false)}
                    timeStamp={
                      new Date(farmData?.levelUpChanceInviteInfo?.createdAt)
                    }
                    timerMinutes={60 * 24 * 3}
                    interval={1000}
                    color='#442b22'
                    style={{
                      marginTop: '-2vw',
                      color: 'red',
                      fontFamily: 'Maplestory',
                      padding: 0,
                      margin: 0,
                      fontSize: '5vw',
                    }}
                    noDays={true}
                  />
                )}
                <div
                  style={{
                    color: 'black',
                    fontFamily: 'maplestory',
                    marginLeft: 4,
                    fontSize: '4vw',
                  }}
                >
                  후에 이벤트가 종료돼요
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  paddingLeft: '10%',
                  paddingRight: '10%',

                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <InviteButton
                  isFilled={isFilled}
                  shareKakao={() => shareKakao()}
                  userImage={userImage}
                />
                <InviteButton shareKakao={() => shareKakao()} />
              </div>

              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  fontSize: '4.5vw',
                  lineHeight: '150%',
                }}
              >
                올팜을 안 해본 친구를 초대하면
                <br />
                마지막 단계로 이동해요
              </div>

              <div
                style={{
                  background:
                    'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                  width: '60vw',
                  height: '14vw',
                  borderRadius: 99,
                  color: 'black',
                  fontFamily: 'Maplestory',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '1vw',
                  fontSize: '5vw',
                }}
                onClick={() => {
                  shareKakao()
                }}
              >
                초대하기
              </div>
              <button
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  fontSize: '4vw',
                  marginBottom: '1vw',
                  marginTop: '-3vw',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  setInfoModal(true)
                }}
              >
                안내사항
              </button>
            </div>
          </div>
        </div>
        {infoModal && (
          <>
            <div
              style={{
                flex: 1,
                position: 'fixed',
                left: '10%',
                top: '45vw',
                zIndex: 12000,
                width: '80%',
                background: 'rgba(0,0,0,0.9)',
                borderRadius: 20,
                fontFamily: 'maplestory',
                fontSize: '4vw',
                padding: '6vw',
                paddingTop: '8vw',
                lineHeight: '170%',
                paddingBottom: '8vw',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                안내사항
              </div>
              <div style={{ height: '3vw' }} />- 올팜을 아직 안 해본 친구 2명을
              초대하면 마지막 단계로 레벨업해요 <br />- 친구를 초대해도 적용이
              되지 않는 경우 작물을 선택하고 친구가 보낸 초대 링크를 다시
              클릭해주세요
              <br /> - 혹은 좌측 하단의 피드 {'>'} 중앙 상단의 초대코드 입력하기{' '}
              {'>'} 친구의 링크에 있는 초대코드 입력을 통해서 초대 보상을 적용할
              수 있어요
              <br /> - 올웨이즈 탈퇴 이력이 있는 경우 탈퇴일로부터 7일간 초대할
              수 없어요 <br />- 정상적이지 않은 방법으로 친구를 초대하는 경우
              작물 발송이 제한될 수 있어요 <br />- 이벤트는 사전 고지 없이 변경
              및 종료 될 수 있어요
            </div>
            <button
              style={{
                width: '8%',
                top: '47vw',
                right: '14%',
                zIndex: 12001,
                position: 'absolute',
              }}
              onClick={() => {
                setInfoModal(false)
              }}
            >
              <img
                style={{
                  zIndex: 1101,
                }}
                src='/icon/modalCloseIcon.png'
                alt=''
              />
            </button>
          </>
        )}
      </>
    )
  },
)

export default LevelUpChanceModalNew
