import { makeObservable, observable, action } from 'mobx'

class ResourceToast {
  resourceToast = null
  topToast = null
  timeoutRef = null

  constructor() {
    makeObservable(this, {
      resourceToast: observable,
      topToast: observable,
      timeoutRef: observable,

      setResourceToast: action,
      setTopToast: action,
      setTimeoutRef: action,
    })
  }

  setResourceToast(resourceToast) {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
    }
    this.resourceToast = resourceToast
    const timeoutRef = setTimeout(() => {
      this.resourceToast = null
    }, 2500)
    this.setTimeoutRef(timeoutRef)
  }

  setTopToast(topToast) {
    this.topToast = topToast
  }

  setTimeoutRef(timeoutRef) {
    this.timeoutRef = timeoutRef
  }
}

export default new ResourceToast()
