import React from 'react'
import { observer } from 'mobx-react-lite'
import FeedSocialHistory from './FeedSocialHistory'
import Badge from './Badge'
import dayjs from 'dayjs'
import BottomSheetStore from '../../store/BottomSheetStore'
import ModalStore from '../../store/ModalStore'
import FeedQuickLink from './FeedQuickLink'
import BottomSheetHeader from 'comps/molecules/BottomSheetHeader'

const FeedModal = observer(
  ({ token, itemType, userCodePushVersion, userId, farmData }) => {
    return (
      <div>
        <BottomSheetHeader />

        <div
          style={{
            width: '100%',
            height: '2vw',
          }}
        />

        <div
          style={{
            display: 'flex',
            paddingBottom: '1vw',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '2vw',
              width: '100%',
              paddingRight: '4vw',
              paddingLeft: '4vw',
            }}
          >
            {farmData.isNewUser &&
              farmData?.inviteNewUserEvent?.length <= 0 &&
              dayjs().isBefore(dayjs(farmData?.createdAt).add(2, 'd')) && (
                <div
                  style={{
                    color: 'black',
                    fontFamily: 'maplestory',
                    fontSize: '4vw',
                    textAlign: 'center',
                    paddingRight: '4vw',
                  }}
                  onClick={() => {
                    // setShowReferralCodeModal(true);
                    ModalStore.setIsModalOpen('referralCode')
                  }}
                >
                  🎁 초대코드 입력
                </div>
              )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('announce')
              }}
            >
              <div
                style={{
                  color: 'black',
                  fontFamily: 'maplestory',
                  fontSize: '4vw',
                  justifyContent: 'flex-end',
                  display: 'flex',
                  textAlign: 'center',
                }}
              >
                📢 공지사항
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: 4,
            backgroundColor: '#EDEDED',
            fontSize: 18,
            marginTop: 8,
            marginBottom: 8,
          }}
        />
        <FeedQuickLink
          token={token}
          userId={userId}
          itemType={itemType}
          farmData={farmData}
          userCodePushVersion={userCodePushVersion}
        />
        <div
          style={{
            width: '100%',
            height: 4,
            backgroundColor: '#EDEDED',
            fontSize: 18,
            marginTop: 8,
            marginBottom: 8,
          }}
        />
        <Badge
          itemType={itemType}
          changedPlantInfo={!!farmData?.didChangePlantFromMandarinToOther}
          token={token}
          userCodePushVersion={userCodePushVersion}
          userId={userId}
          farmLevel={farmData?.farmLevel}
          farmData={farmData}
        />

        <div
          style={{
            width: '100%',
            height: 4,
            backgroundColor: '#EDEDED',
            fontSize: 18,
            marginTop: 8,
            marginBottom: 8,
          }}
        />
        <FeedSocialHistory token={token} farmData={farmData} />
      </div>
    )
  },
)
export default FeedModal
