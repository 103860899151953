import React from 'react'
import ModalStore from 'store/ModalStore'

const CloseButton = ({ styles }) => {
  return (
    <button
      onClick={() => {
        ModalStore.setIsModalOpen('basic')
      }}
    >
      <img src='/icon/modalCloseButton.png' alt='close' style={styles} />
    </button>
  )
}

export default CloseButton
