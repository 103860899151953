import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'

const PiggyGuideIcon = observer(
  ({ id = 0, level = 1, token, isLoading, type, onClick }) => {
    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <button onClick={onClick}>
          <img
            style={{
              width: '15vw',
              marginTop: '7vw',
              marginLeft: '5vw',
            }}
            alt=''
            src={`/luckyPiggy/${type}.png`}
          />
        </button>
      </div>
    )
  },
)

export default PiggyGuideIcon
