import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import WhiteTextTimer from '../WhiteTextTimer'
import commaNumber from 'comma-number'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const SellerCounponPurchaseModal = observer(
  ({
    setShowModal,
    farmData,
    token,
    selectedSellerCoupon,
    setSelectedSellerCoupon,
    sellerCoupons,
  }) => {
    const [linkForQR, setLinkForQR] = useState()
    const throttleRef = useRef({})

    //getCashbackDealItemsInRandomOrder
    //나중에 위에걸로 랜덤샘플링해서 판매자쿠폰 매대 생성하기

    const CouponComponent = ({ coupon }) => {
      let selected =
        coupon?._id?.toString() === selectedSellerCoupon?._id?.toString()
      return (
        <div
          style={{
            border: selected ? '2px solid #E94343' : '',
            borderRadius: 8,
            marginBottom: 16,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-start',
            backgroundColor: '#fff',
            padding: selected ? 0 : 2,
            height: '25vw',
          }}
          onClick={() => {
            setSelectedSellerCoupon(coupon)
          }}
        >
          <div
            style={{
              flex: 1,
              height: '100%',
              padding: '8px 16px 8px 16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{
                fontFamily: 'Maplestory',
                color: '#E94343',
                fontWeight: 'bold',
                marginBottom: 16,
              }}
            >
              {`${commaNumber(coupon?.couponInfo?.value)}원 할인 쿠폰`}
            </div>
            <div
              style={{
                fontFamily: 'Maplestory',
                color: '#442b22',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                marginBottom: 4,
              }}
            >
              {`${coupon?.sellerName} 전용`}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <WhiteTextTimer
                onTimeEnd={() => setShowModal(false)}
                timeStamp={new Date(coupon?.expiresAt)}
                timerMinutes={0}
                interval={1000}
                style={{
                  fontFamily: 'Maplestory',
                  color: '#442b22',
                  fontSize: '0.9rem',
                }}
                startFromMinutes
                noDays
              />
              <div
                style={{
                  fontFamily: 'Maplestory',
                  color: '#442b22',
                  marginLeft: 4,
                  fontSize: '0.9rem',
                }}
              >
                {`남음`}
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: '#E94343',
              width: '10vw',
              height: '100%',
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          />
        </div>
      )
    }

    const sendToPurchaseScreen = () => {
      AlfarmEventLogger({
        throttleRef,
        locationType: 'modal',
        locationName: ModalStore.isModalOpen,
        eventType: 'click',
        eventName: 'cta',
        data: {},
        collection: 'UserAlfarmClickLog',
      })

      const purchaseRewardSellerCoupon = {
        screen: 'SellerItemsScreen',
        sellerId: selectedSellerCoupon?.condition?.sellerId,
        enteringComponent: 'Alfarm_sellerCouponPurchase',
      }
      window.location.href = `#sellerCouponPurchase.${JSON.stringify(
        purchaseRewardSellerCoupon,
      )}`
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage: 'url(../sellerCouponPurchase/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '32vw',
              paddingBottom: 24,
              // marginBottom: "15%",
            }}
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: '5vw',
                position: 'absolute',
                zIndex: 999,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                marginTop: '2vh',
                // border: "1px solid black",
                width: '100%',
                height: '100%',
                // marginBottom: "12vw",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  lineHeight: '1.4rem',
                  marginBottom: 4,
                  // border: "1px solid black",
                }}
              >
                구매할 때마다 <br />
                <b style={{ color: '#E94343', fontSize: '1.2rem' }}>
                  쿠폰
                </b>과{' '}
                <b style={{ color: '#E94343', fontSize: '1.2rem' }}>물 700g</b>
                을 <br />
                <b style={{ color: '#E94343', fontSize: '1.2rem' }}>무한</b>으로
                받아보세요
              </div>
              <div
                style={{
                  overflowY: 'scroll',
                  scrollbarWidth: 'none',
                  maxHeight: '65vw',
                  // border: "1px solid black",
                }}
              >
                {sellerCoupons?.map((item, i) => (
                  <CouponComponent key={Math.random()} coupon={item} />
                ))}
              </div>

              <div
                style={{
                  background:
                    'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                  width: '60vw',
                  height: '14vw',
                  borderRadius: 99,
                  color: 'black',
                  fontFamily: 'Maplestory',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 8,
                }}
                onClick={sendToPurchaseScreen}
              >
                무한쿠폰 쇼핑하기
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default SellerCounponPurchaseModal
