import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import ModalStore from '../../store/ModalStore'
import ToastStore from 'store/ToastStore'

const BokSentModal = ({ onClick, token, userId, gganbuId, gganbuName }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [gganbuHasBok, setGganbuHasBok] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState()
  const [gganbuInfo, setGganbuInfo] = useState()

  useEffect(() => {
    const getUserInfo = async () => {
      const userInfo = await backendApis.getUserBasicInfobyUID(token, 'GET', {
        uid: userId,
      })
      if (userInfo) {
        setPhoneNumber(userInfo?.phoneNumber)
      }
    }
    const getGganbuInfo = async () => {
      const gganbuInfo = await backendApis.getUserBasicInfobyUID(token, 'GET', {
        uid: gganbuId,
      })
      if (gganbuInfo) {
        setGganbuInfo(gganbuInfo)
      }
      const gganbuBokInfo = await backendApis.getGganbuBok(token, 'GET', {
        bokNumber: gganbuInfo?.phoneNumber,
      })

      if (gganbuBokInfo?.status === 200) {
        setGganbuHasBok(true)
      }
    }

    getUserInfo()
    getGganbuInfo()
  }, [token])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 2001,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.7)',
        }}
      />
      <img
        src='/icon/bokSentModalBackground.png'
        alt=''
        style={{
          position: 'absolute',
          zIndex: 2001,
          top: '16vw',
          left: '5vw',
          width: '90vw',
          height: '155vw',
        }}
      />
      <div
        style={{
          display: 'flex',
          position: 'fixed',
          zIndex: 2001,
          top: '60vw',
          left: '10vw',
          width: '80vw',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
          borderRadius: '0.5rem',
          fontSize: '4vw',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            paddingTop: '5vw',
            fontSize: '5vw',
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
            color: 'black',
          }}
        >
          {`덕담을 전달했어용!\n내게 도착한 덕담도 확인해보세용!`}
        </div>
        <img
          src='/icon/bokSent.png'
          alt=''
          style={{
            width: '50vw',
          }}
        />

        <button
          style={{
            background: '#93CB4E',
            boxShadow: isButtonPressed
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',
            flex: 1,
            zIndex: 2,
            borderRadius: 5,
            width: '80%',
            marginTop: '4vw',
            paddingTop: '4vw',
            paddingBottom: '4vw',
            color: 'white',
            fontFamily: 'maplestory',
            fontSize: '5vw',
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
          onClick={async () => {
            const webUrl = 'https://alwayzwebscreen.ilevit.com/bok-event'
            const redirectSearchParams = new URLSearchParams({
              token: token,
              isLoggedIn: true,
              bokNumber: phoneNumber,
              randomString: Math.random().toString(36).slice(2, 10),
            })
            const prop = {
              screen: 'WebScreen',
              prop: {
                eventPageMapping: {
                  redirectUrl: `${webUrl}/?${redirectSearchParams}`,
                },
              },
            }
            window.location.href = `#navigate.${JSON.stringify(prop)}`
          }}
        >
          나한테 온 덕담 보러가기
        </button>

        <button
          style={{
            zIndex: 2,
            borderRadius: 5,
            width: '80%',
            height: '10%',
            paddingTop: '4vw',
            paddingBottom: '4vw',
            color: 'black',
            fontFamily: 'maplestory',
            fontSize: '4vw',
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
          onClick={async () => {
            if (gganbuHasBok) {
              const webUrl = 'https://alwayzwebscreen.ilevit.com/bok-event'
              const redirectSearchParams = new URLSearchParams({
                token: token,
                isLoggedIn: false,
                bokNumber: gganbuInfo?.phoneNumber,
                randomString: Math.random().toString(36).slice(2, 10),
              })
              const prop = {
                screen: 'WebScreen',
                prop: {
                  eventPageMapping: {
                    redirectUrl: `${webUrl}/?${redirectSearchParams}`,
                  },
                },
              }
              window.location.href = `#navigate.${JSON.stringify(prop)}`
            } else {
              ToastStore.toastOn({
                type: 'emoji',
                message: `친구가 덕담을 아직 확인하지 않았어요.\n친구에게 알려줄게요!`,
                emoji: '😢',
                duration: 1500,
              })
            }

            backendApis.sendBokAlarm(token, 'PUT', {
              gganbuId,
              gganbuName,
            })
            ModalStore.setIsModalOpen('basic')
          }}
        >
          친구가 받은 덕담 구경하기
        </button>
      </div>
      <button
        style={{
          width: '8%',
          top: '20vw',
          right: '6%',
          zIndex: 2001,
          position: 'absolute',
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img src='/icon/modalCloseButton.png' alt='' />
      </button>
    </>
  )
}

export default BokSentModal
