import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import commaNumber from 'comma-number'
import indexStore from '../../../store/indexStore'
import Layout from '../layout'
import LoadingIndicator from '../../../comps/loadingIndicator'
import { isPurchaseUser } from 'utils/utils'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import timeChecker from 'utils/timeChecker'
import ABStore from 'store/ABStore'
import AlfarmAdStore from 'store/AlfarmAdStore'

const QuizModalV2 = observer(
  ({ token, farmData, setFarmData, setQuizPurchaseButtonOn, itemType }) => {
    const { ModalStore, UserStore } = indexStore()
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const [mainQuizData, setMainQuizData] = useState(null)
    const [subQuizDataList, setSubQuizDataList] = useState(null)
    const [mainQuizId, setMainQuizId] = useState(null)
    const [subQuizIdList, setSubQuizIdList] = useState(null)
    const [itemIdList, setItemIdList] = useState([])
    const [selectCorrect, setSelectCorrect] = useState(0)
    const [questionMarkToast, setQuestionMarkToast] = useState(false)
    const [quizResultModal, setQuizResultModal] = useState(false)
    const [quizResult, setQuizResult] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [noQuiz, setNoQuiz] = useState(false)
    const codePushVersionAvailable = useCheckCodePushVersion()
    const [canSolveQuiz, setCanSolveQuiz] = useState(true)
    const [selectable, setSelectable] = useState(false)
    const [reward, setReward] = useState(10)
    const [isBrowsing, setIsBrowsing] = useState(null)
    const [userCodePushVersion, setUserCodePushVersion] = useState(
      window.localStorage.getItem('userCodePushVersionNew'),
    )
    const codePushVersionForAdmob = codePushVersionAvailable(UserStore?.codePushVersionFromAlwayzApp, '6.1.5')
    const quizRewardedAdCheck = UserStore.cherryPickerForAd &&
      (!codePushVersionForAdmob || (codePushVersionForAdmob && !ABStore?.admobAlfarmTester))
        

    const quizRewardTester =
      ABStore.quizRewardEnhanceTester && !UserStore.cherryPickerForAd

    useEffect(() => {
      getQuiz()
      //모달 열리자마자 선택지 눌리는 것 방지
      setTimeout(() => {
        setSelectable(true)
      }, 200)
    }, [])

    // 광고 데이터 세팅
    useEffect(() => {
      setMainQuizId(mainQuizData?._id)
      setSubQuizIdList(
        [subQuizDataList?.[0]?._id, subQuizDataList?.[1]?._id]?.filter(
          (e) => e,
        ),
      )
      const mainQuizItemIdList =
        mainQuizData?.itemIds ||
        (mainQuizData?.itemId ? [mainQuizData?.itemId] : [])
      const subAdItem1IdList =
        subQuizDataList?.[0]?.itemIds ||
        (subQuizDataList?.[0]?.itemId ? [subQuizDataList?.[0]?.itemId] : [])
      const subAdItem2IdList =
        subQuizDataList?.[1]?.itemIds ||
        (subQuizDataList?.[1]?.itemId ? [subQuizDataList?.[1]?.itemId] : [])
      const combinedItemIds = [
        ...mainQuizItemIdList,
        ...subAdItem1IdList,
        ...subAdItem2IdList,
      ]
      setItemIdList(combinedItemIds)
    }, [mainQuizData, subQuizDataList])

    // 현재 상태 파악 -> 퀴즈 가능 여부 & 퀴즈 데이터 세팅
    const getQuiz = async () => {
      if (isLoading) return
      setIsLoading(true)
      try {
        // 오늘 퀴즈 깬 기록 확인
        const filteredQuizHistory = farmData?.solvedQuizHistory?.filter((x) => {
          return !timeChecker(x.solvedAt)
        })
        // 오늘 하루 치 맥스 n개(AB store) 다 했으면 -> setNoQuiz
        if (
          filteredQuizHistory?.length >=
          AlfarmAdStore.quizAdDailyMaxExposureCount
        ) {
          setNoQuiz(true)
          setCanSolveQuiz(false)
        } else {
          // 오늘 본 거 최대치 미만이면 -> 새로운 퀴즈 불러오기 + 많이 찬 광고 종료
          const result = await backendApis.getQuizV2(token)
          if (result?.data?.success) {
            setMainQuizData(result?.data?.mainQuizData)
            setSubQuizDataList(result?.data?.subQuizDataList)
            setCanSolveQuiz(true)
            setNoQuiz(false)
          } else {
            // 볼 수 있는 광고 데이터 없을 때
            setNoQuiz(true)
            setCanSolveQuiz(false)
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    }

    const Choice = [
      mainQuizData?.quizChoice?.one,
      mainQuizData?.quizChoice?.two,
      mainQuizData?.quizChoice?.three,
    ]

    const OptionButton = ({ selectedCorrect = 0, choice = 0 }) => {
      return (
        <button
          style={{
            color: 'black',
            width: '100%',
            fontSize: '4.5vw',
            lineHeight: '150%',
            background: 'white',
            padding: '2.5vw',
            fontFamily: 'maplestory',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            borderRadius: '3vw',
            margin: 0,
            border:
              selectCorrect === selectedCorrect
                ? '0.7vw solid #402C24'
                : '0.7vw solid #DEDEE3',
            marginBottom: '2vw',
          }}
          onClick={() => {
            if (!selectable) return
            setSelectCorrect(selectedCorrect)
          }}
        >
          &nbsp;&nbsp;&nbsp;{Choice[choice]}
        </button>
      )
    }

    const AnswerButton = () => {
      if (selectCorrect !== 0) {
        return (
          <div
            style={{
              background: isButtonPressed ? '#F7C82A' : '#F7C82A',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              width: '55vw',
              height: '14vw',
              borderRadius: 99,
              color: 'black',
              fontFamily: 'maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '4.5vw',
              position: 'absolute',
              bottom: '8vw',
              right: '10vw',
            }}
            onClick={() => {
              if (canSolveQuiz) {
                postAnswer()
              }
            }}
          >
            정답 입력하기
          </div>
        )
      } else if (selectCorrect === 0) {
        return (
          <div
            style={{
              background: '#D3D7DD',
              width: '55vw',
              height: '14vw',
              borderRadius: 99,
              color: '#707070',
              fontFamily: 'maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '4.5vw',
              position: 'absolute',
              bottom: '8vw',
              right: '10vw',
            }}
          >
            정답을 입력해주세요
          </div>
        )
      }
    }

    const postAnswer = async () => {
      if (canSolveQuiz === false) return
      // 정답 맞췄는지 여부 & 보상 액수 가져옴
      const result = await backendApis.postAnswerV3(token, 'POST', {
        answer: selectCorrect,
        quizId: mainQuizId,
        subQuizIdList: subQuizIdList,
        isRewardTester: quizRewardTester,
      })
      // farmData 셀프 업데이트
      if (result?.status === 200) {
        setCanSolveQuiz(false)
        let temp = { ...farmData }
        const newSolvedQuiz = {
          quizId: mainQuizId,
          subQuizId1: subQuizIdList?.[0] ?? null,
          subQuizId2: subQuizIdList?.[1] ?? null,
          itemId: mainQuizData?.itemId,
          solvedAt: new Date(),
          reward: result?.data?.reward || 10,
          isCorrect: result?.data?.isCorrect,
          isBrowsing: false,
        }
        if (farmData?.solvedQuizHistory) {
          temp.solvedQuizHistory = [
            ...farmData.solvedQuizHistory,
            newSolvedQuiz,
          ]
        } else {
          temp.solvedQuizHistory = [newSolvedQuiz]
        }

        setFarmData(temp)
        setReward(result?.data?.reward || 10)
        setQuizResult(result?.data)
        setQuestionMarkToast(true)
        setTimeout(() => {
          setQuestionMarkToast(false)
          setQuizResultModal(true)
          setQuizPurchaseButtonOn(true)
        }, 1500)
      }
    }

    const sendToPurchaseScreen = () => {
      ModalStore.setIsModalOpen('basic')
      window.location.href = `#generalNavigator.${JSON.stringify({
        screen: 'AlfarmAdPurchaseScreen',
        params: {
          title: '올팜',
          image:
            'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png',
          ratio: 99 / 360,
          enteringComponent: 'Alfarm_quizPurchase',
          adId: mainQuizId,
          subAdId: subQuizIdList,
          itemIds: itemIdList,
          questReward: reward,
          isTimerOn: !isBrowsing,
          questRewardType: 'water',
          questRewardText: `초간 구경하면 물 ${reward}g을 받아요!`,
          questTimer: 0.5,
        },
      })}`
    }

    const sendToVideoAdScreen = () => {
      ModalStore.setIsModalOpen('basic')
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'showRewardedAd',
          data: { adType: 'rewarded', placement: 'ALFARM_QUIZ_MISSION_REWARD' },
        }),
      )
    }

    const PurchaseButton = () => {
      let buttonText = ''
      if (!quizRewardedAdCheck) {
        buttonText = `구경하고 물 ${reward}g 받기`
      } else if (quizRewardedAdCheck) {
        if (UserStore.isRewardAdReady) {
          buttonText = `영상 보고 물 ${reward}g 받기`
        } else {
          buttonText = `광고 준비 중`
        }
      }
      return (
        <div
          style={{
            background: isButtonPressed ? '#F7C82A' : '#F7C82A',
            boxShadow: isButtonPressed
              ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
              : '',
            width: '70vw',
            height: '14vw',
            borderRadius: 99,
            color: 'black',
            fontFamily: 'maplestory',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '5vw',
            position: 'absolute',
            bottom: '6vw',
            left: '10vw',
          }}
          onClick={() => {
            // 체리피커 아니면 판매 페이지 보내기
            if (!quizRewardedAdCheck) {
              sendToPurchaseScreen()
            } else if (quizRewardedAdCheck) {
              // 체리피커인데 광고 준비 됐으면 영상 광고 보여주기
              if (UserStore.isRewardAdReady) {
                sendToVideoAdScreen()
              } else {
                alert('광고 준비 중이에요! 잠시 후 다시 시도해주세요.')
              }
            }
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
        >
          {buttonText}
        </div>
      )
    }

    const ModalBody = () => {
      if (noQuiz) {
        return (
          <>
            <button
              style={{
                width: '10%',
                transform: 'translate(-50%, -50%)',
                right: '2%',
                top: '3%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <img src='/quiz/noQuiz.png' alt='abc' style={{ width: '90vw' }} />
          </>
        )
      }
      if (!questionMarkToast && !quizResultModal) {
        return (
          <>
            <button
              style={{
                width: '10%',
                transform: 'translate(-50%, -50%)',
                right: '2%',
                top: '3%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <img src='/quiz/modal.png' alt='abc' style={{ width: '90vw' }} />
            <div
              style={{
                transform: 'translate(-50%, -50%)',
                left: '50%',
                top: '37%',
                position: 'absolute',
                color: 'red',
                width: '80%',
                fontSize: '5vw',
              }}
            >
              퀴즈
              <div
                style={{
                  marginTop: '2vw',
                  position: 'absolute',
                  color: 'black',
                  width: '100%',
                  fontSize: '5vw',
                  lineHeight: '150%',
                  wordWrap: 'break-word',
                  wordBreak: 'keep-all',
                }}
              >
                <div
                  style={{
                    maxHeight: '26vw',
                    color: 'black',
                    fontSize: '4.5vw',
                    overflowY: 'scroll',
                    lineHeight: '140%',
                    borderRadius: '2vw',
                  }}
                >
                  {mainQuizData?.quizDescription}
                </div>
                <div
                  style={{
                    marginTop: '4vw',
                  }}
                >
                  <OptionButton selectedCorrect={1} choice={0} />
                  <OptionButton selectedCorrect={2} choice={1} />
                  <OptionButton selectedCorrect={3} choice={2} />
                </div>
              </div>
            </div>
            {AnswerButton()}
            <button
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '4.5vw',
                position: 'absolute',
                bottom: '7vw',
                left: '6vw',
              }}
              //힌트 보기
              onClick={() => {
                const purchaseData = {
                  enteringComponent: 'Alfarm_quizPurchase',
                  itemId: mainQuizData?.itemId,
                  isTimerOn: false,
                  source:
                    'https://assets.ilevit.com/9a9337c5-267b-4ef5-8db1-d6eec3c017c9.png',
                }
                window.location.href = `#quizPurchaseReward.${JSON.stringify(
                  purchaseData,
                )}`
              }}
            >
              <img
                src='/quiz/hintIcon.png'
                alt='abc'
                style={{ width: '15vw' }}
              />
            </button>
          </>
        )
      } else if (questionMarkToast && !quizResultModal) {
        return (
          <div className='wobble-hor-bottom'>
            <img
              src='/quiz/questionMark.png'
              alt='abc'
              style={{ width: '25vw' }}
            />
          </div>
        )
      } else if (!questionMarkToast && quizResultModal && quizRewardedAdCheck) {
        // 체리피커에게 영상 광고 모달 띄우기
        return (
          <>
            <button
              style={{
                width: '10%',
                transform: 'translate(-50%, -50%)',
                right: '2%',
                top: '-12%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            {/* 정답 여부에 따라 모달 모양 달라짐 */}
            {quizResult?.isCorrect ? (
              <img
                src='/quiz/correctModal.png'
                alt='abc'
                style={{ width: '90vw' }}
              />
            ) : (
              <img
                src='/quiz/wrongModal.png'
                alt='abc'
                style={{ width: '90vw' }}
              />
            )}
            <div
              style={{
                transform: 'translate(-50%, -50%)',
                left: '50%',
                top: '45%',
                position: 'absolute',
                color: 'black',
                width: '90%',
                fontSize: '4.5vw',
                textAlign: 'center',
                lineHeight: '150%',
                marginTop: '8vw',
              }}
            >
              {/* 정답 여부에 따라 문구 다름 */}
              {quizResult?.isCorrect ? (
                <>
                  정답을 맞췄어요!
                  <br />
                  영상 광고를 보면 물 {reward}g을 받아요
                </>
              ) : (
                <>
                  {' '}
                  아쉽지만 정답이 아니에요 <br />
                  영상 광고를 보면 물 {reward}g을 받아요
                </>
              )}
              <img
                src='/quiz/rewardVideoAdWaterIconForQuiz.png'
                alt='abc'
                style={{ width: '45vw', marginLeft: '26%', marginTop: '2vw' }}
              />
            </div>
            {PurchaseButton()}
          </>
        )
      } else if (
        !questionMarkToast &&
        quizResultModal &&
        !quizRewardedAdCheck
      ) {
        return (
          <>
            <button
              style={{
                width: '10%',
                transform: 'translate(-50%, -50%)',
                right: '2%',
                top: '-12%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            {quizResult?.isCorrect ? (
              <img
                src='/quiz/correctModal.png'
                alt='abc'
                style={{ width: '90vw' }}
              />
            ) : (
              <img
                src='/quiz/wrongModal.png'
                alt='abc'
                style={{ width: '90vw' }}
              />
            )}
            <div
              style={{
                transform: 'translate(-50%, -50%)',
                left: '50%',
                top: '45%',
                position: 'absolute',
                color: 'black',
                width: '90%',
                fontSize: '4.5vw',
                textAlign: 'center',
                lineHeight: '150%',
              }}
            >
              {quizResult?.isCorrect ? (
                <>
                  정답을 맞췄어요!
                  <br />
                  상품을 30초 구경하면 물 {reward}g을 받아요
                </>
              ) : (
                <>
                  아쉽지만 정답이 아니에요 <br />
                  상품을 30초 구경하면 물 {reward}g을 받아요
                </>
              )}
              <img
                src='/quiz/rewardImage.png'
                alt='abc'
                style={{ width: '25vw', marginLeft: '35%', marginTop: '2vw' }}
              />
              <div
                style={{
                  marginTop: '2vw',
                  position: 'absolute',
                  color: 'black',
                  width: '100%',
                  fontSize: '4.5vw',
                  lineHeight: '150%',
                }}
              >
                퀴즈 상품을 구매하면 <br />물{' '}
                {commaNumber(mainQuizData?.reward?.water)}g과 고급비료{' '}
                {mainQuizData?.reward?.fertilizerSet}개를 드려요
              </div>
            </div>
            {PurchaseButton()}
          </>
        )
      }
    }

    return (
      <>
        <Layout isLoading={isLoading}> </Layout>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div className='w-[90vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
            {ModalBody()}
          </div>
        </div>

        <LoadingIndicator isLoading={isLoading} />
      </>
    )
  },
)

export default QuizModalV2
