import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import { observer } from 'mobx-react-lite'
import Layout from '../comps/atoms/layout'
import GganbuWithEventIcons from '../comps/atoms/gganbuWithEventIcons'
import SellerStore from '../comps/molecules/sellerStore'
import LoadingIndicator from '../comps/loadingIndicator'
import { NavLink } from 'react-router-dom'
import GganbuFarmQuickButton from '../comps/atoms/GganbuFarmQuickButton'
import indexStore from '../store/indexStore'
import GetResourceModal from '../comps/atoms/getResourceModal'
import WhiteTextTimer from '../comps/WhiteTextTimer'
import SellerStoreNew from 'comps/molecules/sellerStoreNew'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import { API_URI } from 'utils/constant'
import UserStore from 'store/UserStore'
import ABStore from 'store/ABStore'

const fetcher = async (url, token, method = 'GET', params = {}) => {
  const resource = method === 'GET' ? url + new URLSearchParams(params) : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init['method'] = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)
    const data = await res.json()
    return data
  } catch (err) {
    return null
  }
}

const SellerFarm = observer(({}) => {
  const { ModalStore, TimeStore } = indexStore()
  const [farmData, setFarmData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const gganbuId = useSearchParam('gganbuId')
  const sellerStoreIds = useSearchParam('sellerStoreIdList')
  const sellerStoreIdList = sellerStoreIds ? sellerStoreIds.split(',') : []
  const [sellerFarmList, setSellerFarmList] = useState([])
  const [mainStoreData, setMainStoreData] = useState(null)
  const [subStoreData1, setSubStoreData1] = useState(null)
  const [subStoreData2, setSubStoreData2] = useState(null)
  const [canClickGetWater, setCanClickGetWater] = useState(false)
  const [lastGetWaterFromSellerFarm, setLastGetWaterFromSellerFarm] = useState(
    new Date(),
  )
  const [visitItemToast, setVisitItemToast] = useState(false)
  const [getResourceModal, setGetResourceModal] = useState(false)
  const [addWater, setAddWater] = useState(0)
  const [gganbuData, setGganbuData] = useState(
    JSON.parse(window.localStorage.getItem('gganbuData')) || null,
  )
  const [beforeVisitItem, setBeforeVisitItem] = useState(false)
  const [userCodePushVersion, setUserCodePushVersion] = useState(
    window.localStorage.getItem('userCodePushVersionNew'),
  )
  const [isValidCodePushVersion, setIsValidCodePushVersion] = useState(false)
  const codePushVersionAvailable = useCheckCodePushVersion()
  const [isWorking, setIsWorking] = useState(false)

  let hidden, visibilityChange
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  useEffect(() => {
    getAlfarmData(token, farmData)
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        if (document.visibilityState === 'visible') {
          setIsLoading(true)
          setTimeout(() => {
            if (document.visibilityState === 'visible') {
              getAlfarmData(token, farmData)
            }
          }, 500)
        }
      },
      false,
    )
  }, [])

  useEffect(() => {
    if (farmData?.waterFromSellerFarm === undefined) {
      setTimeout(() => {
        setCanClickGetWater(true)
      }, 300)
    }
  }, [])

  useEffect(() => {
    getStoreByAdIdList(sellerStoreIdList)
  }, [farmData?.userId])

  useEffect(() => {
    if (codePushVersionAvailable(userCodePushVersion, '4.3.69')) {
      setIsValidCodePushVersion(true)
    } else {
      setIsValidCodePushVersion(false)
    }
  }, [userCodePushVersion])

  useEffect(() => {
    if (farmData && mainStoreData) {
      setIsLoading(false)
      setIsVisitedSellerStore()
    }
  }, [mainStoreData, farmData])

  useEffect(() => {
    lastVisitedSellerStore(token)
  }, [farmData, isValidCodePushVersion])

  //gep 데이터를 통해 마지막 광고 시청 시간 확인 & 4시간 주기로 광고 오픈
  useEffect(() => {
    if (farmData?.waterFromSellerFarm) {
      setLastGetWaterFromSellerFarm(
        new Date(
          farmData?.waterFromSellerFarm[
            farmData?.waterFromSellerFarm?.length - 1
          ]?.rewardedAt,
        ),
      )
      if (
        new Date() -
          new Date(
            farmData?.waterFromSellerFarm[
              farmData?.waterFromSellerFarm?.length - 1
            ]?.rewardedAt,
          ) <
        1000 * 60 * 60 * 4
      ) {
        setCanClickGetWater(false)
      } else {
        setCanClickGetWater(true)
      }
    }
  }, [farmData, canClickGetWater])

  // 올팜 gep 데이터 가져옴
  const getAlfarmData = async (token, farmData) => {
    if (token && !farmData?.userId) {
      fetcher(`/games/start-alfarm`, token, 'POST', {
        itemType: itemType,
      }).then((data) => {
        if (data?.userId) {
          setFarmData(data)
        } else {
          alert('정보를 불러오지 못했어요. 잠시 후 다시 시도해 주세요.')
        }
      })
    }
  }

  const getStoreByAdIdList = async () => {
    const result = await backendApis.getStoreByAdIdList(token, 'GET', {
      sellerStoreIdList: sellerStoreIdList,
    })

    if (result?.status === 200) {
      setSellerFarmList(result?.data)
      setMainStoreData(result?.data?.[0])
      setSubStoreData1(result?.data?.[1])
      setSubStoreData2(result?.data?.[2])
    }
  }

  // 첫 방문 시 gep 세팅 & 팝업
  const setIsVisitedSellerStore = async () => {
    if (!farmData?.isVisitedSellerStore) {
      await backendApis.updateSpecificField({
        fieldName: 'isVisitedSellerStore',
        value: true,
      })
      ModalStore.setIsModalOpen('firstVisitedSellerFarm')
    }
  }

  // localStorage에 있는 셀러 광고 아이디 가져오기 -> sellerStore에서 세팅한 값임 -> lastvisited에 저장
  async function lastVisitedSellerStore(token) {
    const result = await backendApis.getLastVisitedSellerStoreId(token)
    const lastVisitedSellerStoreId = result?.data?.lastVisitedSellerStoreId
    let localStoreData
    try {
      localStoreData = JSON.parse(
        window.localStorage.getItem('alfarmSellerStore'),
      )
    } catch (error) {
      console.error('Error parsing local storage data:', error)
    }
    if (isValidCodePushVersion) {
      if (lastVisitedSellerStoreId !== sellerStoreIdList?.[0]) {
        setBeforeVisitItem(true)
      } else {
        setBeforeVisitItem(false)
      }
    } else {
      if (
        localStoreData?.sellerStoreId !== sellerStoreIdList?.[0] ||
        !localStoreData
      ) {
        setBeforeVisitItem(true)
      }
    }
  }

  const waterFromSellerFarmForTripleExposure = async () => {
    if (isWorking) return
    setIsWorking(true)
    ABStore.initialize({ userId: farmData?.userId, farmData })
    if (canClickGetWater) {
      if (beforeVisitItem) {
        setVisitItemToast(true)
        setTimeout(() => {
          setVisitItemToast(false)
        }, 3000)
        setIsWorking(false)
        return
      }
      setCanClickGetWater(false)
      // 보상 주고, gep 기록하고, 로그 히스토리 업데이트하고, 광고 카운트 올리고, 광고 종료하고
      const result = await backendApis.waterFromSellerFarmForTripleExposure(
        token,
        'POST',
        {
          sellerStoreIdList: sellerStoreIdList,
          rewardAdjust:
            ABStore.sellerFarmRewardEnhanceTester &&
            !UserStore.cherryPickerForAd, // 보상크기 결정시키는 요소
        },
      )

      let tmpFarmData = farmData
      if (UserStore.waterExceeded) {
        tmpFarmData.water += Math.round(result?.data?.water * 0.5)
        setAddWater(Math.round(result?.data?.water * 0.5))
      } else {
        tmpFarmData.water += result?.data?.water
        setAddWater(result?.data?.water)
      }
      setFarmData(tmpFarmData)
      setGetResourceModal(true)
      setTimeout(() => {
        setGetResourceModal(false)
      }, 1500)
      // 임시로 팜데이터 업데이트
      let temp = { ...farmData }
      if (farmData?.waterFromSellerFarm) {
        temp.waterFromSellerFarm.push({
          sellerStoreId: sellerStoreIdList?.[0],
          subSellerStoreId1: sellerStoreIdList?.[1],
          subSellerStoreId2: sellerStoreIdList?.[2],
          rewardedAt: new Date(),
        })
      } else {
        Object.assign(temp, {
          waterFromSellerFarm: [
            {
              sellerStoreId: sellerStoreIdList?.[0],
              subSellerStoreId1: sellerStoreIdList?.[1],
              subSellerStoreId2: sellerStoreIdList?.[2],
              rewardedAt: new Date(),
            },
          ],
        })
      }
      setCanClickGetWater(false)
      setIsWorking(false)
    }
  }

  return (
    <>
      <Layout isLoading={isLoading}>
        <header>
          <div
            style={{
              paddingTop: '10vw',
              position: 'absolute',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              zIndex: 1,
            }}
            className='inset-top'
          >
            <NavLink to={`/farmPage?token=${token}&itemType=${itemType}`}>
              <button
                style={{
                  width: '7vw',
                  marginLeft: '2vw',
                  paddingTop: '3vw',
                }}
                action='goBack'
              >
                <img src='/icon/Back.png' alt='' />
              </button>
            </NavLink>
          </div>
        </header>

        <div
          style={{
            position: 'absolute',
            bottom: '22vw',
          }}
        >
          {isValidCodePushVersion ? (
            <SellerStoreNew
              token={token}
              mainStoreData={mainStoreData}
              subStoreData1={subStoreData1}
              subStoreData2={subStoreData2}
              farmData={farmData}
              sellerStoreId={sellerStoreIdList?.[0]}
            />
          ) : (
            <SellerStore
              token={token}
              mainStoreData={mainStoreData}
              subStoreData1={subStoreData1}
              subStoreData2={subStoreData2}
              farmData={farmData}
              sellerStoreId={sellerStoreIdList?.[0]}
            />
          )}
          <div
            style={{
              position: 'absolute',
              bottom: '1vw',
              left: '1vw',
              zIndex: 7,
            }}
          >
            <NavLink to={`/farmPage?token=${token}&itemType=${itemType}`}>
              <GganbuFarmQuickButton action='backToFarm' />
            </NavLink>
            <div
              style={{
                position: 'absolute',
                bottom: '0vw',
                left: '18.7vw',
                zIndex: 7,
              }}
            >
              {canClickGetWater ? (
                <div onClick={waterFromSellerFarmForTripleExposure}>
                  <GganbuFarmQuickButton action='getWaterFromGganbu' />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '-2vw',
                      right: '-13vw',
                    }}
                    className='wobble-ver-left'
                  >
                    <img
                      style={{
                        width: '35vw',
                      }}
                      src='/sellerFarm/mark.png'
                      alt=''
                    />
                  </div>
                </div>
              ) : (
                <>
                  <GganbuFarmQuickButton action='alreadyGetWaterFromGganbu' />
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: 8,
                      fontFamily: 'maplestory',
                      fontSize: 14,
                      textShadow:
                        '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                    }}
                    className='absolute center gganbu timer'
                  >
                    <WhiteTextTimer
                      onTimeEnd={() => {
                        setCanClickGetWater()
                      }}
                      timeStamp={
                        farmData?.waterFromSellerFarm
                          ? lastGetWaterFromSellerFarm
                          : new Date()
                      }
                      timerMinutes={4 * 60}
                      color='white'
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <GganbuWithEventIcons
            token={token}
            itemType={itemType}
            gganbuData={gganbuData}
            gganbuId={gganbuId}
            sellerFarmList={sellerFarmList}
            sellerStoreId={sellerStoreIdList?.[0]}
            farmData={farmData}
            canClickGetWater={canClickGetWater}
          />
        </div>
        {getResourceModal && (
          <GetResourceModal
            amount={addWater}
            setGetResourceModal={setGetResourceModal}
          />
        )}
      </Layout>
      <LoadingIndicator isLoading={isLoading} />
      {ModalStore.isModalOpen === 'firstVisitedSellerFarm' && (
        <>
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 5004,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }}
          >
            <div
              style={{
                width: '80vw',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -55%)',
                zIndex: 5005,
              }}
            >
              <button
                style={{
                  width: '8vw',
                  right: '2vw',
                  top: '-13vw',
                  position: 'absolute',
                  zIndex: 5004,
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('basic')
                }}
              >
                <img
                  style={{
                    zIndex: 5004,
                  }}
                  src='/icon/modalCloseButton.png'
                  alt=''
                />
              </button>
              <img
                style={{
                  zIndex: 5004,
                }}
                src='/sellerFarm/firstModal.png'
                alt=''
              />
            </div>
          </div>
        </>
      )}
      {visitItemToast && (
        <div
          style={{
            flex: 1,
            position: 'absolute',
            top: '60%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 2000,
            width: '80%',
            padding: '4vw',
            background: 'rgba(0,0,0,0.85)',
            borderRadius: '4vw',
            fontFamily: 'maplestory',
            fontSize: '4.5vw',
            textAlign: 'center',
            lineHeight: '180%',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          상품을 구경하면
          <div style={{ height: '2vw' }} />
          물을 받을 수 있어요
          <div style={{ height: '2vw' }} />
          상품을 구경하고 물을 받아보세요!
        </div>
      )}
    </>
  )
})

export default SellerFarm
