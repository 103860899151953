import React from 'react'
import useCheckCodePushVersion from '../../utils/customHooks/useConvertCodePushVersion'
import WhiteTextTimer from '../WhiteTextTimer'
import dayjs from 'dayjs'
import ModalStore from '../../store/ModalStore'
import TimeStore from '../../store/TimeStore'
import { useNavigate } from 'react-router-dom'

const sprite = [
  {
    type: 'gganbuRecommendation',
    img: () => `/gganbuRecommendation/entryIcon.png`,
    display: ({ farmData }) => farmData?.giveWaterCount > 10,
    onClick: () => {
      ModalStore.setIsModalOpen('gganbuRecommendation')
    },
    text: () => `추천`,
  },
  {
    type: 'requestGganbu',
    img: () => '/requestGganbu/entryIcon.png',
    display: ({ farmData }) => {
      let temp = farmData?.gganbuRequestList?.filter((item) => !item?.deletedAt)
      return temp?.length > 0
    },
    codepush: '2.3.77',
    onClick: () => {
      ModalStore.setIsModalOpen('requestGganbu')
    },
    shake: true,
  },

  {
    type: 'phoneBookGganbu',
    img: () => `/phoneBookGganbu/entryIconv3.png`,
    display: ({ farmData }) =>
      farmData?.giveWaterCount > 12 &&
      farmData?.gganbuList?.filter((each) => !each?.deletedAt)?.length < 20,
    codepush: '2.1.12',
    onClick: ({}) => {
      window.location.href = `#alfarmCheckContactsPermission`
    },
    text: () => `보상가득`,
  },
  {
    type: 'guessMe',
    img: () => `/guessMe/entryIconv3.png`,
    display: ({ farmData, userId }) => {
      let cond1 = farmData?.giveWaterCount > 50
      let cond2 = Number(dayjs().format('HH')) >= 7
      return cond1 && cond2
    },
    onClick: () => {
      ModalStore.setIsModalOpen('guessMe')
    },
    text: () => `매일 보상`,
  },
  {
    type: 'friendGarden',
    img: () => `/friendGarden/entryIconv3.png`,
    display: ({ farmData }) => farmData?.giveWaterCount > 50,
    onClick: () => {},
    text: () => `고급비료`,
  },
  {
    type: 'dailyInvite',
    img: () => `/bottomIcon/inviteFriendsBottomIconv3.png`,
    display: ({ farmData }) => farmData?.giveWaterCount > 30,
    codepush: '2.0.1',
    onClick: () => {
      ModalStore.setIsModalOpen('dailyInvite')
    },
    text: () => `매일가능`,
  },
  //
  {
    type: 'roulette',
    img: () => `/icon/bottomEventIcon/roulette.png`,
    display: ({ farmData }) => farmData?.giveWaterCount >= 40,
    codepush: '1.6.26',
    onClick: () => ModalStore.setIsModalOpen('roulette'),
    text: () => `매일가능`,
  },
  {
    type: 'vip',
    img: () => `/icon/bottomEventIcon/vip.png`,
    display: ({ farmData }) => farmData?.giveWaterCount > 30,
    codepush: '2.0.1',
    onClick: () => {
      ModalStore.setIsModalOpen('vipCard')
    },
    text: () => `고급비료`,
  },
  // {
  //   type: "harvestInvite",
  //   img: () => `/icon/bottomEventIcon/harvestInvite.png`,
  //   display: ({ farmData }) => farmData?.giveWaterCount > 50,
  //   onClick: () => {
  //     ModalStore.setIsModalOpen("harvestInvite");
  //   },
  //   // text: () => `고급비료`,
  // },
]

const IconButton = ({ data, props }) => {
  const navigate = useNavigate()

  const codePushVersionAvailable = useCheckCodePushVersion()
  const {
    img,
    display,
    codepush,
    onClick,
    timer = null,
    text = null,
    largeText = null,
    type,
    shake,
  } = data

  const available =
    codePushVersionAvailable(props?.userCodePushVersion, codepush) &&
    display(props)

  const src = img(props)

  let content = false
  if (timer && timer(props) > TimeStore.currentNewDate) {
    content = (
      <WhiteTextTimer
        style={{ fontSize: '3vw' }}
        timeStamp={timer(props)}
        timerMinutes={0}
      />
    )
  }
  if (text) content = <div style={{ fontSize: '3vw' }}>{text(props)}</div>
  if (largeText)
    content = <div style={{ fontSize: '3.5vw' }}>{largeText(props)}</div>

  if (!available) {
    return null
  }
  if (available) {
    return (
      <button
        className={shake ? 'shake-bottom' : ''}
        style={{
          margin: 0,
          marginTop: 4,
          marginRight: 4,
        }}
        onClick={() => {
          if (type === 'friendGarden') {
            navigate(
              `../friendGarden?token=${props?.token}&itemType=${props?.itemType}`,
            )
          }
          // BottomSheetStore.setIsbottomSheetOpen("basic");

          onClick(props)
        }}
      >
        {content && (
          <div style={{ position: 'relative' }}>
            <div
              style={{
                fontFamily: 'maplestory',
                position: 'absolute',
                textAlign: 'left',
                background: '#EA3323',
                color: 'white',
                borderRadius: '5vw',
                paddingTop: '1vw',
                paddingBottom: '1vw',
                paddingRight: '2vw',
                paddingLeft: '2vw',
                right: '0vw',
                top: '-1vw',
              }}
            >
              {content}
            </div>
          </div>
        )}
        <img
          style={{
            width: '18vw',
            height: '17vw',
          }}
          alt=''
          src={src}
        />
      </button>
    )
  }
}

const FeedQuickLink = (props) => {
  return (
    <div className='mx-[16px] flex flex-col text-[3.5vw] text-[#000]'>
      <div className='text-[#000] mb-[8px] mt-[4px] font-bold'>
        친구와 함께!
      </div>
      <div className='relative overflow-x-scroll flex flex-row'>
        {sprite.map((each) => (
          <IconButton key={each.type} data={each} props={props} />
        ))}
      </div>
    </div>
  )
}

export default FeedQuickLink
