import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import dayjs from 'dayjs'
import ModalStore from '../../store/ModalStore'
import TimeStore from 'store/TimeStore'
import UserStore from 'store/UserStore'
import moment from 'moment'
import PlantBasicInfo from 'comps/molecules/PlantBasicInfo'
import { farmingList } from '../../config'

import apiHandler from 'utils/apiHandler'

const NewUserInstantHarvestModal = observer(
  ({ token, farmData, setFarmData, loadUserCoupon }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const [untilAt, setUntilAt] = useState(7)
    const [targetShareCount, setTargetShareCount] = useState(30)

    useEffect(() => {
      setUntilAt(
        moment(UserStore?.farmData?.createdAt)
          .add(7, 'd')
          .diff(TimeStore?.currentMoment, 'd'),
      )
      setTargetShareCount(50)
    }, [UserStore?.farmData?.createdAt])

    const shareKakao = () => {
      let imageUrl =
        'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png'
      let title = '[올팜] 요즘 핫한 무료로 작물 주는 앱!'
      let description = '작물 키우기만 하면 공짜로 받아요.'
      let templateId = 102068

      apiHandler.kakaoLinkShare({
        shareType: 'Alfarm_linkShare_newUserInstantHarvest',
        title,
        description,
        imageUrl,
        payload: {},
        templateId,
      })
    }

    const CTA = () => {
      return (
        <button
          className='text-[4.5vw] px-[6vw] rounded-3xl py-[4vw] z-[2000] text-[#442B22] mt-[4vw] font-bold mb-[6vw] pulsate-fwd'
          style={{
            background: isButtonPressed
              ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressed
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
            shareKakao()
          }}
        >
          {`카톡 공유하고 바로 수확하기`}
        </button>
      )
    }
    return (
      <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
        <div
          className='w-[90vw] h-[150vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex absolute justify-between items-center flex-col px-[16px] pt-[28vw] pb-[16px]'
          style={{
            backgroundImage: 'url(../newUserInstantHarvest/background.png)',
          }}
        >
          <button
            className='z-[1101] w-[8%] -top-[10vw] right-[4vw] absolute'
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
          </button>

          <div className='flex flex-col items-center justify-center w-full'>
            <div className='font-bold text-[#442B22] mb-[2vw] mt-[4vw]'>
              {`친구들에게 카카오톡 공유만해도`}
            </div>
            <div className='font-bold text-[#442B22] mb-[2vw]'>
              {`바로 무료 ${
                PlantBasicInfo.PlantNameAndAmount(UserStore?.farmData?.itemType)
                  ?.koreanName ?? '작물'
              } 획득!`}
            </div>
            <img
              className='w-[40%] h-auto mt-[-24vw]'
              alt=''
              src={`/farm/${
                farmingList[
                  PlantBasicInfo.IdFromPlantName(UserStore?.farmData?.itemType)
                ]
              }/${6}.gif`}
            />
            <div className='font-bold text-[#442B22] mb-[0vw]'>
              {`${untilAt}일뒤 이벤트가 종료 되기전에 `}
            </div>
            <div className='font-bold text-[#442B22] mb-[4vw]'>
              {`지금 바로 수확해보세요!`}
            </div>
            <div
              className={`text-[5vw] font-bold font-maplestory text-black bg-[#F4E5C5] rounded-full px-[4vw] py-[1vw] mb-[2vw]`}
            >
              {`${
                targetShareCount -
                (UserStore?.farmData?.newUserInstantHarvestInviteListCount ?? 0)
              }회 남음!`}
            </div>
          </div>

          <CTA />
        </div>
      </div>
    )
  },
)

export default NewUserInstantHarvestModal
