import React, { useState, useEffect, useRef } from 'react'
import { useSearchParam } from 'react-use'

import Layout from '../comps/atoms/layout'
import HeaderButton from '../comps/atoms/headerButton'
import { NavLink } from 'react-router-dom'
import Farm from '../comps/molecules/farm'
import GganbuFarmQuickButton from '../comps/atoms/GganbuFarmQuickButton'
import WhiteTextTimer from '../comps/WhiteTextTimer'
import BasicButton from '../comps/atoms/basicButton'
import commaNumber from 'comma-number'
import LoadingIndicator from '../comps/loadingIndicator'
import backendApis from '../utils/backendApis'
import Modal from 'react-modal/lib/components/Modal'
import WaterEffect from '../comps/atoms/waterEffect'
import GganbuWithEventIcons from '../comps/atoms/gganbuWithEventIcons'
import GetResourceModal from '../comps/atoms/getResourceModal'
import dayjs from 'dayjs'
import { BottomSheet } from 'react-spring-bottom-sheet'
import MessageModal from '../comps/atoms/MessageModal'
import { API_URI } from 'utils/constant'
import UserStore from 'store/UserStore'
import { observer } from 'mobx-react-lite'

const fetcher = async (url, token, method = 'GET', params = {}) => {
  const resource = method === 'GET' ? url + new URLSearchParams(params) : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init['method'] = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)

    const data = await res.json()

    return data
  } catch (err) {
    return null
  }
}

const AlwayzFarm = observer(() => {
  const throttleRef = useRef({})

  const currentDay = dayjs().format('YYYY-MM-DD')
  const [isLoading, setIsLoading] = useState(true)
  const [waterModalVisible, setWaterModalVisible] = useState(false)
  const [canClickGiveWater, setCanClickGiveWater] = useState(false)
  const [indexCount, setIndexCount] = useState(0)
  const [showMinimumWaterModal, setShowMinimumWaterModal] = useState(false)
  const [farmData, setFarmData] = useState({})
  const [canClickGetWater, setCanClickGetWater] = useState(false)
  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const phoneNumber = useSearchParam('phoneNumber')
  const [animState, setAnimState] = useState('')
  const [addWater, setAddWater] = useState(0)
  const [addFertilizer, setAddFertilizer] = useState(0)
  const [canClickBonusReward, setCanClickBonusReward] = useState(false)
  const [getResourceModal, setGetResourceModal] = useState(false)
  const [showRewardModal, setShowRewardModal] = useState(false)
  const [showBonusRewardBoxModal, setShowBonusRewardBoxModal] = useState(false)
  const [isButtonPressedOpenReward, setIsButtonPressedOpenReward] =
    useState(false)
  const [showMessageSuccessModal, setShowMessageSuccessModal] = useState(false)
  const [isButtonPressedGiveWater, setIsButtonPressedGiveWater] =
    useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [waterFromALwayzFarmDone, setWaterFromALwayzFarmDone] = useState(false)
  const [giveWaterBasketToALwayzFarmDone, setGiveWaterBasketToALwayzFarmDone] =
    useState(false)
  const [
    bonusRewardBoxFromAlwayzFarmDone,
    setBonusRewardBoxFromAlwayzFarmDone,
  ] = useState(false)

  const getAlfarmData = async (token, farmData) => {
    if (token && !farmData?.userId) {
      fetcher(`/games/start-alfarm`, token, 'POST', {
        itemType: itemType,
        phoneNumber: phoneNumber,
      }).then((data) => {
        if (data?.userId) {
          setFarmData(data)
          setCanClickGiveWater(true)
          setCanClickGetWater(true)
          setCanClickBonusReward(true)

          if (data?.waterFromAlwayzFarm) {
            setWaterFromALwayzFarmDone(true)
          }
          if (data?.giveWaterBasketToAlwayzFarm) {
            setGiveWaterBasketToALwayzFarmDone(true)
          }
          if (data?.bonusRewardBoxFromAlwayzFarm) {
            setBonusRewardBoxFromAlwayzFarmDone(true)
          }
          setIsLoading(false)
        } else {
          alert('정보를 불러오지 못했어요. 잠시 후 다시 시도해 주세요.')
        }
      })
    }
  }

  function onDismiss() {
    setTimeout(() => setShowMessageModal(false), 50)
  }

  const showWaterModal = () => {
    setWaterModalVisible(true)
    setTimeout(() => {
      setWaterModalVisible(false)
    }, 1200)
  }

  const handleAnim = (action, duration, callback) => {
    setAnimState(action)
    setTimeout(() => {
      callback()
    }, duration)
  }

  const bonusRewardBoxFromAlwayzFarm = async () => {
    const result = await backendApis.bonusRewardBoxFromAlwayzFarm(
      token,
      'POST',
      {},
    )
    if (result?.data?.success === false) {
      let tmpFarmData = farmData
      tmpFarmData.fertilizer += 0
      setFarmData(tmpFarmData)
      setAddFertilizer(result?.data?.addFertilizer)
    }
    setBonusRewardBoxFromAlwayzFarmDone(true)
    let tmpFarmData = farmData
    tmpFarmData.fertilizer += result?.data?.addedFertilizer
    setFarmData(tmpFarmData)
    setAddFertilizer(result?.data?.addFertilizer)
    setShowRewardModal(true)
    setTimeout(() => {
      setGetResourceModal(false)
    }, 1000)
  }
  const waterFromGganbu = async () => {
    if (canClickGetWater) {
      setCanClickGetWater(false)
      const result = await backendApis.waterFromAlwayzFarm(token, 'POST', {})

      if (result?.data?.success === false) {
        let tmpFarmData = farmData
        tmpFarmData.water += 0
        setFarmData(tmpFarmData)
        setAddWater(result?.data?.addedWater)
      }
      setWaterFromALwayzFarmDone(true)
      let tmpFarmData = farmData

      if (UserStore.waterExceeded) {
        tmpFarmData.water += Math.round(result?.data?.addedWater * 0.5)
        setAddWater(Math.round(result?.data?.addedWater * 0.5))
      } else {
        tmpFarmData.water += result?.data?.addedWater
        setAddWater(result?.data?.addedWater)
      }
      setFarmData(tmpFarmData)
      setGetResourceModal(true)
      setTimeout(() => {
        setGetResourceModal(false)
      }, 1000)

      setCanClickGetWater(false)
    }
  }

  const bonusRewardButton = () => {
    if (!giveWaterBasketToALwayzFarmDone) {
      return (
        <div>
          <button
            style={{
              width: '100%',
              fontFamily: 'maplestory',
              color: 'black',
              fontSize: '16px',
              borderRadius: '30px',
              padding: 16,
              zIndex: 999,
              background: isButtonPressedGiveWater
                ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              boxShadow: isButtonPressedGiveWater
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
            }}
            onClick={() => {
              setShowBonusRewardBoxModal(false)
              giveWaterBasket()
              setGiveWaterBasketToALwayzFarmDone(true)
            }}
            onPointerDown={() => {
              setIsButtonPressedGiveWater(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressedGiveWater(false)
            }}
            onPointerUp={() => {
              setIsButtonPressedGiveWater(false)
            }}
          >
            물주기
          </button>
        </div>
      )
    } else if (giveWaterBasketToALwayzFarmDone) {
      return (
        <button
          style={{
            width: '100%',
            fontFamily: 'maplestory',
            color: 'white',
            fontSize: '16px',
            borderRadius: '30px',
            padding: 16,
            background: isButtonPressedOpenReward
              ? ' linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)'
              : ' linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)',
            boxShadow: isButtonPressedOpenReward
              ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
              : '',
          }}
          onClick={() => {
            setShowBonusRewardBoxModal(false)
            bonusRewardBoxFromAlwayzFarm()
          }}
          onPointerDown={() => {
            setIsButtonPressedOpenReward(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressedOpenReward(false)
          }}
          onPointerUp={() => {
            setIsButtonPressedOpenReward(false)
          }}
        >
          비료상자 열기
        </button>
      )
    } else {
      return (
        <button
          style={{
            width: '100%',
            background: 'linear-gradient(180deg, #EFDFD0 0%, #FBDEB9 100%)',
            fontFamily: 'maplestory',
            color: 'black',
            fontSize: '16px',
            borderRadius: '30px',
            padding: 16,
          }}
        >
          내일 다시 받을 수 있어요
        </button>
      )
    }
  }

  const giveWaterBasket = async () => {
    if (farmData?.water < 10) {
      if (showMinimumWaterModal) return
      setShowMinimumWaterModal(true)
      setTimeout(() => {
        setShowMinimumWaterModal(false)
      }, 2000)
      return
    }
    setIndexCount(indexCount + 1)
    if (canClickGiveWater) {
      setCanClickGiveWater(false)
      await backendApis.giveWaterBasketToAlwayzFarm(token)
      showWaterModal()
      setCanClickGiveWater(true)
      handleAnim('giveWaterBasket', 1500, () => {
        setAnimState('')
      })
      setGiveWaterBasketToALwayzFarmDone(true)
    }
  }

  useEffect(() => {
    getAlfarmData(token, farmData)
  }, [])

  return (
    <>
      <Layout isLoading={false}>
        <header>
          <div
            style={{
              paddingTop: '10vw',
              position: 'absolute',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              zIndex: 1,
            }}
            className='inset-top'
          >
            <NavLink to={`/farmPage?token=${token}&itemType=${itemType}`}>
              <button
                style={{
                  width: '7vw',
                  marginLeft: '2vw',
                  paddingTop: '3vw',
                }}
                action='goBack'
              >
                <img src='/icon/Back.png' alt='' />
              </button>
            </NavLink>
          </div>
        </header>
        {!waterFromALwayzFarmDone && (
          <>
            <div
              style={{
                flex: 1,
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100vh',
                backgroundColor: 'rgba(0,0,0,0.8)',
                zIndex: 111,
              }}
            />

            <div
              style={{
                flex: 1,
                position: 'absolute',
                textAlign: 'center',
                bottom: '40%',
                left: '0%',
                width: '100%',
                fontSize: '5vw',
                zIndex: 111,
                lineHeight: '150%',
              }}
              className='bottom-fert-title'
            >
              맞팜 체험농장에 오신 것을 환영해요
              <div>버튼을 눌러 물을 받을 수 있어요</div>
            </div>
            <div
              style={{
                flex: 1,
                position: 'absolute',
                left: '5vw',
                bottom: '57vw',
                zIndex: 111,
                fontSize: 18,
                textAlign: 'center',
              }}
              className='slide-bottom'
            >
              <img
                style={{
                  width: '12vw',
                }}
                src='/icon/guideIndicator.png'
                alt=''
              />
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: '27.3vw',
                left: '1vw',
                zIndex: 111,
              }}
            >
              <GganbuFarmQuickButton
                action='getWaterFromGganbu'
                onClick={waterFromGganbu}
              />
            </div>
          </>
        )}
        {waterFromALwayzFarmDone && !giveWaterBasketToALwayzFarmDone && (
          <>
            <div
              style={{
                flex: 1,
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100vh',
                backgroundColor: 'rgba(0,0,0,0.8)',
                zIndex: 112,
              }}
            />

            <div
              style={{
                flex: 1,
                position: 'absolute',
                textAlign: 'center',
                bottom: '38%',
                left: '0%',
                width: '100%',
                fontSize: '5vw',
                zIndex: 112,
                lineHeight: '150%',
              }}
              className='bottom-fert-title'
            >
              버튼을 눌러 내 물을 맞팜 친구에게 줄 수 있어요 <br />
              체험 농장에서는 물이 소모 되지 않아요
            </div>
            <div
              style={{
                position: 'absolute',
                left: '100vw',
                zIndex: 112,
                bottom: '22vw',
              }}
            >
              {!waterModalVisible ? (
                <BasicButton
                  type='water'
                  action='giveWater'
                  label={`${commaNumber(farmData?.water)}g`}
                  disabled={farmData?.water < 10 || !canClickGiveWater}
                  onClick={giveWaterBasket}
                />
              ) : (
                <BasicButton
                  type='water'
                  action='givingWater'
                  label={`${commaNumber(farmData?.water)}g`}
                  disabled={farmData?.water < 10 || !canClickGiveWater}
                  onClick={giveWaterBasket}
                />
              )}
            </div>
            <div
              style={{
                flex: 1,
                position: 'absolute',
                right: '6vw',
                bottom: '60vw',
                zIndex: 112,
                fontSize: 18,
                textAlign: 'center',
              }}
              className='slide-bottom'
            >
              <img
                style={{
                  width: '12vw',
                }}
                src='/icon/guideIndicator.png'
                alt=''
              />
            </div>
          </>
        )}
        {waterFromALwayzFarmDone &&
          giveWaterBasketToALwayzFarmDone &&
          !bonusRewardBoxFromAlwayzFarmDone && (
            <>
              <div
                style={{
                  flex: 1,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100vh',
                  backgroundColor: 'rgba(0,0,0,0.8)',
                  zIndex: 113,
                }}
              />

              <div
                style={{
                  flex: 1,
                  position: 'absolute',
                  textAlign: 'center',
                  bottom: '40%',
                  left: '0%',
                  width: '100%',
                  fontSize: '5vw',
                  zIndex: 113,
                  lineHeight: '150%',
                }}
                className='bottom-fert-title'
              >
                맞팜 친구에게 물을 주면
                <div>비료 상자를 열 수 있어요</div>
              </div>
              <div
                style={{
                  flex: 1,
                  position: 'absolute',
                  left: '23vw',
                  bottom: '55vw',
                  zIndex: 113,
                  fontSize: 18,
                  textAlign: 'center',
                }}
                className='slide-bottom'
              >
                <img
                  style={{
                    width: '12vw',
                  }}
                  src='/icon/guideIndicator.png'
                  alt=''
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: '27.3vw',
                  left: '18.7vw',
                  zIndex: 113,
                }}
              >
                <GganbuFarmQuickButton
                  action='fertBoxIcon'
                  onClick={() => {
                    setShowBonusRewardBoxModal(true)
                  }}
                />
              </div>
            </>
          )}
        {waterFromALwayzFarmDone &&
          giveWaterBasketToALwayzFarmDone &&
          bonusRewardBoxFromAlwayzFarmDone && (
            <>
              <div
                style={{
                  flex: 1,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100vh',
                  backgroundColor: 'rgba(0,0,0,0.8)',
                  zIndex: 114,
                }}
              />

              <div
                style={{
                  flex: 1,
                  position: 'absolute',
                  textAlign: 'center',
                  bottom: '55%',
                  left: '0%',
                  width: '100%',
                  fontSize: '5vw',
                  zIndex: 114,
                  lineHeight: '150%',
                }}
                className='bottom-fert-title'
              >
                <div>맞팜 친구가 많으면 수확을 훨씬 빨리 할 수 있어요</div>
                <div>맞팜 친구를 모집해 올팜을 즐겨보세요!</div>
              </div>
              <div
                style={{
                  flex: 1,
                  position: 'absolute',
                  left: '5vw',
                  bottom: '74vw',
                  zIndex: 114,
                  fontSize: 18,
                  textAlign: 'center',
                }}
                className='slide-bottom'
              >
                <img
                  style={{
                    width: '12vw',
                  }}
                  src='/icon/guideIndicator.png'
                  alt=''
                />
              </div>

              <div
                style={{
                  position: 'absolute',
                  bottom: '47vw',
                  left: '1vw',
                  zIndex: 114,
                }}
              >
                <NavLink
                  to={`/farmPage?token=${token}&itemType=${itemType}`}
                  //   style={{ width: "80vw" }}
                >
                  <GganbuFarmQuickButton action='backToFarm' />
                </NavLink>
              </div>
            </>
          )}

        <div
          style={{
            position: 'absolute',
            fontFamily: 'maplestory',
            top: '27%',
            left: '20%',
            width: '60%',
            zIndex: 1,
          }}
        >
          <img src='/icon/alfarmGganbuBackGround.png' />
          <div
            style={{
              position: 'absolute',
              zIndex: 101,
              fontFamily: 'maplestory',
              top: '15%',
              left: '20%',
              width: '60%',
              color: 'black',
              textAlign: 'center',
              marginTop: '2.5vw',
            }}
          >
            올웨이즈님의
            <div
              style={{
                color: 'black',
              }}
            >
              맞팜 체험농장
            </div>
          </div>
        </div>

        <main
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              left: 0,
              right: 0,
              top: '5vw',
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              zIndex: 1,
            }}
          >
            <Farm id={4} level={5} token={token} gganbuMode isLoading={false} />
          </div>
        </main>
        <div
          style={{
            position: 'absolute',
            bottom: '22vw',
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: '22vw',
            }}
          >
            <div
              style={{
                position: 'absolute',
                bottom: '3vw',
                left: '1vw',
                zIndex: 110,
              }}
            >
              <NavLink
                to={`/farmPage?token=${token}&itemType=${itemType}`}
                //   style={{ width: "80vw" }}
              >
                <GganbuFarmQuickButton action='backToFarm' />
              </NavLink>
            </div>
          </div>
          {canClickGetWater ? (
            <div
              style={{
                position: 'absolute',
                bottom: '5.3vw',
                left: '1vw',
                zIndex: 101,
              }}
            >
              <GganbuFarmQuickButton
                action='getWaterFromGganbu'
                onClick={waterFromGganbu}
              />
            </div>
          ) : (
            <div
              style={{
                position: 'absolute',
                bottom: '5.3vw',
                left: '1vw',
                zIndex: 7,
              }}
            >
              <GganbuFarmQuickButton action='alreadyGetWaterFromGganbu' />

              <div
                style={{
                  position: 'absolute',
                  //   bottom: "50vw",
                  zIndex: 8,
                  fontFamily: 'maplestory',
                  fontSize: 14,
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                }}
                className='absolute center gganbu timer'
              >
                {/* <WhiteTextTimer
                onTimeEnd={() => {
                  // setCanClickGetWater();
                }}
                //   timeStamp={getWaterFromGganbuTimer}
                timerMinutes={240}
                color="white"
              /> */}
              </div>
            </div>
          )}
          <div
            style={{
              position: 'absolute',
              bottom: '5.3vw',
              left: '18.7vw',
              zIndex: 103,
            }}
          >
            <GganbuFarmQuickButton
              action='bonusReward'
              onClick={() => {
                setShowBonusRewardBoxModal(true)
              }}
            />
          </div>
          {/* <div
            style={{
              position: "absolute",
              bottom: "5.3vw",
              left: "36.4vw",
              zIndex: 7,
            }}
          >
            <GganbuFarmQuickButton
              action="message"
              onClick={() => {
                setShowMessageModal(true);
              }}
            />
          </div> */}

          <div
            style={{
              position: 'absolute',
              left: '100vw',
              zIndex: 102,
            }}
          >
            {!waterModalVisible ? (
              <BasicButton
                type='water'
                action='giveWater'
                label={`${commaNumber(farmData?.water)}g`}
                disabled={farmData?.water < 10 || !canClickGiveWater}
                onClick={giveWaterBasket}
              />
            ) : (
              <BasicButton
                type='water'
                action='givingWater'
                label={`${commaNumber(farmData?.water)}g`}
                disabled={farmData?.water < 10 || !canClickGiveWater}
                onClick={giveWaterBasket}
              />
            )}
          </div>
        </div>
        <footer />
        <div>
          <GganbuWithEventIcons
            token={token}
            itemType={itemType}
            isFromGganbuIntro={true}
          />
        </div>
      </Layout>
      {getResourceModal && (
        <GetResourceModal
          amount={addWater}
          setGetResourceModal={setGetResourceModal}
        />
      )}

      {showBonusRewardBoxModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '20%',
            }}
          >
            <img
              style={{
                width: '90%',

                left: '5%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/fertBox.png'}
            />
            <div
              style={{
                position: 'absolute',
                width: '70%',
                left: '15%',
                bottom: '-101vw',
              }}
            >
              {bonusRewardButton()}
            </div>
          </div>

          <button
            style={{
              width: '50%',
              top: '12%',
              right: '-5%',
              position: 'absolute',
              zIndex: 3,
            }}
            onClick={() => {
              setShowBonusRewardBoxModal(false)
            }}
          >
            <img
              style={{
                zIndex: 101,
                position: 'absolute',
                width: '20%',
                top: '73%',
                left: '50%',
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
        </div>
      )}

      <Modal
        isOpen={waterModalVisible}
        onRequestClose={() => {}}
        style={{
          overlay: {
            background: 'rgba(0,0,0,0)',
            zIndex: 100,
          },
          content: {
            position: 'relative',
            width: '100%',
            height: '110%',
          },
        }}
        className='modal-basic'
      >
        <WaterEffect />
      </Modal>
      {showMessageModal && (
        <BottomSheet
          className='bottom-sheet'
          open={showMessageModal}
          blocking={true}
          onDismiss={onDismiss}
          // snapPoints={({ maxHeight }) => [0.7 * maxHeight]}
          defaultSnap={({ lastSnap, snapPoints }) => {
            lastSnap ?? Math.max(...snapPoints)
          }}
        >
          <MessageModal
            showMessageModal={showMessageModal}
            setShowMessageModal={setShowMessageModal}
            token={token}
            showMessageSuccessModal={showMessageSuccessModal}
            setShowMessageSuccessModal={setShowMessageSuccessModal}
          />
        </BottomSheet>
      )}

      <LoadingIndicator isLoading={isLoading} />
      {showRewardModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '40%',
            }}
          >
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/reward2.png'}
            />
            <button
              style={{
                width: '35%',
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: 'black',
                fontSize: '16px',
                borderRadius: '30px',
                padding: 16,
                position: 'absolute',

                left: '32%',
                top: '33vw',
              }}
              onClick={() => {
                setShowRewardModal(false)
              }}
            >
              확인
            </button>
          </div>
        </div>
      )}
    </>
  )
})

export default AlwayzFarm
