import AlfarmEventLogger from 'utils/alfarmEventLogger'
import ModalStore from '../../../store/ModalStore'
import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'

const DailyGetWaterUpgradeModal = observer(
  ({ token, farmData, setFarmData }) => {
    const [isButtonPressedAlwayzFarm, setIsButtonPressedAlwayzFarm] =
      useState(false)
    const throttleRef = useRef({})

    const shareKakao = () => {
      const inviteData = {
        shareType: 'AlfarmDailyGetWaterUpgrade',
        title: '[올팜] 농작물을 무료로 보내드려요',
        description: '우리 같이 올팜에서 작물 키우고 무료로 받아봐요!',
        bannerImage:
          'https://assets.ilevit.com/7ab3f811-7cfb-4108-a3b9-d9b3bfc13867.png',
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 2000,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80vw',
            }}
          >
            <button
              style={{
                width: '12%',
                top: '0%',
                right: '8%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <img alt='' src={'/icon/dailyWaterUpgradeModal.png'} />

            <button
              style={{
                width: '70%',
                left: '13%',
                fontFamily: 'maplestory',
                color: 'black',
                fontSize: '4.5vw',
                borderRadius: '30px',
                padding: 16,
                zIndex: 2000,
                position: 'absolute',
                top: '103vw',
                background: isButtonPressedAlwayzFarm
                  ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                  : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                boxShadow: isButtonPressedAlwayzFarm
                  ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                  : '',
              }}
              onPointerDown={() => {
                setIsButtonPressedAlwayzFarm(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressedAlwayzFarm(false)
              }}
              onPointerUp={() => {
                setIsButtonPressedAlwayzFarm(false)
              }}
              onClick={() => {
                shareKakao()
                AlfarmEventLogger({
                  throttleRef,
                  locationType: 'modal',
                  locationName: ModalStore.isModalOpen,
                  eventType: 'click',
                  eventName: 'cta',
                  data: { testName: 'waterUpgrade0927' },
                  collection: 'UserAlfarmClickLog',
                })
              }}
            >
              친구 초대하기
            </button>
          </div>
        </div>
      </>
    )
  },
)

export default DailyGetWaterUpgradeModal
