import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import BottomSheetStore from 'store/BottomSheetStore'
import ModalStore from 'store/ModalStore'

const BottomSheetHeader = observer(
  ({ historyModalType = 'inviteHistory', title = '피드' }) => {
    return (
      <div className='w-full flex flex-row justify-between items-center px-[4vw] mb-[2vw]'>
        <button
          className='w-[12vw] h-[8vw] z-[1]'
          style={{
            visibility: title !== '올목장 퀘스트' ? 'visible' : 'hidden',
          }}
          onClick={() => {
            ModalStore.setIsModalOpen(historyModalType)
          }}
        >
          <img
            className='w-full z-[3]'
            src='/icon/resourceHistoryButton.png'
            alt=''
          />
        </button>

        <div className='text-[5vw] font-maplestory mt-[0] mb-[1vw] text-[#442b22] text-center z-[2]'>
          {title}
        </div>
        <button
          className='ml-[5vw] w-[7vw] h-[7vw] z-[1]'
          onClick={() => {
            BottomSheetStore.setIsbottomSheetOpen('basic')
          }}
        >
          <img
            className='w-full z-[3]'
            src='/icon/bottomCloseButton.png'
            alt=''
          />
        </button>
      </div>
    )
  },
)

export default BottomSheetHeader
