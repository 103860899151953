import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import StyleAsset from '../molecules/styleAsset'
import backendApis from '../../utils/backendApis'
import moment from 'moment'
import ModalStore from '../../store/ModalStore'

const PokeFriendModal = observer(
  ({ gganbuName, userIdTurn, pokeCount, pokeFriend, canPoke }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)

    const CloseButton = () => {
      return (
        <button
          style={{
            width: '10%',
            top: '12%',
            right: '8%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            zIndex: 1113,
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      )
    }

    const PokeButton = () => {
      if (userIdTurn && pokeCount < 4 && canPoke) {
        return (
          <button
            style={{
              width: '70%',
              bottom: '-18vw',
              height: '14vw',
              left: '15vw',
              position: 'absolute',
              zIndex: 3,
              background: isButtonPressed
                ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: '5vw',
              borderRadius: '40vw',
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
              pokeFriend()
              ModalStore.setIsModalOpen('basic')
            }}
          >
            콕! 찌르기
          </button>
        )
      }
      if (!userIdTurn && pokeCount < 4 && !canPoke) {
        return (
          <button
            style={{
              width: '70%',
              bottom: '-18vw',
              height: '14vw',
              left: '15vw',
              position: 'absolute',
              zIndex: 3,
              background: '#D3D7DD',
              color: '#707070',
              fontFamily: 'maplestory',
              fontSize: '5vw',
              borderRadius: '40vw',
            }}
            onClick={() => {
              pokeFriend()
              ModalStore.setIsModalOpen('basic')
            }}
          >
            친구가 찌를 차례예요
          </button>
        )
      }

      if (!userIdTurn && pokeCount === 5 && !canPoke) {
        return (
          <button
            style={{
              width: '70%',
              bottom: '-18vw',
              height: '14vw',
              left: '15vw',
              position: 'absolute',
              zIndex: 3,
              background: '#D3D7DD',
              color: '#707070',
              fontFamily: 'maplestory',
              fontSize: '5vw',
              borderRadius: '40vw',
            }}
            onClick={() => {
              pokeFriend()
              ModalStore.setIsModalOpen('basic')
            }}
          >
            오늘 콕! 찌르기를 완료했어요
          </button>
        )
      }
    }

    const PokeScore = ({ todaysPokeYetToStart = false }) => {
      if (todaysPokeYetToStart) {
        return null
      }
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '5vw',
          }}
        >
          <div style={{ color: 'black' }}>
            콕! 찌르기
            {`  ${Math.min(4, pokeCount) || 0}/4번 했어요`}
          </div>
        </div>
      )
    }

    const HeadDiscription = () => {
      return (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '4.5vw',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <div style={{ color: 'black', marginBottom: '2vw' }}>
            오늘 친구와 콕! 찌르기 2번 주고 받으면
          </div>
          <div
            style={{
              color: 'black',
              display: 'flex',
              flexDirection: 'row',
              // textAlign: "center",
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div style={{ color: 'black' }}>친구도 나도 랜덤</div>
            <img
              style={{
                width: '6vw',
                height: '6vw',
              }}
              src={'/icon/water.png'}
              alt=''
            />
            <div style={{ color: 'black' }}>물을 받아요</div>
          </div>
        </div>
      )
    }

    const MeAndGganbuImage = ({
      todaysPokeYetToStart = false,
      todaysPokeFinished = false,
      myTurn = false,
      pokeCount,
    }) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',

            alignItems: 'center',
            marginTop: '58vw',
            justifyContent: 'center',
            marginBottom: '4vw',
          }}
        >
          <PersonWithImage userName={'나'} userImage={''} />
          <img
            style={{
              paddingTop: '4vw',
              width: '20%',
              position: 'absolute',
              left: '40%',
            }}
            src={'/icon/pokeWithFinger.png'}
            alt=''
          />
          <PersonWithImage
            userName={
              gganbuName?.length > 4 ? (
                <>{gganbuName.slice(0, 4)}..</>
              ) : (
                <>{gganbuName.slice(0, 4)}</>
              )
            }
            userImage={''}
            isGganbu={true}
          />
        </div>
      )
    }

    const PersonWithImage = ({
      userName = '',
      userImage = '',
      isGganbu = false,
    }) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '8vw',
            marginLeft: '8vw',
          }}
        >
          <div style={{ color: 'black', fontSize: '4vw' }}>{userName}</div>
          <img
            style={{
              width: '20vw',
              height: '20vw',
              borderRadius: '100vw',
              marginTop: '2vw',
              border: '3px solid #F6C64C',
            }}
            src={isGganbu ? '/icon/gganbuFarmer.png' : '/icon/Farmer.png'}
            alt=''
          />
        </div>
      )
    }

    return (
      <>
        <div style={StyleAsset.modalBackground}>
          <div
            style={{
              ...StyleAsset.modalImageCentered,
              backgroundImage: 'url(../icon/pokeFriendModal.png)',
              width: '90vw',
              height: '170vw',
            }}
          >
            <div
              style={{
                ...StyleAsset.centered,
                top: '45%',
              }}
            >
              <HeadDiscription />
              <MeAndGganbuImage
                myTurn={userIdTurn.myTurn}
                pokeCount={pokeCount}
              />
              <PokeScore />
              <PokeButton />
            </div>
            <CloseButton />
          </div>
        </div>
      </>
    )
  },
)
export default PokeFriendModal
