import backendApis from '../../utils/backendApis'
import React, { useEffect, useState } from 'react'
import WhiteTextTimer from '../WhiteTextTimer'
import UserStore from 'store/UserStore'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'

const PuppyButton = observer(
  ({
    onClick,
    farmData,
    setFarmData,
    setAddWater,
    setAddFertilizer,
    setAddFertilizerSet,
    setPuppyGiftModal,
    token,
    setPuppyRewardType,
    loadUserCoupon,
    showPuppy,
  }) => {
    const [canClick, setCanClick] = useState(false)
    const [loadingPuppyGiftTime, setLoadingPuppyGiftTime] = useState(true)
    const [lastGiftAt, setLastGiftAt] = useState(new Date())

    const lastGetPuppyGift = async () => {
      const result = await backendApis.lastGetPuppyGift(token)
      if (result?.data?.canClick === true) {
        setCanClick(true)
      } else if (result?.data?.canClick === false) {
        setCanClick(false)
      } else {
        setCanClick(false)
      }

      setLastGiftAt(new Date(result?.data?.giftAt))
      setLoadingPuppyGiftTime(false)
    }

    useEffect(() => {
      lastGetPuppyGift()
    }, [])

    const puppyGift = async () => {
      if (canClick) {
        setCanClick(false)
        const result = await backendApis.puppyGift(token)
        if (result?.data?.success === true) {
          let tmpFarmData = farmData
          if (UserStore.waterExceeded) {
            tmpFarmData.water += Math.round(result?.data?.addedValueWater * 0.5)
            setAddWater(Math.round(result?.data?.addedValueWater * 0.5))
          } else {
            tmpFarmData.water += result?.data?.addedValueWater
            setAddWater(result?.data?.addedValueWater)
          }
          tmpFarmData.fertilizer += result?.data?.addedValueFertilizer
          tmpFarmData.fertilizerSet += result?.data?.addedValueFertilizerSet
          setPuppyRewardType(result?.data?.rewardType)
          setFarmData(tmpFarmData)
          setAddFertilizer(result?.data?.addedValueFertilizer)
          setAddFertilizerSet(result?.data?.addedValueFertilizerSet)
          setPuppyGiftModal(true)
          setTimeout(() => {
            setPuppyGiftModal(false)
          }, 2000)
          loadUserCoupon()
        }
      }
    }

    if (!showPuppy) return null
    if (farmData?.giveWaterCount < 10) return

    return (
      <div
        style={{
          paddingBottom: canClick ? '8vw' : '10vw',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            marginBottom: '-12vw',
            zIndex: 103,
          }}
        >
          {canClick ? (
            <button
              style={{
                width: '15vw',
              }}
              className='slide-top-bottom'
              onClick={puppyGift}
            >
              <img alt='' src={`/icon/puppyGift.png`} />
            </button>
          ) : (
            <div
              style={{
                zIndex: 8,
                fontSize: '4vw',
                textShadow:
                  '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                marginBottom: '2vw',
              }}
            >
              {!loadingPuppyGiftTime && (
                <WhiteTextTimer
                  onTimeEnd={() => {
                    lastGetPuppyGift()
                  }}
                  timeStamp={lastGiftAt}
                  timerMinutes={240}
                  color='white'
                />
              )}
            </div>
          )}
        </div>
        <button
          style={{
            zIndex: 103,
          }}
        >
          {canClick ? (
            <img alt='' src={'/farm/puppy/puppy.gif'} />
          ) : (
            <div>
              <img alt='' src={'/farm/puppy/puppySleep.gif'} />
            </div>
          )}
          <div
            style={{
              width: '22vw',
              height: '8vw',
              position: 'absolute',
              bottom: '22vw',
              left: '10%',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('puppy')
            }}
          />
        </button>
      </div>
    )
  },
)

export default PuppyButton
