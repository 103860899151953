import backendApis from '../utils/backendApis'
import React, { useState, useEffect, useRef } from 'react'
import { useSearchParam } from 'react-use'
import LoadingIndicator from '../comps/loadingIndicator'
import { NavLink } from 'react-router-dom'
import RouletteEventHistoryModal from '../comps/atoms/rouletteEventHistoryModal'
import moment from 'moment'
import WhiteTextTimer from '../comps/WhiteTextTimer'
import LuckyPiggyGaugeBar from '../comps/atoms/luckyPiggyGaugeBar'
import Piggy from '../comps/molecules/piggy'
import Coin from '../comps/molecules/coin'
import PiggyGuideIcon from '../comps/molecules/piggyGuideIcon'
import LuckyPiggyOpenGiftModal from '../comps/atoms/luckyPiggyOpenGiftModal'
import LuckyPiggyGiftListModal from '../comps/atoms/luckyPiggyGiftListModal'
import commaNumber from 'comma-number'
import LuckyPiggyInviteList from '../comps/molecules/luckyPiggyInviteList'
import LuckyPiggyQuest from '../comps/molecules/luckyPiggyQuest'
import { API_URI } from 'utils/constant'

const fetcher = async (url, token, method = 'GET', params = {}) => {
  const resource = method === 'GET' ? url + new URLSearchParams(params) : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init['method'] = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)

    const data = await res.json()

    return data
  } catch (err) {
    return null
  }
}

let isPressButton = false

const LuckyPiggy = () => {
  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const [isLoading, setIsLoading] = useState(false)
  const [inviteButtonPressed, setInviteButtonPressed] = useState(false)
  const [openFeedingModal, setOpenFeedingModal] = useState(false)
  const [howToModalNumber, setHowToModalNumber] = useState(1)
  const [showGiftListModal, setShowGiftListModal] = useState(false)
  const [showOpenGiftModal, setShowOpenGiftModal] = useState(false)
  const [ongoingData, setOngoingData] = useState('')
  const [level, setLevel] = useState(1)
  const [remainExp, setRemainExp] = useState(0)
  const [expToLevelUp, setExpToLevelUp] = useState(0)
  const [openNeedCoinModal, setOpenNeedCoinModal] = useState(false)
  const [canGiveCoin, setCanGiveCoin] = useState(true)
  const [indexCount, setIndexCount] = useState(1)
  const [openSendMoneyModal, setOpenSendMoneyModal] = useState(false)
  const [moneyAmount, setMoneyAmount] = useState('')
  const [isOpenGiftBoxButtonPressed, setIsOpenGiftBoxButtonPressed] =
    useState(false)
  const getPiggyData = async () => {
    const result = await backendApis.startLuckyPiggy()

    setOngoingData(result?.data?.ongoingData)
  }

  const luckyPiggyEnd = async () => {
    const result = await backendApis.luckyPiggyEnd()
    // setOngoingData(result?.data?.ongoingData);
  }

  const expData = async (exp) => {
    const level1 = ongoingData?.piggyLevel?.level1
    const level2 = ongoingData?.piggyLevel?.level2
    const level3 = ongoingData?.piggyLevel?.level3
    const level4 = ongoingData?.piggyLevel?.level4
    const level5 = ongoingData?.piggyLevel?.level5
    const level6 = ongoingData?.piggyLevel?.level6
    // const exp = ongoingData?.exp;
    const levelData = [level1, level2, level3, level4, level5, level6]

    let level = 0
    let remainExp = 0
    let expToLevelUp = 0
    let prev = 0
    for (let i = 0; i < levelData.length; i++) {
      const each = levelData[i]
      if (exp >= each) level++
      else {
        expToLevelUp = each - exp
        remainExp = exp - prev
        break
      }

      prev = each
    }
    setLevel(level)
    setRemainExp(remainExp)
    setExpToLevelUp(expToLevelUp)

    return { level, remainExp, expToLevelUp }
  }

  const giveCoin = async () => {
    setCanGiveCoin(false)
    if (ongoingData?.coin < 1) {
      setOpenNeedCoinModal(true)
      setTimeout(() => {
        setOpenNeedCoinModal(false)
        setCanGiveCoin(true)
      }, 3000)
      return
    }
    const result = await backendApis.giveCoin()

    if (result?.status === 200) {
      setIndexCount(indexCount + 1)
      let tempOngoingData = ongoingData
      tempOngoingData.coin -= 1
      tempOngoingData.exp += result?.data?.exp
      setOngoingData(tempOngoingData)
      expData(ongoingData?.exp)
    }
    setCanGiveCoin(true)
  }

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
    getPiggyData(token)
    expData(ongoingData?.exp)
  }, [token, ongoingData?.exp])

  const shareKakao = () => {
    const inviteData = {
      shareType: 'AlfarmLuckyPiggyEvent',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }

  return (
    <>
      <div
        style={{
          background: 'linear-gradient(180deg, #FF5D53 0%, #FFD37C 76.04%)',
          overflowY: 'scroll',
          height: '100vh',
          width: '100%',
        }}
      >
        <NavLink
          to={`/farmPage?token=${token}&itemType=${itemType}`}
          //   style={{ width: "80vw" }}
        >
          <button
            style={{
              width: '7vw',
              marginLeft: '2vw',
              paddingTop: '10vw',
            }}
            action='goBack'
          >
            <img src='/luckyPiggy/backWhite.png' alt='' />
          </button>
        </NavLink>

        <button
          style={{
            marginLeft: '53vw',
            paddingTop: '10vw',
            width: '30vw',
            zIndex: 101,
            fontSize: '4vw',
            fontFamily: 'maplestory',
          }}
          onClick={() => {
            setOpenFeedingModal(true)
          }}
        >
          복돼지 키우기란?
        </button>
        <div
          style={{
            paddingTop: '5vw',
            width: '50%',
            marginLeft: '25%',
          }}
        >
          <img src='/luckyPiggy/title.png' alt='' />
        </div>
        <div
          style={{
            color: 'black',
            fontSize: '4vw',
            fontFamily: 'maplestory',
            textAlign: 'center',
            marginTop: '3vw',
            lineHeight: '7vw',
          }}
        >
          코인을 주고 복돼지를 키워보세요! <br />
          복돼지를 많이 키울수록 용돈을 더 많이 받을 수 있어요
        </div>

        <div
          style={{
            width: '80%',
            height: '100vw',
            backgroundColor: 'white',
            borderRadius: '3vw',
            marginLeft: '10vw',
            marginTop: '5vw',
          }}
        >
          <div
            style={{
              fontFamily: 'maplestory',
              textAlign: 'center',
              fontSize: '5vw',
              paddingTop: '2vw',
            }}
          >
            {ongoingData?.endsAt && !ongoingData?.isEnded && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{
                    width: '9vw',
                  }}
                  alt=''
                  src={`/luckyPiggy/timer.png`}
                />
                <div
                  style={{
                    marginLeft: '1vw',
                  }}
                >
                  <WhiteTextTimer
                    onTimeEnd={() => {
                      getPiggyData()
                      luckyPiggyEnd()
                    }}
                    timeStamp={new Date(ongoingData?.endsAt)}
                    timerMinutes={0}
                    color='#000000'
                  />
                </div>
              </div>
            )}
            {ongoingData?.endsAt && ongoingData?.isEnded && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    paddingTop: '2vw',
                    color: 'black',
                    fontFamily: 'Maplestory',
                    fontSize: '4vw',
                    lineHeight: '150%',
                    textAlign: 'center',
                  }}
                >
                  복돼지 키우기가 종료되었어요 <br />
                </div>
              </div>
            )}
            <LuckyPiggyGaugeBar
              level={level}
              remainExp={remainExp}
              expToLevelUp={expToLevelUp}
              // exp={exp}
            />
            <Piggy level={level} />
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <PiggyGuideIcon
                type='giftList'
                onClick={() => {
                  setShowGiftListModal(true)
                }}
              />
              <PiggyGuideIcon
                type='openGift'
                onClick={() => {
                  setShowOpenGiftModal(true)
                }}
              />
              {/* <Coin
                token={token}
                ongoingData={ongoingData}
                setOngoingData={setOngoingData}
              /> */}

              <div
                style={{
                  position: 'relative',
                  marginBottom: '3vw',
                }}
              >
                <img
                  style={{
                    width: '20vw',
                    marginLeft: '14vw',
                  }}
                  onClick={() => {
                    if (canGiveCoin) {
                      giveCoin()
                    }
                  }}
                  alt=''
                  src={`/luckyPiggy/coin.png`}
                />

                <div className='bt-coin-label-box'>
                  <div className='bt-coin-label'>{ongoingData?.coin}개</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            color: 'white',
            fontSize: '5vw',
            fontFamily: 'maplestory',
            textAlign: 'center',
            marginTop: '5vw',
            background: inviteButtonPressed
              ? 'linear-gradient(180deg, #F2A173 0%, #E43131 100%)'
              : 'linear-gradient(180deg, #F2A173 0%, #E43131 100%)',
            boxShadow: inviteButtonPressed
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',

            width: '80%',
            marginLeft: '10%',
            padding: '5vw',
            borderRadius: '50vw',
          }}
          onPointerDown={() => {
            setInviteButtonPressed(true)
          }}
          onPointerCancel={() => {
            setInviteButtonPressed(false)
          }}
          onPointerUp={() => {
            setInviteButtonPressed(false)
          }}
          onClick={() => {
            shareKakao()
          }}
          className='scaleUp'
        >
          친구 초대하고 랜덤 코인 받기
        </div>
        <div
          style={{
            color: 'black',
            fontSize: '3.5vw',
            fontFamily: 'maplestory',
            textAlign: 'center',
            marginTop: '2vw',
          }}
        >
          올팜을 안 해본 친구를 초대하면 더 많은 코인을 받을 수 있어요
        </div>

        <div>
          <div
            style={{
              width: '80%',
              fontFamily: 'maplestory',
              borderRadius: '3vw',
              marginLeft: '10vw',
              marginTop: '5vw',
              marginBottom: '3vw',
              color: 'black',
              fontSize: '5.5vw',
            }}
          >
            미션을 하면 코인을 추가로 받아요
          </div>
        </div>
        <LuckyPiggyQuest
          token={token}
          ongoingData={ongoingData}
          setOngoingData={setOngoingData}
          onClick={shareKakao}
        />
        <LuckyPiggyInviteList token={token} />
        <div
          style={{
            width: '100vw',
          }}
        >
          <div
            style={{
              width: '100vw',
              justifyContent: 'center',
              display: 'table',
            }}
          >
            <div
              style={{
                color: 'black',
                fontFamily: 'maplestory',
                fontSize: '15px',
                marginBottom: '4vw',
                flexDirection: 'row',
                marginLeft: '6vw',
                marginRight: '6vw',
                marginTop: '10vw',
              }}
            >
              이벤트 안내
            </div>
            <div
              style={{
                color: 'black',
                fontFamily: 'maplestory',
                fontSize: '13px',
                marginLeft: '6vw',
                marginRight: '6vw',
                marginBottom: '8vw',
              }}
            >
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 복돼지 키우기는 28일 자정에 종료돼요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 키우던 돼지가 있더라도 28일 자정 이후로 이벤트가 종료되니
                이벤트 종료 전에 꼭 송금을 완료해주세요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 복돼지를 키워 용돈을 받을 수 있어요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 친구를 올팜에 초대하면 코인을 받아요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 코인은 랜덤으로 지급되며 올팜을 안 해본 친구를 초대하면 더
                많은 코인을 받아요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 이미 올팜을 하고 있는 친구도 초대할 수 있어요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 친구를 초대해도 코인이 지급되지 않는 경우 올팜을 시작하고
                친구가 보낸 초대 링크를 다시 클릭해주세요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 올웨이즈 탈퇴 이력이 있는 경우 탈퇴일로부터 7일간 코인을 받을
                수 없어요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 선물을 받으면 코인은 소멸되며 다음 복돼지 키우기에 이관되지
                않아요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 선물은 레벨 단위로 변경되며 각 레벨의 경험치는 보상에 영향을
                주지 않아요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 자세한 내용은 이벤트 페이지 상단의 "복돼지 키우기란?"을
                참고해주세요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 정상적이지 않은 방법으로 코인을 획득하거나 경험치를 획득하는
                경우 경품 발송이 제한 될 수 있어요
              </div>
              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 이벤트 종료 후 경품 발송은 불가해요 반드시 이벤트 종료 전까지
                받아주세요
              </div>

              <div
                style={{
                  color: 'black',
                  marginBottom: '2vw',
                }}
              >
                - 이벤트는 사전 고지 없이 변경 및 종료 될 수 있어요
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoadingIndicator isLoading={isLoading} />
      {openFeedingModal && (
        <div>
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 5004,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }}
          >
            <div
              style={{
                position: 'relative',
                top: '25%',
              }}
            >
              <img
                style={{
                  width: '90%',
                  left: '5%',
                  position: 'absolute',
                }}
                alt=''
                src={`/luckyPiggy/howTo${howToModalNumber}.png`}
              />
              {howToModalNumber <= 4 && (
                <button
                  onClick={() => {
                    setHowToModalNumber(howToModalNumber + 1)
                  }}
                >
                  <img
                    style={{
                      width: '15%',
                      top: '35vw',
                      right: '13vw',
                      position: 'absolute',
                    }}
                    src='/luckyPiggy/nextButton.png'
                    alt=''
                  />
                </button>
              )}
              {howToModalNumber >= 2 && howToModalNumber < 6 && (
                <button
                  onClick={() => {
                    setHowToModalNumber(howToModalNumber - 1)
                  }}
                >
                  <img
                    style={{
                      width: '15%',
                      top: '35vw',
                      left: '13vw',
                      position: 'absolute',
                    }}
                    src='/luckyPiggy/prevButton.png'
                    alt=''
                  />
                </button>
              )}
            </div>
            <button
              style={{
                width: '10%',
                top: '17%',
                right: '12%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                setOpenFeedingModal(false)
                setHowToModalNumber(1)
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
          </div>
        </div>
      )}

      {showGiftListModal && (
        <LuckyPiggyGiftListModal setShowGiftListModal={setShowGiftListModal} />
      )}
      {showOpenGiftModal && (
        <LuckyPiggyOpenGiftModal
          setShowOpenGiftModal={setShowOpenGiftModal}
          level={level}
          setOpenSendMoneyModal={setOpenSendMoneyModal}
          token={token}
          setMoneyAmount={setMoneyAmount}
          moneyAmount={moneyAmount}
          ongoingData={ongoingData}
          openSendMoneyModal={openSendMoneyModal}
        />
      )}
      {openNeedCoinModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: '10%',
            top: '90vw',
            zIndex: 2000,
            width: '80%',
            height: '25vw',
            background: 'rgba(0,0,0,0.8)',
            borderRadius: 20,
            fontFamily: 'maplestory',
            fontSize: '4vw',
            textAlign: 'center',
            paddingTop: '6vw',
          }}
        >
          코인이 부족해요
          <div style={{ height: '3vw' }} />
          친구를 초대해서 코인을 얻을 수 있어요
        </div>
      )}
      {ongoingData?.moneyAmount && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column',
              padding: 16,
              backgroundImage: 'url(../luckyPiggy/modalBg4.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '40vw',
            }}
          >
            <div
              style={{
                paddingTop: '30vw',
                color: 'black',
                fontFamily: 'Maplestory',
                fontSize: '6vw',
              }}
            >
              {commaNumber(ongoingData?.moneyAmount)}원 받았어요!
            </div>
            <NavLink
              to={`/sendMoneyPage?token=${token}&itemType=${itemType}`}
              style={{ width: '60vw' }}
            >
              <button
                style={{
                  position: 'absolute',
                  background: isOpenGiftBoxButtonPressed
                    ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                    : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                  boxShadow: isOpenGiftBoxButtonPressed
                    ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                    : '',
                  color: '#402C24',
                  fontSize: '5vw',
                  borderRadius: '50vw',
                  paddingTop: '5vw',
                  paddingBottom: '5vw',
                  bottom: '40vw',
                  zIndex: 1,
                  fontFamily: 'maplestory',
                  width: '60vw',
                }}
                onPointerDown={() => {
                  setIsOpenGiftBoxButtonPressed(true)
                }}
                onPointerCancel={() => {
                  setIsOpenGiftBoxButtonPressed(false)
                }}
                onPointerUp={() => {
                  setIsOpenGiftBoxButtonPressed(false)
                }}
                onClick={() => {
                  // setClickedButton(true);
                  // openLuckyPiggyBox();
                }}
              >
                내 계좌로 용돈 보내기
              </button>
            </NavLink>
          </div>
        </div>
      )}
      {openSendMoneyModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column',
              padding: 16,
              backgroundImage: 'url(../luckyPiggy/modalBg4.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '40vw',
            }}
          >
            <div
              style={{
                paddingTop: '30vw',
                color: 'black',
                fontFamily: 'Maplestory',
                fontSize: '6vw',
              }}
            >
              {commaNumber(moneyAmount)}원 받았어요!
            </div>
            <NavLink
              to={`/sendMoneyPage?token=${token}&itemType=${itemType}`}
              style={{ width: '60vw' }}
            >
              <button
                style={{
                  position: 'absolute',
                  background: isOpenGiftBoxButtonPressed
                    ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                    : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                  boxShadow: isOpenGiftBoxButtonPressed
                    ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                    : '',
                  color: '#402C24',
                  fontSize: '5vw',
                  borderRadius: '50vw',
                  paddingTop: '5vw',
                  paddingBottom: '5vw',
                  bottom: '40vw',
                  zIndex: 1,
                  fontFamily: 'maplestory',
                  width: '60vw',
                }}
                onPointerDown={() => {
                  setIsOpenGiftBoxButtonPressed(true)
                }}
                onPointerCancel={() => {
                  setIsOpenGiftBoxButtonPressed(false)
                }}
                onPointerUp={() => {
                  setIsOpenGiftBoxButtonPressed(false)
                }}
                onClick={() => {
                  // setClickedButton(true);
                  // openLuckyPiggyBox();
                }}
              >
                내 계좌로 용돈 보내기
              </button>
            </NavLink>
          </div>
        </div>
      )}
    </>
  )
}

export default LuckyPiggy
