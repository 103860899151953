import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import BottomSheetStore from '../../store/BottomSheetStore'
import ModalStore from '../../store/ModalStore'
import UserStore from 'store/UserStore'

const Modal = ({
  farmData,
  setFarmData,
  token,
  giveWaterBasket,
  setOpenIndicator,
  setCheckShowPuppy,
  giveWaterCount,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [userQuestData, setUserQuestData] = useState('')
  const [treasureBoxRewardData, setTreasureBoxRewardData] = useState({
    showReward: false,
    modalRewardData: {},
  })

  useEffect(() => {
    logTreasureBoxMissionStartedAt()
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoaded(false)
    const result = await backendApis.checkTreasureBoxStatus(token)
    if (result.status === 200 && result.data) {
      setUserQuestData(result.data)
      setIsLoaded(true)
    }
    setIsLoaded(true)
  }

  const logTreasureBoxMissionStartedAt = async () => {
    setIsLoaded(false)
    if (farmData && !farmData.treasureBoxMissionStartedAt) {
      const result = await backendApis.updatelastUpdatedAt(token, 'PUT', {
        updateType: 'treasureBoxMissionStartedAt',
      })
      if (result.status === 200) {
        setFarmData({ ...farmData, treasureBoxMissionStartedAt: new Date() })
        setIsLoaded(true)
      }
    }
  }

  const openTreasureBox = async (questType) => {
    setIsClicked(true)
    const result = await backendApis.openNewbieTreasureBox(token, 'PUT', {
      quest: questType,
    })
    if (result.status === 200 && result.data) {
      const { rewardType, valueAmount, questCompleted } = result.data
      const setModalRewardData = () => {
        if (rewardType === 'water') {
          return {
            cardMent: `물 ${valueAmount}g`,
            modalMent: [`축하해요!`, `물 ${valueAmount}g을 받았어요`],
            src: '/icon/newbieTreasureBox/rewardWater.png',
          }
        }
        if (rewardType === 'puppy') {
          return {
            cardMent: `바둑이 ${valueAmount}일`,
            modalMent: [`축하해요!`, `바둑이 ${valueAmount}일에 당첨됐어요`],
            src: '/icon/newbieTreasureBox/rewardPuppy.png',
          }
        }
        if (rewardType === 'fertilizer') {
          return {
            cardMent: `비료 ${valueAmount}개`,
            modalMent: [`축하해요!`, `비료 ${valueAmount}개를 받았어요`],
            src: '/icon/newbieTreasureBox/rewardFertilizer.png',
          }
        } else return { cardMent: '', modalMent: '', src: '' }
      }
      const modalRewardData = setModalRewardData()
      setTreasureBoxRewardData({
        showReward: true,
        questCompleted,
        modalRewardData: modalRewardData,
      })
      if (rewardType === 'puppy') {
        setCheckShowPuppy(true)
      }
    }
    setIsClicked(false)
  }

  const giveWater = () => {
    setIsClicked(true)
    ModalStore.setIsModalOpen('basic')
    giveWaterBasket()
    setIsClicked(false)
  }

  const logVisitReviewModal = async () => {
    if (!farmData.treasureBoxVisitReviewMissionFinishedAt) {
      await backendApis.updatelastUpdatedAt(token, 'PUT', {
        updateType: 'treasureBoxVisitReviewMissionFinishedAt',
      })
    }
  }

  const visitReview = () => {
    setIsClicked(true)
    ModalStore.setIsModalOpen('review')
    logVisitReviewModal()
    setUserQuestData({
      ...userQuestData,
      visitReview: {
        opened: false,
        count: 1,
        maxCount: 1,
      },
    })
    setIsClicked(false)
  }

  const giveFertilizer = () => {
    setIsClicked(true)
    ModalStore.setIsModalOpen('basic')
    BottomSheetStore.setIsbottomSheetOpen('fertilizer')
    setOpenIndicator(true)
    setIsClicked(false)
  }

  const ModalTitle = ({ text = [''], color = 'black', fontSize = '4.4vw' }) => {
    return (
      <div
        style={{
          width: '100%',
        }}
      >
        {text.map((i, index) => {
          return (
            <div
              style={{
                color,
                fontSize,
                fontFamily: 'maplestory',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '1vw',
              }}
              key={i.toString() + index.toString()}
            >
              {i} <br></br>
            </div>
          )
        })}
      </div>
    )
  }

  const Button = ({ data }) => {
    const { opened, count, maxCount, onClickQuest } = data
    const [uiConfig, setUiConfig] = useState({ text: '', color: 'gray' })

    useEffect(() => {
      if (opened) {
        setUiConfig({ text: '완료', color: '#C5C5C5' })
      } else {
        if (count < maxCount) {
          setUiConfig({
            text: `${count}/${maxCount}번`,
            color: '#64B226',
          })
        } else {
          setUiConfig({ text: `받기`, color: '#FFC700' })
        }
      }
    }, [count])

    return (
      <button
        disabled={isClicked || opened}
        onClick={
          opened
            ? () => {}
            : () => {
                onClickQuest()
                UserStore.set('pingManager.treasureBox', false)
              }
        }
        style={{
          ...styles.button,
          backgroundColor: uiConfig?.color,
        }}
      >
        {uiConfig?.text}
      </button>
    )
  }

  const MissionList = ({ data }) => {
    if (!data) {
      return null
    }
    return data.map((each, index) => (
      <div
        style={{
          width: '100%',
          marginBottom: '1vw',
          paddingBottom: '2vw',
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          alignItems: 'center',
          zIndex: 2900,
        }}
        key={index.toString()}
      >
        <img src={each.src} alt='' style={{ width: '100vw', zIndex: 5 }} />
        <Button data={each} />
      </div>
    ))
  }

  const ModalBody = () => {
    if (
      treasureBoxRewardData.showReward &&
      treasureBoxRewardData.modalRewardData
    ) {
      const { modalMent, src, cardMent } = treasureBoxRewardData.modalRewardData
      const { questCompleted } = treasureBoxRewardData
      return (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 100,
            paddingLeft: '5%',
            paddingRight: '5%',
            width: '100%',
            height: '300%',
            justifyContent: 'space-between',
            marginTop: '-5vw',
            overflow: 'hidden',
          }}
        >
          <div>
            <ModalTitle text={modalMent} />
          </div>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '66%',
              marginBottom: '2vw',
            }}
          >
            <img src={src} alt='' />
            <div
              style={{
                position: 'absolute',
                bottom: '16%',
                color: 'black',
                fontSize: '1em',
                fontFamily: 'maplestory',
              }}
            >
              {cardMent}
            </div>
          </div>

          <button
            style={{
              height: '11vw',
              width: '40vw',
              padding: '1vw',
              backgroundColor: '#FFC700',
              fontSize: '4vw',
              color: 'black',
              fontFamily: 'maplestory',
              borderRadius: '2vw',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
              if (questCompleted) {
                setFarmData({
                  ...farmData,
                  treasureBoxMissionFinishedAt: new Date(),
                })
              }
            }}
          >
            닫기
          </button>
        </div>
      )
    } else {
      return (
        <div
          style={{
            ...styles.modalBody,
            // overflow: "hidden",
          }}
        >
          <div>
            <ModalTitle text={['미션하고 보물상자를 열어보세요!']} />
            <ModalTitle
              text={['아래 중 하나의 선물을 랜덤으로 받아요']}
              color='gray'
              fontSize='3.6vw'
            />
            <div style={{ height: '2vw' }}></div>
            <img
              style={{ width: '70vw' }}
              src='/icon/newbieTreasureBox/rewardList.png'
              alt=''
            />
            <div style={{ height: '4vw' }}></div>
          </div>
          <div style={{ width: '100%', zIndex: 100 }}>
            <ModalTitle text={['미션 리스트']} />
            {/* <div style={{ height: "3vw" }}></div> */}
            <MissionList
              data={[
                {
                  type: 'giveWater',
                  src: '/icon/newbieTreasureBox/questGiveWater.png',
                  onClickQuest:
                    userQuestData['giveWater'].count >=
                    userQuestData['giveWater'].maxCount
                      ? () => openTreasureBox('giveWater')
                      : giveWater,
                  ...userQuestData['giveWater'],
                },
                {
                  type: 'visitReview',
                  src: '/icon/newbieTreasureBox/questVisitReview.png',
                  onClickQuest:
                    userQuestData['visitReview'].count >=
                    userQuestData['visitReview'].maxCount
                      ? () => openTreasureBox('visitReview')
                      : visitReview,
                  ...userQuestData['visitReview'],
                },
                {
                  type: 'giveFertilizer',
                  src: '/icon/newbieTreasureBox/questGiveFertilizer.png',
                  onClickQuest:
                    userQuestData['giveFertilizer'].count >=
                    userQuestData['giveFertilizer'].maxCount
                      ? () => openTreasureBox('giveFertilizer')
                      : giveFertilizer,
                  ...userQuestData['giveFertilizer'],
                },
              ]}
            />
          </div>
        </div>
      )
    }
  }

  const ModalFrame = () => {
    return (
      <div style={{ zIndex: 5000 }}>
        <div
          style={{
            ...styles.background,
          }}
        />

        <div
          style={{
            ...styles.modalFrame,
            backgroundImage:
              treasureBoxRewardData.showReward &&
              treasureBoxRewardData.modalRewardData
                ? 'url(../icon/newbieTreasureBox/modalSmall.png)'
                : 'url(../icon/newbieTreasureBox/modalBig.png)',
            width:
              treasureBoxRewardData.showReward &&
              treasureBoxRewardData.modalRewardData
                ? '70vw'
                : '90vw',
          }}
        >
          {giveWaterCount <= 16 && (
            <img
              style={{
                position: 'absolute',
                top: '65%',
                right: '-3%',
                transform: 'translate(-50%, -50%)',
                width: '12vw',
                zIndex: 222,
              }}
              src='/icon/finger.png'
              alt=''
              className='slide-tl'
            />
          )}
          <button
            style={{
              width: '10%',
              top: '12vw',
              right: '2vw',
              zIndex: 100,
              position: 'absolute',
              cursor: 'pointer',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img src='/icon/modalCloseButton.png' alt='' />
          </button>
          <ModalBody />
        </div>
      </div>
    )
  }

  if (userQuestData.length === 0 || !isLoaded) {
    return null
  }
  return <ModalFrame />
}

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 5000,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalFrame: {
    width: '90vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 5001,
    padding: 16,
    backgroundImage: 'url(../icon/newbieTreasureBox/modalBig.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '52vw',
    paddingBottom: '18vw',
  },
  modalBody: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 100,
    paddingLeft: '5%',
    paddingRight: '5%',
    width: '100%',
    height: '300%',
    justifyContent: 'space-between',
    marginTop: '5vw',
    overflow: 'hidden',
    // backgroundColor: "black",
  },

  missionList: {
    width: '100%',
    marginBottom: '1vw',
    paddingBottom: '2vw',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
    zIndex: 2900,
  },
  button: {
    zIndex: 10,
    position: 'absolute',
    right: '-1vw',
    width: '36%',
    height: '70%',
    color: 'black',
    fontSize: '4vw',
    borderRadius: '3vw',
    fontFamily: 'maplestory',
  },
}

export default Modal
