import { observable } from 'mobx'

const UserStore = observable({
  giveWaterCount: 10,
  itemType: 'onion',
  solvedQuizItemId: null,
  dailyPurchaseHistoryCount: null,
  farmData: {},
  guessMeFriendList: [],
  default_profile_image:
    'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png',
  orderCount: null,
  codePushVersionFromAlwayzApp: '4.8.32',
  purchaseHistorys: [],
  cherryPickerForAd: false,
  showPuppy: false,
  pingManager: {
    dailyMission: false,
    treasureBox: false,
  },
  waterExceeded: false,
  isRewardAdReady: false,
  isWatchingAd: false,
  isFirstEnteredShortFormShopAd: false,


  setGuessMeFriendList(data) {
    this.guessMeFriendList = [...data]
  },

  setFarmData(data) {
    this.farmData = { ...data }
    // if (
    //   (data?.water ?? this.farmData?.water) > 3000 &&
    //   ABStore.waterMaximumTester
    // ) {
    //   this.waterExceeded = true
    // }
  },
  setGiveWaterCount(count) {
    this.giveWaterCount = count
  },
  setSolvedQuizItemId(itemId) {
    this.solvedQuizItemId = itemId
  },
  setItemType(type) {
    this.itemType = type
  },
  setDailyPurchaseHistoryCount(count) {
    this.dailyPurchaseHistoryCount = count
  },
  setOrderCount(count) {
    this.orderCount = count
  },
  setCodePushVersionFromAlwayzApp(version) {
    this.codePushVersionFromAlwayzApp = version
  },
  setPurchaseHistorys(data) {
    this.purchaseHistorys = data
  },
  setCherryPickerForAd(data) {
    this.cherryPickerForAd = data
  },
  set(key, value) {
    function setProperty(obj, keys, val) {
      let key
      while (keys.length > 1) {
        key = keys.shift()
        if (!Object.prototype.hasOwnProperty.call(obj, key)) obj[key] = {}
        // if (!obj.hasOwnProperty(key)) obj[key] = {}
        obj = obj[key]
      }
      obj[keys[0]] = val
    }

    if (key?.includes('.')) {
      const keys = key.split('.')
      setProperty(this, keys, value)
    } else {
      this[key] = value
    }
  },
  setIsRewardAdReady(isRewardAdReady) {
    this.isRewardAdReady = isRewardAdReady
  },
  setIsWatchingAd(isWatchingAd) {
    this.isWatchingAd = isWatchingAd
  },
  setIsFirstEnteredShortFormShopAd(isFirstEnteredShortFormShopAd) {
    this.isFirstEnteredShortFormShopAd = isFirstEnteredShortFormShopAd
  }
})

export default UserStore
