import React, { useState, useEffect } from 'react'

const IncreaseWaterNumber = ({
  initialNumber,
  maxNumber,
  increasePerSecond,
  interval = 100,
  setBasicWater,
  farmData,
}) => {
  const [increaseAmount, setIncreaseAmount] = useState(initialNumber)

  useEffect(() => {
    setIncreaseAmount(initialNumber)
  }, [initialNumber])

  useEffect(() => {
    const leftTimeDiff = setTimeout(() => {
      if (maxNumber <= increaseAmount + increasePerSecond / 10) {
        setIncreaseAmount(maxNumber)
        setBasicWater((prev) => maxNumber)
      } else {
        const newIncreaseAmount = increaseAmount + increasePerSecond / 10
        setIncreaseAmount(newIncreaseAmount)
        if (
          Math.floor(newIncreaseAmount) >
          Math.floor(newIncreaseAmount - increasePerSecond / 10)
        ) {
          // setBasicWater(newIncreaseAmount)
          setBasicWater((prev) => newIncreaseAmount)
        }
      }
    }, interval)
    return () => clearTimeout(leftTimeDiff)
  }, [increaseAmount, maxNumber])

  if (farmData?.giveWaterCount <= 4) return null

  return (
    <div
      style={{
        fontSize: '2.4vw',
        zIndex: 2,
        color: 'black',
      }}
    >
      {increaseAmount?.toFixed(2)}
    </div>
  )
}

export default IncreaseWaterNumber
