import ModalStore from '../../store/ModalStore'
import React from 'react'
import PlantBasicInfo from './PlantBasicInfo'

const getItemName = (itemType) => {
  return PlantBasicInfo.PlantNameAndAmount(itemType).koreanName
}

const PlantName = (props) => {
  const { farmData, itemType, gganbuMode } = props
  const beforeNaming =
    !farmData?.myItemName || farmData?.myItemName?.length === 0
  const Indicator = () => {
    if (
      !farmData?.openedNameMyItemModal &&
      (!farmData?.myItemName || farmData?.myItemName?.length === 0)
    ) {
      return (
        <img
          className='wobble-ver-left absolute top-[-4vw] right-[-2vw] w-[6vw] p-[-4vw]'
          src='/icon/namingAlert.png'
          alt=''
        />
      )
    } else return null
  }
  if (gganbuMode) {
    if (beforeNaming) {
      return null
    } else
      return (
        <div className='left-[50%] -translate-x-1/2 bg-[rgba(25,25,25,0.5)] w-[30vw] rounded-[2vw] absolute p-[1.1vw] bottom-[14vw] z-[100]'>
          <div className='text-white text-center text-[4.2vw] font-maplestory flex justify-center'>
            {farmData?.myItemName}
          </div>
        </div>
      )
  } else {
    return (
      <div
        className='left-[50%] -translate-x-1/2 bg-[rgba(25,25,25,0.5)] w-[30vw] rounded-[2vw] absolute p-[1.1vw] bottom-[14vw] z-[100]'
        onClick={() => {
          ModalStore.setIsModalOpen('plantName')
        }}
      >
        <div className='text-white text-center text-[4.2vw] font-maplestory flex justify-center'>
          {beforeNaming ? getItemName(itemType) : farmData?.myItemName}
        </div>
        <Indicator />
      </div>
    )
  }
}

export default PlantName
