import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'

const FeverModeModal = observer(({ farmData }) => {
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1005,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.8)',
        }}
      />
      <div
        style={{
          transform: 'translate(-50%, -50%)',
          left: '50%',
          top: '50%',
          position: 'absolute',
          zIndex: 100111,
          width: '100vw',
          height: '100vh',
        }}
      >
        <div>
          <img
            src='/feverMode/body.png'
            alt=''
            style={{
              width: '100vw',
              zIndex: -111,
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '35%',
            }}
          />
          <img
            src='/feverMode/title.png'
            alt=''
            style={{
              width: '100vw',
              zIndex: 100111,
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '30%',
            }}
          />

          <div
            style={{
              borderRadius: '4vw',
              background: '#FFFFFF',
              color: '#000000',
              textAlign: 'center',
              width: '80vw',
              padding: '4vw',
              fontSize: '5vw',
              zIndex: 1,
              marginLeft: '10vw',
              border: '1vw solid #FFCA28',
              marginTop: '-10vw',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              left: '40%',
              top: '60%',
            }}
          >
            떨어지는 비료를 잡아보세요! <br /> 10개 당 1개의 일반비료를 얻을 수
            있어요!
          </div>
          <button
            style={{
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '60%',
              position: 'absolute',
              marginTop: '30vw',
              fontSize: '5vw',
              textAlign: 'center',
              zIndex: 2,
              borderRadius: 30,
              width: '80%',
              height: '14vw',
              background:
                'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            }}
            onClick={() => {
              //   modalClose();
              ModalStore.setIsModalOpen('feverModeStart')
            }}
          >
            <div
              style={{
                color: '#402C24',
                fontFamily: 'maplestory',
              }}
            >
              시작하기
            </div>
          </button>
          <button
            style={{
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '68.5%',
              position: 'absolute',
              marginTop: '30vw',
              fontSize: '5vw',
              textAlign: 'center',
              zIndex: 2,
              borderRadius: 30,
              width: '40%',
              height: '14vw',
              border: '1vw solid #FFCA28',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <div
              style={{
                color: 'white',
                fontFamily: 'maplestory',
              }}
            >
              다음에 하기
            </div>
          </button>
        </div>
      </div>
    </>
  )
})

const style = {
  confirm: {
    width: '80vw',
    borderRadius: '100vw',
    fontSize: '6.5vw',
    padding: '3.5vw',
    background: '#FFCA28',
    marginLeft: '10vw',
    color: '#593306',
    position: 'fixed',
    bottom: '12vw',
  },
}

export default FeverModeModal
