import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import UserStore from 'store/UserStore'

const TutorialFinisedModal = observer(
  ({
    farmData,
    setFarmData,
    token,
    setTutorialFinishedModal,
    setAddWater,
    setAddFertilizerSet,
    setIsTreasureBoxMissionTargetUser,
  }) => {
    const [rewardAnim, setRewardAnim] = useState(false)
    const [isButtonClicked, setIsButtonClicked] = useState(false)
    const clearedTutorial = async () => {
      const result = await backendApis.clearedTutorial(token)
      if (result.status === 200) {
        setFarmData((prev) => {
          let tmpFarmData = prev
          if (UserStore.waterExceeded) {
            tmpFarmData.water += Math.round(
              result?.data?.addedValue?.addedWater * 0.5,
            )
            setAddWater(Math.round(result?.data?.addedValue?.addedWater * 0.5))
          } else {
            tmpFarmData.water += result?.data?.addedValue?.addedWater
            setAddWater(result?.data?.addedValue?.addedWater)
          }

          tmpFarmData.fertilizerSet +=
            result?.data?.addedValue?.addedFertilizerSet
          return tmpFarmData
        })
        setAddFertilizerSet(result?.data?.addedValue?.addedFertilizerSet)
        setRewardAnim(true)
      }
    }

    const rewardAnimationImage = () => {
      return (
        <div>
          <div
            style={{
              position: 'fixed',
              zIndex: 1000,
              top: '37%',
              width: '25%',
              left: '38%',
            }}
            className='modal-scale-up-center'
          >
            <img alt='' src='/effect/levelUpRewardMotion.gif' />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: 999,
              top: '24%',
              width: '80%',
              left: '10%',
            }}
            className='modal-scale-up-center'
          >
            <img alt='' src='/icon/levelUpRewardBackGround.png' />
          </div>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <div
              style={{
                position: 'fixed',
                zIndex: 999,
                top: '57%',
                width: '80%',
                left: '10%',
                fontFamily: 'maplestory',
                fontSize: 24,
                fontWeight: 'bold',
              }}
            >
              튜토리얼 완료 보상을 받았어요!
              <div
                style={{
                  marginTop: 6,
                  fontFamily: 'maplestory',
                  fontSize: 18,
                  fontWeight: 'lighter',
                }}
              >
                물 100g, 고급비료 1개
              </div>
            </div>
          </div>
          <button
            style={{
              width: '30%',
              height: 55,
              top: '73%',
              left: '50%',
              transform: 'translate(-50%, -80%)',
              position: 'absolute',
              zIndex: 999,
              borderRadius: 30,
              background: isButtonClicked
                ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              boxShadow: isButtonClicked
                ? 'inset 3vw 3vw 4vw rgba(79, 19, 0, 0.15)'
                : '',
              fontFamily: 'maplestory',
              color: '#402C24',
              fontSize: 18,
            }}
            onClick={() => {
              setTutorialFinishedModal(false)
            }}
            onPointerDown={() => {
              setIsButtonClicked(true)
            }}
            onPointerCancel={() => {
              setIsButtonClicked(false)
            }}
            onPointerUp={() => {
              setIsButtonClicked(false)
            }}
          >
            확인
          </button>
        </div>
      )
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 999,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        />
        {!rewardAnim && (
          <button
            onClick={() => {
              clearedTutorial()
              setTutorialFinishedModal(false)
              setIsTreasureBoxMissionTargetUser(true)
              ModalStore.setIsModalOpen('treasureBox')
            }}
          >
            <div
              style={{
                position: 'fixed',
                zIndex: 1000,
                top: '30%',
                width: '80%',
                left: '10%',
              }}
              className='scale-up-hor-center'
            >
              <img alt='' src={`/icon/tutorialClearModal.png`} />
              <button
                style={{
                  width: '90%',
                  bottom: '2vw',
                  left: '4%',
                  borderRadius: 30,
                  zIndex: 999,
                  position: 'absolute',
                }}
                className='scale-up-center-level-up-button'
              >
                <img src='/levelUpReward/levelRewardButton.png' alt='' />
              </button>
            </div>
          </button>
        )}
        {rewardAnim && <> {rewardAnimationImage()}</>}
      </>
    )
  },
)

export default TutorialFinisedModal
