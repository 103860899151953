import axios from 'axios'
import AuthStore from 'store/AuthStore'

const client = axios.create({
  baseURL: 'https://alwayz-alranch-back.prod.ilevit.com',
  headers: {
    'Content-type': 'application/json',
  },
})

client.interceptors.request.use(async (config) => {
  const token = AuthStore.token
  if (config.headers && token) {
    config.headers.set('x-access-token', token)
  }
  return config
})

export default client
