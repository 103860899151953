import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import dayjs from 'dayjs'
import ModalStore from '../../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const CheckInPurchaseBefore = observer(({ token, farmData }) => {
  const throttleRef = useRef({})

  const [isButtonPressed, setIsButtonPressed] = useState(false)
  return (
    <div
      style={{
        flex: 1,
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 5004,
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.8)',
      }}
    >
      <div
        style={{
          width: '90vw',
          height: '180vw',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          justifyContent: 'space-around',
          alignItems: 'center',
          backgroundImage: 'url(../checkInPurchase/modal.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div
          style={{
            background: isButtonPressed ? '#F7C82A' : '#F7C82A',
            boxShadow: isButtonPressed
              ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
              : '',
            width: '65vw',
            height: '14vw',
            borderRadius: '100vw',
            color: 'black',
            fontFamily: 'maplestory',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4.5vw',
            position: 'absolute',
            bottom: '23%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          onClick={() => {
            AlfarmEventLogger({
              throttleRef,
              locationType: 'modal',
              locationName: ModalStore.isModalOpen,
              eventType: 'click',
              eventName: 'cta',
              data: {},
              collection: 'UserAlfarmClickLog',
            })

            const purchaseData = {
              enteringComponent: 'Alfarm_checkInPurchase',
              text: '올팜 연속출석 기념',
              source:
                'https://assets.ilevit.com/1ddf89f2-f6de-4243-8fb4-525aaa43f324.png',
              rewardAmount: 2000,
              rewardType: '물 2,000g',
              rewardText: '상품을 구매하면 물 2,000g을 받아요',
            }

            window.location.href = `#commonPurchaseReward.${JSON.stringify(
              purchaseData,
            )}`
            ModalStore.setIsModalOpen('basic')
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
        >
          상품 구매하고 2,000g 받기
        </div>
        <div
          style={{
            color: 'black',
            marginBottom: '-100%',
            fontSize: '3.5vw',
            textAlign: 'center',
          }}
        >
          보상은 상품 구매 후 물받기에서 받을 수 있어요 <br />
        </div>
        <button
          style={{
            width: '10%',
            top: '20vw',
            right: '10%',
            position: 'absolute',
            zIndex: 1113,
            background: 'black',
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      </div>
    </div>
  )
})

export default CheckInPurchaseBefore
