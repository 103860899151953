import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import QRCode from 'react-qr-code'
import { isAndroid, isIOS, isIOS13, is } from 'react-device-detect'
import ModalStore from '../../store/ModalStore'

const QRBonusModal = observer(
  ({
    setShowModal,
    farmData,
    setFarmData,
    userCodePushVersion,
    codePushVersionAvailable,
  }) => {
    const [linkForQR, setLinkForQR] = useState()

    useEffect(() => {
      window.location.href = '#alfarmQRBonus'
      const saveLink = ({ data }) => {
        setLinkForQR(data)
      }

      if (isIOS || isIOS13) {
        window.addEventListener('message', saveLink)
        return () => {
          window.removeEventListener('message', saveLink)
        }
      } else {
        document.addEventListener('message', saveLink)
        return () => {
          document.removeEventListener('message', saveLink)
        }
      }
    }, [])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage: 'url(../qrBonus/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '57vw',
              paddingBottom: '16%',
              // marginBottom: "15%",
            }}
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: '20vw',
                position: 'absolute',
                zIndex: 999,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                // border: "1px solid black",
                width: '100%',
                height: '100%',
                // marginBottom: "12vw",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  marginBottom: 4,
                }}
              >
                친구에게 QR코드를 보여주세요!
              </div>
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  marginBottom: 8,
                }}
              >
                랜덤하게 물을 얻을 수 있어요
              </div>
              <div
                style={{
                  display: 'flex',
                  // border: "10px solid red",
                  flex: 1,
                  width: '100%',
                  padding: '8px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {linkForQR ? (
                  <QRCode size={100} value={linkForQR} />
                ) : (
                  <div style={{ filter: 'blur(5px)' }}>
                    <QRCode size={100} value={'alwayz'} />
                  </div>
                )}
              </div>
              <div
                style={{
                  width: '100%',
                  height: '20vw',
                  backgroundImage: 'url(../qrBonus/reward.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  margin: '8px 0px 8px 0px',
                }}
              />

              <div
                style={{
                  width: '100%',
                  color: '#666',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  fontSize: '0.8rem',
                  margin: '8px 0px 8px 0px',
                }}
              >
                *보상 수령은 1일 1회만 가능합니다
                <br />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default QRBonusModal
