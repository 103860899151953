import React from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../store/ModalStore'

const MakeGganbuModal = observer(({}) => {
  const shareKakao = () => {
    const inviteData = {
      shareType: 'AlfarmMakeGganbu',
      // referralCode: farmData?.referralCode,
      title: '[올팜] 우리 맞팜해요!',
      description: '서로의 농장에서 물과 비료를 받을 수 있어요.',
      bannerImage:
        'https://assets.ilevit.com/7ab3f811-7cfb-4108-a3b9-d9b3bfc13867.png',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90vw',
            height: '150vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -55%)',
            display: 'flex',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '14vw',
            backgroundImage: 'url(../icon/makeGganbuModal.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            paddingTop: '47vw',
            paddingBottom: '24%',
          }}
        >
          <button
            style={{
              width: '8vw',
              right: 0,
              top: '10vw',
              position: 'absolute',
              zIndex: 5004,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 5004,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            style={{
              width: '116%',
              marginTop: '10vw',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                color: 'black',
                fontFamily: 'maplestory',
                fontWeight: '800',
                marginLeft: 4,
                fontSize: '5vw',
              }}
            >
              맞팜하고 고급비료 받아가세요!<div></div>{' '}
            </div>

            <div
              style={{
                width: '80vw',
                height: '10vw',
                color: 'black',
                fontFamily: 'Maplestory',
                textAlign: 'center',
                fontSize: '4.2vw',
              }}
            >
              시간안에 맞팜을 만들고 고급비료 6개 받으세요
              <br />
            </div>
            <img
              style={{
                width: '40vw',
                zIndex: 2,
              }}
              src='/icon/fertSetPakage.png'
              alt=''
            />

            <div
              style={{
                background:
                  'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                width: '60vw',
                height: '14vw',
                borderRadius: 99,
                color: 'black',
                fontFamily: 'Maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '2vw',
                marginBottom: '1vw',
                fontSize: '5vw',
              }}
              onClick={() => {
                shareKakao()
              }}
            >
              맞팜 친구 초대하기
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default MakeGganbuModal
