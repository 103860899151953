import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'

const changeItemList = (itemType) => {
  if (itemType === 'apple') {
    return ['세척 사과 2.5kg', '가정용 사과 2kg']
  }
  if (itemType === 'tomato') {
    return ['대추방울토마토 1kg', '스테비아 방울토마토 500g']
  }
  if (itemType === 'mandarin') {
    return ['노지 감귤 4.5kg', '하우스 감귤 랜덤사이즈 1.5kg']
  }
  if (itemType === 'persimmon') {
    return ['단감 2.5kg', '단감 1.5kg']
  }
}

const ChangePlantGetFasterModal = observer(
  ({ token, setShowModal, setShowButton, farmData, setFarmData }) => {
    const changePlantAmount = async () => {
      await backendApis.changePlantAmount(token, 'POST', {
        itemType: farmData?.itemType,
      })
      setShowButton(false)
      setShowModal(false)
    }

    const Title = ({
      text = [''],
      color = 'black',
      fontSize = '4.8vw',
      paddingLeft = 0,
      alignItems = 'center',
    }) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            paddingTop: '1vw',
            paddingLeft,
            alignItems,
          }}
        >
          {text.map((i, index) => {
            return (
              <div
                style={{
                  color,
                  fontSize,
                  fontFamily: 'maplestory',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingBottom: '1vw',
                }}
                key={i.toString() + index.toString()}
              >
                {i} <br></br>
              </div>
            )
          })}
        </div>
      )
    }

    const Items = () => {
      if (
        !['apple', 'tomato', 'mandarin', 'persimmon'].includes(
          farmData.itemType,
        )
      )
        return null
      return (
        <div
          style={{
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            style={{ zIndex: 101, width: '45%' }}
            src={`/badge/${farmData?.itemType}.png`}
            alt=''
          />
          <Title
            fontSize='3.6vw'
            alignItems='flex-start'
            paddingLeft='12vw'
            text={[
              `· 현재 작물: ${changeItemList(farmData.itemType)[0]}`,
              `· 변경 후 작물: ${changeItemList(farmData.itemType)[1]}`,
            ]}
          />
        </div>
      )
    }

    const Button = ({ text = '', isBackgroundFilled = true }) => {
      return (
        <button
          onClick={() => {
            if (isBackgroundFilled) {
              changePlantAmount()
            } else {
              setShowModal(false)
            }
          }}
          style={{
            display: 'flex',
            border: isBackgroundFilled
              ? '2px solid #ffd147'
              : '2px solid #ffd147',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isBackgroundFilled ? '#ffd147' : 'white',
            borderRadius: '4vw',
            height: '12vw',
            width: '80%',
            fontSize: '4vw',
            fontFamily: 'maplestory',
            color: 'black',
            marginTop: '2vw',
          }}
        >
          {text}
        </button>
      )
    }

    const ModalBody = () => {
      return (
        <div
          style={{
            position: 'relative',
            paddingBottom: '6vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 100,
            paddingLeft: '5%',
            paddingRight: '5%',
            width: '80%',
            height: '90%',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              marginTop: '6vw',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            <Title
              text={[
                '재배중인 작물을 변경하면',
                '작물을 더 빠르게 수확할 수 있어요!',
              ]}
            />
            <div style={{ height: '3vw' }}></div>
            <Title
              color='gray'
              fontSize='3vw'
              text={[
                '작물의 재고 확보에 어려움이 생겨서',
                `${
                  changeItemList(farmData.itemType)[0]
                }는 곧 올팜에서 사라질 예정이에요`,
                '현재 작물까지는 계속 키울 수도 있어요',
              ]}
            />
            <Items />
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '90%' }}
            >
              <Button text={`작물 유지하기`} isBackgroundFilled={false} />
              <Button text={'작물 변경하기'} />
            </div>
          </div>
        </div>
      )
    }

    const ModalFrame = () => {
      return (
        <>
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 900,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }}
          >
            <div
              style={{
                width: '120vw',
                height: '120vw',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -55%)',
                display: 'flex',
                position: 'absolute',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column',
                zIndex: 901,
                borderRadius: '4vw',
                padding: 16,
                paddingTop: '6vw',
                backgroundImage: 'url(../icon/changePlantGetFasterModal.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              <button
                style={{
                  width: '8%',
                  top: '-14vw',
                  right: '16vw',
                  position: 'absolute',
                  cursor: 'pointer',
                  zIndex: 999,
                }}
                onClick={() => {
                  setShowModal(false)
                }}
              >
                <img
                  style={{ zIndex: 101 }}
                  src='/icon/modalCloseButton.png'
                  alt=''
                />
              </button>
              <ModalBody />
            </div>
          </div>
        </>
      )
    }

    return <ModalFrame />
  },
)

export default ChangePlantGetFasterModal
