import { observable } from 'mobx'

const AlfarmAdStore = observable({
  isAlfarmHeavyUserForAd: false,
  winnerDealDailyMaxExposureCount: 2,
  winnerDealTimeIntervalBetweenExposure: 6,
  yutnoriDailyMaxExposureCount: 2,
  yutnoriTimeIntervalBetweenExposure: 6,
  leavesAdDailyMaxExposureCount: 2,
  leavesAdTimeIntervalBetweenExposure: 6,
  quizAdDailyMaxExposureCount: 2,
  quizAdTimeIntervalBetweenExposure: 6,
  eggAdDailyMaxExposureCount: 2,
  eggAdTimeIntervalBetweenExposure: 6,
  isSanSinEventTarget: false,
  showPingForSanSinEvent: null,
  isSanSinLoading: false,
  getRendering: false,

  setIsAlfarmHeavyUserForAd(data) {
    this.isAlfarmHeavyUserForAd = data
    if (this.isAlfarmHeavyUserForAd) {
      this.winnerDealDailyMaxExposureCount = 3
      this.winnerDealTimeIntervalBetweenExposure = 4
      this.yutnoriDailyMaxExposureCount = 3
      this.yutnoriTimeIntervalBetweenExposure = 4
      this.leavesAdDailyMaxExposureCount = 3
      this.leavesAdTimeIntervalBetweenExposure = 4
      this.quizAdDailyMaxExposureCount = 3
      this.quizAdTimeIntervalBetweenExposure = 4
      this.eggAdDailyMaxExposureCount = 3
      this.eggAdTimeIntervalBetweenExposure = 4
    }
  },
  setIsSanSinEventTarget(data) {
    this.isSanSinEventTarget = data
  },
  setShowPingForSanSinEvent(data) {
    this.showPingForSanSinEvent = data
  },
  setIsSanSinLoading(data) {
    this.isSanSinLoading = data
  },
  setGetRendering(data) {
    this.getRendering = data
  },
})

export default AlfarmAdStore
