import React, { useState, useEffect, useRef } from 'react'
import Layout from '../comps/atoms/layout'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { NavLink } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import commaNumber from 'comma-number'
import toast, { Toaster } from 'react-hot-toast'
import dayjs from 'dayjs'
import UserStore from 'store/UserStore'

const BACKGROUND_COLOR = '#f1f5f9'
const BACKGROUND_POINT_COLOR = '#F2EADA'
const POINT_TEXT_COLOR = '#704410'
const TEXT_COLOR = '#704410'
const BUTTON_BACKGROUND_COLOR = '#704410'
const BUTTON_TEXT_COLOR = '#fff'

const TutorialText = ({ first, second }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
      }}
    >
      <div style={{ color: TEXT_COLOR, marginRight: 4 }}>{first}</div>
      <div style={{ color: TEXT_COLOR, wordBreak: 'keep-all' }}>{second}</div>
      <br />
    </div>
  )
}

const CoffeeGift = () => {
  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const randomString = useSearchParam('randomString')
  const userId = useSearchParam('userId')
  const [coffeeGiftData, setCoffeeGiftData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [showTutorial, setShowTutorial] = useState(false)

  const toastBlock = useRef()

  useEffect(() => {
    initCoffeeGiftPage()
    toastBlock.current = false
  }, [])
  useEffect(() => {
    if (coffeeGiftData) setIsLoading(false)
  }, [coffeeGiftData])

  const notify = (toastBlock, toastText) => {
    if (toastBlock.current === true) return
    toast(toastText)
    toastBlock.current = true
    setTimeout(() => {
      toastBlock.current = false
    }, 2200)
  }

  const initCoffeeGiftPage = async () => {
    let result = await backendApis.getCoffeeGiftData()
    if (!result?.data) {
      result = await backendApis.initCoffeeGiftData()
    }
    setCoffeeGiftData(result?.data)
    if (result?.data?.rewardFertSet < 5) {
      setShowTutorial(true)
    }
  }

  const GiftHistory = ({ data }) => {
    let receivedAt = dayjs(data?.rewardedAt).format('MM월 DD일')

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: 16,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              alt='profileImage'
              style={{
                width: 50,
                height: 50,
                borderRadius: 55,
                marginRight: 16,
              }}
              src={data?.userImage}
              onError={(e) => {
                e.target.src = UserStore?.default_profile_image
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-around',
                height: 50,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ color: TEXT_COLOR }}>{`${data?.userName}`}</div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                alt='profileImage'
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 8,
                }}
                src='/icon/fertilizerSet.png'
              />
              <div
                style={{ color: TEXT_COLOR }}
              >{`${data?.rewardFertSet}개`}</div>
            </div>
            <div
              style={{ color: '#666', fontSize: '0.6rem' }}
            >{`${receivedAt}`}</div>
          </div>
        </div>
        <div
          style={{
            border: '0.5px solid #ccc',
            width: '96%',

            marginRight: 8,
            marginLeft: 8,
          }}
        />
      </>
    )
  }

  if (isLoading) {
    return <Layout isLoading={isLoading}></Layout>
  }

  return (
    <div
      style={{
        backgroundColor: BACKGROUND_POINT_COLOR,
        overflowY: 'scroll',
        height: '100vh',
        width: '100%',
        display: 'flex',
        padding: 16,
        fontFamily: 'maplestory',
      }}
    >
      <NavLink
        to={`/farmPage?token=${token}&itemType=${itemType}&randomNumber=${randomString}`}
      >
        <button
          style={{
            position: 'absolute',
            width: '7vw',
            paddingTop: '10vw',
          }}
          action='goBack'
          onClick={() => {}}
        >
          <img src='/coffeeGift/back.png' alt='' />
        </button>
      </NavLink>
      <div
        style={{
          position: 'absolute',
          width: '10vw',
          height: '10vw',
          marginRight: '4vw',
          paddingTop: '10vw',
          right: 0,
        }}
        onClick={() => {
          setShowTutorial(true)
        }}
      >
        <img src='/coffeeGift/info.png' alt='' />
      </div>

      <div
        style={{
          flex: 1,
          width: '100%',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '6vw',
        }}
      >
        <div
          style={{
            color: POINT_TEXT_COLOR,
            marginTop: 68,
            marginBottom: 40,
            fontSize: 30,
          }}
        >
          {`기프티콘 선물 이벤트`}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 36,
          }}
        >
          <img
            alt='mainImage'
            style={{
              width: '70%',
              marginRight: 4,
            }}
            src='/coffeeGift/mainImage.png'
          />
        </div>
        <div
          style={{
            color: POINT_TEXT_COLOR,
            fontWeight: 900,
            fontSize: 16,
            marginBottom: 4,
          }}
        >
          {`1. 커피를 무료로 선물해보세요`}
        </div>
        <div
          style={{
            color: POINT_TEXT_COLOR,
            fontWeight: 900,
            fontSize: 16,
            marginBottom: 4,
          }}
        >
          {`2. 새친구가 선물을 받으면 나도 커피를 받아요`}
        </div>
        <div
          style={{
            color: POINT_TEXT_COLOR,
            fontWeight: 900,
            fontSize: 16,
            marginBottom: 12,
          }}
        >
          {`3. 새친구 1명당 고급비료 5개를 받아요`}
        </div>
        <div
          style={{
            color: '#98896C',
            fontSize: 12,
            marginBottom: 12,
            // marginTop: 12,
          }}
        >
          {`*커피 기프티콘은 최초 1회만 지급돼요`}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 24,
          }}
        >
          {coffeeGiftData?.initialButtonClickDone === false && (
            <img
              alt='initialButtonClickIcon'
              style={{
                width: '80%',
                marginRight: 4,
                marginBottom: -12,
                zIndex: 2,
              }}
              src='/coffeeGift/initialButtonClickIcon.png'
            />
          )}
          {coffeeGiftData?.initialButtonClickDone === true &&
            coffeeGiftData?.rewardFertSet < 5 && (
              <img
                alt='initialButtonClickIcon'
                style={{
                  width: '80%',
                  marginRight: 4,
                  marginBottom: -12,
                  zIndex: 2,
                }}
                src='/coffeeGift/initialInviteFriendIcon.png'
              />
            )}
          <div
            style={{
              width: '100%',
              height: '16vw',
              color: BUTTON_TEXT_COLOR,
              backgroundImage: 'url(../coffeeGift/coffeeGiftSendButton.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              marginBottom: 32,
              fontWeight: 'bold',
              fontSize: '1.2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={async () => {
              if (
                coffeeGiftData?.initialButtonClickDone === false &&
                coffeeGiftData?.rewardFertSet === 0
              ) {
                const result = await backendApis.setInitialButtonClickFertSet()
                if (result?.status === 200) {
                  setCoffeeGiftData({
                    ...coffeeGiftData,
                    rewardFertSet: 1,
                    initialButtonClickDone: true,
                  })
                }
                notify(toastBlock, `고급비료 1개 획득!`)
                window.location.href = `#openBottomShareSheet.${JSON.stringify({
                  code: 'alfarmCoffeeGift',
                })}`
              } else {
                window.location.href = `#openBottomShareSheet.${JSON.stringify({
                  code: 'alfarmCoffeeGift',
                })}`
              }
            }}
          ></div>
          {coffeeGiftData?.initialButtonClickDone === true && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <button
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  setShowHistoryModal(true)
                }}
              >
                <div
                  style={{
                    width: '90%',
                    marginRight: 4,
                    height: '88%',
                    color: TEXT_COLOR,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    borderRadius: 16,
                    padding: 12,
                    backgroundColor: '#FFF9EC',
                  }}
                >
                  <div
                    style={{
                      color: POINT_TEXT_COLOR,
                      marginBottom: 4,
                      fontFamily: 'maplestory',
                    }}
                  >
                    {`선물하고 받은 고급비료 수`}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      flexDirection: 'row',
                      color: TEXT_COLOR,
                      fontFamily: 'maplestory',
                    }}
                  >
                    <img
                      alt='fertilizerSet'
                      style={{
                        width: 30,
                        height: 30,
                        marginRight: 4,
                      }}
                      src='/icon/fertilizerSet.png'
                    />
                    {`${commaNumber(coffeeGiftData?.rewardFertSet) || 0}개`}
                  </div>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>

      {showTutorial && (
        <div
          style={{
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: 3,
            width: '100%',
            height: '100%',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 16,
          }}
        >
          <div
            style={{
              position: 'absolute',

              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
            }}
          >
            <div
              style={{
                color: TEXT_COLOR,
                // fontWeight: "bold",
                // border: "1px solid black",
                // height: "100px",
                backgroundColor: BACKGROUND_POINT_COLOR,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'left',
                marginBottom: 16,
                lineHeight: '1.4rem',
                borderRadius: 16,
                padding: 16,
                paddingLeft: 32,
                paddingRight: 32,
              }}
            >
              <b
                style={{
                  color: POINT_TEXT_COLOR,
                  textAlign: 'left',
                  margin: 0,
                  padding: 0,
                  fontSize: '1.2rem',
                  marginBottom: 16,
                  marginTop: 8,
                }}
              >
                커피 선물하기 참여방법
              </b>
              <img
                alt='mainImage'
                style={{
                  width: '70%',
                  marginRight: 4,
                  marginBottom: 16,
                }}
                src='/coffeeGift/mainImage.png'
              />
              <TutorialText
                first='1.'
                second={' 커피 기프티콘을 무료로 선물해보세요'}
              />
              <TutorialText
                first='2.'
                second={' 새친구가 선물을 받으면 나도 커피를 받아요'}
              />
              <TutorialText
                first='3.'
                second={' 새친구 1명당 고급비료 5개를 받아요'}
              />
              <br />
              <div
                style={{
                  color: '#333',
                  fontSize: '0.8rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  lineHeight: '1rem',
                  marginBottom: 16,
                }}
              >
                {`* 빽다방 커피 기프티콘은 최초 1회만 지급돼요.`}
                <br />
                {`* 올웨이즈가 처음인 친구에게만 커피를 선물할 수 있어요.`}
                <br />
                {`* 이미 커피 기프티콘을 선물한 경우에도 인정이 돼요.`}
                <br />
                {`* 기프티콘은 모바일로 1월12일(목)에 일괄 발송돼요.`}
                <br />
                {`* 이 이벤트는 사전 고지 없이 조기 종료될 수 있어요.`}
                <br />
                {`* 부정한 방법으로 획득한 재화는 회수될 수 있어요`}
                <br />
                {`* 더 자세한 안내는 고객센터를 이용해주세요.`}
              </div>
            </div>
            <button
              style={{
                width: '7vw',
                right: 0,
                top: '-12vw',
                position: 'absolute',
                zIndex: 999,
              }}
              onClick={() => {
                setShowTutorial(false)
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
          </div>
        </div>
      )}
      {showHistoryModal && (
        <div
          style={{
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: 3,
            width: '100%',
            height: '100%',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 16,
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
            }}
          >
            <div
              style={{
                color: TEXT_COLOR,
                overflowY: 'scroll',
                height: '48vh',
                // fontWeight: "bold",
                // border: "1px solid black",
                // height: "100px",
                backgroundColor: BACKGROUND_POINT_COLOR,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'left',
                marginBottom: 16,
                lineHeight: '1.4rem',

                borderRadius: 16,
                padding: 16,
                paddingLeft: 32,
                paddingRight: 32,
              }}
            >
              <b
                style={{
                  color: POINT_TEXT_COLOR,
                  textAlign: 'left',
                  margin: 0,
                  padding: 0,
                  fontSize: '1.2rem',
                  marginBottom: 16,
                  marginTop: 8,
                }}
              >
                커피 선물한 내역
              </b>
              <br />
              <div
                style={{
                  color: TEXT_COLOR,
                  fontWeight: 'bold',
                  // border: "1px solid white",
                  flex: 1,
                  // marginTop: 4,
                  marginBottom: 16,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  textAlign: 'center',
                  flexDirection: 'column',
                  overflow: 'scroll',
                  backgroundColor: BACKGROUND_POINT_COLOR,
                  borderTop: '0.5px solid',
                  borderTopColor: BUTTON_BACKGROUND_COLOR,
                }}
              >
                {coffeeGiftData?.rewardHistory?.map((e) => (
                  <GiftHistory key={Math.random()} data={e} />
                ))}
              </div>
            </div>
            <button
              style={{
                width: '7vw',
                right: 0,
                top: '-12vw',
                position: 'absolute',
                zIndex: 999,
              }}
              onClick={() => {
                setShowHistoryModal(false)
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default CoffeeGift
