import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../store/ModalStore'
import ToastStore from '../../store/ToastStore'
import backendApis from '../../utils/backendApis'
import UserStore from '../../store/UserStore'

const InviteButton = ({ isFilled = false, shareKakao, userImage }) => {
  return isFilled ? (
    <img
      style={{
        width: '15vw',
        height: '15vw',
        borderRadius: 55,
        marginRight: 8,
        marginTop: '0.5vw',
      }}
      src={userImage}
      alt=''
      onError={(e) => {
        e.target.src = UserStore?.default_profile_image
      }}
    />
  ) : (
    <div
      style={{
        width: '15vw',
        height: '15vw',
        backgroundImage: 'url(../harvestInvite/invite.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      onClick={() => shareKakao()}
    />
  )
}

const HarvestInviteModal = observer(
  ({
    token,
    farmData,
    setFarmData,
    userCodePushVersion,
    codePushVersionAvailable,
  }) => {
    const [isFilled, setIsFilled] = useState(false)
    const [secondIsFilled, setSecondIsFilled] = useState(false)
    const [userImage, setUserImage] = useState()
    const [secondUserImage, setSecondUserImage] = useState()
    const [infoModal, setInfoModal] = useState()
    const [itemInfo, setItemInfo] = useState(null)
    const [threshold, setThreshold] = useState(2)
    const [isValidItemType, setIsValidItemType] = useState(true)
    const [isFinished, setIsFinished] = useState(false)
    const throttleRef = useRef({})

    const shareKakao = () => {
      const inviteData = {
        shareType: 'AlfarmHarvestInvite',
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
    }

    useEffect(() => {
      if (farmData?.harvestInviteList?.length > 0) {
        setIsFilled(true)
        setUserImage(farmData?.harvestInviteList?.[0]?.userImage)
      }
      if (farmData?.harvestInviteList?.length > 1) {
        setSecondIsFilled(true)
        setSecondUserImage(farmData?.harvestInviteList?.[1]?.userImage)
      }
    }, [farmData])

    useEffect(() => {
      //getItemInfo
      const fetch = async () => {
        let res = await backendApis.getItemInfo(token, 'GET', {
          itemType: farmData?.itemType,
          itemPrice: farmData?.itemPrice,
        })
        if (res?.data?.success === false) {
          ToastStore?.toastOn({
            type: 'error',
            message: '[오류] 올팜을 재접속 해주세요',
            duration: 3000,
          })
        }
        setIsValidItemType(
          res?.data?.totalOptions?.length === 1 &&
            res?.data?.totalOptions?.[0]?.length > 1,
        )
        setItemInfo(res?.data)
        const tempLimit =
          res?.data?.optionPrices?.[1]?.teamPurchasePrice >= 3000 ? 2 : 1
        setThreshold(tempLimit)
        if (farmData?.harvestInviteList?.length >= tempLimit) {
          setIsFinished(true)
        } else {
          setIsFinished(false)
        }
      }
      if (farmData?.itemType && !itemInfo) {
        fetch()
      }
    }, [farmData])

    return (
      <>
        <div
          id='harvestInviteModal'
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          {itemInfo && (
            <div
              style={{
                width: '90vw',
                height: '150vw',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -55%)',
                display: 'flex',
                position: 'absolute',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column',
                // border: "1px solid red",
                padding: 16,
                backgroundImage: 'url(../harvestInvite/background.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                paddingTop: '50vw',
                paddingBottom: '11vw',
                // marginBottom: "15%",
              }}
            >
              <button
                style={{
                  width: '8vw',
                  right: 0,
                  top: '16vw',
                  position: 'absolute',
                  zIndex: 1999,
                  // borderRadius: "1px solid black",
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('basic')
                }}
              >
                <img
                  style={{
                    zIndex: 1101,
                  }}
                  src='/icon/modalCloseButton.png'
                  alt=''
                />
              </button>
              {isValidItemType ? (
                <div
                  style={{
                    // border: "1px solid black",
                    width: '100%',
                    height: '100%',
                    // marginBottom: "12vw",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // border: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        color: 'black',
                        fontFamily: 'maplestory',
                        fontSize: '5vw',
                        marginTop: '2vw',
                        marginBottom: 0,
                      }}
                    >
                      {`${
                        isFinished ? '축하해요 농부님!' : '새친구를 초대하면'
                      }`}
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontFamily: 'maplestory',
                        fontSize: '5vw',
                        // marginTop: "2vw",
                        margin: 0,
                      }}
                    >
                      {`${
                        isFinished
                          ? '수확하는 작물의 양이 늘어났어요!'
                          : '받을 수 있는 작물 양이 늘어나요!'
                      }`}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      paddingLeft: '10%',
                      paddingRight: '10%',

                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    <InviteButton
                      isFilled={isFilled}
                      shareKakao={() => shareKakao()}
                      userImage={userImage}
                    />
                    {threshold >= 2 && (
                      <InviteButton
                        isFilled={secondIsFilled}
                        shareKakao={() => shareKakao()}
                        userImage={secondUserImage}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '4vw',
                    }}
                  >
                    <div
                      style={{
                        color: 'black',
                        fontFamily: 'maplestory',
                        marginLeft: 4,
                        textAlign: 'left',
                      }}
                    >
                      {`현재 작물양: ${
                        isFinished
                          ? itemInfo?.totalOptions?.[0]?.[1]?.name
                          : itemInfo?.totalOptions?.[0]?.[0]?.name
                      }`}
                      <br />
                      {`${
                        isFinished
                          ? ''
                          : '적용 작물양: ' +
                            itemInfo?.totalOptions?.[0]?.[1]?.name
                      }`}
                    </div>
                  </div>

                  {/* <div
                style={{
                  width: "100%",
                  color: "black",
                  fontFamily: "Maplestory",
                  textAlign: "center",
                  fontSize: "4vw",
                  lineHeight: "150%",
                }}
              >
                올팜을 안 해본 친구를 초대하면
                <br />
                작물을 바로 받을 수 있어요
              </div> */}

                  <div
                    style={{
                      background:
                        'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
                      width: '60vw',
                      height: '14vw',
                      borderRadius: 99,
                      color: 'black',
                      fontFamily: 'Maplestory',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '5vw',
                    }}
                    onClick={() => {
                      if (isFinished) {
                        ModalStore.setIsModalOpen('basic')
                      } else {
                        shareKakao()
                      }
                    }}
                  >
                    {isFinished ? '닫기' : '초대하기'}
                  </div>
                  <button
                    style={{
                      width: '100%',
                      color: 'black',
                      fontFamily: 'Maplestory',
                      textAlign: 'center',
                      fontSize: '4vw',
                      marginBottom: '0vw',
                      marginTop: '-3vw',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      setInfoModal(true)
                    }}
                  >
                    안내사항
                  </button>
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    // marginBottom: "12vw",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      color: 'black',
                      marginTop: '10vw',
                      fontWeight: 'bold',
                      marginLeft: '4vw',
                      marginRight: '4vw',
                    }}
                  >
                    현재 작물은 이벤트 적용 대상이 아니에요
                  </div>
                  <div
                    style={{
                      backgroundImage: 'url(../harvestInvite/cryingOnion.png)',
                      width: '40vw',
                      height: '40vw',
                    }}
                  />
                  <div
                    style={{
                      background:
                        'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
                      width: '60vw',
                      height: '14vw',
                      borderRadius: 99,
                      color: 'black',
                      fontFamily: 'Maplestory',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '5vw',
                    }}
                    onClick={() => {
                      ModalStore.setIsModalOpen('basic')
                    }}
                  >
                    닫기
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {infoModal && (
          <>
            <div
              style={{
                flex: 1,
                position: 'fixed',
                left: '10%',
                top: '45vw',
                zIndex: 12000,
                width: '80%',
                background: 'rgba(0,0,0,0.9)',
                borderRadius: 20,
                fontFamily: 'maplestory',
                fontSize: '4vw',
                padding: '6vw',
                paddingTop: '8vw',
                lineHeight: '170%',
                paddingBottom: '8vw',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                안내사항
              </div>
              <div style={{ height: '3vw' }} />- 올팜을 아직 안 해본 친구를
              초대하면 수확시에 받는 작물의 양을 늘릴 수 있어요 <br />- 친구를
              초대해도 적용이 되지 않는 경우 작물을 선택하고 친구가 보낸 초대
              링크를 다시 클릭해주세요
              <br /> - 올웨이즈 탈퇴 이력이 있는 경우 초대할 수 없어요 <br />-
              정상적이지 않은 방법으로 친구를 초대하는 경우 작물 발송이 제한될
              수 있어요 <br />- 이벤트는 사전 고지 없이 변경 및 종료 될 수
              있어요
            </div>
            <button
              style={{
                width: '8%',
                top: '47vw',
                right: '14%',
                zIndex: 12001,
                position: 'absolute',
              }}
              onClick={() => {
                setInfoModal(false)
              }}
            >
              <img
                style={{
                  zIndex: 1101,
                }}
                src='/icon/modalCloseIcon.png'
                alt=''
              />
            </button>
          </>
        )}
      </>
    )
  },
)

export default HarvestInviteModal
