import backendApis from '../utils/backendApis'
import { observable, runInAction } from 'mobx'
import dayjs from 'dayjs'
import moment from 'moment'

const TimeStore = observable({
  delta: null,
  now: new Date(),
  currentDate: dayjs(new Date()),
  currentNewDate: new Date(),
  currentDayJS: dayjs(new Date()),
  currentMoment: moment(new Date()),
  currentDay: dayjs(new Date()).format('YYYY-MM-DD'),

  async getNow() {
    let result = await backendApis.getNow()
    runInAction(() => {
      const nowDate = new Date(result?.data)
      this.now = nowDate
      this.delta = nowDate?.getTime() - new Date().getTime()
      this.currentDate = dayjs(nowDate)
      this.currentNewDate = nowDate
      this.currentDayJS = dayjs(nowDate)
      this.currentMoment = moment(nowDate)
      this.currentDay = dayjs(nowDate).format('YYYY-MM-DD')
    })

    return result?.status
  },

  async timeSleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  },
})

export default TimeStore
