import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import toast, { Toaster } from 'react-hot-toast'
import ModalStore from '../../store/ModalStore'

const FlappyBirdPurchaseModal = observer(
  ({
    setShowModal,
    token,
    itemType,
    bestScore,
    timeInfo,
    purchaseAvailable,
    rewardAmount,
  }) => {
    const toastBlockRef = useRef()

    useEffect(() => {
      toastBlockRef.current = false
    }, [])
    const notifyLocal = (toastBlockRef, text) => {
      if (toastBlockRef.current === true) return
      toast(text)
      toastBlockRef.current = true
      setTimeout(() => {
        toastBlockRef.current = false
      }, 2000)
    }

    const sendToPurchaseScreen = () => {
      if (rewardAmount === 0) {
        notifyLocal(toastBlockRef, '보상이 0g 이에요')

        return
      }
      const purchaseData = {
        enteringComponent: 'Alfarm_flappyBirdPurchase',
        text: '올팜 플라잉팜 구매보상',
        source:
          'https://assets.ilevit.com/bd1eb0c1-31f0-4474-88fc-3c7a81f433dc.png',
        rewardText: `상품을 구매하면 물 ${rewardAmount}g을 받아요`,
      }

      window.location.href = `#commonPurchaseReward.${JSON.stringify(
        purchaseData,
      )}`
      ModalStore.setIsModalOpen('basic')
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage:
                'url(../flappyBirdImg/purchaseRewardBackground.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '50vw',
              paddingBottom: '20%',
              // marginBottom: "15%",
            }}
            className='scale-up-center-flappy-bird'
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: '18vw',
                position: 'absolute',
                zIndex: 999,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                // border: "1px solid black",
                width: '100%',
                height: '100%',
                // marginBottom: "12vw",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  lineHeight: '1.4rem',
                  marginTop: 8,
                  // border: "1px solid black",
                }}
              >
                상품을 구매해보고 <br />
                오늘 보상의{' '}
                <b
                  style={{ color: '#E94343', fontSize: '1.2rem' }}
                >{`${50}배`}</b>
                를 받으세요
              </div>
              <div
                style={{
                  width: '40vw',
                  height: '40vw',
                  backgroundImage: 'url(../flappyBirdImg/reward.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  marginBottom: 8,
                  marginTop: 8,
                }}
              />
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  lineHeight: '1.4rem',
                  marginBottom: 8,
                }}
              >
                구매보상은 최대 1500g이에요
              </div>
              {purchaseAvailable ? (
                <div
                  style={{
                    background:
                      'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                    width: '60vw',
                    height: '14vw',
                    borderRadius: 99,
                    color: 'black',
                    fontFamily: 'Maplestory',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                  }}
                  onClick={sendToPurchaseScreen}
                >
                  {`오늘의 구매보상 ${rewardAmount}g 받기`}
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: '#999',
                    width: '60vw',
                    height: '14vw',
                    borderRadius: 99,
                    color: 'black',
                    fontFamily: 'Maplestory',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                  }}
                >
                  {`내일 또 받을 수 있어요`}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default FlappyBirdPurchaseModal
