import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'

const SeasonalEventNoticeModal = observer(
  ({ farmData, token, setFarmData }) => {
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <div
            style={{
              width: '90%',
              height: '90vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              backgroundImage: 'url(../grapeEvent/noticeModal.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <button
              style={{
                width: '10%',
                top: '-10%',
                right: '0%',
                transform: 'translate(0%, -50%)',
                position: 'absolute',
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 100,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
          </div>
        </div>
      </>
    )
  },
)
export default SeasonalEventNoticeModal
