import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import useInterval from '../../utils/customHooks/useInterval'
import backendApis from '../../utils/backendApis'
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'
import ModalStore from '../../store/ModalStore'
import ToastStore from '../../store/ToastStore'
import { sleep } from 'utils/utils'
import moment from 'moment'

const useGesture = createUseGesture([dragAction, pinchAction])

const WaterBombGameModal = observer(
  ({
    token,
    farmData,
    setFarmData,
    setUpdateMinigameInfo,
    showRewardToast,
  }) => {
    const [prize, setPrize] = useState(0)
    const [threshHold, setThreshHold] = useState(Math.random() * 40 + 20)

    const [triggerFlag, setTriggerFlag] = useState(false)
    const [isShakeOn, setIsShakeOn] = useState(false)
    const [isGameOver, setIsGameOver] = useState(false)
    const [didPop, setDidPop] = useState(false)

    const [waterBombSrc, setWaterBombSrc] = useState(
      'url(../minigame/waterBomb/waterBomb.png)',
    )

    const ref = useRef(null)
    const outerRef = useRef(null)

    useGesture(
      {
        onDrag: ({ type, ...rest }) => {},
      },
      {
        target: outerRef,
      },
    )
    useEffect(() => {
      window.addEventListener('contextmenu', (e) => e.preventDefault())
      const handler = (e) => e.preventDefault()
      document.addEventListener('gesturestart', handler)
      document.addEventListener('gesturechange', handler)
      return () => {
        document.removeEventListener('gesturestart', handler)
        document.removeEventListener('gesturechange', handler)
      }
    }, [])

    useEffect(() => {
      setPrize(Math.ceil(prize))
    }, [isGameOver])

    useEffect(() => {
      if (didPop) {
        setWaterBombSrc('url(../minigame/waterBomb/explode.png)')
      } else {
        setWaterBombSrc('url(../minigame/waterBomb/waterBomb.png)')
      }
    }, [didPop])

    async function setWaterBombLastPlayedAt() {
      await backendApis.giveWaterBombReward(token, 'PUT', {
        prize: 0,
      })
      let temp = { ...farmData }
      temp.water += -10
      setFarmData(temp)
      setUpdateMinigameInfo(true)
    }

    useInterval(
      () => {
        if (prize >= threshHold) {
          setIsShakeOn(false)
          setDidPop(true)
          setIsGameOver(true)
          setWaterBombLastPlayedAt()
        }
        setPrize(Math.round((prize + 0.05) * 100) / 100)
      },
      isShakeOn ? 10 : null,
    )

    // Simulate drag when the element is clicked
    const handleElementClick = () => {
      if (farmData?.water < 10) {
        ToastStore.toastOn({
          type: 'error',
          message: '물이 부족해요! 10g이상 물이 있어야 해요',
          duration: 3000,
        })
        return
      }
      if (!isGameOver) {
        setIsShakeOn(true)
      }
    }

    async function giveWaterBombReward() {
      if (prize < threshHold) {
        await backendApis.giveWaterBombReward(token, 'PUT', {
          prize: Math.ceil(prize),
        })
        let temp = { ...farmData }
        temp.water += Math.ceil(prize - 10)
        setFarmData(temp)
      }
      setUpdateMinigameInfo(true)
    }

    // Function to trigger game over and reward logic
    const triggerGameOverAndReward = () => {
      setIsShakeOn(false)
      setIsGameOver(true)
      giveWaterBombReward()
    }

    const randomTimer = async () => {
      const randomDelay = Math.floor(Math.random() * 6000) + 2000 // Random delay between 2 to 10 seconds
      await sleep(randomDelay)
      setTriggerFlag(true)
    }

    // Set a random timer to trigger the game over and reward logic
    useEffect(() => {
      if (isShakeOn) {
        randomTimer()
      }
    }, [isShakeOn])

    useEffect(() => {
      if (triggerFlag) {
        triggerGameOverAndReward()
      }
    }, [triggerFlag])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.9)',
            touchAction: 'none',
            fontFamily: 'maplestory',
          }}
          ref={outerRef}
        >
          <div
            style={{
              width: '100%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              padding: 16,
              backgroundImage: 'url(../minigame/waterBomb/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '57vw',
              paddingBottom: '16%',
              zIndex: 99,
            }}
          >
            {isShakeOn && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 111,
                  width: '12vw',
                  top: '76%',
                  left: '60%',
                  transform: 'translate(-50%, -50%)',
                }}
                className='slide-out-top'
              >
                <img src='/icon/luckydrawCost.png' alt='' />
              </div>
            )}
            <button
              style={{
                width: '8vw',
                right: 32,
                top: '5vw',
                position: 'absolute',
                zIndex: 999,
                // borderRadius: "1px solid black",
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'black',
                zIndex: 99,
              }}
            >
              {didPop ? (
                <div
                  style={{
                    color: 'black',
                    zIndex: 99,
                    backgroundColor: 'white',
                    padding: '8px 16px 8px 16px',
                    border: '1px solid black',
                    borderRadius: 16,
                    fontWeight: 'bold',
                    position: 'absolute',
                    marginTop: 100,
                    textAlign: 'center',
                  }}
                >
                  앗, 오늘은 꽝이에요 😔
                  <br />
                  아쉽지만 내일 다시 도전해보세요!
                </div>
              ) : (
                <div
                  style={{
                    position: 'absolute',
                    left: '44vw',
                    top: '76vw',
                    fontSize: '1.2rem',
                    zIndex: 99,
                    fontWeight: 'bold',
                    color: 'black',
                    width: '14vw',
                    height: '8vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={isShakeOn ? 'shake-bottom' : ''}
                >
                  <div
                    style={{
                      color: 'black',
                      backgroundColor: '#fffb',
                      padding: 8,
                      paddingLeft: 16,
                      paddingRight: 16,
                      borderRadius: 8,
                    }}
                  >{`${
                    prize < 100 ? prize.toFixed(2) : prize.toFixed(1)
                  }g`}</div>
                </div>
              )}
              <div
                style={{
                  flexDirection: 'column',
                  position: 'absolute',
                  left: '20vw',
                  top: '60vw',
                  fontSize: '0.8rem',
                  zIndex: 97,
                  width: '14vw',
                  height: '8vw',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{ fontWeight: 'bold', color: 'black' }}
                >{`${moment().format('MM월')}`}</div>
                <div
                  style={{ fontWeight: 'bold', color: 'black' }}
                >{`${moment().format('DD일')}`}</div>
              </div>
              <div
                ref={ref}
                style={{
                  width: '50vw',
                  height: '50vw',
                  backgroundImage: waterBombSrc,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  zIndex: 98,
                  marginTop: '0vw',
                  touchAction: 'none',
                }}
                className={isShakeOn ? 'shake-bottom' : ''}
              />

              {isGameOver ? (
                <div
                  style={{
                    background:
                      'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                    width: '60vw',
                    height: '14vw',
                    marginTop: '2vw',
                    borderRadius: 99,
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bottom: 30,
                    zIndex: 9999,
                  }}
                  onClick={() => {
                    if (!didPop) {
                      showRewardToast(Math.ceil(prize), 'water')
                    }
                    ModalStore.setIsModalOpen('basic')
                  }}
                >
                  {didPop ? '닫기' : `물 ${Math.ceil(prize)}g 받기`}
                </div>
              ) : (
                <button
                  style={
                    isShakeOn
                      ? {
                          background: 'gray',
                          width: '60vw',
                          height: '14vw',
                          marginTop: '2vw',
                          borderRadius: 99,
                          color: 'black',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bottom: 30,
                          zIndex: 9999,
                          fontFamily: 'maplestory',
                          fontSize: '3.8vw',
                        }
                      : {
                          background:
                            'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
                          width: '60vw',
                          height: '14vw',
                          marginTop: '2vw',
                          borderRadius: 99,
                          color: 'black',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bottom: 30,
                          zIndex: 9999,
                          fontFamily: 'maplestory',
                          fontSize: '3.8vw',
                        }
                  }
                  disabled={isShakeOn}
                  onClick={handleElementClick}
                >
                  {isShakeOn
                    ? `두구두구.. 보상을 뽑고 있어요`
                    : `오늘의 물풍선 보상 뽑기`}
                </button>
              )}
              <div
                style={{
                  top: '130vw',
                  left: '16%',
                  width: '68vw',
                  display: 'flex',
                  position: 'absolute',

                  color: 'white',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  fontFamily: 'maplestory',
                  fontSize: '3.2vw',
                  alignItems: 'flex-start',
                }}
              >
                <div style={{ marginBottom: 4 }}>
                  * 매일 한 번, 물 10g으로 물풍선 뽑기에 도전해보세요!
                </div>
                <div>* 오늘의 운에 따라 물을 0~100g까지 받을 수 있어요</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default WaterBombGameModal
