import dayjs from 'dayjs'
import { toJS } from 'mobx'
import moment from 'moment'
import TimeStore from 'store/TimeStore'
import UserStore from 'store/UserStore'

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }

  return array
}

const isPurchaseUser = (farmData) => {
  const isPurchaseUser =
    UserStore?.purchaseHistorys?.map(
      (item) => TimeStore?.currentDayJS.diff(dayjs(item?.createdAt), 'd') <= 21,
    )?.length > 0 ||
    (TimeStore?.currentDayJS.diff(dayjs(farmData?.createdAt), 'd') <= 21 &&
      farmData?.isNewUser)

  return isPurchaseUser
}

const purchaseHistoryWithinPeriod = (farmData, period, count, str) => {
  const currentDate = new Date()
  let history = []

  if (UserStore?.purchaseHistorys) {
    history = [...UserStore?.purchaseHistorys]
  }
  if (farmData?.purchaseHistory) {
    history = [...history, ...farmData?.purchaseHistory]
  }

  const matchingItemsCount =
    history?.filter((item) => {
      const purchaseDate = new Date(item?.createdAt ?? item?.purchasedAt)
      const daysDiff = (currentDate - purchaseDate) / (1000 * 60 * 60 * 24)

      return daysDiff <= period
    })?.length || 0

  return matchingItemsCount < count
}

const purchaseCountWithinPeriod = () => {
  const currentDate = new Date()
  const currentUTCDate = new Date(currentDate.getTime() + 9 * 60 * 60 * 1000) // 현재 날짜와 시간에 9시간을 더한 UTC 시간

  const todayUTCDate = new Date(currentUTCDate.toISOString().slice(0, 10)) // 오늘 날짜의 UTC 시간
  let history = UserStore?.purchaseHistorys

  const matchingItemsCount =
    history?.filter((item) => {
      const purchaseDate = new Date(item?.createdAt)
      const purchaseUTCDate = new Date(
        purchaseDate.getTime() + 9 * 60 * 60 * 1000,
      ) // 구매 일자와 시간에 9시간을 더한 UTC 시간
      const purchaseDateString = purchaseUTCDate.toISOString().slice(0, 10) // 구매 일자를 YYYY-MM-DD 형식으로 가져옴
      return purchaseDateString === todayUTCDate.toISOString().slice(0, 10)
    })?.length || 0

  return matchingItemsCount
}

const purchaseHistoryThisWeek = () => {
  const currentDate = dayjs(new Date()).toDate()

  currentDate.setHours(0, 0, 0, 0) // 현재 날짜를 자정으로 설정

  const currentDay = currentDate.getDay() // 현재 요일을 가져옴 (0: 일요일, 1: 월요일, ..., 6: 토요일)

  const daysUntilSunday = 0 - currentDay // 현재 요일부터 다음 일요일까지의 날짜 수를 계산 (0은 일요일)

  const sundayDate = new Date(
    currentDate.getTime() + daysUntilSunday * 24 * 60 * 60 * 1000,
  ) // 이번 주 일요일의 날짜를 계산

  // const sundayUTCDate = new Date(sundayDate.getTime() + 9 * 60 * 60 * 1000) // 이번 주 일요일의 UTC 시간
  // console.log('sundayUTCDate', sundayUTCDate)
  const saturdayUTCDate = new Date(
    sundayDate.getTime() + 7 * 24 * 60 * 60 * 1000,
  ) // 이번 주 토요일의 UTC 시간

  const purchaseHistoryThisWeek =
    UserStore?.purchaseHistorys?.filter((item) => {
      const purchaseDate = new Date(item?.createdAt)
      const purchaseUTCDate = new Date(purchaseDate.getTime()) // 구매 일자와 시간에 9시간을 더한 UTC 시간

      return purchaseUTCDate >= sundayDate && purchaseUTCDate <= saturdayUTCDate // 구매 일자가 이번 주 일요일부터 토요일 사이인 경우만 포함
    }) || []

  return purchaseHistoryThisWeek
}

const purchaseCountWithinPeriod4Hour = () => {
  const currentDate = new Date(new Date().getTime() - 4 * 60 * 60 * 1000)
  const currentUTCDate = new Date(currentDate.getTime() + 9 * 60 * 60 * 1000) // 현재 날짜와 시간에 9시간을 더한 UTC 시간
  const todayUTCDate = new Date(currentUTCDate.toISOString().slice(0, 10)) // 오늘 날짜의 UTC 시간
  const matchingItemsCount =
    UserStore?.purchaseHistorys?.filter((item) => {
      const purchaseDate = new Date(item?.createdAt)
      const purchaseUTCDate = new Date(
        purchaseDate.getTime() + 5 * 60 * 60 * 1000,
      ) // 구매 일자와 시간에 9시간을 더한 UTC 시간

      const purchaseDateString = purchaseUTCDate.toISOString().slice(0, 10) // 구매 일자를 YYYY-MM-DD 형식으로 가져옴
      return purchaseDateString === todayUTCDate.toISOString().slice(0, 10)
    })?.length || 0

  return matchingItemsCount
}

const modalController = (farmData) => {
  let modalType = 'basic'
  const number = Math.floor(Math.random() * 10)
  if (number > 3) return modalType
  if (
    localStorage.getItem('alfarmModalControllers') ===
    moment().format('YYYY-MM-DD')
  )
    return modalType

  if (farmData?.giveWaterCount < 100) return modalType
  if (
    farmData?.clearedInviteQuest === undefined ||
    moment(new Date(farmData?.clearedInviteQuest)).format('YYYY-MM-DD') !==
      moment(new Date()).format('YYYY-MM-DD')
  ) {
    modalType = 'dailyInvite'
    localStorage.setItem(
      'alfarmModalControllers',
      moment().format('YYYY-MM-DD'),
    )
  }
  return modalType
}

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

export {
  shuffleArray,
  isPurchaseUser,
  purchaseHistoryWithinPeriod,
  purchaseCountWithinPeriod,
  purchaseHistoryThisWeek,
  purchaseCountWithinPeriod4Hour,
  modalController,
  sleep,
}
