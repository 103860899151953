import WhiteTextTimer from 'comps/WhiteTextTimer'
import BottomSheetHeader from 'comps/molecules/BottomSheetHeader'
import ResourceToast from 'comps/molecules/ResourceToast'
import React, { useState, useEffect, useRef } from 'react'
import BottomSheetStore from 'store/BottomSheetStore'
import ResourceToastStore from 'store/ResourceToastStore'
import UserStore from 'store/UserStore'
import ABv3 from 'utils/ab_v3'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import backendApis from 'utils/backendApis'
import { observer } from 'mobx-react-lite'

const SubQuestBottomSheet = observer(({ farmData, setFarmData }) => {
  const [isLoading, setIsLoading] = useState(true)
  const throttleRef = useRef({})
  const [canClick, setCanClick] = useState(true)
  const [questStatus, setQuestStatus] = useState({})
  const [questData, setQuestData] = useState({})
  const [testRewardText, setTestRewardText] = useState('')
  const [testRewardDesc, setTestRewardDesc] = useState('')

  const groupA = ABv3(farmData?.userId, 'rewardTest0213', [3, 6, 9]) === 'a'
  const groupB = ABv3(farmData?.userId, 'rewardTest0213', [3, 6, 9]) === 'b'
  const groupC = ABv3(farmData?.userId, 'rewardTest0213', [3, 6, 9]) === 'c'
  const groupD = ABv3(farmData?.userId, 'rewardTest0213', [3, 6, 9]) === 'd'

  let hidden, visibilityChange
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  const getUserQuestData = async () => {
    const result = await backendApis.getNewYearEvent2024UserData()

    setQuestStatus(result?.data?.questStatus)
    setQuestData(result?.data?.questData)
  }

  useEffect(() => {
    Promise.all([getUserQuestData()])
    setIsLoading(false)
  }, [])

  useEffect(() => {
    // 페이지의 가시성 변경 이벤트 핸들러
    const handleVisibilityChange = () => {
      if (!document[hidden]) {
        // 페이지가 다시 가시 상태로 돌아올 때 API를 다시 호출합니다.
        fetchData()
      }
    }

    // VisibilityChange 이벤트 리스너 추가
    document.addEventListener(visibilityChange, handleVisibilityChange)

    // 컴포넌트 언마운트 시에 이벤트 리스너 제거
    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange)
    }

    // useEffect를 isLoading에 종속시킴으로써 가시성 변경에만 반응하도록 합니다.
  }, [isLoading])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      await getUserQuestData()
      setIsLoading(false)
    } catch (error) {
      // 오류 처리
      setIsLoading(false) // 오류 발생 시에도 로딩 상태를 해제합니다.
    }
  }

  const clearQuest = async (questType) => {
    if (!canClick) return
    setCanClick(false)
    const result = await backendApis.clearNewYearEvent({
      questType: questType,
    })

    if (result?.data?.success) {
      ResourceToastStore?.setResourceToast({
        open: true,
        type: 'water',
        count: 10,
      })

      setQuestStatus({
        ...questStatus,
        [questType]: true,
      })
      if (questType === 'checkin') {
        let tmpFarmData = farmData
        if (UserStore.waterExceeded) {
          tmpFarmData.water += Math.round(10 * 0.5)
        } else {
          tmpFarmData.water += 10
        }
        setFarmData(tmpFarmData)
        if (questData?.checkin) {
          setQuestData({
            ...questData,
            [questType]: [
              ...questData?.checkin,
              new Date(new Date().getTime()),
            ],
          })
        } else {
          setQuestData({
            ...questData,
            [questType]: [new Date(new Date().getTime())],
          })
        }
      }

      setCanClick(true)
    }
  }

  useEffect(() => {
    if (groupA) {
      // a
      setTestRewardText('상품을 구매하면 고급비료 2개를 드려요')
      setTestRewardDesc('고급비료 2개')
    } else if (groupD) {
      // d
      setTestRewardText('상품을 구매하면 고급비료 5개를 드려요')
      setTestRewardDesc('고급비료 5개')
    } else if (groupB) {
      // c
      setTestRewardText('상품을 구매하면 고급비료 8개를 드려요')
      setTestRewardDesc('고급비료 8개')
    } else if (groupC) {
      // b
      setTestRewardText('상품을 구매하면 고급비료 10개를 드려요')
      setTestRewardDesc('고급비료 10개')
    }
  }, [farmData])
  const sendToPurchaseScreen = async (questType) => {
    BottomSheetStore.setIsbottomSheetOpen('basic')
    window.location.href = `#generalNavigator.${JSON.stringify({
      screen: 'NewYear24DealScreen',
      params: {
        enteringComponent: 'Alfarm_newYearDealScreen',
        enteringLocation: 'browsingQuest',
        isFrom: 'Alfarm_newYearDealScreen',
        isTimerOn: questType === 'browsingQuest' ? true : false,
        timerText: '미션 완료까지 ',
        isTimerEndText: '축하합니다! 물 10g을 받았어요!',
        timeStamp: new Date(new Date().getTime() + 1000 * 30),
        rewardText: testRewardText,
        timeMinute: 0.5,
      },
    })}`
  }

  const MissionButton = ({ item }) => {
    if (item?.isOver) {
      return (
        <div className='p-[2vw] w-[22vw] rounded-[2vw] bg-[#FFC634] h-[11vw] flex justify-center items-center text-[4vw] text-[#6A3615] mr-[2vw]'>
          내일가능
        </div>
      )
    } else if (item?.isCleared) {
      return (
        <div className='p-[2vw] w-[22vw] rounded-[2vw] h-[11vw] flex justify-center items-center text-[4vw] text-[#6A3615] mr-[2vw]'>
          <WhiteTextTimer
            onTimeEnd={() => {
              //
              if (item?.type === 'checkin') {
                setQuestStatus({
                  ...questStatus,
                  checkin: false,
                })
              }
              if (item?.type === 'browsing') {
                setQuestStatus({
                  ...questStatus,
                  browsing: false,
                })
              }
            }}
            timeStamp={item?.cooltime}
            timerMinutes={0}
            color='#442b22'
          />
        </div>
      )
    } else {
      return (
        <div
          className='p-[2vw] w-[22vw] rounded-[2vw] bg-[#FFC634] h-[11vw] flex justify-center items-center text-[4vw] text-[#6A3615] mr-[2vw]'
          onClick={() => {
            item?.onClick()
          }}
        >
          {item?.buttonText}
        </div>
      )
    }
  }

  const exchangeItemCard = [
    {
      description: '출석하기',
      subText: '물 10g, 하루 최대 3번',
      type: 'checkin',
      rewardImg: '../newYear2024/water.png',
      color: '#576DA7',
      shadow: '#435994',
      img: '../newYear2024/checkin.png',
      buttonText: '출석하기',
      onClick: () => {
        AlfarmEventLogger({
          throttleRef,
          locationType: 'page',
          locationName: 'newYear2024',
          eventType: 'click',
          eventName: 'checkin',
          data: {},
          collection: 'UserAlfarmClickLog',
        })
        clearQuest('checkin')
      },
      isCleared: questStatus?.checkin,
      isOver: questData?.checkin?.length >= 3,
      cooltime: questData?.checkin
        ? new Date(
            new Date(
              questData?.checkin[questData?.checkin?.length - 1],
            ).getTime() +
              1000 * 60 * 60 * 3,
          )
        : new Date(new Date().getTime() + 1000 * 60 * 60 * 3),
    },
    {
      description: '상품 30초 구경하기',
      subText: '물 10g, 하루 최대 3번',
      type: 'browsing',
      rewardText: '물 15g',
      rewardImg: '../newYear2024/water.png',
      color: '#B7C43F',
      shadow: '#869216',
      buttonText: '구경하기',
      img: '../newYear2024/browsing.png',
      onClick: () => {
        AlfarmEventLogger({
          throttleRef,
          locationType: 'page',
          locationName: 'newYear2024',
          eventType: 'click',
          eventName: 'browsing',
          data: {},
          collection: 'UserAlfarmClickLog',
        })
        sendToPurchaseScreen('browsingQuest')
      },
      isCleared: questStatus?.browsing,
      isOver: questData?.browsing?.length >= 3,
      cooltime: questData?.browsing
        ? new Date(
            new Date(
              questData?.browsing[questData?.browsing?.length - 1],
            ).getTime() +
              1000 * 60 * 60 * 3,
          )
        : new Date(new Date().getTime() + 1000 * 60 * 60 * 3),
    },
    {
      description: '상품 1개 구매하기',
      subText: testRewardDesc,
      type: 'purchase',
      rewardText: '고급비료 2개',
      buttonText: '구매하기',
      rewardImg: '../newYear2024/fertilizerSet.png',
      color: '#FFC634',
      shadow: '#FFA113',
      img: '../newYear2024/purchase.png',
      onClick: () => {
        AlfarmEventLogger({
          throttleRef,
          locationType: 'page',
          locationName: 'newYear2024',
          eventType: 'click',
          eventName: 'purchase',
          data: {},
          collection: 'UserAlfarmClickLog',
        })
        sendToPurchaseScreen('purchaseQuest')
      },
      isCleared: false,
      isOver: false,
    },
  ]

  return (
    <>
      <div className='w-[65vw] flex flex-row items-center px-[4vw] mb-[2vw] justify-between	ml-[35vw]'>
        <div className='text-[5vw] mt-[0] mb-[1vw] text-[#442b22] text-center z-[2]'>
          한정 이벤트
        </div>
        <button
          className='ml-[5vw] w-[7vw] h-[7vw] z-[1] '
          onClick={() => {
            BottomSheetStore.setIsbottomSheetOpen('basic')
          }}
        >
          <img
            className='w-full z-[3]'
            src='/icon/bottomCloseButton.png'
            alt=''
          />
        </button>
      </div>
      {exchangeItemCard.map((item, index) => {
        return (
          <div key={index}>
            <div className='rounded-[2vw] p-[1vw] ml-[2.5vw] w-[95vw] mb-[4vw]  h-[18vw] items-center border-[0.5vw] border-[#946134] flex flex-row justify-between'>
              <div className='flex flex-row mt-[1vw] '>
                <img
                  className='ml-[4vw] mr-[4vw] w-[12vw] h-[12vw] bg-[#FFF7D6] align-center p-[0.5vw] rounded-[2vw]'
                  src={item.img}
                  alt=''
                />
                <div>
                  <div className='text-[4.5vw] text-[#6A3615]'>
                    {item.description}
                  </div>
                  <div className='flex flex-row items-center '>
                    <img
                      className='ml-[-1vw] w-[7vw]'
                      src={item.rewardImg}
                      alt=''
                    />
                    <div className='text-[3.5vw] text-center text-[#6A3615]'>
                      {item.subText}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <MissionButton item={item} />
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
})

export default SubQuestBottomSheet
