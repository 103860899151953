import React, { useState, useEffect, useRef } from 'react'
import Layout from '../comps/atoms/layout'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { NavLink } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import commaNumber from 'comma-number'
import toast, { Toaster } from 'react-hot-toast'
import dayjs from 'dayjs'
import UserStore from 'store/UserStore'

const BACKGROUND_COLOR = '#f1f5f9'
const BACKGROUND_POINT_COLOR = '#FFC9C9'
const POINT_TEXT_COLOR = '#FF3469'
const TEXT_COLOR = '#000'
const BUTTON_BACKGROUND_COLOR = '#FF3469'
const BUTTON_TEXT_COLOR = '#fff'

const TutorialText = ({ first, second }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
      }}
    >
      <div style={{ color: TEXT_COLOR, marginRight: 4 }}>{first}</div>
      <div style={{ color: TEXT_COLOR, wordBreak: 'keep-all' }}>{second}</div>
      <br />
    </div>
  )
}

const Affiliation = () => {
  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const randomString = useSearchParam('randomString')
  const userId = useSearchParam('userId')
  const [affiliationData, setAffiliationData] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [showShop, setShowShop] = useState(false)
  const [showTutorial, setShowTutorial] = useState(false)

  const toastBlock = useRef()

  useEffect(() => {
    initAffiliationPage()
    toastBlock.current = false
  }, [])
  useEffect(() => {
    if (affiliationData) setIsLoading(false)
  }, [affiliationData])

  const notify = (toastBlock, toastText) => {
    if (toastBlock.current === true) return
    toast(toastText)
    toastBlock.current = true
    setTimeout(() => {
      toastBlock.current = false
    }, 2200)
  }

  const purchaseItem = async (type) => {
    if (toastBlock.current === true) return
    let coinCost = 80
    let rewardType = 80
    let rewardAmount = 1000
    let rewardTypeKorean = '물'
    let unit = 'g'
    switch (type) {
      case 'fixWater':
        coinCost = 80
        rewardType = 'water'
        rewardAmount = 1000
        break
      case 'fixFertilizerSet':
        coinCost = 90
        rewardType = 'fertilizerSet'
        rewardAmount = 3
        rewardTypeKorean = '고급비료'
        unit = '개'
        break
      default:
        coinCost = 80
        rewardType = 'water'
        rewardAmount = 1000
        break
    }

    if (affiliationData?.rewardCoin >= coinCost) {
      let result = await backendApis.purchaseAffiliationItem({ type })
      if (result?.data?.success) {
        let temp = { ...affiliationData }
        temp.rewardCoin -= coinCost
        setAffiliationData(temp)
        notify(toastBlock, `${rewardTypeKorean} ${rewardAmount}${unit} 획득!`)

        return
      }
    }

    notify(toastBlock, '하트가 부족해요')
  }

  const sendToPurchaseScreen = async () => {
    const purchaseData = {
      enteringComponent: 'Alfarm_affiliationRecommend',
      text: '올팜',
      source:
        'https://assets.ilevit.com/e1a56631-dd64-4bd8-b631-e229ee478123.png',
      rewardText: '좋은 상품을 추천해서 하트를 모아보세요',
      // placeholderType: "alfarmSearchMission",

      alwayzInvitorId: userId,
    }
    window.location.href = `#commonPurchaseReward.${JSON.stringify(
      purchaseData,
    )}`
  }

  const initAffiliationPage = async () => {
    let result = await backendApis.getAffiliationData()
    if (!result?.data) {
      result = await backendApis.initAffiliationData()
      setShowTutorial(true)
    }
    result?.data?.rewardHistory.reverse()
    setAffiliationData(result?.data)
  }

  const HistoryItem = ({ data }) => {
    let rewardedAt = dayjs(data?.rewardedAt).format('MM월 DD일')

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: 16,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              // border: "1px solid black",
            }}
          >
            <img
              alt='profileImage'
              style={{
                width: 50,
                height: 50,
                borderRadius: 55,
                marginRight: 16,
              }}
              src={data?.userImage}
              onError={(e) => {
                e.target.src = UserStore?.default_profile_image
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-around',
                // border: "1px solid black",
                height: 50,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  // border: "1px solid black",
                }}
              >
                <div style={{ color: TEXT_COLOR }}>{`${data?.userName}`}</div>
              </div>
              <div style={{ color: TEXT_COLOR, fontWeight: 'normal' }}>
                {data?.itemTitle?.length > 10
                  ? `${data?.itemTitle?.slice(0, 10)}...`
                  : data?.itemTitle}
              </div>
            </div>
          </div>

          <div
            style={{
              // border: "1px solid black",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                alt='profileImage'
                style={{
                  width: 30,
                  height: 30,
                  // borderRadius: 55,
                  marginRight: 8,
                }}
                src='/affiliation/coin.png'
              />
              <div
                style={{ color: TEXT_COLOR }}
              >{`${data?.rewardAmount}개`}</div>
            </div>
            <div
              style={{ color: '#666', fontSize: '0.6rem' }}
            >{`${rewardedAt}`}</div>
          </div>
        </div>
        <div
          style={{
            border: '0.5px solid #ccc',
            width: '96%',

            marginRight: 8,
            marginLeft: 8,
          }}
        />
      </>
    )
  }

  const ShopItem = ({ image, cost, type }) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          // border: "1px solid black",
          padding: '8px 8px 8px 8px',
          borderRadius: 8,
          backgroundColor: BACKGROUND_POINT_COLOR,
        }}
      >
        <div
          style={{
            // border: "1px solid black",
            padding: '2vw',
            borderRadius: 8,
            backgroundColor: BACKGROUND_COLOR,
            marginBottom: 8,
          }}
        >
          <div
            style={{
              width: '30vw',
              height: '30vw',
              // border: "1px solid black",
              backgroundImage: image,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            fontFamily: 'maplestory',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            backgroundColor: BACKGROUND_POINT_COLOR,
            borderRadius: 99,
            padding: '4px 12px 4px 12px',
            // marginTop: 4,
            color: BUTTON_BACKGROUND_COLOR,
            width: '100px',
          }}
        >
          <img
            alt='profileImage'
            style={{
              width: 20,
              height: 20,
              borderRadius: 11,
              // padding: 4,
              marginRight: 4,
            }}
            src='/affiliation/coin.png'
          />
          {`${cost}개`}
        </div>
        <div
          style={{
            // border: "1px solid black",
            marginTop: 8,
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            borderRadius: 8,
            backgroundColor: BUTTON_BACKGROUND_COLOR,
            width: '34vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            purchaseItem(type)
          }}
        >
          <div
            style={{
              color: BUTTON_TEXT_COLOR,
              fontFamily: 'maplestory',
              fontWeight: 'bold',
              fontSize: '1.2rem',
              position: 'relative',
            }}
          >
            구매하기
          </div>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <Layout isLoading={isLoading}></Layout>
  }

  return (
    <div
      style={{
        // background: `linear-gradient(180deg, ${POINT_TEXT_COLOR} 0%, #FFAAAA 76.04%)`,
        backgroundColor: BACKGROUND_POINT_COLOR,
        // overflowY: "scroll",
        height: '100vh',
        width: '100%',
        display: 'flex',
        padding: 16,
        fontFamily: 'maplestory',
      }}
    >
      <NavLink
        to={`/farmPage?token=${token}&itemType=${itemType}&randomNumber=${randomString}`}
      >
        <button
          style={{
            position: 'absolute',
            width: '7vw',
            // marginLeft: "2vw",
            paddingTop: '10vw',
          }}
          action='goBack'
          onClick={() => {}}
        >
          <img src='/affiliation/backWhite.png' alt='' />
        </button>
      </NavLink>
      <div
        style={{
          position: 'absolute',
          width: '7vw',
          height: '7vw',
          marginRight: '2vw',
          paddingTop: '10vw',
          right: 0,
        }}
        onClick={() => {
          setShowTutorial(true)
        }}
      >
        <img src='/affiliation/info.png' alt='' />
      </div>

      <div
        style={{
          flex: 1,
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '6vw',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '30vw',
            backgroundImage: 'url(../affiliation/title.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginBottom: 16,
          }}
        />

        {/* <div
          style={{
            border: "1px solid white",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <div
            style={{
              // width: "160px",
              height: "60px",
              border: "1px solid white",
              color: "white",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {`경험치: `}

            {`${affiliationData?.exp ?? 0}`}
          </div>
        </div> */}
        <div
          style={{
            // border: "1px solid white",
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 16,
          }}
        >
          <div
            style={{
              width: '260px',
              height: '70px',
              // border: "1px solid black",
              color: TEXT_COLOR,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              paddingLeft: 32,
              paddingRight: 32,
              borderRadius: 16,
              backgroundColor: BACKGROUND_COLOR,
            }}
          >
            <div
              style={{
                color: POINT_TEXT_COLOR,
                fontWeight: 'bold',
                marginBottom: 4,
              }}
            >
              {`내 하트`}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'row',
                color: TEXT_COLOR,
                // border: "1px solid black",
                fontSize: '1.4rem',
              }}
            >
              <img
                alt='profileImage'
                style={{
                  width: 30,
                  height: 30,
                  // borderRadius: 55,
                  marginRight: 4,
                }}
                src='/affiliation/coin.png'
              />
              {`${commaNumber(affiliationData?.rewardCoin)}개`}
            </div>
          </div>

          <div
            style={{
              width: '70px',
              height: '70px',
              // border: "1px solid white",
              backgroundImage: 'url(../affiliation/shopEntryIcon.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            onClick={() => {
              setShowShop(true)
            }}
          />
        </div>
        {affiliationData?.rewardHistory?.length > 0 ? (
          <div
            style={{
              color: TEXT_COLOR,
              fontWeight: 'bold',
              // border: "1px solid white",
              flex: 1,
              // marginTop: 4,
              marginBottom: 16,
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              overflow: 'scroll',
              borderRadius: 16,
              backgroundColor: BACKGROUND_COLOR,
            }}
          >
            <div
              style={{
                color: POINT_TEXT_COLOR,
                marginTop: 16,
                marginBottom: 16,
                fontSize: '1.2rem',
              }}
            >
              취향 저격 히스토리
            </div>
            <div
              style={{
                color: TEXT_COLOR,
                fontWeight: 'bold',
                // border: "1px solid white",
                flex: 1,
                // marginTop: 4,
                marginBottom: 16,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                textAlign: 'center',
                flexDirection: 'column',
                overflow: 'scroll',
                backgroundColor: BACKGROUND_COLOR,
                borderTop: '0.5px solid',
                borderTopColor: BUTTON_BACKGROUND_COLOR,
              }}
            >
              {affiliationData?.rewardHistory?.map((e) => (
                <HistoryItem key={Math.random()} data={e} />
              ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              color: TEXT_COLOR,
              fontWeight: 'bold',
              // border: "1px solid white",
              flex: 1,
              // marginTop: 4,
              marginBottom: 16,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              borderRadius: 16,
              backgroundColor: BACKGROUND_COLOR,
            }}
          >
            취향을 저격해 하트를 얻어보세요
          </div>
        )}

        <div
          style={{
            // background:
            //   "linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)",
            // marginTop: 4,
            backgroundColor: BUTTON_BACKGROUND_COLOR,
            width: '100%',
            height: '16vw',
            borderRadius: 16,
            color: BUTTON_TEXT_COLOR,
            fontFamily: 'Maplestory',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 32,
            fontWeight: 'bold',
            fontSize: '1.2rem',
            // position: "fixed",
          }}
          onClick={sendToPurchaseScreen}
          // className={"pulsate-fwd"}
        >
          추천할 상품 고르기
        </div>
      </div>
      <BottomSheet
        className='bottom-sheet'
        open={showShop}
        scrollLocking={false}
        onDismiss={() => {
          setShowShop(false)
        }}
        defaultSnap={({ lastSnap, snapPoints }) => {
          lastSnap ?? Math.max(...snapPoints)
        }}
      >
        <div
          style={{
            // height: "200px",
            width: '100%',
            // border: "1px solid white",
            marginBottom: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              color: TEXT_COLOR,
              fontFamily: 'maplestory',
              fontWeight: 'bold',
              marginBottom: 16,
              fontSize: '1.2rem',
            }}
          >
            상점
          </div>
          <div
            style={{
              width: '90%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: 16,
              padding: 4,
              paddingBottom: 32,
              paddingTop: 16,
              // backgroundColor: BACKGROUND_COLOR,
              // border: "1px solid #334155",
            }}
          >
            <ShopItem
              cost={80}
              image={'url(../affiliation/waterReward.png)'}
              type={'fixWater'}
            />

            <ShopItem
              cost={90}
              image={'url(../affiliation/fertilizerSetFixedReward.png)'}
              type={'fixFertilizerSet'}
            />
          </div>
          {/* <div
            style={{
              width: "25%",
              height: "45px",
              borderRadius: 8,
              backgroundColor: BUTTON_BACKGROUND_COLOR,
              color: BUTTON_TEXT_COLOR,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "maplestory",
              fontWeight: "bold",
              fontSize: "1.2rem",
              marginBottom: 16,
            }}
            onClick={() => {
              setShowShop(false);
            }}
          >
            닫기
          </div> */}
        </div>
      </BottomSheet>

      {showTutorial && (
        <div
          style={{
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.8)',
            width: '100%',
            height: '100%',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 16,
          }}
        >
          <div
            style={{
              position: 'absolute',

              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
            }}
          >
            <div
              style={{
                color: TEXT_COLOR,
                // fontWeight: "bold",
                // border: "1px solid black",
                // height: "100px",
                backgroundColor: BACKGROUND_COLOR,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'left',
                marginBottom: 16,
                lineHeight: '1.4rem',

                borderRadius: 16,
                padding: 16,
                paddingLeft: 32,
                paddingRight: 32,
              }}
            >
              <b
                style={{
                  color: POINT_TEXT_COLOR,
                  textAlign: 'left',
                  margin: 0,
                  padding: 0,
                  fontSize: '1.2rem',
                  marginBottom: 16,
                  marginTop: 8,
                }}
              >
                취향 저격수 참여방법
              </b>
              <TutorialText
                first='1.'
                second={' 친구의 취향을 저격해서 상품을 추천해보세요'}
              />
              <TutorialText
                first='2.'
                second={' 추천한 상품을 친구가 구매하면 하트 100개를 받아요'}
              />
              <TutorialText
                first='3.'
                second={' 모은 하트로 상점에서 물과 비료를 구매해보세요'}
              />
              <br />
              <b
                style={{
                  color: POINT_TEXT_COLOR,
                  fontSize: '1.2rem',
                }}
              >
                저격 성공 꿀팁
              </b>

              <br />
              <TutorialText
                first='1.'
                second={' 가성비가 좋은 상품을 찾아서 추천해야해요'}
              />
              <TutorialText
                first='2.'
                second={' 취향 저격 시도를 최대한 많이 해야해요'}
              />
              <TutorialText
                first='3.'
                second={' 후기가 많이 있는 상품은 성공 확률이 높아요'}
              />
              <br />
              <div
                style={{
                  color: '#333',
                  fontSize: '0.8rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // border: "1px solid white",
                  marginTop: 4,
                  lineHeight: '1rem',
                }}
              >
                {`* 친구가 링크를 통해 상품을 구매해야 하트를 받아요.`}
                <br />
                {`* 이 이벤트는 사전 고지 없이 조기 종료될 수 있어요.`}
                <br />
                {`* 부정한 방법으로 획득한 재화는 회수될 수 있어요`}
                <br />
                {`* 더 자세한 안내는 고객센터를 이용해주세요.`}
              </div>
            </div>
            <button
              style={{
                width: '7vw',
                right: 0,
                top: '-12vw',
                position: 'absolute',
                zIndex: 999,
              }}
              onClick={() => {
                setShowTutorial(false)
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Affiliation
