import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import AuthStore from '../../../store/AuthStore'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import UserStore from 'store/UserStore'

const LastHarvestPurchaseModal = observer(
  ({ token, farmData, itemType, setEnteringRoute, setAlertModal }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const codePushVersionAvailable = useCheckCodePushVersion()

    const harvestData = async (link) => {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'harvest',
          data: {
            itemPrice: farmData?.itemPrice,
          },
        }),
      )
      window.location.href = `${link}&key=${AuthStore.key}`
    }

    const lastHarvestPurchase = async () => {
      if (farmData?.itemPrice) {
        if (
          !codePushVersionAvailable(
            UserStore?.codePushVersionFromAlwayzApp,
            '4.6.36',
          )
        ) {
          setEnteringRoute('newVersionAppForHarvest')
          setAlertModal(true)
          return
        }
      }
      if (itemType === 'coffee_starbucks') {
        harvestData('#lastHarvestPurchaseCoffeeStarbucks')
        return
      }
      if (itemType === 'coffee_mega') {
        harvestData('#lastHarvestPurchaseCoffeeMega')
        return
      }
      if (itemType === 'coffee_compose') {
        harvestData('#lastHarvestPurchaseCoffeeCompose')
        return
      }
      const refinedItemType =
        itemType.charAt(0).toUpperCase() + itemType.slice(1)
      harvestData(`#lastHarvestPurchase${refinedItemType}`)
    }

    return (
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90vw',
            height: '180vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundImage: 'url(../lastHarvestPurchase/modal.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <div
            style={{
              background: isButtonPressed ? '#F7C82A' : '#F7C82A',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              width: '65vw',
              height: '14vw',
              borderRadius: '100vw',
              color: 'black',
              fontFamily: 'maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '4.5vw',
              position: 'absolute',
              bottom: '17.5%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            onClick={() => {
              lastHarvestPurchase()
              ModalStore.setIsModalOpen('basic')
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
          >
            왕창 수확 혜택 받기
          </div>

          <button
            style={{
              width: '10%',
              top: '20vw',
              right: '0%',
              position: 'absolute',
              zIndex: 1113,
              background: 'black',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
        </div>
      </div>
    )
  },
)

export default LastHarvestPurchaseModal
