import React, { useState } from 'react'
import UserStore from 'store/UserStore'

const sprite = {
  giveWater: {
    images: ['/icon/giveWater.png'],
  },
  giveWaterFive: {
    images: ['/icon/giveWaterFive.png'],
  },
  givingWater: {
    images: ['/icon/givingWater.png'],
  },
  newGiveWater: {
    images: ['/icon/giveWaterTwenty.png'],
  },
}

const BasicButton = ({
  action = 'giveWater',
  onClick,
  disabled,
  farmData,
  subButton,
  label,
}) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  return (
    <div
      style={{
        zIndex:
          UserStore?.giveWaterCount >= 6 &&
          UserStore?.giveWaterCount < 9 &&
          UserStore?.farmData?.nutriment >= 75
            ? 9
            : 140,
        position: 'relative',
      }}
    >
      <button
        disabled={disabled}
        onClick={onClick}
        className='w-[20vw]'
        onPointerDown={() => {
          setIsButtonPressed(true)
        }}
        onPointerCancel={() => {
          setIsButtonPressed(false)
        }}
        onPointerUp={() => {
          setIsButtonPressed(false)
        }}
        style={{
          opacity: isButtonPressed ? 0.6 : 1.0,
        }}
      >
        <div className='bt-farm-label'>{label}</div>
        <div
          style={{
            zIndex: -1,
            marginBottom: subButton ? '-2vw' : '0vw',
            marginRight: subButton ? '-2vw' : '0vw',
          }}
        >
          <img src={sprite[action].images} alt='' />
        </div>
      </button>
    </div>
  )
}

export default BasicButton
