import backendApis from '../../utils/backendApis'
import React, { useState, useEffect, useRef } from 'react'
import WhiteTextTimer from '../WhiteTextTimer'
import ModalStore from 'store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const LuckydrawPurchaseReward = ({
  luckydrawPurchaseRewardEndAt,
  setLuckydrawPurchaseRewardOn,
}) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const throttleRef = useRef({})

  const LuckydrawPurchaseRewardButton = () => {
    AlfarmEventLogger({
      throttleRef,
      locationType: 'modal',
      locationName: ModalStore?.isModalOpen,
      eventType: 'click',
      eventName: 'cta',
      data: {},
      collection: 'UserAlfarmClickLog',
    })

    const luckydrawPurchaseReward = {
      screen: 'AlfarmLuckydrawPurchaseRewardScreen',
      rewardType: 'fertilizer',
    }

    window.location.href = `#luckydrawPurchaseReward.${JSON.stringify(
      luckydrawPurchaseReward,
    )}`
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 2001,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.7)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: '80%',
          left: '10%',
          zIndex: 2001,
          top: '55vw',
        }}
      >
        <img src='/icon/luckydrawPurchaseRewardModal.png' alt='' />
        <div
          style={{
            position: 'absolute',
            fontFamily: 'maplestory',
            flex: 1,
            bottom: '25vw',
            zIndex: 2,
            borderRadius: 5,
            width: '100%',
            textAlign: 'center',
            color: '#442b22',
          }}
        >
          <WhiteTextTimer
            onTimeEnd={() => {
              setLuckydrawPurchaseRewardOn(false)
              ModalStore?.setIsModalOpen('basic')
            }}
            timeStamp={luckydrawPurchaseRewardEndAt}
            timerMinutes={0}
            color='#442b22'
          />
          {'  '}이후에 사라져요
        </div>

        <button
          style={{
            background: isButtonPressed ? '93CB4E' : '#93CB4E',
            boxShadow: isButtonPressed
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',
            flex: 1,
            position: 'absolute',
            left: '10%',
            bottom: '10vw',
            zIndex: 2,
            borderRadius: 5,
            width: '80%',
            height: 50,
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
          onClick={() => {
            LuckydrawPurchaseRewardButton()
            ModalStore?.setIsModalOpen('basic')
          }}
        >
          <div
            style={{
              color: 'white',
              fontFamily: 'maplestory',
              fontSize: '5vw',
            }}
          >
            구매하고 비료받기
          </div>
        </button>
      </div>
      <button
        style={{
          width: '10%',
          top: '40vw',
          right: '12%',
          zIndex: 2001,
          position: 'absolute',
        }}
        onClick={() => {
          ModalStore?.setIsModalOpen('basic')
        }}
      >
        <img src='/icon/modalCloseButton.png' alt='' />
      </button>
    </>
  )
}

export default LuckydrawPurchaseReward
