import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import UserStore from 'store/UserStore'

const NewUserEventWaterModal = observer(
  ({ token, farmData, showRewardToast, setFarmData }) => {
    const throttleRef = useRef({})
    const [reviewData, setReviewData] = useState([])

    const alfarmReview = async () => {
      const result = await backendApis.getAlfarmReview(token)
      setReviewData(result)
    }

    useEffect(() => {
      alfarmReview()
    }, [])

    const receiveWater = async () => {
      backendApis.giveReward(token, 'PUT', {
        loggingRewardName: 'newUserEventWater',
        rewardType: 'water',
        valueAmount: 600,
      })

      AlfarmEventLogger({
        throttleRef,
        locationType: 'newUserEventWaterModal',
        locationName: 'receiveWaterButton',
        eventType: 'click',
        collection: 'UserAlfarmClickLog',
      })

      ModalStore.setRewardPopUpOn({
        bool: true,
        type: 'water',
        amount: 600,
      })
      setFarmData((prev) => {
        let tempWater = prev?.water
        if (UserStore.waterExceeded) {
          tempWater += 300
        } else {
          tempWater += 600
        }
        return {
          ...prev,
          water: tempWater,
        }
      })
      ModalStore?.setIsModalOpen('basic')
    }

    const ReviewPhoto = ({ review }) => {
      return (
        <div>
          <img
            className='w-[14vw] h-[14vw] object-cover rounded-md'
            src={review?.reviewImageUris?.[0]}
            alt='review'
          />
          <div className='flex flex-row mt-[2vw] justify-start items-center'>
            <img
              className='w-[5vw] h-[5vw] rounded-full mr-2'
              src={review?.userImageUri}
              alt='abc'
            />
            <div className='text-[3vw]'>
              {review?.userName?.length > 3
                ? review?.userName?.slice(0, 3) + '..'
                : review?.userName}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='flex flex-1 fixed left-0 top-0 z-[1900] w-full h-full bg-black bg-opacity-80'>
        <div
          className={`w-[80vw] h-[74.25vw] 
          top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col`}
          style={{
            backgroundImage: `url(../modal/newUserEventWater.png)`,
          }}
        >
          <button
            className='absolute w-[8vw] right-0 top-[-12vw] z-[1999]'
            onClick={() => {
              receiveWater()
            }}
          >
            <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
          </button>
          <div className='w-full mt-[110vw] z-[1101] relative '>
            <div className='mb-[2vw]'>실제 무료로 받은 후기 사진</div>
            <div className='flex w-full flex-row gap-x-4 overflow-x-scroll'>
              {reviewData?.map((review, index) => {
                return <ReviewPhoto review={review} />
              })}
            </div>
          </div>
          <div
            className='w-[70vw] h-[12vw] absolute bottom-[7vw]'
            onClick={() => {
              receiveWater()
            }}
          />
        </div>
      </div>
    )
  },
)
export default NewUserEventWaterModal
