import React, { useState, useEffect } from 'react'
import ModalStore from '../../../store/ModalStore'
import { useNavigate } from 'react-router-dom'
import backendApis from 'utils/backendApis'

const ActivationEngagingModal = ({
  farmData,
  lackWaterEngageType,
  token,
  itemType,
}) => {
  const [backGroundModalImg, setBackGroundModalImg] = useState(
    'url(../engagingModal/inviteEngaging.png)',
  )
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const navigate = useNavigate()
  const [rewardType, setRewardType] = useState('물')
  const [isInviteNudge, setIsInviteNudge] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const dailyPurchaseRewardFert = async () => {
    const purchaseRewardFert = {
      screen: 'AlfarmPurchaseRewardScreen',
      rewardType: 'fert',
    }

    window.location.href = `#purchaseRewardFert.${JSON.stringify(
      purchaseRewardFert,
    )}`
  }

  const BGModal = () => {
    if (lackWaterEngageType === 'dailyInvite') {
      setBackGroundModalImg('url(../engagingModal/inviteEngaging.png)')
      setRewardType('물')
      setIsInviteNudge(true)
    } else if (lackWaterEngageType === 'vipCard') {
      setBackGroundModalImg('url(../engagingModal/vipCard.png)')
      setRewardType('고급비료')
      setIsInviteNudge(true)
    } else if (lackWaterEngageType === 'friendGarden') {
      setBackGroundModalImg('url(../engagingModal/friendGarden.png)')
      setRewardType('고급비료')
      setIsInviteNudge(true)
    } else if (lackWaterEngageType === 'vipCard_nutriment') {
      setBackGroundModalImg('url(../engagingModal/vipCard_nutriment.png)')
      setRewardType('고급비료')
      setIsInviteNudge(true)
    } else if (lackWaterEngageType === 'friendGarden_nutriment') {
      setBackGroundModalImg('url(../engagingModal/friendGarden_nutriment.png)')
      setRewardType('고급비료')
      setIsInviteNudge(true)
    } else if (
      lackWaterEngageType === 'normalFertPurchase' ||
      lackWaterEngageType === 'normalFertPurchase_nutriment'
    ) {
      setBackGroundModalImg('url(../engagingModal/normalFertPurchase.png)')
      setRewardType('고급비료')
      setIsInviteNudge(false)
    } else if (
      lackWaterEngageType === 'weeklyBingoPurchase' ||
      lackWaterEngageType === 'weeklyBingoPurchase_nutriment'
    ) {
      setBackGroundModalImg('url(../engagingModal/weeklyBingoPurchase.png)')
      setRewardType('고급비료')
      setIsInviteNudge(false)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    BGModal()
  }, [lackWaterEngageType])

  if (isLoading)
    return (
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      />
    )

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '70vw',
            height: '180vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundImage: backGroundModalImg,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <button
            style={{
              width: '12%',
              top: '20%',
              right: '0%',
              position: 'absolute',
              zIndex: 1113,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            style={{
              background: isButtonPressed
                ? 'linear-gradient(180deg, #04D45A 0%, #03C954 40.6%, #02B94B 100%)'
                : 'linear-gradient(180deg, #04D45A 0%, #03C954 40.6%, #02B94B 100%)',
              boxShadow: isButtonPressed
                ? 'inset 1vw 2vw 3vw rgba(79, 19, 0, 0.15)'
                : '',
              width: '60vw',
              height: '14vw',
              borderRadius: 99,
              color: 'white',
              fontFamily: 'maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '4.5vw',
              position: 'absolute',
              top: '61%',
              left: '5vw',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
              if (lackWaterEngageType === 'dailyInvite') {
                ModalStore.setIsModalOpen('dailyInvite')
              } else if (
                lackWaterEngageType === 'vipCard' ||
                lackWaterEngageType === 'vipCard_nutriment'
              ) {
                ModalStore.setIsModalOpen('vipCard')
              } else if (
                lackWaterEngageType === 'friendGarden' ||
                lackWaterEngageType === 'friendGarden_nutriment'
              ) {
                navigate(`../friendGarden?token=${token}&itemType=${itemType}`)
              } else if (
                lackWaterEngageType === 'normalFertPurchase' ||
                lackWaterEngageType === 'normalFertPurchase_nutriment'
              ) {
                dailyPurchaseRewardFert()
              } else if (
                lackWaterEngageType === 'weeklyBingoPurchase' ||
                lackWaterEngageType === 'weeklyBingoPurchase_nutriment'
              ) {
                ModalStore.setIsModalOpen('weeklyBingoPurchase')
              }
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
          >
            {`${
              isInviteNudge ? '친구 초대하고 ' : '상품 구매하고 '
            } ${rewardType} 받기 `}
          </div>
        </div>
      </div>
    </>
  )
}
export default ActivationEngagingModal
