import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import QRCode from 'react-qr-code'
import { isAndroid, isIOS, isIOS13, is } from 'react-device-detect'
import WhiteTextTimer from 'comps/WhiteTextTimer'
import ModalStore from '../../../store/ModalStore'

let rewardTypeMap = {
  level1: { rewardType: `고급비료`, rewardAmount: 2 },
  level2: { rewardType: `고급비료 2개, 비료`, rewardAmount: 2 },
  level3: { rewardType: `물 300g, 고급비료 3개, 비료`, rewardAmount: 3 },
  level4: { rewardType: `물 400g, 고급비료 4개, 비료`, rewardAmount: 4 },
}

const GoldFilledModal = observer(({ token, relayRewardInfo, farmData }) => {
  const [backgroundPngSrc, setBackgroundPngSrc] = useState(
    'url(../goldFilled/background2.png)',
  )

  const sendToPurchaseScreen = () => {
    const payload = {
      enteringComponent: 'Alfarm_GoldFilled',
      text: '올팜',
      source:
        'https://assets.ilevit.com/65ea847a-37db-4ad8-83b4-e77000eca9e9.png',
      rewardText: `상품을 구매하고 물 1,000g 고급비료 5개를 받아보세요`,
    }
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'navigate',
        data: {
          screen: 'AlfarmRevenuePurchaseScreen',
          payload,
        },
      }),
    )

    ModalStore.setIsModalOpen('basic')
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 900,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90vw',
            height: '150vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'column',
            // border: "1px solid red",
            padding: 16,
            backgroundImage: backgroundPngSrc,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            // paddingTop: "57vw",
            paddingTop: '28vw',
            paddingBottom: '16px',
            // marginBottom: "15%",
          }}
        >
          {/* 닫기버튼 */}
          <button
            style={{
              width: '8vw',
              right: 0,
              top: -8,
              position: 'absolute',
              zIndex: 999,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            style={{
              width: '100%',
              flex: 1,
              padding: '4vw',
              paddingTop: '10vw',
            }}
          >
            {/* <img
              style={{
                width: "100%",
                height: "100%",
              }}
              alt=""
              src=""
            /> */}
          </div>
          {/* CTA */}
          <div
            style={{
              background:
                'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

              width: '60vw',
              height: '14vw',
              borderRadius: 99,
              color: 'black',
              fontFamily: 'Maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 8,
              fontSize: '4.5vw',
            }}
            onClick={() => {
              sendToPurchaseScreen()
            }}
          >
            {'구매하고 보상 받기'}
          </div>
        </div>
      </div>
    </>
  )
})

export default GoldFilledModal
