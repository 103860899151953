import { observer } from 'mobx-react-lite'
import React from 'react'

const ImageText = observer(({ text, className, style = {}, fontSize }) => {
  const extractRegex = (str) => {
    const regex = /<([^>]+)>/g
    const match = regex.exec(str)

    if (match !== null) {
      return match[1]
    }
  }

  const renderDescription = (text) =>
    text?.split('\n').map((line, index) => {
      const extractedImage = extractRegex(line)
      const fixedLine = line.replace(/<[^>]+>/g, '_image_')
      const imageLine = fixedLine.split('_image_').map((line2, index) => (
        <span
          className=' flex-row justify-center items-center'
          key={Math.random()}
        >
          {index > 0 && (
            <img
              alt='textImage'
              src={`../images/${extractedImage}.png`}
              className={`w-[${fontSize + 1}vw] h-[${
                fontSize + 1
              }vw] inline-block mb-1 ml-1`}
            />
          )}
          {line2}
        </span>
      ))
      return (
        <span
          key={Math.random()}
          className=''
          style={{
            fontSize: `${fontSize}vw`,
            ...style,
          }}
        >
          {imageLine}
          {index < text.split('\n').length - 1 && <br />}
        </span>
      )
    })

  return <div className={className}>{renderDescription(text)}</div>
})

export default ImageText
