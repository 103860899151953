import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const PetDetailModal = observer(({ token, farmData, showRewardToast }) => {
  const [isFilled, setIsFilled] = useState(false)
  const throttleRef = useRef({})

  const shareKakao = () => {
    const inviteData = {
      shareType: 'AlfarmPetInvite',
      title: '[올팜] 함께 올팜 작물을 키워봐요!',
      description: '내가 키운 작물을 무료로 받아보세요!',
      bannerImage:
        'https://assets.ilevit.com/34ed5826-d1bd-4822-940f-02274f0a9042.png',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
    AlfarmEventLogger({
      throttleRef,
      locationType: 'petDetailModal',
      locationName: 'petInviteButton',
      eventType: 'click',
      collection: 'UserAlfarmClickLog',
    })
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1900,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          className={`w-[80vw] h-[74.25vw] 
          top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col`}
          style={{
            backgroundImage: `url(../pet/modal/${ModalStore?.config?.petIndex}.png)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            // border: "2px solid black",
          }}
        >
          <button
            style={{
              width: '8vw',
              right: 0,
              top: '-12vw',
              position: 'absolute',
              zIndex: 1999,
              // borderRadius: "1px solid black",
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 1101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            style={{
              width: '70vw',
              height: '12vw',
              position: 'absolute',
              bottom: '4vw',
              // border: '2px solid black',
            }}
            onClick={() => {
              shareKakao()
            }}
          />
          {/* <div
            style={{
              background:
                "linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)",

              width: "60vw",
              height: "14vw",
              borderRadius: 99,
              color: "black",
              fontFamily: "Maplestory",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "5.5vw",
              marginBottom: "8vw",
              position: "absolute",
              bottom: "0",
            }}
            className="scaleUp"
            onClick={() => {
              shareKakao();
            }}
          >
            친구 초대하기
          </div> */}
        </div>
      </div>
    </>
  )
})
export default PetDetailModal
