import React from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../store/ModalStore'

const WaterMaximumModal = observer(() => {
  return (
    <>
      <div className='flex flex-1 fixed left-0 top-0 z-[900] w-full h-full bg-black bg-opacity-80'>
        <div
          className='w-[80vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-[55%] flex absolute justify-around items-center flex-col'
          style={{
            height: `${90 * (310 / 334)}vw`,
            backgroundImage: `url(../modal/waterMaximum.png)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            // border: "2px solid black",
          }}
        >
          <button
            className='absolute w-[8vw] right-0 -top-[10vw] z-[1999]'
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
          </button>
          <div
            className='w-[70vw] h-[12vw] absolute bottom-[7vw]'
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          />
        </div>
      </div>
    </>
  )
})
export default WaterMaximumModal
