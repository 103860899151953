import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ScratchCard from './ScratchCard'
import commaNumber from 'comma-number'
import UserStore from 'store/UserStore'

const WIDTH = 250
const HEIGHT = 180

const SecretScratchModal = observer(
  ({
    setOpenSecretScratchModal,
    farmData,
    setFarmData,
    secretScratchHistory,
  }) => {
    const [isScratched, setIsScratched] = useState(false)

    const updateWater = () => {
      let tempFarmData = farmData

      tempFarmData.water += secretScratchHistory?.[0]?.prize

      setFarmData(tempFarmData)
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage: 'url(../secretScratch/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '70%',
              // marginBottom: "15%",
            }}
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: '30vw',
                position: 'absolute',
                zIndex: 999,
                borderRadius: '1px solid black',
              }}
              onClick={() => {
                setOpenSecretScratchModal(false)
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>

            <ScratchCard
              width={WIDTH}
              height={HEIGHT}
              image={'/secretScratch/scratch.png'}
              style={{
                // border: "1px solid black",
                borderRadius: '16px',
                padding: 0,
                margin: 0,
              }}
              finishPercent={70}
              onComplete={() => {
                setIsScratched(true)
                updateWater()
              }}
            >
              <div
                style={{
                  width: WIDTH,
                  height: HEIGHT,
                  color: 'black',
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundImage: 'url(../secretScratch/scratched.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                <img
                  style={{
                    objectFit: 'contain',
                    width: '20vw',
                  }}
                  src='/icon/water.png'
                  alt=''
                />
                <div
                  style={{
                    color: 'black',
                    fontFamily: 'Maplestory',
                    textAlign: 'center',
                    fontSize: '5vw',
                  }}
                >
                  {`${
                    commaNumber(secretScratchHistory?.[0]?.prize) || 100
                  }g 당첨!`}
                </div>
              </div>
            </ScratchCard>
            {/* 버튼 */}
            {isScratched ? (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 16,
                }}
              >
                <div
                  style={{
                    top: '-2vw',
                    // left: "57%",
                    right: '0vw',
                    position: 'absolute',
                    width: '8vw',
                  }}
                  className='wobble-ver-left'
                >
                  <img src='/secretScratch/10xping.png' alt='' />
                </div>
                <button
                  style={{
                    width: '70vw',
                    background:
                      'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                    fontFamily: 'maplestory',
                    color: 'black',
                    fontSize: '5vw',
                    borderRadius: '30vw',
                    padding: '4vw 8vw 4vw 8vw',
                    marginBottom: '4vw',
                    // fontWeight: "bold",
                  }}
                  onClick={() => {
                    setOpenSecretScratchModal(false)
                    window.location.href = '#secretScratchReward'
                  }}
                >
                  {`상품 사고 ${commaNumber(
                    (secretScratchHistory?.[0]?.prize || 100) * 10,
                  )}g 더 받기`}
                </button>
              </div>
            ) : (
              <div
                style={{
                  color: '#555',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  margin: '8px 0px 16px 0px',
                  // border: "1px solid black",
                }}
              >
                물을 주면 낮은 확률로 나오는 <br />
                행운의 복권이에요!
              </div>
            )}
          </div>
        </div>
      </>
    )
  },
)

export default SecretScratchModal
