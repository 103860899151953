import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import backendApis from '../../../utils/backendApis'

const AdItemBrowsingModal = observer(({ token, farmData, setFarmData }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '100%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            style={{
              width: '12%',
              top: '-20%',
              right: '5%',
              position: 'absolute',
              zIndex: 1113,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <img
            src='/adItemBrowsing/modal.png'
            alt='abc'
            style={{ width: '80vw' }}
          />
          <div
            style={{
              background: isButtonPressed ? '#F7C82A' : '#F7C82A',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              width: '60vw',
              height: '14vw',
              borderRadius: 99,
              color: 'black',
              fontFamily: 'maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '4.5vw',
              position: 'absolute',
              bottom: '8vw',
            }}
            onClick={async () => {
              const result = await backendApis.getLastestBrowsedAdItem(token)

              const adItemBrowsing = {
                text: '올팜',

                isTimerOn: true,
                timerText: `초간 구경하면 일반비료 1개를 받아요`,
                isTimerEndText: '축하합니다! 물 10g을 받았어요',

                itemId: result?.data?._id,
                enteringComponent: 'Alfarm_browsingAdQuestModal',
                source:
                  'https://assets.ilevit.com/dc0816b9-b6a8-40cd-9a9e-2f6bb0a218c7.png',
                isAdItem: result?.data?.adInfo?.isAdItem,
                itemInfo: result?.data,
              }

              window.location.href = `#adItemBrowsing.${JSON.stringify(
                adItemBrowsing,
              )}`
              ModalStore.setIsModalOpen('basic')
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
          >
            상품 구매하고 혜택 받기
          </div>
        </div>
      </div>
    </>
  )
})

export default AdItemBrowsingModal
