import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import WhiteTextTimer from '../../../comps/WhiteTextTimer'
import dayjs from 'dayjs'
import ModalStore from '../../../store/ModalStore'
import DealAutomationStore from '../../../store/DealAutomationStore'

const ImageSprite = () => {
  if (
    new Date() > dayjs(DealAutomationStore.firstLifeDealData.timeRangeStart) &&
    new Date() < dayjs(DealAutomationStore.firstLifeDealData.timeRangeEnd)
  ) {
    return {
      modalImage: DealAutomationStore.firstLifeDealData.background,
      purchaseScreenBanner:
        DealAutomationStore.firstLifeDealData.purchaseScreenBanner,
      purchaseScreenText:
        DealAutomationStore.firstLifeDealData.purchaseScreenText,
    }
  } else if (
    new Date() > dayjs(DealAutomationStore.secondLifeDealData.timeRangeStart) &&
    new Date() < dayjs(DealAutomationStore.secondLifeDealData.timeRangeEnd)
  ) {
    return {
      modalImage: DealAutomationStore.secondLifeDealData.background,
      purchaseScreenBanner:
        DealAutomationStore.secondLifeDealData.purchaseScreenBanner,
      purchaseScreenText:
        DealAutomationStore.secondLifeDealData.purchaseScreenText,
    }
  }
}

const LifeModal = observer(({ token, farmData, setFarmData, isLoading }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <button
          style={{
            width: '10%',
            top: '15%',
            right: '10%',
            position: 'absolute',
            zIndex: 1113,
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
        <div
          style={{
            width: '100%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={ImageSprite()?.modalImage}
            alt='abc'
            style={{ width: '80vw' }}
          />
          <div
            style={{
              background: isButtonPressed ? '#F7C82A' : '#F7C82A',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              width: '60vw',
              height: '14vw',
              borderRadius: 99,
              color: 'black',
              fontFamily: 'maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '4.5vw',
              position: 'absolute',
              bottom: '8vw',
            }}
            onClick={() => {
              window.location.href = `#alfarmDealScreen.${JSON.stringify({
                dealType: 'alfarmLife',
                title: ImageSprite()?.purchaseScreenText,
                image: ImageSprite()?.purchaseScreenBanner,
                ratio: 99 / 360,
              })}`
              ModalStore.setIsModalOpen('basic')
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
          >
            상품 구매하고 고급비료 받기
          </div>
        </div>
        {/* <div
              style={style.insideModalStyle}
             
            >
              <img
                src="https://assets.ilevit.com/ceb9ca7e-7bb3-4955-be7e-0615ea1f3fb6.png"
                alt="abc"
                style={{ width: "80vw" }}
              />
            </div> */}
      </div>
    </>
  )
})

export default LifeModal
