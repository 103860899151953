import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'

const LotteryDrawSpecialPurchaseModal = observer(
  ({ token, farmData, setFarmData }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div className='w-[80vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
            <button
              style={{
                width: '10%',
                top: '-10%',
                right: '5%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <img
              src='/minigame/lotteryDraw/purchaseModal.png'
              alt='abc'
              style={{ width: '80vw' }}
            />
            <button
              style={{
                background: isButtonPressed ? '93CB4E' : '#93CB4E',
                boxShadow: isButtonPressed
                  ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                  : '',
                flex: 1,
                position: 'absolute',
                left: '10%',
                bottom: '10vw',
                zIndex: 2,
                borderRadius: 5,
                width: '80%',
                height: 50,
              }}
              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
              }}
              onClick={() => {
                const purchaseData = {
                  enteringComponent: 'Alfarm_lotteryDrawPurchase',
                  text: '특별구매',
                  source:
                    'https://assets.ilevit.com/0641c8f4-6cc7-41fa-9b8b-d618b42e3fdf.png',
                  rewardAmount: 1,
                  rewardType: '고급비료, 물',
                  rewardText: `상품을 구매하면 고급비료 3개, 물 500g을 받아요`,
                }
                window.location.href = `#commonPurchaseReward.${JSON.stringify(
                  purchaseData,
                )}`
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontFamily: 'maplestory',
                  fontSize: '5vw',
                }}
              >
                특별 구매하기
              </div>
            </button>
          </div>
        </div>
      </>
    )
  },
)

export default LotteryDrawSpecialPurchaseModal
