import React, { useState, useEffect, useRef } from 'react'

import backendApis from '../utils/backendApis'

import AuthStore from '../store/AuthStore'
import GrapeEventPageHeader from 'comps/seasonalEvent/EventPageHeader'
import GrapeMainImage from 'comps/seasonalEvent/MainImage'
import { CheckSeasonalEventStatus } from 'comps/seasonalEvent/CheckSeasonalEvent'
import ModalStore from 'store/ModalStore'
import SeasonalEventNoticeModal from 'comps/seasonalEvent/SeasonalEventNoticeModal'
import { observer } from 'mobx-react-lite'
import SuccessImage from 'comps/seasonalEvent/SuccessImage'

const GrapeEvent = observer(() => {
  const [piece, setPiece] = useState(0)
  const [seasonalEventStatus, setSeasonalEventStatus] = useState('')
  const [rewardType, setRewardType] = useState('')

  const getEventData = async () => {
    let seasonalEventData = await backendApis.getSeasonalEventData(
      AuthStore.token,
    )

    if (!seasonalEventData?.data) {
      const res = await backendApis.initSeasonalEventData(AuthStore.token)
    } else {
      setPiece(seasonalEventData?.data?.piece)
      setRewardType(seasonalEventData?.data?.rewardType)
    }
  }
  useEffect(() => {
    getEventData()
    const checkStatus = async () => {
      const res = await CheckSeasonalEventStatus()
      setSeasonalEventStatus(res)
    }
    checkStatus()
  }, [])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        background:
          'linear-gradient(180deg, rgba(52,190,209,0.3) 0%, rgba(0,149,77,0.7) 100%)',
      }}
    >
      <GrapeEventPageHeader />
      <div
        style={{
          display: 'flex',
          backgroundImage: 'url(../grapeEvent/title.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '-5vw',
          width: '150vw',
          justifySelf: 'center',
          alignSelf: 'center',
        }}
      >
        <div
          style={{
            height: '40vw',
          }}
        />
      </div>
      <SuccessImage rewardType={rewardType} />
      {/* <GrapeMainImage piece={piece} status={seasonalEventStatus} /> */}
      {ModalStore.isModalOpen === 'seasonalEventNoticeModal' && (
        <SeasonalEventNoticeModal />
      )}
    </div>
  )
})

export default GrapeEvent
