import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import QRCode from 'react-qr-code'
import { isAndroid, isIOS, isIOS13, is } from 'react-device-detect'
import WhiteTextTimer from 'comps/WhiteTextTimer'
import ModalStore from '../../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const EventModal = observer(({ token, relayRewardInfo, farmData }) => {
  const [backgroundPngSrc, setBackgroundPngSrc] = useState(
    'url(../dealEvent/familyDealEvent/familyModal.png)',
  )
  const throttleRef = useRef({})

  const sendToPurchaseScreen = async () => {
    window.location.href = `#generalNavigator.${JSON.stringify({
      screen: 'WebviewScreen',
      params: {
        url: `https://alwayz-shop-front.prod.ilevit.com/deals/family-deal`,
        enteringComponent: 'Alfarm_familyDeal',
        enteringLocation: 'browsingQuest',
        isFrom: 'Alfarm_familyDeal',
        isTimerOn: false,
        timerText: '미션 완료까지 ',
        isTimerEndText: '축하합니다!하트 2개를 받았어요',
        timeStamp: new Date(new Date().getTime() + 1000 * 30),
        rewardText: '상품을 구매하면 고급비료 5개를 드려요',
        questAmount: '2',
        rewardType: '하트',
        timeMinute: 0.5,
      },
    })}`

    ModalStore.setIsModalOpen(false)
  }
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 900,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '80vw',
            height: '150vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            position: 'absolute',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 16,
            backgroundImage: backgroundPngSrc,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            paddingTop: '28vw',
            paddingBottom: '22vw',
          }}
          onClick={(e) => {
            sendToPurchaseScreen()
          }}
        >
          {/* 닫기버튼 */}
          <button
            style={{
              width: '8vw',
              zIndex: 999,
              position: 'absolute',
              top: '20%',
              right: '0%',
              transform: 'translate(-50%, -50%)',
            }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
        </div>
      </div>
    </>
  )
})

export default EventModal
