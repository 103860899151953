import React, { useState, useEffect, useRef } from 'react'
import PlantBasicInfo from './PlantBasicInfo'
import { useInterval } from 'react-use'

window.onload = function () {
  let movementDistance = (window.innerWidth * 12) / 2 + 500
  document.documentElement.style.setProperty(
    '--movement-distance',
    `${movementDistance}px`,
  )
  let init =
    Math.floor((Math.random() * (window.innerWidth * 12)) / 2) +
    Math.random(400)
  document.documentElement.style.setProperty(
    '--initial-movement-distance',
    `${init}px`,
  )
}

const MovingComponentsScreen = ({ userList }) => {
  const [offset, setOffset] = useState(0)
  const [visible, setVisible] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const offsetRef = useRef(offset) // create a ref for offset

  useInterval(() => {
    setOffset((prevOffset) => {
      const newOffset = prevOffset - 4
      offsetRef.current = newOffset // update offset ref
      return newOffset
    })
  }, 50)

  useEffect(() => {
    const containerWidth = document.getElementById('container').clientWidth
    setContainerWidth(containerWidth)
    const initialOffset = Math.floor(Math.random() * 300 + containerWidth / 2)
    setOffset(initialOffset)
    offsetRef.current = initialOffset // update offset ref
    setVisible(true)
  }, [])

  useEffect(() => {
    if (offsetRef.current < (containerWidth / 2) * -1) {
      // use offset ref
      setVisible(false)
      const newOffset = containerWidth / 2 + 500
      setOffset(newOffset)
      offsetRef.current = newOffset // update offset ref
      setTimeout(() => {
        setVisible(true)
      }, 100)
    }
  }, [offset, containerWidth])

  const maskUserName = (name) => {
    if (!name) return ''

    const length = name.length
    const firstPart = Math.ceil(length / 3)
    const maskedPart = length - firstPart

    return name.substring(0, firstPart) + '*'.repeat(maskedPart)
  }

  return (
    <div style={styles.screen}>
      <div style={styles.componentsContainer} id='container'>
        {userList.map((item, index) => (
          <div
            key={index}
            className='slide-left'
            style={{
              ...styles.component,
              // transform: `translateX(${offset}px)`,
              display: visible ? 'flex' : 'none',
            }}
          >
            <div
              style={{
                width: '6vw',
                height: '6vw',
                borderRadius: 99,
                backgroundImage: `url(${item.thumbnailUserImageUri})`,
                marginRight: 8,
              }}
            />
            <div>
              {maskUserName(item?.userName)}님이 오늘{' '}
              {PlantBasicInfo.PlantNameAndAmount(item?.itemType).koreanName}{' '}
              수확에 성공하셨어요!
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const styles = {
  screen: {
    // width: "100%",
    // height: "100vh",
    // backgroundColor: "#f2f2f2",
    overflow: 'hidden',
    marginTop: 4,
  },
  componentsContainer: {
    display: 'flex',
    position: 'relative',
    width: '1400vw',
    justifyContent: 'space-around',
    alignItems: 'center',
    // height: "12vw",
    // height: "100%",
  },
  component: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: "10vw",
    backgroundColor: '#000000aa',
    fontSize: '3.5vw',
    color: 'white',
    borderRadius: 99,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease',
    padding: 2,
    paddingRight: 8,
  },
}

export default MovingComponentsScreen
