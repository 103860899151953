import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../store/ModalStore'
import ToastStore from 'store/ToastStore'
import { shuffleArray } from 'utils/utils'

const GganbuRecommendationModal = observer(
  ({ token, farmData, setFarmData, showRewardToast, getGganbuList }) => {
    const [recommendations, setRecommendations] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const throttleRef = useRef({})

    useEffect(() => {
      // fetch recommendations
      const fetch = async () => {
        const result = await backendApis.getGganbuRecommendations(token)

        if (result?.data) {
          let temp =
            shuffleArray(result?.data?.filter((item) => !item?.deletedAt)) ?? []

          setRecommendations(temp)
          setIsLoading(false)
        }
      }
      fetch()

      // set to state
    }, [])

    const requestGganbu = async (data) => {
      const result = await backendApis.requestGganbu({
        gganbuId: data?._id,
        rewardType: 'water',
        rewardAmount: 0,
        requestType: 'recommendation',
        gganbuRequestList: farmData?.gganbuRequestList,
        gganbuList: farmData?.ggnabuList,
      })
      if (result?.data?.status === 200) {
        ToastStore.toastOn({
          type: 'success',
          message: '맞팜 신청이 완료되었어요!',
          duration: 2000,
        })
      } else {
        let message = '맞팜 신청에 실패했어요'
        if (result?.data?.status === 201) {
          message = '이미 서로 맞팜이에요'
        } else if (result?.data?.status === 202) {
          message = '나의 맞팜이 가득 찼어요'
        } else if (result?.data?.status === 203) {
          message = '상대방의 맞팜이 가득 찼어요'
        } else if (result?.data?.status === 204) {
          message = '상대가 이미 맞팜 신청을 했어요'
        } else if (result?.data?.status === 205) {
          message = '내가 이미 맞팜 신청을 했어요'
        } else if (result?.data?.status === 206) {
          message = '맞팜 신청은 최대 3일마다 보낼 수 있어요'
        }

        ToastStore.toastOn({
          type: 'error',
          message,
          duration: 2000,
        })
      }
    }

    const FriendComponent = ({ candidate }) => {
      const [buttonPressed, setButtonPressed] = useState(false)

      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 8,
            marginBottom: 16,
            // paddingLeft: "5vw",
            // paddingRight: "5vw",
          }}
        >
          <div
            style={{
              flex: 1,
              // backgroundColor: "green",
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <img
              style={{
                zIndex: 3,
                width: '12vw',
                height: '12vw',
                marginRight: 16,
                borderRadius: '100vw',
                // top: 0,
                // alignSelf: "flex-start",
              }}
              src={candidate?.thumbnailUserImageUri}
              alt=''
            />

            <div
              style={{
                color: 'black',
              }}
            >
              {candidate?.userName.length > 6
                ? candidate?.userName.slice(0, 7) + '...'
                : candidate?.userName}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                // color: "black",
                padding: '3vw 4vw 3vw 4vw',
                background: buttonPressed ? '#ABE066' : '#7ED321',
                boxShadow: buttonPressed
                  ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                  : '',

                color: '#fff',
                fontWeight: 'bold',
                borderRadius: '2vw',
                marginRight: '0vw',
                fontSize: '3.5vw',
              }}
              onPointerDown={() => {
                setButtonPressed(true)
              }}
              onPointerCancel={() => {
                setButtonPressed(false)
              }}
              onPointerUp={() => {
                setButtonPressed(false)
              }}
              onClick={() => {
                requestGganbu(candidate)
              }}
            >
              맞팜 신청
            </div>
          </div>
        </div>
      )
    }
    if (isLoading) {
      return <></>
    }

    return (
      <>
        <div style={styles.background}>
          <div style={styles.modalDiv}>
            <button
              style={styles.closeButton}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>

            <div
              style={{
                width: '100%',
                flex: 1,
                marginTop: '2vw',
                overflow: 'scroll',
                display: 'flex',
                justifyContent:
                  recommendations?.length === 0 ? 'center' : 'flex-start',
                alignItems:
                  recommendations?.length === 0 ? 'center' : 'flex-start',
                flexDirection: 'column',
              }}
            >
              {recommendations?.length > 0 && (
                <div
                  style={{
                    color: 'black',
                    marginBottom: 32,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    textAlign: 'center',
                    flexDirection: 'column',
                    fontSize: '5vw',
                  }}
                >
                  농부님을 아는 올팜 친구들이에요!
                  <br />
                  <b style={{ color: 'black' }}>맞팜을 맺어보세요! 🤭</b>
                </div>
              )}
              <div
                style={{
                  width: '100%',
                }}
              >
                {recommendations?.length > 0 &&
                  recommendations.map((candidate) => (
                    <FriendComponent
                      key={Math.random()}
                      candidate={candidate}
                    />
                  ))}
              </div>
              {recommendations?.length === 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '100%',
                    paddingTop: '20vw',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '20vw',
                    }}
                  >
                    😅
                    <div
                      style={{
                        color: 'black',
                        marginBottom: 8,
                        fontSize: '6vw',
                      }}
                    >
                      추천할 친구를 찾지 못했어요
                    </div>
                  </div>
                  <div
                    style={{
                      background:
                        'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                      width: '60vw',
                      height: '14vw',
                      borderRadius: 99,
                      color: 'black',
                      fontFamily: 'Maplestory',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '5.5vw',
                    }}
                    onClick={() => {
                      ModalStore.setIsModalOpen('basic')
                    }}
                  >
                    닫기
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default GganbuRecommendationModal

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalDiv: {
    width: '90vw',
    height: '150vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10vw',
    backgroundImage: 'url(../gganbuRecommendation/background.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '26vw',
    paddingBottom: '2.9vw',
  },
  closeButton: {
    width: '8vw',
    right: '2vw',
    top: '-10vw',
    position: 'absolute',
    zIndex: 999,
  },
  rewardFlag: {
    position: 'absolute',
    color: 'black',
    left: '10vw',
    borderBottom: '2px solid black',
    width: '14vw',
    marginTop: '1vw',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',

    // borderRadius: 16,
  },
  rewardFlagImg: {
    width: '7vw',
    height: '7vw',
  },
}
