import { observable } from 'mobx'

const MotionStore = observable({
  isPung: false,

  setIsPung(boolean) {
    this.isPung = boolean
  },
})

export default MotionStore
