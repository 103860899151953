import React, { useState } from 'react'

const Balloon = ({
  visible,
  onClick = () => {},
  styleOptions = {},
  contents = {},
}) => {
  const { customizedContainer, customizedContent } = styleOptions

  if (!visible) {
    return <></>
  } else {
    return (
      <div
        onClick={onClick}
        style={{
          ...styles.ballonContainer,
          ...customizedContainer,
          height: contents?.length > 17 ? '16%' : '12%',
          zIndex: 2,
        }}
        className={'bubble absolute left-1/2 -translate-x-1/2 px-[4vw]'}
      >
        <div
          style={{
            ...styles.ballonContent,
            ...customizedContent,
            whiteSpace: 'pre-line',
          }}
        >
          {contents}
        </div>
      </div>
    )
  }
}

export default function SpeechBubble({ contents = {} }) {
  const [balloonVisible, setBalloonVisible] = useState(true)

  const onCloseBalloon = () => {
    setBalloonVisible(false)
  }

  return (
    <Balloon
      visible={balloonVisible}
      styleOptions={{
        customizedContainer: {},
        customizedContent: {},
      }}
      contents={contents}
      onClick={onCloseBalloon}
    />
  )
}

const styles = {
  ballonContainer: {
    position: 'absolute',
    height: '30vw',
    fontSize: '3.4vw',
    zIndex: 50,
  },
  // position absolute 일 때 중앙 정렬 하는 법
  ballonContent: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1.1,
    color: 'black',
    fontSize: '1em',
    zIndex: 100,
    width: '90%',
  },
}
