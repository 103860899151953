import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import WhiteTextTimer from '../../../comps/WhiteTextTimer'
import dayjs from 'dayjs'

const SeasonOffModal = observer(
  ({ token, farmData, setFarmData, isLoading, setOpenSeasonOffModal }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <button
            style={{
              width: '10%',
              top: '15%',
              right: '10%',
              position: 'absolute',
              zIndex: 1113,
            }}
            onClick={() => {
              setOpenSeasonOffModal(false)
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div className='w-[80vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
            <img
              src='https://assets.ilevit.com/fe4ba9ea-e77f-4940-87fc-601738d80919.png'
              alt='abc'
              style={{ width: '80vw' }}
            />
            <div
              style={{
                background: isButtonPressed ? '#F7C82A' : '#F7C82A',
                boxShadow: isButtonPressed
                  ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                  : '',
                width: '60vw',
                height: '14vw',
                borderRadius: 99,
                color: 'black',
                fontFamily: 'maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '4.5vw',
                position: 'absolute',
                bottom: '8vw',
                left: '10vw',
              }}
              onClick={() => {
                window.location.href = `#alfarmDealScreen.${JSON.stringify({
                  dealType: 'alfarmFashion',
                  title: '올팜 시즌오프 특가',
                  image:
                    'https://assets.ilevit.com/84a451f0-28c0-494e-a598-8739e40a9e5e.png',
                  ratio: 99 / 360,
                })}`
                setOpenSeasonOffModal(false)
              }}
              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
              }}
            >
              상품 구매하고 혜택 받기
            </div>
          </div>
          {/* <div
              style={style.insideModalStyle}
             
            >
              <img
                src="https://assets.ilevit.com/ceb9ca7e-7bb3-4955-be7e-0615ea1f3fb6.png"
                alt="abc"
                style={{ width: "80vw" }}
              />
            </div> */}
        </div>
      </>
    )
  },
)

export default SeasonOffModal
