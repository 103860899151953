import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import ModalStore from '../../../store/ModalStore'
import DealAutomationStore from '../../../store/DealAutomationStore'
import TimeStore from '../../../store/TimeStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import backendApis from 'utils/backendApis'
import commaNumber from 'comma-number'

const BlackFridayForceModal = observer(
  ({ token, farmData, setFarmData, isLoading }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const [dealItems, setDealItems] = useState([])
    const throttleRef = useRef({})
    const [canShow, setCanShow] = useState(false)

    useEffect(() => {
      const dealItem = async () => {
        const params = {
          dealType: 'summerDeal24',
          loadNum: 0,
          displayVersion: { ignorePersonalization: true, isCoreItem: true },
        }

        const result = await backendApis.getBlackfridayDealItem(token, params)
        setDealItems(result?.data)
        if (result?.data?.length >= 0) {
          setCanShow(true)
        }
        if (result?.data?.length === 0) {
          ModalStore.setIsModalOpen('basic')
        }
      }

      dealItem()
    }, [])

    const sendToPurchaseScreen = async (questType) => {
      window.location.href = `#generalNavigator.${JSON.stringify({
        screen: 'WebviewScreen',
        params: {
          url: `https://alwayz-shop-front.prod.ilevit.com/deals/summer-deal-24/detail/core`,
          enteringComponent: 'Alfarm_blackFriday',
          enteringLocation: 'browsingQuest',
          isFrom: 'Alfarm_blackFriday',
          isTimerOn: questType === 'browsingQuest' ? true : false,
          timerText: '미션 완료까지 ',
          isTimerEndText: '축하합니다! 텐트 2개를 받았어요',
          timeStamp: new Date(new Date().getTime() + 1000 * 30),
          rewardText: '상품을 구매하면 고급비료 5, 텐트 35개를 드려요',
          questAmount: '2',
          rewardType: '텐트',
          timeMinute: 0.5,
        },
      })}`
    }

    if (dealItems?.length === 0) {
      return null
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div className='w-[90vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
            <button
              style={{
                width: '10%',
                top: '-8%',
                right: '8%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <img src='/bfmodal/modal.png' alt='abc' style={{ width: '90vw' }} />
            {canShow && dealItems?.length > 0 && (
              <div
                style={{
                  position: 'absolute',
                  height: '56vw',
                  display: 'flex',
                  alignItems: 'center',
                  width: '82vw',
                  overflowX: 'scroll',
                  marginLeft: '5vw',
                  bottom: '24vw',
                }}
              >
                {dealItems?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        backgroundColor: '#FFFFFF',
                        width: '35vw',
                        height: '55vw',
                        borderRadius: 15,
                        border: '1vw solid #747474',
                        textAlign: 'center',
                        marginRight: '2vw',
                        justifyContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      onClick={() => {
                        sendToPurchaseScreen('purchaseQuest')
                      }}
                    >
                      <div>
                        <img
                          src={item?.mainImageUris[0]}
                          alt='abc'
                          style={{
                            width: '30vw',
                            borderRadius: 10,
                            marginTop: '2vw',
                            marginBottom: '0vw',
                            marginLeft: '1.5vw',
                          }}
                        />
                        <div
                          style={{
                            fontSize: '3.5vw',
                            color: '#5D2108',
                            paddingRight: '1vw',
                            paddingLeft: '1vw',
                            lineHeight: '4vw',
                            marginTop: '2vw',
                          }}
                        >
                          {item?.dealItemTitle?.length > 24
                            ? item?.dealItemTitle?.substring(0, 24) + '..'
                            : item?.dealItemTitle}
                        </div>
                      </div>
                      <div
                        style={{
                          color: '#FB573B',
                          fontWeight: 'bold',
                          fontSize: '4vw',
                          marginBottom: '1vw',
                        }}
                      >
                        {commaNumber(item?.dealTeamPurchasePrice)}원
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            <div
              style={{
                background: '#FB573B',
                boxShadow: isButtonPressed
                  ? 'inset 1vw 1vw 2vw rgba(79, 19, 0, 0.15)'
                  : '',
                width: '60vw',
                height: '14vw',
                borderRadius: 99,
                color: '#FFFFFF',
                fontFamily: 'maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '4.5vw',
                position: 'absolute',
                bottom: '6vw',
                left: '15vw',
              }}
              onClick={() => {
                AlfarmEventLogger({
                  throttleRef,
                  locationType: 'modal',
                  locationName: ModalStore.isModalOpen,
                  eventType: 'click',
                  eventName: 'cta',
                  data: {},
                  collection: 'UserAlfarmClickLog',
                })
                sendToPurchaseScreen('purchaseQuest')

                ModalStore.setIsModalOpen('basic')
              }}
              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
              }}
            >
              여름 블프 입장하기
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default BlackFridayForceModal
