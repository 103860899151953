import { observable } from 'mobx'

const DecorationItemStore = observable({
  decorationItem: null,

  setDecorationItem(object) {
    this.decorationItem = object
  },
})

export default DecorationItemStore
