import React from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'
import UserStore from 'store/UserStore'
import { farmingList } from '../../config'
import PlantBasicInfo from './PlantBasicInfo'
import GaugeBar from 'comps/atoms/gaugeBar'
import GaugeBarPercentage from 'comps/atoms/gaugeBarPercentage'

const TeamAlfarmPlant = observer(
  ({
    myPlant = false,
    active = false,
    data = {},
    invite,
    animState,
    token,
    teamAlfarmData,
  }) => {
    let plantSrc = ''
    if (UserStore?.teamAlfarmData?.itemType) {
      plantSrc = `/farm/${
        farmingList[
          PlantBasicInfo.IdFromPlantName(
            UserStore?.teamAlfarmData?.itemType?.split('_')?.[0] ?? 'potato',
          )
        ]
      }/${Math.min(data?.level ?? 1, 6)}.gif`
    } else {
      plantSrc = '../teamAlfarm/emptyPlant.png'
    }

    const inviteable =
      teamAlfarmData?.members?.filter((item) => item)?.length === 0 ||
      teamAlfarmData?.myData?.leader

    if (!active) {
      return (
        <div className='relative flex-1 flex-col flex justify-center items-center'>
          <img
            className={`${myPlant ? 'w-[33vw]' : 'w-[24vw]'} z-[4]`}
            onClick={() => {
              ModalStore.setIsModalOpen('nextLevelReward')
            }}
            alt=''
            src={`../teamAlfarm/emptyPlant.png`}
          />
          {!myPlant && inviteable && (
            <div
              className='absolute top-0 z-[10]'
              onClick={() => {
                invite()
              }}
            >
              <img
                className='w-[30vw]'
                alt='inviteBaner'
                src='../teamAlfarm/inviteBanner.png'
              />
            </div>
          )}
          {myPlant && (
            <div
              className={`left-[50%] -translate-x-1/2 bg-[rgba(25,25,25,0.5)]  rounded-[2vw] absolute p-[1.1vw] z-[100] ${
                myPlant ? 'bottom-[8vw] w-[30vw]' : 'bottom-[4vw] w-[26vw]'
              }`}
            >
              <div
                className={`text-white text-center font-maplestory flex justify-center          
          ${myPlant ? 'text-[4.2vw]' : 'text-[3.5vw]'}
          `}
              >
                {data?.userName ?? '팀 모집중..'}
              </div>
              {data?.leader && (
                <div className='absolute rounded-full bg-[#FF5050] text-white font-bold text-[2.5vw] px-[3vw] py-[0.5vw] left-1/2 -translate-x-1/2 -top-[4vw]'>
                  팀장
                </div>
              )}
            </div>
          )}
        </div>
      )
    }

    return (
      <div className='relative flex-1 flex-col flex justify-center items-center'>
        <img
          className={`${myPlant ? 'w-[33vw]' : 'w-[24vw]'} z-[4]`}
          onClick={() => {
            ModalStore.setIsModalOpen('nextLevelReward')
          }}
          alt=''
          src={plantSrc}
        />
        {/* 게이지 */}
        {myPlant && (
          <div
            className={`absolute bottom-[-4vw] w-full flex justify-center items-center flex-col z-110`}
          >
            <GaugeBar
              id={PlantBasicInfo.IdFromPlantName(
                UserStore?.teamAlfarmData?.itemType?.split('_')?.[0],
              )}
              level={data?.level ?? 1}
              remainExp={data?.remainExp ?? 0}
              expToLevelUp={data?.expToLevelUp ?? 20}
              isGiveWater={animState === 'giveWaterBasket'}
              isGiveFert={false}
              isRain={false}
              token={token}
              giveWaterCount={3000}
              animState={animState}
              userId={UserStore?.farmData?.userId}
              style={{
                width: '20vw',
              }}
              gganbuMode={true}
            />
            <GaugeBarPercentage
              level={data?.level ?? 1}
              remainExp={data?.remainExp ?? 0}
              expToLevelUp={data?.expToLevelUp ?? 20}
              giveWaterCount={3000}
              farmData={UserStore?.farmData}
              style={{
                width: '20vw',
              }}
            />
          </div>
        )}
        {/* 이름 */}
        <div
          className={`left-[50%] -translate-x-1/2 bg-[rgba(25,25,25,0.5)]  rounded-[2vw] absolute p-[1.1vw] z-[100] ${
            myPlant ? 'bottom-[8vw] w-[30vw]' : 'bottom-[4vw] w-[26vw]'
          }`}
        >
          <div
            className={`text-white text-center font-maplestory flex justify-center          
        ${myPlant ? 'text-[4.2vw]' : 'text-[3.5vw]'}
        `}
          >
            {data?.userName ?? '이름없음'}
          </div>
          {data?.leader && (
            <div className='absolute rounded-full bg-[#FF5050] text-white font-bold text-[2.5vw] px-[3vw] py-[0.5vw] left-1/2 -translate-x-1/2 -top-[4vw]'>
              팀장
            </div>
          )}
        </div>
      </div>
    )
  },
)

export default TeamAlfarmPlant
