import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../store/ModalStore'
import { set } from 'lodash'

const NextLevelRewardModal = observer(({ level }) => {
  const [image, setImage] = useState('')

  useEffect(() => {
    if (level === 6) {
      setImage('/levelUpReward/lastLevel.png')
    } else {
      setImage(`/levelUpReward/NotYet${level}New.png`)
    }
  }, [image])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1999,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      />

      <div
        style={{
          position: 'relative',
          zIndex: 2000,
          transform: 'translate(5%, -50%)',
          width: '90vw',
          top: '-50%',
          left: '0%',
        }}
      >
        <img
          style={{
            position: 'relative',
            zIndex: 2000,
          }}
          src={image}
          alt=''
        />
        <button
          style={{
            position: 'absolute',
            zIndex: 2000,
            top: '-16vw',
            right: '4vw',
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            className='z-[101] w-[10vw]'
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      </div>
    </>
  )
})

export default NextLevelRewardModal
