import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from 'utils/backendApis'
import ModalStore from 'store/ModalStore'
import ToastStore from 'store/ToastStore'
import { shuffleArray } from 'utils/utils'

const GganbuSurprisePurchaseModal = observer(
  ({ token, farmData, gganbuId }) => {
    const [adItemInfo, setAdItemInfo] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const throttleRef = useRef({})

    useEffect(() => {
      const fetch = async () => {
        const result = await backendApis.getBrowsingAdItem(token)
        setAdItemInfo(result?.data)
        setIsLoading(false)
      }
      fetch()
    }, [])

    const sendToItem = () => {
      const adItemBrowsing = {
        enteringComponent: 'Alfarm_GganbuSurprisePurchase',

        text: '올팜',
        source:
          'https://assets.ilevit.com/2016b433-4280-47e2-a103-1acccd9e1966.png',
        isTimerOn: true,
        timerText: `초간 구경하면 친구와 같이 물 ${10}g씩 받아요`,
        isTimerEndText: '축하합니다! 물 10g을 받았어요',
        timeStamp: new Date(new Date().getTime() + 1000 * 30),
        rewardText: '상품을 구매하면 물 500g을 받아요',

        itemId: adItemInfo?._id,
        isAdItem: adItemInfo?.adInfo?.isAdItem,
        itemInfo: adItemInfo,
        payload: {
          gganbuId,
          rewardAmount: 10,
          rewardType: 'water',
          loggingRewardName: 'gganbuSurprisePurchaseBrowsing',
        },
      }
      ModalStore.setIsModalOpen('basic')
      window.location.href = `#adItemBrowsing.${JSON.stringify(adItemBrowsing)}`
    }

    if (isLoading) {
      return <></>
    }

    return (
      <>
        <div style={styles.background}>
          <div style={styles.modalDiv}>
            <button
              style={styles.closeButton}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={
                {
                  // border: "1px solid black",
                }
              }
            >
              <div
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  marginBottom: 16,
                  width: '120%',
                }}
              >
                상품을 구경하면 친구도 나도 물을 받아요!
              </div>
              <div
                style={{
                  overflow: 'hidden',
                  borderRadius: 16,
                  marginBottom: 8,
                }}
              >
                <img src={adItemInfo?.mainImageUris?.[0]} alt='' />
              </div>
              <div
                style={{
                  color: 'black',
                  textAlign: 'center',
                }}
              >
                {adItemInfo?.itemTitle?.length > 60
                  ? adItemInfo?.itemTitle.slice(0, 61) + '...'
                  : adItemInfo?.itemTitle}
              </div>
            </div>
            <div
              style={{
                background:
                  'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                width: '60vw',
                height: '14vw',
                borderRadius: 99,
                color: 'black',
                fontFamily: 'Maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '5vw',
                marginBottom: '4vw',
              }}
              // className="scaleUp"
              onClick={() => {
                sendToItem()
              }}
            >
              구경하고 같이 물받기
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default GganbuSurprisePurchaseModal

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalDiv: {
    width: '90vw',
    height: '150vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10vw',
    backgroundImage: 'url(../gganbuSurprisePurchase/background.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '28vw',
    paddingBottom: '2.9vw',
  },
  closeButton: {
    width: '8vw',
    right: '2vw',
    top: '-10vw',
    position: 'absolute',
    zIndex: 999,
  },
  rewardFlag: {
    position: 'absolute',
    color: 'black',
    left: '10vw',
    borderBottom: '2px solid black',
    width: '14vw',
    marginTop: '1vw',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',

    // borderRadius: 16,
  },
  rewardFlagImg: {
    width: '7vw',
    height: '7vw',
  },
}
