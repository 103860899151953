import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const AllBrandDealModal = observer(({ token, farmData }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const throttleRef = useRef({})

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '80vw',
            height: '180vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundImage: 'url(../allBrandDeal/modal.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <img
            style={{
              zIndex: 101,
              position: 'absolute',
              width: '60%',
              marginTop: '62%',
              marginLeft: '30%',
            }}
            src='/allBrandDeal/bubble.png'
            alt=''
          />
          <div
            style={{
              color: 'white',
              fontSize: '6vw',
              fontFamily: 'maplestory',
              textAlign: 'center',
              background: isButtonPressed
                ? 'linear-gradient(180deg, #02D658 0%, #01BA4B 100%)'
                : 'linear-gradient(180deg, #02D658 0%, #01BA4B 100%)',
              boxShadow: isButtonPressed
                ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                : '',
              padding: '4vw',
              borderRadius: '50vw',
              width: '65vw',
              marginTop: '84%',
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
              AlfarmEventLogger({
                throttleRef,
                locationType: 'modal',
                locationName: ModalStore.isModalOpen,
                eventType: 'click',
                eventName: 'cta',
                data: {},
                collection: 'UserAlfarmClickLog',
              })

              const purchaseData = {
                text: '올팜 브랜드 모음',
                enteringComponent: 'Alfarm_allBrandDealPurchase',
                source: farmData?.allBrandDealInfo?.browsing
                  ? 'https://assets.ilevit.com/608ce48f-b087-42aa-9009-43a4f593b177.png'
                  : 'https://assets.ilevit.com/54a166a7-577c-4048-b177-8370e92d4090.png',
                timeStamp: new Date(new Date().getTime() + 1000 * 30),
                isTimerOn: farmData?.allBrandDealInfo?.browsing ? false : true,
                timerText: '일반비료 추가까지 ',
                isTimerEndText: '이제 상품을 사면 일반비료 2개를 더 받아요!',
                rewardText: `상품을 구매하면 물 1,000g, 일반비료 2개를 받아요!`,
              }
              window.location.href = `#AllBrandDealScreen.${JSON.stringify(
                purchaseData,
              )}`
              ModalStore.setIsModalOpen('basic')
            }}
          >
            구경하고 구매하기
          </div>
          <button
            style={{
              width: '10%',
              top: '30vw',
              right: '5%',
              position: 'absolute',
              zIndex: 1113,
              background: 'black',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
        </div>
      </div>
    </>
  )
})
export default AllBrandDealModal
