import { observable } from 'mobx'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const ModalStore = observable({
  isModalOpen: 'basic',
  isAutoAppearModal: false,
  isEngagementWaterModal: false,
  isTomorrowEngagingWaterModal: false,
  isLayeredModalOpen: false,
  modalQueue: [],
  config: {},

  rewardPopUpOn: false,
  rewardPopUpType: '',
  rewardPopUpAmount: 0,

  pushQueue(type) {
    this.modalQueue.push(type)
  },
  removeQueue(type) {
    this.modalQueue.shift(type)
  },

  set(key, value) {
    function setProperty(obj, keys, val) {
      let key
      while (keys.length > 1) {
        key = keys.shift()
        if (!Object.prototype.hasOwnProperty.call(obj, key)) obj[key] = {}
        // if (!obj.hasOwnProperty(key)) obj[key] = {}
        obj = obj[key]
      }
      obj[keys[0]] = val
    }

    if (key?.includes('.')) {
      const keys = key.split('.')
      setProperty(this, keys, value)
    } else {
      this[key] = value
    }
  },

  setIsModalOpen(type, config = {}) {
    if (type !== 'basic') {
      this.modalQueue.push(type)

      AlfarmEventLogger({
        locationType: 'modal',
        locationName: type,
        eventType: 'pageview',
        data: {},
        collection: 'UserAlfarmPageviewLog',
      })
    }
    let curr = this.modalQueue.shift()
    this.isModalOpen = curr ?? 'basic'
    this.config = config
  },

  setIsAutoAppearModal(boolean) {
    this.isAutoAppearModal = boolean
  },

  setIsEngagementWaterModal(boolean) {
    this.isEngagementWaterModal = boolean
  },
  setIsTomorrowEngagingWaterModal(boolean) {
    this.isEngagementWaterModal = boolean
  },

  setIsLayeredModalOpen(boolean) {
    this.isLayeredModalOpen = boolean
  },

  setRewardPopUpOn({ bool, type, amount }) {
    this.rewardPopUpOn = bool
    this.rewardPopUpType = type
    this.rewardPopUpAmount = amount
  },
})

export default ModalStore
