import React from 'react';

const PostInfo = ({
  post,
  showFullDescription,
  onToggleDescription,
}) => {
  const descriptionThreshold = 48;
  const shouldShortenDescription =
    post?.description?.length > descriptionThreshold;
  const displayedDescription =
    shouldShortenDescription && !showFullDescription
      ? `${post?.description?.substring(0, descriptionThreshold)}...`
      : post?.description;

  return (
    <div className='p-[2vw] z-30'>
      <div className='flex flex-row font-bold py-[2vw] justify-start items-center'>
        <div className='text-[3.8vw]'>{post?.sellerInfo?.sellerDisplayName}</div>
      </div>
      <div className='w-[76vw] text-[3.6vw] leading-[4.4vw]'>
        {displayedDescription}
      </div>
      {shouldShortenDescription && (
        <button
          type='button'
          onClick={onToggleDescription}
          className=' mt-[2vw] p-1'
        >
          <div className='text-[3vw] text-white'>
            {showFullDescription ? '간략히 보기111' : '자세히 보기'}
          </div>
        </button>
      )}
    </div>
  );
};

export default PostInfo
