import BottomSheetStore from '../../store/BottomSheetStore'
import React from 'react'

const LackWaterEngaingModal = ({ setShowMinimumWaterModal }) => {
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: '50%',
          top: '55%',

          zIndex: 999,
        }}
      >
        <div
          style={{
            background: '#FFFFFF',
            boxShadow: 'inset 0px 0px 12px -3px rgba(0, 0, 0, 0.35)',
            borderRadius: '16px',
            position: 'absolute',
            overlay: {
              background: 'rgba(0,0,0,0)',
              zIndex: 101,
            },
            width: '85vw',
            height: '35vw',

            // opacity: "0.8",
            transform: 'translate(-50%, -50%)',
            fontFamily: 'maplestory',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            fontSize: '18px',
            padding: 22,
          }}
        >
          <div
            style={{
              marginTop: 6,
              color: 'rgba(64, 44, 36, 1)',
            }}
          >
            물은 10g이상 부터 줄 수 있어요
          </div>
          <div style={{ height: '18px' }} />
          <button
            style={{
              background: '#EA3323',
              boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
              borderRadius: '20px',
              fontFamily: 'maplestory',
              fontSize: '18px',
              padding: 12,
              width: '95%',
              // marginBottom: 6,
            }}
            onClick={() => {
              setShowMinimumWaterModal(false)
              BottomSheetStore.setIsbottomSheetOpen('water')
            }}
          >
            퀘스트하고 물 받기
          </button>
        </div>
      </div>
    </>
  )
}
export default LackWaterEngaingModal
