import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../store/ModalStore'
import backendApis from 'utils/backendApis'
import ToastStore from 'store/ToastStore'

const TimeCapsuleBonus = observer(({ timeCapsuleReceived = false, token }) => {
  return (
    <>
      <div style={styles.background}>
        <div style={styles.mainDiv}>
          <button
            style={styles.closeButton}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          {timeCapsuleReceived ? (
            <div style={styles.content}>
              <div style={{ ...styles.title, fontWeight: 'bold' }}>
                타임캡슐 보상 획득!
              </div>

              <div
                style={{
                  width: '35vw',
                  height: '35vw',
                  backgroundImage: 'url(../timeCapsuleBonus/openedCapsule.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
              <div style={{ ...styles.normalText, marginBottom: 0 }}>
                축하해요
              </div>
              <div style={{ ...styles.normalText, marginTop: 0 }}>
                물 100그램을 얻었어요
              </div>
              <div
                style={styles.CTA}
                className='scaleUp'
                onClick={() => {
                  ModalStore.setIsModalOpen('basic')
                }}
              >
                타임캡슐 보상 받기
              </div>
            </div>
          ) : (
            <div style={styles.content}>
              <div style={styles.title}>팜린이 전용 타임캡슐 획득!</div>

              <div
                style={{
                  width: '30vw',
                  height: '30vw',
                  backgroundImage: 'url(../timeCapsuleBonus/closedCapsule.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
              <div style={styles.normalText}>
                타임캡슐 보상은 내일 접속하면 <br /> 자동으로 받아져요
              </div>

              <div
                style={styles.CTA}
                className='scaleUp'
                onClick={() => {
                  backendApis.setUserAlfarmNotificationSettingToTrue()
                  ModalStore.setIsModalOpen('basic')
                  ToastStore.toastOn({
                    type: 'success',
                    message: '내일 물 100g를 예약했어요!',
                    duration: 3000,
                  })
                }}
              >
                내일 물 100g 예약하기
              </div>
              <div style={styles.captionText}>
                *내일 접속하지 않으면 보상이 사라져요
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
})
export default TimeCapsuleBonus

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  mainDiv: {
    width: '90vw',
    height: '150vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -55%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 16,
    backgroundImage: 'url(../timeCapsuleBonus/background.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '42vw',
    paddingBottom: '28vw',
  },
  closeButton: {
    width: '10vw',
    right: 0,
    top: '10vw',
    position: 'absolute',
    zIndex: 999,
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: 'black',
    fontFamily: 'maplestory',
    fontSize: '5vw',
    textAlign: 'center',
    // lineHeight: "160%",
    marginTop: '6vw',
    fontWeight: 'bold',
    marginBottom: 8,
  },
  normalText: {
    color: 'black',
    // marginLeft: "1vw",
    marginBottom: 8,
    marginTop: 8,
    textAlign: 'center',
  },
  captionText: {
    color: 'black',
    textAlign: 'center',
    marginTop: 4,
    fontSize: '0.8rem',
  },
  CTA: {
    background:
      'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

    width: '60vw',
    height: '14vw',
    borderRadius: 99,
    color: 'black',
    fontFamily: 'Maplestory',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    marginBottom: '2vw',
  },
}
