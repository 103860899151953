const version = '1.0.1'

const V = (filePath) => {
  return `${filePath}?v=${version}`
}

const Info = {
  info2: V('/icon/info2.png'),
}

const fertButton = {
  fertIcon: V('/icon/fertIcon.png'),
  fertSetIcon: V('/icon/fertSetIcon.png'),
}

const Assets = {
  Info,
  fertButton,
}

export default Assets
