import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const TeamAlfarmExplainModal = observer(({ setTutorialOn }) => {
  const [index, setIndex] = useState(0)

  return (
    <div className='flex-1 fixed left-0 top-0 z-[1900] w-full h-[100vh] bg-[rgba(0,0,0,0.8)]'>
      <div
        className={`w-[90vw] h-[115.87vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col`}
        style={{
          backgroundImage: `url(../teamAlfarm/explain/${index}.png)`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          // border: "2px solid black",
        }}
      >
        <button
          className='absolute w-[8vw] right-0 top-[-12vw] z-[1999]'
          onClick={() => {
            setTutorialOn(false)
          }}
        >
          <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
        </button>
        <div
          className='absolute w-[80vw] h-[14vw] bottom-[4vw] '
          onClick={() => {
            if (index === 3) {
              setTutorialOn(false)
            }
            setIndex((prev) => prev + 1)
          }}
        />
      </div>
    </div>
  )
})
export default TeamAlfarmExplainModal
