import backendApis from 'utils/backendApis'
import AuthStore from 'store/AuthStore'
import dayjs from 'dayjs'

const CheckSeasonalEventStatus = async () => {
  let seasonalEventData = await backendApis.getSeasonalEventData(
    AuthStore.token,
  )
  if (!seasonalEventData?.data) {
    const res = await backendApis.initSeasonalEventData(AuthStore.token)
    seasonalEventData = await backendApis.getSeasonalEventData(AuthStore.token)
  }
  if (seasonalEventData?.data?.isEnded) {
    return 'finished'
  }
  return 'ongoing'
}

const CheckGganbuRouletteAvailable = async (gganbuId) => {
  let seasonalEventData = await backendApis.getSeasonalEventData(
    AuthStore.token,
  )
  if (!seasonalEventData?.data) {
    const res = await backendApis.initSeasonalEventData(AuthStore.token)
    seasonalEventData = await backendApis.getSeasonalEventData(AuthStore.token)
  }
  const currentData = dayjs()

  const alreadyReceivedPiece = seasonalEventData?.data?.rewardHistory?.find(
    (each) =>
      each?.userId?.toString() === gganbuId?.toString() &&
      dayjs(each?.receivedAt).isSame(currentData, 'day'),
  )
  const alreadyReceivedNonPiece =
    seasonalEventData?.data?.rewardHistoryNonPiece?.find(
      (each) =>
        each?.userId?.toString() === gganbuId?.toString() &&
        dayjs(each?.receivedAt).isSame(currentData, 'day'),
    )
  if (alreadyReceivedPiece || alreadyReceivedNonPiece) {
    return 'alreadyReceived'
  }
  return 'available'
}
export { CheckSeasonalEventStatus, CheckGganbuRouletteAvailable }
