import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import dayjs from 'dayjs'
import ModalStore from '../../../store/ModalStore'

const QuotesModal = observer(({ token, isFrom, quotesIndex }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [todaysQuotes, setTodaysQuotes] = useState({})
  const [indexNumber, setIndexNumber] = useState(0)

  const getQuotes = async () => {
    const randomNumber = Math.random()
    if (localStorage.getItem('todaysQuotesNumber')) {
      if (localStorage.getItem('todaysQuotesDate') !== dayjs().format('DD')) {
        window.localStorage.setItem(
          'todaysQuotesNumber',
          Math.floor(randomNumber * 300 - 1) + 1,
        )
        window.localStorage.setItem('todaysQuotesDate', dayjs().format('DD'))
      }
    } else {
      window.localStorage.setItem(
        'todaysQuotesNumber',
        Math.floor(randomNumber * 300 - 1) + 1,
      )
      window.localStorage.setItem('todaysQuotesDate', dayjs().format('DD'))
    }
    const number = localStorage.getItem('todaysQuotesNumber')
    const result = await backendApis.getQuotes(token, 'GET', {
      number: number,
    })
    if (result?.status === 200) {
      setTodaysQuotes(result?.data?.[0])
    }
    setIndexNumber(localStorage.getItem('todaysQuotesNumber'))
  }

  const shareKakao = () => {
    const inviteData = {
      shareType: 'AlfarmTodaysQuotes',
      title: `오늘의 명언을 확인해보세요!`,
      description: `지금 바로 ${todaysQuotes?.speaker}의 명언 보기`,
      bannerImage:
        'https://assets.ilevit.com/291d468e-c35a-4433-9ffb-ab6f9669fd85.png',
      indexNumber: indexNumber,
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }

  const invitedQuotes = async () => {
    const result = await backendApis.getQuotes(token, 'GET', {
      number: quotesIndex,
    })
    if (result?.status === 200) {
      setTodaysQuotes(result?.data?.[0])
    }
  }
  useEffect(() => {
    if (isFrom === undefined) {
      getQuotes()
    } else if (isFrom === 'invite') {
      invitedQuotes()
    }
  }, [])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <button
          style={{
            width: '8%',
            transform: 'translate(-50%, -50%)',
            right: '10%',
            top: '22%',
            position: 'absolute',
            zIndex: 1113,
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
        <div
          style={{
            width: '90%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img src='/quotes/modal.png' alt='abc' style={{ width: '80vw' }} />
          <div
            style={{
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '55%',
              position: 'absolute',
              textAlign: 'center',
              color: '#6D442E',
              width: '80%',
              fontSize: '5vw',
              lineHeight: '150%',
              overflowWrap: 'break-word',
              wordBreak: 'keep-all',
            }}
          >
            {todaysQuotes?.description}
          </div>
          <div
            style={{
              transform: 'translate(-50%, -50%)',
              left: '50%',
              bottom: '16%',
              position: 'absolute',
              textAlign: 'center',
              color: '#6D442E',
              width: '80%',
              fontSize: '5vw',
            }}
          >
            - {todaysQuotes?.speaker} -
          </div>
          <div
            style={{
              background: isButtonPressed ? '#F7C82A' : '#F7C82A',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              width: '60vw',
              height: '14vw',
              borderRadius: 99,
              color: 'black',
              fontFamily: 'maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '4.5vw',
              position: 'absolute',
              bottom: '6vw',
            }}
            onClick={() => {
              shareKakao()
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
          >
            친구와 명언 공유하기
          </div>
        </div>
      </div>
    </>
  )
})

export default QuotesModal
