import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import dayjs from 'dayjs'
import ModalStore from '../../store/ModalStore'
import TimeStore from 'store/TimeStore'
import { toJS } from 'mobx'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import UserStore from 'store/UserStore'
import ToastStore from 'store/ToastStore'

const ResurrectD1NudgeModal = observer(({ token, farmData, setFarmData }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  const throttleRef = useRef({})

  const receiveReward = async ({ type }) => {
    let rewardInfo = {}

    if (type === 'today') {
      rewardInfo = { type: 'water', amount: 10 }
      backendApis.giveReward(token, 'PUT', {
        loggingRewardName: 'resurrectD1Nudge',
        rewardType: rewardInfo?.type,
        valueAmount: rewardInfo?.amount,
      })
      setFarmData((prev) => {
        let tempWater = prev?.water
        if (UserStore.waterExceeded) {
          tempWater += 5
        } else {
          tempWater += 10
        }
        return {
          ...prev,
          water: tempWater,
          resurrectBonusD1NudgeInfo: {
            type,
            receivedRewardAt: TimeStore?.currentDayJS?.format('YYYY-MM-DD'),
            rewardInfo,
          },
        }
      })

      ModalStore.setRewardPopUpOn({
        bool: true,
        type: rewardInfo?.type,
        amount: rewardInfo?.amount,
      })
    } else {
      rewardInfo = { type: 'water', amount: 600 }
      ToastStore?.toastOn({
        type: 'emoji',
        message: '600g이 예약되었어요',
        emoji: '💧',
        duration: 3000,
      })
      setFarmData((prev) => {
        return {
          ...prev,
          resurrectBonusD1NudgeInfo: {
            type,
            receivedRewardAt: TimeStore?.currentDayJS?.format('YYYY-MM-DD'),
            rewardInfo,
          },
        }
      })
    }

    // await backendApis.updateSpecificField({
    //   fieldName: 'resurrectBonusD1NudgeInfo',
    //   value: {
    //     type,
    //     receivedRewardAt: TimeStore?.currentDayJS.format('YYYY-MM-DD'),
    //     rewardInfo,
    //   },
    // })
    backendApis.updateResurrectBonusD1NudgeInfo({ type, rewardInfo })
  }

  const CTA = ({ type }) => {
    let buttonStyle = {
      background: isButtonPressed
        ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
        : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
      boxShadow: isButtonPressed
        ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
        : '',
    }

    if (type === 'today') {
      buttonStyle = {
        background: 'rgba(0,0,0,0',
        border: '2px solid #F7D046',
        paddingTop: '2vw',
        paddingBottom: '2vw',
        fontSize: '4vw',
      }
    }
    return (
      <button
        className='text-[4.5vw] font-bold rounded-3xl py-[4vw] z-[2000] w-[70%] top-[120vw] left-[12.5vw] text-[#442B22] mt-[2vw]'
        style={buttonStyle}
        onPointerDown={() => {
          if (type === 'tomorrow') {
            setIsButtonPressed(true)
          }
        }}
        onPointerCancel={() => {
          if (type === 'tomorrow') {
            setIsButtonPressed(false)
          }
        }}
        onPointerUp={() => {
          if (type === 'tomorrow') {
            setIsButtonPressed(false)
          }
        }}
        onClick={() => {
          receiveReward({ type })
          ModalStore.setIsModalOpen('basic')
        }}
      >
        {type === 'today' ? '지금 물 10g 받기' : '내일 물 600g 받기'}
      </button>
    )
  }

  return (
    <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
      <div
        className='w-[90vw] h-[150vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex absolute justify-end items-center flex-col px-[16px] pb-[12vw]'
        style={{
          backgroundImage: `url(../resurrectBonus/d1NudgeBackground.png)`,
        }}
      >
        {farmData?.resurrectBonusD1NudgeInfo?.receivedRewardAt ===
        TimeStore?.currentDayJS.format('YYYY-MM-DD') ? (
          <>
            <div className='text-[#442b22] font-bold text-[4vw] mb-[12vw] rounded-full '>
              {`${
                farmData?.resurrectBonusD1NudgeInfo?.type === 'today'
                  ? '내일 추가보상을 또 골라보세요!'
                  : '내일 600g을 받고 또 보상을 골라보세요!'
              }`}
            </div>
          </>
        ) : (
          <>
            <CTA type={'tomorrow'} />
            <CTA type={'today'} />
          </>
        )}
        <button
          className=' z-[1101] w-[8%] -top-[10vw] right-[4vw] absolute'
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
        </button>
      </div>
    </div>
  )
})

export default ResurrectD1NudgeModal
