import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'

const PushBullets = {
  getWaterThirtyGram: '우물 물 받기',
  getWaterResource: '물 받기',
  getFertilizerResource: '비료 받기',
  // gganbuInfo: "맞팜 친구 정보 받기",
  // attendance: "출석체크 정보 받기",
}

const PushBulletsImage = {
  getWaterThirtyGram: '/icon/BasicWaterIconMini.png',
  getWaterResource: '/icon/WaterIconMini.png',
  getFertilizerResource: '/icon/FertilizerIconMini.png',
}

const PushSettingModal = observer(
  ({
    setShowPushSettingModal,
    farmData,
    setFarmData,
    token,
    setShowPopup,
    setResourceType,
  }) => {
    const [userPushSettingStatus, setUserPushSettingStatus] = useState([])
    const pushList = Object.keys(PushBullets)

    useEffect(() => {
      loadPushListCommonData()
      if (!farmData.pushSettingModalOn) {
        logUpdatedAt()
      }
    }, [])

    const logUpdatedAt = async () => {
      await backendApis.updatelastUpdatedAt(token, 'PUT', {
        updateType: `pushSettingModalOn`,
      })
      setFarmData({
        ...farmData,
        pushSettingModalOn: new Date(),
      })
    }

    const loadPushListCommonData = async () => {
      let tempData = {}

      pushList.forEach((push) => {
        let settingStatus = true
        if (!farmData?.pushSettings) {
          settingStatus = true
        } else {
          if (farmData.pushSettings[push]) {
            settingStatus = true
          } else {
            settingStatus = false
          }
        }
        tempData = {
          ...tempData,
          [`${push}`]: settingStatus,
        }
      })
      setUserPushSettingStatus(tempData)
    }

    const logPushNotificationSetting = async () => {
      const result = await backendApis.updateUserAlfarmPushSetting(
        token,
        'PUT',
        {
          newPushSetting: userPushSettingStatus,
        },
      )
      if (result.status === 200) {
        setFarmData({
          ...farmData,
          pushSettings: userPushSettingStatus,
        })
        setShowPushSettingModal(false)
        setResourceType('pushSetting')
        setShowPopup(true)
        setTimeout(() => {
          setShowPopup(false)
        }, 2000)
      } else {
        // alert("오류가 발생했어요. 잠시 후 다시 시도해주세요.");
      }
    }

    const Title = ({ text = [''], color = 'black', fontSize = '4vw' }) => {
      return (
        <div
          style={{
            width: '100%',
            paddingTop: '1vw',
          }}
        >
          {text.map((i, index) => {
            return (
              <div
                style={{
                  color,
                  fontSize,
                  fontFamily: 'maplestory',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingBottom: '1vw',
                }}
                key={i.toString() + index.toString()}
              >
                {i} <br></br>
              </div>
            )
          })}
        </div>
      )
    }

    const Items = () => {
      return (
        <div
          style={{
            overflowY: 'visible',
            overflowX: 'hidden',
            flexDirection: 'column',
            paddingTop: '4vw',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
          className={'masked-overflow'}
        >
          {pushList.map((push, index) => {
            return (
              <div
                key={push.toString() + index.toString()}
                style={{
                  fontFamily: 'maplestory',
                  color: 'black',
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '10vw',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: '#faf8eb',
                  padding: '12px 8px 12px 8px',
                  borderRadius: '2vw',
                  marginBottom: '2vw',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '60%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div
                    style={{ height: '9vw', width: '9vw', marginRight: '3vw' }}
                  >
                    <img src={PushBulletsImage[push]} alt='' />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontFamily: 'maplestory',
                      color: 'black',
                      fontSize: '3.4vw',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {PushBullets[push]}
                  </div>
                </div>
                <button
                  onClick={() => {
                    const currentStatus = !!userPushSettingStatus[push]
                    setUserPushSettingStatus({
                      ...userPushSettingStatus,
                      [`${push}`]: currentStatus ? false : true,
                    })
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '10vw',
                    height: '6vw',
                    padding: '2px 5px 2px 5px',
                    borderRadius: '4vw',
                    backgroundColor: !!userPushSettingStatus[push]
                      ? '#78d11f'
                      : 'lightgray',
                    fontFamily: 'maplestory',
                    color: 'black',
                    justifyContent: !!userPushSettingStatus[push]
                      ? 'flex-end'
                      : 'flex-start',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'white',
                      width: '60%',
                      height: '88%',
                      borderRadius: '3vw',
                    }}
                  ></div>
                </button>
              </div>
            )
          })}
        </div>
      )
    }

    const Button = ({ text = '' }) => {
      return (
        <button
          onClick={() => {
            logPushNotificationSetting()
          }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffd147',
            borderRadius: '4vw',
            height: '20vw',
            width: '100%',
            fontSize: '4vw',
            fontFamily: 'maplestory',
            color: 'black',
            marginTop: '2vw',
          }}
        >
          {text}
        </button>
      )
    }

    const ModalBody = () => {
      if (!pushList || pushList?.length === 0) {
        return null
      }
      return (
        <div
          style={{
            position: 'relative',
            paddingBottom: '4vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 100,
            paddingLeft: '5%',
            paddingRight: '5%',
            width: '80%',
            height: '90%',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              marginTop: '6vw',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            <Title
              text={[
                '푸시 알림 수신에 동의하면',
                '물, 비료를 받을 수 있게 되었을 때',
                '푸시를 통해 알려드려요!',
              ]}
            />
            <Items />
            <Button text={'설정 저장하기'}></Button>
          </div>
        </div>
      )
    }

    const ModalFrame = () => {
      if (pushList.length === 0 || userPushSettingStatus.length === 0) {
        return null
      }
      return (
        <>
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 900,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }}
          >
            <div
              style={{
                width: '100vw',
                height: '100vw',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -55%)',
                display: 'flex',
                position: 'absolute',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column',
                zIndex: 901,
                borderRadius: '4vw',
                padding: 16,
                paddingTop: '6vw',
                backgroundImage: 'url(../icon/pushSettingModal.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              <button
                style={{
                  width: '8%',
                  top: '-12vw',
                  right: '10vw',
                  position: 'absolute',
                  cursor: 'pointer',
                  zIndex: 999,
                }}
                onClick={() => {
                  logPushNotificationSetting()
                  setShowPushSettingModal(false)
                }}
              >
                <img
                  style={{ zIndex: 101 }}
                  src='/icon/modalCloseButton.png'
                  alt=''
                />
              </button>
              <ModalBody />
            </div>
          </div>
        </>
      )
    }

    return <ModalFrame />
  },
)

export default PushSettingModal
