import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

const TomorrowGetWaterButton = observer(
  ({ farmData, onClick, level1, level2, level3, level4 }) => {
    const ObjectAmount = () => {
      if (farmData?.dailyGiveWater < level1) {
        return (
          <div
            style={{
              marginLeft: '7vw',
            }}
          >
            <div
              style={{
                color: '#EC6E54',
                marginTop: '1vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level1}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                채우면
              </div>
            </div>
            <div
              style={{
                color: '#EC6E54',
                marginTop: '0.5vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level1 + 30}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                받아요
              </div>
            </div>
          </div>
        )
      }
      if (farmData?.dailyGiveWater < level2) {
        return (
          <div
            style={{
              marginLeft: '7vw',
            }}
          >
            <div
              style={{
                color: '#EC6E54',
                marginTop: '1vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level2}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                채우면
              </div>
            </div>
            <div
              style={{
                color: '#EC6E54',
                marginTop: '0.5vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level2 + 30}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                받아요
              </div>
            </div>
          </div>
        )
      }
      if (farmData?.dailyGiveWater < level3) {
        return (
          <div
            style={{
              marginLeft: '7vw',
            }}
          >
            <div
              style={{
                color: '#EC6E54',
                marginTop: '1vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level3}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                채우면
              </div>
            </div>
            <div
              style={{
                color: '#EC6E54',
                marginTop: '0.5vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level3 + 40}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                받아요
              </div>
            </div>
          </div>
        )
      }
      if (farmData?.dailyGiveWater < level4) {
        return (
          <div
            style={{
              marginLeft: '7vw',
            }}
          >
            <div
              style={{
                color: '#EC6E54',
                marginTop: '1vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level4}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                채우면
              </div>
            </div>
            <div
              style={{
                color: '#EC6E54',
                marginTop: '0.5vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level4 + 50}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                받아요
              </div>
            </div>
          </div>
        )
      }
    }
    return (
      <>
        <button
          style={{
            position: 'relative',
          }}
          onClick={() => {
            onClick()
          }}
        >
          <div
            style={
              farmData?.dailyGiveWater < level4
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '2vw',
                    position: 'relative',
                    marginLeft: '2vw',
                    marginRight: '0.5vw',
                    width: '16vw',
                    height: '16vw',
                  }
                : {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '2vw',
                    position: 'absolute',
                    marginLeft: '2vw',
                    marginRight: '0.5vw',
                    width: '16vw',
                    height: '16vw',
                    // right: 0,
                  }
            }
          >
            <img
              style={{
                position: 'absolute',
              }}
              src='/tomorrowGetWater/bottle.png'
              alt=''
            />
            <div
              style={{
                fontSize: '4.5vw',
                fontWeight: 'bold',
                zIndex: 3,
                textShadow:
                  '-1.8px 0 #3983C0, 0 1.8px #3983C0, 1.8px 0 #3983C0, 0 -1.8px #3983C0',
                position: 'absolute',
              }}
            >
              {farmData?.dailyGiveWater}g{/* {number} */}
            </div>
            <div
              style={{
                zIndex: -1,
                width: '85%',
                height: '85%',
                marginTop: '12.5%',
                borderRadius: '30vw',
                overflow: 'hidden',
              }}
              className='wave-bottle'
            />
          </div>

          {farmData?.dailyGiveWater < level4 && (
            <div
              style={{
                position: 'absolute',
                right: '-18vw',
                top: '3.5vw',
                zIndex: -2,
                width: '26vw',
                background: '#FDFDF9',
                border: '2px solid #FFFFFF',
                borderRadius: '2vw',
                fontSize: '3vw',
                paddingBottom: '1vw',
              }}
            >
              {ObjectAmount()}
            </div>
          )}
        </button>
      </>
    )
  },
)

export default TomorrowGetWaterButton
