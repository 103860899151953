export default function useCheckCodePushVersion(user, limit) {
  function convertCodePushVer(x) {
    if (!x || !x?.includes('.')) {
      return 0
    }
    return (
      Number(x.split('.')[0] * 10000) +
      Number(x.split('.')[1] * 100) +
      Number(x.split('.')[2])
    )
  }

  function checkCodePushVersion(x, y) {
    return convertCodePushVer(x) >= convertCodePushVer(y)
  }

  return checkCodePushVersion
}
