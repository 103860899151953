import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import dayjs from 'dayjs'
import ModalStore from '../../store/ModalStore'
import TimeStore from 'store/TimeStore'
import { toJS } from 'mobx'

const ResurrectBonusAttendanceEventModal = observer(({ token, farmData }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  return (
    <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
      <div
        className='w-[90vw] h-[150vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex absolute justify-end items-center flex-col px-[16px] pt-[28vw] pb-[12vw]'
        style={{
          backgroundImage: `url(../icon/resurrectBonusAttedanceEvent/${
            ModalStore?.config?.imgType ?? 'fertToday'
          }.png)`,
        }}
      >
        <button
          className='text-[5vw] font-bold rounded-3xl py-[14px] z-[2000] w-[70%] top-[120vw] left-[12.5vw] text-[#442B22] mt-[4vw]'
          style={{
            background: isButtonPressed
              ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressed
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          확인
        </button>
        <button
          className=' z-[1101] w-[8%] -top-[10vw] right-[4vw] absolute'
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
        </button>
      </div>
    </div>
  )
})

export default ResurrectBonusAttendanceEventModal
