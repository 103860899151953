import React from 'react'

const NudgeBar = ({
  text,
  bottom,
  down = false,
  bgColor = '#212121BB',
  textColor = '#FFF',
  width = '80vw',
  center = false,
  right = null,
  triangleRight = 0,
}) => {
  let triangleBottom
  if (down) triangleBottom = 12
  else triangleBottom = -36

  let rightTemp
  if (center) rightTemp = (100 - Number(width.slice(0, -2))) / 2
  else rightTemp = right

  return (
    <>
      {/* <div style={{}}>
        <div
          style={{
            borderTopWidth: down ? 13 : 0,
            borderTopColor: down ? bgColor : "#FFF",
            borderBottomWidth: down ? 0 : 13,
            borderBottomColor: down ? "#FFF" : bgColor,
            width: 0,
            height: 0,
            backgroundColor: "transparent",
            borderStyle: "solid",
            borderLeftWidth: 7,
            borderRightWidth: 7,
            // bottom: 50,
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            position: "absolute",
            // right: center ? "48vw" : right + triangleRight,
            // bottom: bottom - triangleBottom,
            // alignSelf: "flex-end",
            top: "29.3%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        />
      </div> */}
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          // bottom,
          // right: center ? `${rightTemp}vw` : right,
          flex: 1,
          borderRadius: '4vw',
          height: '10vw',
          backgroundColor: bgColor,
          zIndex: -1,
          // paddingRight: "3vw",
          // paddingLeft: 10,
          alignItems: 'center',
          justifyContent: 'center',
          borderColor: 'transparent',
          width: '85%',
          top: '25%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          fontSize: '4.7vw',
        }}
      >
        <div style={{ color: textColor, fontFamily: 'maplestory' }}>{text}</div>
      </div>
    </>
  )
}

export default NudgeBar
