import React, { useState, useEffect } from 'react'
import useInterval from '../../hooks/useInterval'
import { observer } from 'mobx-react-lite'
import BottomSheetStore from 'store/BottomSheetStore'
import UserStore from 'store/UserStore'
import TimeStore from 'store/TimeStore'
import moment from 'moment'
import ModalStore from 'store/ModalStore'
import LottieGif from './LottieGif'
import ABStore from 'store/ABStore'

// eat, sleep, jump, seat, sunbed, home...
const motions = ['work', 'water', 'fert', 'stand']
const firstIndex = Math.floor(Math.random() * motions?.length)

const petIndexToName = {
  0: 'farmer',
  1: 'potato',
  2: 'aloha',
  3: 'sweetpotato',
  4: 'kindergarten',
  5: 'onion',
  98: 'rest',
  99: 'sweetpotato',
}

const Pet = observer(({ petIndex = 0, gganbuMode = false }) => {
  const [petStyle, setPetStyle] = useState({
    right: '-8vw',
    bottom: '39vw',
  })
  const [petAnim, setPetAnim] = useState(motions[firstIndex])
  const [loading, setLoading] = useState(true)
  const [petJson, setPetJson] = useState(null)

  const interval = 5500 - Math.random() * 1000

  useEffect(() => {
    const petStyle = {
      0: {
        right: '-13vw',
        bottom: '10vw',
        zIndex: 5,
      },
      1: {
        left: '-14vw',
        bottom: '10vw',
        zIndex: 5,
        transform: 'scaleX(-1)',
      },
      2: {
        left: '-12vw',
        bottom: '24vw',
        zIndex: 5,
        transform: 'scaleX(-1)',
      },
      3: {
        right: '-13vw',
        bottom: '24vw',
        zIndex: 5,
      },
      4: {
        left: '-8vw',
        bottom: '34vw',
        zIndex: 3,
        transform: 'scaleX(-1)',
      },
      5: {
        right: '-8vw',
        bottom: '34vw',
        zIndex: 3,
      },
      98: {
        right: '-32vw',
        bottom: '35vw',
        zIndex: 3,
      },
      99: {
        left: '-5vw',
        bottom: '34vw',
        zIndex: 3,
        transform: 'scaleX(-1)',
      },
    }

    setPetStyle(petStyle[petIndex])
  }, [petIndex])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, Math.random() * 2000)
  }, [])

  useInterval(() => {
    const randomIndex = Math.floor(Math.random() * motions?.length)
    setPetAnim(motions[randomIndex])
  }, interval)

  useEffect(() => {
    const loadJson = async () => {
      const { default: data } = await import(
        `../../json/gifJson/pets/${petAnim}/${petIndexToName[petIndex]}.json`
      )

      setPetJson(data)
    }

    loadJson()
  }, [petAnim, petIndex])

  if (loading) return null

  return (
    <div
      style={{
        position: 'relative',
      }}
      onClick={() => {
        if (!gganbuMode) {
          if (petIndex === 98) {
            ModalStore.setIsModalOpen('eventPet')
          } else {
            BottomSheetStore.setIsbottomSheetOpen('pet')
          }
        }
      }}
    >
      {TimeStore?.currentMoment?.isSameOrBefore(
        moment(UserStore?.farmData?.petData?.createdAt ?? '2023-01-01').add(
          3,
          'd',
        ),
      ) &&
        (!UserStore?.farmData?.petData?.inviteHistory?.length ?? 0) < 7 &&
        !gganbuMode && (
          <div
            className='petMotion'
            style={{
              position: 'absolute',
              fontWeight: 'bold',
              left: '-2.5vw',
              bottom: '48.5vw',
              borderRadius: '99vw',
              width: '10vw',
              textAlign: 'center',
              fontSize: '2vw',
              paddingTop: '0.5vw',
              paddingBottom: '0.5vw',
              color: '#FFFFFF',
              backgroundColor: '#FF8983',
              border: '2px solid #FF544B',
            }}
          >
            무료체험
          </div>
        )}

      {!!petAnim && petJson && (
        <LottieGif
          animationData={petJson}
          className='petMotion'
          style={{
            position: 'absolute',
            width: `${petIndex === 98 ? 30 : 16}vw`,
            ...petStyle,
          }}
        />
      )}
    </div>
  )
})

export default Pet
