import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'

const BadgeModal = observer(({ token, gganbuId, gganbuFarmData }) => {
  const [clearedItemType, setClearedItemType] = useState([])
  const [lockItemType, setLockItemType] = useState([])

  const clearedItem = async () => {
    const result = await backendApis.clearedItemGganbu(token, 'GET', {
      gganbuId: gganbuId,
    })

    const harvestBadgeData = []
    for (const key of result?.data?.uniqueItemType) {
      if (result?.data?.count.hasOwnProperty(key)) {
        let found = false
        for (const item of harvestBadgeData) {
          if (item.name === key) {
            item.count += result?.data?.count[key]
            found = true
            break
          }
        }
        if (!found) {
          harvestBadgeData.push({ name: key, count: result?.data?.count[key] })
        }
      }
    }

    setClearedItemType(harvestBadgeData)
    setLockItemType(result?.data?.lockItemType)
  }

  useEffect(() => {
    clearedItem()
  }, [])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 2000,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90vw',
            height: '180vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundImage: 'url(../icon/badgeModal.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <img
            style={{
              width: '70%',
              position: 'absolute',
              top: '50vw',
              left: '15vw',
            }}
            alt=''
            src={'/icon/badgeModalTitlev2.png'}
          />
          <div
            style={{
              fontFamily: 'maplestory',
              color: '#FA9D22',
              fontSize: '5vw',
              position: 'absolute',
              top: '68vw',
              width: '92.5%',
              textAlign: 'center',
            }}
          >
            수확 완료한 배지 ({clearedItemType?.length}/31)
          </div>

          <div
            style={{
              position: 'absolute',
              overflow: 'scroll',
              width: '77vw',
              top: '66.9%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1999,
              height: '88vw',
              whiteSpace: 'normal',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              // border: "1px solid black",
            }}
          >
            <div
              style={{
                position: 'relative',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <img
                src={`/badge/${gganbuFarmData?.itemType}.png`}
                style={{
                  width: '25vw',
                }}
                alt='abc'
              />
              <div
                style={{
                  color: '#72B646',
                  background: '#F1F3C8',
                  fontFamily: 'maplestory',
                  fontSize: '3vw',
                  textAlign: 'center',
                  width: '16vw',
                  paddingTop: '1.5vw',
                  paddingBottom: '1.5vw',
                  borderRadius: '3vw',
                  marginLeft: '4.5vw',
                  marginTop: '-1vw',
                }}
              >
                진행중
              </div>
            </div>
            {clearedItemType?.length > 0 &&
              clearedItemType?.map((item, i) => {
                return (
                  <div
                    key={i + Math.random()}
                    style={{
                      position: 'relative',
                      justifyContent: 'center',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        color: 'white',
                        fontFamily: 'maplestory',
                        position: 'absolute',
                        bottom: '22vw',
                        right: '3vw',
                        fontSize: '3vw',
                        background: '#FF3E3E',
                        textAlign: 'center',
                        borderRadius: '3vw',
                        paddingRight: '2vw',
                        paddingLeft: '2vw',
                        paddingTop: '1.5vw',
                        paddingBottom: '1.5vw',
                      }}
                    >
                      x {item?.count}
                    </div>
                    <img
                      src={`/badge/${item?.name}.png`}
                      style={{
                        width: '25vw',
                      }}
                      alt='abc'
                    />
                    <div
                      style={{
                        color: '#46B673',
                        background: '#C8F3D4',
                        fontFamily: 'maplestory',
                        fontSize: '3vw',
                        textAlign: 'center',
                        width: '16vw',
                        paddingTop: '1.5vw',
                        paddingBottom: '1.5vw',
                        borderRadius: '3vw',
                        marginLeft: '4.5vw',
                        marginTop: '-1vw',
                      }}
                    >
                      수확완료
                    </div>
                  </div>
                )
              })}
            {lockItemType?.length > 0 &&
              lockItemType?.map((item, i) => {
                return (
                  <div
                    key={i + Math.random()}
                    style={{
                      position: 'relative',
                      // justifyContent: "center",
                      // flex: 1,
                    }}
                  >
                    <img
                      src={`/badge/${item}Lock.png`}
                      style={{
                        width: '25vw',
                      }}
                    />
                    <div
                      style={{
                        color: '#835932',
                        background: '#FBEBC6',
                        fontFamily: 'maplestory',
                        fontSize: '3vw',
                        textAlign: 'center',
                        width: '16vw',
                        paddingTop: '1.5vw',
                        paddingBottom: '1.5vw',
                        borderRadius: '3vw',
                        marginLeft: '4.5vw',
                        marginTop: '-1vw',
                      }}
                    >
                      수확대기
                    </div>
                  </div>
                )
              })}
          </div>

          <button
            style={{
              width: '8vw',
              top: '13vw',
              right: '8%',
              position: 'absolute',
              zIndex: 1113,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
        </div>
      </div>
    </>
  )
})
export default BadgeModal
