import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)

const timeChecker = (compareTime, timeInfo, from, logOn = false) => {
  const compareTimeMoment = dayjs(compareTime ?? '2022-01-01')
  if (!timeInfo) {
    timeInfo = {
      standardHour: 4,
      standardMin: 0,
      standardSec: 0,
    }
  }

  let availableTime
  const currentTime = dayjs()
  const currentHour = Number(currentTime.format('HH'))
  const currentMin = Number(currentTime.format('mm'))
  const currentSecond = Number(currentTime.format('ss'))

  if (
    currentHour < timeInfo?.standardHour ||
    (currentHour === timeInfo?.standardHour &&
      currentMin < timeInfo?.standardMin) ||
    (currentHour === timeInfo?.standardHour &&
      currentMin === timeInfo?.standardMin &&
      currentSecond < timeInfo?.standardSec)
  ) {
    availableTime = dayjs()
      .startOf('d')
      .subtract(1, 'd')
      .add(timeInfo?.standardHour, 'h')
      .add(timeInfo?.standardMin, 'm')
  } else {
    availableTime = dayjs()
      .startOf('d')
      .add(timeInfo?.standardHour, 'h')
      .add(timeInfo?.standardMin, 'm')
  }

  let isAvailable = false

  if (availableTime?.isSameOrAfter(compareTimeMoment)) {
    isAvailable = true
  }

  return isAvailable
}

export default timeChecker
