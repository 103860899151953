import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const GroceryDealModal = observer(() => {
  const throttleRef = useRef({})
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  const sendToPurchaseScreen = async (questType) => {
    window.location.href = `#generalNavigator.${JSON.stringify({
      screen: 'WebviewScreen',
      params: {
        url: `https://alwayz-shop-front.prod.ilevit.com/deals/grocery-deal`,
        enteringComponent: 'Alfarm_groceryDeal',
        enteringLocation: 'browsingQuest',
        isFrom: 'Alfarm_groceryDeal',
        isTimerOn: questType === 'browsingQuest' ? true : false,
        timerText: '미션 완료까지 ',
        isTimerEndText: '축하합니다! 동전 2개를 받았어요',
        timeStamp: new Date(new Date().getTime() + 1000 * 30),
        rewardText: '상품을 구매하면 고급비료 5개를 드려요',
        questAmount: '2',
        rewardType: '동전',
        timeMinute: 0.5,
      },
    })}`
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90vw',
            height: '180vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            //이미지 바꾸기
            backgroundImage: 'url(../groceryDealModal/groceryDealModal.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <button
            style={{
              width: '10%',
              top: '20vw',
              right: '8%',
              position: 'absolute',
              zIndex: 1113,
              background: 'black',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            style={{
              background: isButtonPressed ? '#F7C82A' : '#F7C82A',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              width: '65vw',
              height: '14vw',
              borderRadius: '100vw',
              color: 'black',
              fontFamily: 'maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '4.5vw',
              position: 'absolute',
              top: '73%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            onClick={() => {
              AlfarmEventLogger({
                throttleRef,
                locationType: 'page',
                locationName: 'dealEvent',
                eventType: 'click',
                eventName: 'purchase',
                data: {},
                collection: 'UserAlfarmClickLog',
              })
              sendToPurchaseScreen('purchaseQuest')
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
          >
            구매하기
          </div>
        </div>
      </div>
    </>
  )
})

export default GroceryDealModal
