import commaNumber from 'comma-number';
import React from 'react';

const ItemInfo = ({ itemInfo, isMuted, isPlaying, handlePlayPause, handleVolumeToggle }) => {
  return (
    <button
      type='button'
      onClick={() => {
        if (isPlaying === true) handlePlayPause();
        const adItemBrowsing = {
            text: '올팜',
  
            isTimerOn: false,
            timerText: `초간 구경하면 일반비료 1개를 받아요`,
            isTimerEndText: '축하합니다! 물 10g을 받았어요',
  
            itemId: itemInfo?.itemId,
            enteringComponent: 'ShortFormShop_C',
            source:
              'https://assets.ilevit.com/dc0816b9-b6a8-40cd-9a9e-2f6bb0a218c7.png',
            isAdItem: true,
            itemInfo: itemInfo,
        }
        
          window.location.href = `#adItemBrowsing.${JSON.stringify(
            adItemBrowsing,
          )}`
      }}
      className='z-40 '
    >
      <div className='flex flex-row items-center justify-between mb-[4vh] bg-white  p-[2.4vw] rounded-lg'>
        <div className='flex flex-col items-start justify-center'>
          <div className='flex flex-row items-center justify-center'>
            <div className='px-[2vw] py-[4vw] mr-[3vw] rounded-full bg-red-500 text-white font-semibold'>
              {Math.floor(
                (1 -
                  itemInfo?.teamPurchasePrice /
                    itemInfo?.individualPurchasePrice) *
                  100,
              )}
              %
            </div>
            <div className='flex flex-col items-start justify-center'>
              <div className='flex text-[3vw] w-[54vw] mb-[1vh] font-bold text-black'>
                {itemInfo?.itemTitle?.length > 20
                  ? `${itemInfo?.itemTitle.slice(0, 20)}..`
                  : itemInfo?.itemTitle}
              </div>
              <div className='flex flex-row items-center justify-center '>
                <div
                  className='line-through pr-[2vw] text-gray-500'
                  style={{ textDecorationColor: '#718096' }}
                >
                  {commaNumber(itemInfo?.individualPurchasePrice)}원
                </div>
                <div className='font-semibold text-black'>
                  {commaNumber(itemInfo?.teamPurchasePrice)}원
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='z-40'>
          <img
            src={itemInfo?.itemImageUrl}
            alt='thumbnail'
            className='w-[16vw] h-auto rounded-lg z-40'
          />
        </div>
      </div>
    </button>
  );
};

export default ItemInfo;
