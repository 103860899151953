import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'

const Piggy = observer(({ id = 0, level = 1, token, isLoading }) => {
  return (
    <>
      {level === 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '45vw',
            marginLeft: '17.5vw',
            marginTop: '3vw',
            color: 'black',
          }}
        >
          복돼지 Lv.1
          <img alt='' src={`/luckyPiggy/level1.png`} />
        </div>
      )}
      {level >= 1 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '45vw',
            marginLeft: '17.5vw',
            marginTop: '3vw',
            color: 'black',
          }}
        >
          복돼지 Lv.{level}
          <img alt='' src={`/luckyPiggy/level${level}.png`} />
        </div>
      )}
    </>
  )
})

export default Piggy
