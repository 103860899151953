import React from 'react'
import LottieGif from './LottieGif'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import TimeStore from 'store/TimeStore'
import UserStore from 'store/UserStore'
import giveWaterJson from '../../json/gifJson/water/giveWater.json'

const WaterEffect = ({ isTeamAlfarm = false }) => {
  return (
    <>
      <LottieGif
        animationData={giveWaterJson}
        className='z-[100001] w-[40vw] h-auto mt-[10vw] absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 flex justify-center items-center'
        loop
      />
      {TimeStore?.currentMoment?.isSameOrBefore(
        moment(UserStore?.farmData?.doubleExpEndAt ?? '2024-01-01'),
      ) &&
        !isTeamAlfarm && (
          <img
            className='z-[100002] absolute top-[30%] left-[55vw] -translate-x-1/2 translate-y-[-10vw] w-[20vw]'
            alt=''
            src={`../doubleExpEventModal/doubleExp.png`}
          />
        )}
    </>
  )
}
export default observer(WaterEffect)
