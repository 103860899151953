import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../store/ModalStore'

const getItemName = (index) => {
  if (index === 0) return
  if (index === 1) return 'giftWater'
  if (index === 2) return 'giftFertilizer'
  if (index === 3) return 'giftFertilizerSet'
  return
}

const SelectedGift = (selectItem) => {
  if (selectItem === 0) return { type: '선물을 선택하세요' }
  if (selectItem === 1) return { type: '물 50g을' }
  if (selectItem === 2) return { type: '일반비료 1개를' }
  if (selectItem === 3) return { type: '고급비료 1개를' }
}

const GganbuGiftModal = observer(
  ({
    token,

    gganbuName,
    gganbuId,
    gganbuFarmGiftInfo,
    getBonusRewardInfo,
    setFarmData,
    farmData,
    setShowSuccessPopup,
  }) => {
    const [selectItem, setSelectItem] = useState(0)
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const [showLackOfResourceModal, setShowLackOfResourceModal] =
      useState(false)
    const [canClickButton, setCanClickButton] = useState(false)

    const gganbuGift = async (selectItem) => {
      if (!canClickButton) return

      setCanClickButton(false)
      const result = await backendApis.gganbuGift(token, 'POST', {
        gganbuId: gganbuId,
        selectItem: selectItem,
      })

      if (result?.data?.reason === 'lackOfResource') {
        setShowLackOfResourceModal(true)
        setTimeout(() => {
          setShowLackOfResourceModal(false)
          setCanClickButton(true)
        }, 3000)
      }
      if (result?.data?.success) {
        if (selectItem === 1) {
          let tmpFarmData = farmData
          tmpFarmData.water -= 50
          setFarmData(tmpFarmData)
          // setAddWater(result?.data?.water);
        }
        // 보내기 완료
        setShowSuccessPopup(true)
        setTimeout(() => {
          setShowSuccessPopup(false)
        }, 2000)

        ModalStore.setIsModalOpen('basic')
      }
    }

    useEffect(() => {
      if (gganbuFarmGiftInfo) {
        setCanClickButton(true)
      }
    }, [])

    const Gift = ({ selectedItem = 0, itemType = 'water' }) => {
      return (
        <div
          className='card clickEffect'
          onClick={() => {
            if (selectItem === selectedItem) setSelectItem(selectItem)
            else setSelectItem(selectedItem)
          }}
        >
          <div
            style={{
              background: 'white',
              width: '25vw',
              height: '25vw',
              borderRadius: '4vw',
              overflow: 'hidden',
              textAlign: 'center',
              marginLeft: '2vw',
              marginTop: '40vw',
              border:
                selectItem === selectedItem
                  ? '6px solid #FFEA2F'
                  : '6px solid #ddd',
              backgroundImage: `url(../../gganbuGift/${itemType}.png)`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
      )
    }

    const GiftButton = () => {
      if (gganbuFarmGiftInfo && !selectItem) {
        return (
          <button
            style={{
              width: '75%',
              bottom: '10vw',
              height: '14vw',
              left: '10vw',
              position: 'absolute',
              zIndex: 3,
              background: '#C5C5C5',
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: '4.5vw',
              borderRadius: '40vw',
            }}
          >
            선물을 선택하세요
          </button>
        )
      }
      if (gganbuFarmGiftInfo && selectItem) {
        return (
          <button
            style={{
              width: '75%',
              bottom: '10vw',
              height: '14vw',
              left: '10vw',
              position: 'absolute',
              zIndex: 3,
              background: isButtonPressed
                ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: '4.5vw',
              borderRadius: '40vw',
            }}
            onClick={() => {
              if (selectItem === 0) return
              gganbuGift(selectItem)
            }}
          >
            맞팜 친구에게 선물하기
          </button>
        )
      } else {
        return (
          <button
            style={{
              width: '75%',
              bottom: '10vw',
              height: '14vw',
              left: '10vw',
              position: 'absolute',
              zIndex: 3,
              background: '#C5C5C5',
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: '4.5vw',
              borderRadius: '40vw',
            }}
          >
            오늘 이미 선물했어요
          </button>
        )
      }
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              width: '90%',
            }}
          >
            <img alt='' src={'/gganbuGift/giftModal.png'} />
            {gganbuFarmGiftInfo ? (
              <>
                <div
                  style={{
                    width: '100%',
                    top: '40%',
                    left: '50%',
                    textAlign: 'center',
                    transform: 'translate(-50%,-50%)',
                    position: 'absolute',
                    color: 'black',
                    fontSize: '5vw',
                    lineHeight: '140%',
                  }}
                >
                  맞팜 친구에게 선물을 해보세요! <br />
                  선물은 내 재화를 사용해요
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    position: 'absolute',
                    top: '43%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    marginLeft: '4vw',
                  }}
                >
                  <Gift selectedItem={1} itemType={getItemName(1)} />
                  <Gift selectedItem={2} itemType={getItemName(2)} />
                  <Gift selectedItem={3} itemType={getItemName(3)} />
                </div>
                <div
                  style={{
                    width: '100%',
                    top: '73%',
                    left: '50%',
                    textAlign: 'center',
                    transform: 'translate(-50%,-50%)',
                    position: 'absolute',
                    color: 'black',
                    fontSize: '4vw',
                  }}
                >
                  {selectItem ? (
                    <>{SelectedGift(selectItem).type} 선택했어요</>
                  ) : (
                    <>보낼 선물을 골라보세요!</>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    width: '100%',
                    top: '40%',
                    left: '50%',
                    textAlign: 'center',
                    transform: 'translate(-50%,-50%)',
                    position: 'absolute',
                    color: 'black',
                    fontSize: '5vw',
                  }}
                >
                  맞팜 친구에게 선물을 해보세요!
                </div>
                <div
                  style={{
                    width: '35vw',
                    top: '60%',
                    left: '50%',
                    textAlign: 'center',
                    transform: 'translate(-50%,-50%)',
                    position: 'absolute',
                    color: 'black',
                    fontSize: '4vw',
                  }}
                >
                  <img src='/gganbuGift/alreadyGift.png' alt='' />
                </div>
              </>
            )}

            <div
              style={{
                width: '100%',
                bottom: '4vw',
                left: '50%',
                textAlign: 'center',
                transform: 'translate(-50%,-50%)',
                position: 'absolute',
                color: 'black',
                fontSize: '3.5vw',
                marginBottom: '-2vw',
              }}
            >
              *하루에 최대 1번 선물할 수 있어요
            </div>
            <button
              style={{
                width: '10%',
                position: 'absolute',
                zIndex: 3,
                top: '0%',
                right: '0%',
                transform: 'translate(-50%,-50%)',
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img src='/icon/modalCloseButton.png' alt='' />
            </button>
            {GiftButton()}
          </div>
        </div>

        {showLackOfResourceModal && (
          <>
            <div
              style={{
                flex: 1,
                position: 'absolute',
                left: '50%',
                top: '55%',
                zIndex: 2222,
              }}
            >
              <div
                style={{
                  backgroundColor: 'black',
                  position: 'absolute',
                  overlay: {
                    background: 'rgba(0,0,0,0)',
                    zIndex: 2222,
                  },
                  width: '80vw',
                  paddingTop: '8vw',
                  paddingBottom: '8vw',
                  borderRadius: '4vw',
                  opacity: '0.9',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '4.5vw',
                  lineHeight: '150%',
                  textAlign: 'center',
                }}
              >
                재화가 부족해서 선물할 수 없어요 <br />
                재화를 획득한 후 다시 시도해주세요
              </div>
            </div>
          </>
        )}
      </>
    )
  },
)

export default GganbuGiftModal
