import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from 'utils/backendApis'
import ResourceToastStore from 'store/ResourceToastStore'
import ToastStore from 'store/ToastStore'
import GrapeEventPageHeader from 'comps/seasonalEvent/EventPageHeader'
import WhiteTextTimer from 'comps/WhiteTextTimer'
import LoadingIndicator from 'comps/loadingIndicator'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import ModalStore from 'store/ModalStore'
import NewYearDealModal from 'comps/atoms/Modals/NewYearDealModal'
import NewYearEventModal from 'comps/atoms/Modals/DealEventHistoryModal'
import DealEventHistoryModal from 'comps/atoms/Modals/DealEventHistoryModal'
import moment from 'moment'

const DEGREE_MAP = [
  5355, // 하트 1개
  5445, // 하트 5개
  5535, // 하트 3개
  5625, // 하트 2개
]

const exchangeItemCard = [
  {
    type: 'water',
    needLuckBag: 10,
    rewardText: '물 50g',
    img: '../dealEvent/familyDealEvent/displaywater.png',
    color: '#FCA4B7',
    shadow: '#FFA234',
  },
  {
    type: 'fertilizer',
    needLuckBag: 20,
    rewardText: '일반비료 2개',
    img: '../dealEvent/familyDealEvent/displayferttilizer.png',
    color: '#FCA4B7',
    shadow: '#FFA234',
  },
  {
    type: 'fertilizerSet',
    needLuckBag: 30,
    rewardText: '고급비료 2개',
    img: '../dealEvent/familyDealEvent/displayfertilizerSet.png',
    color: '#FCA4B7',
    shadow: '#FFA113',
  },
  {
    type: 'fertilizerSetSpecial',
    needLuckBag: 110,
    rewardText: '고급비료 7개',
    img: '../dealEvent/familyDealEvent/displayfertilizerSet.png',
    color: '#FCA4B7',
    shadow: '#FFA234',
  },
]

const FamilyDealEvent = observer(() => {
  const [isRolling, setIsRolling] = useState(false)
  const [rouletteRewardNumber, setRouletteRewardNumber] = useState(0)

  const throttleRef = useRef({})
  const [isLoading, setIsLoading] = useState(true)
  const [questStatus, setQuestStatus] = useState({})
  const [questData, setQuestData] = useState({})
  const [canClick, setCanClick] = useState(true)
  const [jangbogiCoin, setJangbogiCoin] = useState(0)
  const [confirmModal, setConfirmModal] = useState(null)
  const [canClickStartButton, setCanClickStartButton] = useState(false)

  let hidden, visibilityChange
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  useEffect(() => {
    Promise.all([getUserQuestData(), getUserWaterData()])

    setIsLoading(false)
  }, [])

  useEffect(() => {
    AlfarmEventLogger({
      locationType: 'page',
      locationName: 'familyDealEvent',
      eventType: 'pageview',
      data: {},
      collection: 'UserAlfarmPageviewLog',
    })
  }, [])

  useEffect(() => {
    if (
      localStorage.getItem('NewYearDealModal') !== moment().format('YYYY-MM-DD')
    ) {
      ModalStore.setIsModalOpen('NewYearDealModal')
      localStorage.setItem('NewYearDealModal', moment().format('YYYY-MM-DD'))
    }
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      await getUserQuestData()
      await getUserWaterData()
      setIsLoading(false)
    } catch (error) {
      // 오류 처리
      setIsLoading(false) // 오류 발생 시에도 로딩 상태를 해제합니다.
    }
  }

  const startRoulette = async () => {
    setIsRolling(true)

    const result = await backendApis.dealEventRoulette()

    AlfarmEventLogger({
      throttleRef,
      locationType: 'page',
      locationName: 'familyDealEvent',
      eventType: 'click',
      eventName: 'roulette',
      data: {},
      collection: 'UserAlfarmClickLog',
    })
    if (result?.data?.msg === 'clear') {
      ToastStore.toastOn({
        type: 'error',
        message: '이미 오늘 룰렛을 돌렸어요.',
        duration: 2000,
      })
    }
    if (result?.data?.amount) {
      setQuestData({
        ...questData,
        dealEventRoulette: [new Date(new Date().getTime())],
      })

      setRouletteRewardNumber(result?.data?.reward)

      setTimeout(() => {
        setJangbogiCoin(jangbogiCoin + result?.data?.amount)
        ResourceToastStore?.setResourceToast({
          open: true,
          type: 'familyDealCoin',
          count: result?.data?.amount,
        })

        setIsRolling(false)
      }, 7700)
    }
  }

  useEffect(() => {
    // 페이지의 가시성 변경 이벤트 핸들러
    const handleVisibilityChange = () => {
      if (!document[hidden]) {
        // 페이지가 다시 가시 상태로 돌아올 때 API를 다시 호출합니다.
        fetchData()
      }
    }

    // VisibilityChange 이벤트 리스너 추가
    document.addEventListener(visibilityChange, handleVisibilityChange)

    // 컴포넌트 언마운트 시에 이벤트 리스너 제거
    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange)
    }

    // useEffect를 isLoading에 종속시킴으로써 가시성 변경에만 반응하도록 합니다.
  }, [isLoading])

  const getUserQuestData = async () => {
    const result = await backendApis.getDealEventUserData()

    setQuestStatus(result?.data?.questStatus)
    setQuestData(result?.data?.questData)
  }

  const getUserWaterData = async () => {
    const result = await backendApis.getWaterRepoData({
      project: {
        jangbogiCoin: 1,
      },
    })
    setJangbogiCoin(result?.data?.jangbogiCoin || 0)
  }

  const missionList = [
    {
      type: 'checkin',
      title: '가정의달 출석하기',
      rewardText: '1개, 하루 최대 3번 가능',
      buttonText: '출석하기',
      img: '../dealEvent/familyDealEvent/checkin.png',
      onClick: () => {
        AlfarmEventLogger({
          throttleRef,
          locationType: 'page',
          locationName: 'familyDealEvent',
          eventType: 'click',
          eventName: 'checkin',
          data: {},
          collection: 'UserAlfarmClickLog',
        })
        clearQuest('checkin')
      },
      isCleared: questStatus?.checkin,
      isOver: questData?.checkin?.length >= 3,
      cooltime: questData?.checkin
        ? new Date(
            new Date(
              questData?.checkin[questData?.checkin?.length - 1],
            ).getTime() +
              1000 * 60 * 60 * 3,
          )
        : new Date(new Date().getTime() + 1000 * 60 * 60 * 3),
    },
    // {
    //   type: 'checkinSpecial',
    //   title: '가정의달 밤 9시에 출석하기',
    //   rewardText: '3개, 하루 최대 1번 가능',
    //   buttonText: '출석하기',
    //   img: '../dealEvent/familyDealEvent/checkin.png',
    //   onClick: () => {
    //     AlfarmEventLogger({
    //       throttleRef,
    //       locationType: 'page',
    //       locationName: 'familyDealEvent',
    //       eventType: 'click',
    //       eventName: 'checkinSpecial',
    //       data: {},
    //       collection: 'UserAlfarmClickLog',
    //     })
    //     clearQuest('checkinSpecial')
    //   },
    //   isCleared: questStatus?.checkinSpecial,
    //   isOver: questData?.checkinSpecial?.length >= 1,
    // },
    {
      type: 'browsing',
      title: '가정의달 상품 30초 구경하기',
      rewardText: '2개, 하루 최대 3번 가능',
      buttonText: '구경하기',
      img: '../dealEvent/familyDealEvent/browsing.png',
      onClick: () => {
        AlfarmEventLogger({
          throttleRef,
          locationType: 'page',
          locationName: 'familyDealEvent',
          eventType: 'click',
          eventName: 'browsing',
          data: {},
          collection: 'UserAlfarmClickLog',
        })
        sendToPurchaseScreen('browsingQuest')
      },
      isCleared: questStatus?.browsing,
      isOver: questData?.browsing?.length >= 3,
      cooltime: questData?.browsing
        ? new Date(
            new Date(
              questData?.browsing[questData?.browsing?.length - 1],
            ).getTime() +
              1000 * 60 * 60 * 3,
          )
        : new Date(new Date().getTime() + 1000 * 60 * 60 * 3),
    },
    {
      type: 'purchase',
      title: '가정의달 상품 1개 구매하기',
      rewardText: '35개, 고급비료 5개',
      buttonText: '구매하기',
      img: '../dealEvent/familyDealEvent/purchase.png',
      onClick: () => {
        AlfarmEventLogger({
          throttleRef,
          locationType: 'page',
          locationName: 'familyDealEvent',
          eventType: 'click',
          eventName: 'purchase',
          data: {},
          collection: 'UserAlfarmClickLog',
        })
        sendToPurchaseScreen('purchaseQuest')
      },
      isCleared: false,
      isOver: false,
    },
  ]

  const sendToPurchaseScreen = async (questType) => {
    window.location.href = `#generalNavigator.${JSON.stringify({
      screen: 'WebviewScreen',
      params: {
        url: `https://alwayz-shop-front.prod.ilevit.com/deals/family-deal`,
        enteringComponent: 'Alfarm_familyDeal',
        enteringLocation: 'browsingQuest',
        isFrom: 'Alfarm_familyDeal',
        isTimerOn: questType === 'browsingQuest' ? true : false,
        timerText: '미션 완료까지 ',
        isTimerEndText: '축하합니다!하트 2개를 받았어요',
        timeStamp: new Date(new Date().getTime() + 1000 * 30),
        rewardText: '상품을 구매하면 고급비료 5개를 드려요',
        questAmount: '2',
        rewardType: '하트',
        timeMinute: 0.5,
      },
    })}`
  }

  const clearQuest = async (questType) => {
    if (!canClick) return
    setCanClick(false)
    const result = await backendApis.clearDealEvent({
      questType: questType,
    })

    if (result?.data?.success) {
      ResourceToastStore?.setResourceToast({
        open: true,
        type: 'familyDealCoin',
        count: result?.data?.rewardAmount,
      })

      setQuestStatus({
        ...questStatus,
        [questType]: true,
      })
      if (questType === 'checkin' || questType === 'checkinSpecial') {
        setJangbogiCoin(jangbogiCoin + result?.data?.rewardAmount)
        if (questData?.checkin) {
          setQuestData({
            ...questData,
            [questType]: [
              ...questData?.checkin,
              new Date(new Date().getTime()),
            ],
          })
        } else {
          setQuestData({
            ...questData,
            [questType]: [new Date(new Date().getTime())],
          })
        }
      }

      setCanClick(true)
    }
  }

  const exchangeLuckyBag = async (type) => {
    const result = await backendApis.exchangeLuckyBag({ type })
    if (!result?.data) {
      ToastStore.toastOn({
        type: 'error',
        message: '하트가 부족해요. 미션을 완료해주세요.',
        duration: 2000,
      })
    }
    if (result?.data?.success) {
      setJangbogiCoin(jangbogiCoin - result?.data?.needLuckBag)
      ResourceToastStore?.setResourceToast({
        open: true,
        type: type,
        count: result?.data?.rewardAmount,
      })
    }
  }

  const MissionButton = ({ item }) => {
    if (
      item?.type === 'checkinSpecial' &&
      !item?.isOver &&
      new Date().getHours() < 21
    ) {
      return (
        <div
          style={{
            padding: '2vw',
            width: '22vw',
            borderRadius: '2vw',
            color: '#6A3615',
            height: '11vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4vw',
          }}
        >
          <WhiteTextTimer
            onTimeEnd={() => {
              setQuestStatus({
                ...questStatus,
                checkinSpecial: false,
              })
            }}
            timeStamp={
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                21,
                0,
                0,
              )
            }
            timerMinutes={0}
            color='#442b22'
          />
        </div>
      )
    }
    if (item?.type === 'checkinSpecial' && new Date().getHours() >= 22) {
      return (
        <div
          style={{
            padding: '2vw',
            width: '22vw',
            borderRadius: '2vw',
            color: '#6A3615',
            height: '11vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4vw',
          }}
        >
          내일가능
        </div>
      )
    }
    if (item?.isOver) {
      return (
        <div
          style={{
            padding: '2vw',
            width: '22vw',
            borderRadius: '2vw',
            color: '#6A3615',
            height: '11vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4vw',
          }}
        >
          내일가능
        </div>
      )
    } else if (item?.isCleared) {
      return (
        <div
          style={{
            padding: '2vw',
            width: '22vw',
            borderRadius: '2vw',
            color: '#6A3615',
            height: '11vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4vw',
          }}
        >
          <WhiteTextTimer
            onTimeEnd={() => {
              if (item?.type === 'checkin') {
                setQuestStatus({
                  ...questStatus,
                  checkin: false,
                })
              }
              if (item?.type === 'browsing') {
                setQuestStatus({
                  ...questStatus,
                  browsing: false,
                })
              }
            }}
            timeStamp={item?.cooltime}
            timerMinutes={0}
            color='#442b22'
          />
        </div>
      )
    } else {
      return (
        <div
          style={{
            padding: '2vw',
            background: '#3FB751',
            width: '22vw',
            borderRadius: '10vw',
            color: '#FFFFFF',
            // boxShadow:
            //   '0px 2px 0px 0px #FFA234, 0px -1px 0px 0px #FFD874 inset',
            height: '11vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4vw',
            textShadow:
              '1.5px 0 0 #167D25, -1.5px 0 0 #167D25, 0 1.5px 0 #167D25, 0 -1.5px 0 #167D25, 1.5px 1.5px #167D25, -1.5px -1.5px 0 #167D25, 1.5px -1.5px 0 #167D25, -1.5px 1.5px 0 #167D25',
          }}
          onClick={() => {
            item?.onClick()
          }}
        >
          {item?.buttonText}
        </div>
      )
    }
  }

  if (isLoading) return null
  return (
    <div className='w-screen h-screen fixed flex flex-col bg-[#F0F1FF] bg-cover bg-no-repeat bg-center overflow-y-scroll'>
      <GrapeEventPageHeader />
      <div className='relative z-10'>
        {/* 헤더영역 */}
        <img
          src='../dealEvent/familyDealEvent/familyDealHeader.png'
          alt='familyDealHeader'
          className='relative w-[65vw] mt-[10vw] ml-[17.5vw]'
        />
        {/* 우측 상단 레코드 버튼 */}
        {/* 서브헤더영역 */}
        <img
          src='../dealEvent/familyDealEvent/familyDealSubHeader.png'
          alt='familyDealSubHeader'
          className='w-[100vw] mt-[-10vw] ml-[0vw]'
        />
        {/* 보유 하트 영역 디자인 */}
        <div className='bg-[#FFFFFF] w-[45vw] h-[13vw] rounded-[10vw] border-[0.5vw] border-[#10761F] mx-auto mt-[2vw] z-10 absolute ml-[27.5vw] flex justify-center'>
          {/* 하트, 보유하트, 0개  */}
          <div className='flex justify-center items-center flex-row'>
            <img
              src='../dealEvent/familyDealEvent/familyDealCoin.png'
              alt='familyDealCoin'
              className='w-[8vw] mr-[4vw]'
            />
            <div>
              <div className='text-[#10761F] text-[3.5vw] text-center font-bold opacity-70 mb-[-1.5vw] mt-[0.5vw]'>
                보유 하트
              </div>
              <div className='text-[#10761F] text-[5vw] text-center font-bold'>
                {jangbogiCoin}개
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='relative'>
        {/* 핑크 배경 영역 */}
        <img
          src='../dealEvent/familyDealEvent/familyDealRewardBody.png'
          alt='familyDealRewardBody'
          className='absolute w-[100vw] mt-[-60vw] opacity-90 z-[-1]'
        />
        {/* 노란배경영역 */}
        <img
          src='../dealEvent/familyDealEvent/familyDealRewardBodyDetail.png'
          alt='familyDealRewardBodyDetail'
          className='w-[100vw] h-[139vw] absolute top-[-10vw] ml-[0vw]  border-[#10761F]'
        />
        <div className='w-[80vw] grid grid-cols-2 gap-y-[4vw] gap-x-[8vw] absolute top-[21vw] left-[8%] '>
          {exchangeItemCard.map((item, index) => {
            return (
              <div
                key={index}
                className='w-[40vw] flex justify-center items-center flex-col bg-[#FFFFFF] rounded-[3vw]  border-[0.5vw]  border-[#10761F] '
              >
                {/* 아이템 상단 헤드 배경 */}
                <div className=' w-[40vw] bg-[#FCA4B7] text-[#FFFFFF] text-[4.2vw] font-bold border-t-[0.5vw] border-x-[0.5vw] border-[#10761F] flex items-center justify-center px-[9vw] py-[1vw] rounded-tl-[3vw] rounded-tr-[3vw] relative'>
                  <img
                    className='w-[5.5vw] mr-[2vw]'
                    src='../dealEvent/familyDealEvent/familyDealCoin.png'
                    alt='familyDealCoin'
                  />
                  {item.needLuckBag}개{' '}
                </div>
                <div className='flex items-center justify-center '>
                  <img
                    className='w-[19vw] pt-[3vw] pb-[1vw] flex items-center justify-center'
                    src={`../dealEvent/familyDealEvent/display${item?.type}.png`}
                    alt='familyDeal'
                  />
                </div>
                <div className='text-[#6A3615] text-[4vw] pb-[1vw] flex items-center justify-center mt-[-1vw] '>
                  {item.rewardText}
                </div>

                <div
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    background:
                      jangbogiCoin >= item.needLuckBag
                        ? item?.color
                        : '#CDCDCD',

                    borderRadius: '100vw',
                    width: '90%',
                    height: '20%',
                    marginBottom: '2.5vw',
                    padding: '1vw',
                    fontWeight: 'bold',
                    textShadow:
                      jangbogiCoin >= item.needLuckBag
                        ? '1px 0 0 #B30041, -1px 0 0 #B30041, 0 1px 0 #B30041, 0 -1px 0 #B30041, 1px 1px #B30041, -1px -1px 0 #B30041, 1px -1px 0 #B30041, -1px 1px 0 #B30041'
                        : '',
                    fontSize: '4vw',
                    color: '#FFFFFF',
                  }}
                  onClick={() => {
                    if (jangbogiCoin < item.needLuckBag) return
                    if (jangbogiCoin >= item.needLuckBag) {
                      setConfirmModal({
                        type: item.type,
                        rewardText: item.rewardText,
                        needLuckBag: item.needLuckBag,
                      })
                    }
                  }}
                >
                  교환하기
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {new Date() < new Date('2024-05-17T23:59:59') && (
        <>
          <div className='absolute mb-[6vw] border-[#10761F] border-t-[0.62vw] bg-[#FFE3E9] w-[100vw] mx-auto mt-[186vw] pb-[3vw]'>
            <div className='w-[95vw] h-[85vw] mx-auto bg-[#FFFDEF] rounded-[6vw] mt-[6vw] mb-[4vw] pb-[4vw] border-[0.62vw] border-[#10761F] '>
              <div className='mx-auto mt-[1vw] p-[4vw] text-[5.5vw] text-[#10761F] text-center font-bold'>
                가정의달 미션
              </div>

              {missionList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`py-[3vw]  border-[#E5E5E5] border-b-[0.5vw] flex-row flex w-[90vw] justify-between items-center mx-auto ${
                      item?.type === 'purchase' ? 'p-[2vw]' : 'p-[1vw]'
                    }`}
                  >
                    <div className='flex flex-row items-center'>
                      <div className='pr-[3.5vw] pl-[1vw]'>
                        {/* 가정의달 미션 내 좌측 아이콘 */}
                        <img
                          src={item?.img}
                          alt='mission'
                          className='w-[11vw] mt-[1vw]'
                        />
                      </div>
                      <div className='flex flex-col mt-[1vw]'>
                        <div className='text-[#555555] text-[4vw]'>
                          {item?.title}
                        </div>
                        <div className='flex flex-row items-center'>
                          <div>
                            <img
                              className='w-[5vw] mt-[1vw]'
                              src='../dealEvent/familyDealEvent/familyDealCoin.png'
                              alt='FamilyDealCoin'
                            />
                          </div>
                          <div className='text-[3.5vw] text-[#6A3615] ml-[2vw] mt-[1vw]'>
                            {item?.rewardText}
                          </div>
                        </div>
                        <div className='text-[3.5vw] text-[#555555] items-center flex'>
                          {item.desc}
                        </div>
                      </div>
                    </div>
                    <MissionButton item={item} />
                  </div>
                )
              })}
            </div>
          </div>

          {/* 룰렛 가정의 달에서는 안써서 주석 */}
          {/* <div
            style={{
              color: '#6A3615',
              fontSize: '6vw',
              textAlign: 'center',
            }}
          >
            하트 룰렛
            <div
              style={{
                color: '#6A3615',
                fontSize: '5vw',
                textAlign: 'center',
                lineHeight: '8vw',
                marginTop: '4vw',
                marginBottom: '4vw',
              }}
            >
              매일 밤 9시부터 10시까지 <br />
              하트 룰렛을 돌릴 수 있어요.
            </div>
            <div
              style={{
                position: 'relative',
              }}
            >
              <div
                style={{
                  padding: '4vw',
                  marginTop: '6vw',
                  width: '100vw',
                  height: '100vw',
                  transform: isRolling
                    ? `translate(0%, 0%) rotate(${
                        DEGREE_MAP[rouletteRewardNumber - 1]
                      }deg`
                    : `translate(0%, 0%)`,
                  transition: isRolling
                    ? 'all 7s cubic-bezier(1, 0.12, 0, 1) 0s'
                    : '',
                }}
              >
                <img src='/dealEvent/familyDealEvent/coinRoulette.png' alt='' />
              </div>
              <div
                style={{
                  top: '-2vw',
                  position: 'absolute',
                  width: '15vw',
                  left: '42.5%',
                }}
              >
                <img src='/dealEvent/familyDealEvent/pointer.png' alt='' />
              </div>
              <button
                style={{
                  top: '40vw',
                  position: 'absolute',
                  width: '20vw',
                  left: '40%',
                }}
                onClick={() => {
                  if (isRolling) return

                  if (questData?.dealEventRoulette?.length >= 1) {
                    ToastStore.toastOn({
                      type: 'error',
                      message: '이미 오늘 룰렛을 돌렸어요.',
                      duration: 2000,
                    })
                    return
                  }

                  if (
                    new Date().getHours() < 21 ||
                    new Date().getHours() >= 22
                  ) {
                    ToastStore.toastOn({
                      type: 'error',
                      message: '룰렛 이벤트 시간이 아니에요.',
                      duration: 2000,
                    })
                    return
                  }

                  if (!isRolling) {
                    startRoulette()
                  }
                }}
              >
                <img src='/roulette/button.png' alt='' />
              </button>
            </div>
          </div> */}
        </>
      )}

      <div
        style={{
          color: '#6A3615',
          fontSize: '3.8vw',
          lineHeight: '6vw',
          marginTop:
            new Date() < new Date('2024-05-17T23:59:59') ? '220vw' : '130vw',
          marginBottom: '4vw',
          textAlign: 'left',
          background: '#FFE3E9',
          width: '100vw',
          paddingLeft: '8vw',
          paddingTop: '8vw',
          paddingBottom: '20vw',
        }}
      >
        - 이벤트 기간은 2024.04.26 ~ 2024.05.17 이에요.
        <br />- 이벤트 기간 중 미션을 완료하면 하트를 획득할 수 있어요.
        <br />- 하트는 가정의달 상점에서 교환할 수 있어요.
        <br />- 미션은 새벽 4시를 기준으로 초기화 돼요.
        <br />- 가정의달 상점은 2024.05.17 23:59까지 운영돼요.
        <br />- 기간 내 사용하지 않은 하트는 이벤트 종료 후 소멸돼요.
        <br />- 이벤트는 사전고지 없이 변경 또는 종료될 수 있어요.
      </div>
      {/* 모달영역 */}
      {confirmModal && (
        <div
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            background: 'rgba(0,0,0,0.8)',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: 'white',
              color: '#6A3615',
              textAlign: 'center',
              padding: '4vw',
              borderRadius: '4vw',
              width: '70vw',
              fontSize: '4.5vw',
              lineHeight: '6vw',
            }}
          >
            <div
              style={{
                color: '#6A3615',
                fontSize: '6vw',
                marginTop: '2vw',
              }}
            >
              확인
            </div>
            <br />
            하트 {confirmModal?.needLuckBag}개를 사용해
            <br />
            {confirmModal?.rewardText}으로 교환하시겠어요?
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '2vw',
                flexDirection: 'row',
                marginLeft: '6vw',
                marginRight: '6vw',
                marginBottom: '2vw',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '2vw',
                  border: '0.5vw solid #FFC634',
                  color: '#6A3615',
                  padding: '2vw',
                  borderRadius: '4vw',
                  width: '22vw',
                }}
                onClick={() => {
                  setConfirmModal(null)
                }}
              >
                취소
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '2vw',
                  background: '#FFC634',
                  padding: '2vw',
                  borderRadius: '4vw',
                  width: '22vw',
                }}
                onClick={() => {
                  exchangeLuckyBag(confirmModal.type)
                  setConfirmModal(null)
                }}
              >
                확인
              </div>
            </div>
          </div>
        </div>
      )}
      <LoadingIndicator isLoading={isLoading} />
      {ModalStore?.isModalOpen === 'NewYearDealModal' && <NewYearDealModal />}
      {ModalStore?.isModalOpen === 'familyRecordModal' && (
        <DealEventHistoryModal />
      )}
    </div>
  )
})

export default FamilyDealEvent
