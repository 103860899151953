import React from 'react'
import { observer } from 'mobx-react-lite'

const yutResultStyle = {
  width: '75%', // ModalBody의 너비에 맞춤
  height: 'auto', // 이미지의 원래 비율 유지
  display: 'flex',
  position: 'absolute',
  justifyContent: 'center', // 가운데 정렬
  alignItems: 'center', // 세로 중앙 정렬
  overflow: 'hidden', // ModalBody를 넘어가는 이미지는 숨김
}

const YutnoriResult = observer(({ state }) => {
  // 이미지 소스 정의
  const images = {
    0: '/minigame/yutnori/yutStartImage.png', // 초기 상태
    20: '/minigame/yutnori/yutResultDo.png',
    25: '/minigame/yutnori/yutResultGae.png',
    30: 'minigame/yutnori/yutResultGeol.png',
    40: 'minigame/yutnori/yutResultYut.png',
    50: 'minigame/yutnori/yutResultMo.png',
  }

  // 유효한 상태인지 확인
  const isValidState = Object.keys(images).includes(String(state))
  const imageToDisplay = isValidState ? images[state] : null

  return (
    <div style={yutResultStyle}>
      {/* 이미지가 유효할 경우에만 표시 */}
      {imageToDisplay && (
        <img src={imageToDisplay} alt={`Game result ${state}`} />
      )}
    </div>
  )
})

export default YutnoriResult
