import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import dayjs from 'dayjs'
import UserStore from '../../store/UserStore'

const LuckyPiggyInviteList = observer(({ token }) => {
  const [inviteList, setInviteList] = useState([])
  const inviteHistory = async () => {
    const result = await backendApis.luckyPiggyInviteHistory(token)
    if (result?.status === 200) {
      setInviteList(result?.data)
    }
  }

  useEffect(() => {
    inviteHistory()
  }, [token])
  return (
    <div
      style={{
        width: '80%',
        // height: "100vw",
        backgroundColor: 'white',
        borderRadius: '3vw',
        marginLeft: '10vw',
        marginTop: '5vw',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '15vw',
          backgroundColor: '#F8D9A2',
          borderRadius: '2vw 2vw 0vw 0vw',
          marginTop: '5vw',
          fontFamily: 'maplestory',
          color: '#CE4031',
          fontWeight: 'bold',
          fontSize: '6vw',
          textAlign: 'center',
          paddingTop: '4vw',
        }}
      >
        코인 기록
      </div>
      {inviteList.map((item) => {
        return (
          <>
            <div
              style={{
                marginRight: '4vw',
                marginLeft: '4vw',
                marginTop: '2vw',
                marginBottom: '2vw',
              }}
            >
              <div
                style={{
                  zIndex: 1,
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    marginTop: '1vw',
                  }}
                >
                  <img
                    style={{
                      zIndex: 1,
                      width: '9vw',
                      height: '9vw',
                      borderRadius: '100vw',
                    }}
                    src={item?.userImage}
                    onError={(e) => {
                      e.target.src = UserStore?.default_profile_image
                    }}
                  />

                  <div
                    style={{
                      color: '#5F2D0C',
                      zIndex: 1,
                      fontFamily: 'maplestory',
                      fontSize: '4vw',
                      marginLeft: '2vw',
                    }}
                  >
                    {item?.userName?.length > 5 ? (
                      <>{item?.userName.slice(0, 5)}...</>
                    ) : (
                      <>{item?.userName.slice(0, 5)}</>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      zIndex: 1,
                      width: '10vw',
                      height: '10vw',
                    }}
                    src='/luckyPiggy/coinIcon.png'
                  />
                  <div
                    style={{
                      color: '#855C36',
                      zIndex: 1,
                      fontFamily: 'maplestory',
                      fontSize: '4vw',
                    }}
                  >
                    {item?.coin}개
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: '3vw',
                height: '0.2vw',
                backgroundColor: '#D3D7DD',
                flex: 1,
                flexDirection: 'column',
                width: '100%',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            />
          </>
        )
      })}
    </div>
  )
})

export default LuckyPiggyInviteList
