import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://62e3557b245e9acac0d3f32ad13c4ffc@o4504648757608448.ingest.us.sentry.io/4507848350171136',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.001,
  enabled: import.meta.env.PROD,
  environment: import.meta.env.NODE_ENV,

  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
})

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
