import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../store/ModalStore'
import TimeStore from '../../../store/TimeStore'

const FertilizerRouletteModal = observer(
  ({ token, farmData, setFarmData, setShowPuppy, setFertilizerRouletteOn }) => {
    const [inviteButtonPressed, setInviteButtonPressed] = useState(false)

    const [isRolling, setIsRolling] = useState(false)
    const [rewardModal, setRewardModal] = useState(false)
    const [isRolled, setIsRolled] = useState(false)
    const [CTAText, setCTAText] = useState('럭키 비료 룰렛 돌리기')

    const [canClickStartButton, setCanClickStartButton] = useState(true)
    const REWARD_NAME = [
      '고급비료 8개',
      '고급비료 3개',
      '고급비료 5개',
      '고급비료 1개',
    ]
    const REWARD_COUNT = [8, 3, 5, 1]
    const DEGREE_MAP = [
      5445, // 고급비료 8개
      5535, // 고급비료 3개
      5625, // 고급비료 5개
      5715, // 고급비료 1개
    ]

    const sendToPurchaseScreen = () => {
      const purchaseData = {
        enteringComponent: `Alfarm_FertilizerRoulette_${
          REWARD_COUNT[farmData?.fertilizerRouletteData?.rewardNum ?? 0]
        }`,
        text: '럭키 비료 룰렛',
        source:
          'https://assets.ilevit.com/fa14d383-19ac-47d9-be02-b6839b5ad411.png',
        rewardAmount:
          REWARD_COUNT[farmData?.fertilizerRouletteData?.rewardNum ?? 0],
        rewardType: 'fertilizerSet',
        rewardText: `상품을 구매하면 ${
          REWARD_NAME[farmData?.fertilizerRouletteData?.rewardNum ?? 0]
        }를 받아요`,
      }

      window.location.href = `#commonPurchaseReward.${JSON.stringify(
        purchaseData,
      )}`
      ModalStore.setIsModalOpen('basic')
    }

    const startRoulette = async () => {
      setCTAText(`추첨중...`)

      setCanClickStartButton(false)
      setIsRolling(true)
      setIsRolled(true)

      setTimeout(() => {
        setRewardModal(true)
        setIsRolling(false)
        setCTAText(
          `상품 사고 ${
            REWARD_NAME[farmData?.fertilizerRouletteData?.rewardNum ?? 0]
          } 받기`,
        )
      }, 7100)
    }

    return (
      <>
        <div style={styles.background}>
          <div style={styles.modalDiv}>
            <button
              style={styles.closeButton}
              onClick={() => {
                if (!isRolling) {
                  ModalStore.setIsModalOpen('basic')
                }
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>

            <div
              style={{
                color: 'black',
                fontSize: '5vw',
                fontFamily: 'maplestory',
                textAlign: 'center',
                marginBottom: 16,
                // marginTop: "5vw",
                width: '100%',
                lineHeight: '150%',
              }}
            >
              럭키 비료 룰렛 당첨을 축하드려요!
              <br />
              룰렛을 돌리고 비료를 확보해보세요
            </div>

            <div
              style={{
                // marginTop: "10vw",
                // marginLeft: "5%",
                width: '100%',
                // height: "80vw",
                transform:
                  isRolling || isRolled
                    ? `translate(0%, 0%) rotate(${
                        DEGREE_MAP[
                          farmData?.fertilizerRouletteData?.rewardNum ?? 0
                        ]
                      }deg`
                    : `translate(0%, 0%)`,
                transition: isRolling
                  ? 'all 7s cubic-bezier(1, 0.12, 0, 1) 0s'
                  : '',
              }}
            >
              <img src='/fertilizerRoulette/roulette.png' alt='' />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '45vw',
                width: '10vw',
                left: '49.8%',
                transform: 'translateX(-50%)',
              }}
            >
              <img src='/fertilizerRoulette/pointer.png' alt='' />
            </div>
            <div
              style={{
                color: '#402C24',
                fontSize: '5vw',
                fontFamily: 'maplestory',
                textAlign: 'center',
                background: inviteButtonPressed
                  ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                  : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                boxShadow: inviteButtonPressed
                  ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                  : '',
                padding: '5vw',
                borderRadius: '50vw',
                width: '100%',
                // marginTop: "8%",
                marginTop: '4vw',
                marginBottom: 16,
              }}
              onPointerDown={() => {
                if (!isRolling) {
                  setInviteButtonPressed(true)
                }
              }}
              onPointerCancel={() => {
                setInviteButtonPressed(false)
              }}
              onPointerUp={() => {
                setInviteButtonPressed(false)
                if (!isRolled && canClickStartButton) {
                  startRoulette()
                } else if (isRolled && !isRolling) {
                  sendToPurchaseScreen()
                }
              }}
            >
              {CTAText}
            </div>
          </div>

          {rewardModal && (
            <div
              style={{
                flex: 1,
                position: 'fixed',
                left: 0,
                top: 0,
                zIndex: 3000,
                width: '100%',
                height: '100vh',
                backgroundColor: 'rgba(0,0,0,0.8)',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  top: '50%',
                  background: 'white',
                  width: '80%',
                  // height: "55vw",
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  borderRadius: '4vw',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    width: '100%',

                    color: 'black',
                    textAlign: 'center',
                    fontSize: '5vw',
                    marginTop: 16,
                    marginBottom: 8,
                  }}
                >
                  {
                    REWARD_NAME[
                      farmData?.fertilizerRouletteData?.rewardNum ?? 0
                    ]
                  }{' '}
                  당첨!
                </div>
                <img
                  style={{
                    width: '40%',
                    padding: 8,
                    borderRadius: 8,
                    // margin: 8,
                  }}
                  alt=''
                  src={`/fertilizerRoulette/reward${
                    farmData?.fertilizerRouletteData?.rewardNum ?? 0
                  }.png`}
                />
                <button
                  style={{
                    width: '50%',
                    background:
                      'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                    fontFamily: 'maplestory',
                    color: 'black',
                    fontSize: '4vw',
                    borderRadius: '30px',
                    padding: '4vw',
                    marginBottom: 16,
                  }}
                  onClick={() => {
                    setRewardModal(false)
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          )}
        </div>
      </>
    )
  },
)

export default FertilizerRouletteModal

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalDiv: {
    width: '90vw',
    height: '150vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10vw',
    backgroundImage: 'url(../fertilizerRoulette/background.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '28vw',
    paddingBottom: '6vw',
  },
  closeButton: {
    width: '8vw',
    right: '2vw',
    top: '-6vw',
    position: 'absolute',
    zIndex: 999,
  },
}
