import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import WhiteTextTimer from '../WhiteTextTimer'
import ModalStore from '../../store/ModalStore'
import UserStore from '../../store/UserStore'

const OngoingWaterbattleBanner = observer(
  ({
    id,
    battleData,
    battleWaterAmount,
    opponentBattleWaterCount,
    battleClassName,
  }) => {
    const [canGetReward, setCanGetReward] = useState(battleData?.canGetReward)
    return (
      <button
        style={{
          position: 'absolute',
          width: '60vw',
          left: '-12.5vw',
          zIndex: 101,
          top: '-10vw',
        }}
        className={battleClassName}
        onClick={() => {
          ModalStore.setIsModalOpen('waterOneOneBattle')
        }}
      >
        <img
          style={{
            width: '10vw',
            height: '10vw',
            top: '-4vw',
            left: '5vw',
            borderRadius: 50,
            position: 'absolute',
            border: '2px solid #FF4728',
          }}
          alt=''
          src={battleData?.userImage}
          onError={(e) => {
            e.target.src = UserStore?.default_profile_image
          }}
        />
        <img
          style={{
            width: '10vw',
            height: '10vw',
            top: '-4vw',
            right: '5vw',
            borderRadius: 50,
            position: 'absolute',
            border: '2px solid #2FCBBF',
          }}
          alt=''
          src={battleData?.opponentImage}
        />
        <div
          style={{
            zIndex: 201,
            color: 'white',
            fontFamily: 'maplestory',
            position: 'absolute',
            top: '7vw',
            left: '3vw',
            fontSize: '4vw',
          }}
        >
          물주기: {battleWaterAmount}번
        </div>
        <div
          style={{
            zIndex: 2001,
            color: 'white',
            fontFamily: 'maplestory',
            position: 'absolute',
            top: '12vw',
            left: '3vw',
            fontSize: '4vw',
          }}
        >
          {battleData?.waterAmount}g 획득가능
        </div>
        <div
          style={{
            zIndex: 2001,
            color: 'white',
            fontFamily: 'maplestory',
            position: 'absolute',
            top: '7vw',
            right: '3vw',
            fontSize: '4vw',
          }}
        >
          물주기: {opponentBattleWaterCount}번
        </div>
        <div
          style={{
            zIndex: 2001,
            color: 'white',
            fontFamily: 'maplestory',
            position: 'absolute',
            top: '12vw',
            right: '3vw',
            fontSize: '4vw',
          }}
        >
          {battleData?.opponentWaterAmount}g 획득가능
        </div>
        <img alt='' src={'/icon/ongoingWaterBattleBanner.png'} />
        {canGetReward === false ? (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              textAlign: 'center',
              zIndex: 2001,
              bottom: '0.7vw',
              color: '#845D36',
              fontFamily: 'maplestory',
              fontSize: '4vw',
            }}
          >
            <WhiteTextTimer
              onTimeEnd={() => {
                setCanGetReward(true)
              }}
              timeStamp={new Date(battleData?.endedAt)}
              timerMinutes={0}
              color='red'
            />
            , 최대한 많이 물을 주세요!
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              textAlign: 'center',
              zIndex: 2001,
              bottom: '0.7vw',
              color: '#845D36',
              fontFamily: 'maplestory',
              fontSize: '4vw',
            }}
          >
            종료 되었어요! 보상을 받아주세요
          </div>
        )}
      </button>
    )
  },
)

export default OngoingWaterbattleBanner
