import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web'

const LottieGif = ({
  animationData,
  className = '',
  style = {},
  loop = false,
}) => {
  const containerRef = useRef(null)

  useEffect(() => {
    let anim
    if (animationData?.layers?.length) {
      anim = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop,
        autoplay: true,
        animationData,
      })
    }

    return () => {
      if (anim) anim.destroy()
    }
  }, [animationData, loop])

  if (!animationData?.layers?.length) return null

  return <div ref={containerRef} className={className} style={style} />
}

export default LottieGif
