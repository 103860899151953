import moment from 'moment'
import { useRef } from 'react'
import backendApis from './backendApis'

async function AlfarmEventLogger({
  throttleRef,
  locationType,
  locationName,
  eventType,
  eventName,
  data,
  collection,
  duration = 100,
}) {
  const Throttle = (key, duration) => {
    if (throttleRef.current?.[key]) {
      return
    } else {
      // throttleRef.current[key] = true;
      backendApis.recordEventLog({
        locationType,
        locationName,
        eventType,
        eventName,
        data,
        collection,
      })
      setTimeout(() => {
        throttleRef.current[key] = false
      }, duration)
    }
  }

  if (collection === 'UserAlfarmClickLog') {
    Throttle(`$${locationName}_${eventName || 'none'}`, 200)
  } else {
    backendApis.recordEventLog({
      locationType,
      locationName,
      eventType,
      eventName,
      data,
      collection,
    })
  }
}

export default AlfarmEventLogger
