import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import useDebounce from '../useDebounce'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const WaterToFertilizerModal = observer(({ farmData, setFarmData, token }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [showRewardModal, setShowRewardModal] = useState(true)
  const [addFertilizer, setAddFertilizer] = useState(0)
  const [canClick, setCanClick] = useState(true)
  const throttleRef = useRef({})

  const WaterToFertilizerButton = async () => {
    AlfarmEventLogger({
      throttleRef,
      locationType: 'modal',
      locationName: ModalStore.isModalOpen,
      eventType: 'click',
      eventName: 'cta',
      data: {},
      collection: 'UserAlfarmClickLog',
    })
    if (farmData.water < 1000) {
      ModalStore.setIsModalOpen(null)
      return
    }
    if (!canClick) return
    setCanClick(false)
    const result = await backendApis.waterToFertilizer(token)
    if (result?.data?.success === true) {
      let tmpFarmData = farmData
      tmpFarmData.water -= 1000
      tmpFarmData.fertilizer += 5
      setFarmData(tmpFarmData)
      setAddFertilizer(5)
      setShowRewardModal(true)
    }
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 900,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '80%',
            left: '10%',
            zIndex: 2001,
            top: '55vw',
          }}
        >
          <img alt='' src={'/icon/waterToFertModal.png'} />
          <button
            style={{
              background: isButtonPressed ? '93CB4E' : '#93CB4E',
              boxShadow: isButtonPressed
                ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                : '',
              flex: 1,
              position: 'absolute',
              left: '10%',
              bottom: '10vw',
              zIndex: 2,
              borderRadius: 5,
              width: '80%',
              height: 50,
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
            onClick={() => {
              WaterToFertilizerButton()
            }}
          >
            <div
              style={{
                color: 'white',
                fontFamily: 'maplestory',
                fontSize: '5vw',
              }}
            >
              비료 5개로 바꾸기
            </div>
          </button>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              textAlign: 'center',
              zIndex: 2001,
              bottom: '3vw',
              color: '#6D442E',
              fontSize: '4vw',
            }}
          >
            하루에 한번만 가능해요!
          </div>
        </div>

        <button
          style={{
            width: '8vw',
            right: '10vw',
            top: '40vw',
            position: 'absolute',
            zIndex: 999,
            borderRadius: '1px solid black',
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      </div>
      {showRewardModal && addFertilizer === 5 && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '40%',
            }}
          >
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/rewardFertilizer5.png'}
            />
            <button
              style={{
                width: '35%',
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: 'black',
                fontSize: '16px',
                borderRadius: '30px',
                padding: 16,
                position: 'absolute',

                left: '32%',
                top: '33vw',
              }}
              onClick={() => {
                //   setShowRewardModal(false);
                ModalStore.setIsModalOpen('basic')
              }}
            >
              확인
            </button>
          </div>
        </div>
      )}
    </>
  )
})

export default WaterToFertilizerModal
