import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import toast, { Toaster } from 'react-hot-toast'
import ModalStore from '../../store/ModalStore'

const FlappyBirdEventModal = observer(({ setShowModal }) => {
  const toastBlock = useRef()

  useEffect(() => {
    toastBlock.current = false
  }, [])
  let winner10K = [
    {
      _id: {
        $oid: '639e71f5c5532f383e17700f',
      },
      flappyBirdAllTimeBestScore: 66,
      userResult: {
        userName: '김경섭',
        phoneNumber: '01094238756',
      },
    },
    {
      _id: {
        $oid: '639047b87bff701dbb68e220',
      },
      flappyBirdAllTimeBestScore: 96,
      userResult: {
        userName: '장준호',
        phoneNumber: '01051358583',
      },
    },
    {
      _id: {
        $oid: '63894da3b4152b55eb1f274d',
      },
      flappyBirdAllTimeBestScore: 61,
      userResult: {
        userName: '유나영',
        phoneNumber: '01048596402',
      },
    },
    {
      _id: {
        $oid: '639a43d61df69395048bb8c8',
      },
      flappyBirdAllTimeBestScore: 95,
      userResult: {
        userName: '강애란',
        phoneNumber: '01050658023',
      },
    },
    {
      _id: {
        $oid: '63990c5086a19c5b40428c1e',
      },
      flappyBirdAllTimeBestScore: 51,
      userResult: {
        userName: '수기',
        phoneNumber: '01095139093',
      },
    },
    {
      _id: {
        $oid: '637a07fa2ef87f6052f5c634',
      },
      flappyBirdAllTimeBestScore: 172,
      userResult: {
        userName: '주은숙',
        phoneNumber: '01026476198',
      },
    },
    {
      _id: {
        $oid: '637f5dabfea07b01238aac0f',
      },
      flappyBirdAllTimeBestScore: 50,
      userResult: {
        userName: '문귀동',
        phoneNumber: '01080259555',
      },
    },
    {
      _id: {
        $oid: '639e5d8b6151a50baf483d40',
      },
      flappyBirdAllTimeBestScore: 61,
      userResult: {
        userName: '이수진',
        phoneNumber: '01047409544',
      },
    },
    {
      _id: {
        $oid: '639b0fe0d571924afe52654d',
      },
      flappyBirdAllTimeBestScore: 51,
      userResult: {
        userName: '최제민',
        phoneNumber: '01071032125',
      },
    },
    {
      _id: {
        $oid: '6391a11745cfed3a2bd3a81c',
      },
      flappyBirdAllTimeBestScore: 219,
      userResult: {
        userName: '☆',
        phoneNumber: '01031255121',
      },
    },
    {
      _id: {
        $oid: '636ccd234b8941410794f8ae',
      },
      flappyBirdAllTimeBestScore: 55,
      userResult: {
        userName: '세인',
        phoneNumber: '01086971373',
      },
    },
    {
      _id: {
        $oid: '63a27436a5897707c8bff433',
      },
      flappyBirdAllTimeBestScore: 56,
      userResult: {
        userName: '.방대박처럼',
        phoneNumber: '01089830044',
      },
    },
    {
      _id: {
        $oid: '63a125c73ca1c24ba243cc68',
      },
      flappyBirdAllTimeBestScore: 66,
      userResult: {
        userName: '성민',
        phoneNumber: '01090862532',
      },
    },
    {
      _id: {
        $oid: '63981a129778983bdbefa790',
      },
      flappyBirdAllTimeBestScore: 306,
      userResult: {
        userName: '김준수',
        phoneNumber: '01080301208',
      },
    },
    {
      _id: {
        $oid: '638b2cbd4008564786b39e56',
      },
      flappyBirdAllTimeBestScore: 58,
      userResult: {
        userName: '솔잎',
        phoneNumber: '01055399835',
      },
    },
    {
      _id: {
        $oid: '63710e640c817c67462cc973',
      },
      flappyBirdAllTimeBestScore: 62,
      userResult: {
        userName: '👩‍🌾성경희👨‍🌾',
        phoneNumber: '01041617569',
      },
    },
    {
      _id: {
        $oid: '6377ae061416c59d9ef9f957',
      },
      flappyBirdAllTimeBestScore: 240,
      userResult: {
        userName: 'ㅁㄴㅇ',
        phoneNumber: '01075563208',
      },
    },
    {
      _id: {
        $oid: '639c574ddff005221d40c388',
      },
      flappyBirdAllTimeBestScore: 109,
      userResult: {
        userName: '🐯',
        phoneNumber: '01025950427',
      },
    },
    {
      _id: {
        $oid: '6385eae6b3749a172a1233b0',
      },
      flappyBirdAllTimeBestScore: 97,
      userResult: {
        userName: '김동진',
        phoneNumber: '01087098544',
      },
    },
    {
      _id: {
        $oid: '6399aca96df44f615d509118',
      },
      flappyBirdAllTimeBestScore: 69,
      userResult: {
        userName: '김희경',
        phoneNumber: '01099451828',
      },
    },
    {
      _id: {
        $oid: '638981ac6ba9e7275f525db6',
      },
      flappyBirdAllTimeBestScore: 90,
      userResult: {
        userName: '박민정',
        phoneNumber: '01027803252',
      },
    },
    {
      _id: {
        $oid: '638cc7001328c31735110019',
      },
      flappyBirdAllTimeBestScore: 61,
      userResult: {
        userName: '최성은',
        phoneNumber: '01042238446',
      },
    },
    {
      _id: {
        $oid: '6386208762f20c05abfb77d9',
      },
      flappyBirdAllTimeBestScore: 114,
      userResult: {
        userName: '날유',
        phoneNumber: '01024191767',
      },
    },
    {
      _id: {
        $oid: '6383e24960d2465604f546ad',
      },
      flappyBirdAllTimeBestScore: 105,
      userResult: {
        userName: '장동엽',
        phoneNumber: '01098830191',
      },
    },
    {
      _id: {
        $oid: '63970b53ed98a639079cf39f',
      },
      flappyBirdAllTimeBestScore: 250,
      userResult: {
        userName: '정설아',
        phoneNumber: '01077611485',
      },
    },
    {
      _id: {
        $oid: '6395de2ae3d6c25a184081b1',
      },
      flappyBirdAllTimeBestScore: 78,
      userResult: {
        userName: '오은혜',
        phoneNumber: '01033775936',
      },
    },
    {
      _id: {
        $oid: '6398736b1af3d93141a62b33',
      },
      flappyBirdAllTimeBestScore: 73,
      userResult: {
        userName: '황인석',
        phoneNumber: '01047555078',
      },
    },
    {
      _id: {
        $oid: '63915d405f61f8706f9e79e5',
      },
      flappyBirdAllTimeBestScore: 164,
      userResult: {
        userName: '윤중열',
        phoneNumber: '01099032906',
      },
    },
    {
      _id: {
        $oid: '63a6c8cf294f5da4441617af',
      },
      flappyBirdAllTimeBestScore: 65,
      userResult: {
        userName: '서진호',
        phoneNumber: '01029425300',
      },
    },
    {
      _id: {
        $oid: '63a7d07c669e1852d348f5dd',
      },
      flappyBirdAllTimeBestScore: 69,
      userResult: {
        userName: 'kbl46500',
        phoneNumber: '01032614650',
      },
    },
    {
      _id: {
        $oid: '638ec43525dd4967566998a2',
      },
      flappyBirdAllTimeBestScore: 91,
      userResult: {
        userName: '닉네임을 등록해주세요',
        phoneNumber: '01066169838',
      },
    },
    {
      _id: {
        $oid: '63998aa8a89c221c2da22a72',
      },
      flappyBirdAllTimeBestScore: 62,
      userResult: {
        userName: '동하뉘쥬노알랍~😘💕',
        phoneNumber: '01048174946',
      },
    },
    {
      _id: {
        $oid: '637f831966f39d1b4efc1667',
      },
      flappyBirdAllTimeBestScore: 54,
      userResult: {
        userName: '박인서',
        phoneNumber: '01045206854',
      },
    },
    {
      _id: {
        $oid: '6386d10c13d635063bff6ed1',
      },
      flappyBirdAllTimeBestScore: 64,
      userResult: {
        userName: '윰',
        phoneNumber: '01075334958',
      },
    },
    {
      _id: {
        $oid: '63725e69deaffc4facfd6476',
      },
      flappyBirdAllTimeBestScore: 172,
      userResult: {
        userName: '유주상',
        phoneNumber: '01089229446',
      },
    },
    {
      _id: {
        $oid: '63918237b7fb9b93afc49174',
      },
      flappyBirdAllTimeBestScore: 94,
      userResult: {
        userName: '유정화',
        phoneNumber: '01071922842',
      },
    },
    {
      _id: {
        $oid: '63a7b21320eaf1459be4aae1',
      },
      flappyBirdAllTimeBestScore: 63,
      userResult: {
        userName: '쩡이',
        phoneNumber: '01036241954',
      },
    },
    {
      _id: {
        $oid: '638224a201ebfe7f4f2760c8',
      },
      flappyBirdAllTimeBestScore: 62,
      userResult: {
        userName: '김나나',
        phoneNumber: '01048590108',
      },
    },
    {
      _id: {
        $oid: '6399423fad832e5a6ff050c2',
      },
      flappyBirdAllTimeBestScore: 52,
      userResult: {
        userName: '♡윤똘♡',
        phoneNumber: '01048790017',
      },
    },
    {
      _id: {
        $oid: '638f17e826ef5306cc426947',
      },
      flappyBirdAllTimeBestScore: 305,
      userResult: {
        userName: '김경래',
        phoneNumber: '01064101799',
      },
    },
    {
      _id: {
        $oid: '639e9b39d760b85b6e43e655',
      },
      flappyBirdAllTimeBestScore: 66,
      userResult: {
        userName: '최조은',
        phoneNumber: '01066286520',
      },
    },
    {
      _id: {
        $oid: '6387294474125036e60963d6',
      },
      flappyBirdAllTimeBestScore: 67,
      userResult: {
        userName: '배수연',
        phoneNumber: '01075234337',
      },
    },
    {
      _id: {
        $oid: '6381c730be2a1f67a7fdedd6',
      },
      flappyBirdAllTimeBestScore: 58,
      userResult: {
        userName: '쩡미여사',
        phoneNumber: '01084113006',
      },
    },
    {
      _id: {
        $oid: '637f4bc84ffb7612bb57bd1f',
      },
      flappyBirdAllTimeBestScore: 56,
      userResult: {
        userName: '김수민',
        phoneNumber: '01035390518',
      },
    },
    {
      _id: {
        $oid: '63872225b7ed551f69a144b1',
      },
      flappyBirdAllTimeBestScore: 59,
      userResult: {
        userName: '햇빛♥',
        phoneNumber: '01062160607',
      },
    },
    {
      _id: {
        $oid: '639e7247705dc43103310868',
      },
      flappyBirdAllTimeBestScore: 106,
      userResult: {
        userName: '이현영',
        phoneNumber: '01029402811',
      },
    },
    {
      _id: {
        $oid: '638692c6a7b4ca2e088f7fb7',
      },
      flappyBirdAllTimeBestScore: 63,
      userResult: {
        userName: '살구',
        phoneNumber: '01052741804',
      },
    },
    {
      _id: {
        $oid: '639ec64264c8a48bb5d787b8',
      },
      flappyBirdAllTimeBestScore: 59,
      userResult: {
        userName: '이주헌',
        phoneNumber: '01092653670',
      },
    },
    {
      _id: {
        $oid: '637cf302ffc5060c0ab500a6',
      },
      flappyBirdAllTimeBestScore: 124,
      userResult: {
        userName: '김*수',
        phoneNumber: '01062186214',
      },
    },
    {
      _id: {
        $oid: '63a60143a59c865a0bafe124',
      },
      flappyBirdAllTimeBestScore: 63,
      userResult: {
        userName: '최규현',
        phoneNumber: '01088905863',
      },
    },
  ]
  let winner50K = [
    {
      _id: {
        $oid: '639a7ca1706aea4190451879',
      },
      flappyBirdAllTimeBestScore: 130,
      userResult: {
        userName: '손성배',
        phoneNumber: '01036423980',
      },
    },
    {
      _id: {
        $oid: '6382b9eea22ac210ebc1ce30',
      },
      flappyBirdAllTimeBestScore: 120,
      userResult: {
        userName: '장승훈',
        phoneNumber: '01092849295',
      },
    },
    {
      _id: {
        $oid: '63a439f6ac4d5d5156a125e2',
      },
      flappyBirdAllTimeBestScore: 225,
      userResult: {
        userName: '안뇽',
        phoneNumber: '01050479535',
      },
    },
    {
      _id: {
        $oid: '638c8ca8accab439d7459e27',
      },
      flappyBirdAllTimeBestScore: 100,
      userResult: {
        userName: '정준용',
        phoneNumber: '01068524692',
      },
    },
    {
      _id: {
        $oid: '6393d4781704c0adfceaf883',
      },
      flappyBirdAllTimeBestScore: 105,
      userResult: {
        userName: '이한맘',
        phoneNumber: '01064436128',
      },
    },
    {
      _id: {
        $oid: '63744ea07c09231ffd8705ca',
      },
      flappyBirdAllTimeBestScore: 171,
      userResult: {
        userName: '성유진',
        phoneNumber: '01041664502',
      },
    },
    {
      _id: {
        $oid: '63a0137b502783db3e708f1a',
      },
      flappyBirdAllTimeBestScore: 182,
      userResult: {
        userName: '이수호',
        phoneNumber: '01098135400',
      },
    },
    {
      _id: {
        $oid: '638b19a521d4b3211681a6b6',
      },
      flappyBirdAllTimeBestScore: 122,
      userResult: {
        userName: '정재희',
        phoneNumber: '01068103305',
      },
    },
    {
      _id: {
        $oid: '637ca5223fac995b722398a5',
      },
      flappyBirdAllTimeBestScore: 238,
      userResult: {
        userName: '김찬우',
        phoneNumber: '01034579208',
      },
    },
    {
      _id: {
        $oid: '638d6d29abca3949e4c530fa',
      },
      flappyBirdAllTimeBestScore: 100,
      userResult: {
        userName: 'yey',
        phoneNumber: '01095114237',
      },
    },
  ]

  const winner = (name, number, score, i) => {
    let tempName = name.slice(0, 1)
    for (let i = 0; i < Math.min(name.length - 1, 4); i++) {
      tempName += '*'
    }
    let tempNumber = number.slice(-4)

    return (
      <>
        <div
          style={{ color: '#777', display: 'inline' }}
        >{`${i}번 ${tempName}님 (010-****-${tempNumber}) [${score}점]`}</div>
        <br />
      </>
    )
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 900,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90vw',
            height: '170vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'column',
            // border: "1px solid red",
            padding: 16,
            backgroundImage: 'url(../flappyBirdImg/eventModalBackground.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            // paddingTop: "53vw",
            // paddingBottom: "18%",
            paddingTop: '40vw',
            paddingBottom: '16px',
            paddingLeft: 24,
            paddingRight: 24,
            // marginBottom: "15%",
          }}
        >
          <button
            style={{
              width: '8vw',
              right: 0,
              top: '10vw',
              position: 'absolute',
              zIndex: 999,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            style={{
              // border: "1px solid black",
              width: '100%',
              height: '100%',
              // marginBottom: "12vw",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                color: 'black',
                fontFamily: 'Maplestory',
                // textAlign: "center",
                lineHeight: '1.4rem',
                marginTop: 8,
                // border: "1px solid black",
                padding: 8,
                textAlign: 'left',
                overflowY: 'scroll',
              }}
            >
              <div style={{ color: 'black', textAlign: 'center' }}>
                <b style={{ color: 'black', fontSize: '1.2rem' }}>
                  🎉 오픈 이벤트 당첨자 발표🎉
                </b>
                <br />
                <br />
                안녕하세요 농부님들, <br />
                농부님들의 뜨거운 관심과 사랑속에서 <br />
                플라잉팜의 오픈 이벤트가 <br />
                성황리에 종료되었어요!
                <br />
                <br />
                당첨이 되신 분들은 진심으로 축하드려요
                <br /> 다음에는 더 재밌고 알찬 이벤트로 <br />
                찾아뵐게요 <br /> <br />
                우리 귀여운 플라잉팜 계속해서 <br />
                많은 사랑과 관심 부탁드려요
                <br />
                <br />
                이제 여러분이 궁금해하시는 <br />
                행운의 주인공들을 공개할게요!
              </div>

              <br />
              <div style={{ color: '#777' }}>
                <b style={{ color: 'black', fontSize: '1.2rem' }}>
                  당첨 결과🎉
                </b>
                <br />
                <br />
                <b style={{ color: 'black' }}>고급비료 3개</b>
                <br />
                200점 넘으신분들께 전부 지급
                <br />
                <br />
                <b style={{ color: 'black' }}>
                  신세계 상품권 10만원권 당첨자 [1명]
                </b>
                <br />
                {winner('신동현', '01086987928', 329, 1)}
                <br />
                <br />
                <b style={{ color: 'black' }}>
                  신세계 상품권 5만원권 당첨자 [10명]
                </b>
                <br />
                {winner50K.map((e, i) =>
                  winner(
                    e.userResult.userName,
                    e.userResult.phoneNumber,
                    e.flappyBirdAllTimeBestScore,
                    i + 1,
                  ),
                )}
                <br />
                <br />
                <b style={{ color: 'black' }}>
                  신세계 상품권 1만원권 당첨자 [50명]
                </b>
                <br />
                {winner10K.map((e, i) =>
                  winner(
                    e.userResult.userName,
                    e.userResult.phoneNumber,
                    e.flappyBirdAllTimeBestScore,
                    i + 1,
                  ),
                )}
              </div>
            </div>

            <div
              style={{
                background:
                  'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                width: '60vw',
                height: '14vw',
                borderRadius: 99,
                color: 'black',
                fontFamily: 'Maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 8,
                marginTop: 16,
                padding: 16,
              }}
              onClick={() => ModalStore.setIsModalOpen('basic')}
            >
              {`닫기`}
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default FlappyBirdEventModal
