import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import PlantBasicInfo from '../molecules/PlantBasicInfo'
import { farmingListOngoing } from '../../config'
import ModalStore from '../../store/ModalStore'
import UserStore from 'store/UserStore'

const PlantButton = ({
  plantName,
  setSelectedPlant,
  setImageSource,
  selectedPlant,
  setButtonText,
}) => {
  let chosenImageSource = `/badge/${plantName.split('_')[0]}.png`
  let weight = '2kg'
  weight = `${PlantBasicInfo?.PlantNameAndAmount(plantName)?.koreanName} ${
    PlantBasicInfo?.PlantNameAndAmount(plantName)?.amount
  }`

  if (plantName === 'empty') {
    return (
      <div
        key={plantName}
        style={{
          flex: '1 0 35%',
          border: '4px solid #EDEDED',
          margin: '4px',
          borderRadius: '8px',
        }}
        onClick={() => {
          setSelectedPlant(plantName)
          setImageSource(chosenImageSource)
        }}
      />
    )
  }

  return (
    <div
      className='relative'
      key={plantName}
      style={
        selectedPlant === plantName
          ? {
              flex: '1 0 35%',
              border: '4px solid rgba(255,164,0,1)',
              margin: '4px ',
              borderRadius: '8px',
            }
          : {
              flex: '1 0 35%',
              border: '4px solid #EDEDED',
              margin: '4px',
              borderRadius: '8px',
            }
      }
      onClick={() => {
        setSelectedPlant(plantName)
        setImageSource(chosenImageSource)
        setButtonText(
          `${PlantBasicInfo?.PlantNameAndAmount(plantName)?.koreanName} 키우기`,
        )
      }}
    >
      <div className='text-[#000] flex flex-col justify-center items-center mt-[2vw]'>
        <img className='w-[80%] bg-white' src={chosenImageSource} alt='' />
      </div>
      <div className='text-[#000] text-center mb-[2vw] text-[3.5vw]'>
        {weight}
      </div>
    </div>
  )
}

const TeamAlfarmSelectItemTypeModal = observer(
  ({ token, farmData, setFarmData, itemType, setOpenSelectItemModal }) => {
    const [plantList, setPlantList] = useState([
      'egg_team',
      'apple_team',
      'pineapple_team',
      'sweetpotato_team',
      'potato_team',
      'rice_team',
    ])
    const [buttonText, setButtonText] = useState('작물을 선택해주세요')
    const [selectedPlant, setSelectedPlant] = useState()
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [imageSource, setImageSource] = useState('')
    const [infoModal, setInfoModal] = useState(false)
    const [infoModal2, setInfoModal2] = useState(false)

    async function selectItemType() {
      const result = await backendApis.selectTeamAlfarmItemType({
        itemType: selectedPlant,
        teamId: UserStore?.teamAlfarmData?._id,
      })
      if (result?.status === 200) {
        console.log(result?.data)
        // 작물 데이터 가져오고 팀농장 시작
        UserStore?.set('teamAlfarmData', result?.data)
        setOpenSelectItemModal(false)
      }
    }

    return (
      <div className='fixed w-full h-full z-[2000]'>
        <div className='flex-1 fixed left-0 top-0 z-[1000] w-full h-full bg-black bg-opacity-80' />
        <div className='w-[90vw] h-[130vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-between items-center flex-col px-[2vw] z-[1001] bg-white rounded-2xl'>
          <div
            className='absolute w-[8vw] right-0 -top-[12vw] z-[1005]'
            onClick={() => {
              setOpenSelectItemModal(false)
            }}
          >
            <img src='/icon/modalCloseButton.png' alt='' />
          </div>
          <div className='text-[#403C24] text-[5.5vw] text-center mt-[4vw] mb-[0vw] font-bold'>
            작물 선택
          </div>
          <div className='text-[#403C24] text-[4vw] text-center mt-[1vw] mb-[2vw]'>
            함께 키울 작물을 선택해주세요
          </div>

          <div className='flex-1 flex flex-wrap relative overflow-y-scroll my-[4vw] mx-[3vw]'>
            {plantList
              .filter((i) => i !== itemType)
              .map((plantName) => (
                <PlantButton
                  key={Math.random()}
                  selectedPlant={selectedPlant}
                  setSelectedPlant={setSelectedPlant}
                  setImageSource={setImageSource}
                  plantName={plantName}
                  setButtonText={setButtonText}
                />
              ))}

            {plantList.filter((i) => i !== itemType)?.length % 2 === 1 && (
              <PlantButton
                key={Math.random()}
                selectedPlant={selectedPlant}
                setSelectedPlant={setSelectedPlant}
                setImageSource={setImageSource}
                plantName={'empty'}
                setButtonText={setButtonText}
              />
            )}
          </div>
          <button
            className={`text-[4vw] rounded-full w-[70vw] flex justify-center items-center mb-[4vw] py-[4vw] text-[#000] 
            ${
              selectedPlant
                ? 'bg-gradient-to-r from-[#FFD300] via-[#FFA400] to-[#FFD300] '
                : 'bg-[#999]'
            }
            `}
            onClick={() => {
              if (!selectedPlant) {
                setInfoModal(true)
                setTimeout(() => {
                  setInfoModal(false)
                }, 3000)

                return
              }
              setOpenConfirmModal(true)
            }}
          >
            {buttonText}
          </button>
        </div>

        {openConfirmModal && (
          <div className='absolute top-0 w-full h-full bg-[rgba(0,0,0,0.8)] z-[1011]'>
            <div className='absolute h-[80vw] w-[70vw] rounded-md bg-white flex flex-col justify-between items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
              <div className='flex flex-col justify-center items-center py-[16px]'>
                <div className='text-[#000] flex text-center justify-center items-center font-bold text-[5vw] mx-[4vw]'>
                  해당 작물을 키우시겠어요?
                </div>

                <img
                  className='w-[40vw] bg-white mt-[4vw]'
                  src={imageSource}
                  alt=''
                />
              </div>
              <div className='flex flex-row w-full px-[4vw] gap-x-4 pb-[4vw]'>
                <div
                  className='flex justify-center items-center px-[3vw] py-[3vw] flex-1 border-2 border-[#FFA400] text-[#FFA400] text-[4vw] rounded-[2vw] '
                  onClick={() => setOpenConfirmModal(false)}
                >
                  취소
                </div>
                <div
                  className='flex justify-center items-center px-[3vw] py-[3vw] flex-1 bg-[rgba(255,164,0,1)] border-[rgba(255,164,0,1)] text-[4vw] rounded-[2vw] text-black'
                  onClick={() => {
                    selectItemType()
                  }}
                >
                  키우기
                </div>
              </div>
            </div>
          </div>
        )}
        {infoModal && (
          <div
            style={{
              flex: 1,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              zIndex: 2000,
              width: '80%',
              height: '30vw',
              background: 'rgba(0,0,0,0.8)',
              borderRadius: '4vw',
              fontFamily: 'maplestory',
              fontSize: '4.5vw',
              textAlign: 'center',
              lineHeight: '180%',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            선택된 작물이 없어요 <br />
            작물을 선택해주세요
          </div>
        )}
        {infoModal2 && (
          <div
            style={{
              flex: 1,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              zIndex: 2000,
              width: '80%',
              height: '30vw',
              background: 'rgba(0,0,0,0.8)',
              borderRadius: '4vw',
              fontFamily: 'maplestory',
              fontSize: '4.5vw',
              textAlign: 'center',
              lineHeight: '180%',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            현재 키우고 있는 작물이에요 <br />
            다른 작물을 선택해주세요
          </div>
        )}
      </div>
    )
  },
)

export default TeamAlfarmSelectItemTypeModal
