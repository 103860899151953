import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import WhiteTextTimer from '../WhiteTextTimer'
import ModalStore from '../../store/ModalStore'
import TimeStore from '../..//store/TimeStore'
import dayjs from 'dayjs'
import { useInterval } from 'react-use'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(weekOfYear)

const bingoBoard = [
  { category: '가전', categoryId: 8 },
  { category: '주방', categoryId: 4 },
  { category: '리빙', categoryId: 9 },
  { category: '생활', categoryId: 5 },
  { category: '식품', categoryId: 3 },
  { category: '패션', categoryId: 0 },
  { category: '육아', categoryId: 2 },
  { category: '건강', categoryId: 6 },
  { category: '뷰티', categoryId: 1 },
]

const instructionList = [
  '각 빙고칸의 카테고리 상품을 구매할 때마다 구매 보상인 고급비료 3개를 받아요',
  '빙고가 완성되면 자동으로 빙고 보상인 고급비료 5개를 받아요',
  '빙고는 가로 3개, 세로 3개, 총 6개까지 가능해요',
  '주간 빙고는 매주 일요일에 초기화돼요',
  '빙고칸을 클릭한뒤 상품을 구매해야만 빙고칸이 채워져요',
]

const WeeklyBingoPurchaseModal = observer(({ farmData }) => {
  const [bingoBoardType, setBingoBoardType] = useState(1)
  const [images, setImages] = useState([
    `../weeklyBingoPurchase/bingoBoard1.png`,
    `../weeklyBingoPurchase/bingoBoard2.png`,
  ])
  const [instructionButtonPressed, setInstructionButtonPressed] =
    useState(false)

  useEffect(() => {
    images.map((item) => {
      const img = new Image()
      img.src = item
    })
  }, [])

  const Card = (item) => {
    const [isChecked, setIsChecked] = useState(false)
    const [pressed, setPressed] = useState(false)

    useEffect(() => {
      let lastPurchaseAt = dayjs(
        farmData?.weeklyBingoPurchaseInfo?.purchaseHistory?.[
          item?.categoryId
        ] ?? '2022-01-01',
      )

      let now = TimeStore.currentDayJS
      setIsChecked(now.week() === lastPurchaseAt.week())
    }, [farmData])

    if (isChecked) {
      return (
        <div key={Math.random()} style={styles.checkedCard}>
          <div style={{ fontSize: '4vw', marginTop: '-6vw' }}>
            {item.category}
          </div>
          <div
            style={{
              backgroundImage: 'url(../weeklyBingoPurchase/checkMark.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '9vw',
              height: '9vw',
              position: 'absolute',
              bottom: '1.5vw',
              // right: 4,
            }}
          />
        </div>
      )
    }

    return (
      <div
        key={Math.random()}
        style={{
          ...styles.card,
          boxShadow: pressed
            ? 'inset 0px 2px 4px rgba(0,0,0,0.5)'
            : '0px 2px 4px rgba(0,0,0,0.5)',
        }}
        onPointerDown={() => setPressed(true)}
        onPointerCancel={() => setPressed(false)}
        onPointerUp={() => {
          setPressed(false)
          const purchaseData = {
            enteringComponent: `Alfarm_weeklyBingoPurchase_${item.categoryId}`,
            text: '올팜 주간 빙고 보상',
            source:
              'https://assets.ilevit.com/0531e3e5-7bc7-4adc-a317-b3e4971b56d4.png',
            rewardAmount: 1,
            rewardType: '고급비료 3개',
            rewardText:
              '물건 사면 고급비료 3개, 빙고 완성하면 추가 고급비료 5개까지!',
            categoryId: item.categoryId,
          }

          window.location.href = `#commonPurchaseReward.${JSON.stringify(
            purchaseData,
          )}`
        }}
      >
        <div style={{ fontSize: '4vw', color: '#656199', marginTop: '-6vw' }}>
          {item.category}
        </div>
        <div
          style={{
            backgroundImage:
              'url(../weeklyBingoPurchase/fertilizerSetThree.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '7vw',
            height: '7vw',
            position: 'absolute',
            bottom: '1.5vw',
            // right: 4,
          }}
        />
      </div>
    )
  }

  const InstructionText = ({ text }) => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          marginBottom: 4,
        }}
      >
        <div style={{ color: 'black', lineHeight: '6vw', marginRight: 8 }}>
          *
        </div>
        <div
          style={{
            color: 'black',
            lineHeight: '6vw',
            wordWrap: 'break-word',
            wordBreak: 'keep-all',
            fontSize: '3.5vw',
          }}
        >
          {text}
        </div>
      </div>
    )
  }

  // useInterval(() => {
  //   setBingoBoardType((prev) => {
  //     return prev === 1 ? 2 : 1;
  //   });
  // }, 1000);

  useEffect(() => {}, [])

  return (
    <>
      <div style={styles.background}>
        <div style={styles.modalDiv}>
          <button
            style={{
              width: '8vw',
              right: '2vw',
              top: '-6vw',
              position: 'absolute',
              zIndex: 999,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            style={{
              width: '100%',
              // border: "10px solid black",
              // flex: 1,
              color: 'black',
              wordWrap: 'break-word',
              wordBreak: 'keep-all',
              marginBottom: 8,
              // backgroundColor: "white",
              borderRadius: 99,
              paddingTop: 4,
              paddingBottom: 4,
              textAlign: 'center',
            }}
          >
            빙고를 만들어서{' '}
            <strong
              style={{
                color: '#339756',
                fontSize: '5vw',
              }}
            >
              고급비료
            </strong>
            를 받아보세요!
            <br />
            빙고칸을 클릭하여 상품을 구매하면 빙고를 만들 수 있어요
          </div>
          <div
            style={{
              // color: "black",
              // backgroundColor: "green",
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              // paddingBottom: 4,
              // borderBottom: "0.5px solid #444",
              backgroundColor: '#F6F6F6',
              // border: "2px solid #FFC7C7",
              padding: 16,
              paddingBottom: 8,
              paddingTop: 8,
              borderRadius: 8,
              marginTop: 8,
              marginBottom: 24,
              boxShadow: instructionButtonPressed
                ? 'inset 0px 2px 4px rgba(0,0,0,0.5)'
                : '0px 2px 4px rgba(0,0,0,0.5)',
              fontWeight: 'bold',
              color: '#8785A2',
            }}
            onPointerDown={() => setInstructionButtonPressed(true)}
            onPointerUp={() => {
              setInstructionButtonPressed(false)
            }}
            onPointerCancel={() => {
              setInstructionButtonPressed(false)
            }}
            onClick={() => ModalStore.setIsLayeredModalOpen(true)}
            className='btn'
          >
            <div
              style={{
                backgroundImage: `url(../weeklyBingoPurchase/fertilizerSet.png)`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '8vw',
                height: '8vw',
                marginRight: 4,
              }}
            />
            빙고 보상 안내 보기
            <div
              style={{
                backgroundImage: `url(../weeklyBingoPurchase/fertilizerSet.png)`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '8vw',
                height: '8vw',
                marginLeft: 4,
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              // height: "70vw",
              backgroundImage: `url(../weeklyBingoPurchase/bingoBoard${bingoBoardType}.png)`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              padding: '6vw',
              paddingTop: '7vw',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
              }}
            >
              {bingoBoard.map((item) => Card(item))}
            </div>
          </div>
        </div>
      </div>
      {ModalStore.isLayeredModalOpen && (
        <div
          style={{ ...styles.background, zIndex: 999 }}
          onClick={() => {
            ModalStore.setIsLayeredModalOpen(false)
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              width: '80%',
              height: '120vw',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              display: 'flex',
              borderRadius: 8,
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: 16,
            }}
          >
            <button
              style={{
                width: '8vw',
                // right: "-2vw",
                right: 0,
                top: '-14vw',
                position: 'absolute',
                zIndex: 999,
              }}
              onClick={() => {
                ModalStore.setIsLayeredModalOpen(false)
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                color: 'black',
                fontWeight: 'bold',
                // marginTop: 16,
                marginBottom: 32,
                paddingBottom: 8,
                borderBottom: '1px solid #999',
              }}
            >
              주간 빙고 보상 안내
            </div>
            <div
              style={{
                border: '2px solid black',
                borderRadius: 8,
                padding: '3vw',
                width: '100%',
                // height: "30vw",
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginBottom: 32,
              }}
            >
              <div
                style={{
                  backgroundImage:
                    'url(../weeklyBingoPurchase/purchaseReward.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  width: '25vw',
                  height: '25vw',
                }}
              />
              <div
                style={{
                  height: '100%',
                  borderLeft: '0.5px solid #999',
                  borderRadius: 99,
                }}
              />
              <div
                style={{
                  backgroundImage:
                    'url(../weeklyBingoPurchase/bingoReward.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  width: '25vw',
                  height: '25vw',
                }}
              />
            </div>
            {instructionList.map((txt) => (
              <InstructionText text={txt} />
            ))}
          </div>
        </div>
      )}
    </>
  )
})

export default WeeklyBingoPurchaseModal

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalDiv: {
    width: '90vw',
    height: '150vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10vw',
    backgroundImage: 'url(../weeklyBingoPurchase/background.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '28vw',
    paddingBottom: '6vw',
  },

  card: {
    width: '18vw',
    height: '18vw',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: '2vw',
    color: '#656199',
    // background:
    //   "linear-gradient(180deg, #F6F6F6 0%, #FFFFFF 91.67%, #DBDBDB 100%)",
    backgroundColor: '#F6F6F6',
    position: 'relative',
  },

  checkedCard: {
    width: '18vw',
    height: '18vw',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: '2vw',
    color: '#656199',
    position: 'relative',
    // background:
    //   "linear-gradient(180deg, #F6F6F6 0%, #FFFFFF 91.67%, #DBDBDB 100%)",
    backgroundColor: '#8785A2',
  },
}
