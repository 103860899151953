import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import backendApis from '../../utils/backendApis'
import Modal from 'react-modal'
import * as Hangul from 'hangul-js'
import commaNumber from 'comma-number'
import ToastStore from 'store/ToastStore'
import ModalStore from 'store/ModalStore'
import { sleep } from 'utils/utils'

const matchSearchTermWithString = (name, phoneNumber, searchTerm) => {
  if (Number(searchTerm)) {
    const numberSearchResult = Hangul.search(
      phoneNumber.replace(/\s/g, '').toUpperCase(),
      searchTerm.replace(/\s/g, '').toUpperCase(),
    )
    return numberSearchResult >= 0
  }
  const nameSearchResult = Hangul.search(
    name.replace(/\s/g, '').toUpperCase(),
    searchTerm.replace(/\s/g, '').toUpperCase(),
  )
  return nameSearchResult >= 0
}

const ContactsList = observer(({ farmData, token, setFarmData }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [infoModal, setInfoModal] = useState(false)
  const [friendsList, setFriendsList] = useState([])
  const [contactData, setContactData] = useState([])

  useEffect(() => {
    let tempData =
      friendsList?.filter((contact) => {
        if (searchTerm?.length > 2) {
          return matchSearchTermWithString(
            contact?.receiverName,
            contact?.receiverPhoneNumber,
            searchTerm,
          )
        }

        return true
      }) || []

    setContactData(tempData)
  }, [friendsList])
  // const contactData =
  // friendsList?.filter((contact) => {
  //   if (searchTerm?.length > 2) {
  //     return matchSearchTermWithString(
  //       contact?.receiverName,
  //       contact?.receiverPhoneNumber,
  //       searchTerm
  //     );
  //   }

  //   return true;
  // }) || [];

  useEffect(() => {
    initializeContactsListInfo()
  }, [])

  const initializeContactsListInfo = async () => {
    let onGoing = true
    let chunk = 0
    while (onGoing) {
      const friendsListResult = await backendApis.getFriendsList(token, 'GET', {
        loadedNum: chunk,
      })

      if (
        friendsListResult?.status === 200 &&
        friendsListResult?.data?.length
      ) {
        setFriendsList((prev) => {
          const phones = prev?.map((each) => each?.receiverPhoneNumber)
          const next = friendsListResult?.data?.filter(
            (each) => !phones.includes(each?.receiverPhoneNumber),
          )
          return [...prev, ...next]
        })
        chunk += 32
        await sleep(3000)
      } else if (friendsListResult?.status === 400) {
        ToastStore.toastOn({
          type: 'error',
          message: '친구 목록을 불러오는데 실패했어요',
          duration: 3000,
        })
      } else {
        onGoing = false
      }
    }
  }

  const CheckReward = async (receiverPhoneNumber, index) => {
    const result = await backendApis.checkInviteReward(token, 'PUT', {
      phoneNumber: receiverPhoneNumber,
    })
    if (result?.data) {
      const contactA = [...contactData]
      const target = contactA[index]
      target.isCheckedReward = true
      target[result?.data?.reward] = result?.data?.addedValue
      setContactData(contactA)
    } else {
      setInfoModal(true)
      setTimeout(() => {
        setInfoModal(false)
      }, 2500)
    }
  }

  const requestGganbu = async (data) => {
    const result = await backendApis.requestGganbu({
      gganbuId: data?.checkAlfarmUserId,
      rewardType: data?.water ? 'water' : 'fertilizerSet',
      rewardAmount: data?.water ? data?.water : data?.fertilizerSet,
      requestType: 'phoneBookGganbu',
      gganbuRequestList: farmData?.gganbuRequestList,
      gganbuList: farmData?.ggnabuList,
    })
    if (result?.data?.status === 200) {
      ToastStore.toastOn({
        type: 'success',
        message: '맞팜 신청이 완료되었어요!',
        duration: 2000,
      })
    } else {
      let message = '맞팜 신청에 실패했어요'
      if (result?.data?.status === 201) {
        message = '이미 서로 맞팜이에요'
      } else if (result?.data?.status === 202) {
        message = '나의 맞팜이 가득 찼어요'
      } else if (result?.data?.status === 203) {
        message = '상대방의 맞팜이 가득 찼어요'
      } else if (result?.data?.status === 204) {
        message = '상대가 이미 맞팜 신청을 했어요'
      } else if (result?.data?.status === 205) {
        message = '내가 이미 맞팜 신청을 했어요'
      } else if (result?.data?.status === 206) {
        message = '맞팜 신청은 최대 3일마다 보낼 수 있어요'
      }
      ToastStore.toastOn({
        type: 'error',
        message,
        duration: 2000,
      })
    }
  }

  const FriendComponent = ({ data, index }) => {
    const {
      isSent,
      receiverName,
      receiverPhoneNumber,
      isCheckedReward,
      water,
      fertilizerSet,
      alreadyGganbu,
      isAlfarmUser,
      isReceived,
    } = data

    const CTAButton = () => {
      if (isReceived) {
        return (
          <button
            type='button'
            className='text-black mr-[1vw] rounded-[2vw] p-[2.5vw] text-[4vw] w-[25vw]'
            onClick={() => {
              CheckReward(receiverPhoneNumber, index)
            }}
          >
            완료
          </button>
        )
      }
      if (!isCheckedReward) {
        return (
          <button
            type='button'
            className='text-white bg-[#ff8808] mr-[1vw] rounded-[2vw] p-[2.5vw] text-[4vw] w-[25vw]'
            onClick={() => {
              CheckReward(receiverPhoneNumber, index)
            }}
          >
            보상 확인
          </button>
        )
      }
      if (isCheckedReward) {
        return (
          <button
            className='text-[#593306] bg-[#F7C82A] mr-[1vw] rounded-[2vw] text-[4.5vw] p-[2vw] w-[100%]'
            type='button'
            onClick={async () => {
              if (isAlfarmUser) {
                requestGganbu(data)
              } else {
                const paramsInfo = {
                  phoneNumber: receiverPhoneNumber,
                  userName: receiverName,
                }
                window.location.href = `#alfarmSendMessage.${JSON.stringify(
                  paramsInfo,
                )}`
              }
            }}
          >
            {water ? (
              <div className='flex flex-row items-center justify-center text-[#593306] text-[4vw]'>
                <img
                  className='w-[5vw]'
                  src={'../phoneBookGganbu/water.png'}
                  alt='abc'
                />
                &nbsp;{`${commaNumber(water)}`}g 받기
              </div>
            ) : (
              <div className='flex flex-row text-[#593306] items-center justify-center'>
                <img
                  className='w-[5vw]'
                  src={'../phoneBookGganbu/fert.png'}
                  alt='abc'
                />
                &nbsp;{fertilizerSet}개 받기
              </div>
            )}
          </button>
        )
      }
    }

    return (
      <div className='flex flex-row p-[2vw] mt-[2vw] border-b-2 border-[#D3D7DD] h-[16vw] '>
        <div className='flex flex-1 flex-col justify-center mr-[1vw]'>
          <div className='flex flex-row'>
            <div className='text-[4vw] text-[#000]'>
              {receiverName.length > 7
                ? `${receiverName.slice(0, 7)}..`
                : receiverName}
            </div>
            &nbsp;
            {isAlfarmUser && (
              <img
                className='w-[13vw] h-[6vw]'
                src={'../phoneBookGganbu/ongoing.png'}
                alt='abc'
              />
            )}
          </div>
          <div className='h-[1vw]' />
          <div className='text-[4vw] text-[#C2C2C2]'>
            {receiverPhoneNumber.length > 12
              ? `${receiverPhoneNumber.slice(0, 12)}...`
              : receiverPhoneNumber}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <CTAButton />
        </div>
      </div>
    )
  }

  return (
    <div className='flex-1 fixed left-0 top-0 z-[5004] w-full h-full bg-[rgba(0,0,0,0.9)]'>
      <div
        className='w-[90vw] h-[140vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute items-center bg-cover bg-center flex-col px-[4vw] pt-[32vw] '
        style={{
          backgroundImage: 'url(../phoneBookGganbu/backgroundNew.png)',
        }}
      >
        <div className='text-[#000] text-[4.5vw] mt-[-4vw]'>
          맞팜을 초대하고 보상을 받으세요!
        </div>
        <div
          style={{
            position: 'relative',
            overflowY: 'scroll',
            width: '100%',
            zIndex: 1999,
            borderRadius: '8vw',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: 'white',
            marginTop: '2vw',
            height: '98vw',
          }}
        >
          {contactData?.map((each, index) => (
            <FriendComponent key={each?._id} data={each} index={index} />
          ))}
        </div>
        <button
          style={{
            width: '10%',
            top: '-8%',
            right: '8%',
            position: 'absolute',
            zIndex: 1113,
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
        <div
          style={{
            border: '1px solid white',
            borderRadius: '4vw',
            width: '50%',
            height: '12vw',
            fontSize: '4.5vw',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '6vw',
          }}
          onClick={async () => {
            ModalStore.setIsModalOpen('basic')
            const deleteContactList = await backendApis.deleteContactList(token)
            if (deleteContactList?.data?.deletedCount > 0) {
              window.location.href = '#alfarmAcceptContacts'
              setTimeout(() => {
                ToastStore.toastOn({
                  type: 'success',
                  message: '연락처 동기화가 완료 되었어요',
                  duration: 3000,
                })
              }, 500)
            }
          }}
        >
          연락처 동기화
        </div>
      </div>

      {infoModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -45%)',

            zIndex: 2000,
            width: '80%',
            height: '30vw',
            background: 'rgba(0,0,0,0.8)',
            borderRadius: 20,
            fontFamily: 'maplestory',
            fontSize: '4vw',
            textAlign: 'center',
            paddingTop: '6vw',
          }}
        >
          오류가 발생했어요
          <div style={{ height: '3vw' }} />
          잠시 후 다시 시도해주세요
          <div style={{ height: '2vw' }} />
        </div>
      )}
    </div>
  )
})

export default ContactsList
