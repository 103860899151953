import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../store/ModalStore'
import BottomSheetStore from 'store/BottomSheetStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const RewardAdModal = observer(
  ({ token, farmData, setFarmData, loadUserCoupon }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const throttleRef = useRef({})

    const CTA = () => {
      return (
        <button
          className='absolute bottom-[2vw] text-[4.5vw] px-[6vw] rounded-3xl py-[4vw] z-[2000] text-[#442B22] mt-[4vw] font-bold mb-[6vw] pulsate-fwd'
          style={{
            background: isButtonPressed
              ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressed
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
            AlfarmEventLogger({
              throttleRef,
              locationType: ModalStore?.isModalOpen,
              locationName: 'rewardAdModalButton',
              eventType: 'click',
              collection: 'UserAlfarmClickLog',
            })
            BottomSheetStore.setIsbottomSheetOpen('basic')
            ModalStore.setIsModalOpen('basic')
            // applovin 광고임. 구글애드 꿀꺽
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'showRewardedAd',
                data: { adType: 'rewarded', placement: "ALFARM_WATER_DAILY_MISSION_REWARD" },
              }),
            )
          }}
        >
          광고보고 물 받기
        </button>
      )
    }
    return (
      <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
        <div
          className='w-[90vw] h-[134vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex absolute justify-between items-center flex-col px-[16px] pt-[28vw] pb-[16px]'
          style={{
            backgroundImage:
              ModalStore?.isModalOpen === 'rewardAdModalWater'
                ? 'url(../modal/rewardAdWaterModal.png)'
                : 'url(../modal/rewardAdModal.png)',
          }}
        >
          <button
            className='z-[1101] w-[8%] -top-[10vw] right-[4vw] absolute'
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
          </button>

          <CTA />
        </div>
      </div>
    )
  },
)

export default RewardAdModal
