import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import backendApis from '../../utils/backendApis'
import Tile from './tile'
import LuckydrawRewardModal from './luckydrawRewardModal'
import commaNumber from 'comma-number'
import ModalStore from '../../store/ModalStore'

const LuckydrawBodyComponent = {
  background:
    'linear-gradient(180deg, #F8F9C1 0%, #FEF3AB 85.3%, #EDCE6D 100%)',
  width: '25.34vw',
  height: '21.96vw',
  position: 'absolute',
  zIndex: 1101,
  borderRadius: '3vw',
}

const Luckydraw = observer(
  ({
    token,
    setOpenLuckydraw,
    farmData,
    setFarmData,
    setLuckydrawPurchaseRewardModal,
    setOpenLuckydrawHistoryModal,
    setUpdateMinigameInfo,
    luckydrawPurchaseReward,
    loadUserCoupon,
  }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const [canPressLuckydraw, setCanPressLuckydraw] = useState(true)
    const [luckydrawRewardType, setLuckydrawRewardType] = useState(0)
    const [currentTileIndex, setCurrentTileIndex] = useState(0)
    const [showLuckydrawRewardModal, setShowLuckydrawRewardModal] =
      useState(false)
    const [drawCount, setDrawCount] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [showDrawCost, setShowDrawCost] = useState(false)
    const [rolling, setRolling] = useState(false)
    const [showFertInfo, setShowFertInfo] = useState(false)

    useEffect(() => {
      if (farmData?.water < 10) {
        setCanPressLuckydraw(false)
      }
    }, [])
    useEffect(() => {
      if (currentTileIndex < luckydrawRewardType - 84) {
        setTimeout(
          () => {
            setCurrentTileIndex(currentTileIndex + 1)
          },
          currentTileIndex * currentTileIndex + 30,
        )
      } else if (luckydrawRewardType > 1) {
        //  룰렛 끝
        setTimeout(() => {
          setShowLuckydrawRewardModal(true)
          setRolling(false)
        }, 500)
      }
    }, [currentTileIndex, luckydrawRewardType])

    useEffect(() => {
      getDrawCount()
    }, [luckydrawRewardType])

    const getDrawCount = async () => {
      const result = await backendApis.getDrawCount(token)
      setDrawCount(result?.drawCount)
      setIsLoading(false)
      setUpdateMinigameInfo(true)
    }

    const startLuckydraw = async () => {
      if (farmData?.water < 10) {
        return
      }
      setRolling(true)
      let tmpFarmData = farmData
      tmpFarmData.water -= 10
      const result = await backendApis.startLuckydraw(token)
      setLuckydrawRewardType(result?.data?.luckydrawRewardType)
      // if(result?.data?.luckydrawRewardType === 102){
      //   setLuckydrawPurchaseRewardOn(true);
      //   setTimeLoadingEndLuckydraw(false);
      // }
    }

    const DrawButton = () => {
      if (farmData?.water < 10) {
        return (
          <button
            style={{
              position: 'absolute',
              width: '25.34vw',
              height: '21.96vw',
              borderRadius: '3vw',
              zIndex: 1101,
              background:
                'linear-gradient(180deg, #F8F9C1 0%, #FEF3AB 85.3%, #EDCE6D 100%)',
              top: '31vw',
              left: '31.75vw',
              fontSize: '5vw',
              fontFamily: 'maplestory',
              textAlign: 'center',
              color: '#AF4A08',
            }}
          >
            물 10g이
            <div
              style={{
                color: '#AF4A08',
              }}
            >
              필요해요
            </div>
          </button>
        )
      } else if (drawCount === 3 && canPressLuckydraw) {
        return (
          <button
            style={{
              position: 'absolute',
              width: '25.34vw',
              height: '21.96vw',
              borderRadius: '3vw',
              zIndex: 1101,
              background:
                'linear-gradient(180deg, #F8F9C1 0%, #FEF3AB 85.3%, #EDCE6D 100%)',
              top: '31vw',
              left: '31.75vw',
              fontSize: '5vw',
              fontFamily: 'maplestory',
              textAlign: 'center',
              color: '#AF4A08',
            }}
          >
            내일 다시
            <div
              style={{
                color: '#AF4A08',
              }}
            >
              가능해요
            </div>
          </button>
        )
      } else if (canPressLuckydraw && !isLoading && farmData?.water > 10) {
        return (
          <button
            style={{
              background: isButtonPressed
                ? 'linear-gradient(180deg, #FCEB08 0%, #FFD314 87.96%, #E19608 100%)'
                : 'linear-gradient(180deg, #FCEB08 0%, #FFD314 87.96%, #E19608 100%)',
              boxShadow: isButtonPressed
                ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                : '',
              position: 'absolute',
              width: '25.34vw',
              height: '21.96vw',
              borderRadius: '3vw',
              zIndex: 1101,
              top: '31.1vw',
              left: '31.75vw',

              fontSize: '8vw',
              fontFamily: 'maplestory',
              textAlign: 'center',
              color: '#AF4A08',
            }}
            // onPointerDown={() => {
            //   setIsButtonPressed(true);
            // }}
            // onPointerCancel={() => {
            //   setIsButtonPressed(false);
            // }}
            // onPointerUp={() => {
            //   setIsButtonPressed(false);
            // }}
            onClick={() => {
              startLuckydraw()
              setCanPressLuckydraw(false)
              setShowDrawCost(true)
            }}
          >
            뽑기
            <div
              style={{
                marginTop: '1vw',
                color: 'black',
                fontSize: '3vw',
              }}
            >
              {3 - drawCount}번 남았어요
            </div>
          </button>
        )
      } else if (!canPressLuckydraw) {
        return (
          <button
            style={{
              position: 'absolute',
              width: '25.34vw',
              height: '21.96vw',
              borderRadius: '3vw',
              zIndex: 1101,
              background:
                'linear-gradient(180deg, #F8F9C1 0%, #FEF3AB 85.3%, #EDCE6D 100%)',
              top: '31vw',
              left: '31.75vw',

              fontSize: '6vw',
              fontFamily: 'maplestory',
              textAlign: 'center',
              color: '#AF4A08',
            }}
          >
            뽑는중
          </button>
        )
      }
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1100,
            width: '100%',
            height: '100vh',
            background: 'rgba(0,0,0,0.8)',
          }}
        />
        <div>
          <div
            style={{
              width: '45%',
              top: '20%',
              left: '27.5%',
              zIndex: 1101,
              position: 'absolute',
            }}
          >
            <img
              style={{
                zIndex: 1101,
              }}
              src='/icon/luckydrawTitle.png'
              alt=''
            />
          </div>
          <div
            style={{
              width: '60vw',
              top: '61vw',
              left: '20vw',
              zIndex: 1101,
              position: 'absolute',
            }}
          >
            <img src='/icon/luckydrawHeader.png' alt='' />
            <div
              style={{
                color: 'white',
                position: 'absolute',
                top: '4.3vw',
                fontFamily: 'maplestory',
                textAlign: 'center',
                fontSize: '5vw',
                width: '100%',
              }}
            >
              물: {commaNumber(farmData?.water)}g
            </div>
          </div>
          <div
            style={{
              width: '90vw',
              top: '72.3vw',
              left: '5vw',
              zIndex: 1101,
              position: 'absolute',
            }}
          >
            <img src='/icon/luckydrawMain.png' alt='' />
            <div
              style={LuckydrawBodyComponent}
              className='luckydraw-tile-101'
            />
            <div>
              <img
                style={{
                  zIndex: 1105,
                  top: '8.75vw',
                  left: '6.3vw',
                  width: '25.34vw',
                  height: '21.96vw',
                  position: 'absolute',
                }}
                src='/icon/tileOneIcon.png'
                alt=''
              />
            </div>
            <div
              style={LuckydrawBodyComponent}
              className='luckydraw-tile-102'
            />
            <div>
              <img
                style={{
                  zIndex: 1105,
                  top: '8.75vw',
                  left: '32.41vw',
                  width: '25.34vw',
                  height: '21.96vw',
                  position: 'absolute',
                }}
                src='/icon/tileTwoIcon.png'
                alt=''
              />
              <button
                onClick={() => {
                  if (!rolling) {
                    setShowFertInfo(true)
                    setTimeout(() => {
                      setShowFertInfo(false)
                    }, 3000)
                  }
                }}
              >
                <img
                  style={{
                    zIndex: 1105,
                    top: '10vw',
                    left: '51vw',
                    width: '4vw',
                    height: '4vw',
                    position: 'absolute',
                  }}
                  src='/icon/fertInformation.png'
                  alt=''
                />
              </button>
            </div>
            <div
              style={LuckydrawBodyComponent}
              className='luckydraw-tile-103'
            />
            <div>
              <img
                style={{
                  zIndex: 1105,
                  top: '8.75vw',
                  left: '58.52vw',
                  width: '25.34vw',
                  height: '21.96vw',
                  position: 'absolute',
                }}
                src='/icon/tileThreeIcon.png'
                alt=''
              />
            </div>
            <div
              style={LuckydrawBodyComponent}
              className='luckydraw-tile-104'
            />
            <div>
              <img
                style={{
                  zIndex: 1105,
                  top: '31.79vw',
                  left: '58.52vw',
                  width: '25.34vw',
                  height: '21.96vw',
                  position: 'absolute',
                }}
                src='/icon/tileFourIcon.png'
                alt=''
              />
            </div>
            <div
              style={LuckydrawBodyComponent}
              className='luckydraw-tile-105'
            />
            <div>
              <img
                style={{
                  zIndex: 1105,
                  top: '54.83vw',
                  left: '58.52vw',
                  width: '25.34vw',
                  height: '21.96vw',
                  position: 'absolute',
                }}
                src='/icon/tileFiveIcon.png'
                alt=''
              />
            </div>
            <div
              style={LuckydrawBodyComponent}
              className='luckydraw-tile-106'
            />
            <div>
              <img
                style={{
                  zIndex: 1105,
                  top: '54.83vw',
                  left: '32.41vw',
                  width: '25.34vw',
                  height: '21.96vw',
                  position: 'absolute',
                }}
                src='/icon/tileSixIcon.png'
                alt=''
              />
            </div>
            <div
              style={LuckydrawBodyComponent}
              className='luckydraw-tile-107'
            />
            <div>
              <img
                style={{
                  zIndex: 1105,
                  top: '54.83vw',
                  left: '6.3vw',
                  width: '25.34vw',
                  height: '21.96vw',
                  position: 'absolute',
                }}
                src='/icon/tileSevenIcon.png'
                alt=''
              />
            </div>
            <div
              style={LuckydrawBodyComponent}
              className='luckydraw-tile-108'
            />
            <div>
              <img
                style={{
                  zIndex: 1105,
                  top: '31.79vw',
                  left: '6.3vw',
                  width: '25.34vw',
                  height: '21.96vw',
                  position: 'absolute',
                }}
                src='/icon/tileEightIcon.png'
                alt=''
              />
            </div>
            <div
              style={LuckydrawBodyComponent}
              className='luckydraw-tile-109'
            />
            <Tile currentTileIndex={currentTileIndex} />
            {/* 스펙 아웃 */}
            {/* <div>
            <img
              style={{
                zIndex: 1-22,
                bottom: "-23vw",
                width: "90vw",
                position: "absolute",
              }}
              src="/icon/luckydrawFooter.png"
              alt=""
            />
          </div> */}
            <DrawButton />
            {/* <button
              style={{
                width: "15vw",
                bottom: "-3vw",
                right: "5vw",
                zIndex: 1101,
                position: "absolute",
                // background: "#D8173A",
                // padding: "3vw",
              }}
              onClick={() => {
                if (!rolling) {
                  setOpenLuckydrawHistoryModal(true);
                  // ModalStore.setIsModalOpen("luckydrawHistory")
                }
              }}
            >
              <img
                style={{
                  zIndex: 1101,
                }}
                src="/icon/resourceHistoryButtonWhite.png"
                alt=""
              />
            </button> */}
          </div>
        </div>
        <button
          style={{
            width: '10%',
            top: '20%',
            right: '8%',
            zIndex: 1101,
            position: 'absolute',
          }}
          onClick={() => {
            if (!rolling) {
              ModalStore.setIsModalOpen('basic')
            }
          }}
        >
          <img
            style={{
              zIndex: 1101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>

        {showLuckydrawRewardModal && (
          <LuckydrawRewardModal
            luckydrawRewardType={luckydrawRewardType}
            setShowLuckydrawRewardModal={setShowLuckydrawRewardModal}
            setLuckydrawRewardType={setLuckydrawRewardType}
            setCurrentTileIndex={setCurrentTileIndex}
            setCanPressLuckydraw={setCanPressLuckydraw}
            setShowDrawCost={setShowDrawCost}
            farmData={farmData}
            setFarmData={setFarmData}
            setLuckydrawPurchaseRewardModal={setLuckydrawPurchaseRewardModal}
            luckydrawPurchaseReward={luckydrawPurchaseReward}
            loadUserCoupon={loadUserCoupon}
          />
        )}
        {showDrawCost && (
          <div
            style={{
              width: '90vw',
              top: '65vw',
              left: '5vw',
              zIndex: 1101,
              position: 'absolute',
            }}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 1211,
                width: '15vw',
                top: '31.1vw',
                left: '44.75vw',
              }}
              className='slide-out-top'
            >
              <img style={{}} src='/icon/luckydrawCost.png' alt='' />
            </div>
          </div>
        )}
        {showFertInfo && (
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: '10%',
              top: '70vw',
              zIndex: 12000,
              width: '80%',
              height: '15vh',
              background: 'rgba(0,0,0,0.8)',
              borderRadius: 20,
              fontFamily: 'maplestory',
              fontSize: '4vw',
              textAlign: 'center',
              paddingTop: 28,
            }}
          >
            비료 바우처는 최대 1개만 가질 수 있어요
            <br></br>
            <br></br>
            바우처를 사용한 후<br></br>
            다시 당첨되면 바우처를 또 사용할 수 있어요
          </div>
        )}
      </>
    )
  },
)

export default Luckydraw
