import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { Oval as Loader } from 'react-loader-spinner'

const LoadingIndicator = observer(({ isLoading }) => {
  return (
    isLoading && (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: '0',
          bottom: '0',
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 99999,
        }}
      >
        <Loader type='Oval' color='#EA3F49' height='40' width='40' />
      </div>
    )
  )
})

export default LoadingIndicator
