import backendApis from '../../utils/backendApis'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import AuthStore from '../../store/AuthStore'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import UserStore from 'store/UserStore'
import ModalStore from 'store/ModalStore'

const HarvestModal = observer(
  ({
    farmData,
    token,
    itemType,
    setHarvestModalVisible,
    setFinishedModalVisible,
    setInviteResultStatus,
    setAlertModal,
    setEnteringRoute,
    userCodePushVersion,
  }) => {
    const codePushVersionAvailable = useCheckCodePushVersion()

    const harvestData = async (link) => {
      let res = await backendApis.finishedFarm(token, 'PUT', {})
      if (res?.status === 200) {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'harvest',
            data: {
              itemPrice: farmData?.itemPrice,
            },
          }),
        )

        window.location.href = `${link}&key=${AuthStore.key}`

        setHarvestModalVisible(false)
        setFinishedModalVisible(true)
      } else {
        setInviteResultStatus('errorAlert')
      }
    }

    const modalClose = async () => {
      // 배 작물 수확 막기

      if (farmData?.itemType === 'pear') {
        ModalStore?.setIsModalOpen('itemEndModal')
        return
      }
      // 수확 전 최신 버전 체크
      if (farmData?.itemPrice) {
        if (
          !codePushVersionAvailable(
            UserStore?.codePushVersionFromAlwayzApp,
            '4.6.36',
          )
        ) {
          setEnteringRoute('newVersionAppForHarvest')
          setAlertModal(true)
          return
        }
      }

      if (!codePushVersionAvailable(userCodePushVersion, '4.0.0')) {
        setEnteringRoute('newVersionAppForHarvest')
        setAlertModal(true)
        return
      }
      // order Data 까서 최근 order 기록으로 분기 치기 위함
      const result = await backendApis.getHarvestOrderData()

      if (result?.status !== 200) {
        // 잠시 후 다시 시도해주세요
        setEnteringRoute('serverError')
        setAlertModal(true)
        return
      }

      if (result?.data?.data >= 1) {
        // 하루 수확량을 초과했어요. 내일 다시 시도 ㄱㄱ
        setEnteringRoute('alreadyHarvested')
        setAlertModal(true)
        return
      }
      if (result?.status === 200) {
        if (itemType === 'coffee_starbucks') {
          harvestData('#openAlfarmCoffeeStarbucks')
          return
        }
        if (itemType === 'coffee_mega') {
          harvestData('#openAlfarmCoffeeMega')
          return
        }
        if (itemType === 'coffee_compose') {
          harvestData('#openAlfarmCoffeeCompose')
          return
        }

        const refinedItemType =
          itemType.charAt(0).toUpperCase() + itemType.slice(1)
        harvestData(`#openAlfarm${refinedItemType}`)
      }
    }

    const HarvestModals = () => {
      // if (itemType === 'apple') {
      //   return (
      //     <>
      //       <div
      //         style={{
      //           flex: 1,
      //           position: 'fixed',
      //           left: 0,
      //           top: 0,
      //           zIndex: 1100,
      //           width: '100%',
      //           height: '100vh',
      //           background: 'rgba(0,0,0,0.8)',
      //         }}
      //       />
      //       <div
      //         style={{
      //           width: '80vw',
      //           top: '50%',
      //           left: '50%',
      //           transform: 'translate(-50%, -50%)',
      //           zIndex: 1101,
      //           position: 'absolute',
      //         }}
      //       >
      //         <img src='/icon/harvestModalApple.png' alt='' />
      //         <button
      //           style={{
      //             flex: 1,
      //             position: 'absolute',
      //             left: '10%',
      //             textAlign: 'center',
      //             zIndex: 12,
      //             borderRadius: '100vw',
      //             width: '35%',
      //             height: 50,
      //             bottom: '7vw',
      //             border: '#FFE99A 1vw solid',
      //           }}
      //           onClick={() => {
      //             modalClose({ appleType: 'red' })
      //           }}
      //         >
      //           <div
      //             style={{
      //               color: '#402C24',
      //               fontFamily: 'maplestory',
      //               fontSize: '4vw',
      //             }}
      //           >
      //             부사 사과
      //           </div>
      //         </button>
      //         <button
      //           style={{
      //             flex: 1,
      //             position: 'absolute',
      //             right: '10%',
      //             textAlign: 'center',
      //             zIndex: 12,
      //             borderRadius: '100vw',
      //             width: '35%',
      //             height: 50,
      //             bottom: '7vw',
      //             background:
      //               'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
      //           }}
      //           onClick={() .=> {
      //             modalClose({ appleType: 'aori' })
      //           }}
      //         >
      //           <div
      //             style={{
      //               color: '#402C24',
      //               fontFamily: 'maplestory',
      //               fontSize: '4vw',
      //             }}
      //           >
      //             아오리 사과
      //           </div>
      //         </button>
      //       </div>
      //     </>
      //   )
      // } else {
      return (
        <>
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 1100,
              width: '100%',
              height: '100vh',
              background: 'rgba(0,0,0,0.8)',
            }}
          />
          <div
            style={{
              width: '80vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1101,
              position: 'absolute',
            }}
          >
            <img src='/icon/harvestModal.png' alt='' />
            <button
              style={{
                flex: 1,
                position: 'absolute',
                left: '10%',
                fontSize: 18,
                textAlign: 'center',
                zIndex: 12,
                borderRadius: 30,
                width: '80%',
                height: 50,
                bottom: '7vw',
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              }}
              onClick={() => {
                modalClose()
              }}
            >
              <div
                style={{
                  color: '#402C24',
                  fontFamily: 'maplestory',
                  fontSize: 18,
                }}
              >
                배송지 입력하기
              </div>
            </button>
          </div>
        </>
      )
    }
    return (
      <>
        <HarvestModals />
      </>
    )
  },
)
export default HarvestModal
