import React, { useState, useEffect } from 'react'
import Timer from './Timer'

const WhiteTextTimer = ({
  timeStamp,
  onTimeEnd = () => {},
  interval = 1000,
  timerMinutes = 60 * 24,
  color = 'white',
  startFromMinutes = false,
  style = {},
  noDays = false,
}) => {
  return (
    <span
      style={{
        // border: "2px solid red",
        color: color,
        ...style,
      }}
    >
      {
        Timer({
          timeStamp,
          onTimeEnd,
          interval,
          timerMinutes,
          startFromMinutes,
          noDays,
        }).formattedTime
      }
    </span>
  )
}

export default WhiteTextTimer
