import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import backendApis from 'utils/backendApis'
import apiHandler from 'utils/apiHandler'
import MotionStore from 'store/MotionStore'
import DecorationItemStore from 'store/DecorationItemStore'

const ChristmasTreeModal = observer(
  ({ token, farmData, setFarmData, isLoading }) => {
    const [inviteIcon, setInviteIcon] = useState([
      '/christmasTree/invite.png',
      '/christmasTree/invite.png',
      '/christmasTree/invite.png',
    ])

    const [clearedTreeQuest, setClearedTreeQuest] = useState(false)
    const decorationItem = DecorationItemStore?.decorationItem

    useEffect(() => {
      const getInviteUser = async () => {
        const result = await backendApis.getChristmasTreeInviteUser(token)

        if (result?.data) {
          const sharedDateLength = result?.data?.sharedDate?.length || 0
          if (decorationItem?.christmasTree !== null) {
            if (sharedDateLength === 0) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/invite.png',
                '/christmasTree/invite.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength === 1) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/invite.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength === 2) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/clear.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength >= 3) {
              setClearedTreeQuest(true)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/clear.png',
                '/christmasTree/clear.png',
              ])
            }
          }
          if (decorationItem?.christmasTree === 0) {
            if (sharedDateLength === 3) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/invite.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength === 4) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength >= 5) {
              setClearedTreeQuest(true)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/clear.png',
              ])
            }
          }
          if (decorationItem?.christmasTree === 1) {
            if (sharedDateLength === 5) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/invite.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength === 6) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength >= 7) {
              setClearedTreeQuest(true)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/clear.png',
              ])
            }
          }
          if (decorationItem?.christmasTree === 2) {
            if (sharedDateLength === 7) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/invite.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength === 8) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength >= 9) {
              setClearedTreeQuest(true)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/clear.png',
              ])
            }
          }
          if (decorationItem?.christmasTree === 3) {
            if (sharedDateLength === 9) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/invite.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength === 10) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength >= 11) {
              setClearedTreeQuest(true)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/clear.png',
              ])
            }
          }
          if (decorationItem?.christmasTree === 4) {
            if (sharedDateLength === 11) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/invite.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength === 12) {
              setClearedTreeQuest(false)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/invite.png',
              ])
            } else if (sharedDateLength >= 13) {
              setClearedTreeQuest(true)
              setInviteIcon([
                '/christmasTree/clear.png',
                '/christmasTree/clear.png',
              ])
            }
          }
        }
      }

      getInviteUser()
    }, [])

    const Button = () => {
      if (decorationItem?.christmasTree === 5) return
      if (clearedTreeQuest) {
        return (
          <button
            onClick={async () => {
              // clear api
              if (decorationItem?.christmasTree === 5) return
              const result = await backendApis.clearChristmasTree(
                token,
                'PUT',
                {
                  nowLevel: decorationItem?.christmasTree,
                },
              )
              if (result?.data?.success) {
                if (decorationItem?.christmasTree === undefined) {
                  DecorationItemStore.setDecorationItem({
                    christmasTree: 0,
                  })
                } else {
                  DecorationItemStore.setDecorationItem({
                    christmasTree: decorationItem?.christmasTree + 1,
                  })
                }
                ModalStore.setIsModalOpen('basic')
                setTimeout(() => {
                  MotionStore.setIsPung(true)
                }, 300)
                setTimeout(() => {
                  ModalStore.setIsModalOpen('christmasTree')
                }, 3000)
              }
            }}
          >
            <img
              style={{
                width: '60vw',
                position: 'absolute',
                bottom: '-15vw',
                left: '7.5%',
              }}
              src='/christmasTree/clearButton.png'
              alt=''
            />
          </button>
        )
      } else {
        return (
          <button
            onClick={() => {
              let imageUrl =
                'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png'
              let title = '[올팜] 무료 작물과 크리스마스 트리까지 드려요!'
              let description = '농장에 크리스마스가 찾아왔어요.'
              let templateId = 101907

              apiHandler.kakaoLinkShare({
                shareType: 'Alfarm_linkShare',
                title,
                description,
                imageUrl,
                payload: {},
                templateId,
              })
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                width: '60vw',
                position: 'absolute',
                bottom: '-15vw',
                left: '7.5%',
              }}
              src='/christmasTree/shareButton.png'
              alt=''
            />
          </button>
        )
      }
    }

    return (
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '25%',
            position: 'relative',
          }}
        >
          <button
            style={{
              width: '8%',
              marginTop: '-12%',
              right: '12%',
              position: 'absolute',
              zIndex: 1113,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img src='/icon/modalCloseButton.png' alt='' />
          </button>
          <div
            style={{
              width: '80vw',
              marginLeft: '10vw',
              position: 'absolute',
            }}
          >
            <img src='/christmasTree/modal.png' alt='' />
          </div>
          <div
            style={{
              width: '70vw',
              marginLeft: '15vw',
              marginTop: '18vw',
              position: 'absolute',
              background: 'white',
              borderRadius: '4vw',
              color: '#145863',
              textAlign: 'center',
              paddingTop: '4vw',
              fontSize: '4vw',
            }}
          >
            {decorationItem?.christmasTree === undefined && (
              <div
                style={{
                  color: '#145863',
                }}
              >
                친구 3명에게 링크 공유하면 <br />
                농장을 꾸밀 수 있는 트리를 드려요!
              </div>
            )}
            {decorationItem?.christmasTree >= 0 &&
              decorationItem?.christmasTree <= 4 && (
                <div
                  style={{
                    color: '#145863',
                  }}
                >
                  더 공유하면 트리에 장식을 달 수 있어요!
                  <br />
                  나의 농장을 예쁘게 꾸며보세요!
                </div>
              )}
            {decorationItem?.christmasTree === 5 && (
              <div
                style={{
                  color: '#145863',
                }}
              >
                트리를 모두 완성했어요 <br />
                메리크리스마스!
              </div>
            )}
            {decorationItem?.christmasTree === undefined && (
              <img
                style={{
                  width: '25vw',
                  marginTop: '6vw',
                  marginBottom: '4vw',
                }}
                src='/christmasTree/shadowTree.png'
                alt=''
              />
            )}
            {decorationItem?.christmasTree >= 0 &&
              decorationItem?.christmasTree <= 4 && (
                <img
                  style={{
                    width: '24vw',
                    marginTop: '6vw',
                    marginBottom: '4vw',
                  }}
                  src={`/christmasTree/morning/${
                    decorationItem?.christmasTree + 1
                  }.png`}
                  alt=''
                />
              )}
            {decorationItem?.christmasTree >= 5 && (
              <img
                style={{
                  width: '45vw',
                  marginTop: '6vw',
                  marginBottom: '4vw',
                }}
                src={`/christmasTree/morning/${5}.png`}
                alt=''
              />
            )}
          </div>
          {decorationItem?.christmasTree !== 5 && (
            <div
              style={{
                width: '70vw',
                marginLeft: '15vw',
                position: 'absolute',
                background: 'white',
                borderRadius: '4vw',
                color: '#145863',
                textAlign: 'center',
                bottom: '-94vw',
              }}
            >
              {inviteIcon.map((icon, index) => {
                return (
                  <img
                    key={index}
                    style={{
                      width: '12vw',
                      margin: '2vw',
                    }}
                    src={icon}
                    alt=''
                    onClick={() => {
                      let imageUrl =
                        'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png'
                      let title =
                        '[올팜] 무료 작물과 크리스마스 트리까지 드려요!'
                      let description = '농장에 크리스마스가 찾아왔어요.'
                      let templateId = 101907

                      apiHandler.kakaoLinkShare({
                        shareType: 'Alfarm_linkShare',
                        title,
                        description,
                        imageUrl,
                        payload: {},
                        templateId,
                      })
                      ModalStore.setIsModalOpen('basic')
                    }}
                  />
                )
              })}
              <Button />
            </div>
          )}
        </div>
      </div>
    )
  },
)

export default ChristmasTreeModal
