import React, { useEffect, useState } from 'react'
import ExpAnim from './expAnim'
import { purchaseHistoryWithinPeriod } from 'utils/utils'
import UserStore from 'store/UserStore'

function GaugeBarPercentage({
  level,
  remainExp,
  expToLevelUp,
  giveWaterCount,
  farmData,
  gganbuMode = false,
}) {
  const isNewUser = farmData?.isNewUser

  const [exp, setExp] = useState(100)

  useEffect(() => {
    if (gganbuMode) {
      setExp(((expToLevelUp / (remainExp + expToLevelUp)) * 100)?.toFixed(2))
    } else {
      setExp(((expToLevelUp / (remainExp + expToLevelUp)) * 100)?.toFixed(2))
    }
  }, [farmData, expToLevelUp, remainExp, expToLevelUp])

  if (level === 6) {
    return (
      <>
        <div
          className='gauge-percentage'
          style={
            {
              // marginLeft: "30%",
            }
          }
        >
          {`무료배송까지 ${exp}%!`}
        </div>
      </>
    )
  } else if (level > 6) {
    return <></>
  } else {
    return (
      <>
        {giveWaterCount === 1 ? (
          <div
            className='gauge-percentage'
            style={{
              color: 'white',
            }}
          >
            {`레벨업까지 ${exp}%!`}
          </div>
        ) : (
          <div className='gauge-percentage' style={{}}>
            {`레벨업까지 ${exp}%!`}
          </div>
        )}
      </>
    )
  }
}

export default GaugeBarPercentage
