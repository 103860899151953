import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import WhiteTextTimer from '../../../comps/WhiteTextTimer'
import ModalStore from '../../../store/ModalStore'

const UriConverter = (a) => {
  const result = 'url(' + a + ')'
  return result
}

const CloseButton = ({ onClick = () => {} }) => {
  return (
    <button
      style={{
        width: '8vw',
        right: 0,
        top: '20vw',
        position: 'absolute',
        zIndex: 999,
      }}
      onClick={onClick}
    >
      <img
        style={{
          zIndex: 101,
        }}
        src='/icon/modalCloseButton.png'
        alt=''
      />
    </button>
  )
}

const YellowButton = ({ onClick = () => {}, text = '' }) => {
  return (
    <div style={style.yellowButton} onClick={onClick}>
      {text}
    </div>
  )
}

const ButtonDisabled = ({ onClick = () => {}, text = '' }) => {
  return (
    <div
      style={{
        ...style.yellowButton,
        background: '',
      }}
      onClick={onClick}
    >
      {text}
    </div>
  )
}

const SingleDealModal = observer(
  ({ singleDealInfo, setShowModal, farmData, token, isLoaded }) => {
    const SingleDealPurchaseButtonClicked = async () => {
      const paramsInfo = {
        screen: 'MainStackDItemScreen',
        rewardType: singleDealInfo.rewardType || 'fertilizerSet',
        rewardAmount: singleDealInfo.rewardAmount || 3,
        itemId: singleDealInfo.itemId,
        itemDetailScreenImage:
          singleDealInfo.itemDetailImageUri ||
          'https://assets.ilevit.com/c7323846-0a26-4843-ba59-838b0b76c785.png',
        enteringComponent:
          singleDealInfo.enteringComponent || 'Alfarm_singleItemDealScreen',
        type: singleDealInfo.type || 'overProducedDeal',
      }
      window.location.href = `#alfarmSingleDeal.${JSON.stringify(paramsInfo)}`
    }

    if (!isLoaded) {
      return <></>
    }
    return (
      <>
        <div style={style.basicDiv}>
          <div
            style={{
              ...style.modalStyle,
              backgroundImage:
                UriConverter(singleDealInfo.modalImageUri) ||
                UriConverter(
                  'https://assets.ilevit.com/b493213b-d356-45ce-8da9-d806cedf09a7.png',
                ),
            }}
          >
            <CloseButton
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            />
            <div style={style.insideModalStyle}>
              <div
                style={{
                  ...style.modalDetailImage,
                  backgroundImage: UriConverter(
                    singleDealInfo.modalDetailImageUri,
                  ),
                }}
              />

              <div
                style={{
                  marginTop: '4vw',
                  marginBottom: '-3vw',
                  color: '#442b22',
                  fontFamily: 'maplestory',
                }}
              >
                <WhiteTextTimer
                  onTimeEnd={() => {
                    // ModalStore.setIsModalOpen("basic");
                  }}
                  timeStamp={new Date(singleDealInfo.displayStartAt)}
                  timerMinutes={60 * 24}
                  interval={1000}
                  color='#442b22'
                  style={{
                    color: '#442b22',
                    fontFamily: 'Maplestory',
                    fontWeight: 'bold',
                    padding: 0,
                    margin: 0,
                  }}
                  noDays={true}
                />{' '}
                남았어요!
              </div>
              <YellowButton
                onClick={() => {
                  SingleDealPurchaseButtonClicked()
                  ModalStore.setIsModalOpen('basic')
                }}
                text='상품 구매하고 고급비료 받기'
              />
              <div style={style.miniTextStyle}>
                *구매할 때마다 고급비료 3개를 드려요
                <br />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)

const style = {
  basicDiv: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalStyle: {
    width: '90vw',
    height: '150vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -55%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 16,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '57vw',
    paddingBottom: '16%',
  },
  insideModalStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalDetailImage: {
    width: 260,
    height: 220,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: '4px 0px 4px 0px',
  },
  miniTextStyle: {
    width: '100%',
    color: '#666',
    fontFamily: 'Maplestory',
    textAlign: 'center',
    fontSize: '0.8rem',
    margin: '6px 0px 6px 0px',
  },
  yellowButton: {
    zIndex: 100,
    background:
      'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
    width: '60vw',
    height: '16vw',
    borderRadius: 99,
    color: 'black',
    fontFamily: 'Maplestory',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 18,
    marginBottom: 8,
  },
}

export default SingleDealModal
