import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../store/ModalStore'

const DailyGiveWaterGetButton = observer(
  ({
    farmData,
    removedWater,
    yesterdayGiveWater,
    style,
    className = '',
    onClick,
  }) => {
    const [clickedMotion, setClickedMotion] = useState(false)

    return (
      <>
        <button
          style={{
            transform: `${clickedMotion ? 'scale(0.9)' : ''}`,
            width: '15vw',
            height: '15vw',
            position: 'relative',
            fontSize: '4vw',
            fontFamily: 'maplestory',
            textShadow:
              '-1.5px 0 black 0, 0 1.5px black 0, 1.5px 0 black 0, 0 -1.5px black 0',
          }}
          onClick={() => {
            setClickedMotion(true)
            setTimeout(() => {
              setClickedMotion(false)
            }, 200)
            setTimeout(() => {
              ModalStore.setIsModalOpen('yesterdayGiveWaterModal')
            }, 300)
          }}
        >
          <img
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            alt=''
            src='/dailyGiveWater/waterBottle2.png'
          />
          <div
            style={{
              position: 'absolute',
              width: '100%',
              fontSize: '4.5vw',
              display: 'flex',
              flexDirection: 'column',
              top: '55%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            {yesterdayGiveWater}g
          </div>
          <div
            style={{
              zIndex: -1,
              width: '85%',
              height: '85%',
              left: '7.5%',
              marginTop: '12.5%',
              borderRadius: '30vw',
              overflow: 'hidden',
            }}
            className='wave'
          />
        </button>
      </>
    )
  },
)

export default DailyGiveWaterGetButton
