import React, { useRef, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import DealAutomationStore from 'store/DealAutomationStore'

// -------중요---------
// 구매 배너 딜마다 바꿔줘야함
// -------------------

const brandImageSrc =
  'https://assets.ilevit.com/1261d6a3-2fe1-4aec-a476-53b9530666cc.png'

// -------중요---------
// 구매 배너 딜마다 바꿔줘야함
// -------------------

const GganbuFarmBrandDealModal = observer(
  ({ token, farmData, setFarmData }) => {
    const throttleRef = useRef({})

    const sendToPurchaseScreen = () => {
      AlfarmEventLogger({
        throttleRef,
        locationType: 'modal',
        locationName: ModalStore.isModalOpen,
        eventType: 'click',
        eventName: 'cta',
        collection: 'UserAlfarmClickLog',
      })
      window.location.href = `#generalNavigator.${JSON.stringify({
        screen: 'AlfarmGganbuFarmBrandDealScreen',
        params: {
          title: '올팜',
          image:
            'https://assets.ilevit.com/9ab9024a-16da-4437-a6c8-f3916d2cbf64.png',
          ratio: 96 / 360,
          brandImage:
            DealAutomationStore?.firstGganbuFarmBalloonAdData
              ?.purchaseScreenBanner,
          brandImageRatio: 96 / 240,
          enteringComponent: 'Alfarm_gganbuFarmBrandDeal',
          // sellerId: "613a09ae5989653d71925852",
          itemIds:
            DealAutomationStore?.firstGganbuFarmBalloonAdData?.itemIds ?? [],
        },
      })}`
      ModalStore.setIsModalOpen('basic')
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div className='w-[80vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
            <button
              style={{
                width: '10%',
                transform: 'translate(-50%, -50%)',
                right: '2%',
                top: '-12%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                  top: '25vw',
                  right: '1vw',
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <img
              src={
                DealAutomationStore?.firstGganbuFarmBalloonAdData?.background
              }
              alt=''
              style={{ width: '90vw' }}
            />
            {/* <div
            style={{
              position: "absolute",
              fontFamily: "maplestory",
              flex: 1,
              bottom: "20vw",
              zIndex: 2,
              width: "100%",
              textAlign: "center",
              color: "#442b22",
              fontSize: "4.5vw",
            }}
          ></div> */}
            <button
              style={{
                background: 'rgba(255,253,253,1)',
                borderColor: 'rgba(255,146,164,1)',
                borderWidth: 'medium',
                borderStyle: 'solid',
                width: '55vw',
                height: '14vw',
                borderRadius: 99,
                color: 'black',
                fontSize: '4.5vw',
                fontFamily: 'Maplestory',
                display: 'flex',
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 8,
                top: '105vw',
                left: '50%',
                transform: 'translateX(-50%)',
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
              }}
              onClick={() => {
                sendToPurchaseScreen()
              }}
            >
              {'구매하고 보상 받기'}
            </button>
          </div>
        </div>
      </>
    )
  },
)

export default GganbuFarmBrandDealModal
