import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import Log from '../../utils/log'
import ModalStore from '../../store/ModalStore'
import BottomSheetStore from 'store/BottomSheetStore'

const MANDARIN_PERSIMMON_UPDATE = '1.5.60'

const Badge = observer(
  ({
    itemType,
    token,
    userCodePushVersion,
    userId,
    changedPlantInfo,
    farmLevel,
    farmData,
  }) => {
    const [clearedItemType, setClearedItemType] = useState([])
    const [lockItemType, setLockItemType] = useState([])
    const [showChangeButton, setShowChangeButton] = useState(true)
    const clearedItem = async () => {
      const result = await backendApis.clearedItem(token)

      const harvestBadgeData = []
      for (const key of result?.uniqueItemType) {
        if (result?.count.hasOwnProperty(key)) {
          let found = false
          for (const item of harvestBadgeData) {
            if (item.name === key) {
              item.count += result?.count[key]
              found = true
              break
            }
          }
          if (!found) {
            harvestBadgeData.push({ name: key, count: result?.count[key] })
          }
        }
      }

      setClearedItemType(harvestBadgeData)
      setLockItemType(result?.lockItemType)
    }

    async function getChangePlantLog() {
      let result = await backendApis.getChangePlantLog(token)
      if (result?.didChangePlant && !changedPlantInfo) {
        setShowChangeButton(false)
      } else {
        setShowChangeButton(true)
      }
    }
    useEffect(() => {
      getChangePlantLog()
    }, [])

    useEffect(() => {
      clearedItem(token)
    }, [token])

    const PlantBadge = ({ item, text, type }) => {
      let color = '#72B646'
      let backgroundColor = '#F1F3C8'
      if (type === 'cleared') {
        color = '#46B673'
        backgroundColor = '#C8F3D4'
      } else if (type === 'locked') {
        color = '#835932'
        backgroundColor = '#FBEBC6'
      }

      return (
        <div
          className='relative flex flex-col justify-center  items-center'
          key={Math.random()}
        >
          {type === 'cleared' && (
            <div className='text-[#fff] absolute top-0 right-[2vw] text-[3vw] bg-[#FF3E3E] text-center rounded-[3vw] px-[2vw] py-[1vw] pb-[1vw]'>
              x {item?.count}
            </div>
          )}

          <img
            src={`/badge/${type === 'locked' ? item + 'Lock' : item?.name}.png`}
            style={{
              width: '20vw',
              height: '20vw',
            }}
            alt='plants'
          />
          <div
            style={{
              color: color,
              fontFamily: 'maplestory',
              fontSize: '3vw',
              backgroundColor: backgroundColor,
              textAlign: 'center',
              padding: '1vw 2vw 1vw 2vw',
              marginTop: '-1vw',
              borderRadius: '3vw',
            }}
          >
            {text}
          </div>
        </div>
      )
    }

    return (
      <div className='mx-[16px] flex flex-col text-[3.5vw] text-[#000]'>
        <div className='flex flex-row justify-between items-center mb-[2vw]'>
          <div className='flex flex-col'>
            <div className='text-[#000] text-[3.5vw] my-[4px] font-bold'>
              나의 배지 ({clearedItemType.length} / 32)
            </div>
          </div>
          <div className='flex flex-row items-center justify-center mb-[8px]'>
            {showChangeButton &&
              userCodePushVersion >= MANDARIN_PERSIMMON_UPDATE && (
                <div
                  className=' bg-gradient-to-b from-[#FFE99A] to-[#F09236] mr-[16px] rounded-[8px] text-[#000] flex justify-center items-center text-[3.5vw] px-[2.5vw] py-[2vw] '
                  onClick={() => {
                    ModalStore.setIsModalOpen('changePlant')
                  }}
                >
                  작물 변경
                </div>
              )}
            <div
              className=' bg-gradient-to-b from-[#FFE99A] to-[#F09236]  rounded-[8px] text-[#000] flex justify-center items-center text-[3.5vw] px-[2.5vw] py-[2vw] '
              onClick={() => {
                ModalStore.setIsModalOpen('badge')
              }}
            >
              보관함
            </div>
          </div>
        </div>

        <div className='relative overflow-x-scroll flex flex-row'>
          <PlantBadge
            item={{ name: itemType }}
            type='progress'
            text={'진행중'}
          />

          {clearedItemType?.length > 0 &&
            clearedItemType?.map((item, i) => (
              <PlantBadge
                item={item}
                type='cleared'
                text={'수확완료'}
                key={i}
              />
            ))}

          {lockItemType?.length > 0 &&
            lockItemType?.map((item, i) => (
              <PlantBadge item={item} type='locked' text={'수확대기'} key={i} />
            ))}
        </div>
      </div>
    )
  },
)

export default Badge
