import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { farmingList } from '../../config'
import NextLevelRewardModal from '../atoms/nextLevelRewardModal'
import OngoingWaterbattleBanner from '../atoms/ongoingWaterBattleBanner'
import SpeechBubble from '../atoms/speechBubble'
import speechBubbleMentListTemp from '../atoms/speechBubbleMentListTemp'
import Plant from './Plant'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import UserStore from 'store/UserStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import moment from 'moment'
import TimeStore from 'store/TimeStore'

const Farm = observer(
  ({
    id = 0,
    itemType,
    level = 1,
    token,
    farmData,
    myFarmData,
    setFarmData,
    setAddWater,
    setAddFertilizer,
    setAddFertilizerSet,
    gganbuMode = false,
    isLoading,
    battleData,
    battleWaterAmount,
    opponentBattleWaterCount,
    battleClassName,
    giveWaterCountAtThisVisit,
    ongoingWaterBattleBanner,
    lastHarvestPurchaseButtonOn,
    nutrimentLeavesOn,
  }) => {
    const [timerColor, setTimerColor] = useState('black')
    const [speechBubbleMent, setSpeechBubbleMent] = useState()
    const ballonRemoveTimer = useRef()
    const [speechBubbleMentList, setSpeechBubbleMentList] = useState(
      speechBubbleMentListTemp,
    )

    const throttleRef = useRef({})

    const fetchData = async () => {
      const result = await backendApis.getSpeechBubbleMent(token)
      if (result?.status === 200 && result?.data) {
        setSpeechBubbleMentList(result.data)
      } else {
        setSpeechBubbleMentList(speechBubbleMentListTemp)
      }
    }

    useEffect(() => {
      fetchData()
    }, [token])

    useEffect(() => {
      const eventPetPing = window.localStorage.getItem('eventPetPing')
      if (
        TimeStore?.currentMoment.isSameOrAfter(
          moment(eventPetPing ?? '2023-01-01').add(3, 'd'),
        )
      ) {
        UserStore?.set('pingManager.eventPetPing', true)
      }

      if (
        // 펫 해봤고, 끝난시점이 1주일 지났을때
        UserStore?.farmData?.petData?.eventPetEndAt &&
        TimeStore.currentMoment?.isSameOrAfter(
          moment(
            UserStore?.farmData?.petData?.eventPetEndAt ?? '2023-01-01',
          ).add(1, 'week'),
        )
      ) {
        UserStore?.set('hideEventPet', true)
      }
    }, [])

    useEffect(() => {
      let date = new Date()
      if (date.getHours() >= 20 || date.getHours() <= 6) {
        setTimerColor('white')
      } else {
        setTimerColor('black')
      }
    }, [timerColor])

    useEffect(() => {
      ballonRemoveTimer.current = setTimeout(() => {
        removeBallon()
      }, 3000)
    }, [])

    const removeBallon = () => {
      setSpeechBubbleMent()
    }

    const changeBalloon = (status) => {
      const i = Math.floor(
        Math.random() * speechBubbleMentList[status].length - 1,
      )
      setSpeechBubbleMent(speechBubbleMentList[status][i])
      clearTimeout(ballonRemoveTimer.current)
      ballonRemoveTimer.current = setTimeout(() => {
        removeBallon()
      }, 1000 * 3)
    }

    useEffect(() => {
      if (giveWaterCountAtThisVisit === 0) {
        setTimeout(() => {
          changeBalloon('beforeGiveWater')
        }, 1000 * 3)
      } else if (giveWaterCountAtThisVisit > 0) {
        const i = Math.random()
        if (i > 0.7) {
          changeBalloon('afterGiveWater')
        }
      }
    }, [giveWaterCountAtThisVisit])

    if (isLoading) {
      return <></>
    }

    return (
      <div className='relative'>
        {speechBubbleMent &&
          farmData?.giveWaterCount > 9 &&
          !ongoingWaterBattleBanner &&
          farmData?.userId &&
          farmData?.giveWaterCount >= 9 && (
            <SpeechBubble contents={speechBubbleMent} />
          )}
        {ongoingWaterBattleBanner && (
          <OngoingWaterbattleBanner
            id={id}
            battleData={battleData}
            battleWaterAmount={battleWaterAmount}
            opponentBattleWaterCount={opponentBattleWaterCount}
            battleClassName={battleClassName}
          />
        )}
        {lastHarvestPurchaseButtonOn &&
          !ongoingWaterBattleBanner &&
          !speechBubbleMent && (
            <div
              className='absolute w-[50vw] left-1/2 top-[0vw] z-[1] -translate-x-1/2'
              onClick={() => {
                ModalStore.setIsModalOpen('lastHarvestPurchase')
              }}
            >
              <img alt='' src={'/lastHarvestPurchase/lastHarvestBanner.png'} />
            </div>
          )}

        <div className='relative w-[33vw]'>
          <Plant
            myFarmData={myFarmData || {}}
            farmData={farmData}
            farmingList={farmingList}
            id={id}
            level={level}
            gganbuMode={gganbuMode}
            itemType={itemType}
            nutrimentLeavesOn={nutrimentLeavesOn}
          />

          {!gganbuMode &&
            farmData?.giveWaterCount > 80 &&
            TimeStore.currentMoment.isAfter(
              moment(
                UserStore?.farmData?.petData?.eventPetEndAt ?? '2023-01-01',
              ),
            ) &&
            !UserStore?.hideEventPet && (
              <div
                className='absolute top-[11vw] right-[-27vw] w-[16vw] h-[16vw] z-[1]'
                onClick={() => {
                  ModalStore.setIsModalOpen('eventPet')
                  AlfarmEventLogger({
                    throttleRef,
                    locationType: 'farmPage',
                    locationName: 'eventPetEntryIcon',
                    eventType: 'click',
                    collection: 'UserAlfarmClickLog',
                  })
                  window.localStorage.setItem(
                    'eventPetPing',
                    TimeStore?.currentMoment?.format('YYYY-MM-DD'),
                  )
                  UserStore?.set('pingManager.eventPetPing', false)
                }}
              >
                <img alt='eventPetEntryIcon' src='/eventPet/entryIcon.png' />
                {UserStore?.pingManager?.eventPetPing && (
                  <img
                    className='w-[9vw] h-[9vw] absolute -right-[2vw] -top-[2vw] wobble-ver-left'
                    alt='ping'
                    src={`../icon/!.png`}
                  />
                )}
              </div>
            )}
        </div>
      </div>
    )
  },
)

export default Farm
