import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import UserStore from '../../store/UserStore'

import moment from 'moment'

const InviteHistoryModal = observer(({ token }) => {
  const [inviteHistoryData, setInviteHistoryData] = useState([])
  const [emptyModal, setEmptyModal] = useState(false)

  const getInviteHistory = async () => {
    const result = await backendApis.getInviteHistory(token)
    if (result?.data?.logsInfo?.length === 0) {
      setEmptyModal(true)
    }
    let icon
    const customData = result?.data?.logsInfo?.map((item) => {
      if (item?.type === 'puppy3days') {
        icon = '/newUserInviteIcon/puppy.png'
      } else if (item?.type === 'inviteGganbu') {
        icon = '/newUserInviteIcon/gganbuInvite.png'
      } else if (item?.type === 'changePlantInvite') {
        icon = '/newUserInviteIcon/changePlant.png'
      } else if (item?.type === 'inviteNewFriends') {
        icon = '/newUserInviteIcon/dailyInvite.png'
      } else if (item?.type === 'leftGganbuInvite') {
        icon = '/newUserInviteIcon/gganbuReturns.png'
      } else if (item?.type === 'rouletteInvite') {
        icon = '/newUserInviteIcon/roulette.png'
      } else if (item?.type === 'levelUpChance') {
        icon = '/newUserInviteIcon/levelupChance.png'
      } else if (item?.type === 'AlfarmMakeGganbu') {
        icon = '/newUserInviteIcon/gganbuInvite.png'
      } else if (item?.type === 'letsDrinkWater') {
        icon = '/newUserInviteIcon/drinkWater.png'
      } else if (item?.type === 'vipCard') {
        icon = '/newUserInviteIcon/vipCard.png'
      } else if (item?.type === 'inviteFriends') {
        icon = '/newUserInviteIcon/dailyInvite.png'
      } else {
        icon = ''
      }

      return { ...item, icon }
    })

    setInviteHistoryData(customData)
  }

  useEffect(() => {
    getInviteHistory()
  }, [token])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1000,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.8)',
        }}
      />
      <div
        style={{
          width: '80%',
          height: '110vw',
          position: 'absolute',
          zIndex: 1001,
          top: '55vw',
          left: '10%',
          background: 'white',
          borderRadius: 20,
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            fontFamily: 'maplestory',
            color: '#402C24',
            fontSize: '6vw',
            textAlign: 'center',
            marginTop: '5vw',
            marginBottom: '1vw',
          }}
        >
          친구 초대 기록 <br />
        </div>
        <div
          style={{
            fontFamily: 'maplestory',
            color: '#402C24',
            fontSize: '4vw',
            textAlign: 'center',
            marginTop: '2vw',
            marginBottom: '1vw',
          }}
        >
          새로운 친구를 초대한 기록만 보여요 <br />
        </div>
        <div
          style={{
            backgroundColor: '#f2f2f2',
            width: '100%',
            height: '0.5vw',
            marginTop: 12,
          }}
        />
        <div
          style={{
            position: 'relative',
            overflowY: 'scroll',
            flex: '93vw',
          }}
        >
          <div>
            {inviteHistoryData?.length > 0 &&
              inviteHistoryData?.map((item) => {
                return (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 8,
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 18,
                        }}
                      >
                        <div
                          style={{
                            // position: "relative",
                            zIndex: 1999,
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              alignContent: 'center',
                              alignItems: 'center',
                              marginTop: 4,
                            }}
                          >
                            <img
                              style={{
                                zIndex: 1999,
                                width: 30,
                                height: 30,
                                borderRadius: 50,
                              }}
                              src={item?.userImage}
                              onError={(e) => {
                                e.target.src = UserStore?.default_profile_image
                              }}
                            />

                            <div
                              style={{
                                color: '#5F2D0C',
                                zIndex: 1999,
                                fontFamily: 'maplestory',
                                fontSize: 14,
                                marginLeft: 8,
                              }}
                            >
                              {item?.userName?.length > 4 ? (
                                <>{item?.userName.slice(0, 4)}...</>
                              ) : (
                                <>{item?.userName.slice(0, 4)}</>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            color: '#855C36',
                            fontFamily: 'maplestory',
                            fontSize: 12,
                            marginTop: 10,
                          }}
                        >
                          {moment(item?.loggedAt).format(`MM월 DD일 HH:mm`)}
                        </div>
                      </div>

                      <div
                        style={{
                          color: '#EE7B63',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          marginRight: 18,
                          display: 'flex',
                          flex: 1,
                          fontSize: 20,
                          fontFamily: 'maplestory',
                          marginTop: 8,
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <img
                            style={{
                              width: '18vw',
                            }}
                            alt=''
                            src={item?.icon}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#EDEDED',
                        fontSize: 18,
                        marginTop: 16,
                      }}
                    />
                  </>
                )
              })}
          </div>
        </div>
      </div>

      <button
        style={{
          width: '8%',
          top: '40vw',
          right: '10vw',
          zIndex: 1001,
          position: 'absolute',
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img src='/icon/modalCloseButton.png' alt='' />
      </button>

      {emptyModal && (
        <>
          <div
            style={{
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: 18,
              marginTop: '20vw',
              textAlign: 'center',
            }}
          >
            친구 초대 기록이 없어요
            <br />
            친구와 함께 올팜을 즐겨보세요!
          </div>
        </>
      )}
    </>
  )
})

export default InviteHistoryModal
