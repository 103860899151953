import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import UserStore from 'store/UserStore'
import apiHandler from 'utils/apiHandler'
import moment from 'moment'
import TimeStore from 'store/TimeStore'

const EventPetModal = observer(
  ({ token, farmData, showRewardToast, setFarmData }) => {
    const throttleRef = useRef({})

    const shareKakao = () => {
      let imageUrl =
        'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png'
      let title = '[올팜] 요즘 핫한 무료로 작물 주는 앱!'
      let description = '작물 키우기만 하면 공짜로 받아요.'
      let templateId = 102068

      apiHandler.kakaoLinkShare({
        shareType: 'Alfarm_linkShare_event_pet',
        title,
        description,
        imageUrl,
        payload: {},
        templateId,
      })
    }

    const CloseButton = () => {
      return (
        <button
          className='absolute w-[10vw] right-0 top-[10vw] z-[999]'
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img className='z-[101]' src='/icon/modalCloseButton.png' alt='' />
        </button>
      )
    }

    return (
      <div className='flex flex-1 fixed left-0 top-0 z-[900] w-full h-full bg-black bg-opacity-80'>
        <div
          className='w-[90vw] h-[150vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col pt-[47vw] pb-[16vw]'
          style={{
            backgroundImage: 'url(../eventPet/modal.png)',
          }}
        >
          <CloseButton />
          <div className='w-full h-full flex flex-col justify-between items-center'>
            <div className='text-[#424242] text-[4vw] text-center mt-[10vw] font-bold'>
              친구에게 10번만 공유하면 <br />
              보너스 펫을 획득할 수 있어요
            </div>
            <img
              className='mt-[2vw] w-[30vw]'
              alt='reward'
              src='../eventPet/reward.png'
            />

            <div className='text-[#777] text-[3.5vw] text-center mb-[4vw]'>
              {`${
                TimeStore.currentMoment?.isSameOrBefore(
                  moment(
                    UserStore?.farmData?.petData?.eventPetEndAt ?? '2023-01-01',
                  ),
                )
                  ? `${moment(
                      UserStore?.farmData?.petData?.eventPetEndAt ??
                        '2023-01-01',
                    ).diff(
                      TimeStore.currentMoment,
                      'd',
                    )}일 남음 (공유 10회시 7일 추가)`
                  : '*7일간 유지돼요'
              }`}
            </div>

            <div
              className='bg-gradient-to-r from-[#F7B44F] via-[#FFC061] to-[#F7B44F] w-[60vw] h-[14vw] rounded-[99px] text-[#000] font-Maplestory flex justify-center items-center text-[5.5vw] mb-[8vw]'
              onClick={shareKakao}
            >
              {`공유하기 ${
                (UserStore?.farmData?.petData?.eventPetShareHistory?.length ??
                  0) % 10
              }/${10}회`}
            </div>
          </div>
        </div>
      </div>
    )
  },
)
export default EventPetModal
