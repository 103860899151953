import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import toast, { Toaster } from 'react-hot-toast'
import ModalStore from '../../store/ModalStore'
import ToastStore from 'store/ToastStore'

const ReferralCodeModal = observer(
  ({
    farmData,
    setFarmData,
    token,
    setShowPopup,
    setResourceType,
    showReferralCodeModal,
  }) => {
    const referralCode = useRef()
    const toastBlock = useRef()

    useEffect(() => {
      referralCode.current = ''
      toastBlock.current = false
    }, [])

    const notify = (text, type) => {
      ToastStore?.toastOn({
        type,
        message: text,
        duration: 3000,
      })
    }

    const Title = ({ text = [''], color = 'black', fontSize = '4vw' }) => {
      return (
        <div
          style={{
            width: '100%',
            paddingTop: '1vw',
          }}
        >
          {text.map((i, index) => {
            return (
              <div
                style={{
                  color,
                  fontSize,
                  fontFamily: 'maplestory',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingBottom: '1vw',
                }}
                key={i.toString() + index.toString()}
              >
                {i} <br></br>
              </div>
            )
          })}
        </div>
      )
    }

    const Button = ({ text = '' }) => {
      return (
        <button
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffd147',
            borderRadius: '16px',
            height: '40px',
            width: '100%',
            fontSize: '4vw',
            fontFamily: 'maplestory',
            color: 'black',
            marginTop: '2vw',
          }}
        >
          {text}
        </button>
      )
    }

    const ModalBody = () => {
      const onPressSubmit = async () => {
        if (referralCode.current === '') return
        let result = await backendApis.referralCodeProcess({
          referralCode: referralCode.current,
        })
        if (result?.data?.success) {
          notify('친구에게 초대보상이 적용되었어요!', 'success')
          ModalStore.setIsModalOpen('basic')
        } else {
          let text = '비정상 접근을 감지했어요! 다시 시도해주세요'
          switch (result?.data?.msg) {
            default:
              break
            case 'invalid referralCode':
              text = '유효하지 않은 초대코드에요!'
              break
            case 'invaild referralCode':
              text = '유효하지 않은 초대코드에요!'
              break
            case 'same userId':
              text = '자기자신에게는 적용되지 않아요'
              break
            case 'already received new user reward':
              text = '초대보상 적용은 한번만 가능해요'
              break
            case 'left service cooltime':
              text = '탈퇴후 재가입 기간이 짧아서 보상을 줄 수 없어요'
              break
          }
          notify(text, 'error')
        }
      }

      return (
        <div
          style={{
            position: 'relative',
            paddingBottom: '4vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
            zIndex: 100,
            paddingLeft: '5%',
            paddingRight: '5%',
            width: '100%',
            height: '100%',
            // border: "1px solid red",
            // paddingTop: "0vw",
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              marginTop: '14vw',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              // border: "1px solid black",
              zIndex: 1000,
              paddingTop: '7vw',
            }}
          >
            <Title
              text={[
                '초대 보상이 적용되지 않았다면',
                '링크에 있는 초대코드를 입력해주세요',
              ]}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 8,
                paddingRight: 8,
                width: '100%',
                // border: "1px solid black",
              }}
            >
              <input
                style={{
                  // width: "100%",
                  width: '40vw',
                  border: '1px solid #777',
                  height: '40px',
                  borderRadius: '8px',
                  padding: '4px 8px 4px 8px',
                  fontFamily: 'maplestory',
                }}
                value={referralCode.current}
                onChange={(e) => {
                  referralCode.current = e.target.value
                }}
                placeholder='초대코드를 입력해주세요'
              />
              <div
                style={{
                  marginLeft: 8,
                  width: '50px',
                  height: '40px',
                  backgroundColor: '#ffd147',
                  color: 'black',
                  borderRadius: 8,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '0.9  rem',
                }}
                onClick={onPressSubmit}
              >
                확인
              </div>
            </div>
            <div style={{ width: '100%', height: '0px' }}></div>
            {/* <Button text={"닫기"}></Button> */}
          </div>
        </div>
      )
    }

    const ModalFrame = () => {
      return (
        <>
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 900,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }}
          >
            <div
              style={{
                width: '80vw',
                height: '60vw',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                position: 'absolute',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column',
                zIndex: 901,
                borderRadius: '4vw',
                padding: 16,
                backgroundImage: 'url(../icon/referralCodeModal.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              <button
                style={{
                  width: '8vw',
                  top: '-14vw',
                  right: '0vw',
                  position: 'absolute',
                  cursor: 'pointer',
                  zIndex: 999,
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('basic')
                }}
              >
                <img
                  style={{ zIndex: 101 }}
                  src='/icon/modalCloseButton.png'
                  alt=''
                />
              </button>
              <ModalBody />
            </div>
          </div>
        </>
      )
    }

    return <ModalFrame />
  },
)

export default ReferralCodeModal
