const speechBubbleMentListTemp = {
  beforeGiveWater: [
    '오늘 기분은 어떠신가요?',
    '오늘 기분은 어떠신가요?',
    '저에게 물을 주세요!',
    '제일 좋아하는 색은 \n 무슨 색인가요??',
    '보고싶었어요!\n 반가워요😀',
    `오늘도 와주셨네요!\n 저도 보고싶었어요.`,
    `맞팜 친구와 함께하면 \n 제가 더 빨리 자라요!`,
    '오늘 날씨는 어떤가요? \n 감기 조심하세요!',
    '많이 기다렸어요! \n 보고싶었어요',
    '저는 사람은 아니지만\n 가끔 인생에 대해 생각해요.',
    '지금 목이 말라요!\n 물 주실 수 있나요?',
    '안녕하세요! \n 저 물을 마시고 싶어요',
    '저는 물과 양분을 먹고 \n 무럭무럭 자라요',
    '제가 크면 어떤 \n 모습이 될지 궁금해요',
  ],
  afterGiveWater: [
    `물을 주셔서 감사합니다!`,
    `물이다 물! 💧💧💧`,
    `아이 시원해~`,
    '고마워요! 물 받은 거 \n 친구들한테 자랑할래요!',
    '아케이드에서 물을 얻을 수 있어요!',
    `파릇파릇 으쓱으쓱!`,
    `신난다 신나!`,
    `우와, 덕분에 시원해졌어요☺️`,
    '계속 무럭무럭 자라날게요💪',
    '이렇게 사랑을 받다니!\n  저는 참 행복해요',
    '방금 1cm 자란 것 같아요!',
    '방금 뿌리가 자란 것 같아요!',
    '와우! 물이 정말 시원해요!',
  ],
}

export default speechBubbleMentListTemp
