import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import dayjs from 'dayjs'
import ModalStore from '../../store/ModalStore'
import TimeStore from 'store/TimeStore'
import UserStore from 'store/UserStore'

const ComebackUserModal = observer(
  ({ token, farmData, setFarmData, loadUserCoupon }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)

    const [checkInCount, setCheckInCount] = useState(0)
    const [canCheckIn, setCanCheckIn] = useState(false)
    const [data, setData] = useState(false)
    const [untilAt, setUntilAt] = useState(10)

    const checkIn = async () => {
      setCanCheckIn(false)
      const result = await backendApis.checkIn(token)
      let tmpFarmData = farmData
      if (UserStore.waterExceeded) {
        tmpFarmData.water += Math.round(result?.data?.addedWater * 0.5)
      } else {
        tmpFarmData.water += result?.data?.addedWater
      }
      tmpFarmData.fertilizerSet += result?.data?.addedFertilizerSet
      setFarmData(tmpFarmData)
      setData(result?.data)
      loadUserCoupon()

      UserStore?.set('comebackUserCanCheckIn', false)
    }

    const checkInData = async () => {
      const result = await backendApis.comebackUserCheckInData(token)

      setCheckInCount(result?.data?.checkInCount)
      setCanCheckIn(result?.data?.success)
    }

    useEffect(() => {
      checkInData()
      setUntilAt(
        Math.floor(
          (dayjs(farmData?.resurrectInfo?.createdAt ?? '2023-01-01') +
            1000 * 60 * 60 * 24 * 10 -
            TimeStore?.currentDayJS) /
            (1000 * 60 * 60 * 24),
        ),
      )
    }, [data])

    const Item = ({ index, checkInCount }) => {
      return (
        <img
          className='z-[1105] w-full'
          src={`/icon/comebackUser/${
            checkInCount >= index ? 'done' : 'yet'
          }/${index}.png`}
          alt=''
        />
      )
    }

    const CheckButton = () => {
      if (checkInCount >= 7) {
        return (
          <button className='text-[4vw] bg-gradient-to-r from-[#EFDFD0] to-[#FBDEB9] rounded-3xl py-[14px] z-[2000] w-[70%] top-[120vw] left-[12.5vw] text-[#442B22] mt-[4vw]'>
            이벤트를 완료했어요!
          </button>
        )
      } else if (!canCheckIn) {
        return (
          <button className='text-[4vw] bg-gradient-to-r from-[#EFDFD0] to-[#FBDEB9] rounded-3xl py-[14px] z-[2000] w-[70%] top-[120vw] left-[12.5vw] text-[#442B22] mt-[4vw]'>
            오늘 출석 완료!
          </button>
        )
      } else if (canCheckIn && checkInCount <= 6) {
        return (
          <button
            className='text-[4vw] rounded-3xl py-[14px] z-[2000] w-[70%] top-[120vw] left-[12.5vw] text-[#442B22] mt-[4vw]'
            style={{
              background: isButtonPressed
                ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              boxShadow: isButtonPressed
                ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                : '',
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
              checkIn()
            }}
          >
            오늘 출석하기
          </button>
        )
      }
    }
    return (
      <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
        <div
          className='w-[90vw] h-[150vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex absolute justify-start items-center flex-col px-[16px] pt-[28vw] pb-[16px]'
          style={{
            backgroundImage: 'url(../icon/comebackUser/background.png)',
          }}
        >
          {untilAt === 0 ? (
            <div className='text-[4vw] font-maplestory text-black bg-[#F4E5C5] rounded-full px-[4vw] py-[1vw] mb-[2vw]'>
              오늘까지예요!
            </div>
          ) : (
            <div className='text-[4vw] font-maplestory text-black bg-[#F4E5C5] rounded-full px-[4vw] py-[1vw] mb-[2vw]'>
              {untilAt}일 남았어요!
            </div>
          )}
          <div className='text-[#442B22] mb-[2vw] text-[5vw] '>
            돌아온 기념, 7일간의 혜택을 드려요!
          </div>
          <div className='w-full px-[6vw] grid grid-cols-3 gap-4'>
            {Array.from({ length: 6 }, (_, index) => (
              <Item index={index + 1} checkInCount={checkInCount} />
            ))}
          </div>
          <div className='flex px-[6vw] mt-[4vw]'>
            <Item index={7} checkInCount={checkInCount} />
          </div>

          <CheckButton />
        </div>

        <button
          className='z-[1101] w-[8%] top-[10vw] right-[5%] absolute'
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
        </button>
      </div>
    )
  },
)

export default ComebackUserModal
