import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import WhiteTextTimer from '../../../comps/WhiteTextTimer'
import dayjs from 'dayjs'
import { useInterval } from 'react-use'
import CountDown from '../../molecules/CountDown'
import commaNumber from 'comma-number'
import ModalStore from '../../../store/ModalStore'

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

const TapCountGameModal = observer(({ closeModal, token }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [gameStatus, setGameStatus] = useState('ready')
  const [score, setScore] = useState(0)
  const [currentNum, setCurrentNum] = useState(1)
  const [preplayCountDown, setPreplayCountDown] = useState(40)
  const [highScore, sethighScore] = useState(0)
  const [buttonClickable, setButtonClickable] = useState(true)

  const now = useRef()
  const numListRef = useRef()
  const throttleRef = useRef({})
  useEffect(() => {
    numListRef.current = shuffleArray([
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ])
    sethighScore(window.localStorage.getItem('tapCountHighScore') ?? 0)
  }, [])

  useInterval(
    () => {
      if (preplayCountDown <= 0) {
        setGameStatus('playing')
        return
      }
      setPreplayCountDown((prev) => prev - 1)
    },
    gameStatus === 'count' && preplayCountDown >= 0 ? 100 : null,
  )

  useEffect(() => {
    if (currentNum === 21) {
      endGame()
    }
  }, [currentNum])

  const endGame = async () => {
    setTimeout(() => {
      setButtonClickable(true)
    }, 500)
    setGameStatus('gameOver')
    if (score > highScore) {
      window.localStorage.setItem('tapCountHighScore', score)
      sethighScore(score)
    }
    numListRef.current = shuffleArray([
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ])
  }
  //

  useEffect(() => {
    if (gameStatus === 'playing') {
      now.current = dayjs()
    } else if (gameStatus === 'count') {
      setButtonClickable(false)
    }
  }, [gameStatus])

  const Card = (number) => {
    return (
      <div
        key={Math.random()}
        style={{
          ...styles.card,
          color: '#222',
          backgroundColor: number < currentNum ? '#DFFFD8' : '#F7C8E0',
        }}
        onPointerDown={() => {
          if (number === currentNum) {
            setCurrentNum((prev) => {
              setScore((prev) => {
                let plus = 200 - 10 * dayjs().diff(now.current, 's')
                return prev + Math.max(10, plus)
              })

              return prev + 1
            })
          }
        }}
      >
        {number >= currentNum ? number : '🌱'}
      </div>
    )
  }

  const Content = () => {
    if (gameStatus === 'ready') {
      return (
        <div style={styles.content}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: 'black',
              paddingBottom: 32,
              paddingTop: 16,
            }}
          >
            숫자를 찾아라!
          </div>

          <div
            style={{
              width: '50vw',
              // height: "30vw",
              border: '1px solid #DB3B61',
              borderRadius: 99,
              display: 'flex',
              marginBottom: '6vw',
            }}
          >
            <div
              style={{
                padding: 4,
                paddingLeft: 12,
                paddingRight: 12,
                borderRadius: 99,
                backgroundColor: '#DB3B61',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                marginTop: '-4vw',
                fontWeight: 'bold',
              }}
            >
              최고점수
            </div>
            <div
              style={{
                color: 'black',
                padding: '6vw',
                borderRadius: 8,
                fontWeight: 'bold',
                fontSize: '1.5rem',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {`${commaNumber(highScore)}점`}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div style={{ color: 'black' }}>
              1부터 20까지 최대한 빠르게 눌러보세요 💨
            </div>
            <div style={{ color: 'black' }}>
              더 빨리 성공할수록 점수가 높아져요 🌟
            </div>
            <div style={{ color: 'black' }}>점수를 높여 자랑을 해보세요 😎</div>
          </div>
        </div>
      )
    } else if (gameStatus === 'count' || gameStatus === 'playing') {
      return (
        <div
          style={{
            ...styles.content,
            justifyContent:
              gameStatus === 'playing' ? 'space-between' : 'flex-start',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                color: 'black',
                fontWeight: 'bold',
                paddingTop: 16,
                fontSize: '1.5rem',
              }}
            >
              점수: {score}
            </div>
            <div
              style={{
                color: 'black',
                // fontWeight: "bold",
                paddingTop: 16,
                fontSize: '1rem',
                alignSelf: 'flex-start',
              }}
            >
              누를 번호: {currentNum}
            </div>
          </div>

          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              // gridTemplateColumns: "repeat(4, 1fr)",
              // gridTemplateRows: "repeat(5,1fr)",
              // gap: "1vw",
              paddingTop: '10vw',
              // border: "10px solid black",
            }}
          >
            {numListRef.current.map((num) => Card(num))}
          </div>
        </div>
      )
    } else if (gameStatus === 'gameOver') {
      return (
        <div style={{ ...styles.content, justifyContent: 'flex-start' }}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: 'black',
              paddingTop: 16,

              paddingBottom: 32,
            }}
          >
            {`종료`}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              border: '2px solid #F7C8E0',
              borderRadius: 8,
              padding: 16,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '1.5rem',
                color: 'black',
                paddingBottom: 32,
                paddingTop: 8,
              }}
            >
              {`내 점수 ${commaNumber(score)}점`}
            </div>
            <div
              style={{
                color: 'black',
                padding: 8,
                // borderRadius: 8,
                marginBottom: 8,
                // fontWeight: "bold",
                fontSize: '1.5rem',
                backgroundColor: 'rgba(255,0,0,0.1)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >{`최고 점수: ${commaNumber(highScore)}점`}</div>
          </div>
          <div>
            <div style={{ color: 'black', marginTop: 16 }}>
              친구에게 점수를 자랑해보세요 😎
            </div>
          </div>
        </div>
      )
    }
  }

  const share = () => {
    const inviteData = {
      shareType: 'gameTest',
      title: '[올팜] 숫자를 찾아라 한번 해보실래요?',
      description: `저는 방금 총 ${score}점을 달성했어요! 😎`,
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }
  return (
    <>
      <div style={styles.background}>
        <div
          style={{
            ...styles.contentBackground,
          }}
        >
          <button
            style={styles.closeButton}
            onClick={() => {
              // closeModal(false);
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <Content />
          {(gameStatus === 'ready' || gameStatus === 'gameOver') && (
            <div
              style={{
                ...styles.CTA,
                background: isButtonPressed ? '#F7C82A' : '#F7C82A',
                boxShadow: isButtonPressed
                  ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                  : '',
                marginBottom: gameStatus === 'ready' ? 8 : 0,
              }}
              onClick={() => {
                if (!buttonClickable) return
                if (gameStatus === 'gameOver') {
                  share()
                } else {
                  setGameStatus('count')
                }
              }}
              onTouchStart={() => {
                setIsButtonPressed(true)
              }}
              onTouchEnd={() => {
                setIsButtonPressed(false)
              }}
            >
              {`${gameStatus === 'gameOver' ? '자랑하기' : '시작하기'}`}
            </div>
          )}
          {gameStatus === 'playing' ||
            (gameStatus === 'gameOver' && (
              <div
                style={{
                  ...styles.CTA,
                  background: 'rgba(249,241,214,0.5)',
                  border: '1px solid #F7C82A',
                  // bottom: "-18vw",
                  // color: "white",
                }}
                onClick={() => {
                  if (!buttonClickable) return
                  setGameStatus('count')

                  setScore(0)
                  setCurrentNum(1)
                  setPreplayCountDown(40)
                }}
              >
                {`다시하기`}
              </div>
            ))}
        </div>
      </div>

      {gameStatus === 'count' && (
        <CountDown preplayCountDown={preplayCountDown} />
      )}
    </>
  )
})

export default TapCountGameModal

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 98,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 16,
  },
  closeButton: {
    width: '10vw',
    height: '10vw',
    position: 'absolute',
    zIndex: 98,
    // backgroundColor: "black",
    top: '-14vw',
    right: 0,
  },
  contentBackground: {
    borderRadius: 8,
    background: '#fff',
    padding: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    width: '92%',
    position: 'absolute',
  },
  CTA: {
    width: '60vw',
    height: '14vw',
    borderRadius: 99,
    color: 'black',
    fontFamily: 'maplestory',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '4.5vw',
    marginTop: 16,
    marginBottom: 8,
  },
  content: {
    left: '50%',
    width: '100%',
    // height: "100%",
    borderRadius: 8,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '2px solid #F7C8E0',
  },
  card: {
    background: 'pink',
    width: '19vw',
    height: '19vw',
    borderRadius: 99,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1.5vw',
    // margin: "2vw",
    // border: "5px solid black",
  },
}
