import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../store/ModalStore'
import TimeStore from '../../../store/TimeStore'
import { useInterval } from 'react-use'
import { useSearchParam } from 'react-use'
import UserStore from 'store/UserStore'
import timeChecker from 'utils/timeChecker'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import ToastStore from 'store/ToastStore'

const GuessMeModal = observer(
  ({ token, farmData, setFarmData, showRewardToast }) => {
    const alwayzInvitorId = useSearchParam('alwayzInvitorId')
    const indexNumber = useSearchParam('indexNumber')
    const [selectedFriendIdx, setSelectedFriendIdx] = useState(4)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [guessMeData, setGuessMeData] = useState(null)
    const [status, setStatus] = useState('')
    const [submitData, setSubmitData] = useState(null)
    const [refresh, setRefresh] = useState(true)
    const [buttonPressed, setButtonPressed] = useState(0)

    const throttleRef = useRef({})

    const resetGuess = () => {
      setSelectedFriendIdx(4)
      setRefresh(true)
    }
    const resetGganbu = () => {
      setSelectedFriendIdx(4)
      let gganbuList = [
        ...shuffleArray(
          farmData?.gganbuList?.filter((item) => !item?.deletedAt),
        ),
      ]
      UserStore?.setGuessMeFriendList(gganbuList?.slice(0, 3))
    }
    const resetQuestion = async () => {
      setSelectedFriendIdx(4)
      let res = await backendApis.guessMe(farmData?.guessMeIndex)
      setGuessMeData(res?.data)
    }

    const shuffleArray = (array) => {
      for (let i = array?.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1))
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    }

    useEffect(() => {
      const fetch = async () => {
        let gganbuList = [
          ...shuffleArray(
            farmData?.gganbuList?.filter((item) => !item?.deletedAt),
          ),
        ]
        if (gganbuList?.length < 3) {
          setStatus('inavailable')
          return
        }
        if (farmData?.guessMeIndex) {
          setStatus('fromPush')
          if (UserStore?.guessMeFriendList?.length <= 0) {
            let correctFriendIdx = gganbuList.findIndex(
              (item) =>
                item?.userId?.toString() === alwayzInvitorId?.toString(),
            )

            const correctFriend = gganbuList.splice(correctFriendIdx, 1)?.[0]

            let temp = gganbuList?.slice(0, 2)
            temp.push(correctFriend)
            temp = shuffleArray(temp)
            UserStore?.setGuessMeFriendList(temp)
          }
        } else {
          setStatus('available')
          UserStore?.setGuessMeFriendList(gganbuList?.slice(0, 3))
        }
        let res = await backendApis.guessMe(farmData?.guessMeIndex)
        setGuessMeData(res?.data)
      }
      if (farmData?.gganbuList && refresh === true) {
        fetch()
        setTimeout(() => {
          setRefresh(false)
        }, 500)
      }
    }, [farmData, refresh])

    const processLongName = (txt) => {
      return txt?.length > 8 ? txt.slice(0, 8) + '..' : txt
    }

    const submit = async () => {
      if (status === 'available') {
        let res = await backendApis.submitGuessMe({
          idx: guessMeData?.idx,
          gganbuData: UserStore?.guessMeFriendList?.[selectedFriendIdx],
        })
        if (res?.data?.status === 201) {
          ToastStore.toastOn({
            type: 'success',
            message: '친구에게 알림이 전송되었어요!',
            duration: 2000,
          })
        }
        setSubmitData(res?.data)
        if (res?.data?.status === 200) {
          ToastStore.toastOn({
            type: 'success',
            message: `${res?.data?.water}g의 물을 받았어요!`,
            duration: 2000,
          })
          setFarmData((prev) => {
            prev.guessMeIndex = null
            if (UserStore.waterExceeded) {
              prev.water += Math.round(res?.data?.water * 0.5)
            } else {
              prev.water += res?.data?.water
            }
            return prev
          })
        }
        resetGuess()
      } else if (status === 'fromPush') {
        const senderInfo = await backendApis.getUserBasicInfobyUID(
          token,
          'GET',
          { uid: alwayzInvitorId },
        )

        const answer =
          UserStore?.guessMeFriendList?.[selectedFriendIdx]?.userId?.toString()
        const correct = alwayzInvitorId?.toString()
        let temp = {
          status: 202,
          msg: 'wrong answer',
          water: 0,
          title: '이런, 오답이에요!',
          desc: `정답은 ${senderInfo?.userName}님 입니다`,
          emoji: '🥸',
        }
        const myInfo = await backendApis.getUserBasicInfobyUID(token, 'GET', {
          uid: farmData?.userId,
        })
        if (answer === correct) {
          const rewardAmount = Math.floor(Math.random() * (5 - 1) + 1)
          let timeInfo = await backendApis.getStandardTimeInfo()

          backendApis.sendNotification({
            receiverId: correct,
            title: `[나를 맞혀봐] ${myInfo?.userName}님이 문제를 맞췄어요!`,
            desc: `나를 맞혀봐 문제 또 보내기 🧐`,
            url: `alwayz://appLink/?shareType=gameTest`,
          })
          if (
            timeChecker(farmData?.lastGuessMeAnswerRewardAt, timeInfo?.data)
          ) {
            backendApis.giveReward(token, 'PUT', {
              loggingRewardName: 'guessMeCorrectAnswer',
              rewardType: 'water',
              valueAmount: rewardAmount,
            })
            backendApis.updatelastUpdatedAt(token, 'PUT', {
              updateType: 'lastGuessMeAnswerRewardAt',
            })

            temp.status = 200
            temp.msg = 'correct answer'
            temp.water = rewardAmount
            temp.title = '정답이에요!'
            temp.desc = `보상으로 물 ${rewardAmount}g 획득!`
            temp.emoji = '🥳'

            setFarmData((prev) => {
              prev.guessMeIndex = null
              if (UserStore.waterExceeded) {
                prev.water += Math.round(rewardAmount * 0.5)
              } else {
                prev.water += rewardAmount
              }
              return prev
            })
          } else {
            temp.status = 200
            temp.msg = 'correct answer'
            temp.water = 0
            temp.title = '정답이에요!'
            temp.desc = `오늘 보상은 이미 받았어요!`
            temp.emoji = '🥳'

            setFarmData((prev) => {
              prev.guessMeIndex = null
              return prev
            })
          }
        } else {
          backendApis.sendNotification({
            receiverId: correct,
            title: `[나를 맞혀봐]${myInfo?.userName}님이 문제를 못 맞혔어요!`,
            desc: `나를 맞혀봐 문제 또 보내기 🧐`,
            url: `alwayz://appLink/?shareType=gameTest`,
          })
        }
        setSubmitData(temp)
        setStatus('submitted')
      }
    }

    const shareKakao = () => {
      const inviteData = {
        shareType: 'AlfarmGganbuInvite',
        // referralCode: farmData?.referralCode,
        title: '[올팜] 우리 맞팜해요!',
        description: '서로의 농장에서 물과 비료를 받을 수 있어요.',
        bannerImage:
          'https://assets.ilevit.com/7ab3f811-7cfb-4108-a3b9-d9b3bfc13867.png',
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
    }

    const FriendButton = (data, idx) => {
      const selected = selectedFriendIdx === idx
      return (
        <div
          key={idx}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 8,
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            borderRadius: 8,
            width: '100%',
            background: selected ? '#FFCE3D' : 'white',
            border: selected
              ? '2px solid rgba(255, 202, 40, 1)'
              : '2px solid white',
          }}
          onClick={() => {
            setSelectedFriendIdx(idx)
            setOpenConfirmModal(true)
          }}
        >
          <img
            style={{
              width: '10vw',
              height: '10vw',
              borderRadius: 55,
              marginRight: 8,
              border: '1px solid #d9d9d9',
            }}
            src={data?.userImage}
            onError={(e) => {
              e.target.src = UserStore?.default_profile_image
            }}
            alt='profile'
          />
          <div style={{ color: 'black' }}>
            {processLongName(data?.userName)}
          </div>
        </div>
      )
    }

    return (
      <>
        <div style={styles.background}>
          <div style={styles.modalDiv}>
            <button
              style={styles.closeButton}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            {status === 'available' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  width: '100%',
                  marginTop: 8,
                  marginBottom: 0,
                }}
              >
                <div
                  style={{
                    marginTop: -8,
                    marginBottom: 8,
                    backgroundColor: '#fefefe',
                    // width: "12vw",
                    padding: '4px 12px 4px 12px',
                    borderRadius: 8,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // height: "7vw",
                    border: '1px solid #d9d9d9',
                    color: 'black',
                    boxShadow:
                      buttonPressed === 1
                        ? '-1px -2px 0px rgba(60, 60, 60, 0.35)'
                        : '1px 4px 0px rgba(60, 60, 60, 0.35)',
                  }}
                  onPointerDown={() => {
                    setButtonPressed(1)
                  }}
                  onPointerCancel={() => {
                    setButtonPressed(0)
                  }}
                  onPointerUp={() => {
                    setButtonPressed(0)
                  }}
                  onClick={() => {
                    resetQuestion()
                  }}
                >
                  문제
                  <div
                    style={{
                      // backgroundColor: "#d9d9d9",
                      marginLeft: 4,
                      padding: 12,
                      borderRadius: 99,
                      width: '4vw',
                      height: '4vw',
                      backgroundImage: 'url(../guessMe/refresh.png)',
                    }}
                  />
                </div>
                <div
                  style={{
                    color: 'black',
                    marginTop: -8,
                    marginBottom: 8,
                    backgroundColor: '#fefefe',
                    padding: '4px 12px 4px 12px',
                    borderRadius: 8,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #d9d9d9',
                    boxShadow:
                      buttonPressed === 2
                        ? '-1px -2px 0px rgba(60, 60, 60, 0.35)'
                        : '1px 4px 0px rgba(60, 60, 60, 0.35)',
                  }}
                  onPointerDown={() => {
                    setButtonPressed(2)
                  }}
                  onPointerCancel={() => {
                    setButtonPressed(0)
                  }}
                  onPointerUp={() => {
                    setButtonPressed(0)
                  }}
                  onClick={() => {
                    resetGganbu()
                  }}
                >
                  맞팜
                  <div
                    style={{
                      // backgroundColor: "#d9d9d9",
                      marginLeft: 4,
                      padding: 12,
                      borderRadius: 99,
                      width: '4vw',
                      height: '4vw',
                      backgroundImage: 'url(../guessMe/refresh.png)',
                    }}
                  />
                </div>
              </div>
            )}

            {status === 'inavailable' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '5vw',
                      marginTop: 16,
                      marginBottom: 16,
                    }}
                  >
                    맞팜이 최소 3명이 필요해요
                  </div>
                  <div
                    style={{
                      color: 'black',
                      fontSize: '18vw',
                      marginBottom: 16,
                      marginTop: 16,
                    }}
                  >
                    😭
                  </div>
                  <div
                    style={{
                      color: 'black',
                      fontSize: '5vw',
                      marginBottom: 16,
                      textAlign: 'center',
                      wordWrap: 'break-word',
                      wordBreak: 'keep-all',
                    }}
                  >
                    {'친구를 초대해 맞팜을 맺고 재밌는 질문들을 확인해 보세요!'}
                  </div>
                </div>
                <div
                  style={{
                    background:
                      'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
                    width: '60vw',
                    height: '14vw',
                    borderRadius: 99,
                    color: 'black',
                    fontFamily: 'Maplestory',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '5vw',
                    marginBottom: 16,
                  }}
                  onClick={() => {
                    shareKakao()
                    AlfarmEventLogger({
                      throttleRef,
                      locationType: 'modal',
                      locationName: ModalStore.isModalOpen,
                      eventType: 'click',
                      eventName: 'cta',
                      data: {},
                      collection: 'UserAlfarmClickLog',
                    })
                  }}
                >
                  {'초대하기'}
                </div>
              </>
            )}
            {(status === 'available' || status === 'fromPush') && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '5vw',
                      wordWrap: 'break-word',
                      wordBreak: 'keep-all',
                      textAlign: 'center',
                    }}
                  >
                    {`${
                      status === 'available'
                        ? '친구를 골라보세요'
                        : '누가 날 골랐을지 맞혀보세요'
                    }`}
                  </div>
                  <div
                    style={{
                      color: 'black',
                      fontSize: '18vw',
                      marginBottom: 12,
                      marginTop: 12,
                    }}
                  >
                    {guessMeData?.emoji}
                  </div>
                  <div
                    style={{
                      color: 'black',
                      fontSize: '5vw',
                      marginBottom: 16,
                      textAlign: 'center',
                      wordWrap: 'break-word',
                      wordBreak: 'keep-all',
                    }}
                  >
                    {guessMeData?.question}
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  {UserStore?.guessMeFriendList?.map((item, idx) =>
                    FriendButton(item, idx),
                  )}
                </div>
              </>
            )}
            {status === 'submitted' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '5vw',
                      marginTop: 16,
                      marginBottom: 16,
                    }}
                  >
                    {submitData?.title}
                  </div>
                  <div
                    style={{
                      color: 'black',
                      fontSize: '18vw',
                      marginBottom: 16,
                      marginTop: 8,
                    }}
                  >
                    {submitData?.emoji}
                  </div>
                  <div
                    style={{
                      color: 'black',
                      fontSize: '5vw',
                      marginBottom: 16,
                      textAlign: 'center',
                      wordWrap: 'break-word',
                      wordBreak: 'keep-all',
                    }}
                  >
                    {submitData?.desc}
                  </div>
                </div>
                <div
                  style={{
                    background:
                      'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
                    width: '60vw',
                    height: '14vw',
                    borderRadius: 99,
                    color: 'black',
                    fontFamily: 'Maplestory',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '5vw',
                    marginBottom: 16,
                  }}
                  onClick={() => {
                    setFarmData((prev) => {
                      prev.guessMeIndex = null
                      return prev
                    })
                    setStatus('available')
                    resetGuess()
                  }}
                >
                  {'나도 문제 보내기'}
                </div>
              </>
            )}
          </div>
        </div>
        {openConfirmModal && (
          <div
            style={{
              position: 'absolute',
              top: '0px',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.8)',
              zIndex: 1011,
            }}
          >
            <div
              style={{
                position: 'absolute',
                height: '40vw',
                width: '70vw',
                borderRadius: '8px',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                paddingTop: 16,
                paddingBottom: 16,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    color: 'black',
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                    lineHeight: '6vw',
                  }}
                >
                  {`${processLongName(
                    UserStore?.guessMeFriendList[selectedFriendIdx]?.userName,
                  )}님으로`}
                  <br />
                  선택하시겠어요?
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    border: '2px solid rgba(255,164,0,1)',
                    color: 'black',
                    marginLeft: '4vw',
                    marginRight: '4vw',
                    padding: '3vw 6vw 3vw 6vw',
                    fontFamily: 'maplestory',
                    borderRadius: '2vw',
                    fontSize: '4vw',
                  }}
                  onClick={() => setOpenConfirmModal(false)}
                >
                  취소
                </div>
                <div
                  style={{
                    marginLeft: '4vw',
                    marginRight: '4vw',
                    padding: '3vw 6vw 3vw 6vw',
                    fontFamily: 'maplestory',
                    borderRadius: '2vw',
                    backgroundColor: 'rgba(255,164,0,1)',
                    border: '2px solid rgba(255,164,0,1)',
                    color: 'black',
                    fontSize: '4vw',
                  }}
                  onClick={() => {
                    setOpenConfirmModal(false)
                    submit()
                  }}
                >
                  확인
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  },
)

export default GuessMeModal

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalDiv: {
    width: '90vw',
    height: '150vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10vw',
    backgroundImage: 'url(../guessMe/background.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '28vw',
    paddingBottom: '6vw',
  },
  closeButton: {
    width: '8vw',
    right: '2vw',
    top: '-6vw',
    position: 'absolute',
    zIndex: 999,
  },
  rewardFlag: {
    position: 'absolute',
    color: 'black',
    left: '10vw',
    borderBottom: '2px solid black',
    width: '14vw',
    marginTop: '1vw',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',

    // borderRadius: 16,
  },
  rewardFlagImg: {
    width: '7vw',
    height: '7vw',
  },
}
