import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import UserStore from '../../store/UserStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import ABStore from 'store/ABStore'

const InviteButton = ({
  isFilledOne = false,
  shareKakao,
  userImageOne,
  waterIconOne,
}) => {
  return isFilledOne ? (
    <div style={{ position: 'relative' }}>
      <img
        style={{
          width: '15vw',
          height: '15vw',
          borderRadius: '55vw',
          marginTop: '0.5vw',
        }}
        alt=''
        src={userImageOne}
        onError={(e) => {
          e.target.src = UserStore?.default_profile_image
        }}
      />
      <img
        style={{
          position: 'absolute',
          width: '13vw',
          left: '1vw',
          bottom: '-5vw',
          borderRadius: '55vw',
          marginTop: '0.5vw',
        }}
        alt=''
        src={waterIconOne}
      />
    </div>
  ) : (
    <div
      style={{
        width: '15vw',
        height: '15vw',
        backgroundImage: 'url(../lastMinuteInvite/invite.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      onClick={() => shareKakao()}
    />
  )
}

const InviteButtonSecond = ({
  isFilledTwo = false,
  shareKakao,
  userImageTwo,
  waterIconTwo,
}) => {
  return isFilledTwo ? (
    <div style={{ position: 'relative' }}>
      <img
        style={{
          width: '15vw',
          height: '15vw',
          borderRadius: '55vw',
          marginTop: '0.5vw',
        }}
        alt=''
        src={userImageTwo}
        onError={(e) => {
          e.target.src = UserStore?.default_profile_image
        }}
      />
      <img
        style={{
          position: 'absolute',
          width: '13vw',
          left: '1vw',
          bottom: '-5vw',
          borderRadius: '55vw',
          marginTop: '0.5vw',
        }}
        alt=''
        src={waterIconTwo}
      />
    </div>
  ) : (
    <div
      style={{
        width: '15vw',
        height: '15vw',
        backgroundImage: 'url(../lastMinuteInvite/invite.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      onClick={() => shareKakao()}
    />
  )
}

const InviteButtonThird = ({
  isFilledThree = false,
  shareKakao,
  userImageThree,
  waterIconThree,
}) => {
  return isFilledThree ? (
    <div style={{ position: 'relative' }}>
      <img
        style={{
          width: '15vw',
          height: '15vw',
          borderRadius: '55vw',
          marginTop: '0.5vw',
        }}
        alt=''
        src={userImageThree}
        onError={(e) => {
          e.target.src = UserStore?.default_profile_image
        }}
      />
      <img
        style={{
          position: 'absolute',
          width: '13vw',
          left: '1vw',
          bottom: '-5vw',
          borderRadius: '55vw',
          marginTop: '0.5vw',
        }}
        alt=''
        src={waterIconThree}
      />
    </div>
  ) : (
    <div
      style={{
        width: '15vw',
        height: '15vw',
        backgroundImage: 'url(../lastMinuteInvite/invite.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      onClick={() => shareKakao()}
    />
  )
}
const DailyInviteModal = observer(({ farmData, token, setFarmData }) => {
  const [isFilled, setIsFilled] = useState(false)
  const [userImage, setUserImage] = useState()
  const [infoModal, setInfoModal] = useState()

  const [isFilledOne, setIsFilledOne] = useState(false)
  const [isFilledTwo, setIsFilledTwo] = useState(false)
  const [isFilledThree, setIsFilledThree] = useState(false)
  const [userImageOne, setUserImageOne] = useState()
  const [userImageTwo, setUserImageTwo] = useState()
  const [userImageThree, setUserImageThree] = useState()
  const [waterIconOne, setWaterIconOne] = useState()
  const [waterIconTwo, setWaterIconTwo] = useState()
  const [waterIconThree, setWaterIconThree] = useState()
  const [canGetFertilizerSet, setCanGetFertilizerSet] = useState('')
  const [canClickButton, setCanClickButton] = useState(false)
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const throttleRef = useRef({})

  const shareKakao = () => {
    AlfarmEventLogger({
      throttleRef,
      locationType: 'modal',
      locationName: ModalStore.isModalOpen,
      eventType: 'click',
      eventName: 'cta',
      data: {},
      collection: 'UserAlfarmClickLog',
    })
    const inviteData = {
      shareType: 'AlfarmDailyInviteThirdVersion',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }

  const checkDailyInvite = async () => {
    const result = await backendApis.checkDailyInvite()
    setCanClickButton(result?.data?.buttonClickable)
    if (result?.data?.inviteList?.length > 0) {
      setIsFilledOne(true)
      setUserImageOne(result?.data?.inviteList?.[0]?.userThumbnailUImageUri)
      setWaterIconOne(result?.data?.inviteList?.[0]?.waterIcon)
    }
    if (result?.data?.inviteList?.length > 1) {
      setIsFilledTwo(true)
      setUserImageTwo(result?.data?.inviteList?.[1]?.userThumbnailUImageUri)
      setWaterIconTwo(result?.data?.inviteList?.[1]?.waterIcon)
    }
    if (result?.data?.inviteList?.length > 2) {
      setIsFilledThree(true)
      setUserImageThree(result?.data?.inviteList?.[2]?.userThumbnailUImageUri)
      setWaterIconThree(result?.data?.inviteList?.[2]?.waterIcon)
      setCanGetFertilizerSet(true)
    } else {
      setCanGetFertilizerSet(false)
    }
  }

  useEffect(() => {
    checkDailyInvite()
  }, [])

  const clearedInviteQuest = async () => {
    const result = await backendApis.clearedInviteQuest()
    if (result?.data?.success) {
      setCanClickButton(false)
      setFarmData({
        ...farmData,
        clearedInviteQuest: new Date(),
      })
    }
  }

  const Button = () => {
    if (canGetFertilizerSet === '0') {
      return <></>
    }
    if (canGetFertilizerSet) {
      if (!canClickButton) {
        return (
          <div
            style={{
              backgroundColor: '#C1C5CB',
              width: '60vw',
              height: '14vw',
              borderRadius: 99,
              color: 'white',
              fontFamily: 'Maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '1vw',
              left: '50%',
              bottom: '0%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              fontSize: '5vw',
            }}
          >
            오늘 이미 받았어요
          </div>
        )
      } else {
        return (
          <div
            style={{
              background:
                'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              width: '60vw',
              height: '14vw',
              borderRadius: 99,
              color: 'black',
              fontFamily: 'Maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '1vw',
              left: '50%',
              bottom: '0%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              fontSize: '5vw',
            }}
            onClick={() => {
              clearedInviteQuest()
              setCanClickButton(false)
            }}
          >
            고급비료 1개 받기
          </div>
        )
      }
    } else {
      return (
        <div
          style={{
            background: isButtonPressed
              ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressed
              ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
              : '',
            width: '60vw',
            height: '14vw',
            borderRadius: 99,
            color: 'black',
            fontFamily: 'Maplestory',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1vw',
            left: '50%',
            bottom: '0%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            fontSize: '5vw',
          }}
          onClick={() => {
            shareKakao()
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
        >
          친구 초대하기
        </div>
      )
    }
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90%',
            height: '90vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            backgroundImage: `url(../dailyInvite/dailyInviteModal${
              ABStore?.waterMaximumTester ? 'V2' : ''
            }.png)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <button
            style={{
              width: '10%',
              top: '-10%',
              right: '0%',
              transform: 'translate(0%, -50%)',
              position: 'absolute',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            style={{
              display: 'flex',
              width: '100%',
              paddingLeft: '10%',
              paddingRight: '10%',
              justifyContent: 'space-around',
              alignItems: 'center',
              top: '50%',
              right: '0%',
              transform: 'translate(0%, -50%)',
              position: 'absolute',
            }}
          >
            <InviteButton
              isFilledOne={isFilledOne}
              shareKakao={() => shareKakao()}
              userImageOne={userImageOne}
              waterIconOne={waterIconOne}
            />
            <InviteButtonSecond
              isFilledTwo={isFilledTwo}
              shareKakao={() => shareKakao()}
              userImageTwo={userImageTwo}
              waterIconTwo={waterIconTwo}
            />
            <InviteButtonThird
              isFilledThree={isFilledThree}
              shareKakao={() => shareKakao()}
              userImageThree={userImageThree}
              waterIconThree={waterIconThree}
            />
          </div>
          <button
            style={{
              width: '80%',
              left: '9%',
              bottom: '22%',
              transform: 'translate(0%, -50%)',
              position: 'absolute',
            }}
          >
            <img
              style={{
                zIndex: 101,
              }}
              src='/dailyInvite/modalText.png'
              alt=''
            />
          </button>
          {Button()}
        </div>
      </div>
    </>
  )
})
export default DailyInviteModal
