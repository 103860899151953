import React from 'react'
import { observer } from 'mobx-react-lite'
import NudgeBar from './nudgeBar'
import ModalStore from '../../store/ModalStore'

const NoonDailyWaterGetModal = observer(({ farmData }) => {
  const dailyWater = farmData?.dailyWater || 0
  const removedWater = farmData?.removedWater || 0
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            position: 'fixed',
            zIndex: 999,
            top: '30%',
            width: '85%',
            left: '7.5%',
          }}
        >
          {removedWater > 0 && (
            <NudgeBar
              text={`오전 7시부터 물 ${removedWater}g이 증발했어요`}
              bgColor='#F1F1F1'
              down
              bottom={220}
              textColor='#616161'
              // center
              width='80vw'
              right={-40}
              triangleRight={144}
            />
          )}
        </div>

        <img
          style={{
            position: 'absolute',
            width: '70%',
            zIndex: 3,
            top: '45%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
          alt=''
          src='/icon/dailyWaterGetIcon.png'
        />
        <div
          style={{
            zIndex: 3,
            fontSize: 38,
            fontWeight: 'bold',
            textAlign: 'center',
            width: '70%',
            position: 'absolute',
            top: '45%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            fontFamily: 'maplestory',
            textShadow: '-3px 0 black, 0 3px black, 3px 0 black, 0 -3px black',
          }}
        >
          {dailyWater}g
        </div>
        <div
          style={{
            position: 'absolute',
            zIndex: 3,
            textAlign: 'center',
            width: '100%',
            top: '63%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
          className='daily-water-text'
        >
          <div
            style={{
              fontSize: '6vw',
            }}
          >
            어제 채운 물 {dailyWater}g 받았어요
          </div>
        </div>

        <button
          style={{
            width: '30%',
            height: 55,
            top: '73%',
            left: '50%',
            transform: 'translate(-50%, -80%)',
            position: 'absolute',
            zIndex: 999,
            borderRadius: 30,
            background:
              'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            fontFamily: 'maplestory',
            color: '#402C24',
            fontSize: 18,
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <div
            style={{
              color: '#402C24',
              fontFamily: 'maplestory',
              fontSize: 18,
            }}
          >
            확인
          </div>
        </button>
      </div>
      ;
    </>
  )
})

export default NoonDailyWaterGetModal
