import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../store/ModalStore'
import TimeStore from '../../../store/TimeStore'
import { useInterval } from 'react-use'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const instructionMap = [
  'STOP 버튼을 누르면 블럭이 멈춰요',
  '블럭이 겹치도록 타이밍에 맞춰 버튼을 누르세요',
  '블럭을 겹쳐서 멈춰야 블럭이 쌓여요',
  '블록을 높이 쌓을수록 보상이 늘어나요!',
  '하는 중간에 화면을 닫으면 보상이 지급되지 않아요',
]

const FertilizerTowerGameModal = observer(
  ({ token, farmData, setFarmData, showRewardToast }) => {
    const [levels, setLevels] = useState([
      [0, 0, 0, 0, 0, 0, 0], //1
      [0, 0, 0, 0, 0, 0, 0], //2
      [0, 0, 0, 0, 0, 0, 0], //3
      [0, 0, 0, 0, 0, 0, 0], //4
      [0, 0, 0, 0, 0, 0, 0], //5
      [0, 0, 0, 0, 0, 0, 0], //6
      [0, 0, 0, 0, 0, 0, 0], //7
      [0, 0, 0, 0, 0, 0, 0], //8
      [0, 0, 0, 0, 0, 0, 0], //9
      [0, 0, 0, 0, 0, 0, 0], //10
    ])
    const [CTAPressed, setCTAPressed] = useState(false)
    const [currentLevel, setCurrentLevel] = useState(0)
    const [xCoordinate, setXCoordinate] = useState(
      Math.floor(Math.random() * levels[0].length - 1),
    )
    const [dir, setDir] = useState(1)
    const [blockWidth, setBlockWidth] = useState(3)
    const [difficulty, setDifficulty] = useState(280)
    const [gameOver, setGameOver] = useState(false)
    const [pause, setPause] = useState(false)
    const [originalCur, setOriginalCur] = useState([])
    const [blinkOn, setBlinkOn] = useState(false)
    const [blinkCount, setBlinkCount] = useState(0)
    const timeOutRef = useRef()
    const [rewardFertilizerSetCount, setRewardFertilizerSetCount] = useState(3)
    const [rewardFertilizerCount, setRewardFertilizerCount] = useState(0)
    const [gameStart, setGameStart] = useState(false)
    const throttleRef = useRef({})

    useEffect(() => {
      if (gameOver) {
        showRewardToast(rewardFertilizerCount, 'fertilizer')
        updateFertilizerTowerGameData()
      }
    }, [gameOver])

    const updateFertilizerTowerGameData = async () => {
      if (rewardFertilizerCount > 0) {
        backendApis.giveReward(token, 'PUT', {
          loggingRewardName: 'fertilizerTowerGame',
          rewardType: 'fertilizer',
          valueAmount: rewardFertilizerCount,
        })
      }

      backendApis.updateSpecificField({
        fieldName: 'fertilizerTowerGameData.rewardCount',
        value: Number(rewardFertilizerSetCount),
      })
      setFarmData((prev) => {
        let temp = { ...prev }
        let tempFertilizerTowerGameData = { ...prev?.fertilizerTowerGameData }
        temp.fertilizer += rewardFertilizerCount
        tempFertilizerTowerGameData.rewardCount = rewardFertilizerSetCount
        return temp
      })
    }

    useInterval(
      () => {
        setXCoordinate((prev) => {
          if (prev + dir < 0 || prev + dir + blockWidth > levels[0].length) {
            setDir((prev) => prev * -1)
            return prev + dir * -1
          }
          return prev + dir
        })
        setLevels((prev) => {
          let map = prev
          let current = map[map.length - 1 - currentLevel]
          current = current.map((item) => 0)
          for (let i = 0; i < blockWidth; i++) {
            if (xCoordinate + i < current.length) current[xCoordinate + i] = 1
            else break
          }
          map[map.length - 1 - currentLevel] = current

          return map
        })
      },
      pause || gameOver ? null : difficulty,
    )

    useInterval(
      () => {
        if (blinkCount > 5) return
        setBlinkCount((prev) => prev + 1)
        let prev = levels[levels.length - 1 - currentLevel + 1]
        let cur = [...originalCur]
        let sum = cur.reduce((a, c) => a + c)
        if (blinkOn) {
          setLevels((prev) => {
            prev[levels.length - 1 - currentLevel] = cur
            return prev
          })
        } else {
          for (let i = 0; i < prev.length; i++) {
            if (cur[i] === 1 && prev[i] === 0) {
              cur[i] = 0
            }
          }
          let afterSum = cur.reduce((a, c) => a + c)
          if (afterSum === sum) {
            clearTimeout(timeOutRef.current)
            let localGameOver = checkStack()
            if (currentLevel === levels.length - 1) {
              setGameOver(true)
              let prev = levels[levels.length - 1 - currentLevel + 1]
              let cur = levels[levels.length - 1 - currentLevel]
              if (prev.indexOf(1) === cur.indexOf(1)) {
                setRewardFertilizerCount(3)
                setRewardFertilizerSetCount(6)
              }
            } else {
              if (localGameOver) {
                setCurrentLevel((prev) => prev + 1)
              }
              setXCoordinate(Math.floor(Math.random() * levels[0].length - 1))
            }
            setPause(false)
          }
          setLevels((prev) => {
            prev[levels.length - 1 - currentLevel] = cur
            return prev
          })
        }
        setBlinkOn((prev) => !prev)
      },
      pause ? 300 : null,
    )

    // useInterval(() => {
    //   nextLevel();
    // }, 3000);

    useEffect(() => {
      if (currentLevel === 4) {
        setRewardFertilizerSetCount(4)
        setRewardFertilizerCount(1)
      } else if (currentLevel === 7) {
        setRewardFertilizerCount(2)
        setRewardFertilizerSetCount(5)
      }
    }, [currentLevel])

    useEffect(() => {
      if (currentLevel < 3) {
        setBlockWidth(3)
      } else if (currentLevel < 6) {
        setBlockWidth(2)
      } else {
        setBlockWidth(1)
      }
    }, [currentLevel])

    const InstructionText = ({ num, text }) => {
      return (
        <div
          className='min-w-1 flex px-[8px] mb-[8px] text-left text-[4vw] w-full'
          style={{
            wordBreak: 'keep-all',
          }}
        >
          <div className='text-[#442b22] mr-1'>{num}.</div>
          <div className='text-[#442b22] flex-1'>{text}</div>
        </div>
      )
    }

    const nextLevel = () => {
      let x = Math.floor(Math.random() * levels[0].length - 1)

      setDifficulty((prev) => (prev -= 20))
      setBlinkCount(0)
      setOriginalCur([...levels[levels.length - 1 - currentLevel]])
      setPause(true)
      if (currentLevel === 0) {
        checkStack()
        setCurrentLevel((prev) => prev + 1)
        setXCoordinate(x)

        setPause(false)
      } else {
        timeOutRef.current = setTimeout(() => {
          if (!gameOver) {
            let localGameOver = checkStack()

            if (currentLevel === levels.length - 1) {
              setGameOver(true)
              setPause(false)

              return
            }
            if (localGameOver) {
              setCurrentLevel((prev) => prev + 1)
            }

            setXCoordinate(x)

            setTimeout(() => {
              setPause(false)
            }, 400)
          }
        }, 2000)
      }
    }

    const checkStack = () => {
      if (currentLevel === 0) {
        return
      }
      // 전레벨 레이어 가져옴
      let prev = levels[levels.length - 1 - currentLevel + 1]

      let cur = levels[levels.length - 1 - currentLevel]
      for (let i = 0; i < prev.length; i++) {
        if (cur[i] === 1 && prev[i] === 0) {
          cur[i] = 0
        }
      }

      if (cur.reduce((a, c) => a + c) === 0) {
        setGameOver(true)
        return false
      }
      setLevels((prev) => {
        prev[levels.length - 1 - currentLevel] = cur
        return prev
      })
      return true
      // 지금 레이어랑 비교해서 겹치는거만 살림
    }

    const sendToPurchaseScreen = () => {
      AlfarmEventLogger({
        throttleRef,
        locationType: 'modal',
        locationName: ModalStore.isModalOpen,
        eventType: 'click',
        eventName: 'cta',
        data: {},
        collection: 'UserAlfarmClickLog',
      })

      const purchaseData = {
        enteringComponent: `Alfarm_fertilizerTowerGame_${rewardFertilizerSetCount}`,
        text: '비료 쌓기',
        source:
          'https://assets.ilevit.com/7816e13b-2a7a-499a-a266-517dfba4dd07.png',
        rewardAmount: rewardFertilizerSetCount,
        rewardType: 'fertilizerSet',
        rewardText: `상품을 구매하면 고급비료 ${rewardFertilizerSetCount}개를 받아요`,
      }

      window.location.href = `#commonPurchaseReward.${JSON.stringify(
        purchaseData,
      )}`
      ModalStore.setIsModalOpen('basic')
    }

    return (
      <>
        <div style={styles.background}>
          <div style={styles.modalDiv}>
            <button
              style={styles.closeButton}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            {gameStart ? (
              <>
                <div
                  style={{
                    marginBottom: 16,
                    borderRadius: 8,
                    padding: 8,
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  블럭을 쌓고 비료를 얻어보세요
                </div>
                <div
                  style={{
                    flex: 1,
                    width: '100%',
                    // border: "2px solid black",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                  }}
                >
                  {levels.map((level, idx) => {
                    level = level.map((item) => (
                      <div
                        style={{
                          width: '8vw',
                          height: '8vw',
                          backgroundColor: item === 0 ? 'white' : 'red',
                          border:
                            item === 0 ? '2px solid #eee' : '2px solid black',
                          borderRadius: '1.5vw',
                        }}
                      />
                    ))
                    return (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {level}
                        {idx === 0 && (
                          <div style={styles.rewardFlag}>
                            <img
                              style={styles.rewardFlagImg}
                              src={'../fertilizerTowerGame/fertilizer.png'}
                            />
                            x3
                          </div>
                        )}
                        {idx === 3 && (
                          <div style={styles.rewardFlag}>
                            <img
                              style={styles.rewardFlagImg}
                              src={'../fertilizerTowerGame/fertilizer.png'}
                            />
                            x2
                          </div>
                        )}
                        {idx === 6 && (
                          <div style={styles.rewardFlag}>
                            <img
                              style={styles.rewardFlagImg}
                              src={'../fertilizerTowerGame/fertilizer.png'}
                            />
                            x1
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
                <div
                  style={{
                    color: '#402C24',
                    fontSize: '5vw',
                    fontFamily: 'maplestory',
                    textAlign: 'center',
                    background: gameOver
                      ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                      : 'linear-gradient(180deg, #f7b146 31%, #f6a745 64%, #f03f36 100%)',
                    boxShadow: CTAPressed
                      ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                      : '',
                    padding: '5vw',
                    borderRadius: gameOver ? '50vw' : 16,
                    width: '100%',
                    // marginTop: "8%",
                    // marginTop: "4vw",
                    marginTop: 16,
                    marginBottom: 16,
                    fontWeight: gameOver ? 'normal' : 'bold',
                  }}
                  onPointerDown={() => {
                    setCTAPressed(true)
                    if (!gameOver && !pause) {
                      nextLevel()
                    } else if (gameOver) {
                      sendToPurchaseScreen()
                    }
                  }}
                  onPointerCancel={() => {
                    setCTAPressed(false)
                  }}
                  onPointerUp={() => {
                    setCTAPressed(false)
                  }}
                >
                  {gameOver
                    ? `상품 사고 고급비료 ${rewardFertilizerSetCount}개 받기`
                    : 'STOP'}
                </div>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // border: "1px solid black",
                  height: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                    margin: 8,
                    fontSize: '5vw',
                  }}
                >
                  하는 방법
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    marginBottom: 8,
                    fontSize: '4vw',
                  }}
                >
                  {instructionMap.map((item, idx) => (
                    <InstructionText
                      key={Math.random()}
                      num={idx + 1}
                      text={item}
                    />
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    padding: 8,
                    borderRadius: 8,
                  }}
                >
                  <img
                    style={{
                      width: '20vw',
                      padding: 8,
                      border: '1px solid black',
                      borderRadius: 8,
                    }}
                    src={'../fertilizerTowerGame/tutorial1.png'}
                  />
                  <img
                    style={{
                      width: '20vw',
                      padding: 8,
                      border: '1px solid black',
                      borderRadius: 8,
                    }}
                    src={'../fertilizerTowerGame/tutorial2.png'}
                  />
                  <img
                    style={{
                      width: '20vw',
                      padding: 8,
                      border: '1px solid black',
                      borderRadius: 8,
                    }}
                    src={'../fertilizerTowerGame/tutorial3.png'}
                  />
                </div>
                <div
                  style={{
                    color: '#402C24',
                    fontSize: '5vw',
                    fontFamily: 'maplestory',
                    textAlign: 'center',
                    background:
                      'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                    boxShadow: CTAPressed
                      ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                      : '',
                    padding: '5vw',
                    borderRadius: '50vw',
                    width: '100%',
                    // marginTop: "8%",
                    // marginTop: "4vw",
                    marginTop: 16,
                    marginBottom: 16,
                    fontWeight: 'bold',
                    justifySelf: 'flex-end',
                  }}
                  onPointerDown={() => {
                    setCTAPressed(true)
                  }}
                  onPointerCancel={() => {
                    setCTAPressed(false)
                  }}
                  onPointerUp={() => {
                    setCTAPressed(false)
                    setGameStart(true)
                  }}
                >
                  {'시작하기'}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  },
)

export default FertilizerTowerGameModal

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalDiv: {
    width: '90vw',
    height: '150vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10vw',
    backgroundImage: 'url(../fertilizerTowerGame/background.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '28vw',
    paddingBottom: '6vw',
  },
  closeButton: {
    width: '8vw',
    right: '2vw',
    top: '-6vw',
    position: 'absolute',
    zIndex: 999,
  },
  rewardFlag: {
    position: 'absolute',
    color: 'black',
    left: '10vw',
    borderBottom: '2px solid black',
    width: '14vw',
    marginTop: '1vw',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',

    // borderRadius: 16,
  },
  rewardFlagImg: {
    width: '7vw',
    height: '7vw',
  },
}
