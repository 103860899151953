import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../..//utils/backendApis'
import ModalStore from '../../store/ModalStore'
import UserStore from 'store/UserStore'

const AnnouncementModal = observer(
  ({
    setShowModal,
    farmData,
    setFarmData,
    token,
    announcementList,
    setAnnouncementList,
    setLastRewardAnnouncementNumber,
    lastRewardAnnouncementDoc,
    setLastRewardAnnouncementDoc,
    setUserAnnouncementNumber,
    showRewardToast,
  }) => {
    const [page, setPage] = useState(1)
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(
      lastRewardAnnouncementDoc,
    )
    const [CTAtext, setCTAtext] = useState('닫기')
    const [rewardList, setRewardList] = useState([])

    useEffect(() => {
      setUserAnnouncementNumber()
    }, [])

    useEffect(() => {
      rewardList.forEach((item, i) => {
        setTimeout(() => {
          if (item?.rewardType === 'water' && UserStore.waterExceeded) {
            item.rewardAmount = Math.round(item.rewardAmount * 0.5)
          }
          showRewardToast(item?.rewardAmount, item?.rewardType)
        }, i * 750)
      })
    }, [rewardList])

    const date = new Date(selectedAnnouncement?.createdAt)

    function padValue(value) {
      return value < 10 ? `0${value}` : value.toString()
    }

    const year = date.getFullYear()
    const month = padValue(date.getMonth() + 1)
    const day = padValue(date.getDate())

    const formattedDate = `${year}-${month}-${day}`

    const getAnnouncementReward = async (doc) => {
      await backendApis.getAnnouncementReward({ number: doc?.number })

      let tempFarmData = { ...farmData }

      tempFarmData.lastRewardAnnouncementNumber = doc?.number
      for (let i = 0; i < doc?.rewardInfoList?.length; i++) {
        const rewardInfo = doc?.rewardInfoList?.[i]
        switch (rewardInfo?.rewardType) {
          case 'water':
            if (UserStore.waterExceeded) {
              tempFarmData.water += Math.round(rewardInfo?.rewardAmount * 0.5)
            } else {
              tempFarmData.water += rewardInfo?.rewardAmount
            }
            break
          case 'fertilizer':
            tempFarmData.fertilizer += rewardInfo?.rewardAmount
            break
          case 'fertilizerSet':
            tempFarmData.fertilizerSet += rewardInfo?.rewardAmount
            break
          default:
            break
        }
      }
      setRewardList(doc?.rewardInfoList)

      setFarmData(tempFarmData)
      setLastRewardAnnouncementNumber(
        Math.max(
          selectedAnnouncement?.number,
          farmData?.lastRewardAnnouncementNumber || 0,
        ),
      )
    }

    useEffect(() => {
      if (
        selectedAnnouncement &&
        selectedAnnouncement?.type === 'reward' &&
        (farmData?.lastRewardAnnouncementNumber || 0) <
          selectedAnnouncement.number
      ) {
        setCTAtext('보상받기')
      }
    }, [])

    useEffect(() => {
      if (page > 1) {
        async function fetchData() {
          let res = await backendApis.getAnnouncementList(token, 'GET', {
            page: page,
          })
          setAnnouncementList(res.data)
        }
        fetchData()
      }
    }, [page])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage: 'url(../announcement/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '40vw',
              paddingBottom: '16%',
              // marginBottom: "15%",
            }}
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: '5vw',
                position: 'absolute',
                zIndex: 999,
                // borderRadius: "1px solid black",
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
                setLastRewardAnnouncementDoc(false)
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                // border: "1px solid black",
                width: '100%',
                height: '100%',
                // marginBottom: "12vw",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                // border: "1px solid black",
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  paddingLeft: 24,
                  paddingRight: 24,
                  // border: "1px solid black",
                  height: '80vw',
                  overflowY: 'scroll',
                  paddingTop: 16,
                }}
              >
                {selectedAnnouncement && (
                  <div
                    style={{
                      width: '100%',
                      // border: "1px solid black",
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        color: 'black',
                        fontFamily: 'maplestory',
                        fontWeight: 'bold',
                        width: '100%',
                        overflowWrap: 'break-word',
                      }}
                    >
                      {selectedAnnouncement.title}
                    </div>
                    <div
                      style={{
                        alignSelf: 'flex-end',
                        color: '#999',
                        fontSize: '0.8rem',
                        margin: '8px 0px 8px 0px',
                      }}
                    >
                      {formattedDate}
                      {/* {`${new Date(
                      selectedAnnouncement.createdAt
                    ).getFullYear()}-${
                      new Date(selectedAnnouncement.createdAt).getMonth() < 10
                        ? "0" +
                          (
                            new Date(
                              selectedAnnouncement.createdAt
                            ).getMonth() + 1
                          ).toString()
                        : new Date(selectedAnnouncement.createdAt).getMonth() +
                          1
                    }-${
                      new Date(selectedAnnouncement.createdAt).getDate() < 10
                        ? "0" +
                          (
                            new Date(selectedAnnouncement.createdAt).getDate() +
                            1
                          ).toString()
                        : new Date(selectedAnnouncement.createdAt).getDate() + 1 */}
                      {/* }`} */}
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontFamily: 'maplestory',
                        fontSize: '1rem',
                        width: '100%',
                        // border: "1px solid black",
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        wordBreak: 'keep-all',
                      }}
                    >
                      {`${selectedAnnouncement.description}`}
                    </div>
                  </div>
                )}
                {!selectedAnnouncement &&
                  announcementList.map((e, i) => {
                    return (
                      <div
                        key={Math.random()}
                        style={{
                          // marginLeft: 8,
                          width: '100%',
                          height: 50,
                          backgroundColor: '#F1E2B7',
                          marginBottom: 4,
                          borderRadius: 4,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            color: 'black',
                            fontFamily: 'maplestory',
                            // borderBottom: "1px solid rgb(212, 212, 212)",
                            padding: '4px 0px 4px 0px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            // border: "1px solid black",
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectedAnnouncement(e)

                            if (
                              (farmData?.lastRewardAnnouncementNumber || 0) <
                                e.number &&
                              selectedAnnouncement?.type === 'reward'
                            ) {
                              setCTAtext('보상받기')
                            } else {
                              setCTAtext('뒤로가기')
                            }
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              height: '100%',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                position: 'relative',
                                width: '11vw',
                                height: '11vw',
                                color: 'black',
                                fontFamily: 'maplestory',
                                marginLeft: -8,
                                fontSize: '0.8rem',
                                backgroundImage:
                                  'url(../announcement/letter.png)',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                              }}
                            />

                            <div
                              style={{
                                color: 'black',
                                fontFamily: 'maplestory',
                                flex: 1,
                                marginLeft: 8,
                                width: '45vw',
                                height: '5vw',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {e.title}
                            </div>
                          </div>
                          <div
                            style={{
                              color: 'black',
                              fontFamily: 'maplestory',
                              fontSize: '0.6rem',
                              marginRight: 8,
                            }}
                          >
                            {`${
                              new Date(e.createdAt).getMonth() < 9
                                ? '0' +
                                  (
                                    new Date(e.createdAt).getMonth() + 1
                                  ).toString()
                                : new Date(e.createdAt).getMonth() + 1
                            }-${
                              new Date(e.createdAt).getDate() < 10
                                ? '0' +
                                  new Date(e.createdAt).getDate().toString()
                                : new Date(e.createdAt).getDate()
                            }`}
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
              <div
                style={{
                  background:
                    'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                  width: '60vw',
                  height: '14vw',
                  borderRadius: 99,
                  color: 'black',
                  fontFamily: 'Maplestory',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '-6vw',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (
                    selectedAnnouncement?.type === 'reward' &&
                    (farmData?.lastRewardAnnouncementNumber || 0) <
                      selectedAnnouncement?.number
                  ) {
                    getAnnouncementReward(selectedAnnouncement)
                    setLastRewardAnnouncementDoc(false)
                    setSelectedAnnouncement(false)
                    setCTAtext('닫기')
                  } else if (selectedAnnouncement) {
                    setSelectedAnnouncement(false)
                    setCTAtext('닫기')
                  } else {
                    ModalStore.setIsModalOpen('basic')
                    setLastRewardAnnouncementDoc(false)
                  }
                }}
              >
                {CTAtext}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default AnnouncementModal
