import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

const inviteSuccessModal = observer(({ setInviteSuccessModal, rewardType }) => {
  const [source, setSource] = useState()

  useEffect(() => {
    switch (rewardType) {
      case 'changePlantInvite':
        setSource('/icon/changePlantSuccess.png')
        break
      case 'puppy3days':
        setSource('/icon/puppyReward.png')
        break
      default:
        setSource('/icon/dailyWaterGetIcon.png')
        break
    }
  }, [rewardType])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: '0',
          top: '0',
          bottom: '0',
          right: '0',
          zIndex: 1100,
        }}
      >
        <div
          style={{
            position: 'fixed',
            zIndex: 1999,
            top: '30%',
            width: '60%',
            left: '20%',
          }}
        >
          <img alt='' src={source} />

          <div
            style={{
              position: 'absolute',
              zIndex: 13,
              fontSize: 22,
              textAlign: 'center',
              width: '100%',
              top: '28vh',
            }}
            className='daily-water-text'
          >
            <div
              style={{
                fontSize: 18,
              }}
            >
              친구 초대 보상을 받았어요
            </div>
          </div>
        </div>

        <button
          style={{
            width: '30%',
            height: 55,
            top: '73%',
            left: '50%',
            transform: 'translate(-50%, -80%)',
            position: 'absolute',
            zIndex: 1999,
            borderRadius: 30,
            background:
              'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            fontFamily: 'maplestory',
            color: '#402C24',
            fontSize: 18,
          }}
          onClick={() => {
            setInviteSuccessModal(false)
          }}
        >
          <div
            style={{
              color: '#402C24',
              fontFamily: 'maplestory',
              fontSize: 18,
            }}
          >
            확인
          </div>
        </button>

        <div
          style={{
            backgroundColor: 'black',
            position: 'absolute',
            overlay: {
              background: 'rgba(0,0,0,0)',
            },
            width: '100%',
            height: '100%',
            opacity: '0.8',
          }}
        />
      </div>
      ;
    </>
  )
})

export default inviteSuccessModal
