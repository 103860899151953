import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import UserStore from 'store/UserStore'

const LuckydrawRewardModal = observer(
  ({
    luckydrawRewardType,
    setShowLuckydrawRewardModal,
    setLuckydrawRewardType,
    setCurrentTileIndex,
    setCanPressLuckydraw,
    setShowDrawCost,
    farmData,
    setFarmData,
    luckydrawPurchaseReward,
    loadUserCoupon,
  }) => {
    useEffect(() => {
      let tmpFarmData = farmData
      if (luckydrawRewardType === 101) {
        tmpFarmData.fertilizer += 1
        setFarmData(tmpFarmData)
      } else if (luckydrawRewardType === 102) {
        luckydrawPurchaseReward()
      } else if (luckydrawRewardType === 103) {
      } else if (luckydrawRewardType === 104) {
        if (UserStore.waterExceeded) {
          tmpFarmData.water += 5
        } else {
          tmpFarmData.water += 10
        }
        setFarmData(tmpFarmData)
      } else if (luckydrawRewardType === 105) {
        if (UserStore.waterExceeded) {
          tmpFarmData.water += 50
        } else {
          tmpFarmData.water += 100
        }
        setFarmData(tmpFarmData)
      } else if (luckydrawRewardType === 106) {
        tmpFarmData.fertilizerSet += 1
        setFarmData(tmpFarmData)
      } else if (luckydrawRewardType === 107) {
        loadUserCoupon()
      } else if (luckydrawRewardType === 108) {
        if (UserStore.waterExceeded) {
          tmpFarmData.water += 15
        } else {
          tmpFarmData.water += 30
        }
        setFarmData(tmpFarmData)
      } else {
      }
    }, [])

    const LevelUpRewardModal = () => {
      if (luckydrawRewardType === 101) {
        return (
          <>
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/luckydrawReward1.png'}
            />
          </>
        )
      }
      if (luckydrawRewardType === 102) {
        return (
          <>
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/luckydrawReward2.png'}
            />
          </>
        )
      }
      if (luckydrawRewardType === 103) {
        return (
          <>
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/luckydrawReward3.png'}
            />
          </>
        )
      }
      if (luckydrawRewardType === 104) {
        return (
          <>
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/luckydrawReward4.png'}
            />
          </>
        )
      }
      if (luckydrawRewardType === 105) {
        return (
          <>
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/luckydrawReward5.png'}
            />
          </>
        )
      }
      if (luckydrawRewardType === 106) {
        return (
          <>
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/luckydrawReward6.png'}
            />
          </>
        )
      }
      if (luckydrawRewardType === 107) {
        return (
          <>
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/luckydrawReward7.png'}
            />
          </>
        )
      }
      if (luckydrawRewardType === 108) {
        return (
          <>
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/luckydrawReward8.png'}
            />
          </>
        )
      }
    }
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '35%',
            }}
          >
            {LevelUpRewardModal()}
            <button
              style={{
                width: '35%',
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: 'black',
                fontSize: '16px',
                borderRadius: '30px',
                padding: 14,
                position: 'absolute',
                left: '32%',
                top: '36vw',
              }}
              onClick={() => {
                setShowLuckydrawRewardModal(false)
                setLuckydrawRewardType(0)
                setCurrentTileIndex(0)
                setCanPressLuckydraw(true)
                setShowDrawCost(false)
              }}
            >
              확인
            </button>
          </div>
        </div>
      </>
    )
  },
)

export default LuckydrawRewardModal
