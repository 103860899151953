import backendApis from './backendApis'

class apiHandler {
  async kakaoLinkShare({
    shareType,
    title,
    description,
    imageUrl,
    itemId,
    payload,
    templateId,
  }) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'kakaoShare',
        data: {
          shareType,
          title,
          description,
          imageUrl,
          itemId,
          payload,
          templateId,
        },
      }),
    )
  }
}

export default new apiHandler()
