import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'

const LuckyPiggyQuest = observer(
  ({ token, ongoingData, setOngoingData, onClick }) => {
    const [newUserCount, setNewUserCount] = useState(0)
    const [canGetFirstQuest, setCanGetFirstQuest] = useState(false)

    useEffect(() => {
      checkOngoingLuckyPiggyData()
    }, [token, ongoingData])

    const checkOngoingLuckyPiggyData = async () => {
      const result = await backendApis.checkOngoingLuckyPiggyData()

      setNewUserCount(result?.data?.length)
      if (result?.data?.length < 1) {
        setCanGetFirstQuest(false)
      } else if (result?.data?.length >= 1 && !ongoingData?.clearedFirstQuest) {
        setCanGetFirstQuest(true)
      } else if (result?.data?.length >= 1 && ongoingData?.clearedFirstQuest) {
        setCanGetFirstQuest(false)
      }
    }

    const firstQuestButton = async () => {
      if (newUserCount < 1) {
        return false
      } else if (newUserCount >= 1 && !ongoingData?.clearedFirstQuest) {
        const result = await backendApis.clearedFirstQuest()
        if (result?.data?.success) {
          let tempOngoingData = ongoingData
          tempOngoingData.coin += 30
          setOngoingData(tempOngoingData)
        }
      }
    }

    const firstQuestBanner = () => {
      if (newUserCount < 1) {
        return (
          <button
            style={{
              width: '80%',
              fontFamily: 'maplestory',
              background: '#FFEEEC',
              borderRadius: '3vw',
              marginLeft: '10vw',
              marginTop: '2vw',
              fontSize: '4.5vw',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '4vw 5vw 3vw 4vw',
            }}
            onClick={onClick}
          >
            <div>
              <div
                style={{
                  color: 'black',
                }}
              >
                올팜 안 해본 친구 1명 초대하기
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  style={{
                    zIndex: 1,
                    width: '9vw',
                    height: '9vw',
                    marginTop: '1vw',
                  }}
                  src='/luckyPiggy/coinIcon.png'
                />

                <div
                  style={{
                    color: '#855C36',
                    zIndex: 1,
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                  }}
                >
                  30개
                </div>
              </div>
            </div>
            <img
              style={{
                zIndex: 1,
                width: '8vw',
                height: '8vw',
              }}
              src='/luckyPiggy/questIndicator.png'
            />
          </button>
        )
      } else if (canGetFirstQuest) {
        return (
          <button
            style={{
              width: '80%',
              fontFamily: 'maplestory',
              background: '#FFEEEC',
              borderRadius: '3vw',
              marginLeft: '10vw',
              marginTop: '2vw',
              fontSize: '4.5vw',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '4vw 3vw 3vw 4vw',
            }}
            onClick={() => {
              firstQuestButton()
              setCanGetFirstQuest(false)
            }}
          >
            <div>
              <div
                style={{
                  color: 'black',
                }}
              >
                올팜 안 해본 친구 1명 초대하기
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  style={{
                    zIndex: 1,
                    width: '9vw',
                    height: '9vw',
                    marginTop: '1vw',
                  }}
                  src='/luckyPiggy/coinIcon.png'
                />

                <div
                  style={{
                    color: '#855C36',
                    zIndex: 1,
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                  }}
                >
                  30개
                </div>
              </div>
            </div>

            <img
              style={{
                zIndex: 1,
                width: '8vw',
                height: '8vw',
              }}
              src='/luckyPiggy/questIndicator.png'
            />
          </button>
        )
      } else if (!canGetFirstQuest) {
        return (
          <button
            style={{
              width: '80%',
              fontFamily: 'maplestory',
              background: '#FFEEEC',
              borderRadius: '3vw',
              marginLeft: '10vw',
              marginTop: '2vw',
              fontSize: '4.5vw',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '4vw 5vw 3vw 4vw',
            }}
          >
            <div>
              <div
                style={{
                  color: 'black',
                }}
              >
                올팜 안 해본 친구 1명 초대하기
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  style={{
                    zIndex: 1,
                    width: '9vw',
                    height: '9vw',
                    marginTop: '1vw',
                  }}
                  src='/luckyPiggy/coinIcon.png'
                />

                <div
                  style={{
                    color: '#855C36',
                    zIndex: 1,
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                  }}
                >
                  30개
                </div>
              </div>
            </div>
            <div
              style={{
                color: '#855C36',
                zIndex: 1,
                fontFamily: 'maplestory',
                fontSize: '4vw',
              }}
            >
              완료
            </div>
          </button>
        )
      }
    }

    return <div>{firstQuestBanner()}</div>
  },
)

export default LuckyPiggyQuest
