import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import ModalStore from 'store/ModalStore'
import AlwayzUserStore from 'store/AlwayzUserStore'

const LotteryTeamDealModal = observer(
  ({ token, farmData, setFarmData, itemType }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '180vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              //이미지 바꾸기
              backgroundImage:
                'url(../lotteryTeamDeal/lotteryTeamDealModalImage.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <button
              style={{
                width: '10%',
                top: '20vw',
                right: '8%',
                position: 'absolute',
                zIndex: 1113,
                background: 'black',
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                background: isButtonPressed ? '#F7C82A' : '#F7C82A',
                boxShadow: isButtonPressed
                  ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                  : '',
                width: '65vw',
                height: '14vw',
                borderRadius: '100vw',
                color: 'black',
                fontFamily: 'maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '4.5vw',
                position: 'absolute',
                top: '72%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              onClick={async () => {
                const prop = {
                  // screen: "MainStackDItemScreen",
                  // prop: {
                  //   itemId: "64ddc2705a2b0fbd7ff31400",
                  //   isFrom: "Lottery",
                  //   enteringComponent: "Lottery",
                  // },
                  itemId: '64ddc2705a2b0fbd7ff31400',
                  enteringComponent: 'Lottery',
                  teamDealId: AlwayzUserStore.teamDealId || '',
                }
                window.location.href = `#quizPurchaseReward.${JSON.stringify(
                  prop,
                )}`
              }}
              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
              }}
            >
              팀추첨 참여하기
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default LotteryTeamDealModal
