import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'

const ItemEndBeforeModal = observer(() => {
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1000,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.8)',
        }}
      />
      <div
        style={{
          height: '135vw',
          position: 'absolute',
          zIndex: 1001,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: '90vw',
          background:
            'linear-gradient(360deg, rgba(255,185,80,1) 0%, rgba(255,244,116,1) 100%)',
          borderRadius: 20,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontFamily: 'maplestory',
            color: '#402C24',
            fontSize: '6vw',
            textAlign: 'center',
            marginTop: '4.4vw',
            marginBottom: '1vw',
            fontWeight: 'bold',
          }}
        >
          작물 변경 안내
        </div>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '4vw',
            width: '92%',
            height: '85%',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '0vw',
            paddingBottom: '16vw',
            marginTop: '2vw',
            marginBottom: '10vw',
            alignItems: 'center',
            overflowY: 'scroll',
          }}
        >
          <div
            style={{
              fontFamily: 'maplestory',
              color: '#402C24',
              textAlign: 'start',
              width: '86%',
              fontSize: '4vw',
            }}
          >
            <br></br>
            <br></br> 안녕하세요, 농부님
            <br></br>
            최근 사과 원물 가격 상승으로 인해 4월 1일부로 사과의 중량을
            불가피하게 줄이게 되었어요.
            <br></br>
            <br></br>4월 1일 이후로 수확하는 사과는 중량이 줄어들어요.<br></br>{' '}
            - 변경전, 세척 빨간 사과 1.5kg <br></br>- 변경후, 세척 빨간 사과 3과
            <br></br>
            <br></br>
            작물을 변경하시는 경우 현재 경험치 그대로 이용하실 수 있어요.
            <br></br>
            <br></br>이용에 불편을 드려 대단히 죄송합니다
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '5vw',
              }}
            >
              <button
                style={{
                  padding: '4vw 1vw 4vw 1vw',
                  border: 'black solid 1px',
                  borderRadius: '100vw',
                  width: '35vw',
                  fontSize: '3.5vw',
                  fontFamily: 'maplestory',
                  color: 'black',
                  marginRight: '2vw',
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('basic')
                }}
              >
                다음에 변경하기
              </button>
              <button
                style={{
                  padding: '4vw 1vw 4vw 1vw',
                  background:
                    'linear-gradient(180deg, rgba(255,212,61,1) 0%, rgba(255,226,125,1) 100%)',
                  borderRadius: '100vw',
                  width: '35vw',
                  fontSize: '3.5vw',
                  fontFamily: 'maplestory',
                  color: 'black',
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('changePlant')
                }}
              >
                지금 변경하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default ItemEndBeforeModal
