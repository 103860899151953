const StyleAsset = {
  modalBackground: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 2000,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw',
  },
  modalImageCentered: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
  },
}
export default StyleAsset
