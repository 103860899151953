import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import LoadingIndicator from '../loadingIndicator'
import commaNumber from 'comma-number'

import moment from 'moment'

const LuckydrawHistoryModal = observer(
  ({ token, setOpenLuckydrawHistoryModal }) => {
    const [luckydrawHistoryData, setLuckydrawHistoryData] = useState([])
    const [emptyModal, setEmptyModal] = useState(false)

    const getLuckydrawHistory = async () => {
      const result = await backendApis.getLuckydrawHistory(token)

      if (result?.data?.length === 0) {
        setEmptyModal(true)
      }
      let description
      let icon
      const customData = result?.data?.map((item) => {
        if (item?.type === 'luckydraw101') {
          description = '일반비료 1개'
          icon = '/icon/tileOneIcon.png'
        } else if (item?.type === 'luckydraw102') {
          description = '비료 바우처'
          icon = '/icon/tileTwoIcon.png'
        } else if (item?.type === 'luckydraw103') {
          description = '바둑이'
          icon = '/icon/tileThreeIcon.png'
        } else if (item?.type === 'luckydraw104') {
          description = '물 10g'
          icon = '/icon/tileFourIcon.png'
        } else if (item?.type === 'luckydraw105') {
          description = '물 100g'
          icon = '/icon/tileFiveIcon.png'
        } else if (item?.type === 'luckydraw106') {
          description = '고급비료 1개'
          icon = '/icon/tileSixIcon.png'
        } else if (item?.type === 'luckydraw107') {
          description = '쿠폰 500원'
          icon = '/icon/tileSevenIcon.png'
        } else if (item?.type === 'luckydraw108') {
          description = '물 30g'
          icon = '/icon/tileEightIcon.png'
        } else {
          description = '기타'
          icon = ''
        }

        return { ...item, description, icon }
      })

      setLuckydrawHistoryData(customData)
    }

    useEffect(() => {
      getLuckydrawHistory()
    }, [token])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1000,
            width: '100%',
            height: '100vh',
            background: 'rgba(0,0,0,0.8)',
          }}
        />
        <div
          style={{
            width: '80%',
            height: '110vw',
            position: 'absolute',
            zIndex: 1001,
            top: '55vw',
            left: '10%',
            background: 'white',
            borderRadius: 20,
          }}
        >
          <div
            style={{
              fontFamily: 'maplestory',
              color: '#402C24',
              fontSize: 22,
              textAlign: 'center',
              marginTop: '5vw',
              marginBottom: '1vw',
            }}
          >
            럭키드로우 기록
          </div>
          <div
            style={{
              backgroundColor: '#f2f2f2',
              width: '100%',
              height: '0.5vw',
              marginTop: 12,
            }}
          />
          <div
            style={{
              position: 'relative',
              overflowY: 'scroll',
              height: '93vw',
            }}
          >
            <div>
              {luckydrawHistoryData?.length > 0 &&
                luckydrawHistoryData?.map((item) => {
                  return (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginTop: 8,
                        }}
                      >
                        <div
                          style={{
                            marginLeft: 18,
                          }}
                        >
                          <div
                            style={{
                              color: '#5F2D0C',
                              zIndex: 1999,
                              fontFamily: 'maplestory',
                              fontSize: 16,
                              marginTop: 8,
                              marginRight: 16,
                            }}
                          >
                            {item?.description}
                          </div>
                          <div
                            style={{
                              color: '#855C36',
                              fontFamily: 'maplestory',
                              fontSize: 12,
                              marginTop: 10,
                            }}
                          >
                            {moment(item?.loggedAt).format(`MM월 DD일 HH:mm`)}
                          </div>
                        </div>

                        <div
                          style={{
                            color: '#EE7B63',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginRight: 18,
                            display: 'flex',
                            flex: 1,
                            fontSize: 20,
                            fontFamily: 'maplestory',
                            marginTop: 8,
                          }}
                        >
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <img
                              style={{
                                width: '20vw',
                              }}
                              alt=''
                              src={item?.icon}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          height: 1,
                          backgroundColor: '#EDEDED',
                          fontSize: 18,
                          marginTop: 16,
                        }}
                      />
                    </>
                  )
                })}
            </div>
          </div>
        </div>

        <button
          style={{
            width: '8%',
            top: '40vw',
            right: '10vw',
            zIndex: 1001,
            position: 'absolute',
          }}
          onClick={() => {
            setOpenLuckydrawHistoryModal(false)
          }}
        >
          <img src='/icon/modalCloseButton.png' alt='' />
        </button>

        {emptyModal && (
          <>
            <div
              style={{
                color: 'black',
                fontFamily: 'maplestory',
                fontSize: 18,
                marginTop: '20vw',
                textAlign: 'center',
              }}
            >
              뽑기 기록이 없어요
              <br />
              뽑기를 버튼을 눌러보세요!
            </div>
          </>
        )}
      </>
    )
  },
)

export default LuckydrawHistoryModal
