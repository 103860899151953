import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import WhiteTextTimer from '../WhiteTextTimer'
import ModalStore from '../../store/ModalStore'
import UserStore from '../../store/UserStore'

const WaterOneOneBattleModal = observer(
  ({
    token,
    onClick,
    battleData,
    setOngoingWaterBattleBanner,
    setAmount,
    farmData,
    setFarmData,
    battleWaterAmount,
    opponentBattleWaterCount,
  }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)

    const [canGetReward, setCanGetReward] = useState(battleData?.canGetReward)

    const endsBattle = async () => {
      const result = await backendApis.endsBattle(token)
      let tmpFarmData = farmData
      if (UserStore.waterExceeded) {
        tmpFarmData.water += Math.round(result?.data?.waterAmount * 0.5)
        setAmount(Math.round(result?.data?.waterAmount * 0.5))
      } else {
        tmpFarmData.water += result?.data?.waterAmount
        setAmount(result?.data?.waterAmount)
      }
      setFarmData(tmpFarmData)
    }

    const Button = () => {
      if (!canGetReward) {
        return (
          <button
            style={{
              background: isButtonPressed ? '93CB4E' : '#93CB4E',
              boxShadow: isButtonPressed
                ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                : '',
              flex: 1,
              position: 'absolute',
              left: '10%',
              bottom: '8vw',
              zIndex: 12,
              borderRadius: 5,
              width: '80%',
              height: 50,
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
              onClick()
            }}
          >
            <div
              style={{
                color: 'white',
                fontFamily: 'maplestory',
                fontSize: '5vw',
              }}
            >
              물주기
            </div>
          </button>
        )
      } else if (canGetReward) {
        return (
          <button
            style={{
              background: isButtonPressed ? '93CB4E' : '#93CB4E',
              boxShadow: isButtonPressed
                ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                : '',
              flex: 1,
              position: 'absolute',
              left: '10%',
              bottom: '8vw',
              zIndex: 12,
              borderRadius: 5,
              width: '80%',
              height: 50,
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
            onClick={async () => {
              await ModalStore.setIsModalOpen('basic')
              await setOngoingWaterBattleBanner(false)
              await endsBattle()
              await ModalStore.setIsModalOpen('battleReward')
            }}
          >
            <div
              style={{
                color: 'white',
                fontFamily: 'maplestory',
                fontSize: '5vw',
              }}
            >
              보상 받기
            </div>
          </button>
        )
      }
    }
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '80%',
              left: '10%',
              zIndex: 12001,
              top: '55vw',
            }}
          >
            <img alt='' src={'/icon/battleModal.png'} />

            <div
              style={{
                position: 'absolute',
                width: '45%',
                left: '6vw',
                zIndex: 12001,
                top: '48vw',
              }}
            >
              <img
                style={{
                  width: '10vw',
                  height: '10vw',
                  top: '-7vw',
                  left: '3vw',
                  borderRadius: 50,
                  position: 'absolute',
                  border: '2px solid #FF4728',
                }}
                alt=''
                src={battleData?.userImage}
                onError={(e) => {
                  e.target.src = UserStore?.default_profile_image
                }}
              />

              <div>
                <img alt='' src={'/icon/battleModalRed.png'} />
                <div
                  style={{
                    width: '100%',
                    zIndex: 12001,
                    color: 'white',
                    fontFamily: 'maplestory',
                    position: 'absolute',
                    top: '5vw',
                    left: '3vw',
                    fontSize: '4vw',
                  }}
                >
                  물주기: {battleWaterAmount}번
                </div>
                <div
                  style={{
                    width: '100%',
                    zIndex: 12001,
                    color: 'white',
                    fontFamily: 'maplestory',
                    position: 'absolute',
                    top: '10vw',
                    left: '3vw',
                    fontSize: '4vw',
                  }}
                >
                  {battleData?.waterAmount}g 획득가능
                </div>
              </div>
            </div>

            <div
              style={{
                position: 'absolute',
                width: '45%',
                right: '6vw',
                zIndex: 12001,
                top: '50vw',
              }}
            >
              <img
                style={{
                  width: '10vw',
                  height: '10vw',
                  top: '-7vw',
                  right: '3vw',
                  borderRadius: 50,
                  position: 'absolute',
                  border: '2px solid #2FCBBF',
                }}
                alt=''
                src={battleData?.opponentImage}
              />

              <img alt='' src={'/icon/battleModalGreen.png'} />
              <div
                style={{
                  zIndex: 12001,
                  color: 'white',
                  fontFamily: 'maplestory',
                  position: 'absolute',
                  top: '5vw',
                  right: '3vw',
                  fontSize: '4vw',
                }}
              >
                물주기: {opponentBattleWaterCount}번
              </div>
              <div
                style={{
                  zIndex: 12001,
                  color: 'white',
                  fontFamily: 'maplestory',
                  position: 'absolute',
                  top: '10vw',
                  right: '3vw',
                  fontSize: '4vw',
                }}
              >
                {battleData?.opponentWaterAmount}g 획득가능
              </div>
            </div>
            <div
              style={{
                position: 'absolute',
                width: '15%',
                left: '43.5%',
                zIndex: 12001,
                top: '46vw',
              }}
            >
              <img alt='' src={'/icon/battleModalVs.png'} />
            </div>

            {canGetReward === false ? (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                  zIndex: 12001,
                  bottom: '25vw',
                  color: 'black',
                  fontFamily: 'maplestory',
                  fontSize: '5vw',
                }}
              >
                종료까지{' '}
                <WhiteTextTimer
                  onTimeEnd={() => {
                    setCanGetReward(true)
                  }}
                  timeStamp={new Date(battleData?.endedAt)}
                  timerMinutes={0}
                  color='black'
                />{' '}
                남았어요!
              </div>
            ) : (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                  zIndex: 12001,
                  bottom: '25.5vw',
                  color: 'black',
                  fontFamily: 'maplestory',
                  fontSize: '5vw',
                }}
              >
                종료 되었어요! 보상을 받아주세요
              </div>
            )}

            {Button()}
          </div>
          <button
            style={{
              width: '8vw',
              right: '10vw',
              top: '50vw',
              position: 'absolute',
              zIndex: 12999,
              borderRadius: '1px solid black',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 1101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
        </div>
      </>
    )
  },
)

export default WaterOneOneBattleModal
