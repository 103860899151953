import React, { useState, useEffect, useRef } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import useDebounce from '../comps/useDebounce'
import LoadingIndicator from '../comps/loadingIndicator'
import { NavLink } from 'react-router-dom'

const MESSAGE = [
  '계좌정보 입력이 완료됐어요. 영업일 기준 24시간 내에 입금될 예정이니 조금만 기다려주세요',
  `입금 계좌번호를 다시 확인해주세요. 문제가 계속되면 고객센터로 문의해주세요`,
  '오류가 발생했어요. 잠시 후 다시 시도해주세요. 문제가 계속되면 고객센터로 문의해주세요',
  '은행을 선택해주세요',
  '계좌번호를 입력해주세요',
  '예금주 명을 입력해주세요',
]

function SupportFundInputPage() {
  const debounce = useDebounce()
  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const [bankAccountInfo, setBankAccountInfo] = useState({})
  const [receivingAccountNumber, setReceivingAccountNumber] = useState('')
  const [receiverName, setReceiverName] = useState('')
  const [bankList, setBankList] = useState([])
  const [isButtonDisable, setIsButtonDisable] = useState(false)
  const [infoModal, setInfoModal] = useState(true)
  const [messageNumber, setMessageNumber] = useState(10)
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
    const initializeBankList = async () => {
      const result = await backendApis.getBankList(token, 'GET')
      if (result?.status === 200) {
        setBankList(result?.data)
      }
    }
    initializeBankList()
  }, [])

  const inputButton = async () => {
    if (bankAccountInfo.bankCode === undefined) {
      setMessageNumber(3)
      setInfoModal(true)
      setTimeout(() => {
        setInfoModal(false)
        setMessageNumber(10)
      }, 3000)
      return
    }
    if (receivingAccountNumber === '') {
      setMessageNumber(4)
      setInfoModal(true)
      setTimeout(() => {
        setInfoModal(false)
        setMessageNumber(10)
      }, 3000)
      return
    }
    if (receiverName === '') {
      setMessageNumber(5)
      setInfoModal(true)
      setTimeout(() => {
        setInfoModal(false)
        setMessageNumber(10)
      }, 3000)
      return
    }
    const result = await backendApis.requestLuckyPiggyWithdraw(token, 'POST', {
      bankCode: bankAccountInfo?.bankCode,
      bankName: bankAccountInfo?.bankName,
      bankAccountNumber: receivingAccountNumber,
    })

    if (result) {
      setInfoModal(true)
      setTimeout(() => {
        setInfoModal(false)
        setMessageNumber(10)
      }, 3000)
    }
    if (result?.status === 2000) {
      setMessageNumber(0)
      setTimeout(() => {
        window.location.href = `/farmPage?token=${token}&itemType=${itemType}`
      }, 3200)
    } else if (result?.msg === 'invalid bank account') {
      setMessageNumber(1)
    } else {
      setMessageNumber(2)
    }
  }

  return (
    <>
      <div
        style={{
          height: '93%',
          fontFamily: 'maplestory',
        }}
      >
        <NavLink
          to={`/farmPage?token=${token}&itemType=${itemType}`}
          //   style={{ width: "80vw" }}
        >
          <button
            style={{
              width: '7vw',
              marginLeft: '2vw',
              paddingTop: '10vw',
            }}
            action='goBack'
          >
            <img src='/luckyPiggy/backWhite.png' alt='' />
          </button>
        </NavLink>

        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            // backgroundColor: 'blue',
            //   justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            //   alignItems: 'flex-start',
          }}
        >
          <div
            style={{
              width: '84vw',
              marginTop: 48,
              fontWeight: 700,
            }}
          >
            <div>
              은행을 선택해주세요
              <span style={{ color: '#EB4F47', marginLeft: 4 }}>*</span>
            </div>
            <div>
              <select
                style={{
                  width: '100%',
                  border: '2px solid #F7F7F7',
                  height: 40,
                  borderRadius: 5,
                  fontSize: 16,
                  fontFamily: 'maplestory',
                  marginTop: 12,
                  marginBottom: 12,
                  backgroundColor: 'white',
                  color: 'black',
                }}
                placeholder='  입금 은행'
                value={bankAccountInfo.bankCode || ''}
                onChange={(e) => {
                  const correspondingBankInfo = bankList.find(
                    (bankInfo) => bankInfo.bankCode === e.target.value,
                  )
                  if (correspondingBankInfo) {
                    setBankAccountInfo({
                      bankCode: correspondingBankInfo.bankCode,
                      bankName: correspondingBankInfo.bankName,
                    })
                  } else {
                    setBankAccountInfo({})
                  }
                }}
              >
                <option value=''>입금 은행</option>
                {bankList.map((bankInfo) => (
                  <option key={bankInfo.bankCode} value={bankInfo.bankCode}>
                    {bankInfo.bankName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            style={{
              width: '84vw',
              marginTop: 20,
              fontWeight: 700,
            }}
          >
            <div>
              계좌번호
              <span style={{ color: '#EB4F47', marginLeft: 4 }}>*</span>
            </div>
            <div>
              <input
                placeholder='  계좌번호를 - 없이 입력해주세요'
                onChange={(e) => {
                  setReceivingAccountNumber(e.target.value)
                }}
                value={receivingAccountNumber || ''}
                style={{
                  width: '98%',
                  border: '2px solid #F7F7F7',
                  height: 40,
                  borderRadius: 5,
                  fontSize: 16,
                  fontFamily: 'maplestory',
                  marginTop: 12,
                  marginBottom: 12,
                  backgroundColor: 'none',
                }}
              />
            </div>
          </div>
          <div style={{ width: '84vw', marginTop: 20, fontWeight: 700 }}>
            <div>
              예금주 명
              <span style={{ color: '#EB4F47', marginLeft: 4 }}>*</span>
            </div>
            <div>
              <input
                placeholder='  예금주 명을 정확히 입력해주세요'
                onChange={(e) => {
                  setReceiverName(e.target.value)
                }}
                value={receiverName || ''}
                style={{
                  width: '98%',
                  border: '2px solid #F7F7F7',
                  height: 40,
                  borderRadius: 5,
                  fontSize: 16,
                  fontFamily: 'maplestory',
                  marginTop: 12,
                  marginBottom: 12,
                  backgroundColor: 'none',
                }}
              />
            </div>
          </div>
          {isButtonDisable ? (
            <div
              style={{
                backgroundColor: '#c3c3c3',
                padding: 8,
                paddingTop: 12,
                paddingBottom: 12,
                display: 'flex',
                justifyContent: 'center',
                borderRadius: 8,
                margin: 8,
                marginTop: 32,
                marginBottom: 8,
                width: '84vw',
              }}
            >
              <button
                type='button'
                style={{
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'maplestory',
                  fontWeight: 900,
                  fontSize: 16,
                  opacity: '0.7',
                }}
                onClick={() => {
                  // setIsButtonDisable(true);
                  debounce(inputButton, 500)
                }}
              >
                용돈 송금하기
              </button>
            </div>
          ) : (
            <button
              style={{
                backgroundColor: '#EB4F47',
                padding: 8,
                paddingTop: 12,
                paddingBottom: 12,
                display: 'flex',
                justifyContent: 'center',
                borderRadius: 8,
                margin: 8,
                marginTop: 32,
                marginBottom: 8,
                width: '84vw',
                fontFamily: 'maplestory',
                fontSize: '5vw',
                opacity: isButtonPressed ? 0.5 : 1,
              }}
              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
              }}
              onClick={() => {
                // setIsButtonDisable(true);
                debounce(inputButton, 500)
                //   setIsButtonDisable(true);
              }}
            >
              용돈 송금하기
            </button>
          )}
        </div>
      </div>
      <LoadingIndicator isLoading={isLoading} />

      {infoModal && messageNumber < 8 && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: '10%',
            top: '85vw',
            zIndex: 2000,
            width: '80%',
            height: '30vw',
            background: 'rgba(0,0,0,0.8)',
            borderRadius: 20,
            fontFamily: 'maplestory',
            fontSize: '4vw',
            textAlign: 'center',
            paddingLeft: '8vw',
            paddingRight: '8vw',
            paddingTop: '8vw',
          }}
        >
          {MESSAGE[messageNumber]}
          <div style={{ height: '3vw' }} />
        </div>
      )}
    </>
  )
}
export default SupportFundInputPage
