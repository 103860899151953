import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../store/ModalStore'

const PersonalizedShopModal = observer(
  ({ token, farmData, setFarmData, setPersonalizedShopButtonOn }) => {
    const [changeClass, setChangeClass] = useState(false)
    const [canOpenGiftBox, setCanOpenGiftBox] = useState(true)
    useEffect(() => {}, [])

    const openGiffBox = async () => {
      setCanOpenGiftBox(false)
      const result = await backendApis.setPersonalizedShopRewardData(token)

      if (result?.status === 200) {
        let tempData = farmData
        tempData.personalizedShopInfo = {
          createdAt: new Date(),
          type: result?.data?.type,
          twice: result?.data?.twice,
          rewardWater: result?.data?.rewardWater,
          rewardFertilizer: result?.data?.rewardFertilizer,
          rewardFertilizerSet: result?.data?.rewardFertilizerSet,
        }
        setFarmData(tempData)
        setPersonalizedShopButtonOn(true)
        setTimeout(() => {
          ModalStore.setIsModalOpen('personalizedShopRewardModal')
        }, 3000)
      }
    }
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              width: '100%',
            }}
          >
            <div
              onClick={() => {
                setChangeClass(true)
                if (canOpenGiftBox) {
                  openGiffBox()
                }
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                  top: '-50vw',
                }}
                className='rotate-bg-shine'
                src='/personalizedShop/shineBg.png'
                alt=''
              />
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                  width: '40%',
                  left: '30%',
                  top: '-25vw',
                }}
                className={
                  changeClass ? 'wobble-hor-bottom' : 'scale-in-center'
                }
                src='/personalizedShop/giftBox.png'
                alt=''
              />
              <div
                style={{
                  top: '50vw',
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '5vw',
                }}
              >
                두근두근! 선물 상자를 열어보세요
              </div>
            </div>
            {canOpenGiftBox && (
              <button
                style={{
                  width: '10%',
                  top: '-60vw',
                  right: '15%',
                  position: 'absolute',
                  zIndex: 1113,
                  background: 'black',
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('basic')
                }}
              >
                <img
                  style={{
                    position: 'absolute',
                    zIndex: 101,
                  }}
                  src='/icon/modalCloseButton.png'
                  alt=''
                />
              </button>
            )}
          </div>
        </div>
      </>
    )
  },
)
export default PersonalizedShopModal
