import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import toast, { Toaster } from 'react-hot-toast'
import ModalStore from '../../store/ModalStore'
import ToastStore from 'store/ToastStore'

const FlappyBirdRewardModal = observer(
  ({
    setShowModal,
    token,
    itemType,
    bestScore,
    rewardAvailable,
    setRewardAvailable,
  }) => {
    const [rewardAmount, setRewardAmount] = useState(0)
    useEffect(() => {
      setRewardAmount(Math.min(Math.round(bestScore / 4), 100))
    }, [bestScore])

    const notify = () => {
      ToastStore?.toastOn({
        type: 'emoji',
        message: '플라잉팜을 플레이해보세요!',
        emoji: '🕹️',
        duration: 3000,
      })
    }

    const giveFlappyBirdReward = async (bestScore) => {
      if (bestScore <= 0) {
        notify()
        return
      }
      await backendApis.giveFlappyBirdReward({ score: bestScore })
      setRewardAvailable(false)
      ModalStore.setIsModalOpen('basic')
      ModalStore.setIsModalOpen('flappyBirdPurchase')
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage:
                'url(../flappyBirdImg/rewardModalBackground.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '53vw',
              paddingBottom: '18%',
              // marginBottom: "15%",
            }}
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: '18vw',
                position: 'absolute',
                zIndex: 999,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                // border: "1px solid black",
                width: '100%',
                height: '100%',
                // marginBottom: "12vw",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  lineHeight: '1.4rem',
                  marginTop: 8,
                  // border: "1px solid black",
                }}
              >
                하루에 한번, <b style={{ color: '#E94343' }}>물 보상</b>을 받을
                수 있어요
              </div>
              <div
                style={{
                  width: '40vw',
                  height: '40vw',
                  backgroundImage: 'url(../flappyBirdImg/normalReward.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  marginBottom: 8,
                  marginTop: 8,
                  // border: "1px solid black",
                }}
              />
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  lineHeight: '1.4rem',
                  marginBottom: 8,
                  // border: "1px solid black",
                  // position: "relative",
                }}
              >
                오늘의 최고 점수의 25%만큼 물을 받아요
                <div
                  style={{
                    // border: "1px solid black",
                    // width: "100%",
                    color: 'black',
                    fontFamily: 'Maplestory',
                    textAlign: 'center',
                    lineHeight: '1.4rem',
                    position: 'absolute',
                    fontSize: '0.8rem',
                    right: '8vw',
                    // top: 0,
                    bottom: '43vw',
                    color: '#777',
                  }}
                >
                  *최대 100g
                </div>
              </div>
              {rewardAvailable ? (
                <div
                  style={{
                    background:
                      'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                    width: '60vw',
                    height: '14vw',
                    borderRadius: 99,
                    color: 'black',
                    fontFamily: 'Maplestory',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                  }}
                  onClick={() => giveFlappyBirdReward(rewardAmount)}
                >
                  {`오늘의 보상 ${rewardAmount}g 받기`}
                </div>
              ) : (
                <div
                  style={{
                    // background:
                    //   "linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)",
                    backgroundColor: '#999',
                    width: '60vw',
                    height: '14vw',
                    borderRadius: 99,
                    color: 'black',
                    fontFamily: 'Maplestory',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                  }}
                  // onClick={sendToPurchaseScreen}
                  // onClick={{}}
                >
                  {`내일 또 받을 수 있어요`}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default FlappyBirdRewardModal
