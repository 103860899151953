import React, { useState, useEffect } from 'react'
import { nanoid } from 'nanoid'
import commaNumber from 'comma-number'

const SellerStore = (props) => {
  const {
    mainStoreData,
    subStoreData1,
    subStoreData2,
    token,
    farmData,
    sellerStoreId,
  } = props
  const randomString = Math.random().toString(36).slice(2, 8)

  const mainItemIds =
    mainStoreData?.itemIds
      ?.filter((e) => e !== null || e !== undefined)
      ?.map((item) => item?.itemId) || []
  const subItemIds1 =
    subStoreData1?.itemIds
      ?.filter((e) => e !== null || e !== undefined)
      ?.map((item) => item?.itemId) || []
  const subItemIds2 =
    subStoreData2?.itemIds
      ?.filter((e) => e !== null || e !== undefined)
      ?.map((item) => item?.itemId) || []
  const sellerFarmItemIds = [...mainItemIds, ...subItemIds1, ...subItemIds2]
  const sellerStoreIdList = [
    mainStoreData?._id,
    subStoreData1?._id,
    subStoreData2?._id,
  ]?.filter((e) => e)

  return (
    <div
      style={{
        width: '80vw',
        height: '120vw',
        display: 'flex',
        position: 'absolute',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundImage: 'url(../sellerFarm/Modal.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        left: '10vw',
        bottom: '20vw',
      }}
    >
      <img
        style={{
          borderRadius: '2vw',
          width: mainStoreData?.sellerImageUri ? '19vw' : '17vw',
          height: '17vw',
          top: '12%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          left: '15%',
        }}
        alt=''
        src={
          mainStoreData?.sellerImageUri ||
          'https://assets.ilevit.com/6de7dd85-ac41-430d-b160-ce3dd0e104b6.png'
        }
      />

      <div
        style={{
          top: '13%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          color: '#FFE7A8',
          fontSize: '6vw',
          right: '-24.5vw',
          textAlign: 'center',
          width: '54vw',
        }}
      >
        {mainStoreData?.sellerDisplayName?.length > 10 ? (
          <>{mainStoreData?.sellerDisplayName.slice(0, 10)}..</>
        ) : (
          <>{mainStoreData?.sellerDisplayName.slice(0, 10)}</>
        )}
      </div>

      <div
        style={{
          top: '25%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          color: '#FEFDFC',
          fontSize: '4vw',
          left: '50%',
          width: '90%',
        }}
      >
        {mainStoreData?.adDescription?.length > 19 ? (
          <>📢 {mainStoreData?.adDescription.slice(0, 19)}..</>
        ) : (
          <>📢 {mainStoreData?.adDescription.slice(0, 19)}</>
        )}
      </div>

      <div
        style={{
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          color: '#5A1A06',
          fontSize: '4vw',
          textAlign: 'center',
          width: '90%',
          lineHeight: '150%',
          fontWeight: 'bold',
        }}
      >
        상품을 구매하면 <br />
        고급비료 5개, 물 1,000g 을 받아요
      </div>
      <div
        style={{
          position: 'relative',
          overflowX: 'scroll',
          width: '92%',
          zIndex: 1999,
          height: '52vw',
          background: '#5D2009',
          marginTop: '55vw',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {mainStoreData?.itemIds?.map((item) => {
          return (
            <div
              key={nanoid + Math.random()}
              style={{
                background: 'white',
                width: '132vw',
                height: '48.5vw',
                marginRight: '1vw',
                marginLeft: '1.5vw',
                marginTop: '2vw',
                borderRadius: '0.5vw',
              }}
              onClick={() => {
                window.localStorage.setItem(
                  'alfarmSellerStore',
                  JSON.stringify({
                    itemId: item?.itemId,
                    sellerStoreId: sellerStoreId,
                    timeAt: new Date(),
                  }),
                )
                const sellerStorePurchase = {
                  itemId: item?.itemId,
                  enteringComponent: 'Alfarm_sellerStorePurchase',
                  imageSource:
                    'https://assets.ilevit.com/1da7f307-0df3-41c8-87f5-01af59312ad8.png',
                  sellerStoreId: sellerStoreId,
                }
                window.location.href = `/sellerFarm?token=${token}&itemType=${
                  farmData?.itemType
                }&sellerStoreId=${sellerStoreId}
                &sellerStoreIdList=${sellerStoreIdList}
                &gganbuId=${item?.userId}&gganbuName=${
                  item?.userName
                }&randomNumber=${randomString}&#sellerStorePurchase.${JSON.stringify(
                  sellerStorePurchase,
                )}`
              }}
            >
              <img
                style={{
                  color: '#5A1A06',
                  fontSize: '4vw',
                  textAlign: 'center',
                  width: '26vw',
                  height: '26vw',
                  marginLeft: '1vw',
                  marginRight: '1vw',
                  marginTop: '1vw',
                }}
                src={item?.imageUri}
                alt='abc'
              />
              <div
                style={{
                  color: '#5A1A06',
                  fontSize: '3.5vw',
                  textAlign: 'center',
                  height: '12.5vw',
                  marginTop: '1vw',
                  paddingLeft: '1vw',
                  paddingRight: '1vw',
                }}
              >
                {item?.itemTitle?.length > 26 ? (
                  <>{item?.itemTitle.slice(0, 26)}..</>
                ) : (
                  <>{item?.itemTitle.slice(0, 26)}</>
                )}
              </div>
              <div
                style={{
                  color: '#EF6721',
                  fontSize: item?.teamPurchasePrice >= 100000 ? '4vw' : '5vw',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  marginTop: '1vw',
                }}
              >
                {commaNumber(item?.teamPurchasePrice)}원
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SellerStore
