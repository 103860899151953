import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import ModalStore from '../../store/ModalStore'
import ToastStore from 'store/ToastStore'

const BokForGganbuModal = ({
  onClick,
  token,
  userId,
  gganbuId,
  gganbuName,
}) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [gganbuInfo, setGganbuInfo] = useState()
  const [selectedBokType, setSelectedBokType] = useState()
  const [nickname, setNickname] = useState('')
  const [message, setMessage] = useState('')

  const handleInputChange = (event) => {
    setMessage(event.target.value)
  }

  const shortenedName =
    gganbuName.length > 7 ? gganbuName.slice(0, 7) + '...' : gganbuName

  const bokType = [
    {
      type: '1',
    },
    {
      type: '2',
    },
    {
      type: '3',
    },
    {
      type: '4',
    },
  ]

  useEffect(() => {
    const getUserInfo = async () => {
      const userInfo = await backendApis.getUserBasicInfobyUID(token, 'GET', {
        uid: userId,
      })
      if (userInfo) {
        setNickname(userInfo?.userName)
      }
    }
    const getGganbuInfo = async () => {
      const gganbuInfo = await backendApis.getUserBasicInfobyUID(token, 'GET', {
        uid: gganbuId,
      })
      if (gganbuInfo) {
        setGganbuInfo(gganbuInfo)
      }
    }
    getUserInfo()
    getGganbuInfo()
  }, [token, gganbuId])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 2001,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.7)',
        }}
      />
      <img
        src='/icon/bokEventModalBackground.png'
        alt=''
        style={{
          position: 'absolute',
          zIndex: 2001,
          top: '16vw',
          left: '5vw',
          width: '90vw',
          height: '155vw',
        }}
      />
      <div
        style={{
          position: 'fixed',
          zIndex: 2001,
          top: '60vw',
          left: '10vw',
          width: '80vw',
          height: '120vw',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: '0.5rem',
          fontSize: '4vw',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            paddingTop: '5vw',
            fontSize: '5vw',
            whiteSpace: 'nowrap',
            color: 'black',
          }}
        >
          복주머니를 골라주세요
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '2vw',
            padding: '4vw',
          }}
        >
          {bokType.map((item) => (
            <div
              key={item.type}
              style={{
                borderRadius: '0.5rem',
                border:
                  selectedBokType === item.type
                    ? '2px solid #f87171'
                    : '2px solid transparent', // border-red-500 대응
                backgroundColor: 'white',
                padding: '2vw',
              }}
              onClick={() => setSelectedBokType(item.type)}
            >
              <img
                src={`/images/bokEvent/bok_${item.type}.png`}
                alt=''
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          ))}
        </div>
        <div
          style={{
            zIndex: 2000,
            width: '80vw',
            height: '40vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '0.5rem', // rounded-lg
            fontSize: '5vw',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
              paddingTop: '3vw',
              paddingBottom: '2vw',
              whiteSpace: 'pre-wrap',
              color: 'black',
            }}
          >
            {shortenedName}님께 남기고 싶은 말
          </div>
          <textarea
            placeholder={`${shortenedName}님께 전달할\n행복 가득한 덕담을 남겨주세요 :)`}
            value={message}
            onChange={handleInputChange}
            style={{
              width: '90%',
              height: '40vw',
              borderRadius: '0.5rem', // rounded
              fontSize: '4vw',
              resize: 'none', // resize-none
              fontFamily: 'maplestory',
              padding: '4vw',
            }}
          />
        </div>
        <button
          style={{
            background: '#93CB4E',
            boxShadow: isButtonPressed
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',
            flex: 1,
            position: 'absolute',
            left: '10%',
            top: '80vw',
            zIndex: 2,
            borderRadius: 5,
            width: '80%',
            paddingTop: '4vw',
            paddingBottom: '4vw',
            color: 'white',
            fontFamily: 'maplestory',
            fontSize: '5vw',
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
          onClick={async () => {
            if (selectedBokType > 0) {
              if (!message.trim()) {
                // 메시지가 비어있거나 공백만 있는 경우 검사
                ToastStore.toastOn({
                  type: 'emoji',
                  message: `메시지를 입력해주세요`,
                  emoji: '📝',
                  duration: 1500,
                })
                return
              }

              const result = await backendApis.registerBokMessage({
                nickname: nickname,
                phoneNumber: gganbuInfo?.phoneNumber,
                message: message,
                bokType: selectedBokType,
              })

              if (result?.status === 200) {
                ToastStore.toastOn({
                  type: 'emoji',
                  message: `덕담을 남겼어요!`,
                  emoji: '💝',
                  duration: 1500,
                })

                setMessage('')
                ModalStore.setIsModalOpen('bokSentModal')
              } else {
                ToastStore.toastOn({
                  type: 'emoji',
                  message: `문제가 발생했어요.\n잠시 후 다시 시도해주세요`,
                  emoji: '😢',
                  duration: 1500,
                })

                ModalStore.setIsModalOpen('basic')
              }
            } else {
              ToastStore.toastOn({
                type: 'emoji',
                message: `복주머니를 선택해주세요`,
                emoji: '🎁',
                duration: 1500,
              })
            }
          }}
        >
          새해 덕담 남기기
        </button>
      </div>
      <button
        style={{
          width: '8%',
          top: '23vw',
          right: '12%',
          zIndex: 2001,
          position: 'absolute',
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img src='/icon/modalCloseButton.png' alt='' />
      </button>
    </>
  )
}

export default BokForGganbuModal
