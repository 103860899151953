import ManbogiStore from './ManbogiStore'
import BottomSheetStore from './BottomSheetStore'
import ModalStore from './ModalStore'
import TimeStore from './TimeStore'
import UserStore from './UserStore'
import AlwayzUserStore from './AlwayzUserStore'
import AuthStore from './AuthStore'

const indexStore = () => ({
  TimeStore,
  ManbogiStore,
  BottomSheetStore,
  ModalStore,
  UserStore,
  AlwayzUserStore,
  AuthStore,
  // store1,
  // store2,
  // ...
})

export default indexStore
