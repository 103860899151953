import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const TeamAlfarmQuitModal = observer(
  ({ close = () => {}, quitTeam = () => {} }) => {
    return (
      <div className='flex flex-1 fixed left-0 top-0 z-[900] w-full h-full bg-black bg-opacity-80'>
        <div
          className={`w-[80vw] h-[74.25vw] 
      top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col`}
          style={{
            backgroundImage: `url(../teamAlfarm/modal/quitModal.png)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            // border: "2px solid black",
          }}
        >
          <button
            className='w-[8vw] right-0 top-[-12vw] absolute z-[1999]'
            onClick={() => {
              close()
            }}
          >
            <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
          </button>
          <div
            className='w-[35vw] h-[12vw] absolute bottom-[3.5vw] left-0 ml-[4vw]'
            onClick={() => {
              quitTeam()
            }}
          />
          <div
            className='w-[35vw] h-[12vw] absolute bottom-[3.5vw] right-0 mr-[4vw]'
            onClick={() => {
              close()
            }}
          />
        </div>
      </div>
    )
  },
)
export default TeamAlfarmQuitModal
