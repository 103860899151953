import React, { useState, useEffect, useRef } from 'react'
import { useSearchParam, useInterval } from 'react-use'
import { NavLink } from 'react-router-dom'
import commaNumber from 'comma-number'
import toast, { Toaster } from 'react-hot-toast'
import backendApis from '../utils/backendApis'
import timeChecker from '../utils/timeChecker'

const originalCharList = [
  'dog',
  'fox',
  'cat',
  'pig',
  // "coala",
  // "mouse",
  // "cow",
  'tiger',
  // "frog",
  'monkey',
  'rabbit',
  'hamster',
  'bear',
  // "polarBear",
  'panda',
]

const RankItem = ({ data, bragToFriend = () => {} }) => {
  let rankColor = '#FAF0E8'

  if (data?.rank < 4) {
    rankColor = '#FDF89A'
  }

  return (
    <div
      style={{
        // border: "1px solid #965F42",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        backgroundColor: '#E5D1BF',
        borderRadius: 8,
        marginBottom: 8,
        padding: '8px 8px 10px 8px',
        borderTop: '2px solid #C6AF97',
        borderLeft: '2px solid #C6AF97',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '10vw',
          height: '100%',
          color: rankColor,
          fontWeight: 'bold',
          fontSize: data?.rank >= 100 ? '1rem' : '1.5rem',
          textShadow:
            '1px 1px #965F42, -1px 1px #965F42, -1px -1px #965F42, 1px -1px #965F42',
        }}
      >
        {`${data?.rank >= 999 ? '999+' : data?.rank}`}
      </div>
      <div>
        <img
          alt='profileImage'
          style={{
            // border: "1px solid #965F42",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '10vw',
            height: '10vw',
            borderRadius: 16,
            marginLeft: 8,
            marginRight: 8,
            border: '2px solid #AC8E72',
          }}
          src={data?.userImage}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            color: '#965F42',
            fontSize: '0.8rem',
            marginTop: 4,
            marginLeft: 8,
            marginRight: 8,
          }}
        >
          {data?.userName?.slice(0, 3)}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: '14vw',
          color: '#965F42',
          // border: "1px solid #965F42",
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          flexDirection: 'column',
          fontSize: '1.2rem',
          backgroundColor: '#FAF0E8',
          borderRadius: 8,
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        {`${commaNumber(data?.bestScore)}점`}
      </div>
      {data?.isBraggable && (
        <div
          style={{
            border: '2px solid #AC8E72',
            // marginBottom: "11vw",
            borderRadius: 8,
            background: '#FAF0E8',
            color: '#965F42',
            padding: '4px 4px 4px 4px',
            marginLeft: 8,
            width: '12vw',
            height: '12vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            bragToFriend({ gganbuId: data?.userId })
          }}
        >
          <img
            alt='profileImage'
            style={{
              // border: "1px solid #965F42",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '8vw',
              height: '8vw',
            }}
            src={'../icon/water.png'}
          />
        </div>
      )}
    </div>
  )
}

const TimerBar = ({
  handleGameOver,
  inittime,
  setPreGameover,
  addTime,
  combo,
}) => {
  const [remainingTime, setRemainingTime] = useState(inittime)

  useEffect(() => {
    if (combo > 0 && combo % 50 === 0) {
      addTime(setRemainingTime)
    }
  }, [combo])

  useInterval(
    () => {
      if (remainingTime <= 0) {
        setRemainingTime(remainingTime - 1)

        setPreGameover(true)
        setTimeout(() => {
          setPreGameover(false)
          handleGameOver()
        }, 2500)
        return
      }
      setRemainingTime(remainingTime - 1)
    },
    remainingTime >= 0 ? 100 : null,
  )

  return (
    <div
      style={{
        width: '100%',
        height: '10px',
        // backgroundColor: "##965F42",
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          alignSelf: 'center',
          fontFamily: 'maplestory',
          fontWeight: 'bold',
          marginLeft: 4,
          bottom: '8.5vw',
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%)',
          color: 'white',
          fontSize: '1.2rem',
          textShadow:
            '1px 1px #965F42, -1px 1px #965F42, -1px -1px #965F42, 1px -1px #965F42',
        }}
      >
        {Math.round(remainingTime / 10)}
      </div>
      <div
        style={{
          width: `${(remainingTime / 300) * 100}%`,
          height: '18px',
          backgroundColor: remainingTime > 100 ? '#57D758' : '#F05957',
          borderTopRightRadius: 99,
          borderBottomRightRadius: 99,
          borderTop:
            remainingTime > 100 ? '1px solid #639456' : '1px solid #74210E',
          borderRight:
            remainingTime > 100 ? '1px solid #639456' : '1px solid #74210E',
          borderBottom:
            remainingTime > 100 ? '4px solid #639456' : '4px solid #74210E',
          borderLeft: '0px',
          marginBottom: '20vw',
        }}
      />
    </div>
  )
}

const SidePickGame = () => {
  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const randomString = useSearchParam('randomString')

  const [gameStatus, setGameStatus] = useState('getready')
  const [charList, setCharList] = useState([])
  const [currentScore, setCurrentScore] = useState(0)
  const [bestScore, setBestScore] = useState(0)
  const [combo, setCombo] = useState(0)
  const [remainingTime, setRemainingTime] = useState(3000)
  const [wrongAnswerPenalty, setWrongAnswerPenalty] = useState(false)
  const [backgroundImage, setBackgroundImage] = useState('white')
  const [shouldComboTransition, setShouldComboTransition] = useState(true)
  const [comboScale, setComboScale] = useState(0)
  const [sentCount, setSentCount] = useState(0)
  const [feverMode, setFeverMode] = useState(0)
  const [rankList, setRankList] = useState([])
  const [myRank, setMyRank] = useState(1000)
  const [userProfileThumbnail, setUserProfileThumbnail] = useState('')
  const [preplayCountDown, setPreplayCountDown] = useState(300)
  const [fromHome, setFromHome] = useState(false)
  const [showFeverText, setShowFeverText] = useState(false)
  const [feverTextImage, setFeverTextImage] = useState(
    'url(../sidepick/fever.png)',
  )
  const [didReceiveReward, setDidReceiveReward] = useState(false)
  const [showReceiveModal, setShowReceiveModal] = useState(false)
  const [preGameover, setPreGameover] = useState(false)
  const [rankCategory, setRankCategory] = useState('gganbu')
  const [userName, setUserName] = useState('')
  const [sidepickData, setSidepickData] = useState({})
  const [userGganbuRank, setUserGganbuRank] = useState(10)
  const [showBraggingModal, setShowBraggingModal] = useState(false)
  const [rewardAmount, setRewardAmount] = useState(20)
  const [rewardText, setRewardText] = useState('')
  const [canClickWaterIcon, setCanClickWaterIcon] = useState(true)
  const toastBlock = useRef()
  const charTable = useRef()
  const difficulty = useRef()
  const comboDisappearTimeout = useRef()
  const fixedChar = useRef()
  const feverBackgroundTimeout = useRef()

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }
  useEffect(() => {
    toastBlock.current = false
    // charTable.current = ["🐶", "🦊", "🐱", "🐷"];
    charTable.current = shuffleArray(originalCharList)
    difficulty.current = 2
    fixedChar.current = 0
    setFeverMode(0)
    getSidepickUserData()
  }, [])

  useEffect(() => {
    if (difficulty.current === 2 && currentScore > 1000) {
      difficulty.current = Math.min(difficulty.current + 1, 4)
    } else if (difficulty.current === 3 && currentScore > 4000) {
      difficulty.current = Math.min(difficulty.current + 1, 4)
    } else if (difficulty.current === 4 && currentScore > 8000) {
      difficulty.current = Math.min(difficulty.current + 1, 5)
    } else if (difficulty.current === 5 && currentScore > 10000) {
      difficulty.current = Math.min(difficulty.current + 1, 6)
    }
  }, [currentScore])

  useEffect(() => {
    if (comboScale === 0) {
      setShouldComboTransition(true)
      setComboScale(1.5)
      comboDisappearTimeout.current = setTimeout(() => {
        setComboScale(0.1)
        setCombo(0)
        setFeverMode(0)
      }, 2000)
    }
  }, [comboScale])

  useEffect(() => {
    if (combo > 0 && combo % 50 === 0) {
      addTime(setRemainingTime)
    }
  }, [combo])

  useEffect(() => {
    if (feverMode === 0) {
      setBackgroundImage('url(../sidepick/background.png)')
      clearTimeout(feverBackgroundTimeout.current)
    } else if (feverMode === 1) {
      setBackgroundImage('url(../sidepick/feverBackground.gif)')
      setShowFeverText(true)
      setFeverTextImage('url(../sidepick/fever.png)')
      bonusAlign()
    } else {
      setShowFeverText(true)
      setBackgroundImage('url(../sidepick/feverBackground2.gif)')

      setFeverTextImage('url(../sidepick/ultra.png)')
    }
  }, [feverMode])

  useEffect(() => {
    if (showReceiveModal) {
      setTimeout(() => {
        setShowReceiveModal(false)
      }, 1500)
    }
  }, [showReceiveModal])

  useEffect(() => {
    if (showFeverText) {
      setTimeout(() => {
        setShowFeverText(false)
      }, 1500)
    }
  }, [showFeverText])

  useInterval(
    () => {
      if (preplayCountDown <= 0) {
        startGame()
        return
      }
      setPreplayCountDown(preplayCountDown - 1)
    },
    gameStatus === 'preplay' && preplayCountDown >= 0 ? 100 : null,
  )

  const addTime = (callback) => {
    callback((prev) => prev + 10)
  }

  const handleGameOver = async (e) => {
    setGameStatus('gameover')
    setBackgroundImage('url(../sidepick/background.png)')

    if (rankCategory === 'gganbu') {
      await getSidepickGganbuRanks()
    } else if (rankCategory === 'total') {
      await getSidepickTopRanks()
    }

    if (+currentScore > +bestScore) {
      setBestScore(currentScore)
      await backendApis.recordSidepickData({
        score: +currentScore,
        bestScore: +currentScore,
      })
      let rank = await backendApis.getSidepickRankByScore({
        bestScore: currentScore,
      })
      setMyRank(rank?.data)
    }
  }

  const getSidepickGganbuRanks = async () => {
    let rankData = await backendApis.getSidepickGganbuRanks()

    let temp = rankData.data
    let idx = temp.findIndex(
      (item) => item?.userId?.toString() === sidepickData?.userId?.toString(),
    )
    setUserGganbuRank(idx)
    setRankList(rankData.data)
  }

  const getSidepickTopRanks = async () => {
    let rankData = await backendApis.getSidepickTopRanks()
    setRankList(rankData.data)
  }

  const notify = (message, type) => {
    if (toastBlock.current === true) return
    if (type === 'error') {
      toast.error(message, { duration: 2500 })
    } else if (type === 'success') {
      toast.success(message, { duration: 2500 })
    }

    toastBlock.current = true
    setTimeout(() => {
      toastBlock.current = false
    }, 3000)
  }

  const getSidepickUserData = async () => {
    let userData = await backendApis.getSidepickUserData()
    if (!userData.data) {
      setBestScore(userData?.data?.bestScore || 0)
      setUserProfileThumbnail('https://assets.ilevit.com//empty-profile.png')
      setMyRank(1000)
    } else {
      setSidepickData(userData?.data)
      setUserName(userData?.data?.userName || '')
      setBestScore(userData?.data?.bestScore || 0)
      setUserProfileThumbnail(
        userData?.data?.userImage ||
          'https://assets.ilevit.com//empty-profile.png',
      )
      let rank = await backendApis.getSidepickRankByScore({
        bestScore: userData?.data?.bestScore || 0,
      })

      setMyRank(rank.data)
    }
  }

  const startGame = async () => {
    initGame()
    dailyReward()
  }

  const dailyReward = async () => {
    if (didReceiveReward) return
    else {
      setDidReceiveReward(false)
      let result = await backendApis.getSpecificOngoingAlfarmInfo({
        fieldsProjected: JSON.stringify({ lastSidepickRewardAt: 1 }),
      })
      let timeInfo = await backendApis.getStandardTimeInfo()
      if (timeChecker(result?.data?.lastSidepickRewardAt, timeInfo?.data)) {
        await backendApis.updatelastUpdatedAt(token, 'PUT', {
          updateType: 'lastSidepickRewardAt',
        })
        // await backendApis.giveReward(token, "PUT", {
        //   loggingRewardName: "sidepickDailyReward",
        //   rewardType: "water",
        //   valueAmount: 20,
        // });
        // setRewardAmount(20);
        // setShowReceiveModal(true);
      }
    }
  }

  const setUserAlfarmNotificationSettingToTrue = async () => {
    await backendApis.setUserAlfarmNotificationSettingToTrue()
    notify(`알림 설정 완료`, 'success')
    setShowBraggingModal(false)
  }

  const bonusAlign = async () => {
    fixedChar.current = 12
  }

  const initGame = async () => {
    charTable.current = shuffleArray(originalCharList)
    difficulty.current = 2
    setCurrentScore(0)
    setCombo(0)
    setRemainingTime(300)
    setSentCount(0)
    setFeverMode(0)
    initCharList()
    setGameStatus('playing')
    setFromHome(false)
  }

  const bragToFriend = async ({ gganbuId }) => {
    if (!canClickWaterIcon) return
    let result = await backendApis.sendMessageToGganbu(token, 'POST', {
      selectMessage: 201,
      gganbuId: gganbuId,
    })
    if (result?.data?.success) {
      setCanClickWaterIcon(false)
      setRewardAmount(5)
      setRewardText('자랑하기 성공')
      setShowReceiveModal(true)
      setTimeout(() => {
        setRewardAmount(20)
        setRewardText('')
        setCanClickWaterIcon(true)
      }, 2000)
      backendApis.giveReward(token, 'PUT', {
        loggingRewardName: 'sidepickDailyRewardBrag',
        rewardType: 'water',
        valueAmount: 5,
      })
      getSidepickGganbuRanks()
    } else if (
      result?.data?.success === false &&
      result?.data?.reason === 'cooltime'
    ) {
    } else if (
      result?.data?.success === false &&
      result?.data?.reason === 'pushsetting'
    ) {
      notify(
        `친구가 알림설정을 하지 않아서
      자랑하지 못했어요`,
        'error',
      )
    } else if (
      result?.data?.success === false &&
      result?.data?.reason === 'senderPushSettingOff'
    ) {
      setShowBraggingModal(true)
    }
    return result
  }

  const initCharList = (type) => {
    let temp = []

    for (let i = 0; i < 8; i++) {
      let flag = Math.floor(Math.random() * difficulty.current)
      temp.push(flag)
    }
    setCharList(temp)
  }

  const pushCharList = () => {
    let flag = Math.floor(Math.random() * difficulty.current)
    let temp = [...charList]
    if (fixedChar.current > 0) {
      fixedChar.current -= 1
      temp.push(0)
    } else {
      temp.push(flag)
    }
    setCharList([...temp])
  }

  const renderChar = ({
    idx = -1,
    flag,
    marginTop,
    position,
    marginBottom,
    fontSize = 4,
  }) => {
    let content = <div key={Math.random()}></div>
    if (
      idx > 8 ||
      (idx > 7 && wrongAnswerPenalty === true) ||
      (idx > 7 && combo === 0)
    ) {
      content = <div key={Math.random()}> </div>
    } else if (idx > 7 && wrongAnswerPenalty === false) {
      content = (
        <div
          style={{
            ...styles.char,
            top: `${marginTop}vw`,
            position,
            marginBottom,
          }}
          key={Math.random()}
          className={`${flag % 2 === 0 ? 'roll-out-left' : 'roll-out-right'}`}
        >
          {/* <div style={{ fontSize: `${fontSize}rem` }}>
            {charTable.current[flag]}
          </div> */}
          <div
            style={{
              width: '60px',
              height: '60px',
              backgroundImage: `url(../sidepick/${charTable.current[flag]}.png`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
      )
    } else if (
      wrongAnswerPenalty === true &&
      idx === charList.length - 1 - sentCount
    ) {
      content = (
        <div
          style={{
            ...styles.char,
            top: `${marginTop}vw`,
            position,
            marginBottom,
          }}
          key={Math.random()}
        >
          {/* <div style={{ fontSize: `${fontSize + 1}rem` }}>
            {charTable.current[flag]}
          </div> */}
          <div
            style={{
              width: '70px',
              height: '70px',
              backgroundImage: `url(../sidepick/${charTable.current[flag]}.png`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <div
              style={{
                // top: `${35}vw`,
                position,
                backgroundColor: 'red',
                width: '25vw',
                height: '20vw',
                borderRadius: 16,
                opacity: 0.3,
                left: '50%',
                top: '50%',
                transform: 'translate(-50%,-50%)',
              }}
            />
          </div>
        </div>
      )
    } else if (
      wrongAnswerPenalty === false &&
      idx === charList.length - 1 - sentCount
    ) {
      content = (
        <div
          style={{
            ...styles.char,
            position,
            top: `${marginTop}vw`,
            marginBottom,
          }}
          key={Math.random()}
        >
          <div
            style={{
              width: '70px',
              height: '70px',
              backgroundImage: `url(../sidepick/${charTable.current[flag]}.png`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
      )
    } else {
      content = (
        <div
          style={{
            ...styles.char,
            position,
            top: `${marginTop}vw`,
            marginBottom,
          }}
          key={Math.random()}
        >
          {/* <div style={{ fontSize: `${fontSize}rem` }}>
            {charTable.current[flag]}
          </div> */}
          <div
            style={{
              width: '60px',
              height: '60px',
              backgroundImage: `url(../sidepick/${charTable.current[flag]}.png`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
      )
    }

    return content
  }

  const onPressArrow = (dir) => {
    if (wrongAnswerPenalty === true) return
    checkAnswer(dir)
  }
  const checkAnswer = (dir) => {
    let cur = charList[sentCount]
    let isCorrect = false
    setShouldComboTransition(false)
    setComboScale(0)

    switch (dir) {
      default:
        if (cur % 2 === 0) {
          isCorrect = true
        }
        break
      case 'right':
        if (cur % 2 === 1) {
          isCorrect = true
        }
        break
    }
    if (isCorrect) {
      setSentCount((prev) => prev + 1)
      pushCharList()

      clearTimeout(comboDisappearTimeout.current)
      setCombo((prev) => {
        let combo = prev + 1
        if (combo === 0) {
          setFeverMode(0)
        } else if (combo === 20) {
          setFeverMode(1)
        } else if (combo === 40) {
          setFeverMode(2)
        }

        return combo
      })
      setCurrentScore((prev) => {
        let score = prev
        if (feverMode === 0) {
          score += 10
        } else if (feverMode === 1) {
          score += 20
        } else if (feverMode === 2) {
          score += 40 + combo
        }

        return score
      })
    } else {
      setCombo(0)
      setRemainingTime((prev) => Math.max(prev - 10, 0))
      setWrongAnswerPenalty(true)
      setFeverMode(0)
      setTimeout(() => {
        // setSentCount((prev) => prev + 1);
        setWrongAnswerPenalty(false)
        // pushCharList();
      }, 1000)
    }
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundImage: backgroundImage,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      {gameStatus === 'playing' ||
      gameStatus === 'preplay' ||
      gameStatus === 'gameover' ? (
        <button
          style={{
            position: 'absolute',
            width: '7vw',
            marginLeft: '2vw',
            paddingTop: '12vw',
            zIndex: 2222,
          }}
          action='goBack'
          onClick={() => {
            setGameStatus('getready')
            setCurrentScore(0)
          }}
        >
          <img src='/icon/Back.png' alt='' />
        </button>
      ) : (
        <NavLink
          to={`/farmPage?token=${token}&itemType=${itemType}&randomNumber=${randomString}`}
        >
          <button
            style={{
              position: 'absolute',
              width: '7vw',
              marginLeft: '2vw',
              paddingTop: '12vw',
              zIndex: 2222,
            }}
            action='goBack'
          >
            <img src='/icon/Back.png' alt='' />
          </button>
        </NavLink>
      )}

      {gameStatus === 'getready' && (
        <>
          <div
            style={{
              top: '20vw',
              width: '100%',
              height: '25vw',
              backgroundImage: 'url(../sidepick/titleRibbon.png)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              position: 'absolute',
            }}
          >
            <div
              style={{
                top: '30vw',
                width: '100%',
                height: '80vw',
                backgroundImage: 'url(../sidepick/explainv2.png)',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                position: 'absolute',
              }}
            />
          </div>
          {/* <div>하는 방법</div>
          <div>
            중앙의 동물들을 지정된 곳으로 보내주세요. 제대로 보낼 때마다 콤보가
            올라가고 높은 콤보를 유지하여야 점수를 높게 받을 수 있어요
          </div> */}
          <div
            style={styles.gameStartButton}
            onClick={() => {
              setGameStatus('preplay')
              setPreplayCountDown(40)
            }}
          >
            <div
              style={{
                fontSize: '1.4rem',
                fontWeight: 'bold',
                color: '#965F42',
              }}
            >
              시작하기
            </div>
            <div
              style={{ ...styles.gameStartButton, top: '180%' }}
              onClick={(e) => {
                e.stopPropagation()
                handleGameOver()
                setFromHome(true)
              }}
            >
              <div
                style={{
                  fontSize: '1.4rem',
                  fontWeight: 'bold',
                  color: '#965F42',
                }}
              >
                순위표 보기
              </div>
            </div>
          </div>
        </>
      )}
      {gameStatus === 'preplay' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: `5rem`,
            width: '100%',
            height: '100%',
            fontWeight: 'bold',
            textShadow:
              '1px 1px #965F42, -1px 1px #965F42, -1px -1px #965F42, 1px -1px #965F42',
          }}
        >
          {preplayCountDown > 10
            ? Math.ceil(preplayCountDown / 10) - 1
            : '시작!'}
        </div>
      )}
      {gameStatus === 'playing' && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            position: 'fixed',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: '20vw',
          }}
        >
          {/* UI */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '30vw',

              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            <div
              style={{
                fontSize: '2rem',
                color: 'white',
                fontWeight: 'bold',
                textShadow:
                  '1px 1px #965F42, -1px 1px #965F42, -1px -1px #965F42, 1px -1px #965F42',
              }}
            >{`${commaNumber(currentScore)}점`}</div>

            {!showFeverText && combo > 0 && (
              <div
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'maplestory',
                  fontSize: '2rem',
                  position: 'absolute',
                  top: '50vw',
                  zIndex: 100,
                  transform: `scale(${comboScale})`,
                  transition: shouldComboTransition
                    ? 'all 0.1s ease-in-out'
                    : '',
                  textShadow: '2px 4px #965F42',
                }}
                className='textBorder'
              >{`${commaNumber(combo)} Combo`}</div>
            )}
          </div>

          {/* charList */}
          <div
            style={{
              width: '100%',

              // flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vw',
              position: 'relative',
            }}
          >
            {charList
              ?.slice(0)
              .reverse()
              .map((e, i) =>
                renderChar({
                  idx: i,
                  flag: e,
                  marginTop: 10 * i,
                  position: 'absolute',
                  marginBottom: '35vw',
                  fontSize: 4,
                }),
              )}
          </div>

          {/* arrow buttons */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'flex-end',
              alignSelf: 'flex-end',
              width: '100%',
              height: '60vw',
            }}
          >
            <div
              style={{ ...styles.bottomButton, alignItems: 'flex-start' }}
              onPointerDown={() => onPressArrow('left')}
            >
              <div style={styles.charIndicator}>
                {charTable.current?.map((e, i) => {
                  if (i % 2 === 0 && i < difficulty.current) {
                    return renderChar({ flag: i, fontSize: 3 })
                  }
                })}
              </div>
              <div
                style={{
                  ...styles.arrow,
                  backgroundImage: 'url(../sidepick/left.png)',
                }}
              />
            </div>
            <div
              style={{ ...styles.bottomButton, alignItems: 'flex-end' }}
              onPointerDown={() => onPressArrow('right')}
            >
              <div style={styles.charIndicator}>
                {charTable.current?.map((e, i) => {
                  if (i % 2 >= 1 && i < difficulty.current) {
                    return renderChar({ flag: i, fontSize: 3 })
                  }
                })}
              </div>

              <div
                style={{
                  ...styles.arrow,
                  backgroundImage: 'url(../sidepick/right.png)',
                }}
              />
            </div>
          </div>

          {/* timer */}
          <TimerBar
            inittime={remainingTime}
            handleGameOver={handleGameOver}
            setPreGameover={setPreGameover}
            addTime={addTime}
            combo={combo}
          />

          {showFeverText && (
            <div
              style={{
                position: 'absolute',
                top: '40vw',
                width: '300px',
                height: '150px',
                // backgroundColor: "brown",
                backgroundImage: feverTextImage,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                zIndex: 999,
              }}
            />
          )}
          {preGameover && (
            <div
              style={{
                width: '100%',
                height: '120%',
                position: 'absolute',
                backgroundColor: 'rgba(0,0,0,0.5)',
                top: '-20vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '80%',
                  height: '80%',
                  backgroundImage: 'url(../sidepick/gameover.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  // transform: `scale(5)`,
                  // transition: preGameover ? "all 1s ease-in-out" : "",
                }}
                className='scale-up-center'
              />
            </div>
          )}
        </div>
      )}
      {gameStatus === 'gameover' && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            // maxHeight: "100vh",
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginTop: '20vw',
              width: '100vw',

              maxHeight: '100%',
              display: 'flex',

              backgroundImage: 'url(../sidepick/rankingBackground.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '6vw',
              paddingTop: '26vw',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '2vw',
                paddingRight: '2vw',

                borderLeft: '10px solid rgba(0,0,0,0)',
                borderRight: '10px solid rgba(0,0,0,0)',
              }}
            >
              <div
                style={{
                  paddingTop: 8,
                  color: '#965F42',
                  width: '100%',
                  textAlign: 'center',
                  paddingBottom: 8,
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >{`내 점수: ${commaNumber(currentScore)}점`}</div>

              <div
                style={{
                  color: '#965F42',
                  width: '100%',
                  textAlign: 'center',
                  paddingTop: 16,
                  paddingBottom: 8,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <div
                  style={
                    rankCategory === 'gganbu'
                      ? {
                          // border: "1px solid #965F42",
                          borderRadius: 8,
                          padding: 8,
                          fontSize: '4vw',
                          color: '#965F42',
                          backgroundColor: '#E5D1BF',
                          borderTop: '2px solid #C6AF97',
                          borderLeft: '2px solid #C6AF97',
                        }
                      : {
                          borderRadius: 8,
                          padding: 8,
                          fontSize: '4vw',
                          color: '#965F42',
                          borderTop: '1px solid #C6AF97',
                          borderLeft: '1px solid #C6AF97',
                          borderBottom: '2px solid #C6AF97',
                          borderRight: '2px solid #C6AF97',
                        }
                  }
                  onClick={async () => {
                    await getSidepickGganbuRanks()
                    setRankCategory('gganbu')
                  }}
                >
                  맞팜 순위
                </div>
                <div
                  style={
                    rankCategory === 'total'
                      ? {
                          // border: "1px solid #965F42",
                          borderRadius: 8,
                          padding: 8,
                          fontSize: '4vw',
                          color: '#965F42',
                          backgroundColor: '#E5D1BF',
                          borderTop: '2px solid #C6AF97',
                          borderLeft: '2px solid #C6AF97',
                        }
                      : {
                          borderRadius: 8,
                          padding: 8,
                          fontSize: '4vw',
                          color: '#965F42',
                          borderTop: '1px solid #C6AF97',
                          borderLeft: '1px solid #C6AF97',
                          borderBottom: '2px solid #C6AF97',
                          borderRight: '2px solid #C6AF97',
                        }
                  }
                  onClick={async () => {
                    await getSidepickTopRanks()
                    setRankCategory('total')
                  }}
                >
                  전체 순위
                </div>
                <div
                  style={
                    rankCategory === 'myrank'
                      ? {
                          // border: "1px solid #965F42",
                          borderRadius: 8,
                          padding: 8,
                          fontSize: '4vw',
                          color: '#965F42',
                          backgroundColor: '#E5D1BF',
                          borderTop: '2px solid #C6AF97',
                          borderLeft: '2px solid #C6AF97',
                        }
                      : {
                          borderRadius: 8,
                          padding: 8,
                          fontSize: '4vw',
                          color: '#965F42',
                          borderTop: '1px solid #C6AF97',
                          borderLeft: '1px solid #C6AF97',
                          borderBottom: '2px solid #C6AF97',
                          borderRight: '2px solid #C6AF97',
                        }
                  }
                  onClick={async () => {
                    setRankCategory('myrank')
                  }}
                >
                  내 순위
                </div>
              </div>
              {rankCategory === 'gganbu' ? (
                <div
                  style={{ marginBottom: 8, color: '#965F42', height: '5vw' }}
                >
                  - 친구에게 점수를 자랑하면 물 5g을 받아요 -
                </div>
              ) : (
                <div
                  style={{ marginBottom: 8, color: '#965F42', height: '5vw' }}
                />
              )}
              {rankCategory === 'gganbu' && (
                <div
                  style={{
                    width: '100%',
                    height: '65vw',

                    overflowY: 'scroll',
                  }}
                >
                  {rankList.map((item, idx) => (
                    <RankItem
                      key={Math.random()}
                      data={{
                        ...item,
                        rank: idx + 1,
                      }}
                      bragToFriend={bragToFriend}
                    />
                  ))}
                </div>
              )}

              {rankCategory === 'total' && (
                <div
                  style={{
                    width: '100%',
                    height: '65vw',

                    overflowY: 'scroll',
                  }}
                >
                  {rankList.map((item, idx) => (
                    <RankItem
                      key={Math.random()}
                      data={{ ...item, rank: idx + 1 }}
                    />
                  ))}
                </div>
              )}
              {rankCategory === 'myrank' && (
                <div
                  style={{
                    width: '100%',
                    height: '65vw',

                    overflowY: 'scroll',
                  }}
                >
                  <RankItem
                    data={{
                      bestScore: bestScore,
                      userImage: userProfileThumbnail,
                      rank: myRank,
                      userName: userName,
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                color: '#965F42',
                fontSize: '0.8rem',
                paddingTop: 8,
                // paddingBottom: 4,
              }}
            >
              *매주 일요일에 점수 및 순위가 초기화 돼요
            </div>
          </div>
          <div
            style={{
              width: 200,
              height: 50,
              bottom: 0,
              // marginBottom: "28vh",
              // left: "50%",
              // transform: "translate(-50%)",
              backgroundColor: '#FDB800',
              borderRadius: 16,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'maplestory',
              border: '2px solid #92614B',
              color: '#965F42',
              fontWeight: 'bold',
              zIndex: 20,
              marginTop: '6vw',
              marginBottom: '6vw',
            }}
            onClick={() => {
              if (fromHome) {
                setGameStatus('getready')
                setFromHome(false)
              } else {
                setGameStatus('preplay')
                setPreplayCountDown(40)
              }
            }}
          >
            {`${fromHome ? '돌아가기' : '시작하기'}`}
          </div>
          {showReceiveModal && (
            <div
              style={{
                width: '150px',
                height: '150px',
                borderRadius: 16,
                position: 'absolute',
                top: '50%',
                left: '50%',
                backgroundColor: 'rgba(0,0,0,0.8)',
                transform: 'translate(-50%,-50%)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ marginTop: 0 }}>{rewardText}</div>

              <div
                style={{
                  width: '75px',
                  height: '75px',
                  backgroundImage: 'url(../icon/water.png)',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                }}
              />
              <div style={{ marginTop: 8 }}>{`${rewardAmount}g 획득!`}</div>
            </div>
          )}

          {showBraggingModal && (
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'fixed',
                backgroundColor: 'rgba(0,0,0,0.8)',
                zIndex: 999,
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  width: '60vw',
                  height: '60vw',
                  backgroundColor: 'white',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%,-50%)',
                  zIndex: 999,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  flexDirection: 'column',
                  padding: 24,
                  borderRadius: 16,
                }}
              >
                <div
                  style={{
                    color: '#965F42',
                    textAlign: 'center',
                    paddingTop: '10vw',
                  }}
                >
                  친구에게 점수를 자랑하려면
                  <br />
                  알림 설정을 해야돼요
                </div>
                <div
                  style={{
                    width: '100%',
                    height: 50,
                    borderRadius: 16,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#965F42',
                    border: '2px solid #92614B',
                    backgroundColor: '#FDB800',
                  }}
                  onClick={() => {
                    setUserAlfarmNotificationSettingToTrue()
                  }}
                >
                  알림 동의하기
                </div>
                <button
                  style={{
                    width: '8vw',
                    right: '-2vw',
                    top: '-15vw',
                    position: 'absolute',
                    zIndex: 999,
                  }}
                  onClick={() => {
                    setShowBraggingModal(false)
                  }}
                >
                  <img
                    style={{
                      zIndex: 101,
                    }}
                    src='/icon/modalCloseButton.png'
                    alt=''
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SidePickGame

let styles = {
  gameStartButton: {
    position: 'absolute',
    width: 200,
    height: 60,
    // marginBottom: "28vh",
    left: '50%',
    top: '80%',
    transform: 'translate(-50%,-50%)',
    backgroundColor: '#FDB800',
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'maplestory',
    border: '2px solid #92614B',
    color: '#965F42',
    fontWeight: 'bold',
    zIndex: 20,
    textAlign: 'center',
    flexDirection: 'column',
  },
  bottomButton: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    paddingLeft: '16px',
    paddingRight: '16px',
  },
  charIndicator: {
    display: 'flex',
    position: 'absolute',
    bottom: '60vw',
    width: '20vw',
    height: '60vw',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
  },
  char: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // marginBottom: "65vw",
  },
  arrow: {
    width: '16vw',
    height: '16vw',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginTop: '20vw',
    marginLeft: '10vw',
    marginRight: '10vw',
  },
  progressBar: {
    height: '10px',
    width: '50%',
  },
}
