import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import dayjs from 'dayjs'
import ModalStore from '../../store/ModalStore'
import UserStore from 'store/UserStore'

const DailyLotteryModal = observer(
  ({
    setShowModal,
    inviteInfo,
    dailyLotteryData,
    farmData,
    setFarmData,
    personalDailyLotteryInfo,
    token,
    setIsDailyLotteryOn,
  }) => {
    const [isWinner, setIsWinner] = useState(false)
    const [didInviteTodayState, setDidInviteTodayState] = useState(true)
    const [beforeInvite, setBeforeInvite] = useState(false)
    const [afterInviteWaiting, setAfterInviteWaiting] = useState(false)
    const [afterInviteResult, setAfterInviteResult] = useState(false)

    const shareKakao = () => {
      const inviteData = {
        shareType: inviteInfo?.shareType,
        screen: 'gameTeset',
        title: '곧 타임 추첨이 시작돼요!',
        description: '팀을 맺고 추첨에 같이 참여해요!',
        templateId: 85165,
        bannerImage:
          'https://assets.ilevit.com//5797e13f-2a63-4d33-9d5d-f26db076f7b2.png',
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
    }

    useEffect(() => {
      let eightPM = dayjs(
        `${dailyLotteryData?.now.getFullYear()}-${
          dailyLotteryData?.now.getMonth() + 1
        }-${dailyLotteryData?.now.getDate()} 20:00:00`,
        'YYYY-MM-DD HH:mm:ss',
      )
      let diff = eightPM.diff(dailyLotteryData?.now, 'm')

      //타임추첨이라서 일별 비교가능
      const didInviteToday =
        dayjs().format('YYYY-MM-DD') ===
        dayjs(
          personalDailyLotteryInfo?.teammateInfo?.teamedAt || '2021-01-01',
        ).format('YYYY-MM-DD')
      setDidInviteTodayState(didInviteToday)

      if (diff < 0) {
        //8시 이후
        if (didInviteToday) {
          setAfterInviteResult(true)
        } else {
          setIsWinner(false)
          setAfterInviteResult(true)
        }
      } else {
        //8시 이전
        if (didInviteToday) {
          setAfterInviteWaiting(true)
        } else {
          setBeforeInvite(true)
        }
      }

      if (
        dailyLotteryData?.winningNumber ===
          personalDailyLotteryInfo?.winningNumber &&
        didInviteToday
      ) {
        setIsWinner(true)
      }
    }, [])

    async function getDailyLotterPrize() {
      let result = await backendApis.giveDailyLotteryPrize(token)
      if (result?.status === 200) {
        setIsDailyLotteryOn(false)
        let temp = farmData
        if (UserStore.waterExceeded) {
          temp.water += 50
        } else {
          temp.water += 100
        }
        setFarmData(temp)
        ModalStore.setIsModalOpen('basic')
      }
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage: 'url(../dailyLottery/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '53vw',
              paddingBottom: '16vw',
              // marginBottom: "15%",
            }}
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: '20vw',
                position: 'absolute',
                zIndex: 999,
                // borderRadius: "1px solid black",
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                // border: "1px solid black",
                width: '100%',
                height: '100%',
                // marginBottom: "12vw",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                // border: "1px solid black",
                padding: 8,
                paddingBottom: 8,
              }}
            >
              {/* 초대화면 */}
              {beforeInvite && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        color: 'black',
                        fontFamily: 'maplestory',
                        fontWeight: 'bold',
                      }}
                    >
                      매일 오후 8시에
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontFamily: 'maplestory',
                        fontWeight: 'bold',
                      }}
                    >
                      {`추첨을 통해 💧${dailyLotteryData?.prize}g을 드려요!`}
                    </div>
                  </div>
                  <div
                    style={{
                      // border: "1px solid black",
                      width: '30vw',
                      height: '30vw',
                      backgroundImage: 'url(../dailyLottery/trophy.png)',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                    onClick={() => shareKakao()}
                  />
                  <div
                    style={{
                      color: 'black',
                      fontFamily: 'maplestory',
                    }}
                  >
                    친구와 팀을 맺고 참여해보세요!
                  </div>
                  <div
                    style={{
                      background:
                        'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                      width: '60vw',
                      height: '14vw',
                      borderRadius: 99,
                      color: 'black',
                      fontFamily: 'Maplestory',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      shareKakao()
                    }}
                  >
                    팀 맺고 참여하기
                  </div>
                </>
              )}
              {/* 초대 후 대기 화면 */}
              {afterInviteWaiting && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // border: "1px solid ",
                    }}
                  >
                    <div
                      style={{
                        color: 'black',
                        fontFamily: 'maplestory',
                        marginBottom: '8px',
                        fontWeight: 'bold',
                      }}
                    >
                      {`오늘의 보상: 💧${dailyLotteryData?.prize}g`}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      width: '45vw',
                      padding: '16px 0px 8px 0px',
                      borderRadius: 16,
                      // border: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // border: "1px solid black",
                        marginBottom: 16,
                      }}
                    >
                      <img
                        style={{
                          width: '8vw',
                          height: '8vw',
                          borderRadius: 55,
                          marginRight: 4,
                        }}
                        alt={''}
                        src={personalDailyLotteryInfo?.userImage}
                      />
                      <img
                        style={{
                          width: '8vw',
                          height: '8vw',
                          borderRadius: 55,
                          marginLeft: 4,
                        }}
                        alt={''}
                        src={personalDailyLotteryInfo?.teammateInfo?.userImage}
                      />
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontFamily: 'maplestory',
                        marginBottom: 4,
                      }}
                    >
                      우리 팀 번호
                    </div>
                    <div
                      style={{
                        width: '20vw',
                        height: '20vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: 'url(../dailyLottery/ball.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    >
                      <div
                        style={{
                          color: 'black',
                          fontFamily: 'maplestory',
                          fontWeight: 'bold',
                          fontSize: '1.5rem',
                        }}
                      >
                        {`${personalDailyLotteryInfo?.winningNumber}`}
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div
                    style={{
                      color: "black",
                      fontFamily: "maplestory",
                    }}
                  >
                    오후 8시에 결과를 알아보세요!
                  </div> */}

                  <div
                    style={{
                      background:
                        'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                      width: '60vw',
                      height: '14vw',
                      borderRadius: 99,
                      color: 'black',
                      fontFamily: 'Maplestory',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 16,
                    }}
                    onClick={() => {
                      ModalStore.setIsModalOpen('basic')
                    }}
                  >
                    오후 8시에 결과보기
                  </div>
                </>
              )}
              {/* 당첨내역화면 */}
              {afterInviteResult && (
                <>
                  {isWinner ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            color: 'black',
                            fontFamily: 'maplestory',
                            fontWeight: 'bold',
                            fontSize: '1.4rem',
                          }}
                        >
                          {`축하합니다!`}
                        </div>
                      </div>
                      <div
                        style={{
                          // border: "1px solid black",
                          width: '45vw',
                          flex: 1,
                          backgroundImage: 'url(../dailyLottery/winner.png)',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                        }}
                      />
                      <div
                        style={{
                          color: 'black',
                          fontFamily: 'maplestory',
                          fontWeight: 'bold',
                          marginBottom: '8px',
                        }}
                      >
                        {`${dailyLotteryData?.prize}g 당첨!`}
                      </div>
                      <div
                        style={{
                          background:
                            'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                          width: '60vw',
                          height: '14vw',
                          borderRadius: 99,
                          color: 'black',
                          fontFamily: 'Maplestory',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          getDailyLotterPrize()
                        }}
                      >
                        당첨 보상 받기
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            color: 'black',
                            fontFamily: 'maplestory',
                            fontWeight: 'bold',
                            fontSize: '1.4rem',
                            marginBottom: 8,
                          }}
                        >
                          {`아쉽네요..`}
                        </div>
                        <div
                          style={{
                            color: 'black',
                            fontFamily: 'maplestory',

                            fontSize: '1rem',
                          }}
                        >
                          {`당첨 번호: ${dailyLotteryData?.winningNumber}`}
                        </div>
                      </div>
                      <div
                        style={{
                          // border: "1px solid black",
                          width: '45vw',
                          flex: 1,
                          marginLeft: '8vw',
                          backgroundImage: 'url(../dailyLottery/loser.png)',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                        }}
                      />
                      <div
                        style={{
                          color: 'black',
                          fontFamily: 'maplestory',
                          // fontWeight: "bold",
                          marginBottom: '8px',
                        }}
                      >
                        {didInviteTodayState
                          ? `내 번호: ${personalDailyLotteryInfo?.winningNumber}`
                          : `오늘은 참여하지 않았어요!`}
                      </div>
                      <div
                        style={{
                          background:
                            'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                          width: '60vw',
                          height: '14vw',
                          borderRadius: 99,
                          color: 'black',
                          fontFamily: 'Maplestory',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          ModalStore.setIsModalOpen('basic')
                        }}
                      >
                        내일 다시 도전하기
                      </div>
                    </>
                  )}
                </>
              )}
              <div
                style={{
                  width: '100%',
                  color: '#666',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  fontSize: '0.8rem',
                  margin: '8px 0px 0px 0px',
                }}
              >
                *당첨 번호와 우리 팀 번호가 같으면 당첨이에요!
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default DailyLotteryModal
