const devEndpoint =
  'https://alwayz.ent.us-central1.gcp.cloud.es.io/api/as/v1/engines/alwayz-game-dev/documents'
const prodEndpoint =
  'https://alwayz.ent.us-central1.gcp.cloud.es.io/api/as/v1/engines/alwayz-game-prod/documents'
let sessionTime = Date.now()
const endpoint =
  process.env.NODE_ENV === 'production' ? prodEndpoint : devEndpoint

const availableDataScheme = [
  'item_id',
  'user_id',
  'game',
  'session_id',
  'logged_at',
  'user_agent',
  'action',
]

const sendLog = async (params = {}) => {
  const user_id = params?.user_id
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: 'Bearer private-8ve8qh4i23kcy2ik7tydzjxv',
  })

  const data = {
    ...params,
    user_agent: navigator.userAgent,
    server_env: process.env.NODE_ENV,
    session_id: `${user_id}_${sessionTime}`,
    session_duration: Date.now() - sessionTime,
    logged_at: new Date(),
  }

  const refineData = {}
  for (const key in data) {
    if (key in availableDataScheme) {
      refineData[key] = data[key]
    }
  }

  // const res = await fetch(endpoint, {
  //   method: "POST",
  //   headers,
  //   body: JSON.stringify(data),
  // });
  // const json = await res.json();
}

const Log = {
  send: (props) => {
    // sendLog(props);
  },
}

export default Log
