import React from 'react'
import { observer } from 'mobx-react-lite'

const PushSettingFinishedPopup = observer(
  ({ type, setShowPushSettingFinishedPopup }) => {
    const Modal = () => {
      if (type === 'pushSetting') {
        return (
          <div
            style={{
              flex: 1,
              position: 'absolute',
              left: '0',
              top: '0',
              bottom: '0',
              right: '0',
              zIndex: 100,
            }}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 4000,
                top: '63%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                fontWeight: 'bold',
                fontSize: 22,
                textAlign: 'center',
                fontFamily: 'maplestory',
                width: '100%',
                height: '24vw',
              }}
            >
              <div>
                마케팅 정보 수신 동의<br></br> 설정이 완료되었습니다
              </div>
              <div
                style={{
                  fontSize: '4vw',
                  fontWeight: 'lighter',
                  marginTop: 6,
                }}
              >
                피드 > 푸시설정 에서 <br></br> 설정을 변경할 수 있어요!
              </div>
            </div>
            {/* <button
              style={{
                width: "30%",
                height: "10vw",
                top: "73%",
                left: "50%",
                transform: "translate(-50%, -80%)",
                position: "absolute",
                zIndex: 999,
                borderRadius: 30,
                background:
                  "linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)",
                fontFamily: "maplestory",
                color: "#402C24",
                fontSize: 18,
              }}
              onClick={() => {
                setShowPushSettingFinishedPopup(false);
              }}
            >
              확인
            </button> */}
          </div>
        )
      }
    }

    return (
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: '0',
          top: '-40vw',
          bottom: '0',
          right: '0',
          zIndex: 100,
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        {Modal()}
      </div>
    )
  },
)

export default PushSettingFinishedPopup
