import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

const SuccessTomorrowGetWaterModal = observer(
  ({ dailyGiveWater, level1, level2, level3, level4 }) => {
    const [opacity, setOpacity] = useState(0.2)
    const [plusWater, setPlusWater] = useState(30)
    const [show, setShow] = useState(false)
    const [firstCut, setFirstCut] = useState(false)
    const [waterDropApper, setWaterDropApper] = useState(false)
    const [updatedNumber, setUpdatedNumber] = useState(false)
    const [getCurrentWater, setGetCurrentWater] = useState(false)

    const ObjectAmount = () => {
      if (dailyGiveWater < level1) {
        return (
          <div
            style={{
              marginLeft: '7vw',
            }}
          >
            <div
              style={{
                color: '#EC6E54',
                marginTop: '1vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level1}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                채우면
              </div>
            </div>
            <div
              style={{
                color: '#EC6E54',
                marginTop: '0.5vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level1 + 30}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                받아요
              </div>
            </div>
          </div>
        )
      }
      if (dailyGiveWater < level2) {
        return (
          <div
            style={{
              marginLeft: '7vw',
            }}
          >
            <div
              style={{
                color: '#EC6E54',
                marginTop: '1vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level2}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                채우면
              </div>
            </div>
            <div
              style={{
                color: '#EC6E54',
                marginTop: '0.5vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level2 + 30}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                받아요
              </div>
            </div>
          </div>
        )
      }
      if (dailyGiveWater < level3) {
        return (
          <div
            style={{
              marginLeft: '7vw',
            }}
          >
            <div
              style={{
                color: '#EC6E54',
                marginTop: '1vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level3}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                채우면
              </div>
            </div>
            <div
              style={{
                color: '#EC6E54',
                marginTop: '0.5vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level3 + 40}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                받아요
              </div>
            </div>
          </div>
        )
      }
      if (dailyGiveWater < level4) {
        return (
          <div
            style={{
              marginLeft: '7vw',
            }}
          >
            <div
              style={{
                color: '#EC6E54',
                marginTop: '1vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level4}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                채우면
              </div>
            </div>
            <div
              style={{
                color: '#EC6E54',
                marginTop: '0.5vw',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {level4 + 50}g&nbsp;
              <div
                style={{
                  color: '#AB5A21',
                }}
              >
                받아요
              </div>
            </div>
          </div>
        )
      }
      if (dailyGiveWater >= level4) {
        return (
          <div
            style={{
              marginLeft: '7vw',
              color: '#EC6E54',
              marginTop: '1vw',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            내일 획득
          </div>
        )
      }
    }

    const ObjectAmount2 = () => {
      if (dailyGiveWater < level1) {
        return (
          <div
            style={{
              marginTop: '1vw',
            }}
          >
            계속 물을 주세요! <br />
            {level1}g 채우면 {level1 + 30}g&nbsp; 받아요
          </div>
        )
      }
      if (dailyGiveWater < level2) {
        return (
          <div
            style={{
              marginTop: '1vw',
            }}
          >
            계속 물을 주세요! <br />
            {level2}g 채우면 {level2 + 30}g&nbsp; 받아요
          </div>
        )
      }
      if (dailyGiveWater < level3) {
        return (
          <div
            style={{
              marginTop: '1vw',
            }}
          >
            계속 물을 주세요! <br />
            {level3}g 채우면 {level3 + 40}g&nbsp; 받아요
          </div>
        )
      }
      if (dailyGiveWater < level4) {
        return (
          <div
            style={{
              marginTop: '1vw',
            }}
          >
            계속 물을 주세요! <br />
            {level4}g 채우면 {level4 + 50}g&nbsp; 받아요
          </div>
        )
      }
      if (dailyGiveWater > level4) {
        return (
          <div
            style={{
              marginTop: '1vw',
            }}
          >
            내일 받을 수 있어요! <br />
          </div>
        )
      }
    }
    useEffect(() => {
      if (dailyGiveWater <= level2) {
        setPlusWater(30)
      } else if (dailyGiveWater <= level3) {
        setPlusWater(30)
      } else if (dailyGiveWater <= level4) {
        setPlusWater(40)
      } else {
        setPlusWater(50)
      }
    }, [dailyGiveWater])

    useEffect(() => {
      setTimeout(() => {
        setFirstCut(true)
      }, 1000)

      setTimeout(() => {
        setWaterDropApper(true)
      }, 900)

      setTimeout(() => {
        setUpdatedNumber(true)
      }, 1500)

      setTimeout(() => {
        setGetCurrentWater(true)
      }, 300)
    }, [])

    const Tag = () => {
      if (!firstCut) {
        return (
          <div
            style={{
              fontSize: '7vw',
              right: '-61vw',
              width: '100%',
              position: 'absolute',
              fontFamily: 'maplestory',
              marginTop: '-7.5vw',
              color: '#E6745B',
            }}
            className={firstCut ? 'fade-out' : ''}
          >
            +{plusWater}g
          </div>
        )
      }
    }

    useEffect(() => {
      setShow(true)
    }, [])

    useEffect(() => {
      const interval = setInterval(() => {
        setOpacity((prevOpacity) => {
          if (prevOpacity >= 0.7) {
            clearInterval(interval)
            return
          } else {
            return prevOpacity + 0.05
          }
        })
      }, 50)
      return () => clearInterval(interval)
    }, [])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1100,
            width: '100%',
            height: '100vh',
            backgroundColor: `rgba(0,0,0,${opacity})`,
          }}
        />
        <div
          style={{
            zIndex: 1101,
            width: '100vw',
            height: '180vw',
            position: 'fixed',
            bottom: show ? '50%' : '-90vw',
            left: '50%',
            transform: 'translate(-50%, 50%)',
            transition: 'bottom 0.5s ease-out',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundImage: 'url(../successTomorrowGetWater/background.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <div
            style={{
              color: 'linear-gradient(180deg, #FCE789 0%, #FEF9E2 100%)',
              fontSize: '5.5vw',
              marginTop: '-55vw',
            }}
          >
            물 주기 목표 달성! {plusWater}g을 더 받았어요.
          </div>

          <img
            src='/successTomorrowGetWater/bottle.png'
            alt=''
            style={{
              position: 'absolute',
              left: '35%',
              width: '45vw',
            }}
          />
          {!updatedNumber && getCurrentWater && (
            <div
              style={{
                zIndex: 11000000000,
                fontSize: '7.5vw',
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                position: 'absolute',
                fontFamily: 'maplestory',
                textShadow:
                  '-1.8px 0 #3983C0, 0 1.8px #3983C0, 1.8px 0 #3983C0, 0 -1.8px #3983C0',
                marginTop: '4vw',
              }}
              // className="slide-bl scale-up-tr"
            >
              {dailyGiveWater - plusWater}g
            </div>
          )}
          {updatedNumber && (
            <div
              style={{
                fontSize: '7.5vw',
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                position: 'absolute',
                fontFamily: 'maplestory',
                textShadow:
                  '-1.8px 0 #3983C0, 0 1.8px #3983C0, 1.8px 0 #3983C0, 0 -1.8px #3983C0',
                marginTop: '4vw',
              }}
              className={updatedNumber ? 'slide-in-bottom' : ''}
            >
              {dailyGiveWater}g
            </div>
          )}

          <Tag />

          {waterDropApper && (
            <div
              id='water-drops'
              style={{
                position: 'absolute',
                left: '65%',
                width: '8vw',
                display: 'flex',
                flexDirection: 'row',
                marginTop: '-7vw',
              }}
            >
              <img src='/successTomorrowGetWater/water1.png' alt='water' />

              <img
                style={{
                  marginLeft: '-3vw',
                }}
                src='/successTomorrowGetWater/water2.png'
                alt='water'
              />
              <img
                style={{
                  marginLeft: '-3vw',
                }}
                src='/successTomorrowGetWater/water3.png'
                alt='water'
              />
            </div>
          )}
          {updatedNumber && (
            <>
              <div
                id='water-drops'
                style={{
                  position: 'absolute',
                  left: '48.5%',
                  width: '35vw',
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '-8vw',
                  fontSize: '3.5vw',
                }}
                className='slit-in-vertical'
              >
                {ObjectAmount()}
              </div>
              <div
                style={{
                  color: 'linear-gradient(180deg, #FCE789 0%, #FEF9E2 100%)',
                  fontSize: '5.5vw',
                  marginTop: '60vw',
                  position: 'absolute',
                  textAlign: 'center',
                }}
              >
                {ObjectAmount2()}
              </div>
            </>
          )}
        </div>
      </>
    )
  },
)
export default SuccessTomorrowGetWaterModal
