import { observable } from 'mobx'

const BottomSheetStore = observable({
  isBottomSheetOpen: 'basic',

  setIsbottomSheetOpen(type) {
    this.isBottomSheetOpen = type
  },
})

export default BottomSheetStore
