import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import userButtonClickLogger from 'utils/userButtonClickLogger'

const BrainTeaserModal = observer(
  ({ token, farmData, setFarmData, isLoading }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)

    const sendToPurchaseScreen = () => {
      const paramsInfo = {
        screen: 'Algames',
      }
      window.location.href = `#navigate.${JSON.stringify(paramsInfo)}`
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '100%',
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '50%',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              style={{
                width: '8%',
                top: '-10%',
                right: '10%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <img
              src='/brainTeaser/modal.png'
              alt='abc'
              style={{ width: '80vw' }}
            />
            <div
              style={{
                background: isButtonPressed ? '#F7C82A' : '#F7C82A',
                boxShadow: isButtonPressed
                  ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                  : '',
                width: '60vw',
                height: '14vw',
                borderRadius: 99,
                color: 'black',
                fontFamily: 'maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '4.5vw',
                position: 'absolute',
                bottom: '10vw',
              }}
              onClick={() => {
                userButtonClickLogger({ buttonType: 'brainTeaser_modal' })
                sendToPurchaseScreen()
                ModalStore.setIsModalOpen('basic')
              }}
              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
              }}
            >
              응모하러 가기
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default BrainTeaserModal
