import AB from 'utils/ab'
import backendApis from '../utils/backendApis'
import { observable, runInAction } from 'mobx'
import dayjs from 'dayjs'
import moment from 'moment'

const DealAutomationStore = observable({
  firstBrandDealData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
  },
  secondBrandDealData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
  },

  firstFashionDealData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
  },
  secondFashionDealData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
  },
  firstLifeDealData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
  },
  secondLifeDealData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
  },
  firstNewItemDealData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
  },
  secondNewItemDealData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
  },
  firstWinnerDealData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
    questBanner: '',
    browsingQuestBanner: '',
  },
  secondWinnerDealData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
    questBanner: '',
    browsingQuestBanner: '',
  },
  thirdWinnerDealData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
    questBanner: '',
    browsingQuestBanner: '',
  },
  firstGganbuFarmBalloonAdData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenText: '',
    purchaseScreenBanner: '',
    questBanner: '',
    browsingQuestBanner: '',
  },
  // secondGganbuFarmBalloonAdData: {
  //   entryIcon: '',
  //   timeRangeStart: '',
  //   timeRangeEnd: '',
  //   background: '',
  //   purchaseScreenText: '',
  //   purchaseScreenBanner: '',
  //   questBanner: '',
  //   browsingQuestBanner: '',
  // },
  firstWaterFountainAdData: {
    entryIcon: '',
    timeRangeStart: '',
    timeRangeEnd: '',
    background: '',
    purchaseScreenBanner: '',
    itemIds: [],
  },
  // secondWaterFountainAdData: {
  //   entryIcon: '',
  //   timeRangeStart: '',
  //   timeRangeEnd: '',
  //   background: '',
  //   purchaseScreenBanner: '',
  //   itemIds: [],
  // },

  async fetchCommonData() {
    if (!this.firstBrandDealData.entryIcon) {
      let result = await backendApis.dealAutomation('GET', {})
      runInAction(() => {
        this.firstFashionDealData = { ...result?.data?.firstFashionDealData }
        this.secondFashionDealData = { ...result?.data?.secondFashionDealData }
        this.firstBrandDealData = { ...result?.data?.firstBrandDealData }
        this.secondBrandDealData = { ...result?.data?.secondBrandDealData }
        this.firstLifeDealData = { ...result?.data?.firstLifeDealData }
        this.secondLifeDealData = { ...result?.data?.secondLifeDealData }
        this.firstNewItemDealData = { ...result?.data?.firstNewItemDealData }
        this.secondNewItemDealData = { ...result?.data?.secondNewItemDealData }
        this.firstWinnerDealData = { ...result?.data?.firstWinnerDealData }
        this.secondWinnerDealData = { ...result?.data?.secondWinnerDealData }
        this.thirdWinnerDealData = { ...result?.data?.thirdWinnerDealData }
        this.firstGganbuFarmBalloonAdData = {
          ...result?.data?.firstGganbuFarmBalloonAdData,
        }
        // this.secondGganbuFarmBalloonAdData = {
        //   ...result?.data?.secondGganbuFarmBalloonAdData,
        // }
        this.firstWaterFountainAdData = {
          ...result?.data?.firstWaterFountainAdData,
        }
        // this.secondWaterFountainAdData = {
        //   ...result?.data?.secondWaterFountainAdData,
        // }
      })
    }
  },

  async calculateTimeStamp() {
    const now = new Date()
    let nextTime = new Date(now)

    const currHours = now.getHours()
    const currMinutes = now.getMinutes()

    nextTime.setSeconds(0)
    nextTime.setMilliseconds(0)

    if (currHours < 8 && currHours >= 2) {
      nextTime.setHours(8, 0, 0)
    } else if (currHours === 8 && currMinutes <= 10) {
      nextTime.setHours(8, 10, 0)
    } else if (currHours === 20 && currMinutes <= 10) {
      nextTime.setHours(20, 10, 0)
    } else if (currHours >= 8 && currHours < 20) {
      nextTime.setHours(20, 0, 0)
    } else {
      // 20:10 이후
      nextTime.setHours(8, 0, 0)
      nextTime.setDate(nextTime.getDate() + 1)
    }
    return new Date(nextTime)
  },

  isTenMinDealOpen() {
    const now = dayjs()
    if (!now.isValid()) {
      return false
    }

    const openTimeAM = now.startOf('day').add(7, 'hour').add(0, 'minute')
    const closeTimeAM = now.startOf('day').add(8, 'hour').add(12, 'minute')

    const openTimePM = now.startOf('day').add(19, 'hour').add(0, 'minute')
    const closeTimePM = now.startOf('day').add(20, 'hour').add(12, 'minute')

    return (
      (now.isAfter(openTimeAM) && now.isBefore(closeTimeAM)) ||
      (now.isAfter(openTimePM) && now.isBefore(closeTimePM))
    )
  },

  isTenMinDealModalOpen() {
    const now = dayjs()
    if (!now.isValid()) {
      return false
    }

    const openTimeAM = now.startOf('day').add(7, 'hour').add(50, 'minute')
    const closeTimeAM = now.startOf('day').add(8, 'hour').add(10, 'minute')

    const openTimePM = now.startOf('day').add(19, 'hour').add(50, 'minute')
    const closeTimePM = now.startOf('day').add(20, 'hour').add(10, 'minute')

    return (
      (now.isAfter(openTimeAM) && now.isBefore(closeTimeAM)) ||
      (now.isAfter(openTimePM) && now.isBefore(closeTimePM))
    )
  },

  isTenMinDealBrowsingQuestAvailable() {
    const now = dayjs()
    if (!now.isValid()) {
      return false
    }
    const openTimeAM = now.startOf('day').add(8, 'hour').add(0, 'minute')
    const closeTimeAM = now.startOf('day').add(8, 'hour').add(10, 'minute')
    const openTimePM = now.startOf('day').add(20, 'hour').add(0, 'minute')
    const closeTimePM = now.startOf('day').add(20, 'hour').add(10, 'minute')

    return (
      (now.isSameOrAfter(openTimeAM) && now.isBefore(closeTimeAM)) ||
      (now.isSameOrAfter(openTimePM) && now.isBefore(closeTimePM))
    )
  },

  tenMinDealBrowsingQuestOpen() {
    const now = dayjs()
    if (!now.isValid()) {
      return false
    }

    const openTimeAM = now.startOf('day').add(0, 'hour').add(0, 'minute')
    const closeTimeAM = now.startOf('day').add(23, 'hour').add(59, 'minute')
    const openTimePM = now.startOf('day').add(20, 'hour').add(0, 'minute')
    const closeTimePM = now.startOf('day').add(20, 'hour').add(30, 'minute')

    return (
      (now.isSameOrAfter(openTimeAM) && now.isBefore(closeTimeAM)) ||
      (now.isSameOrAfter(openTimePM) && now.isBefore(closeTimePM))
    )
  },
})

export default DealAutomationStore
