import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../store/ModalStore'

const ResurrectionModal = observer(({ token, gganbuName, gganbuId }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  const shareKakao = () => {
    const inviteData = {
      shareType: 'AlfarmLeftGganbuV2',
      title: `${gganbuName}님 고급비료 받고 다시 작물 같이 키워요!`,
      description: `작물들이 ${gganbuName}님을 기다리고 있어요. \n접속해서 물을 주면 고급비료를 받을 수 있어요!`,
      bannerImage:
        'https://assets.ilevit.com/5168652d-0e21-4a7f-87d0-e38f48e7553d_original.jpg',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 900,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            width: '80%',
          }}
        >
          <img alt='' src={'/icon/resurrectionModal.png'} />
          <button
            style={{
              width: '10%',
              position: 'absolute',
              zIndex: 3,
              top: '-15%',
              right: '0%',
              transform: 'translate(-50%,-50%)',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img src='/icon/modalCloseButton.png' alt='' />
          </button>
          <button
            style={{
              width: '75%',
              bottom: '6vw',
              height: '14vw',
              left: '10vw',
              position: 'absolute',
              zIndex: 3,
              background: isButtonPressed
                ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: '4.5vw',
              borderRadius: '40vw',
            }}
            onClick={() => {
              shareKakao()
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
          >
            맞팜 친구 다시 부르기
          </button>
        </div>
      </div>
    </>
  )
})

export default ResurrectionModal
