import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import WhiteTextTimer from '../WhiteTextTimer'
import useInterval from '../../utils/customHooks/useInterval'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import ToastStore from 'store/ToastStore'
import UserStore from 'store/UserStore'

const LotteryDrawModal = observer(
  ({
    token,
    setShowModal,
    farmData,
    setFarmData,
    userCodePushVersion,
    codePushVersionAvailable,
    setUpdateMinigameInfo,
    showRewardToast,
    setLotteryDrawSpecialPurchase,
  }) => {
    const [scale, setScale] = useState(1)
    const [threshHold, setThreshHold] = useState(5)
    const [didPressButton, setDidPressButton] = useState(false)
    const [isInflateOn, setIsInflateOn] = useState(false)
    const [scaleStyle, setScaleStyle] = useState(
      `translate(-50%, 0%) scale(${scale}, ${scale})`,
    )
    const [showReward, setShowReward] = useState(false)
    const [backgroundSrc, setBackgroundSrc] = useState(
      'url(../minigame/lotteryDraw/background.gif)',
    )
    const [rewardSrc, setRewardSrc] = useState(
      'url(../minigame/lotteryDraw/winner.png)',
    )

    const images = [
      'url(../minigame/lotteryDraw/capsule1.png)',
      'url(../minigame/lotteryDraw/capsule2.png)',
      'url(../minigame/lotteryDraw/capsule3.png)',
      'url(../minigame/lotteryDraw/capsule4.png)',
      'url(../minigame/lotteryDraw/capsule5.png)',
    ]
    const randomIndex = Math.floor(Math.random() * images.length)
    const [randomCapsule, setRandomCapsule] = useState(0)

    useEffect(() => {
      setRandomCapsule(randomIndex)
    }, [])

    const [rewardForToast, setRewardForToast] = useState()

    function onPressButton() {
      if (farmData?.water < 10) {
        ToastStore.toastOn({
          type: 'error',
          message: '물이 부족해요! 10g이상 물이 있어야 해요',
          duration: 3000,
        })
        return
      }

      giveLotteryDrawReward()
      setIsInflateOn(true)
      setDidPressButton(true)
    }

    async function giveLotteryDrawReward() {
      let result = await backendApis.giveLotteryDrawReward(token)
      let temp = { ...farmData }
      temp.water -= 10
      temp.water += result?.data?.water
      temp.fertilizer += result?.data?.fertilizer
      temp.fertilizerSet += result?.data?.fertilizerSet

      if (result?.data?.fertilizerSet > 0) {
        setRewardSrc('url(../minigame/lotteryDraw/fertilizerSet.png)')
        setRewardForToast({
          rewardAmount: result?.data?.fertilizerSet,
          rewardType: 'fertilizerSet',
        })
      } else if (result?.data?.fertilizer > 0) {
        setRewardSrc('url(../minigame/lotteryDraw/fertilizer.png)')
        setRewardForToast({
          rewardAmount: result?.data?.fertilizer,
          rewardType: 'fertilizer',
        })
      } else if (result?.data?.specialPurchase) {
        setRewardSrc('url(../minigame/lotteryDraw/specialPurchase.png)')
        setRewardForToast({
          rewardAmount: 1,
          rewardType: 'specialPurchase',
        })

        setFarmData((prev) => {
          prev.lotteryDrawSpecialPurchase = new Date()
          return prev
        })
        setLotteryDrawSpecialPurchase(true)
      } else if (result?.data?.water === 25) {
        setRewardSrc('url(../minigame/lotteryDraw/water25.png)')
        setRewardForToast({
          rewardAmount: 50,
          rewardType: 'water',
        })
      } else {
        setRewardSrc('url(../minigame/lotteryDraw/water.png)')
        setRewardForToast({
          rewardAmount: 50,
          rewardType: 'water',
        })
      }
      setFarmData(temp)

      setUpdateMinigameInfo(true)
    }

    useEffect(() => {
      if (showReward) {
        setBackgroundSrc('')
      }
    }, [showReward])

    useInterval(
      () => {
        if (scale >= threshHold) {
          setIsInflateOn(false)
        }
        let scaleAdd = 0.06
        setScale(scale + scaleAdd)
        setScaleStyle(
          `translate(-50%, -${scale * 10 - 10}%) scale(${scale + scaleAdd}, ${
            scale + scaleAdd
          })`,
        )
      },
      isInflateOn ? 10 : null,
    )

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '120vw',
              top: '65%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              padding: 16,
              backgroundImage: backgroundSrc,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '57vw',
              paddingBottom: '16%',
              // marginBottom: "15%",
            }}
          >
            {didPressButton && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  width: '20vw',
                  top: '65%',
                  left: '40%',
                  transform: 'translate(-50%, -50%)',
                }}
                className='slide-out-top'
              >
                <img src='/icon/luckydrawCost.png' alt='' />
              </div>
            )}
            <div
              style={{
                width: '100%',
                height: '40vw',
                top: '-40vw',
                backgroundImage: 'url(../minigame/lotteryDraw/title.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                position: 'absolute',
              }}
            />
            <button
              style={{
                width: '8vw',
                right: 16,
                top: '-45vw',
                position: 'absolute',
                zIndex: 999,
                // borderRadius: "1px solid black",
              }}
              onClick={() => {
                if (showReward) {
                  showRewardToast(
                    rewardForToast?.rewardAmount,
                    rewardForToast?.rewardType,
                  )
                }
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>

            <div
              style={{
                // border: "1px solid black",
                width: '100%',
                height: '100%',
                // marginBottom: "12vw",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                // border: "1px solid black",
                color: 'black',
                fontFamily: 'maplestory',
              }}
            >
              {!showReward && (
                <div
                  style={{
                    width: '40vw',
                    height: '16vw',
                    top: '84vw',
                    left: '50%',
                    transform: 'translate(-50%, 0%)',
                    position: 'absolute',
                    // backgroundImage: "url(../minigame/lotteryDraw/button.png)",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    // marginBottom: "15%",
                    // border: "1px solid black",
                  }}
                  onClick={() => {
                    if (!didPressButton) {
                      onPressButton()
                    }
                  }}
                />
              )}
              {didPressButton && !showReward && (
                <>
                  {scale >= threshHold && (
                    <>
                      <div
                        style={{
                          width: '12vw',
                          height: '12vw',
                          backgroundImage:
                            'url(../minigame/waterBomb/finger.png)',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          zIndex: 98,
                          marginTop: '35vw',
                          marginLeft: '50vw',
                        }}
                        onClick={() => {
                          if (scale >= threshHold) {
                            setShowReward(true)
                          }
                        }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          width: '12vw',
                          height: '12vw',

                          zIndex: 98,
                          marginTop: '30vw',
                          marginLeft: '14vw',
                          color: 'black',
                          fontFamily: 'maplestory',
                          bottom: '23vw',
                          left: '45vw',
                        }}
                        onClick={() => {
                          if (scale >= threshHold) {
                            setShowReward(true)
                          }
                        }}
                      >
                        클릭!
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      width: '12vw',
                      height: '10vw',
                      top: '72vw',
                      left: '50%',
                      transform: scaleStyle,
                      position: 'absolute',
                      backgroundImage: images[randomCapsule],

                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      paddingTop: '57vw',
                      paddingBottom: '16%',
                      // marginBottom: "15%",
                    }}
                    onClick={() => {
                      if (scale >= threshHold) {
                        setShowReward(true)
                      }
                    }}
                  />
                </>
              )}
              {showReward && (
                <div
                  style={{
                    top: '35vw',
                    width: '50vw',
                    height: '50vw',
                    position: 'absolute',
                    backgroundImage: rewardSrc,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    // border: "10px solid white",
                    // marginBottom: "15%",
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      top: '-40vw',
                      width: '80vw',
                      height: '50vw',
                      position: 'absolute',
                      backgroundImage:
                        'url(../minigame/lotteryDraw/congrat.png)',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  />
                  <div
                    style={{
                      // width: "100%",
                      background:
                        'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
                      fontFamily: 'maplestory',
                      color: 'black',
                      position: 'absolute',
                      textAlign: 'center',
                      bottom: '-25vw',
                      padding: '18px 44px 18px 44px',
                      borderRadius: 99,
                      fontSize: '1.5rem',
                    }}
                    onClick={() => {
                      showRewardToast(
                        rewardForToast?.rewardAmount,
                        rewardForToast?.rewardType,
                      )
                      ModalStore.setIsModalOpen('basic')
                    }}
                  >
                    보상 받기
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default LotteryDrawModal
