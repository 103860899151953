import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import dayjs from 'dayjs'
import WhiteTextTimer from '../WhiteTextTimer'
import BottomSheetStore from '../../store/BottomSheetStore'
import TimeStore from '../../store/TimeStore'

const data = [
  { img: '/icon/alfarmMessage1.png', id: 1 },
  { img: '/icon/alfarmMessage2.png', id: 2 },
  { img: '/icon/alfarmMessage3.png', id: 3 },
  { img: '/icon/alfarmMessage4.png', id: 4 },
  { img: '/icon/alfarmMessage5.png', id: 5 },
  { img: '/icon/alfarmMessage6.png', id: 6 },
]

const MessageCard = ({ id, img, onClick, selected = false, disabled }) => (
  <button
    style={{
      width: '45vw',
      display: 'inline-block',
      margin: '1vw',
      opacity: disabled ? 0.5 : 1,
    }}
    disabled={disabled}
    onClick={() => onClick(id)}
  >
    <div
      style={{
        position: 'relative',
        borderRadius: 18,
        overflow: 'hidden',
        border: selected ? '6px solid #FFEA2F' : '6px solid white',
      }}
    >
      <img
        style={{
          width: '43vw',
          background: 'white',
        }}
        src={img}
        alt=''
      />
    </div>
  </button>
)

const MessageModal = observer(
  ({ token, gganbuId, setShowMessageSuccessModal }) => {
    const [selectMessage, setSelectMessage] = useState(0)
    const [isButtonPressedMessage, setIsButtonPressedMessage] = useState(false)
    const [isButtonPressed, setIsButtonPressed] = useState(false)

    const [canSendMessage, setCanSendMessage] = useState(-1)
    const [lastSentMessage, setLastSentMessage] = useState(new Date())

    const sendMessage = async () => {
      if (isButtonPressedMessage) return
      setIsButtonPressedMessage(true)
      await backendApis.sendMessageToGganbu(token, 'POST', {
        selectMessage,
        gganbuId,
      })
      setIsButtonPressedMessage(false)
      setShowMessageSuccessModal(true)
      setTimeout(() => setShowMessageSuccessModal(false), 1500)
      setSelectMessage(0)
      BottomSheetStore.setIsbottomSheetOpen('basic')
    }

    useEffect(() => {
      const getLastSendMessageAt = async () => {
        const result = await backendApis.getLastSendMessageAt(token, 'GET', {
          gganbuId: gganbuId,
        })
        setLastSentMessage(result)

        if (TimeStore.currentDayJS - dayjs(result) >= 3600000)
          setCanSendMessage(1)
        else setCanSendMessage(0)
      }
      getLastSendMessageAt()
    }, [gganbuId, token])

    const SendMessageButton = () => {
      if (canSendMessage < 0) {
        return (
          <div
            style={{
              position: 'absolute',
              width: '80%',
              fontFamily: 'maplestory',
              color: 'black',
              fontSize: '16px',
              borderRadius: '30px',
              padding: 16,
              zIndex: 999,
              background: '#D3D7DD',
              boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
              textAlign: 'center',
              bottom: '7vw',
              paddingBottom: '4vw',
              left: '10%',
            }}
          >
            잠시 후 다시 시도해주세요
          </div>
        )
      } else if (!canSendMessage) {
        return (
          <div
            style={{
              position: 'absolute',
              width: '80%',
              fontFamily: 'maplestory',
              color: 'black',
              fontSize: '16px',
              borderRadius: '30px',
              padding: 16,
              zIndex: 1,
              background: '#D3D7DD',
              boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
              textAlign: 'center',
              bottom: '7vw',
              paddingBottom: '4vw',
              left: '10%',
            }}
          >
            <WhiteTextTimer
              onTimeEnd={() => {
                setCanSendMessage(true)
              }}
              timeStamp={new Date(lastSentMessage)}
              timerMinutes={60}
              color='#442b22'
            />{' '}
            후에 다시 보낼 수 있어요
          </div>
        )
      } else if (selectMessage === 0) {
        return (
          <div
            style={{
              position: 'absolute',
              width: '80%',
              fontFamily: 'maplestory',
              color: 'black',
              fontSize: '16px',
              borderRadius: '30px',
              padding: 16,
              zIndex: 1,
              background: '#D3D7DD',
              boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
              textAlign: 'center',
              bottom: '7vw',
              paddingBottom: '4vw',
              left: '10%',
            }}
          >
            보낼 쪽지를 선택하세요
          </div>
        )
      }

      return (
        <div
          style={{
            position: 'absolute',
            width: '80%',
            fontFamily: 'maplestory',
            color: 'black',
            fontSize: '4.5vw',
            borderRadius: '30vw',
            padding: 16,
            zIndex: 1,
            background: isButtonPressed
              ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressed
              ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
              : '',
            textAlign: 'center',
            bottom: '7vw',
            paddingBottom: '4vw',
            left: '10%',
          }}
          onClick={sendMessage}
        >
          {isButtonPressedMessage ? '쪽지 보내는중...' : '쪽지 남기기'}
        </div>
      )
    }

    return (
      <div>
        <div
          style={{
            color: 'black',
            fontFamily: 'maplestory',
            fontSize: 18,
            marginTop: 8,
            textAlign: 'center',
          }}
        >
          친구에게 쪽지 남기기
          <div
            style={{
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: 16,
              marginTop: 8,
              textAlign: 'center',
            }}
          >
            남기고 싶은 말을 선택해보세요
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: 2,
            backgroundColor: '#EDEDED',
            fontSize: 18,
            marginTop: 16,
          }}
        />
        {/* 메세지 카드 시작 */}
        <div
          style={{
            width: '100%',
            zIndex: 1,
            marginRight: '3vw',
            marginLeft: '3vw',
            marginTop: '3vw',
            marginBottom: '18vw',
          }}
        >
          <div style={{ paddingBottom: '2vw' }}>
            {data.map(({ img, id }) => (
              <MessageCard
                id={id}
                disabled={!canSendMessage}
                selected={selectMessage === id}
                img={img}
                onClick={(id) => setSelectMessage(id)}
              />
            ))}
          </div>
          <div
            style={{
              marginBottom: '4vw',
            }}
          >
            <SendMessageButton />
          </div>
          <button
            style={{
              width: '7vw',
              top: '4vw',
              right: '8vw',
              position: 'absolute',
              zIndex: 11,
            }}
            onClick={() => {
              BottomSheetStore.setIsbottomSheetOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 1,
              }}
              src='/icon/bottomCloseButton.png'
              alt=''
            />
          </button>
        </div>
      </div>
    )
  },
)

export default MessageModal
