import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import dayjs from 'dayjs'
import ModalStore from '../../store/ModalStore'
import TimeStore from 'store/TimeStore'
import { toJS } from 'mobx'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import UserStore from 'store/UserStore'

const ChurnedGganbuBountyModal = observer(({ token, farmData }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const throttleRef = useRef({})

  const invite = () => {
    const inviteData = {
      shareType: 'AlfarmChurnedGganbuBounty',
      title: '[올팜] 함께 올팜 작물을 키워봐요!',
      description: '내가 키운 작물을 무료로 받아보세요!',
      bannerImage:
        'https://assets.ilevit.com/34ed5826-d1bd-4822-940f-02274f0a9042.png',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
    AlfarmEventLogger({
      throttleRef,
      locationType: 'ChurnedGganbuBountyModal',
      locationName: 'inviteButton',
      eventType: 'click',
      collection: 'UserAlfarmClickLog',
    })
  }

  return (
    <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
      <div
        className='w-[90vw] h-[150vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex absolute justify-end items-center flex-col px-[16px] pt-[28vw] pb-[12vw]'
        style={{
          backgroundImage: `url(../gganbuBounty/background.png)`,
        }}
      >
        <div className='w-full flex flex-col justify-center items-center pb-[32vw]'>
          <img
            className='w-[26vw] h-[26vw] rounded-full'
            alt='profileImage'
            src={`${UserStore?.farmData?.gganbuBountyInfo?.gganbu?.userImage}`}
            onError={(e) => {
              e.target.src = UserStore?.default_profile_image
            }}
          />
          <div className='text-[#442b22] font-bold mt-[2vw] text-[5vw]'>
            {UserStore?.farmData?.gganbuBountyInfo?.gganbu?.userName}
          </div>
        </div>
        <button
          className='text-[5vw] font-bold rounded-3xl py-[14px] z-[2000] w-[70%] top-[120vw] left-[12.5vw] text-[#442B22] mt-[4vw]'
          style={{
            background: isButtonPressed
              ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressed
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
          onClick={() => {
            invite()

            ModalStore.setIsModalOpen('basic')
          }}
        >
          초대하기
        </button>
        <button
          className=' z-[1101] w-[8%] -top-[10vw] right-[4vw] absolute'
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
        </button>
      </div>
    </div>
  )
})

export default ChurnedGganbuBountyModal
