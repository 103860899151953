import { observable } from 'mobx'

const SecondModalStore = observable({
  isModalOpen: 'basic',

  setIsModalOpen(type) {
    this.isModalOpen = type
  },
})

export default SecondModalStore
