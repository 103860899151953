import React, { useState } from 'react'
import ExpAnim from './expAnim'
import { farmingList } from '../../config'
import ReviewModal from './reviewModal'
import Log from '../../utils/log'

function LuckyPiggyGaugeBar({
  level = 1,
  remainExp,
  expToLevelUp,
  token,
  userId,
}) {
  const [showRewardModal, setShowRewardModal] = useState(false)
  const [clickedReward, setClickedReward] = useState(false)

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            marginLeft: '12.5vw',
            marginTop: '2vw',
          }}
          className='gauge-bg-piggy'
        >
          <div
            className='gauge-bar-piggy'
            style={{
              width: `${(remainExp / (expToLevelUp + remainExp)) * 100}%`,
            }}
          />

          <div
            style={{
              width: `${(remainExp / (expToLevelUp + remainExp)) * 98.8}%`,
            }}
          />

          <div
            style={{
              paddingTop: '1vw',
              fontSize: '4.5vw',
              position: 'absolute',
              left: '22.5vw',
            }}
          >
            {`${((remainExp / (remainExp + expToLevelUp) || 0) * 100).toFixed(
              2,
            )}%`}
          </div>
        </div>

        <>
          <button
            style={{
              width: '12vw',
              position: 'absolute',
              right: '4vw',
              bottom: '-2.5vw',
            }}
            className={!clickedReward && 'pulsate-fwd'}
            onClick={() => {
              setShowRewardModal(true)
              setClickedReward(true)
            }}
          >
            {level === 0 && (
              <img alt='' src={`/luckyPiggy/level${level + 2}.png`} />
            )}
            {level !== 0 && (
              <img alt='' src={`/luckyPiggy/level${level + 1}.png`} />
            )}
          </button>
        </>
      </div>
    </div>
  )
}

export default LuckyPiggyGaugeBar
