import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import commaNumber from 'comma-number'
import ModalStore from 'store/ModalStore'
import backendApis from 'utils/backendApis'

import moment from 'moment'

const GAME_MSG = {
  checkin: '상점 출석',
  browsing: '상품 구경',
  purchase: '상품 구매',
  exchange: '상점 교환',
  orderCancel: '결제 취소',
}

const DealEventHistoryModal = observer(({ token }) => {
  const [newYearHistoryData, setNewYearHistoryData] = useState([])
  const [emptyModal, setEmptyModal] = useState(false)

  const getNewYearEventHistory = async () => {
    const result = await backendApis.getNewYearEventHistory()

    if (result?.data?.length === 0) {
      setEmptyModal(true)
    }
    let description
    const customData = result?.data?.map((item) => {
      const typeList = item.type.split('_')
      description = GAME_MSG[typeList?.[0]] || '기타'

      return { ...item, description }
    })

    setNewYearHistoryData(customData)
  }

  useEffect(() => {
    getNewYearEventHistory()
  }, [token])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1000,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.8)',
        }}
      />
      <div
        style={{
          width: '80%',
          height: '110vw',
          position: 'absolute',
          zIndex: 1001,
          top: '55vw',
          left: '10%',
          background: 'white',
          borderRadius: 20,
        }}
      >
        <div
          style={{
            fontFamily: 'maplestory',
            color: '#402C24',
            fontSize: 22,
            textAlign: 'center',
            marginTop: '5vw',
            marginBottom: '1vw',
          }}
        >
          송편 기록
        </div>
        <div
          style={{
            backgroundColor: '#f2f2f2',
            width: '100%',
            height: '0.5vw',
            marginTop: 12,
          }}
        />
        <div
          style={{
            position: 'relative',
            overflowY: 'scroll',
            height: '93vw',
          }}
        >
          <div>
            {emptyModal && (
              <>
                <div
                  style={{
                    color: 'black',
                    fontFamily: 'maplestory',
                    fontSize: '4.3vw',
                    marginTop: '20vw',
                    textAlign: 'center',
                    lineHeight: '6vw',
                  }}
                >
                  기록이 없어요
                  <br />
                  미션을 완료하고 송편을 받아보세요!
                </div>
              </>
            )}
            {newYearHistoryData?.length > 0 &&
              newYearHistoryData?.map((item) => {
                return (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 8,
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 18,
                        }}
                      >
                        <div
                          style={{
                            color: '#5F2D0C',
                            zIndex: 1999,
                            fontFamily: 'maplestory',
                            fontSize: 16,
                            marginTop: 8,
                            marginRight: 16,
                          }}
                        >
                          {item?.description}
                        </div>
                        <div
                          style={{
                            color: '#855C36',
                            fontFamily: 'maplestory',
                            fontSize: 12,
                            marginTop: 10,
                          }}
                        >
                          {moment(item?.loggedAt).format(`MM월 DD일 HH:mm`)}
                        </div>
                      </div>

                      <div
                        style={{
                          color: '#EE7B63',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          marginRight: 18,
                          display: 'flex',
                          flex: 1,
                          fontSize: 20,
                          fontFamily: 'maplestory',
                          marginTop: 8,
                        }}
                      >
                        {commaNumber(item?.amount)}개
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <img
                            style={{
                              width: '7vw',
                              marginLeft: '1vw',
                            }}
                            alt=''
                            src='/dealEvent/chuseokDeal24/coin.png'
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#EDEDED',
                        fontSize: 18,
                        marginTop: 16,
                      }}
                    />
                  </>
                )
              })}
          </div>
        </div>
      </div>

      <button
        style={{
          width: '8%',
          top: '40vw',
          right: '10vw',
          zIndex: 1001,
          position: 'absolute',
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img src='/icon/modalCloseButton.png' alt='' />
      </button>
    </>
  )
})

export default DealEventHistoryModal
