import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import UserStore from '../../store/UserStore'
import WhiteTextTimer from 'comps/WhiteTextTimer'

const GganbuDeleteModal = observer(({ gganbuData, token, setGganbuData }) => {
  const [showDeleteGganbuConfirmModal, setShowDeleteGganbuConfirmModal] =
    useState(false)
  const [showDeleteGganbuLimitModal, setShowDeleteGganbuLimitModal] =
    useState(false)
  const [lastDeleteTime, setLastDeleteTime] = useState(null)
  const [failReason, setFailReason] = useState(
    '친구 삭제는 12시간에 한번만 가능해요',
  )
  const [deleteUserId, setDeleteUserId] = useState(false)

  return (
    <>
      {!showDeleteGganbuConfirmModal && !showDeleteGganbuLimitModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 2000,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <button
            style={{
              width: '8vw',
              height: 'auto',
              right: '10%',
              top: '12%',
              position: 'absolute',
              zIndex: 1002,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img src='/icon/modalCloseButton.png' alt='' />
          </button>
          <div
            style={{
              backgroundColor: 'white',
              width: '80%',
              height: 'auto',
              left: '10%',
              top: '20%',
              paddingBottom: '4vw',
              borderRadius: '4vw',
              position: 'absolute',
              overflowY: 'scroll',
              minHeight: '40%',
              maxHeight: '60%',
            }}
          >
            <div
              style={{
                fontFamily: 'maplestory',
                color: '#402C24',
                fontSize: '4.5vw',
                textAlign: 'center',
                marginTop: '5vw',
                marginBottom: '1vw',
              }}
            >
              삭제할 친구를 선택하세요
            </div>

            <div
              style={{
                fontFamily: 'maplestory',
                color: '#402C24',
                fontSize: '3.5vw',
                textAlign: 'center',
                marginTop: '1vw',
                marginBottom: '5vw',
              }}
            >
              친구 삭제는 12시간에 한번만 가능해요 <br />
              같은 친구 삭제는 3일에 한번만 가능해요
            </div>

            {gganbuData?.gganbuList?.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      backgroundColor: '#f2f2f2',
                      width: '100%',
                      height: '0.5vw',
                      marginTop: '4vw',
                      marginBottom: '4vw',
                    }}
                  />
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: '4vw',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        style={{
                          width: '10vw',
                          height: '10vw',
                          borderRadius: '100vw',
                          marginRight: '2vw',
                        }}
                        alt=''
                        src={item?.userImage}
                        onError={(e) => {
                          e.target.src = UserStore?.default_profile_image
                        }}
                      />
                      <div
                        style={{
                          color: '#402C24',
                          fontFamily: 'maplestory',
                          fontSize: '4vw',
                        }}
                      >
                        {item?.userName?.length > 5 ? (
                          <>{item?.userName.slice(0, 5)}..</>
                        ) : (
                          <>{item?.userName.slice(0, 5)}</>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        background:
                          'linear-gradient(180deg, #F2A173 0%, #E43131 100%)',
                        borderRadius: '8vw',
                        width: '20vw',
                        fontFamily: 'maplestory',
                        alignContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        marginRight: '4vw',
                      }}
                    >
                      <button
                        style={{
                          fontSize: '4vw',
                          fontFamily: 'maplestory',
                          textAlign: 'center',
                          flex: 1,
                        }}
                        onClick={() => {
                          setShowDeleteGganbuConfirmModal(true)
                          setDeleteUserId(item?.userId)
                        }}
                      >
                        삭제
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}

      {showDeleteGganbuConfirmModal && !showDeleteGganbuLimitModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 3000,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              width: '80%',
              borderRadius: 15,
              position: 'absolute',
              left: '10%',
              top: '35%',
            }}
          >
            <div
              style={{
                fontFamily: 'maplestory',
                color: '#402C24',
                fontSize: '4vw',
                textAlign: 'center',
                marginTop: '5vw',
                marginBottom: '5vw',
                lineHeight: '150%',
              }}
            >
              맞팜을 삭제하시겠어요?
              <br></br>
              삭제는 12시간에 한번만 가능해요
              <br />
              같은 친구 삭제는 3일에 한번만 가능해요
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                marginBottom: '5vw',
                fontSize: '4vw',
              }}
            >
              <button
                style={{
                  fontFamily: 'maplestory',
                  color: 'black',
                  fontSize: '4vw',
                  textAlign: 'center',
                  backgroundColor: '#E5E8ED',
                  width: '40%',
                  padding: '4vw',
                  borderRadius: '8vw',
                  marginLeft: '5vw',
                }}
                onClick={() => {
                  setDeleteUserId('')
                  setShowDeleteGganbuConfirmModal(false)
                }}
              >
                취소
              </button>
              <button
                style={{
                  fontFamily: 'maplestory',
                  color: 'white',
                  fontSize: '4vw',
                  textAlign: 'center',
                  background:
                    'linear-gradient(180deg, #F2A173 0%, #E43131 100%)',
                  width: '40%',
                  padding: '4vw',
                  borderRadius: '8vw',
                  marginLeft: '5vw',
                }}
                onClick={async () => {
                  const result = await backendApis.deleteGganbuFromList(
                    token,
                    'PUT',
                    {
                      gganbuAlfarmId: deleteUserId,
                    },
                  )

                  if (!result?.success) {
                    if (result?.type === 'duplicate') {
                      //
                      setFailReason('같은 친구 삭제는 3일에 한번만 가능해요')
                    } else {
                      setFailReason('친구 삭제는 12시간에 한번만 가능해요')
                    }
                    setLastDeleteTime(result?.result?.deletedAt)
                    setShowDeleteGganbuConfirmModal(false)
                    setShowDeleteGganbuLimitModal(true)
                  }

                  if (result?.success) {
                    ModalStore?.setIsModalOpen('basic')

                    const result2 = await backendApis.getGganbuList(token)

                    if (result2?.data) {
                      setGganbuData(result2?.data)
                      window.localStorage.setItem(
                        'gganbuData',
                        JSON.stringify(result2?.data),
                      )
                    }
                  }
                }}
              >
                삭제
              </button>
            </div>
          </div>
        </div>
      )}

      {!showDeleteGganbuConfirmModal && showDeleteGganbuLimitModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1005,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              width: '80%',
              borderRadius: '4vw',
              position: 'absolute',
              left: '10%',
              top: '35%',
            }}
          >
            <div
              style={{
                fontFamily: 'maplestory',
                color: '#402C24',
                fontSize: '4vw',
                textAlign: 'center',
                marginTop: '5vw',
                marginBottom: '5vw',
              }}
            >
              {failReason}
              <br />
              <br />
              {lastDeleteTime && (
                <WhiteTextTimer
                  onTimeEnd={() => setShowDeleteGganbuLimitModal(false)}
                  timeStamp={new Date(lastDeleteTime)}
                  timerMinutes={
                    failReason === '같은 친구 삭제는 3일에 한번만 가능해요'
                      ? 60 * 24 * 3
                      : 60 * 12
                  }
                  interval={1000}
                  color='black'
                />
              )}{' '}
              뒤에 삭제 가능해요.
            </div>
            <div
              style={{
                marginBottom: '5vw',
              }}
            >
              <button
                style={{
                  fontFamily: 'maplestory',
                  color: 'black',
                  fontSize: '4vw',
                  textAlign: 'center',
                  backgroundColor: '#E5E8ED',
                  width: '40%',
                  padding: '4vw',
                  borderRadius: '8vw',
                  marginLeft: '22.5vw',
                }}
                onClick={() => {
                  setShowDeleteGganbuLimitModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
})
export default GganbuDeleteModal
