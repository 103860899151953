import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import backendApis from '../../../utils/backendApis'

const CherryPickerRouletteModal = observer(
  ({ token, farmData, loadUserCoupon }) => {
    const [inviteButtonPressed, setInviteButtonPressed] = useState(false)
    const [isRolling, setIsRolling] = useState(false)
    const [rewardModal, setRewardModal] = useState(false)
    const [isRolled, setIsRolled] = useState(false)
    const [CTAText, setCTAText] = useState('럭키 룰렛 돌리기')
    const [canClickStartButton, setCanClickStartButton] = useState(true)
    const DEGREE_MAP = [
      5400, // 고급비료 8개
    ]

    const startRoulette = async () => {
      setCTAText(`추첨중...`)
      setCanClickStartButton(false)
      //backend호출
      const result = await backendApis.setCherryPickerCoupon(token)
      setIsRolling(true)
      setIsRolled(true)
      if (result?.data?.success) {
        loadUserCoupon()
        setTimeout(() => {
          setRewardModal(true)
          setIsRolling(false)
          setCTAText(`대박! 쿠폰 당첨`)
        }, 7100)
        let tmpFarmData = farmData
        tmpFarmData.cherryPickerCouponUntil =
          new Date().getTime() + 1000 * 60 * 60 * 24
      }
    }

    return (
      <>
        <div style={styles.background}>
          <div style={styles.modalDiv}>
            <button
              style={styles.closeButton}
              onClick={() => {
                if (!isRolling) {
                  ModalStore.setIsModalOpen('basic')
                }
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>

            <div
              style={{
                color: 'white',
                fontSize: '6vw',
                fontFamily: 'maplestory',
                textAlign: 'center',
                width: '100vw',
                lineHeight: '150%',
                marginTop: '-13vw',
                marginBottom: '20vw',
              }}
            >
              럭키 룰렛 당첨을 축하드려요!
              <br />
              룰렛을 돌리고 선물을 받아보세요
            </div>

            <div
              style={{
                width: '90vw',
                marginTop: '-10vw',
                transform:
                  isRolling || isRolled
                    ? `translate(0%, 0%) rotate(${
                        DEGREE_MAP[
                          farmData?.fertilizerRouletteData?.rewardNum ?? 0
                        ]
                      }deg`
                    : `translate(0%, 0%)`,
                transition: isRolling
                  ? 'all 7s cubic-bezier(1, 0.12, 0, 1) 0s'
                  : '',
              }}
            >
              <img src='/cherryPickerRoulette/roulette.png' alt='' />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '37.5vw',
                width: '10vw',
                left: '49.8%',
                transform: 'translateX(-50%)',
              }}
            >
              <img src='/fertilizerRoulette/pointer.png' alt='' />
            </div>
            <div
              style={{
                color: '#402C24',
                fontSize: '6vw',
                fontFamily: 'maplestory',
                textAlign: 'center',
                background: inviteButtonPressed
                  ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                  : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                boxShadow: inviteButtonPressed
                  ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                  : '',
                padding: '5vw',
                borderRadius: '50vw',
                width: '80vw',
                marginTop: '12vw',
              }}
              onPointerDown={() => {
                if (isRolled) return
                if (!isRolling) {
                  setInviteButtonPressed(true)
                }
              }}
              onPointerCancel={() => {
                setInviteButtonPressed(false)
              }}
              onPointerUp={() => {
                setInviteButtonPressed(false)
                if (!isRolled && canClickStartButton) {
                  startRoulette()
                }
              }}
            >
              {CTAText}
            </div>
          </div>

          {rewardModal && (
            <div
              style={{
                flex: 1,
                position: 'fixed',
                left: 0,
                top: 0,
                zIndex: 3000,
                width: '100%',
                height: '100vh',
                backgroundColor: 'rgba(0,0,0,0.8)',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  top: '50%',
                  background: 'white',
                  width: '80%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  borderRadius: '4vw',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                {/* <div
                  style={{
                    width: "100%",
                    color: "black",
                    textAlign: "center",
                    fontSize: "5vw",
                    marginTop: 16,
                    marginBottom: 8,
                  }}
                >
                  {
                    REWARD_NAME[
                      farmData?.fertilizerRouletteData?.rewardNum ?? 0
                    ]
                  }{" "}
                  당첨!
                </div> */}
                <img
                  style={{
                    width: '60%',
                    paddingTop: '4vw',
                    borderRadius: '2vw',
                  }}
                  alt=''
                  src={`/cherryPickerRoulette/reward.png`}
                />
                <button
                  style={{
                    width: '40%',
                    background:
                      'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                    fontFamily: 'maplestory',
                    color: 'black',
                    fontSize: '5vw',
                    borderRadius: '30vw',
                    padding: '4vw',
                    marginBottom: '4vw',
                  }}
                  onClick={() => {
                    setRewardModal(false)
                    ModalStore.setIsModalOpen('basic')
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          )}
        </div>
      </>
    )
  },
)

export default CherryPickerRouletteModal

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalDiv: {
    width: '90vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10vw',
    backgroundImage: 'url(../cherryPickerRoulette/background.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '28vw',
    paddingBottom: '6vw',
  },
  closeButton: {
    width: '10vw',
    right: '4vw',
    top: '-6vw',
    position: 'absolute',
    zIndex: 999,
  },
}
