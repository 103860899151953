import React, { useEffect, useState } from 'react'

const sprite = {
  fertilizerResource: {
    images: ['/icon/fertLowIcon.png'],
  },
}

const ResourceButton = ({
  farmData,
  action = 'goBack',
  label = '',
  onClick,
  giveWaterCount,
  showCondition,
}) => {
  const [ballIcon, setBallIcon] = useState('/icon/fertIconLow.png')

  useEffect(() => {
    if (label <= 1) {
      setBallIcon('/icon/fertIconLow.png')
    }
    if (label > 1 && label < 75) {
      setBallIcon('/icon/fertIconMiddle.png')
    }

    if (label >= 75) {
      setBallIcon('/icon/fertIconHigh.png')
    }
  }, [label])

  if (!showCondition) return null

  const FertLevelIcon = () => {
    return (
      <div
        style={{
          position: 'relative',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <button onClick={onClick}>
          <img
            style={{
              width: '20vw',
            }}
            alt=''
            src={ballIcon}
          />
          <div
            style={{
              fontSize: '4vw',
              textAlign: 'center',
              width: '20vw',
              color: 'white',
              top: '5vw',
              position: 'absolute',
            }}
          >
            양분
          </div>
          <div
            style={{
              fontSize: '6vw',
              position: 'absolute',
              textAlign: 'center',
              width: '20vw',
              color: 'white',
              top: '9vw',
            }}
          >
            {label}
          </div>
        </button>
      </div>
    )
  }
  if (!(action in sprite)) return null
  if (farmData?.giveWaterCount < 10) return null

  return (
    <div
      style={{
        height: '20vw',
      }}
    >
      {FertLevelIcon()}
    </div>
  )
}

export default ResourceButton
