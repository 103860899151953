import ItemInfo from 'comps/molecules/ItemInfo';
import PostInfo from 'comps/molecules/PostInfo';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthStore from 'store/AuthStore';
import backendApis from 'utils/backendApis';
import ReactPlayer from 'react-player';
import UserStore from 'store/UserStore';

const ShortFormShopScreen = observer(() => {
  const [post, setPost] = useState({});
  const [state, setState] = useState({
    playing: true,
    played: 0, // 게이지 바를 위한 상태
    showPauseIcon: false,
    showMuteBubble: true, // 음소거 해제 말풍선 상태
    showFullDescription: false,
    muted: false,
  });
  const [progress, setProgress] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(15);
  const timerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (UserStore.isFirstEnteredShortFormShopAd === false) {
      backendApis.logEventShortFormShop(AuthStore?.token, 'PUT', {
          eventType: 'click',
          eventData: {
            eventAction: 'enter',
            eventLocation: 'alfarm',
            screen: 'fertilizerQuestBottomSheet',
          },
      })
      
      UserStore.setIsFirstEnteredShortFormShopAd(true)
    }
  }, []);

  const handleBack = () => {
    navigate(-1); // 뒤로가기
  };

  const handlePlayPause = useCallback(() => {
    setState((prevState) => {
      const newPlayingState = !prevState.playing;
      if (newPlayingState) {
        startTimer();
      } else {
        stopTimer();
      }
      return {
        ...prevState,
        playing: newPlayingState,
        showPauseIcon: true,
      };
    });
  }, []);

  const handleVolumeToggle = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      muted: !prevState.muted,
      showMuteBubble: false,
    }));
  }, []);

  const handleProgress = useCallback((progressState) => {
    setState((prevState) => ({
      ...prevState,
      played: progressState.played,
    }));
  }, []);

  const handleToggleDescription = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showFullDescription: !prevState.showFullDescription,
    }));
  }, []);


  useEffect(() => {
    if (state.showPauseIcon) {
      const timer = setTimeout(() => {
        setState((prevState) => ({ ...prevState, showPauseIcon: false }));
      }, 2000); // 2초 후에 아이콘 숨기기
      return () => clearTimeout(timer);
    }
  }, [state.showPauseIcon]);

  useEffect(() => {
    if (state.playing) {
      startTimer();
    } else {
      stopTimer();
    }
    return () => stopTimer(); // 컴포넌트 언마운트 시 타이머 클리어
  }, [state.playing]);

  const startTimer = () => {
    if (timerRef.current) return;

    timerRef.current = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          stopTimer()
          handleTimerEnd()
          return 100;
        }
        return prevProgress + (100 / 15);
      });
      setSecondsLeft((prevSeconds) => {
      if (prevSeconds <= 1) {
        stopTimer();
        handleTimerEnd();
        return 0;
      }
      return prevSeconds - 1;
    });
    }, 1000); // 1초마다 진행 상태 업데이트
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  // 15초 타이머가 끝났을 때 실행할 함수
  const handleTimerEnd = () => {
    setSecondsLeft(0)
    backendApis.giveShortFormVideoAdReward(AuthStore?.token, 'PUT', {
      itemId: post?.itemInfo?.itemId,
    })
  }

  useEffect(() => {
    const fetch = async () => {
      const result = await backendApis.getShortFormVideoAdItem(AuthStore.token);
      if (result?.status === 200) {
        setPost(result.data);
      }
    };
    fetch();
  }, []);

  return (
    <div
      className='relative w-full h-screen bg-black overflow-hidden '
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ReactPlayer
        url={post.videoUrl}
        playing={state.playing}
        controls={false}
        width='100%'
        height='100%'
        className='absolute top-0 left-0 bottom-0'
        style={{ objectFit: 'cover', filter: 'brightness(0.9) contrast(1.1)' }}
        onProgress={handleProgress}
        progressInterval={100} // Update progress every 100ms
        loop
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
        playsinline
        muted={state.muted}
      />

      <button
        type='button'
        onClick={handlePlayPause}
        className='absolute w-full h-screen text-black p-2 rounded z-10'
      >
        {' '}
      </button>

      <div className='p-[2vw] z-30'>
        <button
          type='button'
          onClick={handleBack}
          className='absolute top-4 left-2 p-2 z-40 mt-[8vw]'
        >
          <img src='/icon/goBack.png' className='w-10 h-10' alt='back' />
        </button>
      </div>

      <div
        className={`absolute top-0 left-0 w-full h-full text-white p-4 flex flex-col justify-end transition-opacity duration-300 ${
          !state.playing || state.showFullDescription
            ? 'bg-gradient-to-t from-black via-transparent to-transparent'
            : 'bg-black bg-opacity-0'
        }`}
      >
        <div className='z-40'>
          <PostInfo
            post={post}
            showFullDescription={state.showFullDescription}
            onToggleDescription={handleToggleDescription}
          />
          <ItemInfo
            itemInfo={post?.itemInfo}
            isMuted={state.muted}
            isPlaying={state.playing}
            handlePlayPause={handlePlayPause}
            handleVolumeToggle={handleVolumeToggle}
          />
        </div>
      </div>
      <div className='absolute top-0 left-0 w-full'>
        <ProgressBar progress={progress} secondsLeft={secondsLeft} />
      </div>
    </div>
  );
});

export default ShortFormShopScreen;

const ProgressBar = ({ progress, secondsLeft }) => {
  return (
    <div className='relative top-[10vw] w-full h-[6vw] bg-gray-500'>
      
      <div
        className='absolute top-0 left-0 h-full bg-white transition-width duration-150'
        style={{ width: `${progress}%` }}
      >
      </div>
      <div className='absolute top-0 left-0 h-full flex items-center justify-center w-full text-black font-bold'>
        {secondsLeft !== 0 ? `${secondsLeft}초 보면 일반 비료 1개를 받아요` : '축하합니다! 일반 비료 1개를 받았어요'}
      </div>
    </div>
  )
}
