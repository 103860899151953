import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import commaNumber from 'comma-number'
import indexStore from '../../../store/indexStore'
import Layout from '../layout'
import LoadingIndicator from '../../loadingIndicator'
import ToastStore from 'store/ToastStore'

const SurpriseMissionModal = observer(
  ({ token, farmData, giveWaterBasket, giveSurpriseMission }) => {
    const { ModalStore, UserStore } = indexStore()
    const [inviteButtonPressed, setInviteButtonPressed] = useState(false)
    const throttleRef = useRef({})

    const shareKakao = () => {
      const inviteData = {
        indexNumber: farmData?.surpriseMissionCandidate.userId,
        shareType: 'AlfarmSurpriseMission',
        title: '[올팜] 작물이 농부님을 기다리고 있어요~',
        description: '오늘도 귀여운 작물을 키워보세요! 🌱',
        bannerImage:
          'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/7ab3f811-7cfb-4108-a3b9-d9b3bfc13867.png',
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
    }

    return (
      <>
        <div style={styles.background}>
          <div style={styles.modalDiv}>
            <button
              style={styles.closeButton}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <div
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                {`미션: 작물에 물 주기`}
              </div>
              <div
                style={{
                  width: '25vw',
                  height: '25vw',
                  backgroundImage: 'url(../surpriseMission/reward.png)',
                  display: 'inline-block',
                }}
              />

              <div
                style={{
                  color: 'black',
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                {farmData?.surpriseMissionInfo ? (
                  '물을 주면 친구와 내가 미션 보상을 받아요'
                ) : (
                  <>
                    <img
                      alt='profile'
                      style={{
                        width: '5vw',
                        height: '5vw',
                        borderRadius: '55vw',
                        marginTop: '0.5vw',
                        marginRight: '1vw',
                      }}
                      src={farmData?.surpriseMissionCandidate?.userImageUri}
                      onError={(e) => {
                        e.target.src = UserStore?.default_profile_image
                      }}
                    />

                    <div
                      style={{
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      {`${farmData?.surpriseMissionCandidate?.userName}님이 미션에 성공하면`}
                      <br />
                      {`서로 보상을 받아요`}
                    </div>
                  </>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    color: '#402C24',
                    fontSize: '5vw',
                    fontFamily: 'maplestory',
                    textAlign: 'center',
                    background: inviteButtonPressed
                      ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                      : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                    boxShadow: inviteButtonPressed
                      ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                      : '',
                    //   width: "80%",
                    padding: '5vw',
                    borderRadius: '50vw',
                    width: '100%',
                  }}
                  onPointerDown={() => {
                    setInviteButtonPressed(true)
                  }}
                  onPointerCancel={() => {
                    setInviteButtonPressed(false)
                  }}
                  onPointerUp={() => {
                    setInviteButtonPressed(false)
                  }}
                  onClick={() => {
                    if (farmData?.surpriseMissionInfo) {
                      ModalStore.setIsModalOpen('basic')
                      giveWaterBasket()
                    } else {
                      shareKakao()
                    }
                  }}
                >
                  {farmData?.surpriseMissionInfo
                    ? '물 주기'
                    : `${farmData?.surpriseMissionCandidate?.userName}님 초대하기`}
                </div>
                <div
                  style={{
                    color: 'black',
                    marginTop: 4,
                    fontSize: '3vw',
                  }}
                >
                  * 깜짝 미션 초대를 통해 성공해야만 보상 지급이 돼요!
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default SurpriseMissionModal

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalDiv: {
    width: '90vw',
    height: '150vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10vw',
    backgroundImage: 'url(../surpriseMission/background.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '48vw',
    paddingBottom: '26vw',
  },
  closeButton: {
    width: '8vw',
    right: '2vw',
    top: '6vw',
    position: 'absolute',
    zIndex: 999,
  },
  rewardFlag: {
    position: 'absolute',
    color: 'black',
    left: '10vw',
    borderBottom: '2px solid black',
    width: '14vw',
    marginTop: '1vw',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',

    // borderRadius: 16,
  },
  rewardFlagImg: {
    width: '7vw',
    height: '7vw',
  },
}
