import { observable } from 'mobx'
import { toast } from 'react-hot-toast'

const ToastStore = observable({
  toastBlock: false,
  type: 'error',
  duration: 2500,
  message: '다시 시도해주세요',
  timeOutRef: null,

  toastOn({ type, message, duration, emoji }) {
    clearTimeout(this.timeOutRef)
    if (!this.toastBlock) {
      if (type === 'error') {
        toast.error(message, duration)
      } else if (type === 'success') {
        toast.success(message, duration)
      } else if (type === 'emoji') {
        toast(message, { icon: emoji }, duration)
      }
    }
    this.toastBlock = true
    this.timeOutRef = setTimeout(() => {
      this.toastBlock = false
    }, duration)
  },
})

export default ToastStore
