import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import commaNumber from 'comma-number'
const LuckyPiggyOpenGiftModal = observer(
  ({
    setShowOpenGiftModal,
    level,
    setOpenSendMoneyModal,
    token,
    setMoneyAmount,
    ongoingData,
    openSendMoneyModal,
  }) => {
    const [isOpenGiftBoxButtonPressed, setIsOpenGiftBoxButtonPressed] =
      useState(false)

    const [clickedButton, setClickedButton] = useState(false)

    const openLuckyPiggyBox = async () => {
      const result = await backendApis.openLuckyPiggyBox(token)

      if (result?.status === 200) {
        setMoneyAmount(result?.data?.gift)
      }
    }

    useEffect(() => {
      if (clickedButton) {
        setTimeout(() => {
          setClickedButton(false)
          setOpenSendMoneyModal(true)
          setShowOpenGiftModal(false)
        }, 4000)
      }
    }, [clickedButton])

    return (
      <>
        {level === 1 && (
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 900,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }}
          >
            <div
              style={{
                width: '90vw',
                height: '150vw',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                position: 'absolute',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                padding: 16,
                backgroundImage: 'url(../luckyPiggy/modalBg2.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                paddingTop: '40vw',
              }}
            >
              <img
                style={{
                  width: '40vw',
                  paddingTop: '12vw',
                }}
                src='/luckyPiggy/warningIcon.png'
                alt=''
              />
              <div
                style={{
                  paddingTop: '0vw',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  fontSize: '4.5vw',
                  lineHeight: '180%',
                  textAlign: 'center',
                }}
              >
                용돈은 레벨2 이상부터 받을 수 있어요 <br />
                코인을 주고 복돼지를 키워보세요
              </div>
            </div>
            <button
              style={{
                width: '10%',
                top: '17%',
                right: '12%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                setShowOpenGiftModal(false)
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
          </div>
        )}
        {level >= 2 && !clickedButton && !ongoingData?.moneyAmount && (
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 900,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }}
          >
            <div
              style={{
                width: '90vw',
                height: '150vw',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                position: 'absolute',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                padding: 16,
                backgroundImage: 'url(../luckyPiggy/modalBg.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                paddingTop: '40vw',
              }}
            >
              <img
                style={{
                  width: '40vw',
                  paddingTop: '12vw',
                }}
                // className="wobble-hor-bottom"
                src='/luckyPiggy/giftBoxIcon.png'
                alt=''
              />
              <div
                style={{
                  paddingTop: '0vw',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  fontSize: '4.5vw',
                }}
              >
                상자를 열면 복돼지 키우기가 종료돼요
              </div>
              <button
                style={{
                  position: 'absolute',
                  background: isOpenGiftBoxButtonPressed
                    ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                    : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                  boxShadow: isOpenGiftBoxButtonPressed
                    ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                    : '',
                  color: '#402C24',
                  fontSize: '5vw',
                  borderRadius: '50vw',
                  paddingTop: '3vw',
                  paddingBottom: '3vw',
                  bottom: '37vw',
                  zIndex: 1,
                  fontFamily: 'maplestory',
                  width: '50vw',
                }}
                onPointerDown={() => {
                  setIsOpenGiftBoxButtonPressed(true)
                }}
                onPointerCancel={() => {
                  setIsOpenGiftBoxButtonPressed(false)
                }}
                onPointerUp={() => {
                  setIsOpenGiftBoxButtonPressed(false)
                }}
                onClick={() => {
                  setClickedButton(true)
                  openLuckyPiggyBox()
                }}
              >
                용돈 받기
              </button>
            </div>
            <button
              style={{
                width: '10%',
                top: '17%',
                right: '12%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                setShowOpenGiftModal(false)
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
          </div>
        )}

        {clickedButton && (
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 900,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }}
          >
            <div
              style={{
                width: '90vw',
                height: '150vw',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                position: 'absolute',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                padding: 16,
                backgroundImage: 'url(../luckyPiggy/modalBg3.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                paddingTop: '40vw',
              }}
            >
              <img
                style={{
                  width: '50vw',
                  paddingTop: '12vw',
                }}
                className='wobble-hor-bottom'
                src='/luckyPiggy/giftBoxIcon.png'
                alt=''
              />
            </div>
          </div>
        )}
      </>
    )
  },
)

export default LuckyPiggyOpenGiftModal
