import React, { useEffect } from 'react'

const PuppyGiftModal = ({
  puppyRewardType,
  setPuppyGiftModal,
  addWater,
  addFertilizer,
  addFertilizerSet,
}) => {
  const RewardModal = () => {
    if (puppyRewardType === 'water') {
      return (
        <div
          style={{
            position: 'absolute',
            width: '50vw',
            left: '25vw',
            zIndex: 11002,
            top: '65vw',
            textAlign: 'center',
          }}
          onClick={() => {
            setPuppyGiftModal(false)
          }}
        >
          <img src='/icon/puppyRewardModal.png' alt='' />

          <div
            style={{
              fontFamily: 'maplestory',
              color: 'black',
              position: 'absolute',
              top: '30vw',
              left: '15vw',
              fontSize: '5vw',
            }}
          >
            물어왔어요
          </div>
          <div
            style={{
              position: 'absolute',
              width: '15vw',
              left: '17.5vw',
              zIndex: 11002,
              top: '38vw',
              textAlign: 'center',
            }}
          >
            <img src='/icon/water.png' alt='' />
          </div>
          <div
            style={{
              fontFamily: 'maplestory',
              color: 'black',
              position: 'absolute',
              bottom: '7vw',
              left: '21vw',
              fontSize: '5vw',
            }}
          >
            {addWater}g
          </div>
        </div>
      )
    } else if (puppyRewardType === 'fertilizer') {
      return (
        <div
          style={{
            position: 'absolute',
            width: '50vw',
            left: '25vw',
            zIndex: 11002,
            top: '65vw',
            textAlign: 'center',
          }}
          onClick={() => {
            setPuppyGiftModal(false)
          }}
        >
          <img src='/icon/puppyRewardModal.png' alt='' />

          <div
            style={{
              fontFamily: 'maplestory',
              color: 'black',
              position: 'absolute',
              top: '30vw',
              left: '15vw',
              fontSize: '5vw',
            }}
          >
            물어왔어요
          </div>
          <div
            style={{
              position: 'absolute',
              width: '15vw',
              left: '17.5vw',
              zIndex: 11002,
              top: '38vw',
              textAlign: 'center',
            }}
          >
            <img src='/icon/fertilizer.png' alt='' />
          </div>
          <div
            style={{
              fontFamily: 'maplestory',
              color: 'black',
              position: 'absolute',
              bottom: '7vw',
              left: '21.5vw',
              fontSize: '5vw',
            }}
          >
            {addFertilizer}개
          </div>
        </div>
      )
    } else if (puppyRewardType === 'fertilizerSet') {
      return (
        <div
          style={{
            position: 'absolute',
            width: '50vw',
            left: '25vw',
            zIndex: 11002,
            top: '65vw',
            textAlign: 'center',
          }}
          onClick={() => {
            setPuppyGiftModal(false)
          }}
        >
          <img src='/icon/puppyRewardModal.png' alt='' />

          <div
            style={{
              fontFamily: 'maplestory',
              color: 'black',
              position: 'absolute',
              top: '30vw',
              left: '15vw',
              fontSize: '5vw',
            }}
          >
            물어왔어요
          </div>
          <div
            style={{
              position: 'absolute',
              width: '15vw',
              left: '17.5vw',
              zIndex: 11002,
              top: '38vw',
              textAlign: 'center',
            }}
          >
            <img src='/icon/fertilizerSet.png' alt='' />
          </div>
          <div
            style={{
              fontFamily: 'maplestory',
              color: 'black',
              position: 'absolute',
              bottom: '7vw',
              left: '21.5vw',
              fontSize: '5vw',
            }}
          >
            {addFertilizerSet}개
          </div>
        </div>
      )
    } else if (puppyRewardType === 'coupon') {
      return (
        <div
          style={{
            position: 'absolute',
            width: '50vw',
            left: '25vw',
            zIndex: 11002,
            top: '65vw',
            textAlign: 'center',
          }}
          onClick={() => {
            setPuppyGiftModal(false)
          }}
        >
          <img src='/icon/puppyRewardModal.png' alt='' />

          <div
            style={{
              fontFamily: 'maplestory',
              color: 'black',
              position: 'absolute',
              top: '30vw',
              left: '15vw',
              fontSize: '5vw',
            }}
          >
            물어왔어요
          </div>
          <div
            style={{
              position: 'absolute',
              width: '15vw',
              left: '17.5vw',
              zIndex: 11002,
              top: '38vw',
              textAlign: 'center',
            }}
          >
            <img src='/icon/coupon.png' alt='' />
          </div>
          <div
            style={{
              fontFamily: 'maplestory',
              color: 'black',
              position: 'absolute',
              bottom: '7vw',
              left: '18.5vw',
              fontSize: '5vw',
            }}
          >
            500원
          </div>
        </div>
      )
    } else {
      return (
        <div
          style={{
            position: 'absolute',
            width: '80%',
            left: '10%',
            zIndex: 11002,
            top: '65vw',
          }}
        >
          <img src='/icon/puppyRewardModal.png' alt='' />
        </div>
      )
    }
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1100,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
        onClick={() => {
          setPuppyGiftModal(false)
        }}
      />
      {RewardModal()}
      {/* <button
        style={{
          width: "30%",
          height: 55,
          top: "73%",
          left: "50%",
          transform: "translate(-50%, -80%)",
          position: "absolute",
          zIndex: 1999,
          borderRadius: 30,
          background:
            "linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)",
          fontFamily: "maplestory",
          color: "#402C24",
          fontSize: 18,
        }}
        onClick={() => {
          setPuppyGiftModal(false);
        }}
      >
        확인
      </button> */}
    </>
  )
}

export default PuppyGiftModal
