import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useState } from 'react'
import InitModalStore from 'store/InitModalStore'
import backendApis from 'utils/backendApis'

const InitModal = observer(
  ({ farmData, setFarmData, setShowPopup, setResourceType, setAddWater }) => {
    const [isClicked, setIsClicked] = useState(false)
    const initModalList = InitModalStore?.isInitModal
    const currentModal = initModalList?.[initModalList?.length - 1]

    if (initModalList?.length <= 0) return

    const timeFormat = moment(new Date()).format('HH')
    const cheerupGganbuTime = timeFormat >= '07' && timeFormat < '22'

    if (currentModal?.type === 'cheerupGganbu' && !cheerupGganbuTime) return

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            right: '0',
            zIndex: 30000,
          }}
        >
          <div
            style={{
              backgroundColor: 'black',
              position: 'absolute',
              overlay: {
                background: 'rgba(0,0,0,0)',
              },
              width: '100%',
              height: '100%',
              opacity: '0.8',
            }}
          />
          <div
            style={{
              background:
                'linear-gradient(180deg, #FFF0BB 0%, #FFE176 3.65%, #FFBC39 94.79%, #F99219 100%)',
              width: '70vw',
              zIndex: 110,
              borderRadius: '4vw',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              padding: '4vw',
            }}
          >
            <div
              style={{
                color: '#402C24',
                fontFamily: 'maplestory',
                fontSize: '4.5vw',
                zIndex: 30001,
                textAlign: 'center',
                lineHeight: '150%',
                background: 'white',
                borderRadius: '2vw',
                padding: '4vw',
                flexDirection: 'column',
                display: 'flex',
                wordBreak: 'keep-all',
                wordWrap: 'break-word',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {currentModal?.title ?? '오류가 발생했어요'}

              {(currentModal?.type === 'potatoPetGift' ||
                currentModal?.type === 'rewardedAdfertilizerSet') && (
                <img
                  style={{
                    width: '20vw',
                    marginTop: '4vw',
                  }}
                  alt='pet gift'
                  src='/icon/fertilizerSet.png'
                />
              )}

              {(currentModal?.type === 'kindergardenPetGift' ||
                currentModal?.type === 'rewardedAdWater' ||
                currentModal?.type === 'eventPetGift') && (
                <img
                  style={{
                    width: '20vw',
                    marginTop: '4vw',
                  }}
                  alt='pet gift'
                  src='/icon/water.png'
                />
              )}

              <br />
              {currentModal?.description ?? '재접속을 시도해주세요'}
              <button
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: '50%',
                  height: '14vw',
                  marginTop: '8%',
                  zIndex: 999,
                  borderRadius: '444vw',
                  background:
                    'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                  fontFamily: 'maplestory',
                  color: '#402C24',
                  fontSize: '4vw',

                  marginBottom: '0vw',
                }}
                onClick={async () => {
                  if (currentModal?.type === 'cheerupGganbu') {
                    if (isClicked) return
                    setIsClicked(true)
                    const result = await backendApis.fightingGganbu()
                    if (result?.data?.success) {
                      let tmpFarmData = farmData
                      tmpFarmData.water += result?.data?.water
                      setAddWater(result?.data?.water)
                      setResourceType('water')
                      setShowPopup(true)
                      setFarmData(tmpFarmData)
                    }
                  }

                  InitModalStore?.setIsInitModal(
                    [...InitModalStore?.isInitModal].slice(0, -1),
                  )
                  await backendApis.updateSpecificField({
                    fieldName: 'initModalList',
                    value: [],
                  })
                }}
              >
                <div
                  style={{
                    color: '#402C24',
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                  }}
                >
                  확인
                </div>
              </button>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default InitModal
