import React, { useState } from 'react'
import GiveWaterFive from './giveWaterFive'

const sprite = {
  giveWater: {
    images: ['/icon/giveWater.png'],
  },
  giveWaterFive: {
    images: ['/icon/giveWaterFive.png'],
  },
  makeRain: {
    images: ['/icon/rainFromWater.png'],
  },
  giveFert: {
    images: ['/icon/giveFert.png'],
  },
  givingWater: {
    images: ['/icon/givingWater.png'],
  },
}

const BasicButtonGganbu = ({
  type,
  action = 'giveWater',
  label = '',
  onClick,
  disabled,
  sound,
}) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  return (
    <>
      <button
        disabled={disabled}
        onClick={onClick}
        // className=""

        className={`${isButtonPressed && 'clickEffect'}
        bt-farm-basic-gganbu
        `}
        onPointerDown={() => {
          setIsButtonPressed(true)
        }}
        onPointerCancel={() => {
          setIsButtonPressed(false)
        }}
        onPointerUp={() => {
          setIsButtonPressed(false)
        }}
        style={{
          opacity: isButtonPressed ? 0.6 : 1.0,
        }}
      >
        <div
          style={{
            bottom: '2vw',
            textAlign: 'center',
            left: '0%',
            position: 'absolute',
            color: '#442b22',
            fontWeight: 'bold',
            width: '100%',
            fontSize: '4vw',
          }}
        >
          {label}
        </div>
        <div
          style={{
            zIndex: -1,
          }}
        >
          <img src={sprite[action].images} alt='' />
        </div>

        {sound}
      </button>
      <button>
        {action === 'giveWaterFive' ? (
          <div
            className='give-water-five-gganbu'
            style={{
              width: '100%',
            }}
          ></div>
        ) : (
          <></>
        )}
      </button>
    </>
  )
}

export default BasicButtonGganbu
