import useCheckCodePushVersion from '../../utils/customHooks/useConvertCodePushVersion'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import GganbuInviteModal from './gganbuInviteModal'
import ModalStore from '../../store/ModalStore'
import TimeStore from '../../store/TimeStore'
import UserStore from 'store/UserStore'

const IconButton = ({
  codePushVerStandard = '',
  onClick = () => {},
  src = '',
}) => {
  return (
    <button
      style={{
        marginRight: '2vw',
        paddingTop: '0.5vw',
      }}
      onClick={onClick}
    >
      <img
        style={{
          width: '17.55vw',
          height: '16.2vw',
        }}
        alt=''
        src={src}
      />
    </button>
  )
}

const EachSellerFarm = ({
  item,
  props,
  sellerStoreId,
  gganbuId,
  canClickGiveWater,
  sellerStoreIdList,
}) => {
  const {
    setLoadingToGganbuIndicator,
    token,
    itemType,
    randomString,
    scrollPositionX,
    farmData,
  } = props

  if (!item) return null // item은 sellerFarmList의 각 광고 정보 요소
  if (farmData?.gganbuList?.length === 0) return null

  const current =
    sellerStoreId && item?._id?.toString() === sellerStoreId?.toString()
  const onClick = () => {
    if (current) return
    if (!canClickGiveWater) return
    window.location.href = `/sellerFarm?token=${token}&itemType=${itemType}&sellerStoreId=${item?._id}&sellerStoreIdList=${sellerStoreIdList}&gganbuId=${item?.userId}&gganbuName=${item?.userName}&randomNumber=${randomString}&prevScrollPosition=${scrollPositionX}`
  }

  return (
    <button
      style={{
        marginLeft: 8,
        textAlign: 'center',
      }}
      onClick={() => {
        setLoadingToGganbuIndicator(true)
        onClick()
        setTimeout(() => {
          setLoadingToGganbuIndicator(false)
        }, 3000)
      }}
    >
      <div
        style={{
          position: 'relative',
        }}
      >
        <img
          style={{
            width: '13vw',
            height: '13vw',
            borderRadius: 55,
            marginRight: 8,
            marginTop: '0.5vw',
            border: current ? '3px solid #F6C64C' : null,
          }}
          src={
            item?.sellerImageUri ||
            'https://assets.ilevit.com/6de7dd85-ac41-430d-b160-ce3dd0e104b6.png'
          }
          alt=''
        />

        {farmData?.waterFromSellerFarm &&
        TimeStore.currentDayJS -
          dayjs(
            farmData?.waterFromSellerFarm[
              farmData?.waterFromSellerFarm?.length - 1
            ]?.rewardedAt,
          ) <
          14400000 ? (
          <div
            style={{
              position: 'absolute',
              bottom: '-0.5vw',
              right: '-1vw',
            }}
          >
            <img
              style={{
                width: 35,
                height: 35,
              }}
              src='/icon/waitGetWaterGganbu.png'
              alt=''
            />
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              bottom: '-0.7vw',
              right: '-1vw',
            }}
          >
            <img
              style={{
                width: 35,
                height: 35,
              }}
              src='/icon/canGetWaterGganbu.png'
              alt=''
            />
          </div>
        )}
      </div>

      <div
        style={{
          color: current ? '#F6C64C' : 'white',
          fontFamily: 'maplestory',
          fontSize: '3vw',
          paddingTop: '0.5vw',
          marginLeft: '-2vw',
        }}
      >
        {item?.sellerDisplayName?.length > 5 ? (
          <>{item?.sellerDisplayName.slice(0, 5)}..</>
        ) : (
          <>{item?.sellerDisplayName.slice(0, 5)}</>
        )}
      </div>
    </button>
  )
}

const SellerFarmList = (props) => {
  const { sellerFarmList } = props
  const firstSellerFarm = [sellerFarmList?.[0]]
  const gganbuId = props?.gganbuId
  const sellerStoreId = props?.sellerStoreId
  const canClickGiveWater = props?.canClickGiveWater
  const sellerStoreIdList = sellerFarmList?.map((item) => item?._id) || []

  return (
    <div className='flex-shrink-0'>
      {firstSellerFarm?.map((item) => (
        <EachSellerFarm
          key={nanoid + Math.random()}
          item={item}
          props={props}
          sellerStoreId={sellerStoreId}
          sellerStoreIdList={sellerStoreIdList}
          gganbuId={gganbuId}
          canClickGiveWater={canClickGiveWater}
          farmData={props?.farmData}
        />
      ))}
    </div>
  )
}

export default SellerFarmList
