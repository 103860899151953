import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import commaNumber from 'comma-number'
import backendApis from 'utils/backendApis'
import SecondModalStore from 'store/SecondModalStore'

import moment from 'moment'

const GAME_MSG = {
  checkin: '상점 출석',
  browsing: '상품 구경',
  purchase: '상품 구매',
  exchange: '상점 교환',
  modal: '설날 기념',
}

const AlphabetEventHistoryModal = observer(({ token }) => {
  const [newYearHistoryData, setNewYearHistoryData] = useState([])
  const [emptyModal, setEmptyModal] = useState(false)

  const getNewYearEventHistory = async () => {
    const result = await backendApis.getAlphabetHistory()

    if (result?.data?.length === 0) {
      setEmptyModal(true)
    }

    setNewYearHistoryData(result?.data)
  }

  useEffect(() => {
    getNewYearEventHistory()
  }, [token])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 3000,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.8)',
        }}
      />
      <div
        style={{
          width: '80%',
          height: '110vw',
          position: 'absolute',
          zIndex: 3001,
          top: '55vw',
          left: '10%',
          background: 'white',
          borderRadius: 20,
        }}
      >
        <div
          style={{
            fontFamily: 'maplestory',
            color: '#402C24',
            fontSize: 22,
            textAlign: 'center',
            marginTop: '5vw',
            marginBottom: '1vw',
          }}
        >
          알파벳 기록
        </div>
        <div
          style={{
            backgroundColor: '#f2f2f2',
            width: '100%',
            height: '0.5vw',
            marginTop: 12,
          }}
        />
        <div
          style={{
            position: 'relative',
            overflowY: 'scroll',
            height: '93vw',
          }}
        >
          <div>
            {emptyModal && (
              <>
                <div
                  style={{
                    color: 'black',
                    fontFamily: 'maplestory',
                    fontSize: '4.3vw',
                    marginTop: '20vw',
                    textAlign: 'center',
                    lineHeight: '6vw',
                  }}
                >
                  기록이 없어요
                  <br />
                  미션을 완료하고 알파벳을 받아보세요!
                </div>
              </>
            )}
            {newYearHistoryData?.length > 0 &&
              newYearHistoryData?.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 8,
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 18,
                        }}
                      >
                        {item?.alphabet === 'all' ? (
                          <div
                            style={{
                              color: '#5F2D0C',
                              zIndex: 1999,
                              fontFamily: 'maplestory',
                              fontSize: 16,
                              marginTop: 8,
                              marginRight: 16,
                            }}
                          >
                            보상 교환
                          </div>
                        ) : (
                          <div
                            style={{
                              color: '#5F2D0C',
                              zIndex: 1999,
                              fontFamily: 'maplestory',
                              fontSize: 16,
                              marginTop: 8,
                              marginRight: 16,
                            }}
                          >
                            {item?.alphabet}
                          </div>
                        )}

                        <div
                          style={{
                            color: '#855C36',
                            fontFamily: 'maplestory',
                            fontSize: 12,
                            marginTop: 10,
                          }}
                        >
                          {moment(item?.loggedAt).format(`MM월 DD일 HH:mm`)}
                        </div>
                      </div>

                      <div
                        style={{
                          color: '#EE7B63',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          marginRight: 18,
                          display: 'flex',
                          flex: 1,
                          fontSize: 20,
                          fontFamily: 'maplestory',
                          marginTop: 8,
                        }}
                      >
                        {item?.alphabet === 'all'
                          ? 'HAPPY NEW YEAR'
                          : '1개 획득'}
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#EDEDED',
                        fontSize: 18,
                        marginTop: 16,
                      }}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>

      <button
        style={{
          width: '8%',
          top: '40vw',
          right: '10vw',
          zIndex: 3001,
          position: 'absolute',
        }}
        onClick={() => {
          SecondModalStore.setIsModalOpen('basic')
        }}
      >
        <img src='/icon/modalCloseButton.png' alt='' />
      </button>
    </>
  )
})

export default AlphabetEventHistoryModal
