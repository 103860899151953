import { observable } from 'mobx'

const InitModalStore = observable({
  isInitModal: [],

  setIsInitModal(arr) {
    this.isInitModal = arr
  },
})

export default InitModalStore
