import React from 'react'
import { observer } from 'mobx-react-lite'

const Popup = observer(
  ({
    showPopup,
    setShowPopup,
    className,
    style,
    addWater,
    addFertilizer,
    addFertilizerSet,
    type,
  }) => {
    const Mooodal = () => {
      if (type === 'fertilizer') {
        return (
          <div
            style={{
              flex: 1,
              position: 'absolute',
              left: '0',
              top: '0',
              bottom: '0',
              right: '0',
              zIndex: 100,
            }}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 4000,
                top: '63%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                fontWeight: 'bold',
                fontSize: 22,
                textAlign: 'center',
                fontFamily: 'maplestory',
              }}
            >
              <div> 일반비료 {addFertilizer}개 획득!</div>

              <div
                style={{ fontSize: 16, fontWeight: 'lighter', marginTop: 6 }}
              >
                양분을 높게 유지하면 농작물이 더 잘 자라요
              </div>
            </div>
            <img
              src='/icon/getFertImageNew.png'
              alt=''
              style={{
                position: 'absolute',
                width: '80%',
                top: '55%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                zIndex: 3000,
              }}
            />
          </div>
        )
      }
      if (type === 'water') {
        return (
          <div
            style={{
              flex: 1,
              position: 'absolute',
              left: '0',
              top: '0',
              bottom: '0',
              right: '0',
              zIndex: 100,
            }}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 4000,
                top: '63%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                fontWeight: 'bold',
                textAlign: 'center',
                fontFamily: 'maplestory',
                fontSize: '6vw',
              }}
            >
              <div> 물 {addWater}g 획득!</div>
              <div
                style={{ fontSize: '5vw', fontWeight: 'lighter', marginTop: 6 }}
              >
                물 주러 가볼까요?
              </div>
            </div>
            <img
              src='/icon/getWaterImageNew.png'
              alt=''
              style={{
                position: 'absolute',
                width: '80%',
                top: '55%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                zIndex: 3000,
              }}
            />
          </div>
        )
      }
      if (type === 'fertilizerSet') {
        return (
          <div
            style={{
              flex: 1,
              position: 'absolute',
              left: '0',
              top: '0',
              bottom: '0',
              right: '0',
              zIndex: 100,
            }}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 4000,
                top: '63%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                fontWeight: 'bold',
                fontSize: 22,
                textAlign: 'center',
                fontFamily: 'maplestory',
              }}
            >
              <div> 고급비료 {addFertilizerSet}개 획득!</div>

              <div
                style={{ fontSize: 16, fontWeight: 'lighter', marginTop: 6 }}
              >
                양분을 높게 유지하면 농작물이 더 잘 자라요
              </div>
            </div>
            <img
              src='/icon/getFertSetImage.png'
              alt=''
              style={{
                position: 'absolute',
                width: '80%',
                top: '55%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                zIndex: 3000,
              }}
            />
          </div>
        )
      }
    }
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            right: '0',
            zIndex: 2222,
          }}
        >
          {Mooodal()}
          <button
            style={{
              top: '73%',
              left: '50%',
              transform: 'translate(-50%, -80%)',
              position: 'absolute',
              zIndex: 999,
              borderRadius: 30,
              background:
                'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              fontFamily: 'maplestory',
              color: '#402C24',
              fontSize: '5vw',
              padding: '4vw 12vw 4vw 12vw',
            }}
            onClick={() => {
              setShowPopup(false)
            }}
          >
            확인
          </button>
          <div
            style={{
              backgroundColor: 'black',
              position: 'absolute',
              overlay: {
                background: 'rgba(0,0,0,0)',
              },
              width: '100%',
              height: '100%',
              opacity: '0.8',
            }}
          />
        </div>
      </>
    )
  },
)

export default Popup
