import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import FeverRain from 'comps/organisms/FeverRain'

const FeverGameStart = observer(
  ({
    token,
    setRewardTypeForRewardToast,
    setRewardAmountForRewardToast,
    setGetResourceModal,
  }) => {
    const [timer, setTimer] = useState(3)
    const [isTimerOn, setIsTimerOn] = useState(true)
    const [gameStart, setGameStart] = useState(false)
    const [end, setEnd] = useState(false)

    useEffect(() => {
      const interval = setInterval(() => {
        if (timer === 0) {
          setIsTimerOn(false)
          setGameStart(true)
        }
        if (timer > 0 && isTimerOn) {
          setTimer(timer - 1)
        }
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }, [timer])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1005,
            width: '100%',
            height: '100vh',
            background: 'rgba(0,0,0,0.8)',
          }}
        />
        {!gameStart && (
          <div
            style={{
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '50%',
              width: '100vw',
              fontSize: '12vw',
              color: '#FFFFFF',
              textAlign: 'center',
              position: 'absolute',
              zIndex: 1100,
            }}
          >
            {timer}
          </div>
        )}

        <div>
          {gameStart && !end && (
            <FeverRain
              setRewardTypeForRewardToast={setRewardTypeForRewardToast}
              setRewardAmountForRewardToast={setRewardAmountForRewardToast}
              setGetResourceModal={setGetResourceModal}
              setEnd={setEnd}
              token={token}
            />
          )}
        </div>
      </>
    )
  },
)

const style = {
  confirm: {
    width: '80vw',
    borderRadius: '100vw',
    fontSize: '6.5vw',
    padding: '3.5vw',
    background: '#FFCA28',
    marginLeft: '10vw',
    color: '#593306',
    position: 'fixed',
    bottom: '12vw',
  },
}

export default FeverGameStart
