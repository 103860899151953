import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import dayjs from 'dayjs'
import ModalStore from '../../store/ModalStore'
import BottomSheetStore from '../../store/BottomSheetStore'
import TimeStore from 'store/TimeStore'

const NutrimentInformationSection = ({
  backgroundOn,
  marginLeft,
  timePeriod,
  time = new Date('2022-01-01'),
  didReceive,
  userTimePeriod,
}) => {
  let timePeriodText = '8:00~18:00'
  let periodKorean = '아침'
  let standard = dayjs().startOf('d')
  let now = dayjs()

  if (timePeriod === 'morning') {
    timePeriodText = '0:00~8:00'
    periodKorean = '아침'
  } else if (timePeriod === 'afternoon') {
    timePeriodText = '8:00~18:00'
    periodKorean = '점심'
    standard = standard.add(8, 'h')
  } else {
    timePeriodText = '18:00~24:00'
    periodKorean = '저녁'
    standard = standard.add(18, 'h')
  }

  let rewardAt = dayjs(time ?? '2022-01-01')
  let receiveFlag = false
  if (standard <= rewardAt) {
    receiveFlag = true
  }

  let nutrimentImage = receiveFlag
    ? 'url(../engagementNutrimentModal/receivedNutriment.png)'
    : 'url(../engagementNutrimentModal/missedNutriment.png)'

  if (timePeriod === userTimePeriod && !receiveFlag) {
    nutrimentImage = 'url(../engagementNutrimentModal/flowerNutriment.png)'
  } else if (now.isBefore(standard)) {
    nutrimentImage = 'url(../engagementNutrimentModal/flowerNutriment.png)'
  }

  return (
    <div
      className='flex-1 h-full rounded-[16px]'
      style={{
        flex: 1,
        height: '100%',
        marginLeft,
        background:
          backgroundOn &&
          !receiveFlag &&
          `linear-gradient(180deg, rgba(250,229,178,0) 0%, rgba(250,229,178,1) 70%)`,
        borderRadius: 16,
        // border: "1px solid black",
      }}
    >
      <div
        style={{
          // width: "20vw",
          // height: "20vw",
          marginTop: '2vw',
          width: '100%',
          height: '50%',
          backgroundImage: nutrimentImage,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          // border: "1px solid black",
        }}
      />
      <div
        style={{
          color: backgroundOn && !receiveFlag ? 'black' : '#BBBBBB',
          fontSize: '1rem',
          textAlign: 'center',
          marginTop: 4,
          // position: "absolute",
          marginBottom: 4,
          fontWeight: 'bold',
        }}
      >
        {periodKorean}
      </div>
      <div
        style={{
          color: backgroundOn && !receiveFlag ? 'black' : '#BBBBBB',
          fontSize: '0.8rem',
          textAlign: 'center',
          // position: "absolute",
        }}
      >
        {timePeriodText}
      </div>
    </div>
  )
}

const EngagementNutrimentModal = observer(
  ({
    setShowModal,
    farmData,
    setFarmData,
    giveWaterBasket,
    showRewardToast,
  }) => {
    const [engagementNutrimentInfo, setEngagementNutrimentInfo] = useState()
    const [receivedText, setReceivedText] =
      useState('내일 아침에 받을 수 있어요')
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      checkEngagementNutrimentType()
    }, [])

    useEffect(() => {
      setEngagementNutrimentInfo(farmData?.engagementNutrimentInfo)
    }, [farmData?.engagementNutrimentInfo])

    const checkEngagementNutrimentType = async () => {
      let result = await backendApis.checkEngagementNutrimentType()
      let temp = { ...farmData }
      temp.engagementNutrimentInfo = { ...result?.data }
      setFarmData(temp)
      if (result?.data?.timePeriod === 'morning') {
        setReceivedText('점심에 다시 받을 수 있어요')
      } else if (result?.data?.timePeriod === 'afternoon') {
        setReceivedText('저녁에 다시 받을 수 있어요')
      } else {
        setReceivedText('내일 아침에 받을 수 있어요')
      }
      setIsLoading(false)
    }

    const giveEngagementNutrimentReward = async () => {
      let result = await backendApis.giveEngagementNutrimentReward()
      if (result?.data?.success) {
        let temp = { ...farmData }
        temp.nutriment += 30
        let tempEngagement = engagementNutrimentInfo
        tempEngagement.didReceive = true
        setFarmData(temp)
        showRewardToast(30, 'nutriment')
        ModalStore.setIsModalOpen('basic')
      }
    }
    if (isLoading) {
      return
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: `${(90 * 14) / 10}vw`,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage:
                'url(../engagementNutrimentModal/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '44vw',
              // paddingBottom: "16%",
              // marginBottom: "15%",
            }}
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: 0,
                position: 'absolute',
                zIndex: 999,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                color: 'black',
                fontSize: '5vw',
                marginBottom: '2vw',
                textAlign: 'center',
              }}
            >
              하루에 3번,
              <br />
              양분 보너스를 받아보세요
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                flex: 1,
                // border: "1px solid black",
                padding: '2vw',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 4,
              }}
            >
              <NutrimentInformationSection
                backgroundOn={engagementNutrimentInfo?.timePeriod === 'morning'}
                marginLeft={0}
                time={engagementNutrimentInfo?.morningRewardAt}
                timePeriod={'morning'}
                didReceive={engagementNutrimentInfo?.didReceive}
                userTimePeriod={engagementNutrimentInfo?.timePeriod}
              />
              <NutrimentInformationSection
                backgroundOn={
                  engagementNutrimentInfo?.timePeriod === 'afternoon'
                }
                marginLeft={8}
                time={engagementNutrimentInfo?.afternoonRewardAt}
                timePeriod={'afternoon'}
                didReceive={engagementNutrimentInfo?.didReceive}
                userTimePeriod={engagementNutrimentInfo?.timePeriod}
              />
              <NutrimentInformationSection
                backgroundOn={engagementNutrimentInfo?.timePeriod === 'night'}
                marginLeft={8}
                time={engagementNutrimentInfo?.nightRewardAt}
                timePeriod={'night'}
                didReceive={engagementNutrimentInfo?.didReceive}
                userTimePeriod={engagementNutrimentInfo?.timePeriod}
              />
            </div>

            {!farmData?.resurrectBonus && (
              <div
                style={{
                  color: 'black',
                  fontSize: '4vw',
                  // marginBottom: "2vw",
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {`${dayjs(farmData?.createdAt)
                  .add(3, 'd')
                  .diff(TimeStore.currentDayJS, 'day')}일동안 받을 수 있어요`}
              </div>
            )}
            {true ? (
              <div
                style={{
                  ...styles.buttonClass,
                  background:
                    engagementNutrimentInfo?.didReceive === true
                      ? '#888'
                      : 'linear-gradient(#b8e356, #a5cc52)',
                  boxShadow:
                    engagementNutrimentInfo?.didReceive === true
                      ? ''
                      : 'inset 0px 1px 0px 0px #d9fbbe',
                }}
                onClick={() => {
                  engagementNutrimentInfo?.didReceive
                    ? ModalStore.setIsModalOpen('basic')
                    : giveEngagementNutrimentReward()
                }}
              >
                {engagementNutrimentInfo?.didReceive
                  ? receivedText
                  : '양분받기'}
              </div>
            ) : (
              <div
                style={{
                  ...styles.buttonClass,
                  background:
                    engagementNutrimentInfo?.didReceive === true
                      ? '#888'
                      : 'linear-gradient(#b8e356, #a5cc52)',
                  boxShadow:
                    engagementNutrimentInfo?.didReceive === true
                      ? ''
                      : 'inset 0px 1px 0px 0px #d9fbbe',
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('basic')
                  BottomSheetStore.setIsbottomSheetOpen('basic')
                  giveWaterBasket()
                }}
              >
                {`물 주고 양분 받기 ${
                  engagementNutrimentInfo?.giveWaterCount ?? 0
                }/35회`}
              </div>
            )}
          </div>
        </div>
      </>
    )
  },
)

export default EngagementNutrimentModal

const styles = {
  buttonClass: {
    width: '60vw',
    // height: "15vw",
    paddingTop: '4vw',
    paddingBottom: '4vw',
    borderWidth: '1px',
    color: '#fff',
    borderColor: '#83c41a',
    // fontWeight: "bold",
    borderRadius: 8,
    textShadow: 'inset 0px 1px 0px #86ae47',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 4,
    fontWeight: 'bold',
  },
}
