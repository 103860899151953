import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'
import backendApis from 'utils/backendApis'
import UserStore from 'store/UserStore'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import AuthStore from 'store/AuthStore'

const HarvestGrapeModal = observer(
  ({
    farmData,
    setEnteringRoute,
    setAlertModal,
    userCodePushVersion,
    itemType,
    setHarvestModalVisible,
    setFinishedModalVisible,
    setInviteResultStatus,
  }) => {
    const codePushVersionAvailable = useCheckCodePushVersion()

    const harvestData = async (link) => {
      let res = await backendApis.finishedFarm(AuthStore?.token, 'PUT', {})
      if (res?.status === 200) {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'harvest',
            data: {
              itemPrice: farmData?.itemPrice,
            },
          }),
        )

        window.location.href = `${link}&key=${AuthStore.key}`

        setHarvestModalVisible(false)
        setFinishedModalVisible(true)
      } else {
        setInviteResultStatus('errorAlert')
      }
    }

    const modalClose = async () => {
      // 수확 전 최신 버전 체크
      if (farmData?.itemPrice) {
        if (
          !codePushVersionAvailable(
            UserStore?.codePushVersionFromAlwayzApp,
            '4.6.36',
          )
        ) {
          setEnteringRoute('newVersionAppForHarvest')
          setAlertModal(true)
          return
        }
      }

      if (!codePushVersionAvailable(userCodePushVersion, '4.0.0')) {
        setEnteringRoute('newVersionAppForHarvest')
        setAlertModal(true)
        return
      }
      // order Data 까서 최근 order 기록으로 분기 치기 위함
      const result = await backendApis.getHarvestOrderData()

      if (result?.status !== 200) {
        // 잠시 후 다시 시도해주세요
        setEnteringRoute('serverError')
        setAlertModal(true)
        return
      }

      if (result?.data?.data >= 1) {
        // 하루 수확량을 초과했어요. 내일 다시 시도 ㄱㄱ
        setEnteringRoute('alreadyHarvested')
        setAlertModal(true)
        return
      }
      if (result?.status === 200) {
        if (itemType === 'coffee_starbucks') {
          harvestData('#openAlfarmCoffeeStarbucks')
          return
        }
        if (itemType === 'coffee_mega') {
          harvestData('#openAlfarmCoffeeMega')
          return
        }
        if (itemType === 'coffee_compose') {
          harvestData('#openAlfarmCoffeeCompose')
          return
        }

        const refinedItemType =
          itemType.charAt(0).toUpperCase() + itemType.slice(1)
        harvestData(`#openAlfarm${refinedItemType}`)
      }
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1000,
            width: '100%',
            height: '100vh',
            background: 'rgba(0,0,0,0.8)',
          }}
        />
        <div
          style={{
            height: '125vw',
            position: 'absolute',
            zIndex: 1001,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            width: '90vw',
            background:
              'linear-gradient(360deg, rgba(255,185,80,1) 0%, rgba(255,244,116,1) 100%)',
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontFamily: 'maplestory',
              color: '#402C24',
              fontSize: '6vw',
              textAlign: 'center',
              marginTop: '4.4vw',
              marginBottom: '1vw',
              fontWeight: 'bold',
            }}
          >
            바로 수확 안내
          </div>
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '4vw',
              width: '92%',
              height: '83%',
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '0vw',
              marginTop: '2vw',
              marginBottom: '10vw',
              alignItems: 'center',
              overflowY: 'scroll',
            }}
          >
            <div
              style={{
                fontFamily: 'maplestory',
                color: '#402C24',
                textAlign: 'start',
                width: '86%',
                fontSize: '4vw',
                marginTop: '8vw',
              }}
            >
              안녕하세요 농부님,
              <br /> 거봉 설문조사에 참여해주셔서 감사합니다. <br />
              <br />
              안내드린대로 바로 수확권을 지급해드립니다. <br />
              키우고 계신 작물은 지금 바로 수확이 가능하며, 수확 후 5,000원
              쿠폰도 지급될 예정입니다.
              <br />
              <br />
              추가 문의 사항은 올웨이즈 고객센터로 문의 부탁드립니다. <br />
              고객센터 : 올웨이즈 앱 {'>'} 내 정보 {'>'} 고객문의 {'>'} 1:1
              실시간 상담하기
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '5vw',
                }}
              >
                <button
                  style={{
                    padding: '4vw 1vw 4vw 1vw',
                    background:
                      'linear-gradient(180deg, rgba(255,212,61,1) 0%, rgba(255,226,125,1) 100%)',
                    borderRadius: '100vw',
                    width: '45vw',
                    fontSize: '4vw',
                    fontFamily: 'maplestory',
                    color: 'black',

                    marginLeft: '15vw',
                  }}
                  onClick={() => {
                    modalClose()
                  }}
                >
                  작물 수확하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default HarvestGrapeModal
