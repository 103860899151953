import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'

import backendApis from '../../utils/backendApis'

const Coin = observer(({ token, ongoingData, setOngoingData }) => {
  const [openNeedCoinModal, setOpenNeedCoinModal] = useState(false)
  const [canGiveCoin, setCanGiveCoin] = useState(true)
  const [indexCount, setIndexCount] = useState(1)
  const [coinState, setCoinState] = useState(false)

  const giveCoin = async () => {
    setCanGiveCoin(false)
    if (ongoingData?.coin < 1) {
      setOpenNeedCoinModal(true)
      setTimeout(() => {
        setOpenNeedCoinModal(false)
        setCanGiveCoin(true)
      }, 3000)
      return
    }
    const result = await backendApis.giveCoin()

    if (result?.status === 200) {
      setIndexCount(indexCount + 1)
      let tempOngoingData = ongoingData
      tempOngoingData.coin -= 1
      tempOngoingData.exp += result?.data?.exp
      setOngoingData(tempOngoingData)
    }
    setCanGiveCoin(true)
  }

  // useEffect(() => {
  //   setCoinState(false);
  //   setCanGiveCoin(false);
  //   setTimeout(() => {
  //     setCoinState(true);
  //     setCanGiveCoin(true);
  //   }, 500);

  // }, [indexCount]);

  return (
    <div
      style={{
        position: 'relative',
        marginBottom: '3vw',
      }}
    >
      <img
        style={{
          width: '20vw',
          marginLeft: '14vw',
        }}
        onClick={() => {
          if (canGiveCoin) {
            giveCoin(true)
          }
        }}
        alt=''
        src={`/luckyPiggy/coin.png`}
      />

      <div className='bt-coin-label-box'>
        <div className='bt-coin-label'>{ongoingData?.coin}개</div>
      </div>

      {/* {coinState && (
        <div
          style={{
            width: "15vw",
            right: "0vw",
            bottom: "30vw",
            zIndex: 1101,
            position: "absolute",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 1211,
            }}
            className={`${coinState}
            slide-out-top-piggy
        `}
          >
            <img style={{}} src="/luckyPiggy/coinIcon.png" alt="" />
          </div>
        </div>
      )} */}
    </div>
  )
})

export default Coin
