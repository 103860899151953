import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import ModalStore from '../../store/ModalStore'

const PurchaseForGganbuModal = ({ onClick, token, farmData, gganbuId }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [limitCount, setLimitCount] = useState(false)
  const PurchaseForGganbu = () => {
    const purchaseForGganbu = {
      screen: 'AlfarmPurchaseRewardScreen',
      rewardType: 'water',
    }
    backendApis.updateLastVisitedGganbu(token, 'POST', { gganbuId: gganbuId })
    window.location.href = `#purchaseForGganbu.${JSON.stringify(
      purchaseForGganbu,
    )}`
  }

  const purchaseCount = async () => {
    const result = await backendApis.gganbuPurchaseCount(token)

    if (result?.data?.purchaseCount >= 2) {
      setLimitCount(true)
    }
  }

  useEffect(() => {
    purchaseCount()
  }, [])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 2001,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.7)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: '80%',
          left: '10%',
          zIndex: 2001,
          top: '20%',
        }}
      >
        <img src='/icon/purchaseForGganbuModal.png' alt='' />

        {limitCount ? (
          <button
            style={{
              background: isButtonPressed ? '93CB4E' : '#93CB4E',
              boxShadow: isButtonPressed
                ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                : '',
              flex: 1,
              position: 'absolute',
              left: '10%',
              bottom: '11vw',
              zIndex: 2,
              borderRadius: 5,
              width: '80%',
              height: 50,
            }}
          >
            <div
              style={{
                color: 'white',
                fontFamily: 'maplestory',
                fontSize: '5vw',
              }}
            >
              하루에 2번까지 가능해요
            </div>
          </button>
        ) : (
          <button
            style={{
              background: isButtonPressed ? '93CB4E' : '#93CB4E',
              boxShadow: isButtonPressed
                ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                : '',
              flex: 1,
              position: 'absolute',
              left: '10%',
              bottom: '11vw',
              zIndex: 2,
              borderRadius: 5,
              width: '80%',
              height: 50,
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
            onClick={PurchaseForGganbu}
          >
            <div
              style={{
                color: 'white',
                fontFamily: 'maplestory',
                fontSize: '5vw',
              }}
            >
              구매하고 품앗이 하기
            </div>
          </button>
        )}
      </div>
      <button
        style={{
          width: '8%',
          top: '43vw',
          right: '12%',
          zIndex: 2001,
          position: 'absolute',
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img src='/icon/modalCloseButton.png' alt='' />
      </button>
    </>
  )
}

export default PurchaseForGganbuModal
