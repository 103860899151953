import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'
import apiHandler from 'utils/apiHandler'
import backendApis from 'utils/backendApis'
import ModalFrame from './ModalFrame'
import ToastStore from 'store/ToastStore'
import ResourceToastStore from 'store/ResourceToastStore'
import SecondModalStore from 'store/SecondModalStore'
import UserStore from 'store/UserStore'

let hidden, visibilityChange
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

const AlphabetEventModal = observer(({ farmData, setFarmData }) => {
  const [alphabetData, setAlphabetData] = useState({})
  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        await userAlphabetData()

        setIsLoading(false)
      } catch (error) {
        // 오류 처리
        // setIsLoading(false); // 오류 발생 시에도 로딩 상태를 해제합니다.
      }
    }

    // 페이지의 가시성 변경 이벤트 핸들러
    const handleVisibilityChange = () => {
      if (!document[hidden]) {
        // 페이지가 다시 가시 상태로 돌아올 때 API를 다시 호출합니다.
        fetchData()
      }
    }

    // VisibilityChange 이벤트 리스너 추가
    document.addEventListener(visibilityChange, handleVisibilityChange)

    // 컴포넌트 언마운트 시에 이벤트 리스너 제거
    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange)
    }

    // useEffect를 isLoading에 종속시킴으로써 가시성 변경에만 반응하도록 합니다.
  }, [isLoading])

  const tteok = [
    {
      name: 'H',
      offImage: '/alphabetEvent/alphabet/hOff.png',
      onImage: '/alphabetEvent/alphabet/hOn.png',
      isOn: alphabetData[0]?.value > 0,
      count: alphabetData[0]?.value,
      left: '14%',
      top: '24vw',
      transform: 'rotate(-11deg)',
    },
    {
      name: 'A',
      offImage: '/alphabetEvent/alphabet/aOff.png',
      onImage: '/alphabetEvent/alphabet/aOn.png',
      isOn: alphabetData[1]?.value > 0,
      count: alphabetData[1]?.value,
      left: '27%',
      top: '16vw',
      transform: 'rotate(-10deg)',
    },
    {
      name: 'P',
      offImage: '/alphabetEvent/alphabet/pOff.png',
      onImage: '/alphabetEvent/alphabet/pOn.png',
      isOn: alphabetData[2]?.value > 0,
      count: alphabetData[2]?.value,
      left: '42.5%',
      top: '14vw',
    },
    {
      name: 'P',
      offImage: '/alphabetEvent/alphabet/pOff.png',
      onImage: '/alphabetEvent/alphabet/pOn.png',
      isOn: alphabetData[2]?.value > 1,
      count: alphabetData[2]?.value,
      left: '58%',
      top: '16vw',
      transform: 'rotate(10deg)',
    },
    {
      name: 'Y',
      offImage: '/alphabetEvent/alphabet/yOff.png',
      onImage: '/alphabetEvent/alphabet/yOn.png',
      isOn: alphabetData[3]?.value > 0,
      count: alphabetData[3]?.value,
      left: '71%',
      top: '24vw',
      transform: 'rotate(11deg)',
    },
    {
      name: 'N',
      offImage: '/alphabetEvent/alphabet/nOff.png',
      onImage: '/alphabetEvent/alphabet/nOn.png',
      isOn: alphabetData[4]?.value > 0,
      count: alphabetData[4]?.value,
      left: '28%',
      top: '35vw',
      transform: 'rotate(-10deg)',
    },
    {
      name: 'E',
      offImage: '/alphabetEvent/alphabet/eOff.png',
      onImage: '/alphabetEvent/alphabet/eOn.png',
      isOn: alphabetData[5]?.value > 0,
      count: alphabetData[5]?.value,
      left: '42.5%',
      top: '33vw',
    },
    {
      name: 'W',
      offImage: '/alphabetEvent/alphabet/wOff.png',
      onImage: '/alphabetEvent/alphabet/wOn.png',
      isOn: alphabetData[6]?.value > 0,
      count: alphabetData[6]?.value,
      left: '57%',
      top: '35vw',
      transform: 'rotate(10deg)',
    },
    {
      name: 'Y',
      offImage: '/alphabetEvent/alphabet/yOff.png',
      onImage: '/alphabetEvent/alphabet/yOn.png',
      isOn: alphabetData[3]?.value > 1,
      count: alphabetData[3]?.value,
      left: '20%',
      top: '49.5vw',
      transform: 'rotate(-10deg)',
    },
    {
      name: 'E',
      offImage: '/alphabetEvent/alphabet/eOff.png',
      onImage: '/alphabetEvent/alphabet/eOn.png',
      isOn: alphabetData[5]?.value > 1,
      count: alphabetData[5]?.value,
      left: '34.5%',
      top: '52vw',
      transform: 'rotate(-5deg)',
    },
    {
      name: 'A',
      offImage: '/alphabetEvent/alphabet/aOff.png',
      onImage: '/alphabetEvent/alphabet/aOn.png',
      isOn: alphabetData[1]?.value > 1,
      count: alphabetData[1]?.value,
      left: '50.5%',
      top: '52vw',
      transform: 'rotate(5deg)',
    },
    {
      name: 'r',
      offImage: '/alphabetEvent/alphabet/rOff.png',
      onImage: '/alphabetEvent/alphabet/rOn.png',
      isOn: alphabetData[7]?.value > 0,
      count: alphabetData[7]?.value,
      left: '65.5%',
      top: '49.5vw',
      transform: 'rotate(10deg)',
    },
  ]

  const shareKakao = () => {
    let imageUrl =
      'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png'
    let title = '[올웨이즈] 떡국 만들면 세뱃돈 100만원!'
    let description = '올팜에서 떡국을 만들면 세뱃돈 100만원을 드려요.'
    let templateId = 103278

    apiHandler.kakaoLinkShare({
      shareType: 'Alfarm_linkShare_alphabet_event',
      title,
      description,
      imageUrl,
      payload: {},
      templateId,
    })
  }

  useEffect(() => {
    userAlphabetData()
  }, [])

  const userAlphabetData = async () => {
    const result = await backendApis.getUserAlphabetData()

    setAlphabetData(result?.data?.dataArray)
    setUserData(result?.data)
    setIsLoading(false)
  }

  const exchageAlphabet = async () => {
    const result = await backendApis.exchangeAlphabet()
    if (!result?.data?.success) {
      ToastStore.toastOn({
        type: 'error',
        message: '알파벳이 부족해요.',
        duration: 3000,
      })
    }
    if (result?.data?.success) {
      userAlphabetData()
      ResourceToastStore.setResourceToast({
        open: true,
        type: 'waterFertilizerSet',
        count: '보상',
      })
      let tmpFarmData = farmData
      if (UserStore.waterExceeded) {
        tmpFarmData.water += Math.round(500 * 0.5)
      } else {
        tmpFarmData.water += 500
      }
      setFarmData(tmpFarmData)
    }
  }

  if (isLoading) return null

  return (
    <ModalFrame modalContentStyle={styles.modalContent}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <img
          style={{
            marginTop: '12vw',
            width: '6vw',
            marginRight: '12vw',
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
          src='/alphabetEvent/closeButton.png'
          alt=''
        />
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            width: '60vw',
            marginTop: '4vw',
          }}
          src='/alphabetEvent/headerSection.png'
          alt=''
        />

        <div
          style={{
            textAlign: 'center',
            fontSize: '5vw',
            marginTop: '4vw',
            // marginBottom: "4vw",
            width: '90vw',
            marginLeft: '5vw',
          }}
        >
          <img src='/alphabetEvent/rewardTextSection.png' alt='' />
        </div>
        <div
          style={{
            position: 'relative',
          }}
        >
          <img
            style={{
              marginTop: '4vw',
              width: '80vw',
            }}
            src='/alphabetEvent/tteokguk.png'
            alt=''
          />
          {tteok.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  position: 'absolute',
                  left: item?.left,
                  top: item?.top,
                  transform: item?.transform,
                  background: item?.background,
                }}
              >
                <img
                  style={{
                    width: '10vw',
                    height: '17vw',
                  }}
                  src={item?.isOn ? item?.onImage : item?.offImage}
                  alt='tteok'
                />
                <div
                  style={{
                    position: 'absolute',
                    fontSize: '3.5vw',
                    fontWeight: 'bold',
                    color: '#D5B78B',

                    bottom: '2vw',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {item?.count}개
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <img
        style={{
          marginTop: '4vw',
          width: '80vw',
          marginLeft: '10vw',
          marginBottom: '4vw',
        }}
        src='/alphabetEvent/rewardSection.png'
        alt=''
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            borderRadius: '10vw',
            background: '#FFC634',
            boxShadow: '0vw 1.5vw 0vw 0vw #EA9F0D',
            width: '70vw',
            marginLeft: '15vw',
            textAlign: 'center',
            fontSize: '6vw',
            height: '16vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '4vw',
          }}
          onClick={() => {
            if (!userData?.canCreateString) {
              shareKakao()
            } else {
              exchageAlphabet()
            }
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: -1,
              height: '100vw',
            }}
            src='/alphabetEvent/background.png'
            alt=''
          />

          <img
            style={{
              width: '55vw',
            }}
            src={
              userData?.canCreateString
                ? '/alphabetEvent/rewardButton.png'
                : '/alphabetEvent/shareButton.png'
            }
            alt=''
          />
        </div>

        <img
          src='/alphabetEvent/history.png'
          alt='seasonal event header'
          style={{
            marginTop: '4vw',
            width: '8vw',
            height: '12vw',
            marginLeft: '3vw',
          }}
          onClick={() => {
            SecondModalStore.setIsModalOpen('alphabetEventHistoryModal')
          }}
        />
      </div>

      <div
        style={{
          fontSize: '4vw',
          width: '100vw',
          lineHeight: '6vw',
          background: '#844824',
          paddingBottom: '8vw',
        }}
      >
        <div
          style={{
            paddingTop: '12vw',
            textAlign: 'center',
            fontSize: '5.5vw',
            marginBottom: '4vw',
          }}
        >
          이벤트 안내
        </div>
        <div
          style={{
            width: '80vw',
            marginLeft: '10vw',
          }}
        >
          - 카카오 링크를 공유 1회당 물 30g과 1개의 랜덤 알파벳을 획득해요.
          <br />
          - 알파벳을 모두 모으면 보상과 교환할 수 있어요. <br />
          - 이벤트는 계속해서 참여할 수 있어요. 최대한 많은 떡국을 만들어보세요!
          <br />
          - 이벤트는 2024년 2월 13일까지 진행돼요. <br />
          - 세뱃돈 100만원은 떡국을 다 만든 고객님 중 5분을 추첨해서 드려요.
          <br />
          - 추첨 결과는 2월 15일에 당첨된 고객님께 별도로 연락을 드려요.
          <br />
          - 세뱃돈 경품 수령을 위해 제세공과금 22%가 부과돼요. <br />
          - 이벤트는 사전고지 없이 변경 및 종료될 수 있어요. <br />
        </div>
      </div>
    </ModalFrame>
  )
})

const styles = {
  closeButton: {
    position: 'absolute',
    top: '-30vw',
    right: '0vw',
    width: '8vw',
  },
  modalContent: {
    width: '100vw',
    position: 'relative',
    overflow: 'scroll',
    height: '100vh',
  },
}

export default AlphabetEventModal
