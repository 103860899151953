import React, { useState, useEffect, useRef } from 'react'

const SuccessImage = ({ rewardType }) => {
  if (rewardType === 'gifticon') {
    return (
      <div
        style={{
          display: 'flex',
          // backgroundImage: "url(../grapeEvent/grapeCover.png)",
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '5vw',
          width: '150vw',
          justifySelf: 'center',
          alignSelf: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            backgroundImage: 'url(../grapeEvent/rewardGifticon.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginTop: '5vw',
            width: '150vw',
            justifySelf: 'center',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              height: '120vw',
            }}
          />
        </div>
      </div>
    )
  }
  if (rewardType === 'grape') {
    return (
      <div
        style={{
          display: 'flex',
          // backgroundImage: "url(../grapeEvent/grapeCover.png)",
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '5vw',
          width: '150vw',
          justifySelf: 'center',
          alignSelf: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            backgroundImage: 'url(../grapeEvent/rewardGrape.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginTop: '5vw',
            width: '150vw',
            justifySelf: 'center',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              height: '120vw',
            }}
          />
        </div>
      </div>
    )
  }
  if (rewardType === 'water') {
    return (
      <div
        style={{
          display: 'flex',
          // backgroundImage: "url(../grapeEvent/grapeCover.png)",
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '5vw',
          width: '150vw',
          justifySelf: 'center',
          alignSelf: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            backgroundImage: 'url(../grapeEvent/rewardWater.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginTop: '5vw',
            width: '150vw',
            justifySelf: 'center',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              height: '120vw',
            }}
          />
        </div>
      </div>
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        backgroundImage: 'url(../grapeEvent/grapeCover.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: '5vw',
        width: '150vw',
        justifySelf: 'center',
        alignSelf: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '0vw',
          width: '150vw',
          justifySelf: 'center',
          alignSelf: 'center',
        }}
      >
        <div
          style={{
            height: '120vw',
          }}
        />
      </div>
    </div>
  )
}

export default SuccessImage
