import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const FeverModePurchaseModal = observer(
  ({ token, farmData, setFarmData, isLoading }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const throttleRef = useRef({})

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div className='w-[80vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
            <button
              style={{
                width: '10%',
                top: '-15%',
                right: '8%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>

            <img
              src='/feverMode/purchaseModal.png'
              alt='abc'
              className='w-[100vw]'
            />
            <div
              style={{
                background: isButtonPressed ? '#F7C82A' : '#F7C82A',
                boxShadow: isButtonPressed
                  ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                  : '',
                width: '60vw',
                height: '14vw',
                borderRadius: 99,
                color: 'black',
                fontFamily: 'maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '4.5vw',
                position: 'absolute',
                bottom: '8vw',
                left: '10vw',
              }}
              onClick={() => {
                AlfarmEventLogger({
                  throttleRef,
                  locationType: 'modal',
                  locationName: ModalStore.isModalOpen,
                  eventType: 'click',
                  eventName: 'cta',
                  data: {},
                  collection: 'UserAlfarmClickLog',
                })

                const purchaseData = {
                  enteringComponent: 'Alfarm_feverModePurchase',
                  text: '올팜 피버 모드',
                  source:
                    'https://assets.ilevit.com/d0465557-9c31-4a20-a409-0f8f688074ea.png',
                  rewardText: `상품을 구매하면 고급비료 3개를 받아요`,
                }
                window.location.href = `#commonPurchaseReward.${JSON.stringify(
                  purchaseData,
                )}`
                ModalStore.setIsModalOpen('basic')
              }}
              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
              }}
            >
              상품 구매하고 혜택 받기
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default FeverModePurchaseModal
