import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import AlfarmEventLogger from '../../utils/alfarmEventLogger'
import ModalStore from '../../store/ModalStore'

const plantNameTable = {
  potato: '감자 1Kg',
  onion: '양파 1Kg',
  coffee_starbucks: '스타벅스 커피 1잔',
  mool: '물 2L 6개입',
}

const imageTable = {
  potato: {
    onImage: 'url(../changePlantBeforeChurn/potatoColor.png)',
    offImage: 'url(../changePlantBeforeChurn/potatoGray.png)',
  },
  onion: {
    onImage: 'url(../changePlantBeforeChurn/onionColor.png)',
    offImage: 'url(../changePlantBeforeChurn/onionGray.png)',
  },
  coffee_starbucks: {
    onImage: 'url(../changePlantBeforeChurn/coffeeColor.png)',
    offImage: 'url(../changePlantBeforeChurn/coffeeGray.png)',
  },
  mool: {
    onImage: 'url(../changePlantBeforeChurn/moolColor.png)',
    offImage: 'url(../changePlantBeforeChurn/moolGray.png)',
  },
}

const PlantButton = ({
  type = 'coffee_starbucks',
  isClicked = false,
  setClickedPlant,
}) => {
  let borderStyle = isClicked
    ? {
        backgroundColor: '#E9DDC8',
        border: '3px solid #FFC42E',
      }
    : {
        backgroundColor: '#FFF5E2',
        borderTop: '1px solid #C6AF97',
        borderLeft: '1px solid #C6AF97',
        borderBottom: '2px solid #C6AF97',
        borderRight: '2px solid #C6AF97',
      }

  return (
    <div
      className='flex flex-1 m-[8px] p-[8px] rounded-full aspect-square'
      style={{
        backgroundImage: isClicked
          ? imageTable[type]?.onImage
          : imageTable[type]?.offImage,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        ...borderStyle,
      }}
      onClick={() => {
        setClickedPlant(type)
      }}
    />
  )
}

const ChangePlantBeforeChurnModal = observer(
  ({
    closeModal,
    farmData,
    showRewardToast,
    setFarmData,
    setItemType,
    token,
  }) => {
    const [clickedPlant, setClickedPlant] = useState(null)
    const throttleRef = useRef({})

    const changePlantBeforeChurn = async () => {
      if (!clickedPlant) {
        return
      }
      let result = await backendApis.changePlantBeforeChurn({
        itemType: clickedPlant,
      })

      if (result?.data?.success) {
        window.location.reload()
      }
    }

    return (
      <>
        <div className='flex-1 fixed left-0 top-0 z-[900] w-full h-full bg-[rgba(0,0,0,0.8)]'>
          <div
            className='w-[90vw] h-[150vw] top-1/2 left-1/2 -translate-x-1/2 -translate-y-[55%] flex absolute justify-around items-center flex-col px-[16px] pt-[42vw] pb-[20vw] bg-center bg-contain bg-no-repeat'
            style={{
              backgroundImage: 'url(../changePlantBeforeChurn/background.png)',
            }}
          >
            <button
              className='w-[10vw] right-0 top-[10vw] absolute z-[999] '
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                className='z-[101]'
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div className='w-full h-full flex flex-col justify-between items-center'>
              <div className='text-[black] font-maplestory text-[5vw] text-center mt-[6vw] font-bold'>
                더 쉬운 작물로 변경하고 <br />
                빨리 수확해보세요!
              </div>
              <div className='text-[black] mt-[8px] mb-[8px]'>
                지금 변경하면 경험치 보너스를 드려요
              </div>

              <div className='flex flex-row justify-center items-center w-full'>
                <PlantButton
                  type='coffee_starbucks'
                  isClicked={clickedPlant === 'coffee_starbucks'}
                  setClickedPlant={setClickedPlant}
                />
                <PlantButton
                  type='mool'
                  isClicked={clickedPlant === 'mool'}
                  setClickedPlant={setClickedPlant}
                />
                <PlantButton
                  type='onion'
                  isClicked={clickedPlant === 'onion'}
                  setClickedPlant={setClickedPlant}
                />
                <PlantButton
                  type='potato'
                  isClicked={clickedPlant === 'potato'}
                  setClickedPlant={setClickedPlant}
                />
              </div>

              <div
                style={{
                  color: 'black',
                  fontFamily: 'maplestory',
                  fontSize: '1.1rem',
                  textAlign: 'center',
                  // lineHeight: "160%",
                  marginBottom: 16,
                  // fontWeight: "bold",
                }}
              >
                {clickedPlant
                  ? `내가 선택한 작물: ${plantNameTable[clickedPlant]}`
                  : '작물을 선택해주세요'}
              </div>

              <div
                style={{
                  background: clickedPlant
                    ? 'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)'
                    : '#C5C5C5',

                  width: '60vw',
                  height: '14vw',
                  borderRadius: 99,
                  color: clickedPlant ? 'black' : 'white',
                  fontFamily: 'Maplestory',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '5.5vw',
                  marginBottom: '8vw',
                }}
                className={clickedPlant ? 'scaleUp' : ''}
                onClick={() => {
                  changePlantBeforeChurn()
                  AlfarmEventLogger({
                    throttleRef,
                    locationType: 'modal',
                    locationName: ModalStore?.isModalOpen,
                    eventType: 'click',
                    eventName: 'cta',
                    data: {},
                    collection: 'UserAlfarmClickLog',
                  })
                }}
              >
                {clickedPlant ? '작물 변경하기' : '작물 선택'}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)
export default ChangePlantBeforeChurnModal
