import backendApis from '../../utils/backendApis'
import React, { useEffect, useRef, useState } from 'react'
import WhiteTextTimer from '../WhiteTextTimer'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import UserStore from 'store/UserStore'

const PuppyModal = ({ token, farmData, setPuppyEndTime, setShowPuppy }) => {
  const [isButtonPressedPurchase, setIsButtonPressedPurchase] = useState(false)
  const [isButtonPressedInvite, setIsButtonPressedInvite] = useState(false)
  const [canPurchase, setCanPurchase] = useState(false)
  const [purchaseTime, setPurchaseTime] = useState(new Date())
  const [timeLoading, setTimeLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const throttleRef = useRef({})

  useEffect(() => {
    getPuppyPurchasedAt()
  }, [])

  useEffect(() => {
    puppyEndAt()
  }, [])

  const puppyEndAt = async () => {
    const result = await backendApis.puppyEndAt(token)

    if (result?.data?.success === true) {
      setShowPuppy(true)

      const result = await backendApis.lastGetPuppyGift(token)
      UserStore?.set('puppyCanClick', result?.data?.canClick)
      setPuppyEndTime(new Date(result?.data?.endedAt))
    }
    if (result?.data?.success === false) {
      setShowPuppy(false)
      setPuppyEndTime(new Date(result?.data?.endedAt))
    }
    setIsLoading(false)
  }
  const PuppyPurchaseButton = () => {
    AlfarmEventLogger({
      throttleRef,
      locationType: 'modal',
      locationName: ModalStore.isModalOpen,
      eventType: 'click',
      eventName: 'cta_purchase',
      data: {},
      collection: 'UserAlfarmClickLog',
    })

    const puppyPurchaseReward = {
      screen: 'AlfarmPuppyPurchaseRewardScreen',
      rewardType: 'puppy',
    }
    window.location.href = `#puppyPurchaseReward.${JSON.stringify(
      puppyPurchaseReward,
    )}`
  }

  const getPuppyPurchasedAt = async () => {
    const result = await backendApis.getPuppyPurchasedAt(token)

    if (result?.data?.success === true) {
      setCanPurchase(true)
    } else if (result?.data?.success === false) {
      setCanPurchase(false)
      setPurchaseTime(new Date(result?.data?.lastPayedAt))
      setTimeLoading(false)
    }
  }

  const PurchaseButton = () => {
    if (!canPurchase) {
      return (
        <div
          style={{
            color: '#402C24',
            fontSize: '14px',
            borderRadius: '22px',
            paddingTop: '10px',
            paddingBottom: '10px',
            zIndex: 1103,
            fontFamily: 'maplestory',
            width: '20%',
            bottom: '1vw',
            right: '5vw',
            position: 'absolute',
            textAlign: 'center',
          }}
        >
          {!timeLoading && (
            <WhiteTextTimer
              onTimeEnd={() => {
                setCanPurchase(true)
              }}
              timeStamp={purchaseTime}
              timerMinutes={60 * 24 * 2}
              color='#442b22'
            />
          )}
          후에 구매 가능해요
        </div>
      )
    } else if (canPurchase) {
      return (
        <button
          style={{
            position: 'absolute',
            background: isButtonPressedPurchase
              ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressedPurchase
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',
            color: '#402C24',
            fontSize: '4vw',
            borderRadius: '22px',
            paddingTop: '10px',
            paddingBottom: '10px',
            zIndex: 11,
            fontFamily: 'maplestory',
            width: '22%',
            bottom: '5vw',
            right: '5vw',
          }}
          onPointerDown={() => {
            setIsButtonPressedPurchase(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressedPurchase(false)
          }}
          onPointerUp={() => {
            setIsButtonPressedPurchase(false)
          }}
          onClick={PuppyPurchaseButton}
        >
          구매하기
        </button>
      )
    }
  }

  const shareKakao = () => {
    AlfarmEventLogger({
      throttleRef,
      locationType: 'modal',
      locationName: ModalStore.isModalOpen,
      eventType: 'click',
      eventName: 'cta_share',
      data: {},
      collection: 'UserAlfarmClickLog',
    })

    window.location.href = '#alfarmPuppyInvite'
  }

  const InviteButton = () => {
    return (
      <button
        style={{
          position: 'absolute',
          background: isButtonPressedInvite
            ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
            : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
          boxShadow: isButtonPressedInvite
            ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
            : '',
          color: '#402C24',
          fontSize: '4vw',
          borderRadius: '22px',
          paddingTop: '10px',
          paddingBottom: '10px',
          zIndex: 11,
          fontFamily: 'maplestory',
          width: '22%',
          bottom: '5vw',
          right: '5vw',
        }}
        onPointerDown={() => {
          setIsButtonPressedInvite(true)
        }}
        onPointerCancel={() => {
          setIsButtonPressedInvite(false)
        }}
        onPointerUp={() => {
          setIsButtonPressedInvite(false)
        }}
        onClick={shareKakao}
      >
        초대하기
      </button>
    )
  }

  if (isLoading) return null

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1100,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      />
      <div
        style={{
          width: '90vw',
          top: '40vw',
          left: '5vw',
          zIndex: 1101,
          position: 'absolute',
        }}
      >
        <img src='/icon/puppyModalMainNew.png' alt='' />
        <div
          style={{
            position: 'absolute',
            fontFamily: 'maplestory',
            flex: 1,
            bottom: '4vw',
            zIndex: 12,
            borderRadius: 5,
            width: '100%',
            textAlign: 'center',
            color: '#442b22',
            fontSize: '4.5vw',
          }}
        >
          <WhiteTextTimer
            onTimeEnd={() => {
              // setLuckydrawPurchaseRewardOn(false);
              ModalStore.setIsModalOpen('basic')
              setShowPuppy(false)
            }}
            timeStamp={new Date(farmData?.puppyEndAt)}
            timerMinutes={0}
            color='#442b22'
          />
          {'  '}후에 바둑이가 사라져요
        </div>
        {/* <div
          style={{
            position: 'absolute',
            fontFamily: 'maplestory',
            flex: 1,
            bottom: '26vw',
            zIndex: 12,
            borderRadius: 5,
            width: '93.4%',
            left: '3.3%',
            textAlign: 'center',
            color: '#442b22',
          }}
        >
          <img src='/icon/puppyInviteQuest1.png' alt='' />
          {PurchaseButton()}
        </div> */}

        {/* {userCodePushVersion >= '1.5.54' && (
          <div
            style={{
              position: 'absolute',
              fontFamily: 'maplestory',
              flex: 1,
              bottom: '7vw',
              zIndex: 12,
              borderRadius: 5,
              width: '93.4%',
              left: '3.3%',
              textAlign: 'center',
              color: '#442b22',
            }}
          >
            <img src='/icon/puppyInviteQuest2.png' alt='' />
            {InviteButton()}
          </div>
        )} */}
      </div>

      <button
        style={{
          width: '10%',
          top: '15%',
          right: '10%',
          zIndex: 1101,
          position: 'absolute',
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img
          style={{
            zIndex: 1101,
          }}
          src='/icon/modalCloseButton.png'
          alt=''
        />
      </button>
    </>
  )
}

export default PuppyModal
