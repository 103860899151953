import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import dayjs from 'dayjs'
import ModalStore from '../../store/ModalStore'
import TimeStore from 'store/TimeStore'
import UserStore from 'store/UserStore'
import moment from 'moment'
import PlantBasicInfo from 'comps/molecules/PlantBasicInfo'
import { farmingList } from '../../config'

import AlfarmEventLogger from 'utils/alfarmEventLogger'

const DoubleExpEventModal = observer(
  ({ token, farmData, setFarmData, loadUserCoupon }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const throttleRef = useRef({})

    const shareKakao = () => {
      const inviteData = {
        shareType: 'AlfarmDoubleExpEvent',
        title: '[올팜] 함께 올팜 작물을 키워봐요!',
        description: '내가 키운 작물을 무료로 받아보세요!',
        bannerImage:
          'https://assets.ilevit.com/34ed5826-d1bd-4822-940f-02274f0a9042.png',
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
      AlfarmEventLogger({
        throttleRef,
        locationType: 'DoubleExpEventModal',
        locationName: 'InviteButton',
        eventType: 'click',
        collection: 'UserAlfarmClickLog',
      })
    }

    const CTA = () => {
      return (
        <button
          className='text-[4.5vw] px-[6vw] rounded-3xl py-[4vw] z-[2000] text-[#442B22] mt-[4vw] font-bold mb-[6vw] pulsate-fwd'
          style={{
            background: isButtonPressed
              ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressed
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
            shareKakao()
          }}
        >
          {`새친구 초대하기`}
        </button>
      )
    }
    return (
      <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
        <div
          className='w-[90vw] h-[150vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex absolute justify-between items-center flex-col px-[16px] pt-[28vw] pb-[16px]'
          style={{
            backgroundImage: 'url(../doubleExpEventModal/background.png)',
          }}
        >
          <button
            className='z-[1101] w-[8%] -top-[10vw] right-[4vw] absolute'
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
          </button>

          <div className='flex flex-col items-center justify-center w-full'>
            <div className='font-bold text-[#442B22] mb-[2vw] mt-[4vw]'>
              {`새친구를 초대하면 서로 7일간`}
            </div>
            <div className='font-bold text-[#442B22] mb-[2vw]'>
              {`경험치가 2배로 올라요!`}
            </div>
            <div className='w-full h-auto mt-[-20vw] relative flex justify-center items-center'>
              <img
                className='w-[40%]'
                alt=''
                src={`/farm/${
                  farmingList[
                    PlantBasicInfo.IdFromPlantName(
                      UserStore?.farmData?.itemType,
                    )
                  ]
                }/${6}.gif`}
              />
              <img
                className='absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-[-10vw] w-[40vw]'
                alt=''
                src={`../doubleExpEventModal/upgrade.png`}
              />
            </div>
            <div className='font-bold text-[#442B22] mb-[2vw]'>
              {`이벤트 종료: 2024년 3월 31일`}
            </div>
            <div className='font-bold text-[#442B22] mb-[4vw] text-[3.5vw]'>
              {`*종료전에 성공한 초대만 적용됩니다.`}
            </div>
            <div
              className={`text-[5vw] font-bold font-maplestory text-black bg-[#F4E5C5] rounded-full px-[4vw] py-[1vw] mb-[2vw]`}
            >
              {`${
                UserStore?.farmData?.doubleExpEndAt
                  ? `${moment(UserStore?.farmData?.doubleExpEndAt).diff(
                      TimeStore?.currentMoment,
                      'days',
                    )}일간 적용!`
                  : '미적용 중'
              }`}
            </div>
          </div>

          {TimeStore?.currentMoment?.isSameOrBefore(moment('2024-03-31')) && (
            <CTA />
          )}
        </div>
      </div>
    )
  },
)

export default DoubleExpEventModal
