import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import backendApis from '../../../utils/backendApis'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const PurchaseRouletteModal = observer(
  ({ token, farmData, setFarmData, setPurchaseRouletteButtonOn }) => {
    const [infoModal, setInfoModal] = useState(false)
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const [isRolling, setIsRolling] = useState(false)
    const [rewardModal, setRewardModal] = useState(false)
    const [isRolled, setIsRolled] = useState(false)
    const [CTAText, setCTAText] = useState('상품 구매하고 룰렛 돌리기')
    const [canClickStartButton, setCanClickStartButton] = useState(true)
    const [reward, setReward] = useState(false)
    const REWARD_NAME = [
      '일반비료 22개',
      '물 466g + 일반비료 15개',
      '물 1266g',
      '바로수확',
    ]
    const REWARD_IMAGE = []
    const DEGREE_MAP = [
      5490, // 일반비료22개
      5580, // 물 466 + 일반 15
      5670, // 물 1266g
      5760, // 바로수확
    ]
    const throttleRef = useRef({})

    useEffect(() => {
      if (
        farmData?.purchaseRouletteInfo?.purchase &&
        !farmData?.purchaseRouletteInfo?.runAt
      ) {
        setCTAText(`룰렛 돌리기`)
      }
    }, [farmData])

    const startRoulette = async () => {
      if (!canClickStartButton) {
        return
      }
      setCanClickStartButton(false)

      const result = await backendApis.runPurchaseRoulette(token)
      if (!result?.data?.success) {
        setInfoModal(true)
        setTimeout(() => {
          setInfoModal(false)
        }, 3000)
        return
      }
      setIsRolling(true)
      setIsRolled(true)
      setCTAText(`추첨중...`)
      if (result?.data?.success) {
        let tempFarmData = farmData
        tempFarmData.purchaseRouletteInfo.run = true
        setFarmData(tempFarmData)
        setReward(result?.data?.reward)
        setTimeout(() => {
          setRewardModal(true)
          setIsRolling(false)
          setCTAText(`대박! 당첨`)
        }, 7100)
      }
    }

    return (
      <>
        <div style={styles.background}>
          <div style={styles.modalDiv}>
            <button
              style={styles.closeButton}
              onClick={() => {
                if (!isRolling) {
                  ModalStore.setIsModalOpen('basic')
                }
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>

            <div
              style={{
                color: 'white',
                fontSize: '6vw',
                fontFamily: 'maplestory',
                textAlign: 'center',
                width: '100vw',
                lineHeight: '150%',
                marginTop: '-13vw',
                marginBottom: '20vw',
              }}
            >
              럭키 룰렛을 돌려보세요!
              <br />
              상품을 구매하면 룰렛을 돌릴 수 있어요
            </div>

            <div
              style={{
                width: '90vw',
                marginTop: '-10vw',
                transform:
                  isRolling || isRolled
                    ? `translate(0%, 0%) rotate(${DEGREE_MAP[reward]}deg`
                    : `translate(0%, 0%)`,
                transition: isRolling
                  ? 'all 7s cubic-bezier(1, 0.12, 0, 1) 0s'
                  : '',
              }}
            >
              <img src='/purchaseRoulette/roulette.png' alt='' />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '43vw',
                width: '10vw',
                left: '49.8%',
                transform: 'translateX(-50%)',
              }}
            >
              <img src='/fertilizerRoulette/pointer.png' alt='' />
            </div>
            <div
              style={{
                color: '#402C24',
                fontSize: '6vw',
                fontFamily: 'maplestory',
                textAlign: 'center',
                background: isButtonPressed
                  ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                  : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                boxShadow: isButtonPressed
                  ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                  : '',
                padding: '5vw',
                borderRadius: '50vw',
                width: '80vw',
                marginTop: '4vw',
              }}
              onPointerDown={() => {
                if (isRolled) return
                if (!isRolling) {
                  setIsButtonPressed(true)
                }
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
                if (
                  !isRolled &&
                  canClickStartButton &&
                  !farmData?.purchaseRouletteInfo?.purchase &&
                  !farmData?.purchaseRouletteInfo?.runAt
                ) {
                  AlfarmEventLogger({
                    throttleRef,
                    locationType: 'modal',
                    locationName: ModalStore.isModalOpen,
                    eventType: 'click',
                    eventName: 'cta_water',
                    data: {},
                    collection: 'UserAlfarmClickLog',
                  })

                  const purchaseRewardWater = {
                    screen: 'AlFarmQuestLinkTimeDeal',
                    enteringComponent: 'Alfarm_purchaseRoulette',
                    source:
                      'https://assets.ilevit.com/0bb6923a-4a2b-4ac0-9ba3-5e46689f26a7.png',
                  }

                  window.location.href = `#timeDealPurchaseNew.${JSON.stringify(
                    purchaseRewardWater,
                  )}`
                  ModalStore.setIsModalOpen('basic')
                }
                if (
                  !isRolled &&
                  canClickStartButton &&
                  farmData?.purchaseRouletteInfo?.purchase
                ) {
                  startRoulette()
                }
              }}
            >
              {CTAText}
            </div>
          </div>

          {rewardModal && (
            <div
              style={{
                flex: 1,
                position: 'fixed',
                left: 0,
                top: 0,
                zIndex: 3000,
                width: '100%',
                height: '100vh',
                backgroundColor: 'rgba(0,0,0,0.8)',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  top: '50%',
                  background: 'white',
                  width: '60%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  borderRadius: '4vw',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    color: 'black',
                    textAlign: 'center',
                    fontSize: '5vw',
                    marginTop: 16,
                    marginBottom: 8,
                  }}
                >
                  당첨!
                </div>
                <img
                  style={{
                    width: '55%',
                    borderRadius: '2vw',
                    marginTop: '-2vw',
                  }}
                  alt=''
                  src={`/purchaseRoulette/reward${reward}.png`}
                />
                <button
                  style={{
                    width: '40%',
                    background:
                      'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                    fontFamily: 'maplestory',
                    color: 'black',
                    fontSize: '4vw',
                    borderRadius: '30vw',
                    padding: '4vw',
                    marginBottom: '4vw',
                  }}
                  onClick={() => {
                    setRewardModal(false)
                    setPurchaseRouletteButtonOn(false)
                    ModalStore.setIsModalOpen('basic')
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          )}
        </div>

        {infoModal && (
          <div
            style={{
              flex: 1,
              position: 'fixed',
              zIndex: 2000,
              background: 'rgba(0,0,0,0.9)',
              borderRadius: 20,
              fontFamily: 'maplestory',
              fontSize: '4vw',
              textAlign: 'center',
              padding: '6vw',
              top: '50%',
              width: '60%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              lineHeight: '150%',
            }}
          >
            상품을 구매해야 <br />
            룰렛을 돌릴 수 있어요
          </div>
        )}
      </>
    )
  },
)

export default PurchaseRouletteModal

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalDiv: {
    width: '90vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10vw',
    backgroundImage: 'url(../cherryPickerRoulette/background.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '28vw',
    paddingBottom: '6vw',
  },
  closeButton: {
    width: '10vw',
    right: '4vw',
    top: '0vw',
    position: 'absolute',
    zIndex: 999,
  },
}
