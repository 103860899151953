import { useEffect, useState, useRef } from 'react'
import dayjs from 'dayjs'
import ABStore from '../../store/ABStore'
import UserStore from '../../store/UserStore'
import TimeStore from '../../store/TimeStore'
import DealAutomationStore from '../../store/DealAutomationStore'
import backendApis from 'utils/backendApis'
import ModalStore from 'store/ModalStore'
import WhiteTextTimer from 'comps/WhiteTextTimer'
import AlfarmAdStore from 'store/AlfarmAdStore'
import useInterval from 'hooks/useInterval'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import { Oval as Loader } from 'react-loader-spinner'

const WinnerDealButton = ({ token, farmData, closeBottomSheet }) => {
  const [buttonClicked, setButtonClicked] = useState(false)
  const [winnerDealTimeIntervalReady, setWinnerDealTimeIntervalReady] =
    useState(false)
  const [winnerDealCount, setWinnerDealCount] = useState(0)
  const [sellerAdAvailable, setSellerAdAvailable] = useState(false)
  const [winnerDealMainAdData, setWinnerDealMainAdData] = useState(null)
  const [winnerDealSubAdData, setWinnerDealSubAdData] = useState(null)
  const [clearedWinnerDealAd, setClearedWinnerDealAd] = useState(true)
  const [lastWinnerDealClearedAt, setLastWinnerDealClearedAt] = useState(null)

  // #region RewardedAdModalTrigger Context
  const [
    rewardedAdModalForWinnerDealCount,
    setRewardedAdModalForWinnerDealCount,
  ] = useState(0)

  const MAXIMUM_REWARDED_AD_MODAL_TRIGGER_COUNT = 3
  const REWARDED_AD_MODAL_TRIGGER_INTERVAL_MS = 1000
  const showRewardedAdModalCTA =
    rewardedAdModalForWinnerDealCount ===
    MAXIMUM_REWARDED_AD_MODAL_TRIGGER_COUNT

  useInterval(
    () => {
      if (UserStore.isRewardAdReady) {
        setRewardedAdModalForWinnerDealCount(
          MAXIMUM_REWARDED_AD_MODAL_TRIGGER_COUNT,
        )
        return
      }
      setRewardedAdModalForWinnerDealCount((c) => c + 1)
    },
    rewardedAdModalForWinnerDealCount ===
      MAXIMUM_REWARDED_AD_MODAL_TRIGGER_COUNT
      ? null
      : REWARDED_AD_MODAL_TRIGGER_INTERVAL_MS,
  )
  // #endregion

  let ALFARM_QUEST_REWARD = 10
  let QUEST_REWARD_TEXT = '초간 구경하면 물 10g을 받아요'
  if (!UserStore?.cherryPickerForAd) {
    //20~50 사이에서 보상 결정, 평균은 30 수렴
    const determineQuestReward = () => {
      const values = [20, 25, 30, 35, 40, 45, 50]
      const weights = [3, 3, 3, 2, 2, 1, 1] // 가중치 설정

      // 가중치의 합을 계산합니다.
      const totalWeight = weights.reduce((sum, weight) => sum + weight, 0)

      // 0에서 totalWeight 사이의 난수를 생성합니다.
      let randomValue = Math.random() * totalWeight

      // 난수에 따라 보상을 결정합니다.
      for (let i = 0; i < values.length; i++) {
        randomValue -= weights[i]
        if (randomValue < 0) {
          return values[i]
        }
      }
    }

    ALFARM_QUEST_REWARD = determineQuestReward()
    QUEST_REWARD_TEXT = `초간 구경하면 물 최대 50g을 받아요`
  }

  useEffect(() => {
    checkWinnerDealAvailable()
    getWinnerDealAdItemInfos()
  }, [])

  //체리피커 발라내는 로직 -> for 앱러빈
  const codePushVersionAvailable = useCheckCodePushVersion()
  const codePushVersionForAdmob = codePushVersionAvailable(UserStore?.codePushVersionFromAlwayzApp, '6.1.5')
  const winnerDealRewardedAdCheck = UserStore?.cherryPickerForAd &&
    (!codePushVersionForAdmob || (codePushVersionForAdmob && !ABStore?.admobAlfarmTester))
      

  // interval time 지났는지 & 광고 본 개수 확인 & 마지막 광고 본 시간 확인
  const checkWinnerDealAvailable = async () => {
    const result = await backendApis.getWaterBottomSheetInfo(token)

    const winnerDealReady = result?.waterBottomSheetInfoTimeChecker
      ?.lastWinnerDealClearedAt
      ? new Date(
          result?.waterBottomSheetInfoTimeChecker?.lastWinnerDealClearedAt,
        ).getTime() +
          1000 * 60 * 60 * AlfarmAdStore.winnerDealTimeIntervalBetweenExposure <
        new Date().getTime()
      : true
    setWinnerDealTimeIntervalReady(winnerDealReady)
    setWinnerDealCount(result?.waterBottomSheetInfoTimeChecker?.winnerDealCount)
    setLastWinnerDealClearedAt(
      result?.waterBottomSheetInfoTimeChecker?.lastWinnerDealClearedAt,
    )
  }

  // 셀러 광고에 잔량이 있는지 확인하고, 봤던 광고 or 새로운 광고 가져와서 세팅함
  const getWinnerDealAdItemInfos = async () => {
    const result = await backendApis.getWinnerDealAdsInfoV2()
    if (result?.data?.mainAdData) {
      setWinnerDealMainAdData(result?.data?.mainAdData)
      setWinnerDealSubAdData(result?.data?.subAdData)
    }
    if (result?.data?.checkSellerAdAvailable) {
      setSellerAdAvailable(result?.data?.checkSellerAdAvailable)
    }
    setClearedWinnerDealAd(result?.data?.cleared)
  }

  // 구매 페이지 진입 후 배너 이미지를 날짜에 맞게 세팅함
  const ImageSprite = () => {
    if (
      TimeStore.currentDayJS >
        dayjs(DealAutomationStore.firstWinnerDealData.timeRangeStart) &&
      TimeStore.currentDayJS <
        dayjs(DealAutomationStore.firstWinnerDealData.timeRangeEnd)
    ) {
      return {
        modalImage: DealAutomationStore.firstWinnerDealData.background,
        purchaseScreenBanner:
          DealAutomationStore.firstWinnerDealData.purchaseScreenBanner,
        purchaseScreenText:
          DealAutomationStore.firstWinnerDealData.purchaseScreenText,
      }
    } else if (
      TimeStore.currentDayJS >
        dayjs(DealAutomationStore.secondWinnerDealData.timeRangeStart) &&
      TimeStore.currentDayJS <
        dayjs(DealAutomationStore.secondWinnerDealData.timeRangeEnd)
    ) {
      return {
        modalImage: DealAutomationStore.secondWinnerDealData.background,
        purchaseScreenBanner:
          DealAutomationStore.secondWinnerDealData.purchaseScreenBanner,
        purchaseScreenText:
          DealAutomationStore.secondWinnerDealData.purchaseScreenText,
      }
    }
  }

  // 광고 데이터를 토대로 구매 페이지 내 아이템 정보를 세팅함
  const mainAdItemIds = winnerDealMainAdData?.itemIds || []
  const subAdItem1Ids = winnerDealSubAdData?.[0]
    ? winnerDealSubAdData?.[0]?.itemIds
    : []
  const subAdItem2Ids = winnerDealSubAdData?.[1]
    ? winnerDealSubAdData?.[1]?.itemIds
    : []
  const combinedItemIds = [...mainAdItemIds, ...subAdItem1Ids, ...subAdItem2Ids]
  const mainAdId = winnerDealMainAdData?._id
  const subAdId1 = winnerDealSubAdData?.[0]?._id || null
  const subAdId2 = winnerDealSubAdData?.[1]?._id || null
  const subAdIds = [subAdId1, subAdId2].filter((e) => e)

  // 오늘 더 볼 수 있는 광고가 있는 경우
  if (winnerDealCount < AlfarmAdStore.winnerDealDailyMaxExposureCount) {
    // 마지막 광고 본 지 x시간 넘었음
    if (winnerDealTimeIntervalReady) {
      // 체리피커 아니고, 셀러 광고 남아있음 -> 판매페이지로 이동
      if (!winnerDealRewardedAdCheck && sellerAdAvailable) {
        return (
          <>
            <button
              className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
                buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
              }`}
              onPointerDown={() => {
                setButtonClicked(true)
              }}
              onPointerCancel={() => {
                setButtonClicked(false)
              }}
              onPointerUp={() => {
                setButtonClicked(false)

                window.location.href = `#generalNavigator.${JSON.stringify({
                  screen: 'AlfarmAdPurchaseScreen',
                  params: {
                    title: ImageSprite()?.purchaseScreenText,
                    image: ImageSprite()?.purchaseScreenBanner,
                    ratio: 99 / 360,
                    enteringComponent: 'Alfarm_winnerDealForAd',
                    questReward: ALFARM_QUEST_REWARD,
                    questRewardType: 'water',
                    questRewardText: QUEST_REWARD_TEXT, // 수정 필요
                    questTimer: 0.5,
                    adId: mainAdId,
                    subAdId: subAdIds,
                    itemIds: combinedItemIds,
                    isTimerOn: !clearedWinnerDealAd,
                  },
                })}`
                closeBottomSheet()
              }}
            >
              구경하기
            </button>
            <div
              className='blinking-slow'
              style={{
                position: 'absolute',
                background: '#EA3323',
                borderRadius: '5vw',
                paddingTop: '1.1vw',
                paddingBottom: '1.1vw',
                paddingRight: '1.1vw',
                paddingLeft: '1.1vw',
                right: '3.0vw',
                // right: '85.0vw',
                top: '2.6vw',
                zIndex: 999,
              }}
            ></div>
          </>
        )
        // 체리피커이거나, 셀러 광고 다 소진됨 -> 앱러빈 모달로 이동
      } else if (winnerDealRewardedAdCheck || !sellerAdAvailable) {
        if (showRewardedAdModalCTA) {
          return (
            <>
              <button
                className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
                  buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
                }`}
                onPointerDown={() => {
                  setButtonClicked(true)
                }}
                onPointerCancel={() => {
                  setButtonClicked(false)
                }}
                onPointerUp={() => {
                  setButtonClicked(false)
                  if (UserStore.isRewardAdReady) {
                    ModalStore?.setIsModalOpen('rewardAdModalForWinnerDeal')
                  } else {
                    window.location.href = `#generalNavigator.${JSON.stringify({
                      screen: 'AlfarmAdPurchaseScreen',
                      params: {
                        title: ImageSprite()?.purchaseScreenText,
                        image: ImageSprite()?.purchaseScreenBanner,
                        ratio: 99 / 360,
                        enteringComponent: 'Alfarm_winnerDealForAd',
                        questReward: ALFARM_QUEST_REWARD,
                        questRewardType: 'water',
                        questRewardText: QUEST_REWARD_TEXT, // 수정 필요
                        questTimer: 0.5,
                        adId: mainAdId,
                        subAdId: subAdIds,
                        itemIds: combinedItemIds,
                        isTimerOn: !clearedWinnerDealAd,
                      },
                    })}`
                  }
                  closeBottomSheet()
                }}
              >
                구경하기
              </button>
              <div
                className='blinking-slow'
                style={{
                  position: 'absolute',
                  background: '#EA3323',
                  borderRadius: '5vw',
                  paddingTop: '1.1vw',
                  paddingBottom: '1.1vw',
                  paddingRight: '1.1vw',
                  paddingLeft: '1.1vw',
                  right: '3.0vw',
                  // right: '85.0vw',
                  top: '2.6vw',
                  zIndex: 999,
                }}
              ></div>
            </>
          )
        }
        return (
          <button className='right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] flex justify-center items-center'>
            <Loader type='Oval' color='#EA3F49' height='30' width='30' />
          </button>
        )
      }
      // 광고 본 지 4시간 안됨 -> 타이머 띄워주기
    } else {
      return (
        <>
          <button
            className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
          >
            <div
              style={{
                color: '#442b22',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <div>
                {lastWinnerDealClearedAt && (
                  <WhiteTextTimer
                    onTimeEnd={() => {
                      setWinnerDealTimeIntervalReady(true)
                    }}
                    timeStamp={
                      new Date(
                        new Date(lastWinnerDealClearedAt).getTime() +
                          1000 *
                            60 *
                            60 *
                            AlfarmAdStore.winnerDealTimeIntervalBetweenExposure,
                      )
                    }
                    timerMinutes={0}
                    color='#442b22'
                  />
                )}
              </div>
            </div>
          </button>
        </>
      )
    }
    // 오늘 볼 수 있는 광고 다 봤음
  } else {
    return (
      <button
        className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
      >
        완료
      </button>
    )
  }
}

export default WinnerDealButton
