import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import NudgeBar from './nudgeBar'
import moment from 'moment'
import ModalStore from '../../store/ModalStore'

const TomorrowEngagingWaterModal = observer(
  ({
    farmData,
    level1,
    level2,
    level3,
    level4,
    action = 'goBack',
    onClick,
    className = '',
    fetcher,
    setFarmData,
    token,
    text,
  }) => {
    const LevelAmount = () => {
      if (farmData < level1) {
        return (
          <>
            {level1}g 채우면 {level1 + 30}g 획득 가능
          </>
        )
      }
      if (farmData < level2 && farmData >= level1) {
        return (
          <>
            {level2}g 채우면 {level2 + 30}g 획득 가능
          </>
        )
      }
      if (farmData < level3 && farmData >= level2) {
        return (
          <>
            {level3}g 채우면 {level3 + 40}g 획득 가능
          </>
        )
      }
      if (farmData < level4 && farmData >= level3) {
        return (
          <>
            {level4}g 채우면 {level4 + 50}g 획득 가능
          </>
        )
      } else {
        return (
          <>
            {level4}g 채우면 {level4 + 50}g 획득 가능
          </>
        )
      }
    }
    const BottleImage = () => {
      if (farmData < level4) {
        return (
          <div
          // style={{
          //   position: "relative",
          //   zIndex: 100,
          // }}
          >
            <NudgeBar
              text='매일 오전 7시부터 물이 조금씩 증발해요'
              bgColor='#F1F1F1'
              down
              bottom={-30}
              textColor='#616161'
              fon
              center
              width='80vw'
            />
            <div
              style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                width: '80%',
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                  top: '-45vw',
                }}
                alt=''
                src='/dailyGiveWater/shineBg.png'
                className='rotate-bg-shine'
              />
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                  width: '35%',
                  left: '30%',
                  top: '-20vw',
                }}
                src='/dailyGiveWater/waterBottleModalNew.png'
                alt=''
              />
              <div
                style={{
                  zIndex: 113,
                  fontSize: '10vw',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  width: '100%',
                  position: 'absolute',
                  fontFamily: 'maplestory',
                  textShadow:
                    '-1.8px 0 #3983C0, 0 1.8px #3983C0, 1.8px 0 #3983C0, 0 -1.8px #3983C0',
                  marginLeft: '-1vw',
                  top: '-8vw',
                }}
              >
                {farmData}g
              </div>

              <div
                style={{
                  position: 'absolute',
                  zIndex: 3,
                  textAlign: 'center',
                  width: '100%',
                  top: '30vw',
                  fontSize: '5vw',
                }}
                className='daily-water-text'
              >
                오늘 물을 많이 줄수록 <br />
                물을 더 많이 얻을 수 있어요 <br />
              </div>
              <div
                style={{
                  position: 'absolute',
                  zIndex: 3,
                  textAlign: 'center',
                  width: '100%',
                  top: '65vw',
                  fontSize: '5vw',
                }}
                className='daily-water-text'
              >
                <div className='mt-4'>
                  <LevelAmount />
                </div>
              </div>
            </div>
          </div>
        )
      }

      if (farmData >= level4)
        return (
          <div
          // style={{
          //   position: "relative",
          //   top: "25%",
          //   zIndex: 100,
          // }}
          >
            <NudgeBar
              text='매일 오전 7시부터 물이 조금씩 증발해요'
              bgColor='#F1F1F1'
              down
              top={50}
              textColor='#616161'
              center
              width='80vw'
            />
            <div
              style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                width: '80%',
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                  top: '-45vw',
                }}
                alt=''
                src='/dailyGiveWater/shineBg.png'
                className='rotate-bg-shine'
              />
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                  width: '35%',
                  left: '30%',
                  top: '-20vw',
                }}
                src='/dailyGiveWater/waterBottleModalNew.png'
                alt=''
              />

              <div
                style={{
                  zIndex: 113,
                  fontSize: '10vw',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  width: '100%',
                  position: 'absolute',
                  fontFamily: 'maplestory',
                  textShadow:
                    '-1.8px 0 #3983C0, 0 1.8px #3983C0, 1.8px 0 #3983C0, 0 -1.8px #3983C0',
                  top: '-6vw',
                }}
              >
                {farmData}g
              </div>

              <div
                style={{
                  position: 'absolute',
                  zIndex: 3,
                  textAlign: 'center',
                  width: '100%',
                  top: '30vw',
                  fontSize: '5vw',
                }}
                className='daily-water-text'
              >
                보너스 목표를 다 채웠어요! <br />
                내일 {farmData}g 받을 수 있어요
              </div>
            </div>
          </div>
        )
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <BottleImage />
          <button
            style={{
              flex: 1,
              position: 'absolute',
              textAlign: 'center',
              zIndex: 2,
              borderRadius: '99vw',
              width: '80%',
              padding: '4vw',
              background:
                'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, 330%)',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
              onClick()
            }}
          >
            <div
              style={{
                color: '#402C24',
                fontFamily: 'maplestory',
                fontSize: '5vw',
              }}
            >
              물 주기
            </div>
          </button>
          <button
            style={{
              width: '50%',
              top: '12%',
              right: '-30%',
              position: 'absolute',
              zIndex: 3,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
                width: '20%',
                // top: "72%",
                // left: "50%",
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
        </div>
      </>
    )
  },
)
export default TomorrowEngagingWaterModal
