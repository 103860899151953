import backendApis from '../../utils/backendApis'
import React, { useEffect, useState } from 'react'
import ModalStore from '../../store/ModalStore'

const NameMyItemModal = ({
  farmData,
  setFarmData,
  token,
  setInviteResultStatus,
}) => {
  const [isClicked, setIsClicked] = useState(false)

  const inputNameRef = React.useRef()

  useEffect(() => {
    logOpenedNameMyItemModal()
  }, [])

  const logOpenedNameMyItemModal = async () => {
    if (!farmData?.openedNameMyItemModal) {
      setFarmData({
        ...farmData,
        openedNameMyItemModal: new Date(),
      })
      await backendApis.updatelastUpdatedAt(token, 'PUT', {
        updateType: 'openedNameMyItemModal',
      })
    }
  }

  const updateMyItemName = async (itemNameTemp) => {
    const result = await backendApis.updateMyItemName(token, 'PUT', {
      myItemName: itemNameTemp,
    })
    if (result.status === 200) {
      setFarmData({
        ...farmData,
        myItemName: itemNameTemp,
      })
      ModalStore.setIsModalOpen('basic')
    } else {
      setInviteResultStatus('errorAlert')
      return false
    }
  }

  const ModalBody = () => {
    return (
      <div
        style={{
          ...styles.modalBody,
        }}
      >
        <div
          style={{
            paddingTop: '18vw',
            marginBottom: '-2vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontFamily: 'maplestory',
              fontSize: '4.5vw',
              color: 'black',
              paddingBottom: '1vw',
            }}
          >
            작물의 이름을 지어보세요!
          </div>
          <div
            style={{
              fontFamily: 'maplestory',
              fontSize: '4.2vw',
              color: 'black',
            }}
          >
            이름을 지어주면 좋아할거예요 :)
          </div>
        </div>
        <input
          maxLength={6}
          style={{
            alignContent: 'center',
            width: '90%',
            height: '12vw',
            paddingRight: '4vw',
            paddingLeft: '4vw',
            borderRadius: '2vw',
            color: 'black',
            fontFamily: 'maplestory',
            fontSize: '3.6vw',
            zIndex: 5,
          }}
          type='text'
          placeholder={
            !farmData?.myItemName || farmData?.myItemName.length === 0
              ? `최대 6자까지 가능해요 `
              : `${farmData.myItemName}`
          }
          value={inputNameRef.current}
          onChange={(e) => {
            inputNameRef.current = e.target.value
          }}
        />

        <div style={{ width: '90%', paddingBottom: '2vw' }}>
          <button
            onClick={() => {
              if (inputNameRef?.current?.length > 0) {
                updateMyItemName(inputNameRef.current)
              } else {
                setInviteResultStatus('nameLength')
              }
            }}
            style={{
              alignContent: 'center',
              marginLeft: '0vw',
              width: '100%',
              height: '12vw',
              borderRadius: '6vw',
              color: 'black',
              backgroundColor: '#9FDA54',
              fontFamily: 'maplestory',
              fontSize: '4.5vw',
              zIndex: 2000,
              borderWidth: '0.5vw',
            }}
          >
            이름짓기
          </button>
        </div>
      </div>
    )
  }

  const ModalFrame = () => {
    return (
      <>
        <div
          style={{
            ...styles.background,
          }}
        />
        <div
          style={{
            ...styles.modalFrame,
          }}
        >
          <button
            style={{
              width: '8%',
              top: '-6vw',
              right: '10vw',
              zIndex: 102,
              position: 'absolute',
              cursor: 'pointer',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img src='/icon/modalCloseButton.png' alt='' />
          </button>
          <ModalBody />
        </div>
      </>
    )
  }

  return <ModalFrame />
}

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 5000,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
  modalFrame: {
    backgroundImage: 'url(../icon/nameMyItemModalFrame.png)',
    width: '100vw',
    height: '75vw',
    borderRadius: '4vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -55%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 5001,
    padding: 16,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  modalBody: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 100,
    paddingLeft: '5%',
    paddingRight: '5%',
    width: '80%',
    height: '100%',
    justifyContent: 'space-between',
  },
}

export default NameMyItemModal
