import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import NudgeBar from '../nudgeBar'
import ModalStore from '../../../store/ModalStore'
import backendApis from '../../../utils/backendApis'

const YesterdayGiveWaterModal = observer(
  ({
    farmData,
    setCanGetDailyGiveWater,
    reSetDailyGiveWater,
    setFarmData,
    token,
    yesterdayGiveWater,
    removedWater,
  }) => {
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'fixed',
              zIndex: 999,
              top: '26%',
              width: '85%',
              left: '7.5%',
            }}
          >
            {removedWater > 0 && (
              <NudgeBar
                text={`오전 7시부터 물 ${removedWater}g이 증발했어요`}
                bgColor='#F1F1F1'
                down
                bottom={220}
                textColor='#616161'
                // center
                width='80vw'
                right={-40}
                triangleRight={144}
              />
            )}
          </div>

          <div
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              width: '100%',
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
                top: '-45vw',
                width: '80%',
                left: '10%',
              }}
              alt=''
              src='/dailyGiveWater/shineBg.png'
              className='rotate-bg-shine'
            />
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
                width: '30%',
                left: '35%',
                top: '-22.5vw',
              }}
              src='/dailyGiveWater/waterBottleModalNew.png'
              alt=''
            />
            <div
              style={{
                zIndex: 113,
                fontSize: '10vw',
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                position: 'absolute',
                fontFamily: 'maplestory',
                textShadow:
                  '-1.8px 0 #3983C0, 0 1.8px #3983C0, 1.8px 0 #3983C0, 0 -1.8px #3983C0',
                top: '-8vw',
              }}
            >
              {yesterdayGiveWater}g
            </div>
            <div
              style={{
                position: 'absolute',
                zIndex: 3,
                textAlign: 'center',
                width: '100%',
                top: '30vw',
              }}
              className='daily-water-text'
            >
              <div
                style={{
                  fontSize: '6vw',
                }}
              >
                어제 채운 물 {yesterdayGiveWater}g을 받을 수 있어요
              </div>
            </div>

            <button
              style={{
                width: '70%',
                left: '50%',
                top: '50vw',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                zIndex: 999,
                borderRadius: '44vw',
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: '#402C24',
                fontSize: '6vw',
                paddingTop: '4vw',
                paddingBottom: '4vw',
              }}
              onClick={() => {
                reSetDailyGiveWater()
                ModalStore.setIsModalOpen('basic')
              }}
            >
              물 받기
            </button>
          </div>
        </div>
      </>
    )
  },
)

export default YesterdayGiveWaterModal
