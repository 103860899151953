import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import WhiteTextTimer from '../../comps/WhiteTextTimer'
import AB from '../../utils/ab'
import ModalStore from '../../store/ModalStore'
import UserStore from '../../store/UserStore'

const InviteButton = ({ isFilled = false, shareKakao, userImage }) => {
  return isFilled ? (
    <img
      style={{
        width: '15vw',
        height: '15vw',
        borderRadius: 55,
        marginRight: 8,
        marginTop: '0.5vw',
      }}
      src={userImage}
      onError={(e) => {
        e.target.src = UserStore?.default_profile_image
      }}
    />
  ) : (
    <div
      style={{
        width: '15vw',
        height: '15vw',
        backgroundImage: 'url(../lastMinuteInvite/invite.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      onClick={() => shareKakao()}
    />
  )
}

const LastMinuteInviteModal = observer(
  ({
    token,
    farmData,
    setFarmData,
    userCodePushVersion,
    codePushVersionAvailable,
  }) => {
    const [isFilled, setIsFilled] = useState(false)
    const [userImage, setUserImage] = useState()
    const [infoModal, setInfoModal] = useState()
    const shareImageType = AB(farmData?.userId, '0219ksit', [30])
    const shareKakao = () => {
      const purpose = {
        purpose: 'kakaoShare',
        referralCode: farmData?.referralCode,
        shareType: `AlfarmLastMinuteInvite`,
        title: `[올팜] 농작물을 키우면 집으로 보내드려요.`,
        desc: `원하는 농작물을 키우고 공짜로 받아보세요!`,
        imageSrc:
          shareImageType === 'a'
            ? 'https://assets.ilevit.com/27f7985b-ecbc-498c-99f6-68855d14632c.png'
            : 'https://assets.ilevit.com/7ab3f811-7cfb-4108-a3b9-d9b3bfc13867.png',
      }
      window.location.href = `#alfarmLastMinuteInvite.${JSON.stringify(
        purpose,
      )}`
    }
    const copyLink = () => {
      const purpose = {
        purpose: 'copyLink',
      }
      window.location.href = `#alfarmLastMinuteInvite.${JSON.stringify(
        purpose,
      )}`
    }

    useEffect(() => {
      if (farmData?.lastMinuteInviteInfo?.inviteeList?.length > 0) {
        setIsFilled(true)
        setUserImage(
          farmData?.lastMinuteInviteInfo?.inviteeList?.[0]?.userImage,
        )
      }
    }, [farmData])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage: 'url(../lastMinuteInvite/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '57vw',
              paddingBottom: '16%',
              // marginBottom: "15%",
            }}
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: '20vw',
                position: 'absolute',
                zIndex: 999,
                // borderRadius: "1px solid black",
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                // border: "1px solid black",
                width: '100%',
                height: '100%',
                // marginBottom: "12vw",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                // border: "1px solid black",
              }}
            >
              <div
                style={{
                  color: 'black',
                  fontFamily: 'maplestory',
                  fontSize: '5vw',
                  marginTop: '2vw',
                }}
              >
                새친구를 초대하면 작물을 바로 받아요!
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '4vw',
                }}
              >
                <WhiteTextTimer
                  // onTimeEnd={() => setOpenLastMinuteInviteModal(false)}
                  timeStamp={
                    new Date(farmData?.lastMinuteInviteInfo?.createdAt)
                  }
                  timerMinutes={60 * 24 * 3}
                  interval={1000}
                  color='#442b22'
                  style={{
                    marginTop: '-2vw',
                    color: '#442b22',
                    fontFamily: 'Maplestory',
                    padding: 0,
                    margin: 0,
                  }}
                  noDays={true}
                />
                <div
                  style={{
                    color: 'black',
                    fontFamily: 'maplestory',
                    marginLeft: 4,
                  }}
                >
                  후에 이벤트가 종료돼요
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  paddingLeft: '10%',
                  paddingRight: '10%',

                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <InviteButton
                  isFilled={isFilled}
                  shareKakao={() => shareKakao()}
                  userImage={userImage}
                />
                <InviteButton shareKakao={() => shareKakao()} />
              </div>
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  fontSize: '4vw',
                  lineHeight: '150%',
                }}
              >
                올팜을 안 해본 친구를 초대하면
                <br />
                작물을 바로 받을 수 있어요
              </div>

              <div
                style={{
                  background:
                    'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
                  width: '60vw',
                  height: '14vw',
                  borderRadius: 99,
                  color: 'black',
                  fontFamily: 'Maplestory',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '5vw',
                }}
                onClick={() => {
                  shareKakao()
                }}
              >
                초대하기
              </div>
              <button
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  fontSize: '4vw',
                  marginBottom: '0vw',
                  marginTop: '-3vw',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  setInfoModal(true)
                }}
              >
                안내사항
              </button>
              {/* {codePushVersionAvailable(userCodePushVersion, "1.6.24") && (
                <div
                  style={{
                    // background:
                    // "linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)",

                    width: "60vw",
                    // height: "14vw",
                    paddingBottom: "8px",
                    marginBottom: "8px",
                    borderRadius: 99,
                    color: "black",
                    fontFamily: "Maplestory",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                  }}
                  onClick={() => {
                    copyLink();
                  }}
                >
                  초대 링크 복사하기
                </div>
              )} */}
            </div>
          </div>
        </div>
        {infoModal && (
          <>
            <div
              style={{
                flex: 1,
                position: 'fixed',
                left: '10%',
                top: '45vw',
                zIndex: 2000,
                width: '80%',
                background: 'rgba(0,0,0,0.9)',
                borderRadius: 20,
                fontFamily: 'maplestory',
                fontSize: '4vw',
                padding: '6vw',
                paddingTop: '8vw',
                lineHeight: '170%',
                paddingBottom: '8vw',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                안내사항
              </div>
              <div style={{ height: '3vw' }} />- 올팜을 아직 안 해본 친구 2명을
              초대하면 작물을 바로 받을 수 있어요 <br />- 친구를 초대해도 적용이
              되지 않는 경우 작물을 선택하고 친구가 보낸 초대 링크를 다시
              클릭해주세요 <br /> - 혹은 좌측 하단의 피드 {'>'} 중앙 상단의
              초대코드 입력하기 {'>'} 친구의 링크에 있는 초대코드 입력을 통해서
              초대 보상을 적용할 수 있어요
              <br /> - 올웨이즈 탈퇴 이력이 있는 경우 초대할 수 없어요 <br />-
              정상적이지 않은 방법으로 친구를 초대하는 경우 작물 발송이 제한될
              수 있어요 <br />- 이벤트는 사전 고지 없이 변경 및 종료 될 수
              있어요
            </div>
            <button
              style={{
                width: '8%',
                top: '47vw',
                right: '14%',
                zIndex: 2001,
                position: 'absolute',
              }}
              onClick={() => {
                setInfoModal(false)
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseIcon.png'
                alt=''
              />
            </button>
          </>
        )}
      </>
    )
  },
)

export default LastMinuteInviteModal
