import React from 'react'

const GetAttendanceWaterFinalModal = ({ addedWater }) => {
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: '50%',
          top: '50%',
        }}
      >
        <div
          style={{
            backgroundColor: 'black',
            position: 'absolute',
            overlay: {
              background: 'rgba(0,0,0,0)',
              zIndex: 9999,
            },
            width: '50vw',
            height: '50vw',
            borderRadius: '15px',
            opacity: '0.8',
            transform: 'translate(-50%, -60%)',
          }}
        />

        <img
          src='/icon/attendaceFinalWater200.png'
          alt=''
          style={{
            width: '30vw',
            top: 0,
            height: 'auto',
            transform: 'translate(-50%, -65%)',
          }}
        />
      </div>
    </>
  )
}
export default GetAttendanceWaterFinalModal
