import AlfarmEventLogger from 'utils/alfarmEventLogger'
import ModalStore from '../../store/ModalStore'
import React, { useRef, useState } from 'react'

const GganbuIntroModal = (props) => {
  const {
    clearedAlwayzFarm,
    userId,
    token,
    itemType,
    gganbuData,
    isButtonPressedAlwayzFarm,
    setIsButtonPressedAlwayzFarm,
  } = props

  const throttleRef = useRef({})

  const shareKakao = () => {
    const inviteData = {
      shareType: 'AlfarmGganbuInvite',
      // referralCode: farmData?.referralCode,
      title: '[올팜] 우리 맞팜해요!',
      description: '서로의 농장에서 물과 비료를 받을 수 있어요.',
      bannerImage:
        'https://assets.ilevit.com/7ab3f811-7cfb-4108-a3b9-d9b3bfc13867.png',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 2000,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            position: 'relative',
            top: '25%',
            left: '10%',
          }}
        >
          <img
            style={{
              height: '89.09vw',
              width: '80vw',
              position: 'absolute',
            }}
            alt=''
            src={'/icon/gganbuFarmIntroModal.png'}
          />

          {!clearedAlwayzFarm && gganbuData?.gganbuList?.length === 0 ? (
            <a
              style={{}}
              href={`/alwayzFarm?token=${token}&itemType=${itemType}`}
            >
              <div
                style={{
                  position: 'absolute',
                  width: '10%',
                  right: '22vw',
                  bottom: '-69vw',
                }}
                className='wobble-ver-left'
              >
                <img src='/icon/leftScrollImage/fertBubblev2.png' alt='' />
              </div>
              <button
                style={{
                  width: '60%',
                  left: '10%',
                  fontFamily: 'maplestory',
                  color: 'black',
                  fontSize: '16px',
                  borderRadius: '30px',
                  padding: 16,
                  zIndex: 2000,
                  position: 'absolute',
                  top: '67vw',
                  background: isButtonPressedAlwayzFarm
                    ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                    : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                  boxShadow: isButtonPressedAlwayzFarm
                    ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                    : '',
                }}
                onPointerDown={() => {
                  setIsButtonPressedAlwayzFarm(true)
                }}
                onPointerCancel={() => {
                  setIsButtonPressedAlwayzFarm(false)
                }}
                onPointerUp={() => {
                  setIsButtonPressedAlwayzFarm(false)
                  AlfarmEventLogger({
                    throttleRef,
                    locationType: 'modal',
                    locationName: ModalStore.isModalOpen,
                    eventType: 'click',
                    eventName: 'cta',
                    data: { step: 0 },
                    collection: 'UserAlfarmClickLog',
                  })
                }}
              >
                맞팜 체험하기
              </button>
            </a>
          ) : (
            <button
              style={{
                width: '60%',
                left: '10%',
                fontFamily: 'maplestory',
                color: 'black',
                fontSize: '16px',
                borderRadius: '30px',
                padding: 16,
                zIndex: 2000,
                position: 'absolute',
                top: '67vw',
                background: isButtonPressedAlwayzFarm
                  ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                  : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                boxShadow: isButtonPressedAlwayzFarm
                  ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                  : '',
              }}
              // onPointerDown={() => {
              //   setIsButtonPressedAlwayzFarm(true);
              // }}
              // onPointerCancel={() => {
              //   setIsButtonPressedAlwayzFarm(false);
              // }}
              // onPointerUp={() => {
              //   setIsButtonPressedAlwayzFarm(false);
              // }}
              onClick={() => {
                shareKakao()
              }}
            >
              맞팜 초대하기
            </button>
          )}
        </div>
        <button
          style={{
            width: '8%',
            top: '19%',
            right: '12%',
            position: 'absolute',
            zIndex: 1113,
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      </div>
    </>
  )
}

export default GganbuIntroModal
