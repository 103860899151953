import ModalStore from '../../store/ModalStore'
import React from 'react'
import PlantName from './PlantName'
import UserStore from 'store/UserStore'
import { observer } from 'mobx-react-lite'
import Pet from 'comps/atoms/Pet'
import moment from 'moment'
import TimeStore from 'store/TimeStore'

const Plant = observer((props) => {
  const {
    farmData,
    farmingList,
    id,
    level,
    gganbuMode,
    itemType,
    nutrimentLeavesOn,
  } = props
  // 만약 깐부농장 돌아다닐 때 내가 테스트 그룹이 아니라면! 상대방 유저의 작물 이름이 보이면 안됨
  // 깐부농장 들어갈 때 farmData는 깐부 데이터여서, 내 데이터를 따로 받아줘야함. 그게 myFarmData

  if (gganbuMode) {
    return (
      <div className='relative'>
        <img
          className='mb-[4vw] z-[4]'
          alt=''
          src={`/farm/${farmingList[id]}/${level}.gif`}
        />
        <PlantName
          farmData={farmData}
          itemType={itemType}
          gganbuMode={gganbuMode}
        />
        {(farmData?.petData?.inviteHistory?.length ?? 0) >= 1 && (
          <Pet gganbuMode={gganbuMode} petIndex={0} />
        )}
        {(farmData?.petData?.inviteHistory?.length ?? 0) >= 2 && (
          <Pet gganbuMode={gganbuMode} petIndex={1} />
        )}
        {(farmData?.petData?.inviteHistory?.length ?? 0) >= 4 && (
          <Pet gganbuMode={gganbuMode} petIndex={2} />
        )}
        {(farmData?.petData?.inviteHistory?.length ?? 0) >= 7 && (
          <Pet gganbuMode={gganbuMode} petIndex={3} />
        )}
        {(farmData?.petData?.inviteHistory?.length ?? 0) >= 10 && (
          <Pet gganbuMode={gganbuMode} petIndex={4} />
        )}
        {(farmData?.petData?.inviteHistory?.length ?? 0) >= 15 && (
          <Pet gganbuMode={gganbuMode} petIndex={5} />
        )}
        {TimeStore.currentMoment?.isSameOrBefore(
          moment(farmData?.petData?.eventPetEndAt ?? '2023-01-01'),
        ) && <Pet gganbuMode={gganbuMode} petIndex={98} />}
      </div>
    )
  } else
    return (
      <div className='relative'>
        <img
          className='z-[4]'
          onClick={() => {
            ModalStore.setIsModalOpen('nextLevelReward')
          }}
          alt=''
          src={`/farm/${farmingList[id]}/${level}.gif`}
        />

        {farmData?.giveWaterCount >= 9 && (
          <PlantName
            farmData={farmData}
            itemType={itemType}
            gganbuMode={gganbuMode}
          />
        )}

        {farmData?.giveWaterCount >= 300 && nutrimentLeavesOn && (
          <div
            className='absolute top-[50vw] z-10 blinking'
            onClick={() => {
              ModalStore.setIsModalOpen('nutrimentLeavesV2')
            }}
          >
            <img
              className='w-[40vw] pulsate-fwd'
              alt='leaves'
              src='../nutrimentLeaves/leaves.png'
            />
          </div>
        )}
        {/* 펫 위치 */}
        {TimeStore.currentMoment?.isSameOrBefore(
          moment(UserStore?.farmData?.petData?.eventPetEndAt ?? '2023-01-01'),
        ) && <Pet gganbuMode={gganbuMode} petIndex={98} />}

        {TimeStore?.currentMoment?.isSameOrBefore(
          moment(UserStore?.farmData?.petData?.createdAt ?? '2023-01-01').add(
            3,
            'd',
          ),
        ) &&
          (UserStore?.farmData?.petData?.inviteHistory?.length ?? 0) < 7 && (
            <Pet petIndex={99} />
          )}

        {(UserStore?.farmData?.petData?.inviteHistory?.length ?? 0) >= 1 && (
          <Pet petIndex={0} />
        )}
        {(UserStore?.farmData?.petData?.inviteHistory?.length ?? 0) >= 2 && (
          <Pet petIndex={1} />
        )}
        {(UserStore?.farmData?.petData?.inviteHistory?.length ?? 0) >= 4 && (
          <Pet petIndex={2} />
        )}
        {(UserStore?.farmData?.petData?.inviteHistory?.length ?? 0) >= 7 && (
          <Pet petIndex={3} />
        )}
        {(UserStore?.farmData?.petData?.inviteHistory?.length ?? 0) >= 10 && (
          <Pet petIndex={4} />
        )}
        {(UserStore?.farmData?.petData?.inviteHistory?.length ?? 0) >= 15 && (
          <Pet petIndex={5} />
        )}
      </div>
    )
})

export default Plant
