import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import dayjs from 'dayjs'
import ModalStore from '../../store/ModalStore'
import AB from 'utils/ab'
import UserStore from 'store/UserStore'

const SevenDaysModal = observer(
  ({
    token,
    farmData,
    setFarmData,
    loadUserCoupon,
    getWelcomeQuestCheckInData,
    setCanGetSevendaysEventNotification,
  }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const [checkInCount, setCheckInCount] = useState(0)
    const [canCheckIn, setCanCheckIn] = useState(false)
    const [data, setData] = useState(false)
    const [now, setNow] = useState(new Date())
    const [untilAt, setUntilAt] = useState(10)

    const checkIn = async () => {
      setCanCheckIn(false)
      const result = await backendApis.newUserCheckIn(token)
      let tmpFarmData = farmData
      tmpFarmData.water += result?.data?.addedWater

      tmpFarmData.fertilizerSet += result?.data?.addedFertilizerSet
      setCanGetSevendaysEventNotification(false)
      setFarmData(tmpFarmData)
      setData(result?.data)
      loadUserCoupon()
      getWelcomeQuestCheckInData()
    }

    const checkInData = async () => {
      const result = await backendApis.checkInData(token)
      setCheckInCount(result?.data?.checkInCount)
      setCanCheckIn(result?.data?.success)
    }

    const ModalCloseButton = () => {
      return (
        <button
          style={{
            width: '8%',
            top: '10%',
            right: '5%',
            zIndex: 1101,
            position: 'absolute',
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              zIndex: 1101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      )
    }

    useEffect(() => {
      checkInData()
      setUntilAt(
        Math.floor(
          (dayjs(farmData?.createdAt) + 1000 * 60 * 60 * 24 * 10 - dayjs(now)) /
            (1000 * 60 * 60 * 24),
        ),
      )
    }, [data])

    const CheckButton = () => {
      if (!canCheckIn) {
        return (
          <button
            style={{
              position: 'absolute',
              background: 'linear-gradient(180deg, #EFDFD0 0%, #FBDEB9 100%)',
              color: '#402C24',
              fontSize: '18px',
              borderRadius: '30px',
              paddingTop: '14px',
              paddingBottom: '14px',
              zIndex: 1999,
              fontFamily: 'maplestory',
              width: '70%',
              top: '93vw',
              left: '15vw',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            오늘 출석 완료!
          </button>
        )
      } else if (checkInCount >= 7) {
        return (
          <button
            style={{
              position: 'absolute',
              background: 'linear-gradient(180deg, #EFDFD0 0%, #FBDEB9 100%)',
              color: '#402C24',
              fontSize: '18px',
              borderRadius: '30px',
              paddingTop: '14px',
              paddingBottom: '14px',
              zIndex: 1999,
              fontFamily: 'maplestory',
              width: '70%',
              top: '93vw',
              left: '15vw',
            }}
          >
            출석 이벤트를 완료했어요!
          </button>
        )
      } else if (canCheckIn && checkInCount <= 6) {
        return (
          <button
            style={{
              position: 'absolute',
              background: isButtonPressed
                ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              boxShadow: isButtonPressed
                ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                : '',
              color: '#402C24',
              fontSize: '18px',
              borderRadius: '30px',
              paddingTop: '14px',
              paddingBottom: '14px',
              zIndex: 1999,
              fontFamily: 'maplestory',
              width: '70%',
              top: '93vw',
              left: '15vw',
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
            onClick={checkIn}
          >
            오늘 출석하기
          </button>
        )
      }
    }
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1100,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)',
              left: 0,
              right: 0,
              zIndex: 1222,
            }}
          >
            <img
              style={{
                borderRadius: '3vw',
              }}
              src='/icon/sevenDays/sevenDaysBackground.png'
              alt=''
            />
            {untilAt === 0 ? (
              <div
                style={{
                  position: 'absolute',
                  fontFamily: 'maplestory',
                  color: 'black',
                  top: '5vw',
                  right: '15vw',
                }}
              >
                오늘까지예요!
              </div>
            ) : (
              <div
                style={{
                  position: 'absolute',
                  fontFamily: 'maplestory',
                  color: 'black',
                  top: '5vw',
                  right: '15vw',
                }}
              >
                {untilAt}일 남았어요!
              </div>
            )}

            <div
              style={{
                border: '2px solid #965F42',
                zIndex: 1105,
                position: 'absolute',
                top: '52%',
                transform: 'translateY(-50%)',
                // width: "100%",
                height: '55%',
                padding: 8,
                margin: 8,
                left: 0,
                right: 0,
                borderRadius: 16,
                backgroundColor: '#FCFEE9',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className='sevenDaysModalDiv'
            >
              {checkInCount >= 1 ? (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-first-icon'
                    src='/icon/sevenDays/firstIconDone.png'
                    alt=''
                  />
                </div>
              ) : (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-first-icon'
                    src='/icon/sevenDays/firstIcon.png'
                    alt=''
                  />
                </div>
              )}
              {checkInCount >= 2 ? (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-second-icon'
                    src='/icon/sevenDays/secondIconDone.png'
                    alt=''
                  />
                </div>
              ) : (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-second-icon'
                    src='/icon/sevenDays/secondIcon.png'
                    alt=''
                  />
                </div>
              )}
              {checkInCount >= 3 ? (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-third-icon'
                    src='/icon/sevenDays/thirdIconDone.png'
                    alt=''
                  />
                </div>
              ) : (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-third-icon'
                    src='/icon/sevenDays/thirdIcon.png'
                    alt=''
                  />
                </div>
              )}
              {checkInCount >= 4 ? (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-fourth-icon'
                    src='/icon/sevenDays/fourthIconDone.png'
                    alt=''
                  />
                </div>
              ) : (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-fourth-icon'
                    src='/icon/sevenDays/fourthIcon.png'
                    alt=''
                  />
                </div>
              )}
              {checkInCount >= 5 ? (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-fifth-icon'
                    src='/icon/sevenDays/fifthIconDone.png'
                    alt=''
                  />
                </div>
              ) : (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-fifth-icon'
                    src='/icon/sevenDays/fifthIcon.png'
                    alt=''
                  />
                </div>
              )}
              {checkInCount >= 6 ? (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-sixth-icon'
                    src='/icon/sevenDays/sixthIconDone.png'
                    alt=''
                  />
                </div>
              ) : (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-sixth-icon'
                    src='/icon/sevenDays/sixthIcon.png'
                    alt=''
                  />
                </div>
              )}
              {checkInCount >= 7 ? (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-seventh-icon'
                    src='/icon/sevenDays/seventhIconDone.png'
                    alt=''
                  />
                </div>
              ) : (
                <div>
                  <img
                    style={{
                      zIndex: 1105,
                    }}
                    className='seven-days-seventh-icon'
                    src='/icon/sevenDays/seventhIcon.png'
                    alt=''
                  />
                </div>
              )}
            </div>
            {CheckButton()}
          </div>
          {ModalCloseButton()}
        </div>
      </>
    )
  },
)

export default SevenDaysModal
