import React, { useState, useEffect } from 'react'

const totalFrames = 60 // 총 프레임 수
const frameRate = 2300 / 60 // 프레임 속도

const imageStyle = {
  width: '70vw',
  height: '70vw',
  position: 'relative',
}

const YutThrowAnimation = () => {
  const [frameIndex, setFrameIndex] = useState(0)
  const [imagesLoaded, setImagesLoaded] = useState(false)

  const preloadImages = () => {
    let loadedImages = 0
    const imageObjects = []

    for (let i = 0; i < totalFrames; i++) {
      const image = new Image()
      image.onload = () => {
        loadedImages++
        if (loadedImages === totalFrames) {
          setImagesLoaded(true)
        }
      }
      image.src = `/minigame/yutnori/yutThrowMotion/yutThrow${i
        .toString()
        .padStart(2, '0')}.png`
      imageObjects.push(image)
    }
  }

  useEffect(() => {
    preloadImages()
  }, [])

  useEffect(() => {
    let timer
    if (imagesLoaded && frameIndex < totalFrames - 1) {
      timer = setTimeout(() => {
        setFrameIndex(frameIndex + 1)
      }, frameRate)
    }

    return () => clearTimeout(timer)
  }, [frameIndex, imagesLoaded])

  const frameImage = `/minigame/yutnori/yutThrowMotion/yutThrow${frameIndex
    .toString()
    .padStart(2, '0')}.png`

  return (
    <div>
      {imagesLoaded && (
        <img src={frameImage} alt='윷을 던지고 있어요!' style={imageStyle} />
      )}
    </div>
  )

  // useEffect(() => {
  //   if (frameIndex < totalFrames - 1) {
  //     const timer = setTimeout(() => {
  //       setFrameIndex(frameIndex + 1);
  //     }, frameRate);

  //     return () => clearTimeout(timer);
  //   }
  // }, [frameIndex]);

  // const frameImage = `/minigame/yutnori/yutThrowMotion/yutThrow${frameIndex
  //   .toString()
  //   .padStart(2, "0")}.png`;

  // return <img src={frameImage} alt="윷을 던지고 있어요!" style={imageStyle} />;
}

export default YutThrowAnimation
