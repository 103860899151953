import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'
import backendApis from 'utils/backendApis'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const SelectOnePurchaseModal = observer(({ token, farmData }) => {
  const [clickedRewardFert, setClickedRewardFert] = useState(false)
  const [clickedRewardWater, setClickedRewardWater] = useState(false)
  const [clickedMotion, setClickedMotion] = useState(false)
  const [clickedMotion2, setClickedMotion2] = useState(false)
  const throttleRef = useRef({})

  const selectReward = async (selectedReward) => {
    AlfarmEventLogger({
      throttleRef,
      locationType: 'modal',
      locationName: ModalStore.isModalOpen,
      eventType: 'click',
      eventName: `cta_${selectedReward}`,
      data: {},
      collection: 'UserAlfarmClickLog',
    })
    if (selectedReward === 1) {
      setTimeout(() => {
        setClickedRewardFert(true)
      }, 500)
      const purchaseData = {
        enteringComponent: 'Alfarm_selectOnePurchaseFertilizer',
        text: '올팜 골라 사기',
        source:
          'https://assets.ilevit.com/0060895c-ff46-43e9-8dad-7b6243686996.png',
        // rewardAmount: 1,
        // rewardType: "물 2,000g",
        rewardText: '상품을 구매하면 고급비료 2개, 일반비료 6개를 받아요',
      }
      window.location.href = `#commonPurchaseReward.${JSON.stringify(
        purchaseData,
      )}`
      ModalStore.setIsModalOpen('basic')
    }

    if (selectedReward === 2) {
      setTimeout(() => {
        setClickedRewardWater(true)
      }, 500)
      const purchaseData = {
        enteringComponent: 'Alfarm_selectOnePurchaseWater',
        text: '올팜 골라 사기',
        source:
          'https://assets.ilevit.com/5e990bee-0214-4971-9c62-c875cfa0eb08.png',
        // rewardAmount: 1,
        // rewardType: "물 2,000g",
        rewardText: '상품을 구매하면 물 1,000g, 일반비료 2개를 받아요',
      }
      window.location.href = `#commonPurchaseReward.${JSON.stringify(
        purchaseData,
      )}`
      ModalStore.setIsModalOpen('basic')
    }
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1112,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      />
      <div
        style={{
          zIndex: 1112,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          width: '100%',
        }}
      >
        <img
          style={{
            zIndex: 122,
            marginTop: '-30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            width: '100%',
          }}
          src='/selectOnePurchase/title.png'
          alt='abc'
        />

        <img
          style={{
            zIndex: 122,
            marginTop: '12.5%',
            left: '25%',
            transform: `${
              clickedMotion
                ? 'scale(0.97) translate(-50%, -50%)'
                : 'translate(-50%, -50%)'
            }`,
            position: 'absolute',
            width: '45%',
          }}
          src='/selectOnePurchase/fert.png'
          alt='abc'
          onClick={() => {
            setClickedMotion(true)
            setTimeout(() => {
              setClickedMotion(false)
            }, 200)
            selectReward(1)
          }}
        />

        <img
          style={{
            zIndex: 122,
            marginTop: '12.5%',
            left: '75%',
            transform: `${
              clickedMotion2
                ? 'scale(0.97) translate(-50%, -50%)'
                : 'translate(-50%, -50%)'
            }`,
            position: 'absolute',
            width: '45%',
          }}
          src='/selectOnePurchase/water.png'
          alt='abc'
          onClick={() => {
            //
            setClickedMotion2(true)
            setTimeout(() => {
              setClickedMotion2(false)
            }, 200)
            selectReward(2)
          }}
        />

        <button
          style={{
            width: '8%',
            marginTop: '-50%',
            right: '15%',
            position: 'absolute',
            zIndex: 1113,
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: 122,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      </div>
    </>
  )
})

export default SelectOnePurchaseModal
