import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import WhiteTextTimer from '../WhiteTextTimer'
import ModalStore from '../../store/ModalStore'

const ProduceFertMachineModal = observer(
  ({
    produceFertMachineHistory,
    token,
    onClick,
    farmData,
    setFarmData,
    setProducingFertMachine,
    setResourceType,
    setShowPopup,
    setAddFertilizer,
  }) => {
    const [giveWaterButtonPressed, setGiveWaterButtonPressed] = useState(false)
    const [canGetFert, setCanGetFert] = useState(false)

    useEffect(() => {
      if (new Date() >= new Date(produceFertMachineHistory?.[0]?.endsAt)) {
        setCanGetFert(true)
      }
      if (produceFertMachineHistory?.[0]?.isEnded) {
        ModalStore.setIsModalOpen('basic')
        setProducingFertMachine(false)
      }
    }, [])

    const receiveProducedFertFromMachine = async () => {
      const result = await backendApis.receiveProducedFertFromMachine(token)
      if (result?.status === 200) {
        let tmpFarmData = farmData
        tmpFarmData.fertilizer += result?.data?.addedValue
        setFarmData(tmpFarmData)
        if (result?.data?.addedValue >= 1) {
          setResourceType('fertilizer')
          setAddFertilizer(result?.data?.addedValue)
          setShowPopup(true)
        }
      }

      setProducingFertMachine(false)
    }

    const purchaseButton = () => {
      const purchaseData = {
        enteringComponent: 'Alfarm_produceFertMachine',
        text: '비료 생산기',
        source:
          'https://assets.ilevit.com/ee50691e-6fa7-4bae-888d-5573689fdfcf.png',
        rewardAmount: Math.min(
          Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10),
          8,
        ),
        rewardType: '일반비료',
        rewardText: `상품을 구매하면 일반비료 ${Math.min(
          Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10),
          8,
        )}개를 받아요`,
      }

      window.location.href = `#commonPurchaseReward.${JSON.stringify(
        purchaseData,
      )}`
    }

    const description = () => {
      if (
        Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10) >= 1 &&
        canGetFert
      ) {
        return (
          <div
            style={{
              fontFamily: 'maplestory',
              fontSize: '5vw',
              top: '26%',
              position: 'absolute',
              width: '100%',
            }}
          >
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              만든 비료를 받을 수 있어요
            </div>
            <div style={{ height: '2vw' }} />

            <>
              <div style={{ height: '2vw' }} />
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                버튼을 눌러 비료를 받아보세요
              </div>
              <div style={{ height: '2vw' }} />
            </>
          </div>
        )
      } else if (
        Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10) < 1 &&
        !canGetFert
      ) {
        return (
          <div
            style={{
              fontFamily: 'maplestory',
              fontSize: '5vw',
              top: '26%',
              position: 'absolute',
              width: '100%',
            }}
          >
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              물을 주고 비료를 만들어 보세요
            </div>
            <div style={{ height: '2vw' }} />

            <>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {Math.floor(
                  10 - (produceFertMachineHistory?.[0]?.waterCount % 10),
                )}
                번 주면 비료가 1개 더 만들어져요
              </div>
              <div style={{ height: '2vw' }} />
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                계속해서 물을 주면, 비료
                <div style={{ color: '#DDBF6B' }}>&nbsp;8개</div>를 받을 수
                있어요
              </div>
            </>
          </div>
        )
      } else if (
        Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10) < 8 &&
        Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10) >= 1 &&
        !canGetFert
      ) {
        return (
          <div
            style={{
              fontFamily: 'maplestory',
              fontSize: '5vw',
              top: '26%',
              position: 'absolute',
              width: '100%',
            }}
          >
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              물을 줘서 비료를&nbsp;
              <div style={{ color: '#DDBF6B' }}>
                {Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10)}
                개&nbsp;
              </div>
              만들었어요
            </div>
            <div style={{ height: '2vw' }} />

            <>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {Math.floor(
                  10 - (produceFertMachineHistory?.[0]?.waterCount % 10),
                )}
                번 주면 비료가 1개 더 만들어져요
              </div>
              <div style={{ height: '2vw' }} />
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                계속해서 물을 주면, 비료
                <div style={{ color: '#DDBF6B' }}>&nbsp;8개</div>를 받을 수
                있어요
              </div>
            </>
          </div>
        )
      } else if (
        Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10) >= 8 &&
        !canGetFert
      ) {
        return (
          <div
            style={{
              fontFamily: 'maplestory',
              fontSize: '5vw',
              top: '26%',
              position: 'absolute',
              width: '100%',
            }}
          >
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              물을 줘서 비료를&nbsp;
              <div style={{ color: '#DDBF6B' }}>8개</div>
              만들었어요
            </div>
            <div style={{ height: '2vw' }} />

            <>
              <div style={{ height: '2vw' }} />
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                물건을 구매하거나 시간이 지나면
              </div>
              <div style={{ height: '2vw' }} />
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                만든 비료를 받을 수 있어요
              </div>
            </>
          </div>
        )
      }
    }
    const makeFertButton = () => {
      if (produceFertMachineHistory?.[0]?.waterCount < 80 && !canGetFert) {
        return (
          <div>
            <button
              style={{
                flex: 1,
                position: 'absolute',
                left: '15%',
                top: '80vw',
                right: '0',
                fontSize: 18,
                textAlign: 'center',
                zIndex: 100,
                borderRadius: 30,
                width: '70%',
                padding: '2vw',
                background: giveWaterButtonPressed
                  ? 'linear-gradient(180deg, #44DC26 1.57%, #43E723 31.77%, #4BDD2E 64.58%, #1CB53D 100%)'
                  : 'linear-gradient(180deg, #88E879 0%, #6CC741 100%)',
                boxShadow: giveWaterButtonPressed
                  ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                  : '',
              }}
              onPointerDown={() => {
                setGiveWaterButtonPressed(true)
              }}
              onPointerCancel={() => {
                setGiveWaterButtonPressed(false)
              }}
              onPointerUp={() => {
                setGiveWaterButtonPressed(false)
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
                onClick()
              }}
            >
              <div
                style={{
                  color: '#402C24',
                  fontFamily: 'maplestory',
                  fontSize: 18,
                }}
              >
                물주고 비료 만들기
              </div>
            </button>
          </div>
        )
      } else if (
        produceFertMachineHistory?.[0]?.waterCount >= 80 &&
        !canGetFert
      ) {
        return (
          <div>
            <button
              style={{
                flex: 1,
                position: 'absolute',
                left: '15%',
                top: '80vw',
                right: '0',
                fontSize: 18,
                textAlign: 'center',
                zIndex: 100,
                borderRadius: 30,
                width: '70%',
                padding: '2vw',
                background: giveWaterButtonPressed
                  ? 'linear-gradient(180deg, #44DC26 1.57%, #43E723 31.77%, #4BDD2E 64.58%, #1CB53D 100%)'
                  : 'linear-gradient(180deg, #88E879 0%, #6CC741 100%)',
                boxShadow: giveWaterButtonPressed
                  ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                  : '',
              }}
              onPointerDown={() => {
                setGiveWaterButtonPressed(true)
              }}
              onPointerCancel={() => {
                setGiveWaterButtonPressed(false)
              }}
              onPointerUp={() => {
                setGiveWaterButtonPressed(false)
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <div
                style={{
                  color: '#402C24',
                  fontFamily: 'maplestory',
                  fontSize: '5vw',
                }}
              >
                잠시 후에 비료 받기
              </div>
            </button>
          </div>
        )
      } else if (
        produceFertMachineHistory?.[0]?.waterCount >= 10 &&
        produceFertMachineHistory?.[0]?.waterCount <= 80 &&
        canGetFert
      ) {
        return (
          <div>
            <button
              style={{
                flex: 1,
                position: 'absolute',
                left: '15%',
                top: '80vw',
                right: '0',
                fontSize: 18,
                textAlign: 'center',
                zIndex: 100,
                borderRadius: 30,
                width: '70%',
                padding: '2vw',
                background: giveWaterButtonPressed
                  ? 'linear-gradient(180deg, #44DC26 1.57%, #43E723 31.77%, #4BDD2E 64.58%, #1CB53D 100%)'
                  : 'linear-gradient(180deg, #88E879 0%, #6CC741 100%)',
                boxShadow: giveWaterButtonPressed
                  ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                  : '',
              }}
              onPointerDown={() => {
                setGiveWaterButtonPressed(true)
              }}
              onPointerCancel={() => {
                setGiveWaterButtonPressed(false)
              }}
              onPointerUp={() => {
                setGiveWaterButtonPressed(false)
              }}
              onClick={() => {
                receiveProducedFertFromMachine()
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <div
                style={{
                  color: '#402C24',
                  fontFamily: 'maplestory',
                  fontSize: 18,
                }}
              >
                비료{' '}
                {Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10)}개
                받기
              </div>
            </button>
          </div>
        )
      } else if (
        produceFertMachineHistory?.[0]?.waterCount > 80 &&
        canGetFert
      ) {
        return (
          <div>
            <button
              style={{
                flex: 1,
                position: 'absolute',
                left: '15%',
                top: '80vw',
                right: '0',
                fontSize: 18,
                textAlign: 'center',
                zIndex: 100,
                borderRadius: 30,
                width: '70%',
                padding: '2vw',
                background: giveWaterButtonPressed
                  ? 'linear-gradient(180deg, #44DC26 1.57%, #43E723 31.77%, #4BDD2E 64.58%, #1CB53D 100%)'
                  : 'linear-gradient(180deg, #88E879 0%, #6CC741 100%)',
                boxShadow: giveWaterButtonPressed
                  ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                  : '',
              }}
              onPointerDown={() => {
                setGiveWaterButtonPressed(true)
              }}
              onPointerCancel={() => {
                setGiveWaterButtonPressed(false)
              }}
              onPointerUp={() => {
                setGiveWaterButtonPressed(false)
              }}
              onClick={() => {
                receiveProducedFertFromMachine()
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <div
                style={{
                  color: '#402C24',
                  fontFamily: 'maplestory',
                  fontSize: 18,
                }}
              >
                비료 8개 받기
              </div>
            </button>
          </div>
        )
      } else if (
        produceFertMachineHistory?.[0]?.waterCount < 10 &&
        canGetFert
      ) {
        return (
          <div>
            <button
              style={{
                flex: 1,
                position: 'absolute',
                left: '15%',
                top: '80vw',
                right: '0',
                fontSize: 18,
                textAlign: 'center',
                zIndex: 100,
                borderRadius: 30,
                width: '70%',
                padding: '2vw',
                background: giveWaterButtonPressed
                  ? 'linear-gradient(180deg, #44DC26 1.57%, #43E723 31.77%, #4BDD2E 64.58%, #1CB53D 100%)'
                  : 'linear-gradient(180deg, #88E879 0%, #6CC741 100%)',
                boxShadow: giveWaterButtonPressed
                  ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
                  : '',
              }}
              onPointerDown={() => {
                setGiveWaterButtonPressed(true)
              }}
              onPointerCancel={() => {
                setGiveWaterButtonPressed(false)
              }}
              onPointerUp={() => {
                setGiveWaterButtonPressed(false)
              }}
              onClick={() => {
                receiveProducedFertFromMachine()
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <div
                style={{
                  color: '#402C24',
                  fontFamily: 'maplestory',
                  fontSize: 18,
                }}
              >
                다음에 다시 비료 만들기
              </div>
            </button>
          </div>
        )
      }
    }

    const purchaseFertButton = () => {
      if (
        produceFertMachineHistory?.[0]?.waterCount < 80 &&
        produceFertMachineHistory?.[0]?.waterCount >= 10 &&
        new Date(produceFertMachineHistory?.[0]?.endsAt) >= new Date()
      ) {
        return (
          <div>
            <button
              style={{
                flex: 1,
                position: 'absolute',
                left: '15%',
                top: '96vw',
                // top: "160vw",
                right: '0',
                fontSize: 18,
                textAlign: 'center',
                zIndex: 100,
                borderRadius: 30,
                width: '70%',
                padding: '2vw',
                border: '2px solid #56C002',
              }}
              onClick={() => {
                purchaseButton()
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontFamily: 'maplestory',
                  fontSize: 18,
                }}
              >
                물건 구매하고 비료{' '}
                {Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10)}개
                바로 받기
              </div>
            </button>
          </div>
        )
      } else if (
        produceFertMachineHistory?.[0]?.waterCount >= 80 &&
        new Date(produceFertMachineHistory?.[0]?.endsAt) >= new Date()
      ) {
        return (
          <div>
            <button
              style={{
                flex: 1,
                position: 'absolute',
                left: '15%',
                top: '96vw',
                // top: "160vw",
                right: '0',
                fontSize: 18,
                textAlign: 'center',
                zIndex: 100,
                borderRadius: 30,
                width: '70%',
                padding: '2vw',
                border: '2px solid #56C002',
              }}
              onPointerDown={() => {
                setGiveWaterButtonPressed(true)
              }}
              onPointerCancel={() => {
                setGiveWaterButtonPressed(false)
              }}
              onPointerUp={() => {
                setGiveWaterButtonPressed(false)
              }}
              onClick={() => {
                purchaseButton()
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontFamily: 'maplestory',
                  fontSize: '4.5vw',
                }}
              >
                물건 구매하고 비료 8개 바로 받기
              </div>
            </button>
          </div>
        )
      } else if (produceFertMachineHistory?.[0]?.waterCount < 10) {
        return <></>
      }
    }
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 2000,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          {description()}
          <div
            style={{
              position: 'relative',
              top: '30%',
            }}
          >
            {Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10) < 8 ? (
              <div
                style={{
                  fontFamily: 'maplestory',
                  fontSize: '5vw',
                  position: 'absolute',
                  width: '15vw',
                  left: '42.5%',
                  textAlign: 'center',
                  top: '53vw',
                  zIndex: 333,
                  background: '#EA3323',
                  color: 'white',
                  borderRadius: '5vw',
                  paddingTop: '1vw',
                  paddingBottom: '1vw',
                  paddingRight: '2vw',
                  paddingLeft: '2vw',
                }}
              >
                x {Math.floor(produceFertMachineHistory?.[0]?.waterCount / 10)}
              </div>
            ) : (
              <div
                style={{
                  fontFamily: 'maplestory',
                  fontSize: '5vw',
                  position: 'absolute',
                  width: '15vw',
                  left: '42.5%',
                  textAlign: 'center',
                  top: '53vw',
                  zIndex: 333,
                  background: '#EA3323',
                  color: 'white',
                  borderRadius: '5vw',
                  paddingTop: '1vw',
                  paddingBottom: '1vw',
                  paddingRight: '2vw',
                  paddingLeft: '2vw',
                }}
              >
                x 8
              </div>
            )}

            {!canGetFert && (
              <div
                style={{
                  flex: 1,
                  top: '70vw',
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                  zIndex: 2001,
                  color: 'white',
                  fontFamily: 'maplestory',
                  fontSize: '5vw',
                }}
              >
                <WhiteTextTimer
                  onTimeEnd={() => {
                    setCanGetFert(true)
                  }}
                  timeStamp={new Date(produceFertMachineHistory?.[0]?.endsAt)}
                  timerMinutes={0}
                  color='#DDBF6B'
                />{' '}
                후에 만들기가 끝나요!
              </div>
            )}
            <img
              style={{
                width: '90%',
                left: '5%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/getFertImageNew.png'}
            />
            {makeFertButton()}
            {purchaseFertButton()}
          </div>
          <button
            style={{
              width: '8%',
              top: '20%',
              right: '12%',
              position: 'absolute',
              zIndex: 1113,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
        </div>
      </>
    )
  },
)
export default ProduceFertMachineModal
