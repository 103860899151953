import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import backendApis from '../../utils/backendApis'
import Modal from 'react-modal'
import GetResourceModal from './getResourceModal'
import GetAttendanceWaterFinalModal from './getAttendanceWaterFinalModal'
import GetAttendanceWater50Modal from './getAttendanceWater50Modal'
import ModalStore from '../../store/ModalStore'
import TimeStore from '../../store/TimeStore'
import UserStore from 'store/UserStore'

const AttendanceWaterModalNew = observer(
  ({
    farmData,
    setFarmData,
    token,
    setAddWater,
    setCanGetDailyAttendanceWaterNotification,
    todayCheckInDone,
    setTodayCheckInDone,
    setShowPopup,
    setResourceType,
    // setCheckInPurchaseIconOn,
    checkinPurchaseInfo,
  }) => {
    const [attendanceNumber, setAttendanceNumber] = useState(0)
    const [getResourceModal, setGetResourceModal] = useState(false)
    const [getAttendanceWaterFinalModal, setGetAttendanceWaterFinalModal] =
      useState(false)
    const [getAttendanceWater50modal, setGetAttendanceWater50modal] =
      useState(false)

    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const [showInfoModal, setShowInfoModal] = useState(false)

    useEffect(() => {
      if (farmData?.questLogForAttendanceWater) {
        if (
          dayjs(
            farmData?.questLogForAttendanceWater[
              farmData?.questLogForAttendanceWater?.length - 1
            ],
          ).format('YYYY-MM-DD') <= '2022-11-17'
        ) {
          setTimeout(() => {
            setShowInfoModal(true)
          }, 500)
        }
      }
    }, [])

    const getAttendanceNumber = async () => {
      if (!farmData?.questLogForAttendanceWater) {
        setAttendanceNumber(0)
      } else {
        if (farmData?.questLogForAttendanceWater) {
          let result = await backendApis.consecutiveAttendanceChecker({
            data: JSON.stringify({
              questLogForAttendanceWater: farmData?.questLogForAttendanceWater,
              questLogForAttendanceWaterFinalDate:
                farmData?.questLogForAttendanceWaterFinalDate,
            }),
          })
          setAttendanceNumber(result?.data?.attendanceNumber)
        }
      }
    }

    useEffect(() => {
      getAttendanceNumber()
    }, [farmData?.questLogForAttendanceWater, farmData?.water])

    const [canClickButton, setCanClickButton] = useState(false)

    const attendanceCheckInButton = async () => {
      setCanGetDailyAttendanceWaterNotification(false)

      if (attendanceNumber >= 0 && attendanceNumber <= 3) {
        setCanClickButton(true)
        const result = await backendApis.questDailyLogInForWaterNew(token)
        if (result?.status === 200 && !result?.data?.reset) {
          let tmpFarmData = farmData
          setAttendanceNumber(attendanceNumber + 1)
          if (tmpFarmData?.questLogForAttendanceWater) {
            const tmpLoginLogs = [
              ...tmpFarmData?.questLogForAttendanceWater,
              new Date(),
            ]
            tmpFarmData.questLogForAttendanceWater = tmpLoginLogs
          } else {
            tmpFarmData.questLogForAttendanceWater = [new Date()]
          }

          setTodayCheckInDone(true)
        } else if (result?.status === 200 && result?.data?.reset) {
          let tmpFarmData = farmData
          tmpFarmData.questLogForAttendanceWater = [new Date()]
          setAttendanceNumber(1)
          setTodayCheckInDone(true)
        }
      } else {
        setCanClickButton(false)
      }
      if (attendanceNumber >= 4) {
        setCanClickButton(true)
        const result = await backendApis.questDailyLogInForWaterFinalDateNew(
          token,
        )
        if (result?.status === 200) {
          if (result?.status === 200 && result?.data?.reset) {
            let tmpFarmData = farmData
            tmpFarmData.questLogForAttendanceWater = []
            tmpFarmData.questLogForAttendanceWaterFinalDate = new Date()
            if (UserStore.waterExceeded) {
              tmpFarmData.water += Math.round(
                result?.data?.addedValue?.addedWater * 0.5,
              )
            } else {
              tmpFarmData.water += result?.data?.addedValue?.addedWater
            }

            setAttendanceNumber(0)
            setTodayCheckInDone(true)
            setFarmData(tmpFarmData)
            if (UserStore.waterExceeded) {
              setAddWater(
                Math.round(result?.data?.addedValue?.addedWater * 0.5),
              )
            } else {
              setAddWater(result?.data?.addedValue?.addedWater)
            }

            if (result?.data?.addedValue?.addedWater) {
              setResourceType('water')
              setShowPopup(true)
              ModalStore.setIsModalOpen('basic')
            }
          } else {
            let tmpFarmData = farmData
            if (tmpFarmData?.questLogForAttendanceWater) {
              const tmpLoginLogs = [
                ...tmpFarmData?.questLogForAttendanceWater,
                TimeStore.currentDayJS,
              ]
              tmpFarmData.questLogForAttendanceWater = tmpLoginLogs
            } else {
              tmpFarmData.questLogForAttendanceWater = [TimeStore.currentDayJS]
            }
            setGetAttendanceWaterFinalModal(true)
            setTimeout(() => {
              setGetAttendanceWaterFinalModal(false)
            }, 2000)
            setFarmData(tmpFarmData)
            if (UserStore.waterExceeded) {
              setAddWater(
                Math.round(result?.data?.addedValue?.addedWater * 0.5),
              )
            } else {
              setAddWater(result?.data?.addedValue?.addedWater)
            }
            setAttendanceNumber(0)
            if (result?.data?.addedValue?.addedWater) {
              setResourceType('water')

              setShowPopup(true)
              ModalStore.setIsModalOpen('basic')
            }
          }
          // 출석 매대 등장
          // setCheckInPurchaseIconOn(true);

          checkinPurchaseInfo(new Date())
          setTimeout(() => {
            ModalStore.setIsModalOpen('checkinPurchase')
          }, 2500)
        }
      } else {
        setCanClickButton(false)
      }
    }

    const AttendanceTopWater = () => {
      if (attendanceNumber === 0) {
        return (
          <img
            className='absolute z-[104] w-[45%] left-[30%] top-[-80vw]'
            src='/icon/attendanceTopWater0.png'
            alt=''
          />
        )
      }
      if (attendanceNumber === 1) {
        return (
          <img
            className='absolute z-[104] w-[45%] left-[30%] top-[-80vw]'
            src='/icon/attendanceTopWater50.png'
            alt=''
          />
        )
      }
      if (attendanceNumber === 2) {
        return (
          <img
            className='absolute z-[104] w-[45%] left-[30%] top-[-80vw]'
            src='/icon/attendanceTopWater100.png'
            alt=''
          />
        )
      }
      if (attendanceNumber === 3) {
        return (
          <img
            className='absolute z-[104] w-[45%] left-[30%] top-[-80vw]'
            src='/icon/attendanceTopWater150.png'
            alt=''
          />
        )
      }
      if (attendanceNumber === 4 && todayCheckInDone) {
        return (
          <img
            className='absolute z-[104] w-[45%] left-[30%] top-[-80vw]'
            src='/icon/attendanceTopWater200.png'
            alt=''
          />
        )
      }
      if (attendanceNumber === 4 && !todayCheckInDone) {
        return (
          <img
            className='absolute z-[104] w-[45%] left-[30%] top-[-80vw]'
            src='/icon/attendanceTopWater200Can.png'
            alt=''
          />
        )
      }
    }

    return (
      <div>
        <div className='fixed flex-1 left-0 top-0 z-[999] w-full h-full bg-black bg-opacity-80'>
          <div className='flex justify-center relative h-full modal-scale-up-center'>
            <div className='relative flex justify-center flex-col w-full mt-[10vw]'>
              <img
                className='w-[90%] ml-[5vw]'
                src='/icon/attendanceMainImageNew.png'
                alt=''
              />

              <div className='absolute flex flex-1 z-[101] w-full flex-col'>
                <div className='relative flex flex-1 h-full z-[101] mt-[5vh]'>
                  <div className='absolute top-[37.5%] flex flex-1 w-full z-[101]'>
                    {attendanceNumber === 0 ? (
                      <img
                        className='z-[101] w-[20%] h-[20%] ml-[10%]'
                        src='/icon/attendanceWaterDay1.png'
                        alt=''
                      />
                    ) : (
                      <img
                        className='z-[101] w-[20%] h-[20%] ml-[10%]'
                        src='/icon/attendanceWaterDay1Done.png'
                        alt=''
                      />
                    )}
                    {attendanceNumber < 2 ? (
                      <img
                        className='z-[101] w-[20%] h-[20%]'
                        src='/icon/attendanceWaterDay2.png'
                        alt=''
                      />
                    ) : (
                      <img
                        className='z-[101] w-[20%] h-[20%]'
                        src='/icon/attendanceWaterDay2Done.png'
                        alt=''
                      />
                    )}
                    {attendanceNumber < 3 ? (
                      <img
                        className='z-[101] w-[20%] h-[20%] '
                        src='/icon/attendanceWaterDay3.png'
                        alt=''
                      />
                    ) : (
                      <img
                        className='z-[101] w-[20%] h-[20%] '
                        src='/icon/attendanceWaterDay3Done.png'
                        alt=''
                      />
                    )}
                    {attendanceNumber < 4 ? (
                      <img
                        className='z-[101] w-[20%] h-[20%]'
                        src='/icon/attendanceWaterDay4.png'
                        alt=''
                      />
                    ) : (
                      <img
                        className='z-[101] w-[20%] h-[20%] '
                        src='/icon/attendanceWaterDay4Done.png'
                        alt=''
                      />
                    )}
                  </div>
                  {AttendanceTopWater()}

                  {todayCheckInDone === true ? (
                    <button
                      style={{
                        position: 'absolute',
                        zIndex: 104,
                        backgroundColor: '#ADADAD',
                        fontFamily: 'maplestory',
                        fontSize: '5vw',
                        width: '70%',
                        padding: '5vw',
                        borderRadius: '30vw',
                        bottom: '-50vw',
                        marginLeft: '15%',
                        textAlign: 'center',
                      }}
                    >
                      내일 다시 가능해요
                    </button>
                  ) : (
                    <button
                      style={{
                        position: 'absolute',
                        zIndex: 104,
                        backgroundColor: isButtonPressed
                          ? 'rgb(23, 161, 194, 0.5)'
                          : 'rgb(23, 161, 194)',
                        fontFamily: 'maplestory',
                        fontSize: '5vw',
                        width: '70%',
                        padding: '5vw',
                        borderRadius: '30vw',
                        bottom: '-50vw',
                        marginLeft: '15%',
                        textAlign: 'center',
                      }}
                      onPointerDown={() => {
                        setIsButtonPressed(true)
                      }}
                      onPointerCancel={() => {
                        setIsButtonPressed(false)
                      }}
                      onPointerUp={() => {
                        setIsButtonPressed(false)
                      }}
                      onClick={attendanceCheckInButton}
                    >
                      출석하기
                      {!farmData?.questLogForAttendanceWater &&
                        farmData?.giveWaterCount < 10 && (
                          <img
                            style={{
                              position: 'absolute',
                              width: '15vw',
                              zIndex: 222,
                              right: '-5vw',
                              top: '10vw',
                            }}
                            src='/icon/finger.png'
                            alt=''
                            className='slide-tl'
                          />
                        )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            style={{
              width: '50%',
              top: '30vw',
              right: '-5%',
              position: 'absolute',
              zIndex: 3,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 101,
                position: 'absolute',
                width: '20%',
                top: '73%',
                left: '50%',
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
            {farmData?.questLogForAttendanceWater &&
              farmData?.giveWaterCount < 10 && (
                <img
                  style={{
                    position: 'absolute',
                    width: '15vw',
                    zIndex: 222,
                    right: '0vw',
                    top: '10vw',
                  }}
                  src='/icon/finger.png'
                  alt=''
                  className='slide-tl'
                />
              )}
          </button>
        </div>

        {getResourceModal && (
          <GetResourceModal
            amount={50}
            setGetResourceModal={setGetResourceModal}
          />
        )}
        <Modal
          isOpen={getAttendanceWaterFinalModal}
          onRequestClose={() => {}}
          style={{
            overlay: {
              background: 'rgba(0,0,0,0)',
              zIndex: 1900,
            },
            content: {
              width: '100%',
              height: '100%',
            },
          }}
          className='modal-getResource'
        >
          <GetAttendanceWaterFinalModal
            amount={200}
            // type={type}
          />
        </Modal>
        <Modal
          isOpen={getAttendanceWater50modal}
          onRequestClose={() => {}}
          style={{
            overlay: {
              background: 'rgba(0,0,0,0)',
              zIndex: 1900,
            },
            content: {
              width: '100%',
              height: '100%',
            },
          }}
          className='modal-getResource'
        >
          <GetAttendanceWater50Modal
            amount={50}
            // type={type}
          />
        </Modal>
      </div>
    )
  },
)

export default AttendanceWaterModalNew
