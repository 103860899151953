import React from 'react'

const CountDown = ({ preplayCountDown }) => {
  return (
    <div
      style={{
        flex: 1,
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 98,
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: `5rem`,
          fontWeight: 'bold',
          textShadow:
            '2px 2px #000, -2px 2px #000, -2px -2px #000, 2px -2px #000',
        }}
      >
        {preplayCountDown > 10 ? Math.ceil(preplayCountDown / 10) - 1 : '시작!'}
      </div>
    </div>
  )
}
export default CountDown
