import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const MissionHunterModal = observer(
  ({
    farmData,

    token,
  }) => {
    //getCashbackDealItemsInRandomOrder
    //나중에 위에걸로 랜덤샘플링해서 판매자쿠폰 매대 생성하기
    const throttleRef = useRef({})

    const sendToPurchaseScreen = () => {
      AlfarmEventLogger({
        throttleRef,
        locationType: 'modal',
        locationName: ModalStore.isModalOpen,
        eventType: 'click',
        eventName: 'cta',
        data: {},
        collection: 'UserAlfarmClickLog',
      })

      const purchaseData = {
        enteringComponent: 'Alfarm_missionHunterPurchase',
        text: '올팜 미션 사냥꾼 이벤트',
        source:
          'https://assets.ilevit.com/5974b2bf-7bd4-4193-af5f-a5e8dca7f165.png',
        rewardText: '상품을 구매하면 고급비료 8개를 받아요',
      }

      window.location.href = `#commonPurchaseReward.${JSON.stringify(
        purchaseData,
      )}`
      ModalStore.setIsModalOpen('basic')
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage: 'url(../missionHunter/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '53vw',
              paddingBottom: '18%',
              // marginBottom: "15%",
            }}
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: '18vw',
                position: 'absolute',
                zIndex: 999,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                // border: "1px solid black",
                width: '100%',
                height: '100%',
                // marginBottom: "12vw",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  lineHeight: '1.4rem',
                  // marginBottom: 4,
                  // border: "1px solid black",
                }}
              >
                미션을 열심히 해주신 <br />
                농부님들에게만 드리는 <br />
                <br />
                <b style={{ color: '#E94343', fontSize: '1.2rem' }}>
                  특.별.기.회.
                </b>
              </div>
              <div
                style={{
                  // border: "1px solid black",
                  width: '60vw',
                  height: '30vw',
                  backgroundImage: 'url(../missionHunter/rewardImage.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  lineHeight: '1.4rem',
                  marginBottom: 16,
                  // border: "1px solid black",
                }}
              >
                지금 상품을 구매하면 고급비료 8개
              </div>
              <div
                style={{
                  background:
                    'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                  width: '60vw',
                  height: '14vw',
                  borderRadius: 99,
                  color: 'black',
                  fontFamily: 'Maplestory',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 8,
                }}
                onClick={sendToPurchaseScreen}
              >
                구매하러가기
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default MissionHunterModal
