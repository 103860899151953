import React, { useRef, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import TimeStore from 'store/TimeStore'
import DealAutomationStore from 'store/DealAutomationStore'
import dayjs from 'dayjs'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const TimerWaterUpgradeModal = observer(({ token, farmData, setFarmData }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  const throttleRef = useRef({})

  const sendToPurchaseScreen = () => {
    AlfarmEventLogger({
      throttleRef,
      locationType: 'modal',
      locationName: ModalStore.isModalOpen,
      eventType: 'click',
      eventName: 'cta',
      collection: 'UserAlfarmClickLog',
    })

    window.location.href = `#generalNavigator.${JSON.stringify({
      screen: 'AlfarmTimerWaterUpgradePurchaseScreen',
      params: {
        title: '올팜',
        image:
          'https://assets.ilevit.com/81b74e90-b8aa-4778-9b25-a68a811fba37.png',
        ratio: 96 / 360,
        brandImage:
          DealAutomationStore?.firstWaterFountainAdData?.purchaseScreenBanner,
        brandImageRatio: 96 / 240,
        enteringComponent: 'Alfarm_timerWaterUpgradePurchase',
        // sellerId: "613a09ae5989653d71925852",
        itemIds: DealAutomationStore?.firstWaterFountainAdData?.itemIds ?? [],
      },
    })}`
    ModalStore.setIsModalOpen('basic')
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 900,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '90%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            position: 'relative',
          }}
        >
          <button
            style={{
              width: '10%',
              right: '2%',
              top: '-24%',
              position: 'absolute',
              zIndex: 1113,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
                top: '25vw',
                right: '1vw',
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <img
            src={DealAutomationStore?.firstWaterFountainAdData?.background}
            alt=''
            style={{ width: '80vw', marginLeft: '5vw' }}
          />
          {/* <div
            style={{
              position: "absolute",
              fontFamily: "maplestory",
              flex: 1,
              bottom: "20vw",
              zIndex: 2,
              width: "100%",
              textAlign: "center",
              color: "#442b22",\
              fontSize: "4.5vw",
            }}
          ></div> */}
          <button
            style={{
              // background:
              //   "linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)",
              background: isButtonPressed ? '#F7C82A' : '#F7C82A',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              width: '55vw',
              height: '14vw',
              borderRadius: 99,
              color: 'black',
              fontSize: '4.5vw',
              fontFamily: 'Maplestory',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'center',
              alignItems: 'center',
              left: '16.5vw',
              bottom: '8vw',
              // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
            }}
            onClick={() => {
              sendToPurchaseScreen()
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
          >
            {'구매하고 강화하기'}
          </button>
        </div>
      </div>
    </>
  )
})

export default TimerWaterUpgradeModal
