import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'

const Tile = observer(({ currentTileIndex }) => {
  const Rolling = () => {
    if (
      currentTileIndex === 1 ||
      currentTileIndex === 9 ||
      currentTileIndex === 17 ||
      currentTileIndex === 25
    ) {
      return (
        <div
          style={{
            background:
              'linear-gradient(180deg, #FCEB08 0%, #FFD314 87.96%, #E19608 100%)',
            width: '25.34vw',
            height: '21.96vw',
            position: 'absolute',
            zIndex: 1102,
            borderRadius: '3vw',
          }}
          className='luckydraw-tile-101'
        />
      )
    }
    if (
      currentTileIndex === 2 ||
      currentTileIndex === 10 ||
      currentTileIndex === 18 ||
      currentTileIndex === 26
    ) {
      return (
        <div
          style={{
            background:
              'linear-gradient(180deg, #FCEB08 0%, #FFD314 87.96%, #E19608 100%)',
            width: '25.34vw',
            height: '21.96vw',
            position: 'absolute',
            zIndex: 1102,
            borderRadius: '3vw',
          }}
          className='luckydraw-tile-102'
        />
      )
    }
    if (
      currentTileIndex === 3 ||
      currentTileIndex === 11 ||
      currentTileIndex === 19 ||
      currentTileIndex === 27
    ) {
      return (
        <div
          style={{
            background:
              'linear-gradient(180deg, #FCEB08 0%, #FFD314 87.96%, #E19608 100%)',
            width: '25.34vw',
            height: '21.96vw',
            position: 'absolute',
            zIndex: 1102,
            borderRadius: '3vw',
          }}
          className='luckydraw-tile-103'
        />
      )
    }
    if (
      currentTileIndex === 4 ||
      currentTileIndex === 12 ||
      currentTileIndex === 20 ||
      currentTileIndex === 28
    ) {
      return (
        <div
          style={{
            background:
              'linear-gradient(180deg, #FCEB08 0%, #FFD314 87.96%, #E19608 100%)',
            width: '25.34vw',
            height: '21.96vw',
            position: 'absolute',
            zIndex: 1102,
            borderRadius: '3vw',
          }}
          className='luckydraw-tile-104'
        />
      )
    }
    if (
      currentTileIndex === 5 ||
      currentTileIndex === 13 ||
      currentTileIndex === 21 ||
      currentTileIndex === 29
    ) {
      return (
        <div
          style={{
            background:
              'linear-gradient(180deg, #FCEB08 0%, #FFD314 87.96%, #E19608 100%)',
            width: '25.34vw',
            height: '21.96vw',
            position: 'absolute',
            zIndex: 1102,
            borderRadius: '3vw',
          }}
          className='luckydraw-tile-105'
        />
      )
    }
    if (
      currentTileIndex === 6 ||
      currentTileIndex === 14 ||
      currentTileIndex === 22 ||
      currentTileIndex === 30
    ) {
      return (
        <div
          style={{
            background:
              'linear-gradient(180deg, #FCEB08 0%, #FFD314 87.96%, #E19608 100%)',
            width: '25.34vw',
            height: '21.96vw',
            position: 'absolute',
            zIndex: 1102,
            borderRadius: '3vw',
          }}
          className='luckydraw-tile-106'
        />
      )
    }
    if (
      currentTileIndex === 7 ||
      currentTileIndex === 15 ||
      currentTileIndex === 23 ||
      currentTileIndex === 31
    ) {
      return (
        <div
          style={{
            background:
              'linear-gradient(180deg, #FCEB08 0%, #FFD314 87.96%, #E19608 100%)',
            width: '25.34vw',
            height: '21.96vw',
            position: 'absolute',
            zIndex: 1102,
            borderRadius: '3vw',
          }}
          className='luckydraw-tile-107'
        />
      )
    }
    if (
      currentTileIndex === 8 ||
      currentTileIndex === 16 ||
      currentTileIndex === 24 ||
      currentTileIndex === 32
    ) {
      return (
        <div
          style={{
            background:
              'linear-gradient(180deg, #FCEB08 0%, #FFD314 87.96%, #E19608 100%)',
            width: '25.34vw',
            height: '21.96vw',
            position: 'absolute',
            zIndex: 1102,
            borderRadius: '3vw',
          }}
          className='luckydraw-tile-108'
        />
      )
    }
  }
  return <>{Rolling()}</>
})

export default Tile
