import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import WhiteTextTimer from '../WhiteTextTimer'
import userButtonClickLogger from 'utils/userButtonClickLogger'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const sprite = {
  secretScratch: {
    images: [`/secretScratch/entryIconv2.png`],
  },
  newbiePurchaseReward: {
    images: [`/icon/newbiePurchaseRewardv2.png`],
  },
  lastMinuteInvite: {
    images: [`/lastMinuteInvite/entryIconv2.png`],
  },
  dailyLottery: {
    images: [`/dailyLottery/entryIconv2.png`],
  },
  levelUpChance: {
    images: [`/levelUpChance/entryIconv2.png`],
  },
  couponUsageNudge: {
    images: [`/couponUsageNudge/entryIconv2.png`],
  },
  relayReward: {
    images: [`/relayReward/entryIconv2.png`],
  },
  luckydrawIcon: {
    images: [`/icon/luckydrawIconv2.png`],
  },
  sellerCouponPurchase: {
    images: [`/sellerCouponPurchase/entryIconv2.png`],
  },
  missionHunter: {
    images: [`/missionHunter/entryIconv2.png`],
  },
  makeGganbu: {
    images: [`/icon/makeGganbuv2.png`],
  },
  lottery: {
    images: [`/icon/lotteryv2.png`],
  },
  fastHarvestIcon: {
    images: [`/icon/fastHarvestIconv2.png`],
  },
  // 아이콘 안바뀜
  seasonDeal: {
    images: [`/seasonDeal/entryIconv2.png`],
  },
  // 아이콘 안바뀜
  instaEvent: {
    images: [`/icon/instaEntryIconv2.png`],
  },
  // 아이콘 안바뀜
  newyearGiftIcon: {
    images: [`/newyearGift/iconv2.png`],
  },

  changePlantBeforeChurn: {
    images: [`/changePlantBeforeChurn/entryIconv2.png`],
  },

  newbieTreasureBox: {
    images: [`/newbieTreasureBox/entryIconv2.png`],
  },
  categoryFirstConv: {
    images: [`/icon/categoryFirstConvv2.png`],
  },

  liftFirst: {
    images: [`/icon/liftFirstv2.png`],
  },

  checkinPurchase: {
    images: [`/checkInPurchase/entryIconv2.png`],
  },
  lastHarvestPurchase: {
    images: [`/lastHarvestPurchase/entryIconv2.png`],
  },
  quiz: {
    images: [`/quiz/entryIconv3.png`],
  },
  dailyGetWaterUpgrade: {
    images: [`/icon/dailyGetWaterUpgradeIcon.png`],
  },
  fertilizerRoulette: {
    images: [`/fertilizerRoulette/entryIconv2.png`],
  },
  fertilizerTowerGame: {
    images: [`/fertilizerTowerGame/entryIconv2.png`],
  },
  adItemBrowsing: {
    images: [`/adItemBrowsing/entryIconv2.png`],
  },
  cj: {
    images: [`/adItemBrowsing/cjv2.png`],
  },
  purchaseRoulette: {
    images: [`/purchaseRoulette/entryIconv2.png`],
  },
  timeDealQuest: {
    images: [`/timeDeal/entryIconv2.png`],
  },
  personalizedShop: {
    images: [`/personalizedShop/entryIconv2.png`],
  },
  selectOnePurchase: {
    images: [`/selectOnePurchase/entryIconv2.png`],
  },
  allBrandDealPurchase: {
    images: [`/allBrandDeal/entryIconv2.png`],
  },
  surpriseMission: {
    images: [`/surpriseMission/entryIconv2.png`],
  },
  treasureDealPurchase: {
    images: [`/treasureDeal/entryIconv2.png`],
  },
  waterBrowsingAdQuest: {
    images: [`/waterBottomSheet/waterBrowsingAdThumbnail.png`],
  },
  grapeEventRight: {
    images: [`/grapeEvent/rightScrollGrapeIcon.png`],
  },
  tenMinDeal: {
    images: [`/icon/tenMinDeal.png`],
  },
  trendQuiz: {
    images: [`/icon/leftScrollImage/trendQuizv3.png`],
  },
  goldFilled: {
    images: [`/goldFilled/entryIcon.png`],
  },
  jewelryRevenueDeal: {
    images: [`/jewelryRevenueDeal/entryIcon.png`],
  },
  kidsRevenueDeal: {
    images: [`/revenueDeal/kidsRevenueDealEntryIcon.png`],
  },
  winterRevenueDeal: {
    images: [`/revenueDeal/winterRevenueDealEntryIcon.png`],
  },
  christmasTree: {
    images: [`/christmasTree/entryIcon.png`],
  },
  newUserKakaoShare: {
    images: [`/newUserKakaoShare/entryIcon.png`],
  },
  lotteryDrawSpecialPurchase: {
    images: [`/minigame/lotteryDraw/entryIcon.png`],
  },
  snackRevenueDeal: {
    images: [`/revenueDeal/snackRevenueDealEntryIcon.png`],
  },
  dongwookEvent: {
    images: [`/dongwookEvent/entryIcon.png`],
  },
  feverMode: {
    images: [`/feverMode/entryIcon.png`],
  },
  yutnori: {
    images: [`/minigame/yutnori/yutRightEntryIcon.png`],
  },
  alphabetEvent: {
    images: [`/alphabetEvent/enteryIcon.png`],
  },
  daisoRevenueDeal: {
    images: [`/revenueDeal/daisoRevenueDealEntryIcon.png`],
  },
  healthRevenueDeal: {
    images: [`/revenueDeal/healthRevenueDealEntryIcon.png`],
  },
  gganbuBounty: {
    images: [`/icon/gganbuBounty.png`],
  },
  summerRevenueDeal: {
    images: [`/revenueDeal/3000.png`],
  },
  nutrimentLeaves: {
    images: [`/nutrimentLeaves/entryIcon.png`],
  },
}

const PurchaseScreenTimerButton = observer(
  ({
    imageType = 'secretScratch',
    onClick,
    className = '',
    style,
    onTimeEnd = () => {},
    timeStamp,
    timerMinutes,
    timerStyle,
    interval = 1000,
    pingType = null,
  }) => {
    const throttleRef = useRef({})
    if (!(imageType in sprite)) return null
    const [timerColor, setTimerColor] = useState('#442b22')

    useEffect(() => {
      let date = new Date()

      if (date.getHours() >= 19 || date.getHours() <= 6) {
        setTimerColor('#F5F5F5')
      } else {
        setTimerColor('#442b22')
      }
    }, [])

    return (
      <button
        onClick={() => {
          onClick()
          AlfarmEventLogger({
            throttleRef,
            locationType: 'rightTimerButton',
            locationName: imageType,
            eventType: 'click',
            collection: 'UserAlfarmClickLog',
          })
          userButtonClickLogger({ buttonType: imageType })
        }}
        className={`bt-header ${className}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '-0.5vw',
          ...style,
          position: 'relative',
          marginLeft: '2vw',
          marginRight: '-0.5vw',
        }}
      >
        <img src={sprite[imageType].images} alt='' />
        <WhiteTextTimer
          onTimeEnd={onTimeEnd}
          timeStamp={timeStamp}
          timerMinutes={timerMinutes}
          interval={interval}
          color={timerColor}
          style={{
            marginTop: '-0.5vw',
            ...timerStyle,
            fontSize: '3vw',
          }}
          startFromMinutes
          noDays
        />

        {pingType && (
          <img
            className='w-[9vw] h-[9vw] absolute -right-[2vw] -top-[2vw]'
            alt='ping'
            src={`../icon/${pingType}.png`}
          />
        )}
      </button>
    )
  },
)
export default PurchaseScreenTimerButton
