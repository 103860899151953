import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import AuthStore from 'store/AuthStore'
import backendApis from 'utils/backendApis'

const WatermelonModal = observer(() => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '100%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            style={{
              width: '10%',
              top: '-8vw',
              right: '4%',
              position: 'absolute',
              zIndex: 1113,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <img
            src='/watermelonModal/modal.png'
            alt='abc'
            style={{ width: '80vw' }}
          />

          <div
            style={{
              background: isButtonPressed ? '#F7C82A' : '#F7C82A',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              width: '60vw',
              height: '14vw',
              borderRadius: 99,
              color: 'black',
              fontFamily: 'maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '4.5vw',
              position: 'absolute',
              bottom: '8vw',
            }}
            onClick={async () => {
              const result = await backendApis.getEventPageMapping({
                eventPageName: 'watermelon',
              })
              const webUrl = result?.data?.data
              const redirectUrlHost = webUrl?.redirectUrl
              const redirectSearchParams = new URLSearchParams({
                token: AuthStore.token,

                randomString: Math.random().toString(36).slice(2, 10),
              })
              const prop = {
                screen: 'TreasureHuntWeb',
                prop: {
                  eventPageMapping: {
                    ...webUrl,
                    redirectUrl: `${redirectUrlHost}/?${redirectSearchParams}`,
                  },
                },
              }
              window.location.href = `#navigate.${JSON.stringify(prop)}`
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
          >
            양파게임 하러가기
          </div>
        </div>
      </div>
    </>
  )
})

export default WatermelonModal
