import React, { useState, useEffect, useRef } from 'react'
import { useSearchParam } from 'react-use'
import { isAndroid, isIOS, isIOS13, setUserAgent } from 'react-device-detect'
import backendApis from '../utils/backendApis'
import { NavLink } from 'react-router-dom'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import commaNumber from 'comma-number'
import { BottomSheet } from 'react-spring-bottom-sheet'
import timeChecker from '../utils/timeChecker'
import toast, { Toaster } from 'react-hot-toast'
import LoadingIndicator from '../comps/loadingIndicator'
import dayjs from 'dayjs'
import ManbogiModal from '../comps/molecules/ManbogiModal'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import '../css/ManbogiCalendar.css'
import indexStore from '../store/indexStore'
const { ManbogiStore } = indexStore()

let hidden, visibilityChange
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

const BannerItem = ({
  thumbnail,
  title,
  description,
  buttonText,
  onClick,
  disable,
  notify,
  notifyText,
  notifyType,
  thumbnailSize = '16vw',
  thumbnailBackgroundColor = '#F6F3EC',
  hidden = false,
}) => {
  return (
    <div
      style={{
        display: hidden ? 'none' : 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 8,
        borderRadius: 8,
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            width: thumbnailSize,
            height: thumbnailSize,
            marginRight: 16,
            marginLeft: 8,
            backgroundColor: thumbnailBackgroundColor,
            borderRadius: 99,
            padding: '3.5vw',
            overflow: 'visible',
          }}
          src={thumbnail}
          alt=''
        />
        {description ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{
                color: '#000',
                margin: '4px 0px 8px 0px',
                fontWeight: 'bold',
              }}
            >
              {title}
            </div>
            <div
              style={{
                fontSize: '0.8rem',
                color: '#765C2B',
                marginBottom: 4,
              }}
            >
              {description}
            </div>
          </div>
        ) : (
          <div
            style={{
              color: 'black',
              margin: '4px 0px 4px 0px',
            }}
          >
            {title}
          </div>
        )}
      </div>
      <div
        style={{
          padding: 8,
          background: disable
            ? '#d9d9d9'
            : 'linear-gradient(180deg, rgba(255,231,157,1) 0%, rgba(255,191,30,1) 100%)',
          borderRadius: 8,
          color: disable ? '#555' : '#000',
          marginRight: 8,
          fontSize: '1rem',
          paddingLeft: 12,
          paddingRight: 12,
          fontWeight: 'bold',
        }}
        onClick={() => {
          if (!disable) {
            onClick()
          } else {
            notify(notifyText, notifyType)
          }
        }}
      >
        {buttonText}
      </div>
    </div>
  )
}

const Manbogi = () => {
  const missionMap = [
    {
      thumbnail: '/manbogi/calendar.png',
      hidden: () => false,
      title: '출석체크',
      description: '매일 출석하고 30코인 받기',
      buttonText: '출석하기',
      onClick: () => manbogiAttendanceReward(),
      disable: ({ manbogiData, timeInfoRef }) =>
        !timeChecker(manbogiData?.attendanceRewardAt, timeInfoRef.current),
      notifyText: () => '내일 다시 가능해요',
      notifyType: () => {
        return { icon: '⏳' }
      },
    },
    {
      thumbnail: '/manbogi/trophy.png',
      hidden: (manbogiData) =>
        dayjs().format('DD') ===
        dayjs(manbogiData?.stepMissionOne ?? '2023-01-01').format('DD'),
      title: '1,000걸음 걷기',
      description: '매일 미션하고 20코인 받기',
      buttonText: '보상받기',
      disable: ({ manbogiData, timeInfoRef }) =>
        steps < 1000 ||
        !timeChecker(manbogiData?.stepMissionOne, timeInfoRef.current),
      onClick: () => manbogiStepMission({ type: 'stepMissionOne' }),
      notifyText: ({ steps }) => {
        return steps < 1000
          ? `${commaNumber(1000 - steps)}걸음 남았어요!`
          : '내일 다시 가능해요'
      },
      notifyType: (steps) => {
        return { icon: `${steps < 1000 ? '🔥' : '⏳'}` }
      },
    },
    {
      thumbnail: '/manbogi/trophy.png',
      hidden: (manbogiData) =>
        dayjs().format('DD') ===
        dayjs(manbogiData?.stepMissionTwo ?? '2023-01-01').format('DD'),
      title: '3,000걸음 걷기',
      description: '매일 미션하고 20코인 받기',
      buttonText: '보상받기',
      disable: ({ manbogiData, timeInfoRef }) =>
        steps < 3000 ||
        !timeChecker(manbogiData?.stepMissionTwo, timeInfoRef.current),
      onClick: () => manbogiStepMission({ type: 'stepMissionTwo' }),
      notifyText: ({ steps }) => {
        return steps < 3000
          ? `${commaNumber(3000 - steps)}걸음 남았어요!`
          : '내일 다시 가능해요'
      },
      notifyType: (steps) => {
        return { icon: `${steps < 3000 ? '🔥' : '⏳'}` }
      },
    },
    {
      thumbnail: '/manbogi/trophy.png',
      hidden: (manbogiData) =>
        dayjs().format('DD') ===
        dayjs(manbogiData?.stepMissionThree ?? '2023-01-01').format('DD'),
      title: '5,000걸음 걷기',
      description: '매일 미션하고 20코인 받기',
      buttonText: '보상받기',
      disable: ({ manbogiData, timeInfoRef }) =>
        steps < 5000 ||
        !timeChecker(manbogiData?.stepMissionThree, timeInfoRef.current),
      onClick: () => manbogiStepMission({ type: 'stepMissionThree' }),
      notifyText: ({ steps }) => {
        return steps < 5000
          ? `${commaNumber(5000 - steps)}걸음 남았어요!`
          : '내일 다시 가능해요'
      },
      notifyType: (steps) => {
        return { icon: `${steps < 5000 ? '🔥' : '⏳'}` }
      },
    },
    {
      thumbnail: '/manbogi/trophy.png',
      hidden: (manbogiData) =>
        dayjs().format('DD') ===
        dayjs(manbogiData?.stepMissionFour ?? '2023-01-01').format('DD'),
      title: '10,000걸음 걷기',
      description: '매일 미션하고 20코인 받기',
      buttonText: '보상받기',
      disable: ({ manbogiData, timeInfoRef }) =>
        steps < 10000 ||
        !timeChecker(manbogiData?.stepMissionFour, timeInfoRef.current),
      onClick: () => manbogiStepMission({ type: 'stepMissionFour' }),
      notifyText: ({ steps }) => {
        return steps < 10000
          ? `${commaNumber(10000 - steps)}걸음 남았어요!`
          : '내일 다시 가능해요'
      },
      notifyType: (steps) => {
        return { icon: `${steps < 10000 ? '🔥' : '⏳'}` }
      },
    },
  ]

  const storeMenuMap = [
    {
      thumbnail: '/manbogi/water.png',
      title: '물 100g 구매',
      description: '1,000 코인',
      buttonText: '구매하기',
      thumbnailSize: '18vw',
      onClick: () => orderItem({ order: 'water100' }),
    },
    {
      thumbnail: '/manbogi/water.png',
      title: '물 300g 구매',
      description: '2,500 코인',
      buttonText: '구매하기',
      thumbnailSize: '18vw',
      onClick: () => orderItem({ order: 'water300' }),
    },
    {
      thumbnail: '/manbogi/fertilizerSet.png',
      title: '고급비료 1개 구매',
      description: '2,000 코인',
      buttonText: '구매하기',
      thumbnailSize: '18vw',
      onClick: () => orderItem({ order: 'fertilizerSet1' }),
    },
    {
      thumbnail: '/manbogi/fertilizerSet.png',
      title: '고급비료 2개 구매',
      description: '3,000 코인',
      buttonText: '구매하기',
      thumbnailSize: '18vw',
      onClick: () => orderItem({ order: 'fertilizerSet2' }),
    },
  ]

  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const randomString = useSearchParam('randomString')

  const [isLoading, setIsLoading] = useState(false)
  const [steps, setSteps] = useState(0)
  const [rewardBox, setRewardBox] = useState(0)
  const [coin, setCoin] = useState(0)
  const [showStore, setShowStore] = useState(false)
  const [showHistoryCalendar, setShowHistoryCalendar] = useState(false)
  const [manbogiData, setManbogiData] = useState({})
  const [rewardToastInfo, setRewardToastInfo] = useState({
    rewardImage: 'url(../manbogi/coin.png)',
    rewardAmount: 5,
    rewardText: '코인 당첨',
    unit: '개',
    visible: false,
  })
  const [value, onChange] = useState(new Date())
  const [stepsForCalendar, setStepsForCalendar] = useState(steps)
  const [historyLog, setHistoryLog] = useState([])

  const timeInfoRef = useRef()
  const toastBlock = useRef()
  const coinRef = useRef(null)
  const coinReceiveModalRef = useRef()
  const showToastRef = useRef()

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        if (document.visibilityState === 'visible') {
          setIsLoading(true)
          setTimeout(() => {
            getManbogiData()
            setIsLoading(false)
          }, 500)
        }
      },
      false,
    )
  }, [token])

  useEffect(() => {
    showToastRef.current = false
    setTimeout(() => {
      showToastRef.current = true
    }, 800)
    getManbogiData()
    getTimeInfo()
    const saveSteps = async ({ data }) => {
      let message = await JSON.parse(data)
      if (message?.type === 'steps') {
        setSteps((prev) => {
          if (showToastRef.current && prev > 0) {
            const prevHundread = Math.floor(prev / 100)
            const nowHundread = Math.floor(+message?.steps / 100)

            if (nowHundread > prevHundread && prev < 10100) {
              setRewardToastInfo({
                rewardImage: 'url(../manbogi/giftBox.png)',
                rewardAmount: 1,
                rewardText: '100보 달성',
                unit: '개',
                visible: true,
              })
              getRewardBoxCount()
            }
          }

          return +message?.steps
        })
        setIsLoading(false)
      } else if (message?.type === 'goBack') {
        window.location.href = `/farmPage?token=${token}&itemType=${itemType}&randomNumber=${randomString}`
      }
    }

    getManbogiLog()

    if (isIOS || isIOS13) {
      window.addEventListener('message', saveSteps)
      return () => {
        window.removeEventListener('message', saveSteps)
      }
    } else {
      document.addEventListener('message', saveSteps)
      return () => {
        document.removeEventListener('message', saveSteps)
      }
    }
  }, [])

  useEffect(() => {
    if (rewardToastInfo?.visible) {
      clearTimeout(coinReceiveModalRef.current)
      coinReceiveModalRef.current = setTimeout(() => {
        setRewardToastInfo({ ...rewardToastInfo, visible: false })
      }, 1500)
    }
  }, [rewardToastInfo])

  const getManbogiLog = async (date = new Date()) => {
    let startDate = dayjs(date).startOf('month').format('YYYY-MM-DD')
    let endDate = dayjs(date).add(1, 'M').startOf('month').format('YYYY-MM-DD')
    let result = await backendApis.getManbogiLogRange({
      startDate,
      endDate,
    })
    setHistoryLog(result?.data)
  }

  const notify = (message, type) => {
    if (toastBlock.current === true) return
    if (type === 'error') {
      toast.error(message, { duration: 1400 })
    } else if (type === 'success') {
      toast.success(message, { duration: 1400 })
    } else if (type?.icon) {
      toast(message, { ...type, duration: 1400 })
    }

    toastBlock.current = true
    setTimeout(() => {
      toastBlock.current = false
    }, 1400)
  }

  const getTimeInfo = async () => {
    timeInfoRef.current = {
      standardHour: 12,
      standardMin: 0,
      standardSec: 0,
    }
  }

  const openRewardBox = async () => {
    setRewardBox((prev) => {
      if (prev <= 0) return 0
      if (prev > 0) {
        clearTimeout(coinReceiveModalRef.current)
        backendApis.increaseSpecificFieldManbogi({
          fieldName: 'rewardBox',
          value: -1,
        })
        let reward = Math.floor(Math.random() * (10 - 5)) + 5

        backendApis.increaseSpecificFieldManbogi({
          fieldName: 'coin',
          value: reward,
        })
        setRewardToastInfo({
          rewardImage: 'url(../manbogi/coin.png)',
          rewardAmount: reward,
          rewardText: '코인 당첨',
          unit: '개',
          visible: true,
        })

        getCoinCount()
        return prev - 1
      }
    })
  }

  const orderItem = async ({ order }) => {
    let menu = {
      water100: { cost: 1000, amount: 100, type: 'water' },
      water300: { cost: 2500, amount: 300, type: 'water' },
      fertilizerSet1: { cost: 2000, amount: 1, type: 'fertilizerSet' },
      fertilizerSet2: { cost: 3000, amount: 2, type: 'fertilizerSet' },
    }
    let item = menu[order]

    if (coin < item?.cost) {
      notify('코인이 부족해요', 'error')
      return
    }

    let result = await backendApis.storePurchaseManbogi({ order: order })
    if (result?.data?.success) {
      setCoin(result?.data?.coin)
      setRewardToastInfo({
        rewardImage: `url(../manbogi/${item.type}.png)`,
        rewardAmount: item.amount,
        rewardText: '구매 완료',
        unit: item.type === 'water' ? 'g' : '개',
        visible: true,
      })
    } else if (
      result?.data?.success === false &&
      result.data?.msg === 'not enough coin'
    ) {
      notify('코인이 부족해요', 'error')
    }
  }

  const getRewardBoxCount = async () => {
    let result = await backendApis.getSpecificFieldManbogi({
      fieldsProjected: JSON.stringify({ rewardBox: 1 }),
    })

    setRewardBox(result?.data?.rewardBox ?? 0)
  }

  const getManbogiData = async () => {
    let result = await backendApis.getManbogi()
    setManbogiData(result?.data)
    setCoin(result?.data?.coin)
    setRewardBox(result?.data?.rewardBox)
  }

  const getCoinCount = async () => {
    let result = await backendApis.getSpecificFieldManbogi({
      fieldsProjected: JSON.stringify({ coin: 1 }),
    })
    setCoin(result?.data?.coin ?? 0)
  }
  const getCompoundFields = async (params) => {
    let result = await backendApis.getSpecificFieldManbogi({
      fieldsProjected: JSON.stringify(params),
    })
    return result
  }

  const manbogiAttendanceReward = async () => {
    let result = await backendApis.manbogiAttendanceReward()
    if (result?.data?.success) {
      let res = await getCompoundFields({ coin: 1, attendanceRewardAt: 1 })
      let temp = { ...manbogiData }
      temp.attendanceRewardAt = res?.data?.attendanceRewardAt
      temp.coin = res?.data?.coin
      setManbogiData(temp)
      setCoin(res?.data?.coin)

      setRewardToastInfo({
        rewardImage: 'url(../manbogi/coin.png)',
        rewardAmount: 30,
        rewardText: '출석 완료',
        unit: '개',
        visible: true,
      })
    }
  }

  const manbogiStepMission = async ({ type }) => {
    let result = await backendApis.manbogiStepMission({ type })
    if (result?.data?.success) {
      let res = await getCompoundFields({ coin: 1, attendanceRewardAt: 1 })
      let temp = { ...manbogiData }
      temp.attendanceRewardAt = res?.data?.attendanceRewardAt
      temp.coin = res?.data?.coin
      temp[type] = new Date()
      setManbogiData(temp)
      setCoin(res?.data?.coin)
      setRewardToastInfo({
        rewardImage: 'url(../manbogi/coin.png)',
        rewardAmount: 20,
        rewardText: '미션 성공',
        unit: '개',
        visible: true,
      })
    } else {
      notify('서버와의 연결이 불안정해요', 'error')
    }
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',

        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        background:
          'linear-gradient(180deg, rgba(52,190,209,0.3) 0%, rgba(0,149,77,0.7) 100%)',
      }}
    >
      <header
        style={{
          width: '100%',
          marginTop: '10vw',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <NavLink
          to={`/farmPage?token=${token}&itemType=${itemType}&randomNumber=${randomString}`}
        >
          <button
            style={{
              width: '7vw',
              marginLeft: '2vw',
              zIndex: 999,
              flex: 1,
            }}
            action='goBack'
          >
            <img src='/icon/backBlack.png' alt='' />
          </button>
        </NavLink>

        <div
          style={{
            zIndex: 100,
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            marginLeft: '-9vw',
            fontWeight: 'bold',
          }}
        >
          올팜 만보기
        </div>
      </header>

      {/* 중앙 원형 게이지 */}
      <div
        style={{
          width: '100%',
          position: 'relative',
          paddingLeft: 32,
          paddingRight: 32,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          // border: "5px solid black",
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              padding: 4,
              borderRadius: 99,
              zIndex: 100,
              color: 'black',
              fontSize: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(7,79,87,0.2)',
              alignSelf: 'flex-end',
              margin: 4,
              marginRight: -8,
            }}
          >
            <img
              ref={coinRef}
              style={{
                width: '8vw',
                height: '8vw',
                marginRight: 4,
              }}
              src='/manbogi/coin.png'
              alt=''
            />
            <div
              style={{
                border: '1px solid black',
                borderRadius: 99,
                padding: 4,
                paddingLeft: 8,
                paddingRight: 8,
                fontWeight: 'bold',
                backgroundColor: '#D27200',
              }}
            >{`${commaNumber(coin)}`}</div>
          </div>
        </div>

        <div
          style={{
            width: '100%',
            alignSelf: 'center',
            // border: "5px solid black",
            position: 'relative',
          }}
        >
          <CircularProgressbar
            strokeWidth={8}
            value={steps % 100}
            // background
            backgroundPadding={4}
            styles={{
              root: {},
              path: {
                // Path color
                // stroke: `rgba(62, 152, 199, ${(steps % 100) / 100})`,
                stroke: `#ffe79d`,
                strokeWidth: 4,
                height: 4,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                // Customize transition animation
                transition: 'stroke-dashoffset 0.5s ease 0s',
                // Rotate the path
                transformOrigin: 'center center',
              },
              // Customize the circle behind the path, i.e. the "total progress"
              trail: {
                // Trail color

                stroke: 'rgba(7,79,87,0.2)',
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'round',
                // Rotate the trail
                // transform: "rotate(0.25turn)",
                // transformOrigin: "center center",
              },
              // Customize the text
              text: {
                fill: '#f88',
                fontSize: '16px',
              },
              // Customize background - only used when the `background` prop is true
              // background: {
              //   fill: "#3e98c7",
              // },
            }}
          />
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',

              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontSize: '1.5rem',
                width: '100%',
                color: 'black',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexDirection: 'column',
                flex: 1.2,
              }}
            >
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginBottom: 24,
                  // border: "1px solid black",
                }}
              >
                <div
                  style={{
                    fontSize: '3rem',
                    fontWeight: 'bold',
                  }}
                >
                  {commaNumber(steps)}
                </div>
                <div
                  style={{
                    fontSize: '1.2rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  걸음수
                </div>
              </div>
            </div>
            <div
              style={{
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                flex: 1,
                width: '25vw',
              }}
              onClick={openRewardBox}
            >
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    width: '18vw',
                    height: '18vw',
                    // border: "1px solid black",
                    marginTop: 16,
                    backgroundColor: 'rgba(7,79,87,0.2)',
                    borderRadius: 99,
                    padding: '3vw',
                  }}
                  src='/manbogi/giftBox.png'
                  alt=''
                />
                <div
                  style={{
                    position: 'absolute',
                    backgroundColor: '#D44E01',
                    border: '1px solid black',
                    borderRadius: 99,
                    padding: '4px 8px 4px 8px',
                    // top: "2vw",
                    bottom: -4,
                    left: '50%',

                    transform: 'translate(-50%)',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}
                >
                  {`${rewardBox}`}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '100%',
            paddingLeft: 16,
            paddingRight: 16,
            // border: "5px solid black",
            borderRadius: 16,
            marginTop: '4vw',
            marginBottom: '4vw',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              height: '14vw',
              color: 'black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 4,
              borderRadius: 99,
              fontWeight: 'bold',
            }}
            onClick={() => {
              setShowHistoryCalendar(true)
            }}
          >
            <img
              style={{
                width: '9vw',
                height: '9vw',
                marginRight: 8,
              }}
              src='/manbogi/historyCalendar.png'
              alt=''
            />
            {`출석달력`}
          </div>

          <div
            style={{ width: 1, height: '60%', borderLeft: '1px solid #ccc' }}
          />

          <div
            style={{
              height: '14vw',
              color: 'black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 4,
              borderRadius: 99,
              fontWeight: 'bold',
            }}
            onClick={() => {
              setShowStore(true)
            }}
          >
            <img
              style={{
                width: '9vw',
                height: '9vw',
                marginRight: 8,
              }}
              src='/manbogi/store.png'
              alt=''
            />
            {`코인상점`}
          </div>
        </div>
      </div>

      {/* 미션 바텀싯 */}
      <div
        style={{
          padding: 8,
          paddingTop: 32,
          borderRadius: 16,
          // border: "5px solid black",
          background: '#fff',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        {missionMap.map((item) => {
          return (
            <div
              key={Math.random()}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <BannerItem
                thumbnail={item.thumbnail}
                hidden={item.hidden()}
                title={item.title}
                description={item.description}
                buttonText={item.buttonText}
                disable={item.disable({ manbogiData, timeInfoRef })}
                onClick={item.onClick}
                notify={notify}
                notifyText={item.notifyText(steps)}
                notifyType={item.notifyType(steps)}
              />
              <div
                style={{
                  borderBottom: '1px solid #eee',
                  width: '92%',
                  marginTop: 4,
                  marginBottom: 4,

                  alignSelf: 'center',
                }}
              />
            </div>
          )
        })}
      </div>

      <BottomSheet
        className='bottom-sheet'
        open={showStore}
        expandOnContentDrag
        scrollLocking={false}
        onDismiss={() => {
          setShowStore(false)
        }}
        defaultSnap={({ lastSnap, snapPoints }) => {
          lastSnap ?? Math.max(...snapPoints)
        }}
      >
        <div
          style={{
            width: '100%',
            padding: 8,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              color: 'black',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              marginTop: 8,
              marginBottom: 24,
              position: 'relative',
            }}
          >
            코인상점
            <div
              style={{
                position: 'absolute',
                right: 0,
                padding: 8,
                borderRadius: 99,
                zIndex: 100,
                color: 'black',
                fontSize: '1rem',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#eee',
              }}
            >
              <img
                style={{
                  width: '7vw',
                  height: '7vw',
                  marginRight: 4,
                }}
                src='/manbogi/coin.png'
                alt=''
              />
              {`${commaNumber(coin)}`}
            </div>
          </div>
          {storeMenuMap.map((item) => (
            <div
              key={Math.random()}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <BannerItem
                thumbnail={item.thumbnail}
                title={item.title}
                description={item.description}
                buttonText={item.buttonText}
                thumbnailSize={item.thumbnailSize}
                thumbnailBackgroundColor={item.thumbnailBackgroundColor}
                onClick={item.onClick}
              />
              <div
                style={{
                  borderBottom: '1px solid #eee',
                  width: '92%',
                  marginTop: 4,
                  marginBottom: 4,

                  alignSelf: 'center',
                }}
              />
            </div>
          ))}

          {rewardToastInfo?.visible && (
            <div
              style={{
                width: '150px',
                height: '150px',
                borderRadius: 16,
                position: 'absolute',
                top: '25%',
                left: '50%',
                backgroundColor: 'rgba(0,0,0,0.8)',
                transform: 'translate(-50%,-50%)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 500,
              }}
            >
              <div style={{ marginTop: 0, marginBottom: 8 }}>
                {rewardToastInfo?.rewardText}
              </div>

              <div
                style={{
                  width: '20vw',
                  height: '20vw',
                  backgroundImage: rewardToastInfo?.rewardImage,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                }}
              />
              <div
                style={{ marginTop: 8 }}
              >{`${rewardToastInfo?.rewardAmount}${rewardToastInfo?.unit} 획득!`}</div>
            </div>
          )}
        </div>
      </BottomSheet>

      {/* hisotry BottomSheet */}
      <BottomSheet
        expandOnContentDrag
        className='bottom-sheet'
        open={showHistoryCalendar}
        // blocking={        }
        scrollLocking={false}
        onDismiss={() => {
          setShowHistoryCalendar(false)
        }}
        defaultSnap={({ lastSnap, snapPoints }) => {
          lastSnap ?? Math.max(...snapPoints)
        }}
        style={{ backgroundColor: 'black' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              width: '100%',
              padding: 16,
              paddingTop: 0,
              paddingBottom: 0,
              // height: "400px",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginTop: 8,
                position: 'relative',
                color: '#000',
                fontSize: '1.2rem',
              }}
            >
              걷기 기록
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
                marginTop: 8,
              }}
            >
              <div
                style={{
                  color: '#FFA823',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: 8,
                  position: 'relative',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                {`${Number(dayjs(value).format('MM'))}월 ${dayjs(value).format(
                  'DD',
                )}일 `}
              </div>
              <div
                style={{
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: 8,
                  position: 'relative',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  marginLeft: 8,
                  padding: 4,
                  paddingLeft: 8,
                  paddingRight: 8,
                  backgroundColor: '#FFA823',
                  borderRadius: 8,
                }}
              >
                {`${commaNumber(stepsForCalendar)}걸음`}
              </div>
            </div>
          </div>
          <div
            style={{
              borderBottom: '1px solid #ddd',
              width: '90%',
              marginTop: 16,
              marginBottom: 16,
              alignSelf: 'center',
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 16,
              padding: 16,
              paddingTop: 0,
            }}
          >
            <Calendar
              onChange={(date) => {
                onChange(date)
                const dayjsDate = dayjs(date).format('YYYY-MM-DD')
                let target = historyLog.find((x) => x.createdDate === dayjsDate)
                setStepsForCalendar(target?.steps ?? 0)
              }}
              value={value}
              minDetail='month' // 상단 네비게이션에서 '월' 단위만 보이게 설정
              formatDay={(locale, date) => dayjs(date).format('DD')} // 날'일' 제외하고 숫자만 보이도록 설정
              showNeighboringMonth={false} //이전, 이후 달의 날짜는 보이지 않도록 설정
              onActiveStartDateChange={async (m) => {
                getManbogiLog(new Date(m.activeStartDate))
              }}
              showNavigation={false}
              tileContent={({ date, view }) => {
                if (
                  historyLog.find(
                    (x) => x?.createdDate === dayjs(date).format('YYYY-MM-DD'),
                  )
                ) {
                  return (
                    <>
                      <div className='dot-div'>
                        <div className='dot' />
                      </div>
                    </>
                  )
                }
              }}
            />
          </div>
        </div>
      </BottomSheet>

      {/* <ManbogiModal /> */}
      {/* <div style={{ marginTop: 16 }} /> */}

      {!showStore && rewardToastInfo?.visible && (
        <div
          style={{
            width: '150px',
            height: '150px',
            borderRadius: 16,
            position: 'fixed',
            top: '25%',
            left: '50%',
            backgroundColor: 'rgba(0,0,0,0.8)',
            transform: 'translate(-50%,-50%)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 500,
          }}
        >
          <div style={{ marginTop: 0, marginBottom: 8 }}>
            {rewardToastInfo?.rewardText}
          </div>

          <div
            style={{
              width: '20vw',
              height: '20vw',
              backgroundImage: rewardToastInfo?.rewardImage,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          />
          <div
            style={{ marginTop: 8 }}
          >{`${rewardToastInfo?.rewardAmount}${rewardToastInfo?.unit} 획득!`}</div>
        </div>
      )}

      <div style={{ zIndex: 900 }}>
        <LoadingIndicator isLoading={isLoading} />
      </div>
    </div>
  )
}

export default Manbogi
