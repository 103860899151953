import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const PetTrialModal = observer(({ token, farmData, showRewardToast }) => {
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1900,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '80vw',
            height: `${90 * (310 / 334)}vw`,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -55%)',
            display: 'flex',
            position: 'absolute',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundImage: `url(../pet/petTrial.png)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            // border: "2px solid black",
          }}
        >
          <button
            style={{
              width: '8vw',
              right: 0,
              top: '-10vw',
              position: 'absolute',
              zIndex: 1999,
              // borderRadius: "1px solid black",
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 1101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            style={{
              width: '70vw',
              height: '12vw',
              position: 'absolute',
              bottom: '7vw',
              // border: "2px solid black",
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          />
          {/* <div
            style={{
              background:
                "linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)",

              width: "60vw",
              height: "14vw",
              borderRadius: 99,
              color: "black",
              fontFamily: "Maplestory",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "5.5vw",
              marginBottom: "8vw",
              position: "absolute",
              bottom: "0",
            }}
            className="scaleUp"
            onClick={() => {
              shareKakao();
            }}
          >
            친구 초대하기
          </div> */}
        </div>
      </div>
    </>
  )
})
export default PetTrialModal
