import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import WhiteTextTimer from '../WhiteTextTimer'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const CouponUsageNudgeModal = observer(
  ({
    setShowModal,
    farmData,
    setFarmData,
    userCodePushVersion,
    codePushVersionAvailable,
    token,
    couponInfo,
  }) => {
    const [linkForQR, setLinkForQR] = useState()
    const throttleRef = useRef({})

    const sendToPurchaseScreen = () => {
      AlfarmEventLogger({
        throttleRef,
        locationType: 'modal',
        locationName: ModalStore.isModalOpen,
        eventType: 'click',
        eventName: 'cta',
        data: {},
        collection: 'UserAlfarmClickLog',
      })

      const purchaseData = {
        enteringComponent: 'Alfarm_couponUsageNudge',
        text: '올팜 일석이조 찬스',
        source:
          'https://assets.ilevit.com/340b3ce8-53af-49c6-adb7-bf2c8f4fce89.png',
        rewardAmount: 1,
        rewardType: '물 500g, 고급비료',
        rewardText: '쿠폰을 써서 구매하면 물 500g, 고급비료 1개를 받아요',
      }

      window.location.href = `#commonPurchaseReward.${JSON.stringify(
        purchaseData,
      )}`
      ModalStore.setIsModalOpen('basic')
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '150vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
              display: 'flex',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              // border: "1px solid red",
              padding: 16,
              backgroundImage: 'url(../couponUsageNudge/background.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              paddingTop: '57vw',
              paddingBottom: '16%',
              // marginBottom: "15%",
            }}
          >
            <button
              style={{
                width: '8vw',
                right: 0,
                top: '20vw',
                position: 'absolute',
                zIndex: 999,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                marginTop: '2vw',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontFamily: 'Maplestory',
                  textAlign: 'center',
                  lineHeight: '2rem',
                }}
              >
                가지고있는 쿠폰 써서{' '}
                <b style={{ color: '#E94343', fontSize: '5vw' }}>할인</b> 받고
                <br />
                <b style={{ color: '#E94343', fontSize: '5vw' }}> 보상</b>도
                받아보세요
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '-2vw',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Maplestory',
                    color: '#442b22',
                    marginRight: 4,
                    fontWeight: 'bold',
                  }}
                >
                  {`쿠폰 시간: `}
                </div>
                <WhiteTextTimer
                  onTimeEnd={() => setShowModal(false)}
                  timeStamp={
                    couponInfo?.couponList?.[0]?.expiresAt
                      ? new Date(couponInfo?.couponList?.[0]?.expiresAt)
                      : new Date(new Date().getTime() + 1000 * 60 * 60 * 3)
                  }
                  timerMinutes={0}
                  interval={1000}
                  style={{
                    fontFamily: 'Maplestory',
                    color: '#442b22',
                    fontWeight: 'bold',
                  }}
                  startFromMinutes
                  noDays
                />
                <div
                  style={{
                    fontFamily: 'Maplestory',
                    color: '#442b22',
                    marginLeft: 4,
                    fontWeight: 'bold',
                  }}
                >
                  {`남음`}
                </div>
              </div>

              <div
                style={{
                  width: '100%',
                  height: '20vw',
                  backgroundImage: 'url(../couponUsageNudge/reward.png)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  margin: '8px 0px 8px 0px',
                }}
              />

              <div
                style={{
                  background:
                    'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',

                  width: '60vw',
                  height: '14vw',
                  borderRadius: 99,
                  color: 'black',
                  fontFamily: 'Maplestory',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '4vw',
                }}
                onClick={sendToPurchaseScreen}
              >
                할인 받고 보상도 받기
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default CouponUsageNudgeModal
