import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'

const ItemEndedModal = observer(() => {
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 10000,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.8)',
        }}
      />
      <div
        style={{
          height: '120vw',
          position: 'absolute',
          zIndex: 10001,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: '80vw',
          background:
            'linear-gradient(360deg, rgba(255,185,80,1) 0%, rgba(255,244,116,1) 100%)',
          borderRadius: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontFamily: 'maplestory',
            color: '#402C24',
            fontSize: '5vw',
            textAlign: 'center',
            marginTop: '4.4vw',
          }}
        >
          작물 변경 안내
        </div>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '4vw',
            width: '92%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vw',
            marginTop: '4vw',
          }}
        >
          <div
            style={{
              fontFamily: 'maplestory',
              color: '#402C24',
              textAlign: 'start',
              width: '86%',
              fontSize: '4vw',
            }}
          >
            <br></br> 안녕하세요
            <br></br>
            <br></br> 고객님께서 현재 키우고 계신 작물의
            <br></br>
            농가 수확 시기가 종료되었어요
            <br></br>
            작물을 수확하기 위해 작물을 변경해주세요
            <br></br>
            <br></br>작물 변경시 현재 경험치 그대로 유지되며 키우기 쉬운 작물로
            변경하는 경우
            <br></br>더 빨리 수확할 수 있어요
            <br></br>
            <br></br>이용에 불편을 드려 대단히 죄송합니다
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '5vw',
              }}
            >
              <button
                style={{
                  padding: '4vw 1vw 4vw 1vw',
                  background:
                    'linear-gradient(180deg, rgba(255,212,61,1) 0%, rgba(255,226,125,1) 100%)',
                  borderRadius: '100vw',
                  width: '65vw',
                  fontSize: '4vw',
                  fontFamily: 'maplestory',
                  color: 'black',
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('changePlant')
                }}
              >
                지금 변경하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default ItemEndedModal
