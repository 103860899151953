import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const PesticideModal = observer(({ token, farmData, setFarmData }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const throttleRef = useRef({})

  return (
    <div className='flex flex-1 fixed left-0 top-0 z-[5004] w-full h-full bg-[rgba(0,0,0,0.8)]'>
      <div className='w-full transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 absolute flex justify-center'>
        <button
          className='w-[10vw] -top-[10vw] right-[5vw] absolute z-[1113]'
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            className='z-[101] absolute'
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
        <img src='/pesticide/modal.png' alt='abc' style={{ width: '80vw' }} />
        <div
          className={`
        ${isButtonPressed ? 'bg-[#F7C82A]' : 'bg-[#F7C82A]'}
        ${isButtonPressed ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)' : ''}
        w-[65vw] h-[14vw] rounded-full text-black font-maplestory flex justify-center items-center text-[4.5vw] absolute bottom-[16vw]
        `}
          onClick={async () => {
            AlfarmEventLogger({
              throttleRef,
              locationType: 'modal',
              locationName: ModalStore.isModalOpen,
              eventType: 'click',
              eventName: 'cta',
              data: {},
              collection: 'UserAlfarmClickLog',
            })

            const purchaseData = {
              enteringComponent: 'Alfarm_pesticidePurchase',
              text: '올팜',
              source:
                'https://assets.ilevit.com/c09a3a8f-8e54-4ba4-905b-e075c1b59eaa.png',
              rewardAmount: 1,
              rewardType: '살충제',
              rewardText: `상품을 구매하면 살충제가 뿌려져요`,
            }

            window.location.href = `#commonPurchaseReward.${JSON.stringify(
              purchaseData,
            )}`
            ModalStore.setIsModalOpen('basic')
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
        >
          상품 구매하고 살충제 뿌리기
        </div>
        <div
          style={{
            width: '80vw',
            textAlign: 'center',
            color: 'black',
            fontFamily: 'maplestory',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '3.5vw',
            position: 'absolute',
            bottom: '6vw',
          }}
        >
          *올팜 어디서든 상품을 구매하면 애벌레가 사라져요!
        </div>
      </div>
    </div>
  )
})

export default PesticideModal
