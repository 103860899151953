import React, { useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import ModalStore from 'store/ModalStore'
import backendApis from 'utils/backendApis'

const Money = ({ setScore }) => {
  const [money, setMoney] = useState([])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newMoney = [
        {
          id: Math.random(),
          x: Math.random() * window.innerWidth * 0.8 * 0.4,
          y: -Math.floor(Math.random() * 70 + 30),
          value: 0.1,
        },
        {
          id: Math.random(),
          x: (Math.random() * 0.4 + 0.6) * window.innerWidth * 0.8,
          y: -Math.floor(Math.random() * 70 + 30),
          value: 0.1,
        },
      ]
      setMoney((prevMoney) => [...prevMoney, ...newMoney])
    }, 500)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    const animationFrameId = { current: null } // 객체로 초기화

    function animate() {
      setMoney((prevMoney) =>
        prevMoney
          .map((m) => {
            const newY = m.y + 5
            if (newY > window.innerHeight) {
              return null
            }
            return { ...m, y: newY }
          })
          .filter(Boolean),
      )

      animationFrameId.current = requestAnimationFrame(animate)
    }

    animationFrameId.current = requestAnimationFrame(animate)

    return () => {
      cancelAnimationFrame(animationFrameId.current)
    }
  }, [])

  return (
    <>
      {money.map((m) => (
        <div
          aria-hidden='true'
          key={m.id}
          className='absolute cursor-pointer'
          style={{
            position: 'absolute',
            left: m.x,
            top: m.y,
            width: '17vw',
            height: '19vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1010,
          }}
          onPointerDown={() => {
            if (m?.clicked) return null
            setMoney((prevMoney) =>
              prevMoney.map((pm) => {
                if (pm === m) return { ...pm, clicked: true }
                return pm
              }),
            )
            setScore((prev) => prev + m.value)
            window.location.href = '#vibrate'
          }}
        >
          <img
            src={
              m?.clicked
                ? '/feverMode/bubbleOpened.png'
                : '/feverMode/bubble.png'
            }
            alt='envelope'
            style={{
              width: '14vw',
              height: '14vw',
            }}
          />
        </div>
      ))}
    </>
  )
}

const Timer = ({ onEnd }) => {
  const [time, setTime] = useState(12)

  useInterval(() => {
    if (time > 0) {
      setTime(time - 1)
    } else {
      onEnd()
    }
  }, 1000)

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100vw',
        height: '4vw',
        zIndex: 1010,
      }}
    >
      <div
        style={{
          background: '#FF0000',
          height: '100%',
          width: `${(time / 12) * 100}%`,
        }}
      />
    </div>
  )
}

const FeverRain = ({
  setEnd,
  token,
  setRewardTypeForRewardToast,
  setRewardAmountForRewardToast,
  setGetResourceModal,
}) => {
  const [score, setScore] = useState(0)
  const [rewardedFeverMode, setRewardedFeverMode] = useState(false)

  const onEnd = async () => {
    if (rewardedFeverMode) return null
    setRewardedFeverMode(true)
    if (Math.floor(score) > 10) {
      ModalStore?.setIsModalOpen('feverModePurchaseModal')
      return null
    }
    const result = await backendApis.giveReward(token, 'PUT', {
      loggingRewardName: 'feverMode',
      rewardType: 'fertilizer',
      valueAmount: Math.floor(score),
    })
    ModalStore?.setIsModalOpen('basic')
    backendApis.updateSpecificField({
      fieldName: 'feverModeGameData.rewardCount',
      value: Number(Math.floor(score)),
    })
    if (result?.status === 200) {
      setEnd(score)
      ModalStore?.setIsModalOpen('feverModePurchaseModal')
      setRewardTypeForRewardToast('fertilizer')
      setRewardAmountForRewardToast(Math.floor(score))
      setGetResourceModal(true)
    }
  }

  return (
    <div
      style={{
        width: '100vw',
        marginTop: '-100vh',
        zIndex: 1010,
      }}
    >
      <div
        style={{
          zIndex: 1010,
          position: 'relative',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            zIndex: 1010,
          }}
        >
          <Money setScore={setScore} />
        </div>
        <Timer onEnd={onEnd} />
        <div
          style={{
            zIndex: 1010,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            fontSize: '5vw',
            color: 'white',
            alignItems: 'center',
            right: '4vw',
            top: '2vw',
          }}
        >
          <img
            style={{
              width: '12vw',
              height: '12vw',
            }}
            src='/feverMode/fert.png'
            alt='fert'
          />
          {score.toFixed(1)}
        </div>
      </div>
    </div>
  )
}

export default FeverRain
