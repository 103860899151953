import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const TenMinDealModal = observer(
  ({ token, farmData, setFarmData, itemType }) => {
    const throttleRef = useRef({})
    const [isButtonPressed, setIsButtonPressed] = useState(false)

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              width: '90vw',
              height: '180vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              justifyContent: 'space-around',
              alignItems: 'center',
              //이미지 바꾸기
              backgroundImage: 'url(../brandDealModal/tenMinDealModal.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <button
              style={{
                width: '10%',
                top: '20vw',
                right: '8%',
                position: 'absolute',
                zIndex: 1113,
                background: 'black',
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <div
              style={{
                background: isButtonPressed ? '#F7C82A' : '#F7C82A',
                boxShadow: isButtonPressed
                  ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                  : '',
                width: '65vw',
                height: '14vw',
                borderRadius: '100vw',
                color: 'black',
                fontFamily: 'maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '4.5vw',
                position: 'absolute',
                top: '75%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              onClick={() => {
                AlfarmEventLogger({
                  throttleRef,
                  locationType: 'modal',
                  locationName: ModalStore.isModalOpen,
                  eventType: 'click',
                  eventName: 'cta',
                  collection: 'UserAlfarmClickLog',
                })
                window.location.href = `#generalNavigator.${JSON.stringify({
                  screen: 'TenMinDealScreen',
                  params: {
                    imageBannerUri:
                      'https://assets.ilevit.com/7fce66c5-cbfc-48ca-a939-ffe2e46772d1.png',
                    ratio: 99 / 360,
                    enteringComponent: 'Alfarm_tenMinDealScreen#modal',
                    initialComponent: 'Alfarm_tenMinDealScreen#modal',
                    isFrom: 'Alfarm_tenMinDealScreen',
                  },
                })}`
              }}
              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
              }}
            >
              구매하기
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default TenMinDealModal
