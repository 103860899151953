import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

const LuckyPiggyGiftListModal = observer(({ setShowGiftListModal }) => {
  const [isOpenGiftBoxButtonPressed, setIsOpenGiftBoxButtonPressed] =
    useState(false)
  const [rewardModalNumber, setRewardModalNumber] = useState(2)
  return (
    <div>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            position: 'relative',
            top: '20%',
          }}
        >
          <img
            style={{
              width: '90%',
              left: '5%',
              position: 'absolute',
            }}
            alt=''
            src={`/luckyPiggy/rewardModal${rewardModalNumber}.png`}
          />
          {rewardModalNumber <= 5 && (
            <button
              onClick={() => {
                setRewardModalNumber(rewardModalNumber + 1)
              }}
            >
              <img
                style={{
                  width: '15%',
                  top: '50vw',
                  right: '10vw',
                  position: 'absolute',
                }}
                src='/luckyPiggy/nextButton.png'
                alt=''
              />
            </button>
          )}
          {rewardModalNumber >= 3 && rewardModalNumber < 7 && (
            <button
              onClick={() => {
                setRewardModalNumber(rewardModalNumber - 1)
              }}
            >
              <img
                style={{
                  width: '15%',
                  top: '50vw',
                  left: '10vw',
                  position: 'absolute',
                }}
                src='/luckyPiggy/prevButton.png'
                alt=''
              />
            </button>
          )}
        </div>
        <button
          style={{
            width: '10%',
            top: '15%',
            right: '12%',
            position: 'absolute',
            zIndex: 1113,
          }}
          onClick={() => {
            setShowGiftListModal(false)
            setRewardModalNumber(1)
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      </div>
    </div>
  )
})

export default LuckyPiggyGiftListModal
